import styled, { css } from "styled-components";

export const ImoveisManualContainer = styled.div`
  height: 100%;
  overflow: hidden;

  .question-container {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    opacity: 0;
    transition: opacity 0.5s;

    &.show {
      opacity: 1;
    }
  }

  @media only screen and (max-width: 812px) {
    background-color: var(--velotax-background-color);
  }
`;

export const Container = styled.div`
  padding: 24px 32px 128px;
  &.view {
    padding: 0;
  }

  .integration-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    max-width: 680px;
    margin: 0 auto 16px;
    a {
      font-weight: 600;
    }
  }

  @media only screen and (max-device-width: 812px) {
    padding: 64px 0 64px;
    color: aliceblue !important;
    background-color: var(--velotax-background-color);

    .integration-text {
      padding: 16px 16px 0;
    }
  }
`;

export const ButtonContent = styled.div`
  max-width: 680px;
  margin: 0 auto 8px;
  div {
    display: flex;
    justify-content: flex-end;
  }

  @media only screen and (max-device-width: 812px) {
    max-width: 100%;
    div {
      width: 100%;
      padding: 16px 24px 0;
      button {
        span {
          font-size: 11px;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  @media only screen and (max-device-width: 576px) {
    div {
      button {
        width: 100%;
      }
    }
  }
`;

export const Question = styled.div<{ width?: string | number }>`
  height: fit-content;
  padding: 48px;
  margin: 64px 8px;
  border-radius: 16px;
  background-color: var(--velotax-background-color);
  border: 1px solid var(--velotax-background-color-ghost);
  ${({ width }) =>
    width
      ? css`
          width: ${width} !important;
        `
      : ""}
  h2 {
    font-size: 24px;
    font-weight: bold;
    position: relative;
    color: var(--velotax-text-color);
    margin-bottom: 24px;
    &::before {
      content: "";
      top: 0;
      left: -48px;
      width: 4px;
      height: 100%;
      position: absolute;
      background-color: var(--ant-primary-color);
    }
  }
  @media only screen and (min-width: 1025px) {
    width: 48%;
  }
  @media only screen and (max-width: 1024px) {
    width: 72% !important;
    padding: 32px;
    h2 {
      &::before {
        left: -32px;
      }
    }
  }
  @media only screen and (max-width: 812px) {
    width: 100% !important;
    padding: 32px 24px;
    border-radius: 0;
    margin: 0 0 64px;
    border: none;
    h2 {
      &::before {
        left: -24px;
      }
    }
    .ant-descriptions {
      .ant-descriptions-item-content {
        font-size: 12px;
      }
      .ant-descriptions-item-label {
        width: 30%;
        font-size: 12px;
      }
    }
  }
`;
