import { Collapse } from "antd";
import styled from "styled-components";

export const PosicaoCardStyled = styled(Collapse.Panel)`
  margin-bottom: 4px;
  border-radius: 8px !important;
  border: 2px solid var(--light-gray) !important;
  background-color: var(--light-gray);

  &:last-of-type {
    margin-bottom: 0;
  }

  &.ant-collapse-item-active {
    background-color: var(--velotax-background-color);
  }

  .ant-collapse-header {
    height: 72px;
    background-color: transparent;
    padding: 24px 72px 24px 32px !important;
    .ant-collapse-arrow {
      right: 32px !important;
    }

    .header {
      width: 100%;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1.5rem;
        color: var(--velotax-font-color);

        span {
          margin-left: 8px;
          font-weight: 600;
          font-size: 0.875rem;
        }
      }

      .posicao {
        font-weight: 600;
        font-size: 1.125rem;
        color: var(--velotax-font-color);
      }
    }
  }

  .ant-collapse-content {
    border: none;
    background-color: transparent;
    border-radius: 0 0 4px 4px !important;

    .ant-collapse-content-box {
      padding: 0 32px 32px;
      color: var(--velotax-font-color);
      & > div {
        & > span:first-child {
          width: 160px;
          display: inline-block;
        }
        & > span:not(:first-child) {
          margin-left: 16px;
        }
      }
    }
  }

  .hr-details {
    margin: 16px 0;
    opacity: 0.3;
  }

  .row-section {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;

    .recharts-wrapper {
      transform: translateX(12px);
    }
  }
  .chart-section {
    flex-grow: 1;
    width: 300px;
    overflow: visible;
    margin: 1rem 0 0 2rem;

    .recharts-responsive-container {
      margin-bottom: 0;
    }

    .recharts-surface {
      margin-left: 0 !important;
    }

    .recharts-text {
      font-weight: 300;
      font-size: 13px;
    }
  }

  .recharts-surface {
    overflow: visible;
  }

  .chart-tooltip {
    width: 200px;
    height: 60px;
    background-color: white;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;

    text {
      &:first-of-type {
        font-weight: 500;
      }
      &:last-of-type {
        color: var(--ant-primary-8);
      }
    }
  }

  .info-section {
    margin-top: 8px;
    div {
      display: flex;
      span {
        &:first-of-type {
          width: 160px;
        }
        &:not(:first-of-type) {
          margin-left: 16px;
        }
      }
    }
    h5 {
      color: var(--velotax-font-color-light);
    }
  }

  .info-title {
    font-size: 13px;
    margin: 1rem 0 0 0;

    &:first-of-type {
      margin: 0;
    }
  }

  @media only screen and (max-device-width: 812px) {
    .ant-collapse-header {
      height: 64px;
      padding: 20px 48px 20px 16px !important;
      .ant-collapse-arrow {
        right: 16px !important;
      }
      .header {
        .title {
          font-size: 1rem;
          span {
            margin-left: 8px;
            font-size: 0.675rem;
          }
        }
        .posicao {
          font-size: 0.875rem;
        }
      }
    }
    .row-section {
      flex-wrap: wrap;
      .chart-section {
        margin: 0;
      }
    }
    .ant-collapse-content {
      .ant-collapse-content-box {
        padding: 0 16px 24px 16px;
        & > div {
          & > span:first-child {
            width: 128px;
            font-size: 0.75rem;
            letter-spacing: -0.5px;
          }
          & > span:not(:first-child) {
            font-size: 0.75rem;
            letter-spacing: -0.8px;
          }
        }
      }
    }
  }
`;
