import { Typography } from "antd";
import { AiOutlineLock } from "react-icons/ai";
import { Card } from "../Bolsa/Cards";
import Button from "../../components/Button";
import { HomeContainer } from "../Home/styles";
import { itensExterior } from "./itensExterior";
import { useAuth } from "../../contexts/AuthContext";

export const Exterior = () => {
  const { hasPlan, showUserPlanModal } = useAuth();

  const handleOpenPlanModal = () => {
    showUserPlanModal(true);
  };

  return (
    <HomeContainer>
      <div className="content module">
        <div>
          <Typography.Title level={1}>Ativos no exterior</Typography.Title>
          <Typography.Text strong className="destak">
            Selecione a opção que deseja utilizar
          </Typography.Text>
          <div className="cards cards-2">
            {itensExterior.map((item: any) => (
              <Card
                id={item.id}
                key={item.id}
                link={item.link}
                icon={item.icon}
                onClick={
                  item.showToBasic && (hasPlan && hasPlan.basic) ?
                    undefined
                    :
                    (item.premium && (!hasPlan || !hasPlan.premium)) ||
                      (item.basic && !hasPlan)
                      ? () => {
                        handleOpenPlanModal();
                      }
                      : undefined
                }
              >
                {item.content}
                {item.description && (
                  <div className="description">{item.description}</div>
                )}
                {
                  item.showToBasic && (hasPlan && hasPlan.basic)
                    ?
                    null
                    : item.premium && (!hasPlan || !hasPlan.premium)
                      ? (
                        <Button
                          type="primary"
                          className="btn-premium"
                          icon={<AiOutlineLock size={18} />}
                          onClick={() => {
                            handleOpenPlanModal();
                          }}
                        >
                          PREMIUM
                        </Button>
                      )
                      :
                      null}
              </Card>
            ))}
          </div>
        </div>
      </div>
    </HomeContainer>
  );
};
