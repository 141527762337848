import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_EXTERIOR_URL || "http://localhost:8080",
  headers: {
    "x-api-provider": "exterior-velotax",
  },
});

export default api;
