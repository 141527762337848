import React from "react";
import NumberFormat from "react-number-format";
import { Col, Form, FormInstance, Input, Row } from "antd";
import { cepApi } from "../../../../../services/cep";
import {
  validationCep,
  validationFieldRequired,
  validationStateAddress,
} from "../../../../../utils/formValidations";

interface IAddressFormProps {
  form: FormInstance;
  setLoading: (loading: boolean) => void;
  setIsValidAddressForm: (isValidAddressForm: boolean) => void;
}

const AddressForm: React.FC<IAddressFormProps> = ({
  form,
  setLoading,
  setIsValidAddressForm,
}) => {
  const getAddressSuggestion = (value: string) => {
    if (value?.length === 8) {
      setLoading(true);
      cepApi
        .get(`${value}/json`)
        .then((res) => {
          const data = res.data;

          if (data.cep) {
            form.setFieldsValue({
              street: data.logradouro,
              neighborhood: data.bairro,
              city: data.localidade,
              state: data.uf,
            });
          } else {
            throw Error();
          }
        })
        .catch(() => {
          form.setFieldsValue({
            street: undefined,
            neighborhood: undefined,
            city: undefined,
            state: undefined,
            number: undefined,
          });
        })
        .finally(() => {
          onFieldsChange();
          setLoading(false);
        });
    }
  };

  const onFieldsChange = () => {
    form
      .validateFields()
      .then(() => setIsValidAddressForm(true))
      .catch(() => setIsValidAddressForm(false));
  };

  return (
    <>
      <Form
        form={form}
        size="large"
        layout="vertical"
        onChange={onFieldsChange}
      >
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name="cep" label="CEP" rules={validationCep}>
              <NumberFormat
                mask="_"
                placeholder="CEP"
                format="#####-###"
                className="ant-input ant-input-lg"
                onValueChange={(value) => getAddressSuggestion(value?.value)}
              ></NumberFormat>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="street"
              label="Logradouro"
              rules={validationFieldRequired}
            >
              <Input placeholder="Logradouro" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              name="neighborhood"
              label="Bairro"
              rules={validationFieldRequired}
            >
              <Input placeholder="Bairro" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="number"
              label="Número"
              rules={validationFieldRequired}
            >
              <Input placeholder="Número" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              name="city"
              label="Cidade"
              rules={validationFieldRequired}
            >
              <Input placeholder="Cidade" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="state"
              label="Estado"
              rules={validationStateAddress}
            >
              <Input placeholder="Estado" maxLength={2} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default AddressForm;
