import { Col, Row, message } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Container } from "./styles";
import api from "../../services/api";
import { DrawerModal } from "../../components/DrawerModal";

let getStatus: NodeJS.Timeout;

interface PlanIosProps {}

export const PlanIos: React.FC<PlanIosProps> = () => {
  const navigate = useNavigate();
  const [loading] = useState(true);
  const [showDrawer, setShowDrawer] = useState(true);

  useEffect(() => {
    let attempts = 0;
    getStatus = setInterval(() => {
      api
        .get("/irpf/payment/check_payment")
        .then((res) => {
          if (res.data?.status === "paid") {
            clearInterval(getStatus);
            message.success("Pagamento confirmado com sucesso!");
            navigate("/carteira");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        })
        .catch((err) => {
          message.error("Erro ao verificar contratação de plano no IOS", err);
        })
        .finally(() => {
          attempts += 1;
          if (attempts > 200) {
            clearInterval(getStatus);
          }
        });
    }, 5000);
    return () => {
      clearInterval(getStatus);
    };
  }, [navigate]);

  return (
    <Container>
      <DrawerModal
        visible={showDrawer}
        height="calc(100% - 280px)"
        title="Aguardando pagamento"
        maskStyle={{ backdropFilter: "blur(1px)" }}
        onCancel={() => {
          navigate("/");
          setShowDrawer(false);
        }}
      >
        <Row justify="center">
          <Col>{loading && <LoadingOutlined />}</Col>
        </Row>
      </DrawerModal>
    </Container>
  );
};
