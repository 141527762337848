import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { createContext, useCallback, useContext, useState } from "react";
import apiBolsa from "../services/apiBolsa";
import { getLineChartData } from "../utils/walletCharts";

export interface Posicao {
  categoria: string;
  codigo: string;
  posicao: number;
  quantidade: number;
  precoAtual: number;
  precoVenda: number;
  custoMedio: number;
  lucroPrejuizoTotal: number;
  lucroPrejuizoDiario: number;
  lucroPrejuizoTotalPercentual: number;
  lucroPrejuizoDiarioPercentual: number;
  hasPosition2019: boolean;
  corretora: string;
  resumo: any;
  loan?: {
    isLoan?: boolean;
    typeLoan?: string;
  };
}

interface Carteira {
  posicoes: Posicao[];
  patrimonioTotal: number;
  lucroPrejuizoTotal: number;
  lucroPrejuizoDiario: number;
  lucroPrejuizoTotalPercentual: number;
  lucroPrejuizoDiarioPercentual: number;
  bolsaLiquidez: any[];
}

interface ICarteiraContext {
  carteira: Carteira;
  loading: boolean;
  loadingLucroPrejuizo: boolean;
  lineChartData: any[];
  lastUpdate: Date;
  handleRefresh: () => void;
  getCarteira: (refresh?: boolean) => void;
  getLucroPrejuizo: () => void;
  getPatrimonios: (cpf: string) => Promise<any>;
}

export const CarteiraContext = createContext<ICarteiraContext>(
  {} as ICarteiraContext
);

export const CarteiraProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingLucroPrejuizo, setLoadingLucroPrejuizo] = useState(false);
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const [carteira, setCarteira] = useState<Carteira | any>({});
  const [lineChartData, setLineChartData] = useState<any>([]);
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const handleRefresh = () => {
    setShouldRefresh(true);
  }

  const getCarteira = useCallback((refresh = false) => {
    setLoading(true);
    apiBolsa
      .get("/wallet", { params: { refresh: shouldRefresh || refresh } })
      .then(({ data }) => {
        setCarteira(data);
        setLastUpdate(new Date());
      })
      .catch((error) => {
        if (error.response?.data?.status === 400) {
          message.info("Refaça sua integração com a B3 e tente novamente.");
          setTimeout(() => {
            navigate("/velotax/bolsa-integration");
          }, 1000);
        }
      })
      .finally(() => {
        setLoading(false);
        setShouldRefresh(false);
      });
  }, [navigate, shouldRefresh]);

  const getLucroPrejuizo = useCallback(() => {
    setLoadingLucroPrejuizo(true);
    apiBolsa
      .get("/wallet/lucroPrejuizo")
      .then(({ data }) => {
        setLineChartData(getLineChartData(data));
      })
      .catch((error) => {
        if (error.response?.data?.status === 400) {
          message.info("Erro getLucroPrejuizo");
        }
      })
      .finally(() => {
        setLoadingLucroPrejuizo(false);
      });
  }, []);

  const getPatrimonios = async (cpf: string) => {
    const { data } = await apiBolsa.get(`/wallet/get-patrimonios/${cpf}`);
    return data;
  };

  return (
    <CarteiraContext.Provider
      value={{
        loading,
        loadingLucroPrejuizo,
        carteira,
        lineChartData,
        lastUpdate,
        handleRefresh,
        getCarteira,
        getLucroPrejuizo,
        getPatrimonios,
      }}
    >
      {children}
    </CarteiraContext.Provider>
  );
};

export const useCarteira = () => useContext(CarteiraContext);
