import clsx from "clsx";
import { useLocation } from "react-router-dom";
import React, { useEffect, useMemo } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Layout } from "./styles";
import { Page } from "../../constants/brokers";
import { TabNavigator } from "../TabNavigator";
import { useAuth } from "../../contexts/AuthContext";
import { useBroker } from "../../contexts/BrokerContext";

interface IPageWrapperProps {
  page?: string;
  overflow?: boolean;
}

const PageWrapper: React.FC<IPageWrapperProps> = ({ children, overflow }) => {
  const { pathname } = useLocation();
  const {
    user,
    b3Authorized,
    loadingSession,
    cameFromRegister,
    setShowPhoneModal,
    showIRPFAPPBanner,
    setCameFromRegister,
  } = useAuth();
  const {
    currentBroker,
    privateRoutes,
    integration,
    hasCriptoIntegration,
    hasExteriorIntegration,
  } = useBroker();

  useEffect(() => {
    const mainContent = document.getElementById("main-content");

    function scrollEvent(e: Event) {
      const y = (e.target as HTMLDivElement).scrollTop;
      if (
        y > 100 &&
        user.user &&
        !loadingSession &&
        !user?.user?.cellphone &&
        cameFromRegister.phone &&
        !user?.user?.cellphoneFilled &&
        pathname.includes("historic") &&
        (hasCriptoIntegration || hasExteriorIntegration || true)
      ) {
        // setShowPhoneModal(true);
        setCameFromRegister((data) => ({ ...data, phone: false }));
      }
      if (user?.user?.cellphone || user?.user?.cellphoneFilled) {
        mainContent?.removeEventListener("scroll", scrollEvent);
      }
    }
    if (!user?.user?.cellphone) {
      mainContent?.addEventListener("scroll", scrollEvent);
    }
    return () => {
      mainContent?.removeEventListener("scroll", scrollEvent);
    };
  }, [
    pathname,
    user.user,
    b3Authorized,
    loadingSession,
    setShowPhoneModal,
    setCameFromRegister,
    hasCriptoIntegration,
    hasExteriorIntegration,
    cameFromRegister.phone,
    integration?.lastUpdate,
  ]);

  const currentPage = useMemo(
    () =>
      [
        ...privateRoutes,
        ...(currentBroker.pages.reduce(
          (acc, cur) => [...acc, cur, ...(cur.subPages ?? [])],
          [] as Page[]
        ) || []),
      ].find(
        (page) =>
          pathname ===
          (page.path.substring(0, 1) === "/" ? page.path : `/${page.path}`)
      ),
    [pathname, currentBroker, privateRoutes]
  );

  const hasHeader = !!currentPage?.header;
  const hasSidebar = !!currentPage?.sidebar;

  return (
    <Layout className={clsx({ showIRPFAPPBanner })}>
      {hasSidebar && <Sidebar pagePath={currentPage?.path || ""} />}
      <Layout>
        {hasHeader && <Header />}
        <div
          id="main-content"
          className={clsx("content", "beauty-scrollbar", "tab-navigator", {
            overflow,
            "no-header": !hasHeader,
          })}
        >
          {children}
        </div>
        <TabNavigator />
      </Layout>
    </Layout>
  );
};

export default PageWrapper;
