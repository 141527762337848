import { useState } from "react";
import ReactInputMask from "react-input-mask";
import { AiOutlineArrowDown } from "react-icons/ai";
import { Col, Form, Input, Row, Typography } from "antd";
import { Content } from "./styles";
import Button from "../../components/Button";
import { Page } from "../../constants/brokers";
import { useAuth } from "../../contexts/AuthContext";
import { validationCpf, validationEmail } from "../../utils/formValidations";

type Step = "cpf" | "email";

interface NovoCadastroProps {
  item: Partial<Page>;
  corretoras?: JSX.Element;
}

export const NovoCadastro: React.FC<NovoCadastroProps> = ({
  item,
  corretoras,
}) => {
  const [step, setStep] = useState<Step>("cpf");
  const { handleSignUp, loading, resgisterForm } = useAuth();

  const {
    settings: { title, subTitle },
  } = item;

  const onFinish = (data: any) => {
    handleSignUp(
      {
        name: "",
        pass: "",
        email: data.email,
        policiesAccepted: true,
        cpf: data.cpf.replace(/[_.-/-]/g, ""),
      },
      {}
    );
  };

  return (
    <div className="velotax-container">
      <Typography.Title level={1} className="page-title-outside">
        {step === "email" ? "Saiba quando tiver impostos" : title}
      </Typography.Title>
      <Typography.Paragraph className="destak" strong>
        {step === "email"
          ? "Notificações no seu e-mail quando estiver com impostos em aberto"
          : subTitle}
      </Typography.Paragraph>
      <div className="velotax-content">
        <Content>
          <Row>
            {corretoras && (
              <Col span={24} className="corretoras">
                {corretoras}
              </Col>
            )}
            <Col span={24}>
              <Form
                size="large"
                layout="vertical"
                onFinish={onFinish}
                form={resgisterForm}
              >
                <Form.Item
                  name="cpf"
                  label="Digite seu CPF"
                  rules={validationCpf}
                  hidden={step !== "cpf"}
                >
                  <ReactInputMask
                    placeholder="CPF"
                    mask="999.999.999-99"
                    className="ant-input ant-input-lg"
                  />
                </Form.Item>

                <Form.Item
                  name="email"
                  label="Digite seu e-mail"
                  rules={validationEmail}
                  hidden={step !== "email"}
                >
                  <Input placeholder="E-mail" />
                </Form.Item>

                {step === "cpf" ? (
                  <Button
                    block
                    size="large"
                    type="primary"
                    icon={<AiOutlineArrowDown size={18} />}
                    onClick={() => {
                      resgisterForm
                        .validateFields(["cpf"])
                        .then(() => {
                          setStep("email");
                        })
                        .catch(() => {});
                    }}
                  >
                    Prosseguir
                  </Button>
                ) : (
                  <>
                    <Button
                      block
                      size="large"
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                      icon={<AiOutlineArrowDown size={18} />}
                    >
                      Avançar
                    </Button>
                    <Button
                      block
                      size="large"
                      disabled={loading}
                      style={{ marginTop: "8px" }}
                      onClick={() => {
                        setStep("cpf");
                      }}
                    >
                      Voltar
                    </Button>
                  </>
                )}
              </Form>
            </Col>
          </Row>
          {step === "email" && (
            <Typography.Paragraph className="termos">
              Ao prosseguir você declara que leu, entendeu e está de acordo com
              os{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://velotax.com.br/termos"
              >
                Termos de Uso
              </a>
              ,{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://velotax.com.br/termos"
              >
                Política de Privacidade
              </a>{" "}
              e{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://velotax.com.br/termos"
              >
                Política de Cookies
              </a>{" "}
              do Velotax.
            </Typography.Paragraph>
          )}
        </Content>
      </div>
    </div>
  );
};
