import { Typography } from "antd";
import { RiFileList3Line } from "react-icons/ri";
import { HiOutlineCalculator } from "react-icons/hi";
import { isMobile } from "../../utils";
import { ReactComponent as ChartIcon } from "../../assets/icons/chart.svg";
import { ReactComponent as IntegrationIcon } from "../../assets/icons/integration.svg";
import { GiProfit } from "react-icons/gi";
import { AiOutlineApi } from "react-icons/ai";
import { FaHandHoldingUsd } from "react-icons/fa";

export const itensBolsa = [
  {
    id: "darf",
    link: "/velotax/bolsa-historic",
    content: <Typography>Calculadora de DARF</Typography>,
    description: <Typography>Cálculo de IR e emissão de DARF</Typography>,
    icon: (
      <HiOutlineCalculator
        className="no-fill"
        color="var(--ant-primary-color)"
      />
    ),
  },
  {
    id: "report",
    link: "/velotax/bolsa-report",
    premium: true,
    content: (
      <Typography>
        Relatórios {!isMobile() && "(padrão Receita Federal)"}
      </Typography>
    ),
    description: (
      <Typography>Relatórios auxiliares para declaração de IR</Typography>
    ),
    icon: (
      <RiFileList3Line className="no-fill" color="var(--ant-primary-color)" />
    ),
  },
  {
    id: "dividendos",
    link: "/velotax/bolsa-dividendos",
    premium: true,
    content: <Typography>Dividendos</Typography>,
    description: <Typography>Gestão dos dividendos recebidos</Typography>,
    icon: <GiProfit className="no-fill" color="var(--ant-primary-color)" />,
  },
  {
    id: "insert-manual",
    link: "/velotax/bolsa-insert-manual",
    content: <Typography>Posição 31/12/2019</Typography>,
    icon: <ChartIcon className="no-fill" />,
  },
  {
    id: "doacoes-heranca",
    link: "/velotax/bolsa-doacoes-heranca",
    content: <Typography>Doações e heranças</Typography>,
    icon: (
      <FaHandHoldingUsd
        className="no-fill"
        color="var(--ant-primary-color)"
      />
    ),
  },
  {
    id: "integration",
    link: "/velotax/bolsa-integration",
    content: <Typography>Integração com B3</Typography>,
    icon: <IntegrationIcon className="no-fill" />,
  },
  {
    id: "brokerage-note",
    link: "/velotax/bolsa-integration",
    content: <Typography>Notas de corretagem</Typography>,
    icon: <AiOutlineApi className="no-fill" color="var(--ant-primary-color)" />,
  },
];
