import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_BOLSA_URL || "http://localhost:8888",
  headers: {
    "x-api-provider": "bolsa-velotax",
  },
});

export default api;
