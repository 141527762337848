import styled from "styled-components";

export const Content = styled.div`
  .ant-btn-primary {
    height: 36px;
    font-weight: 500;
    padding: 4px 16px !important;
  }
  .ant-list.ant-list-split {
    clear: both;
    padding: 0 16px;
    border-radius: 8px;
    background-color: var(--light-gray);
    .ant-list-item-action > li {
      padding: 0;
    }
  }
  .ant-list-item-action > li,
  .ant-list-item-meta-title,
  .ant-list-item-meta-description {
    color: var(--velotax-font-color);
  }
  .ant-list-item-meta-title {
    font-size: 16px;
    font-weight: 700;
  }
  .ant-list-item-meta-description {
    opacity: 0.75;
  }
  @media only screen and (max-device-width: 812px) {
    .ant-list.ant-list-split {
      a,
      button {
        font-size: 14px;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
`;

export const ModalContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    width: 100%;
  }
  .subtitle {
    text-transform: uppercase;
    opacity: 0.6;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 8px;
    :first-of-type {
      margin-bottom: -2px;
    }
  }
  .content {
    display: flex;
    align-items: center;
    font-weight: 500;
    margin-bottom: 24px;
    text-transform: uppercase;
    .status {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      display: inline-block;
      &.success {
        border-radius: 50%;
        background-color: var(--ant-success-color);
      }
    }
    button {
      display: inline-flex;
    }
  }
`;
