import React from "react";
import { PIPProvider } from "./PIPContext";
import { AuthProvider } from "./AuthContext";
import { BrokerProvider } from "./BrokerContext";
import { HistoryProvider } from "./HistoryContext";
import { CarteiraProvider } from "./CarteiraContext";
import { UploadDecProvider } from "./UploadDecContext";
import { NotificationProvider } from "./NotificationContext";
import { B3IntegrationProvider } from "./B3IntegrationContext";
import { OldTransactionsProvider } from "./OldTransactionsContext";
import { TransferenciaCustodiaProvider } from "./TransferenciaCustodiaContext";
import { WebSocketProvider } from "./Notification.context";

const ContextWrapper: React.FC = ({ children }) => {
  return (
    <AuthProvider>
      <WebSocketProvider>
        <BrokerProvider>
          <HistoryProvider>
            <PIPProvider>
              <B3IntegrationProvider>
                <UploadDecProvider>
                  <OldTransactionsProvider>
                    <CarteiraProvider>
                      <TransferenciaCustodiaProvider>
                        <NotificationProvider>{children}</NotificationProvider>
                      </TransferenciaCustodiaProvider>
                    </CarteiraProvider>
                  </OldTransactionsProvider>
                </UploadDecProvider>
              </B3IntegrationProvider>
            </PIPProvider>
          </HistoryProvider>
        </BrokerProvider>
      </WebSocketProvider>
    </AuthProvider>
  );
};

export default ContextWrapper;
