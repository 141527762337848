import Button from "../../../Button";
import { DrawerModal } from "../../../DrawerModal";
import { message, Typography, Upload, UploadProps } from "antd";
import { CardSpreadsheet, ContainerCards } from "./styles";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { BsDownload } from "react-icons/bs";
import { useState } from "react";
import api from "../../../../services/apiCrypto";
import { useAuth } from "../../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { useBroker } from "../../../../contexts/BrokerContext";
import { download } from "../../../../utils";

interface SpreadsheetModalProps {
    visible: boolean;
    setModalSpreadsheet: Function;
}

export const itensModalSpreadsheet = [
    {
        id: "downloadSpreadsheet",
        content: <Typography>Clique aqui para baixar e</Typography>,
        description: <Typography>preencher a planilha modelo</Typography>,
        icon: <BsDownload />,
    },
    {
        id: "uploadSpreadsheet",
        premium: true,
        content: <Typography>Clique aqui para enviar a</Typography>,
        description: <Typography>planilha modelo preenchida</Typography>,
        icon: <AiOutlineCloudUpload />,
    },
];

export const SpreadsheetModal: React.FC<SpreadsheetModalProps> = ({
    visible,
    setModalSpreadsheet
}) => {
    const { user } = useAuth();
    const { currentBroker } = useBroker();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const downloadAction = async (id: string) => {
        const response = await api.get("/generic/");
        if (response?.data?.file) download(response?.data?.file)
    }

    const props: UploadProps = {
        listType: 'picture',
        beforeUpload(file) {
            return new Promise(async (resolve) => {
                setLoading(true);
                const formData = new FormData();
                formData.append("file", file);
                await api
                    .post("/generic/readSpreadsheet/", formData, {
                        params: { email: user.user.email, key: "generic" },
                    })
                    .then(() => {
                        message.success("Integração iniciada com sucesso");
                        setLoading(false);
                        setModalSpreadsheet(false);
                        resolve();
                    })
                    .catch((error) => {
                        message.error("Não foi possivel realizar a integração");
                        setLoading(true);
                        resolve();
                    }).finally(() => {
                        navigate(`/${currentBroker.path}/cripto`);
                    });
            });
        },
    };

    return (
        <DrawerModal
            destroyOnClose
            width="600px"
            height="800px"
            onCancel={() => { setModalSpreadsheet(false) }}
            maskClosable={false}
            visible={visible}
            title="Integração via planilha"
        >
            Baixe a planilha modelo e preencha suas operações de criptoativos ou faça o upload da sua planilha preenchida.
            <ContainerCards>
                {itensModalSpreadsheet.map((item) => {
                    return (
                        <CardSpreadsheet id={`${item.id}`}>
                            {item.id === "uploadSpreadsheet"
                                ? (<Upload {...props}><Button className="button" disabled={loading} icon={item.icon}></Button></Upload>)
                                : (<Button className="button" disabled={loading} onClick={() => { downloadAction(item.id) }} icon={item.icon} />)
                            }
                            <Typography.Text className="content">
                                {item.content}
                            </Typography.Text>
                            <Typography.Text>
                                {item.description}
                            </Typography.Text>
                        </CardSpreadsheet>
                    )
                })}
            </ContainerCards>
        </DrawerModal>
    );
};
