import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useCallback, useEffect, useState } from "react";
import { Col, Form, Input, Row, Typography, message } from "antd";
import api from "../../services/api";
import Button from "../../components/Button";
import { Content, Subtitle } from "./styles";
import { Page } from "../../constants/brokers";
import { AuthContainer } from "../../styles/LoginSignUp/authContainer";

interface UserForgotPasswordB3Props {
  item: Page;
}

type TDetailsUser = {
  cpf?: string;
  email?: string;
  institution?: string;
};

export const UserForgotPasswordB3: React.FC<UserForgotPasswordB3Props> = () => {
  const navigate = useNavigate();
  // const [loadingCheckDetail, setLoadingCheckDetail] = useState(true);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [, setCheckChangePassword] = useState(false);
  const [textLoading, setTextLoading] = useState("");
  const [processingBot, setProcessingBot] = useState(true);
  const [detailsUserB3, setDetailsUserB3] = useState<TDetailsUser>();

  const resetAccountCodeB3 = useCallback(() => {
    setCheckChangePassword(true);
    api
      .post(`/b3/reset`)
      .then(({ data }) => {
        message.success("Senha alterada com sucesso", 5);

        if (data.success) {
          setTimeout(() => {
            navigate("/velotax/bolsa-integration-user-has-account");
          }, 1000);
        } else {
          setTimeout(() => {
            navigate("/velotax/bolsa-integration-user-has-account");
          }, 1000);
          message.error("Erro interno. Tente novamente");
        }
      })
      .catch((err) => {
        message.error(err.response.data.message);
      })
      .finally(() => {
        setProcessingBot(false);
        setCheckChangePassword(false);
      });
  }, [navigate]);

  const checkFinishResetPassword = useCallback(() => {
    api
      .get(`/b3/checkPasswordReseted`)
      .then((response: any) => {
        if (response.data?.data) {
          setDetailsUserB3(response.data.data);

          if (response.data?.data?.passwordReset) {
            message.info("navegou");
            setTimeout(() => {
              navigate("/velotax/bolsa-integration-user-has-account");
            }, 1000);
          }
        }
      })
      .catch((err) => message.error(err));
  }, [navigate]);

  const onFinish = (data: any) => {
    api
      .post(`/b3/saveCode`, {
        data,
      })
      .then(() => {
        setLoading(true);
        setTextLoading("Alterando sua senha...");
        setCheckChangePassword(true);
      })
      .catch((err) => message.error(err?.message))
      .finally(() => {});
  };

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    console.log(processingBot);

    if (processingBot) {
      interval = setInterval(() => {
        checkFinishResetPassword();
      }, 2500);
    } else {
      if (interval) {
        clearInterval(interval);
      }
    }
    return () => clearInterval(interval as unknown as NodeJS.Timeout);
  }, [processingBot, checkFinishResetPassword]);

  useEffect(() => {
    setTextLoading("Gerando código de segurança...");
    resetAccountCodeB3();
  }, [resetAccountCodeB3]);

  return (
    <AuthContainer className="beauty-scrollbar">
      <div className="auth-content">
        <div className="form-container">
          <Typography.Title level={1}>
            <Row align="bottom" justify="space-between">
              <Col>
                {" "}
                <Col>Código de autenticação</Col>
              </Col>
            </Row>
          </Typography.Title>
          {processingBot && (
            <Typography.Title level={4}>
              <Row align="bottom" justify="space-between">
                <Subtitle>
                  <Col>
                    Um código de autenticação foi enviado para o seu e-mail para
                    cadastrarmos uma nova senha para você.
                  </Col>
                </Subtitle>
              </Row>
            </Typography.Title>
          )}

          {detailsUserB3 && (
            <Typography.Title level={4}>
              <Row align="bottom" justify="space-between">
                <Subtitle>
                  <Col>
                    E-mail:
                    {detailsUserB3?.email! || ""}
                  </Col>
                </Subtitle>
              </Row>
            </Typography.Title>
          )}

          <Content>
            {detailsUserB3?.email && (
              <Form
                form={form}
                size="large"
                layout="vertical"
                onFinish={onFinish}
              >
                <Form.Item
                  name="codeReceivedEmail"
                  label="Código"
                  // rules={}
                >
                  <Input placeholder="Código" />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Nova Senha"
                  // rules={}
                >
                  <Input.Password placeholder="Nova Senha" />
                </Form.Item>

                <Form.Item>
                  <Button size="large" type="primary" block htmlType="submit">
                    Salvar
                    <AiOutlineArrowRight />
                  </Button>
                </Form.Item>
              </Form>
            )}
          </Content>

          {(!detailsUserB3 || loading) && (
            <Typography.Paragraph
              style={{
                display: "flex",
                margin: "24px 0",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LoadingOutlined />
              &nbsp; {textLoading}
            </Typography.Paragraph>
          )}
        </div>
      </div>
    </AuthContainer>
  );
};
