import moment from "moment";
import { message } from "antd";
import { Route, useLocation, useNavigate } from "react-router-dom";
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import SSO from "../pages/SSO";
import apis from "../services/apis";
import { Home } from "../pages/Home";
import { Login } from "../pages/Login";
import { useAuth } from "./AuthContext";
import { Planos } from "../pages/Planos";
import { IRPFPage } from "../pages/IRPF";
import { Sucesso } from "../pages/Sucesso";
import { PlanIos } from "../pages/PlanIos";
import apiBolsa from "../services/apiBolsa";
import { Cadastro } from "../pages/Cadastro";
import { Carteira } from "../pages/Carteira";
import apiCrypto from "../services/apiCrypto";
import { MinhaConta } from "../pages/MinhaConta";
import { Regulation } from "../pages/Regulation";
import imoveisBackend from "../services/imoveis";
import apiExterior from "../services/apiExterior";
import { tenteIntegrarNovamente } from "../utils";
import { Pages } from "../constants/brokers/pages";
import { ForgotPassword } from "../pages/ForgotPassword";
import { ChangePassword } from "../pages/ChangePassword";
import rendimentosBackend from "../services/rendimentos";
import { Broker, brokers, Page } from "../constants/brokers";
import { LoadingSession } from "../components/LoadingSession";
import { PhoneNotification } from "../components/PhoneNotification";
import { parseSearchParams } from "../utils/path.utils";
import { DeclaracaoIRPF } from "../pages/Declaracao-IRPF";

interface HandleIntegrationInitParams {
  onlyB3?: boolean;
}

export interface Integration {
  key: string;
  integrated: boolean;
  lastUpdate: string;
  providerExists: boolean;
  isIntegrated?: boolean;
  plans?: { name: string; status: string };
  secret?: string;
  userId?: string;
  status?: string;
  provider?: string;
  statusTimestamp?: string;
  hasError?: boolean;
}

const defaultIntegration: Integration = {
  key: "unset",
  lastUpdate: "",
  integrated: false,
  providerExists: false,
  hasError: false,
};

interface IBrokerContext {
  currentBroker: Broker;
  currentPage?: Page;
  integration: Integration;
  setIntegration?: React.Dispatch<React.SetStateAction<Integration>>;
  xtageIntegration: boolean;
  integrations: { [key: string]: Integration };
  setInitIntegration: (v: boolean) => void;
  setIntegrations: Dispatch<SetStateAction<{ [key: string]: Integration }>>;
  initIntegration: boolean;
  loadingIntegrationStatus: boolean;
  handleIntegrationInit: (props?: HandleIntegrationInitParams) => void
  getIntegrationStatus: (options: {
    token?: string;
    onlyB3?: boolean;
    hideLoading?: boolean;
  }) => void;
  // getXtageIntegrationStatus: (options: {
  //   token?: string;
  //   redirect: boolean;
  // }) => void;
  handleProviderExists: (value: boolean) => void;
  hasCriptoIntegration?: boolean;
  hasExteriorIntegration?: boolean;
  integrouHoje: boolean;
  pathBackup: string;
  allPaths: string[];
  abledPages: Page[];
  sidebarPages: Page[];
  mixedRoutes: JSX.Element[];
  brokerRoutes: JSX.Element[];
  privatePublicWrapperRoutes: JSX.Element[];
  publicRoutes: { path: string; element: JSX.Element }[];
  privateRoutes: {
    path: string;
    element: JSX.Element;
    header?: boolean;
    sidebar?: boolean;
  }[];
  searchParams: Record<string, string>;
  registerRoutes: JSX.Element[];
}

export const BrokerContext = createContext<IBrokerContext>({} as IBrokerContext);

export const BrokerProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const { hostname } = window.location;
  const { pathname, search } = useLocation();
  const { user, loadingSession, hasPlan, cameFromRegister, b3Authorized } =
    useAuth();
  const [onlyB3, setOnlyB3] = useState(false);
  const [pathBackup, setPathBackup] = useState<string>('');
  const [initIntegration, setInitIntegration] = useState(false);
  const [integration, setIntegration] = useState(defaultIntegration);
  const [xtageIntegration] = useState(false);
  // const [counter, setCounter] = useState(0);
  const [integrations, setIntegrations] = useState<{
    [key: string]: Integration;
  }>({});
  const [loadingIntegrationStatus, setLoadingIntegrationStatus] =
    useState(false);

  const interval = useRef<ReturnType<typeof setInterval>>();

  const searchParams = parseSearchParams(search);

  // useEffect(() => {
  //   setCounter(counter + 1);
  //   if (counter === 24) {
  //     message.info({
  //       content:
  //         "Você possui um número alto de operações realizadas na bolsa de valores. Por isso, precisaremos de um pouco mais de tempo para finalizar os seus cálculos de imposto de renda. Volte novamente em 30 minutos.",
  //       duration: 40,
  //     });
  //   }
  // }, [integration, counter]);

  useEffect(() => {
    setPathBackup((path) =>
      path !== pathname && pathname !== "/" && !pathname.includes("/p/")
        ? `${pathname}${search || ""}`
        : path
    );
  }, [pathname, search]);

  const brokerList = useMemo(
    () => brokers.filter((broker) => !broker.disabled),
    []
  );

  const dnsBroker = useMemo(
    () => brokerList.find((broker) => hostname.includes(broker.dns)),
    [brokerList, hostname]
  );

  const currentBroker = useMemo(
    () =>
      dnsBroker ||
      brokerList.find((broker) => pathname.includes(broker.path)) ||
      brokerList[0],
    [pathname, brokerList, dnsBroker]
  );

  const integrouHoje = useMemo(
    () =>
      integration.lastUpdate
        ? moment(integration.lastUpdate).isAfter(moment().startOf("day"))
        : false,
    [integration]
  );

  const hasCriptoIntegration = useMemo(
    () =>
      integrations?.mercadobitcoin?.integrated ||
      integrations?.mercadobitcoin?.status === "sucesso" ||
      integrations?.bitcointrade?.integrated ||
      integrations?.bitcointrade?.status === "sucesso" ||
      integrations?.binance?.integrated ||
      integrations?.binance?.status === "sucesso" ||
      integrations?.mynt?.isIntegrated,
    [integrations]
  );

  const hasExteriorIntegration = useMemo(
    () =>
      integrations?.sproutfi?.isIntegrated ||
      integrations?.warren?.isIntegrated ||
      integrations?.xp?.isIntegrated,
    [integrations]
  );

  const sidebarPages = useMemo(
    () => currentBroker.pages.filter((page) => page.showOnSidebar),
    [currentBroker?.pages]
  );

  const abledPages = useMemo(
    () =>
      currentBroker.pages
        .reduce(
          (acc, cur) => [...acc, cur, ...(cur.subPages ?? [])],
          [] as Page[]
        )
        .filter((page) => !!page.component)
        .filter((page) => hasPlan || !page.premium)
        .filter((page) => (page.publicRoute ? !user.user : true))
        .filter(
          (page) =>
            !page.path.includes("bolsa") ||
            b3Authorized ||
            user?.user?.skippedB3Integration ||
            !page.integratedOnly
        ),
    [hasPlan, user.user, b3Authorized, currentBroker.pages]
  );

  const brokerRoutes = useMemo(
    () =>
      abledPages
        .filter(
          (page) => !page.mixedRoute && !page.publicRoute && !page.publicWrapper
        )
        .map((page) => {
          const Element = Pages[page.component!];
          return (
            <Route
              key={page.path}
              path={page.path}
              element={<Element item={page} />}
            />
          );
        }),
    [abledPages]
  );

  const registerRoutes = useMemo(
    () =>
      abledPages
        .filter((page) => page.publicRoute)
        .map((page) => {
          const Element = Pages[page.component!];
          return (
            <Route
              key={page.path}
              path={page.path}
              element={<Element item={page} />}
            />
          );
        }),
    [abledPages]
  );

  const mixedRoutes = useMemo(
    () =>
      abledPages
        .filter((page) => page.mixedRoute)
        .map((page) => {
          const Element = Pages[page.component!];
          return (
            <Route
              key={page.path}
              path={page.path}
              element={<Element item={page} />}
            />
          );
        }),
    [abledPages]
  );

  const privatePublicWrapperRoutes = useMemo(
    () =>
      abledPages
        .filter((page) => page.publicWrapper)
        .map((page) => {
          const Element = Pages[page.component!];
          return (
            <Route
              key={page.path}
              path={page.path}
              element={<Element item={page} />}
            />
          );
        }),
    [abledPages]
  );

  const publicRoutes = [
    { path: "/p/login", element: <Login /> },
    { path: "/p/cadastrar", element: <Cadastro /> },
    { path: "/p/forgot-password", element: <ForgotPassword /> },
    { path: "/p/change-password/:token", element: <ChangePassword /> },
    ...(currentBroker.useSSO
      ? [
          {
            path: "/p/sso/:token",
            element: <SSO />,
          },
        ]
      : []),
  ];

  const privateRoutes = [
    { path: "/", element: <Home />, header: true, sidebar: true },
    { path: "/sucesso", element: <Sucesso />, header: false, sidebar: true },
    { path: "/sucesso2", element: <Sucesso />, header: false, sidebar: true },
    { path: "/conta", element: <MinhaConta />, header: true, sidebar: true },
    {
      path: "/regulacao",
      element: <Regulation />,
      header: true,
      sidebar: true,
    },
    {
      path: "/declaracao-irpf",
      element: <DeclaracaoIRPF />,
      header: true,
      sidebar: true,
    },
    {
      path: "/plan/ios",
      element: <PlanIos />,
      header: false,
      sidebar: false,
    },
    ...(!hasPlan || !hasPlan.concierge
      ? [
          {
            path: "/planos",
            element: <Planos />,
            header: true,
            sidebar: true,
          },
        ]
      : []),
    { path: "/irpf", element: <IRPFPage />, header: true, sidebar: true },
    ...(currentBroker.useCarteira
      ? [
          {
            path: "/carteira",
            element: <Carteira />,
            header: true,
            sidebar: true,
          },
        ]
      : []),
  ];

  const privatePaths = privateRoutes.map((page) => page.path);
  const publicPaths = publicRoutes.map((page) =>
    page.path.replace("/:token", "")
  );

  const abledPagesPaths = abledPages.map((page) => page.path);
  const allPaths = [...abledPagesPaths, ...privatePaths, ...publicPaths];

  const currentPage = useMemo(() => {
    const current = currentBroker?.pages.find((page) =>
      pathname.includes(page.path)
    );
    if (current?.api) {
      current.api.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("@VeloTax:token")}`;
    }
    return current;
  }, [pathname, currentBroker?.pages]);

  const getIntegrationStatus = useCallback(
    async ({
      token = user.token,
      hideLoading,
      onlyB3,
    }: {
      token?: string;
      hideLoading?: boolean;
      onlyB3?: boolean;
    }) => {
      if (token && currentBroker) {
        !hideLoading && setLoadingIntegrationStatus(true);
        try {
          const { data: b3Data } = await apiBolsa.get(
            "providers/integration-status"
          );
          setIntegration({
            key: b3Data.key,
            integrated: b3Data.integrated,
            lastUpdate: b3Data.lastUpdate,
            providerExists: b3Data.providerExists,
            plans: b3Data.plans,
            hasError: b3Data.hasError,
          });
        } catch (error) {
          setIntegration(defaultIntegration);
        }

        if (!onlyB3) {
          const binance = apiCrypto.get("/binance/integration-status");
          const bitcointrade = apiCrypto.get(
            "/bitcoin-trade/integration-status"
          );
          const mercadobitcoin = apiCrypto.get(
            "/mercado-bitcoin/integration-status"
          );
          const mynt = apiCrypto.get("/mynt/integration-status");
          const xp = apiExterior.get("/xp/integration-status", {
            headers: {
              "x-api-provider": "exterior-velotax",
            },
          });
          const sproutfi = apiExterior.get("/sproutfi/integration-status", {
            headers: {
              "x-api-provider": "exterior-velotax",
            },
          });
          const warren = apiExterior.get("/warren/integration-status", {
            headers: {
              "x-api-provider": "exterior-velotax",
            },
          });
          Promise.allSettled([
            binance,
            bitcointrade,
            mercadobitcoin,
            mynt,
            xp,
            sproutfi,
            warren,
          ])
            .then((values) => {
              setIntegrations((integrations) => ({
                ...integrations,
                ...(values[0].status === "fulfilled"
                  ? { binance: values[0].value.data }
                  : {}),
                ...(values[1].status === "fulfilled"
                  ? { bitcointrade: values[1].value.data }
                  : {}),
                ...(values[2].status === "fulfilled"
                  ? { mercadobitcoin: values[2].value.data }
                  : {}),
                ...(values[3].status === "fulfilled"
                  ? { mynt: values[3].value.data }
                  : {}),
                ...(values[4].status === "fulfilled"
                  ? { xp: values[4].value.data }
                  : {}),
                ...(values[5].status === "fulfilled"
                  ? { sproutfi: values[5].value.data }
                  : {}),
                ...(values[6].status === "fulfilled"
                  ? { warren: values[6].value.data }
                  : {}),
              }));
            })
            .catch(() => {
              setIntegrations({});
            });
        }
        !hideLoading && setLoadingIntegrationStatus(false);
      }
    },
    [currentBroker, user.token]
  );

  // const getXtageIntegrationStatus = useCallback(
  //   ({
  //     redirect,
  //     token = user.token,
  //   }: {
  //     redirect: boolean;
  //     token?: string;
  //   }) => {
  //     if (token && currentBroker) {
  //       apiCrypto
  //         .get(`brokerage-notes/status`)
  //         .then((response) => {
  //           const { isIntegrated } = response.data;
  //           setXtageIntegration(isIntegrated);
  //           if (isIntegrated && redirect) {
  //             navigate("/velotax/cripto-historic");
  //           }
  //         })
  //         .catch(() => {
  //           message.error("Erro ao consultar status de leitura");
  //         });
  //     }
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [currentBroker, user.token]
  // );

  const handleIntegrationInit = useCallback(
    (props?: HandleIntegrationInitParams) => {
      const { onlyB3 } = props ?? {}
      setOnlyB3(!!onlyB3);
      setIntegration((integration) => ({
        ...integration,
        hasError: false,
        integrated: false,
      }));
      setTimeout(() => {
        setInitIntegration(true);
      }, 250);
    },
    []
  );

  const handleProviderExists = useCallback((providerExists: boolean) => {
    setIntegration((integration) => ({ ...integration, providerExists }));
  }, []);

  useEffect(() => {
    if (currentBroker?.html.favicon) {
      const link = (document.querySelector("link[rel~='icon']") ||
        document.createElement("link")) as HTMLLinkElement;
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
      link.href = currentBroker?.html.favicon;
    }
    if (currentBroker?.html.title) {
      const title = (document.querySelector("head title") ||
        document.createElement("title")) as HTMLTitleElement;
      document.getElementsByTagName("head")[0].appendChild(title);
      title.innerHTML = currentBroker.html.title;
    }
  }, [currentBroker]);

  useEffect(() => {
    if (integration.key === "unset") {
      getIntegrationStatus({
        token: user.token,
        hideLoading: cameFromRegister.bolsa,
      });
    }
  }, [
    user.token,
    integration.key,
    getIntegrationStatus,
    cameFromRegister.bolsa,
  ]);

  // useEffect(() => {
  //   if (!xtageIntegration) {
  //     getXtageIntegrationStatus({
  //       token: user.token,
  //       redirect: false,
  //     });
  //   }
  // }, [user.token, xtageIntegration, getXtageIntegrationStatus]);

  useEffect(() => {
    if (integration.integrated && interval.current) {
      clearInterval(interval.current);
      setInitIntegration(false);
      setOnlyB3(false);
      setTimeout(() => {
        if (
          initIntegration &&
          pathname === `/${currentBroker.path}/bolsa-integration`
        ) {
          navigate("/carteira");
        }
      }, 2500);
    } else if (initIntegration && integration.hasError && interval.current) {
      clearInterval(interval.current);
      setInitIntegration(false);
      setOnlyB3(false);
      setTimeout(() => {
        message.info(tenteIntegrarNovamente, 6);
      }, 300);
    }
  }, [
    integration.integrated,
    integration.hasError,
    pathname,
    currentBroker,
    navigate,
    initIntegration,
  ]);

  useEffect(() => {
    getIntegrationStatus({
      token: user.token,
      hideLoading: true,
    })
  }, [getIntegrationStatus, user.token]);

  apis.defaults.headers.common["x-api-provider"] = currentBroker?.id || "";
  rendimentosBackend.defaults.headers.common["x-api-provider"] =
    "velotax-rendimentos" || "";
  imoveisBackend.defaults.headers.common["x-api-provider"] =
    "imoveis-velotax" || "";

  return (
    <BrokerContext.Provider
      value={{
        // Route
        searchParams,
        currentBroker,
        currentPage,
        // integrations
        integration,
        xtageIntegration,
        integrations,
        setIntegrations,
        handleIntegrationInit,
        handleProviderExists,
        getIntegrationStatus,
        setIntegration,
        // getXtageIntegrationStatus,
        initIntegration,
        loadingIntegrationStatus,
        hasCriptoIntegration,
        hasExteriorIntegration,
        integrouHoje,
        abledPages,
        allPaths,
        sidebarPages,
        mixedRoutes,
        brokerRoutes,
        publicRoutes,
        privateRoutes,
        registerRoutes,
        privatePublicWrapperRoutes,
        pathBackup,
        setInitIntegration,
      }}
    >
      {children}
      {!initIntegration && (loadingSession || loadingIntegrationStatus) && (
        <LoadingSession />
      )}
      <PhoneNotification />
    </BrokerContext.Provider>
  );
};

export const useBroker = (): IBrokerContext => useContext(BrokerContext);
