import styled from "styled-components";

export const AuthContainer = styled.section`
  height: 100%;
  padding: 96px 0 0;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;

  .auth-content {
    min-height: 100%;
    margin: 0 auto;
    max-width: calc(100% - 128px);
    border-left: 1px solid var(--velotax-ghost);
    padding: 48px 0;
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;

    :before {
      content: "";
      position: absolute;
      top: 48px;
      left: -2px;
      width: 6px;
      height: 32px;
      background-color: var(--ant-primary-color);
    }

    h1 {
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 2rem;
      color: var(--velotax-font-color);
      margin: 0 0 32px;
    }

    p {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
      color: var(--velotax-font-color);
      margin: 0 0 32px;
      text-align: center;
    }

    .form-container {
      width: 420px;

      form {
        row-gap: 0;
        display: flex;
        flex-direction: column;
      }

      .bottom-acessar {
        display: flex;
        justify-content: center;
      }

      .click-enter {
        width: 100%;
        opacity: 0.75;
        font-size: 0.75rem;
        text-align: center;
        margin: 8px 0 0 auto;
      }

      .back-button {
        padding: 6.4px 0;
        display: inline-flex;
        margin: 8px auto 0 0;
      }

      .termos {
        padding: 0;
        margin: 0 auto;
        font-size: 12px;
        text-align: justify;
      }
    }
  }

  .buttons {
    margin-top: 16px;
    display: flex;
    column-gap: 16px;
    align-items: center;
    justify-content: space-between;
  }

  .btn-underlined {
    padding: 0;
    height: 32px;
    font-weight: 700;
    border-radius: 0;
    border-bottom: 2px dashed var(--ant-primary-color);
  }

  .footer {
    display: flex;
    column-gap: 16px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 24px;
    padding: 8px 0 8px;
    border-top: 1px solid var(--velotax-ghost);
  }

  .velopro {
    display: flex;
    column-gap: 16px;
    align-items: center;
    width: 100%;
    margin: 0px auto 24px;
    justify-content: space-between;
  }

  .ant-checkbox-wrapper {
    span {
      color: var(--velotax-font-color);
    }
    a {
      font-weight: 600;
    }
  }

  .checkbox {
    margin-top: 8px;
  }

  .password-with-eye {
    position: relative;

    button {
      position: absolute;
      right: 8px;
      top: 8px;
    }
  }

  @media screen and (max-width: 576px) {
    .auth-content {
      border-left: none;
      padding: 64px 24px 0;

      :before {
        display: none;
      }

      .form-container {
        width: 100%;
      }
    }

    .buttons {
      button {
        width: 100%;
      }

      button + button {
        display: none;
      }
    }

    .footer {
      margin: 8px auto 0;
    }
  }

  @media only screen and (max-device-width: 812px) {
    padding: 80px 0 0;
    .auth-content {
      max-width: 100%;
      padding: 48px 24px 64px;
      border-left: none;
      .form-container {
        .click-enter {
          opacity: 0;
        }
      }
    }
    .ant-checkbox + span {
      text-align: justify;
    }
  }
`;
