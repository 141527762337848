import styled from "styled-components";

export const ContainerCards = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 30px;
    gap: 10px;
`;

export const CardSpreadsheet = styled.div`
  width: 250px;
  height: 150px;
  border-radius: 8px;
  transition: opacity 0.3s ease-in-out;
  border: 2px solid var(--light-gray);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;

  a {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .ant-upload {
    color: none !important;
    background-color: transparent !important;
    border-color: none !important;
  }

  .button {
    width: 50px;
    height: 50px;
    border-radius: 30px;
    border: 2px solid var(--light-gray);
    background-color: var(--light-gray);
    align-items: center;
    justify-content: center;
    display: flex;

    figure {
      align-items: center;
      justify-content: center;
      display: flex;
    }

    svg {
      width: 26px;
      height: 26px;
      fill: var(--ant-primary-color) !important;
    }
  }

  .content {
    font-weight: 700;
    margin-top: 10px;
  }

  .ant-typography {
        color: var(--ant-primary-color) !important;
    }
`
