import { Layout as AntLayout } from "antd";
import styled from "styled-components";

export const Layout = styled(AntLayout)`
  height: 100%;
  background-color: var(--velotax-ghost);
  &.showIRPFAPPBanner {
    height: calc(100% - 64px);
  }
  & > .content {
    height: 100%;
    &.overflow {
      overflow-y: auto;
    }
  }

  @media only screen and (max-device-width: 812px) {
    & > .content.tab-navigator {
      height: calc(100% - 64px - 64px);
    }
    & > .content.content.tab-navigator.no-header {
      height: calc(100% - 64px);
    }
  }
`;
