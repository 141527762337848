import React, { useState } from "react";
import { Form, Input, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../services/api";
import Button from "../../components/Button";
import { AuthContainer } from "../../styles/LoginSignUp/authContainer";
import {
  validationPassConfirmation,
  validationPassword,
} from "../../utils/formValidations";

interface IData {
  pass: string;
  passConfirmation: string;
}

export const ChangePassword: React.FC = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [form] = Form.useForm<IData>();
  const [loading, setLoading] = useState(false);

  const onFinish = async (data: IData) => {
    try {
      setLoading(true);

      await api.post(`/user/change-password/${token}`, data);
      message.success("Senha alterada com sucesso!", 5);

      setTimeout(() => {
        setLoading(false);
        navigate("/p/login");
      }, 500);
    } catch (err: any) {
      if (err?.response?.status === 400) {
        message.error(err.response?.data?.message || "Ocorreu um erro");
      }
      setLoading(false);
      return;
    }
  };

  return (
    <AuthContainer className="beauty-scrollbar">
      <div className="auth-content">
        <div className="form-container">
          <h1>Alterar senha</h1>
          <Form form={form} size="large" onFinish={onFinish}>
            <Form.Item name="pass" rules={validationPassword}>
              <Input.Password placeholder="Senha" visibilityToggle={false} />
            </Form.Item>

            <Form.Item
              name="passConfirmation"
              rules={validationPassConfirmation}
            >
              <Input.Password
                placeholder="Confirmar senha"
                visibilityToggle={false}
              />
            </Form.Item>

            <div className="buttons">
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                className="full-width"
                loading={loading}
              >
                Enviar
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </AuthContainer>
  );
};
