export function parseSearchParams(queryString: string) {
  queryString = queryString.replace("?", "");
  const queryParams = queryString.split("&");
  const params: Record<string, string> = {};

  queryParams.forEach((param) => {
    const [key, value] = param.split("=");
    params[key] = decodeURIComponent(value);
  });

  return params;
}
