import React, { useEffect, useState, useMemo } from "react";
import { message, Typography, Col, Select, Form, Row } from "antd";
import { CardContent, NewCardContent, Content } from "./styles";
import { apiPayment } from "../../services/apiPayment";
import Cards from "react-credit-cards";
import { LoadingOutlined } from "@ant-design/icons";
import { Plan as IPlan, UserPlan } from "../../constants/plans";
import getInstallmentsByPrice from "../../utils/getInstallmentsListByPrice";
import { formatCurrency } from "../../utils/format";

interface ICardList {
  onSelectCard: (card: any) => void;
  selectedCardId: string;
  amount?: string;
  plan?: IPlan | UserPlan;
  value: number;
  onChangeInstallments?: (value: number) => void;
  installmentSelected?: number;
}

export interface cardAttributes {
  id: string;
  first_six_digits: string;
  last_four_digits: string;
  brand: string;
  customer_id: string;
  holder_name: string;
  exp_month: number;
  exp_year: number;
  status: string;
  type: string;
  created_at: Date;
  updated_at: Date;
  billing_address: {
    street: string;
    zip_code: string;
    city: string;
    state: string;
    country: string;
    line_1: string;
  };
}

export const CardList: React.FC<ICardList> = ({
  onSelectCard,
  selectedCardId,
  value,
  plan,
  onChangeInstallments,
  installmentSelected,
}) => {
  const [cardList, setCardList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getCardList = () => {
    setLoading(true);
    apiPayment
      .get(`/payment/list_cards`)
      .then((ret) => {
        setCardList(ret?.data?.list || []);

        if (ret?.data?.list?.length === 0 || !ret?.data?.list) {
          onSelectCard(undefined);
        }
        setLoading(false);
      })
      .catch((err) => {
        message.error("Ocorreu um erro ao buscar cartões");
        setLoading(false);
      });
  };

  useEffect(() => {
    getCardList();
    // eslint-disable-next-line
  }, []);

  const fixedInstallments = useMemo(
    () => (!!plan?.type ? 12 : undefined),
    [plan?.type]
  );

  const installments = useMemo(
    () => getInstallmentsByPrice(value, !!plan?.type, fixedInstallments),
    [plan, value, fixedInstallments]
  );

  return (
    <Content gutter={[16, 16]}>
      <div
        style={{
          display: loading ? "flex" : "none",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <LoadingOutlined style={{ color: "var(--ant-primary-color)" }} />
      </div>
      {!loading && (
        <>
          <Col span={24} style={{ textAlign: "center" }}>
            {/* <Typography.Paragraph style={{ color: 'var(--ant-primary-color)' }}>{getMessage()}</Typography.Paragraph> */}
            <Typography.Paragraph
              style={{
                fontSize: 18,
                fontWeight: "500",
                textAlign: "center",
                textTransform: "uppercase",
                color: "var(--ant-primary-color)",
              }}
            >
              {value ? (
                <strong> {formatCurrency(value ?? 0)}</strong>
              ) : undefined}
            </Typography.Paragraph>
          </Col>
          <Col span={24}>
            <NewCardContent onClick={() => onSelectCard(undefined)}>
              <h3>Novo Cartão</h3>
            </NewCardContent>
          </Col>
          <Col span={24}>
            <Row gutter={[16, 16]}>
              {cardList &&
                cardList?.length > 0 &&
                cardList?.map((card: cardAttributes) => (
                  <Col
                    span={12}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <CardContent
                      style={{
                        backgroundColor:
                          selectedCardId === card.id
                            ? "var(--ant-primary-color)"
                            : "transparent",
                      }}
                      onClick={() => onSelectCard(card)}
                    >
                      <Cards
                        preview
                        cvc={"***"}
                        name={card.holder_name}
                        expiry={`${card.exp_month <= 9 ? "0" : ""}${card.exp_month
                          }/${card.exp_year}`}
                        number={
                          card.first_six_digits +
                          "** *****" +
                          card.last_four_digits
                        }
                        locale={{ valid: "Validade" }}
                        callback={(issuer: any, isValid: boolean) => {
                          console.log("cb issuer isvalid", issuer, isValid);
                        }}
                      />
                    </CardContent>
                  </Col>
                ))}
            </Row>
          </Col>
          {value ? (
            <Col span={24}>
              <Form.Item name="installments">
                <Select
                  size="large"
                  listHeight={400}
                  options={installments}
                  style={{ minWidth: "150px" }}
                  onChange={onChangeInstallments}
                  placeholder="Selecione a(s) parcela(s)"
                  getPopupContainer={(target) => target.parentElement}
                  value={
                    fixedInstallments || plan?.type
                      ? installments[0].value
                      : installmentSelected
                  }
                />
              </Form.Item>
            </Col>
          ) : (
            ""
          )}
        </>
      )}
    </Content>
  );
};