import { Typography } from "antd";
import { VscHistory } from "react-icons/vsc";
import { HiOutlineCalculator } from "react-icons/hi";

export const itensAutonomos = [
  {
    id: "darf",
    link: "/velotax/autonomos-historico",
    content: <Typography>Calculadora de DARF</Typography>,
    description: <Typography>Cálculo de IR e emissão de DARF</Typography>,
    icon: (
      <HiOutlineCalculator
        className="no-fill"
        color="var(--ant-primary-color)"
      />
    ),
  },
  // {
  //   id: "historic",
  //   link: "/velotax/autonomos-historico",
  //   content: <Typography>Histórico</Typography>,
  //   description: (
  //     <Typography>Histórico de impostos e pagamentos de DARF</Typography>
  //   ),
  //   icon: <VscHistory className="no-fill" color="var(--ant-primary-color)" />,
  // },
];
