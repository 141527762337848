import * as XLSX from "xlsx";
import { message } from "antd";
import { callWebviewHandler, isIosPlatform } from "./webview";

export const handleDownloadSheet = async (
  serializedData: any[],
  sheetName: string,
  fileName: string
) => {
  try {
    if (isIosPlatform()) {
      callWebviewHandler("download_file_excel", {
        sheetName: sheetName,
        fileName: fileName,
        data: serializedData,
      });
      return;
    }
    const worksheet = XLSX.utils.json_to_sheet(serializedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    XLSX.writeFile(workbook, fileName);
  } catch (error: any) {
    message.error(error);
  }
};
