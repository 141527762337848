import styled from "styled-components";

export const ChatButton = styled.div`
  position: fixed;
  width: 3.75rem;
  height: 3.75rem;
  right: 0.625rem;
  bottom: 0.625rem;
  z-index: 100;
  display: flex;
  cursor: pointer;
  user-select: none;
  border-radius: 2rem;
  align-items: center;
  justify-content: center;
  transition: 0.3s filter;
  background-color: var(--ant-primary-color);
  :hover {
    filter: contrast(1.2);
  }
  :active {
    filter: contrast(1.5);
  }

  svg {
    width: 2rem;
    height: 2rem;
    stroke: white;
    margin-top: -0.25rem;
  }

  &.hide {
    display: none;
  }

  @media (max-device-width: 812px) {
    display: none;
  }
`;

export const ChatWindow = styled.div`
  left: unset;
  right: 0.875rem;
  border: none;
  bottom: 80px;
  width: 458px;
  height: 85dvh;
  display: flex;
  position: fixed;
  user-select: none;
  max-height: 824px;
  border-radius: 8px;
  z-index: 2147483647;
  flex-direction: column;
  background-color: white;
  box-shadow: rgba(150, 150, 150, 0.2) 0 10px 30px 0,
    rgba(150, 150, 150, 0.2) 0 0 0 1px;

  &.hide,
  &.hidden {
    display: none;
  }

  .chat-header {
    height: 80px;
    padding: 1rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--light-gray);

    .chat-icon {
      display: flex;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 1.25rem;
      align-items: center;
      justify-content: center;
      background-color: var(--ant-info-color);

      svg {
        width: 1rem;
        height: 1.5rem;
        margin-left: 2px;
      }
    }

    .reset-icon {
      display: none;
      cursor: pointer;
      margin-left: auto;
      margin-right: 0.5rem;
      transition: 0.3s opacity, 0.3s transform;
      :hover {
        opacity: 0.5;
      }
      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
    .close-icon {
      cursor: pointer;
      margin-left: auto;
      transition: 0.3s opacity, 0.3s transform;
      :hover {
        opacity: 0.5;
      }
      svg {
        width: 2rem;
        height: 2rem;
      }
    }

    h1 {
      font-size: 1.125rem;
      margin: 0 0 0 0.75rem;
    }
  }

  .chat-body {
    display: flex;
    flex: 1 1 100%;
    overflow-y: auto;
    scroll-behavior: smooth;

    .chat-body-conversation {
      width: 100%;
      flex: 1 1 0%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding: 1rem 1rem 0 1rem;

      .chat-body-white-space {
        width: 100%;
        min-height: 1rem;
      }

      .chat-msg {
        :first-of-type {
          margin-top: 0;
        }
        &.chat-msg-user {
          padding-right: 0;
          padding-left: 2.5rem;
          justify-content: flex-end;
          .chat-mgs-text {
            color: white;
            background-color: #2573e2;
            border-radius: 1.25rem 1.25rem 0.25rem 1.25rem;
          }
        }
        display: flex;
        margin-top: 1.25rem;
        padding-right: 2rem;
        align-items: flex-end;
        .chat-msg-icon {
          display: flex;
          width: 2rem;
          height: 2rem;
          min-width: 2rem;
          min-height: 2rem;
          border-radius: 1rem;
          align-items: center;
          margin-right: 0.5rem;
          justify-content: center;
          background-color: var(--ant-info-color);
          &.error {
            margin: 0 0.5rem 0 0;
            background-color: var(--ant-error-color);
            svg {
              margin: 0;
              fill: white;
              width: 1.5rem;
            }
          }
          svg {
            width: 0.75rem;
            height: 1.125rem;
            margin-left: 0.125rem;
          }
        }
        .chat-mgs-text {
          text-align: left;
          user-select: text;
          font-size: 0.875rem;
          line-height: 1.25rem;
          padding: 0.875rem 1rem;
          background-color: var(--light-gray);
          border-radius: 1.25rem 1.25rem 1.25rem 0.25rem;

          .anticon-loading,
          .anticon-loading svg {
            width: 1rem;
            height: 1rem;
            color: var(--ant-primary-color);
          }
        }
      }
    }
  }

  .chat-footer {
    display: flex;
    margin-top: auto;
    flex-direction: column;
    justify-content: flex-end;
    border-top: 1px solid var(--light-gray);
    .chat-input-container {
      display: flex;
      align-items: flex-end;
      textarea {
        flex-grow: 1;
        padding: 0.75rem;
        min-height: 2rem;
        max-height: 10rem;
        font-size: 0.875rem;
        line-height: 1.25rem;
        margin: 0;
        border: none;
        resize: none;
        overflow-y: auto;
        border-radius: 0;
        pointer-events: auto;
        background-color: white;
        overflow-wrap: break-word;
        tab-size: 4;
        text-align: start;
        border-bottom-left-radius: 0.5rem;
        display: flex;
      }
      button {
        cursor: pointer;
        white-space: nowrap;
        align-items: center;
        display: inline-flex;
        justify-content: center;
        background-color: transparent;
        width: 2.25rem;
        height: 2.25rem;
        border-radius: 0.375rem;
        margin: 0.75rem 0.75rem 1rem;
        background-color: hsla(240, 5%, 96%, 0.9);
        :hover {
          filter: opacity(0.8);
        }
        :disabled {
          cursor: default;
          filter: grayscale(1) opacity(0.8);
          background-color: hsla(240, 5%, 96%, 0.9);
        }
        svg {
          fill: var(--ant-primary-color);
        }
      }
    }
  }
  @media (max-device-width: 812px) {
    display: none;
  }
`;
