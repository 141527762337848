import { useLocation } from "react-router-dom";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Header } from "./Header";
import { Container } from "./styles";

interface IPublicProgressContext {
  progress: number;
  setProgress: Dispatch<SetStateAction<number>>;
}

const PublicProgressContext = createContext<IPublicProgressContext>(
  {} as IPublicProgressContext
);

export const usePublicProgress = () => useContext(PublicProgressContext);

export const PublicWrapper: React.FC<{ className?: string }> = ({
  children,
  className,
}) => {
  const { pathname } = useLocation();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (
      pathname !== "/p/cadastrar" &&
      pathname !== "/velotax/bolsa-integration"
    ) {
      setProgress(0);
    } else if (pathname === "/velotax/bolsa-integration") {
      setProgress(90);
    }
  }, [pathname]);

  return (
    <PublicProgressContext.Provider value={{ progress, setProgress }}>
      <Container className={className}>
        <Header className={className} />
        {children}
      </Container>
    </PublicProgressContext.Provider>
  );
};
