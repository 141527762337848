import { useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";

const GoogleTagManagerNoScript = () => {
  const { isIOSAPP } = useAuth();
  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV === "prd" && !isIOSAPP) {
      // const noscriptElement = document.createElement('noscript');
      // const iframeElement = document.createElement('iframe');
      // iframeElement.src = 'https://www.googletagmanager.com/ns.html?id=GTM-M4QZ393';
      // iframeElement.height = '0';
      // iframeElement.width = '0';
      // iframeElement.style.display = 'none';
      // iframeElement.style.visibility = 'hidden';
      // noscriptElement.appendChild(iframeElement);
      // document.body.appendChild(noscriptElement);
    }
  }, [isIOSAPP]);

  return null;
};

export default GoogleTagManagerNoScript;
