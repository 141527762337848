import styled from "styled-components";

export const LogoStyled = styled.div`
  height: 96px;
  display: flex;
  justify-content: center;
  background-color: var(--velotax-background-color);
  .ant-image {
    height: 96px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img {
      height: 48px;
    }
  }
  @media only screen and (max-device-width: 812px) {
    height: 64px;
    .ant-image {
      height: 64px;
    }
  }
`;
