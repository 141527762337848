import { useEffect, useState } from "react";
import { Col, Divider, Form, Row } from "antd";
import { FooterModal } from "../FooterModal";
import api from "../../services/rendimentos";
import { DrawerModal } from "../DrawerModal";
interface FormModalProps {
  add: any;
  edit: any;
  title: string;
  onCancel: any;
  itemToEdit: any;
  visibility: boolean;
  hideFooter?: boolean;
  rows: any[];
  disableButtons?: boolean;
  preFormChildren?: React.ReactNode;
  loading?: boolean;
  saveButtonText?: string;
}

export const FormModal: React.FC<FormModalProps> = ({
  add,
  edit,
  rows,
  title,
  onCancel,
  visibility,
  itemToEdit,
  children,
  disableButtons,
  preFormChildren,
  hideFooter,
  loading,
  saveButtonText,
}) => {
  const [form] = Form.useForm();
  const [, setUpdate] = useState(false);
  useEffect(() => {
    if (!visibility) {
      form.resetFields();
    } else if (itemToEdit) {
      setUpdate((update) => !update);
      form.setFieldsValue(itemToEdit);
    }
  }, [visibility, form, itemToEdit]);

  const onValuesChange = async (changedValues: any, values: any) => {
    const inputs = rows
      .flat()
      .map((input) =>
        typeof input === "function" ? input({ data: values }) : input
      );
    if (
      inputs
        .filter((item) => item.mustUpdate)
        .map((item) => item.name)
        .includes(Object.keys(changedValues)[0])
    ) {
      setUpdate((update) => !update);
    }
    let data;
    if (changedValues?.date?.replace("_", "").length === 10) {
      const response = await api.post("/dividends/get-cambio", {
        date: changedValues.date,
      });
      values.taxaCambio = response?.data;
    }
    data = {
      ...values,
      ...inputs
        .filter((input) => input.onChange)
        .reduce(
          (acc, cur) => ({
            ...acc,
            [cur.name]: cur.onChange?.(changedValues, { ...values, ...acc }),
          }),
          {}
        ),
    };

    const dataEntries = Object.entries(data);
    form.setFields(dataEntries.map(([name, value]) => ({ name, value })));
    form.resetFields(
      dataEntries
        .filter(([, value]) => value === undefined || value === null)
        .map(([name]) => name)
    );
  };

  const onFinish = (data: any) => {
    if (itemToEdit) {
      edit({
        ...data,
        ...(itemToEdit?.manual ? { manual: true } : {}),
      });
    } else {
      add({
        ...data,
        ...(itemToEdit?.manual ? { manual: true } : {}),
      });
    }
  };

  return (
    <DrawerModal
      footer={null}
      title={title}
      onCancel={onCancel}
      visible={visibility}
      height="calc(100% - 64px)"
    >
      <Form
        form={form}
        size="large"
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        {rows.map((row, index) => (
          <Row key={index} gutter={[16, 16]}>
            {row
              .map((input: any) =>
                typeof input === "function"
                  ? input({ data: form.getFieldsValue() })
                  : input
              )
              .filter(
                (input: any) =>
                  !(
                    typeof input.hide === "function" &&
                    input.hide({ data: form.getFieldsValue() })
                  )
              )
              .map((input: any, index: number, array: any[]) => (
                <Col
                  key={input.name}
                  span={input.span ? input.span : 24 / array.length}
                  style={input.align ? { alignSelf: input.align } : {}}
                >
                  <Form.Item
                    name={input.name}
                    label={input.label}
                    rules={input.rules}
                    hidden={input.hidden}
                  >
                    {input.input}
                  </Form.Item>
                </Col>
              ))}
          </Row>
        ))}
        {children}
        <Divider />
        {!hideFooter && (
          <FooterModal
            onCancel={onCancel}
            disabled={disableButtons}
            loading={loading}
          />
        )}
      </Form>
    </DrawerModal>
  );
};
