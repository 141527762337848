import React, { useState } from "react";
import { Col, Divider, message, Row } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Button from "../Button";
import { DrawerModal } from "../DrawerModal";

interface IDeleteConfirmationModalProps {
  title: string;
  visibility: boolean;
  body: React.ReactNode;
  onCancel: () => void;
  onOk?: () => Promise<any>;
  updateData?: () => void;
  setVisibility: (param: any) => void;
  buttonLabel?: string;
  showToast?: boolean;
  successMessage?: string;
}

const DeleteConfirmationModal: React.FC<IDeleteConfirmationModalProps> = ({
  title,
  visibility,
  onCancel,
  onOk,
  body,
  updateData,
  setVisibility,
  buttonLabel,
  showToast = true,
  successMessage,
}) => {
  const [loading, setLoading] = useState(false);
  const handleOnOk = () => {
    setLoading(true);
    const finish = () => {
      setLoading(false);
      updateData?.();
      setVisibility(false);
      showToast && message.success(successMessage || "Deletado com sucesso");
    };
    if (onOk) {
      onOk()
        .then(() => finish())
        .catch((err) => {
          showToast && message.error("Algo deu errado");
          setLoading(false);
        });
    } else {
      finish();
    }
  };
  return (
    <DrawerModal
      title={title}
      footer={null}
      height="auto"
      destroyOnClose
      onCancel={onCancel}
      visible={visibility}
      maskClosable={false}
    >
      <p>{body}</p>
      <Divider />
      <Row gutter={16}>
        <Col span={12}>
          <Button
            size="large"
            disabled={loading}
            onClick={onCancel}
            className="full-width"
          >
            Cancelar
          </Button>
        </Col>
        <Col span={12}>
          <Button
            size="large"
            type="primary"
            disabled={loading}
            onClick={handleOnOk}
            className="full-width"
          >
            {loading && <LoadingOutlined />}
            {buttonLabel || "Deletar"}
          </Button>
        </Col>
      </Row>
    </DrawerModal>
  );
};

export default DeleteConfirmationModal;
