import { Select, Typography } from "antd";
import { useLocation } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { monthList } from "./dataSourceList";
import { BolsaOperations, ModalStyled } from "./styles";
import backendBolsa from "../../services/apiBolsa";
import { useBroker } from "../../contexts/BrokerContext";
import { DividendosTabs } from "../../constants/dividendos";
import { DividendosModal } from "../../components/DividendosModal/index";

export const DividendosBolsa = () => {
  const { state } = useLocation();
  const today = new Date();
  const currentYear = today.getFullYear();
  const initialYear = state?.year ?? currentYear;
  window.history.replaceState({}, document.title);

  const { currentBroker } = useBroker();

  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState<any>({});
  const [index, setIndex] = useState<any>({});
  const [showModal, setShowModal] = useState(false);
  const [year, setYear] = useState(initialYear);

  const yearOptions = Array.from(
    Array(new Date().getFullYear() - currentBroker.initialYear + 1)
  )
    .map((y, i) => ({
      value: currentBroker.initialYear + i,
      label: `${currentBroker.initialYear + i}`,
    }))
    .reverse();

  const getHistoric = useCallback(() => {
    setLoading(true);
    backendBolsa
      .get(`/dividendos?year=${year}`)
      .then(async ({ data }) => setData(data))
      .finally(() => setLoading(false))
    // eslint-disable-next-line
  }, [year]);

  const onChangeYear = (value: string) => {
    setYear(parseInt(value));
  };

  useEffect(() => {
    getHistoric();
  }, [getHistoric, year]);

  return (
    <div className="velotax-container">
      <div className="velotax-content">
        <Typography.Title level={1} className="page-title">
          Dividendos recebidos na bolsa de valores
        </Typography.Title>
        <Typography.Text>
          Os dividendos recebidos e os impostos pagos na bolsa de valores deverão ser
          declarados anualmente na Declaração de Ajuste Anual de IRPF.
        </Typography.Text>
        <br />
        <br />
        <Select
          size="large"
          options={yearOptions}
          value={year.toString()}
          onChange={onChangeYear}
          placeholder="Selecione o ano"
          style={{ minWidth: "150px" }}
          getPopupContainer={(target) => target.parentElement}
        />
        <BolsaOperations>
          <div className="sub-header">
            <span className="show dividendos-header">Mês</span>
            <span className="show dividendos-header">Rendimento Bruto</span>
            <span className="show dividendos-header"></span>
          </div>
          {monthList.map((item, index: number) => {
            return DividendosTabs(
              item,
              index,
              data,
              loading,
              setShowModal,
              setItem,
              setIndex,
              true
            ).map((container: any, rowIndex: any) => (
              <div key={rowIndex} className={container.class}>
                {container.tabs.map((item: any, colIndex: any) => (
                  <span key={colIndex} className="show">
                    {item.content}
                  </span>
                ))}
              </div>
            ));
          })}
        </BolsaOperations>

        {/* <br />

        <Typography.Text style={{ textAlign: "justify" }}>
          (1) A conversão de Dólar (US$) para Real (R$) utiliza a cotação do
          dólar PTAX de Compra fixada pelo Banco Central do Brasil para o último
          dia útil da primeira quinzena do mês anterior ao mês de recebimento do
          rendimento no exterior (art. 65 da IN RFB nº 1.500 de 2014)
        </Typography.Text> */}
      </div>
      <ModalStyled
        closable
        width={660}
        footer={null}
        destroyOnClose
        visible={showModal}
        onCancel={() => setShowModal(false)}
        title={`${monthList[index]?.monthName ?? ""} de ${year}`}
      >
        <DividendosModal item={item} year={year} />
      </ModalStyled>
    </div>
  );
};
