import styled from "styled-components";

export const Container = styled.div`
  /* utils */
  .flex {
    display: flex;
  }

  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .flex-col {
    flex-direction: column;
  }

  .gap {
    gap: 1rem;
  }

  .mt-10 {
    margin-top: 10px;
  }
  /* end-utils */

  &.velotax-container {
    height: 100%;
    padding: 64px 32px;
    .velotax-content {
      .page-title {
        margin: 0 0 4rem;
      }
      h1.page-title {
        font-size: 28px;
        margin-bottom: 8px;
        :after {
          display: none;
        }
      }
      .subtitle {
        font-size: 16px;
        color: var(--dark-gray);
        margin-bottom: 2rem;
      }
      .container-mobile {
        display: none;
      }
      width: 100%;
      max-width: 856px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .top-content {
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
        .success {
          width: 80px;
          height: 80px;
          display: flex;
          margin-top: 64px;
          position: relative;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
          background-color: var(--ant-primary-color);
          :after,
          :before {
            content: "";
            position: absolute;
            border-radius: 50%;
            background-color: var(--ant-primary-color);
          }
          :before {
            top: -16px;
            left: -16px;
            width: 112px;
            height: 112px;
            opacity: 0.1;
          }
          :after {
            top: -28px;
            left: -28px;
            width: 136px;
            height: 136px;
            opacity: 0.08;
          }
          svg {
            width: 40px;
            height: 40px;
            fill: var(--white);
          }
        }
        h1 {
          font-size: 24px;
          font-weight: 700;
          line-height: 32px;
          font-style: normal;
          margin: 48px 0 8px;
          letter-spacing: -0.24px;
        }
        div.ant-typography {
          font-size: 16px;
          font-weight: 400;
          max-width: 450px;
          line-height: 24px;
          font-style: normal;
          text-align: center;
          margin-bottom: 40px;
          color: var(--dark-gray);
        }
        .ant-btn-primary {
          width: 200px;
        }
      }
      .footer {
        opacity: 0.6;
        display: flex;
        align-items: center;
        justify-content: center;
        .ant-typography {
          display: block;
          font-size: 11px;
          max-width: 400px;
          text-align: center;
          strong {
            font-weight: 500;
            white-space: nowrap;
          }
        }
      }
    }
  }

  @media only screen and (max-device-width: 812px) {
    &.velotax-container {
      padding: 0;
      height: 100%;
      min-height: 100%;
      .velotax-content {
        height: 100%;
        min-height: 100%;
        padding: 24px 24px 32px;
        overflow-y: auto;
        .subtitle {
          display: none;
        }
        .container-mobile {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          .subtitle-mobile {
            font-size: 1rem;
            margin-top: 1rem;
            padding-top: 1rem;
            border-top: 2px solid var(--light-gray);
          }
          .link-mobile {
            margin-top: 1rem;
            font-size: 1rem;
            font-weight: 700;
          }
          .button-mobile {
            height: 72px;
            font-size: 1.125rem;
            font-weight: 700;
            margin-bottom: 2rem;
          }
          .ios-soon {
            display: flex;
            padding: 1.5rem;
            margin-top: auto;
            margin-bottom: 1rem;
            border-radius: 0.5rem;
            background-color: var(--light-gray);

            svg {
              width: 28px;
              height: 28px;
              min-width: 28px;
            }
            .ios-soon-content {
              margin-left: 1rem;
              .ios-soon-title {
                font-weight: 700;
                font-size: 1.125rem;
                margin-bottom: 1rem;
                color: var(--ant-primary-color);
              }
              .ios-soon-p {
                margin: 0;
                font-weight: 500;
              }
            }
          }
        }
        .footer {
          margin-top: 48px;
        }
      }
    }
  }
`;

export const DeclaracaoContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  font-family: "Inter", sans-serif;
  position: relative;

  .section-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  }

  .item-list-slot {
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    border-radius: 50%;
    background-color: var(--light-gray);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }

  .my-1-rem {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  section {
    width: 100%;
    overflow: hidden;
  }

  .separator-left {
    height: 100%;
    border-left: 2px solid var(--light-gray);
    padding: 24px 0px 0px 0px;
  }

  .separator-top {
    width: 60%;
    border-top: 2px solid var(--light-gray);
    padding: 1rem 0px 0px 0px;
  }

  .step-text {
    font-size: 14px;
  }

  .step-text a {
    font-weight: bold;
    color: var(--ant-primary-color);
  }

  section .text-area {
    text-align: start;
    font-size: 20px;
  }

  section h1 {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
  }

  section h4 {
    font-size: 22px;
    font-weight: 300;
    line-height: 32px;
  }

  section p {
    text-align: center;
  }

  section p a {
    cursor: pointer;
    color: var(--ant-primary-color);
    border-bottom: 1px dotted var(--ant-primary-color);
    padding-bottom: 4px;
  }

  section .qr-code-app {
    display: flex;
    margin-bottom: 1rem;
    max-height: 168px;
  }

  section .qr-code-app {
    width: 100%;
    height: 100%;
  }

  .image-section {
    max-width: 1200px;
    width: fit-content;
  }

  section .image-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
  }

  section .image-container img {
    width: auto;
    height: 100%;
  }

  .image-logo {
    position: absolute;
    bottom: 110px;
    right: 24px;
  }

  .header-text {
    text-align: center;
  }

  .header-text p {
    font-size: 14px;
    line-height: 20px;
    color: var(--dark-gray);
  }

  .declaracao-content-container {
    height: 496px;
    width: 100%;
    border: 1px solid var(--light-gray);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    overflow: hidden;
    gap: 0.75rem;
    max-width: 340px;
    padding: 2rem;
  }

  .apk-btns {
    display: flex;
    flex-direction: column;
    margin-bottom: 42px;
    gap: 20px;
  }

  .apk-btns img {
    cursor: pointer;
    border-radius: 10px;
    width: 220px;
    height: 80px;
    border: 1px solid var(--light-gray);
  }

  .tooltip-download-app {
    font-size: 12px;
    display: none;
    color: var(--ant-primary-color);
  }

  .first-section {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }

  @media only screen and (max-device-width: 812px) {
    display: none;
  }

  @media (max-width: 980px) {
    .lg-only {
      display: none !important;
      opacity: 0 !important;
      width: 0 !important;
      height: 0 !important;
    }
    .tooltip-download-app {
      display: block;
    }
    .first-section {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
    .declaracao-content-container {
      background-color: white;
      padding: 36px;
      border-radius: 12px;
      width: fit-content;
      height: fit-content;
    }
    section .image-logo {
      bottom: 10px;
      right: auto;
      margin-left: auto;
    }
  }

  @media (max-width: 480px) {
    .web-only {
      display: none !important;
    }
  }

  @media (min-width: 481px) {
    .mobile-only {
      display: none;
    }
  }
`;
