import clsx from "clsx";
import moment from "moment";
import { useState } from "react";
import { GoSync } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { List, Skeleton, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import api from "../../services/api";
import Button from "../../components/Button";
import { Content, ModalContent } from "./styles";
import { useAuth } from "../../contexts/AuthContext";
import { DrawerModal } from "../../components/DrawerModal";

interface IRPFData {
  status: {
    type: string;
    message: string;
  };
  lastUpdate: Date;
  restituionDate: Date;
  bank: string;
}

export const IRPFPage = () => {
  const { ssoToken } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [irpfData, setIrpfData] = useState<IRPFData>();
  const [selectedYear, setSelectedYear] = useState<number>();

  const initialYear = 2022;
  const yearsList = Array.from(
    Array(new Date().getFullYear() - initialYear)
  ).map((_, i) => initialYear + 1 + i);

  const openModal = (item: number) => {
    setLoading(true);
    setSelectedYear(item);
    api
      .get("/irpf/dec/details")
      .then(({ data }) => {
        setIrpfData(data);
      })
      .catch(() => {
        setIrpfData({
          status: {
            type: "success",
            message: "Declaração processada sem pendências",
          },
          bank: "ITAU UNIBANCO SA (AGÊNCIA 7069)",
          restituionDate: new Date("2023-08-31"),
          lastUpdate: new Date("2023-06-12T18:17:00"),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="velotax-container">
      <Content className="velotax-content">
        <Typography.Title level={1} className="page-title">
          Declaração IRPF
        </Typography.Title>
        <br />
        <List
          dataSource={yearsList}
          renderItem={(item) => (
            <List.Item
              actions={[
                // <Button
                //   type="link"
                //   size="large"
                //   icon={<AiOutlineSearch size={16} />}
                //   onClick={() => {
                //     openModal(item);
                //   }}
                // >
                //   Consultar
                // </Button>,
                <Button
                  type="primary"
                  target="_blank"
                  href={
                    process.env.REACT_APP_NODE_ENV === "prd"
                      ? `https://irpf.velotax.com.br/p/sso/${ssoToken}`
                      : `https://irpfdev.velotax.com.br/p/sso/${ssoToken}`
                  }
                >
                  Retificar
                </Button>,
              ]}
            >
              <Skeleton title={false} loading={false} active>
                <List.Item.Meta title={item} />
              </Skeleton>
            </List.Item>
          )}
        />
        <br />
        <Button
          size="large"
          onClick={() => {
            navigate("/carteira");
          }}
        >
          Voltar
        </Button>
        <DrawerModal
          visible={Boolean(selectedYear)}
          onCancel={() => setSelectedYear(undefined)}
          title={`Consulta ao imposto de renda: ${selectedYear}`}
        >
          <ModalContent>
            {loading ? (
              <LoadingOutlined />
            ) : (
              <div>
                <Typography.Paragraph className="subtitle">
                  Atualizado em
                </Typography.Paragraph>
                <Typography.Paragraph className="content">
                  {moment(irpfData?.lastUpdate).format("DD/MM/YYYY à\\s HH:mm")}{" "}
                  <Button
                    type="text"
                    size="large"
                    icon={<GoSync />}
                    onClick={() => {
                      openModal(selectedYear!);
                    }}
                  />
                </Typography.Paragraph>
                <Typography.Paragraph className="subtitle">
                  Status ano {selectedYear}
                </Typography.Paragraph>
                <Typography.Paragraph className="content">
                  <span className={clsx("status", irpfData?.status.type)} />
                  <strong>{irpfData?.status.message}</strong>
                </Typography.Paragraph>
                <Typography.Paragraph className="subtitle">
                  Restituição do imposto de renda
                </Typography.Paragraph>
                <Typography.Paragraph className="content">
                  <div>
                    <div>
                      Data da liberação:{" "}
                      <strong>
                        {moment(irpfData?.restituionDate).format("DD/MM/YYYY")}
                      </strong>
                    </div>
                    <div>
                      Banco: <strong>{irpfData?.bank}</strong>
                    </div>
                  </div>
                </Typography.Paragraph>
              </div>
            )}
          </ModalContent>
        </DrawerModal>
      </Content>
    </div>
  );
};
