import { Form, DatePicker, Radio } from "antd";
import NumberFormat from "react-number-format";
import { BsCalendar3 } from "react-icons/bs";
import {
  validationFieldRequired,
  validationCep,
} from "../../utils/formValidations";
import { Question } from "./interfaces";
import { antDatePickerLocale } from "../../utils";
import Input from "react-input-mask";
import moment from "moment";

export const questions: Question[] = [
  {
    id: "cep",
    title: "Qual a localização do imóvel vendido ?",
    formItems: [
      {
        name: "cep",
        FormItem: ({ tabIndex }) => {
          return (
            <Form.Item name="cep" rules={validationCep} label="CEP">
              <Input
                className="ant-input ant-input-lg"
                mask="99999-999"
                tabIndex={tabIndex}
              />
            </Form.Item>
          );
        },
      },
    ],
  },
  {
    id: "informaçõesVendaImovel",
    title: "Informações da venda do imóvel",
    formItems: [
      {
        name: "mesVenda",
        FormItem: ({ tabIndex, form }) => {
          return (
            <Form.Item
              rules={validationFieldRequired}
              name="mesVenda"
              label="Mês da venda"
            >
              <DatePicker
                size="large"
                picker="month"
                tabIndex={tabIndex}
                onChange={() =>
                  form.setFieldsValue({
                    mesAquisicao: form.getFieldValue("mesVenda"),
                  })
                }
                format="MM-YYYY"
                allowClear={false}
                locale={antDatePickerLocale}
                getPopupContainer={(target) => target.parentElement!}
                disabledDate={(current) =>
                  current && current > moment().endOf("day")
                }
                inputReadOnly={true}
                suffixIcon={<BsCalendar3 />}
              />
            </Form.Item>
          );
        },
      },
      {
        name: "valorVenda",
        FormItem: ({ tabIndex }) => {
          return (
            <Form.Item
              rules={validationFieldRequired}
              name="valorVenda"
              label="Valor da venda (R$)"
            >
              <NumberFormat
                decimalSeparator=","
                thousandSeparator="."
                prefix="R$ "
                tabIndex={tabIndex}
                placeholder="Valor da venda"
                allowNegative={false}
                className="ant-input ant-input-lg"
              />
            </Form.Item>
          );
        },
      },
      {
        name: "custoCorretagem",
        FormItem: ({ tabIndex }) => {
          return (
            <Form.Item
              rules={validationFieldRequired}
              name="custoCorretagem"
              label="Custo de Corretagem (R$)"
            >
              <NumberFormat
                decimalSeparator=","
                thousandSeparator="."
                prefix="R$ "
                tabIndex={tabIndex}
                placeholder="Custo de Corretagem"
                allowNegative={false}
                className="ant-input ant-input-lg"
              />
            </Form.Item>
          );
        },
      },
    ],
  },
  {
    id: "infoCompraImoveis",
    title: "Informações da compra do imóvel",
    formItems: [
      {
        name: "mesAquisicao",
        FormItem: ({ tabIndex, form }) => {
          return (
            <Form.Item
              rules={validationFieldRequired}
              name="mesAquisicao"
              label="Mês da aquisição"
            >
              <DatePicker
                size="large"
                disabledDate={(d) =>
                  !d || d.isAfter(`${form.getFieldValue("mesVenda")}`)
                }
                picker="month"
                format="MM-YYYY"
                allowClear={false}
                tabIndex={tabIndex}
                inputReadOnly={true}
                suffixIcon={<BsCalendar3 />}
                getPopupContainer={(target) => target.parentElement!}
              />
            </Form.Item>
          );
        },
      },
      {
        name: "valorAquisicao",
        FormItem: ({ tabIndex }) => {
          return (
            <Form.Item
              rules={validationFieldRequired}
              name="valorAquisicao"
              label="Valor da aquisição (R$)"
            >
              <NumberFormat
                decimalSeparator=","
                thousandSeparator="."
                prefix="R$ "
                tabIndex={tabIndex}
                placeholder="Valor da aquisição"
                allowNegative={false}
                className="ant-input ant-input-lg"
              />
            </Form.Item>
          );
        },
      },
    ],
  },
  {
    id: "infoResidencial",
    title: "O Imóvel é residencial ?",
    formItems: [
      {
        name: "isResidencial",
        FormItem: ({ tabIndex }) => {
          return (
            <Form.Item name="isResidencial" rules={validationFieldRequired}>
              <Radio.Group>
                <Radio value={true} tabIndex={tabIndex}>
                  Sim
                </Radio>
                <Radio value={false} tabIndex={tabIndex}>
                  Não
                </Radio>
              </Radio.Group>
            </Form.Item>
          );
        },
      },
    ],
  },
  {
    id: "infoAplicadoQuitar",
    title: `O valor obtido na venda será aplicado na compra
de um imóvel residencial em até 180 dias ou
usado para quitar financiamento imobiliário?`,
    skip: ({ data }) => !data.isResidencial,
    formItems: [
      {
        name: "isAplicado",
        FormItem: ({ tabIndex }) => {
          return (
            <Form.Item name="isAplicado" rules={validationFieldRequired}>
              <Radio.Group>
                <Radio value={true} tabIndex={tabIndex}>
                  Sim
                </Radio>
                <Radio value={false} tabIndex={tabIndex}>
                  Não
                </Radio>
              </Radio.Group>
            </Form.Item>
          );
        },
      },
      {
        name: "valorAplicado",
        FormItem: ({ tabIndex, ...rest }) => {
          return (rest as any).isValorAPlicado ? (
            <Form.Item
              rules={validationFieldRequired}
              name="valorAplicado"
              label="Informe o valor a ser aplicado (R$)"
            >
              <NumberFormat
                decimalSeparator=","
                thousandSeparator="."
                prefix="R$ "
                tabIndex={tabIndex}
                placeholder="Informe o valor a ser aplicado"
                allowNegative={false}
                className="ant-input ant-input-lg"
              />
            </Form.Item>
          ) : null;
        },
      },
    ],
  },
];
