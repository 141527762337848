import axios from "axios";

const apis = axios.create({
  baseURL: process.env.REACT_APP_URL || "http://localhost:8888",
  headers: {
    "ngrok-skip-browser-warning": "69420",
  },
});

export const NOT_AUTHORIZED = "not authorized";

export default apis;
