import { Typography } from "antd";
import {
  RiMoneyDollarCircleLine,
  RiHandCoinLine,
  RiFileSearchLine,
} from "react-icons/ri";

export const cards = [
  {
    id: "gain",
    icon: (
      <RiMoneyDollarCircleLine
        size={24}
        className="no-fill"
        color="var(--ant-primary-color)"
      />
    ),
    content: <Typography>Ganho de capital</Typography>,
  },
  {
    id: "income",
    icon: (
      <RiHandCoinLine
        size={24}
        className="no-fill"
        color="var(--ant-primary-color)"
      />
    ),
    content: <Typography>Rendimentos</Typography>,
  },
  {
    id: "anual-report",
    icon: (
      <RiFileSearchLine
        size={24}
        className="no-fill"
        color="var(--ant-primary-color)"
      />
    ),
    content: <Typography>Relatórios anuais</Typography>,
  },
];
