import { Col, Image, Row, Typography } from "antd";
import { DrawerModal, DrawerModalProps } from "../../DrawerModal";
import { ReactComponent as BTGLogo } from "../../../assets/btgpactual.svg";
import btgIntegrationExample from "../../../assets/integracao_btg_exterior.gif";

interface BTGIntegrationModalProps extends DrawerModalProps {}

export const BTGIntegrationModal: React.FC<BTGIntegrationModalProps> = (
  props
) => {
  return (
    <DrawerModal
      {...props}
      title={
        <Row align="middle" gutter={16}>
          <Col>
            <BTGLogo />
          </Col>
          <Col>Integrar com a BTG</Col>
        </Row>
      }
    >
      <Typography.Paragraph>
        Para integrar com a BTG, realize os seguintes passos:
      </Typography.Paragraph>
      <ul style={{ listStyle: "inside" }}>
        <li>Acesse a sua conta internacional dentro do aplicativo da BTG</li>
        <li>No menu inicial clique em "Mais opções"</li>
        <li> Clique em "Imposto de Renda com Velotax" e aceite os termos</li>
      </ul>
      <div
        style={{
          display: "flex",
          width: "100%",
          margin: "2rem 0",
          justifyContent: "center",
        }}
      >
        <Image
          width={430}
          src={btgIntegrationExample}
        />
      </div>
      <Typography.Paragraph>
        Após realizar esses passos, sua integração será concluída. 
        Você então poderá atualizar a página atual para confirmar que a conexão funcionou corretamente.
      </Typography.Paragraph>
    </DrawerModal>
  );
};
