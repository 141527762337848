import { Tooltip } from "antd";
import { Button } from "@mui/material";
import { AiOutlineUnlock } from "react-icons/ai";

export const PayButton: React.FC<{
  fullWidth?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  title?: React.ReactNode;
  isReport?: boolean;
  allow: boolean;
}> = ({ onClick, fullWidth, disabled, title, isReport, allow }) => (
  <Tooltip title={title} getPopupContainer={(target) => target.parentElement!}>
    <Button
      onClick={onClick}
      variant="contained"
      disabled={disabled}
      startIcon={!allow && <AiOutlineUnlock />}
      fullWidth={fullWidth}
      style={fullWidth ? { marginTop: "16px", height: "40px", backgroundColor: "#1634ff" } : {backgroundColor: "#1634ff"}}
    >
      {isReport ? 'Emitir relatório' : 'Pagar'}
    </Button>{" "}
  </Tooltip>
);