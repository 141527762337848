import { ReactNode } from "react";
import { FormItemProps } from "antd";
import { currencyToNumber } from "../../utils";
import { MaskInput, NumberInput, TextInput, SelectInput } from "../inputs";
import {
  validationFieldRequired,
  validationDate,
  validationNumberFieldPositive,
} from "../../utils/formValidations";

interface FormItemParams extends FormItemProps {
  name: string;
  span?: number;
  align?: "end";
  disabled?: boolean;
  placeholder?: string;
  onChange?: (changed: any, values: any) => any;
  hide?: ((data: any) => boolean) | boolean;
}

interface NumberFormItemParams extends FormItemParams {
  prefix?: string;
  decimalScale?: number;
  allowNegative?: boolean;
}

interface MaskFormItemParams extends FormItemParams {
  label?: string;
  mask?: string;
}

interface SelectFormItemParams extends FormItemParams {
  options: any;
  renderItem?: any;
  defaultValue?: any;
  mustUpdate?: boolean;
  value?: any;
}

export const MaskFormItem = ({
  mask,
  placeholder,
  disabled,
  ...rest
}: MaskFormItemParams) => ({
  ...rest,
  input: MaskInput({
    mask,
    placeholder,
    disabled,
  }),
});

export const DateFormItem = ({
  rules = [],
  placeholder,
  disabled,
  mask,
  ...rest
}: MaskFormItemParams) => ({
  ...rest,
  rules: rules.length > 0 ? rules : validationDate,
  input: MaskInput({
    mask: mask ?? "99/99/9999",
    placeholder: placeholder || "01/01/2021",
    disabled,
  }),
});

export const TextFormItem = ({
  rules = [],
  placeholder,
  disabled,
  ...rest
}: FormItemParams) => ({
  ...rest,
  rules,
  input: TextInput({ placeholder, disabled }),
});

export const SelectFormItem = ({
  placeholder,
  disabled,
  options,
  label,
  rules,
  value,
  defaultValue,
  ...rest
}: SelectFormItemParams) => ({
  ...rest,
  getValueFromEvent: undefined,
  rules: rules ?? validationFieldRequired,
  input: SelectInput({
    placeholder,
    disabled,
    options,
    label,
    defaultValue,
    value,
    id: rest.name
  }),
});

export const NumberFormItem = ({
  name,
  label,
  rules = [],
  prefix = "",
  disabled = false,
  placeholder = "",
  decimalScale = 2,
  allowNegative = false,
  onChange,
}: NumberFormItemParams) => ({
  name,
  label,
  rules,
  placeholder,
  input: NumberInput({
    prefix,
    decimalScale,
    placeholder,
    allowNegative,
    disabled,
  }),
  onChange: onChange
    ? onChange
    : (changed: any, values: any) =>
        changed[name]
          ? Number(changed[name].replace(/\./g, "").replace(",", ".")) || 0
          : values[name],
});

export const CurrencyFormItem = ({
  name,
  label,
  rules = [],
  prefix = "R$ ",
  disabled = false,
  decimalScale = 2,
  placeholder = "R$ 0,00",
  allowNegative = false,
  onChange,
  ...rest
}: NumberFormItemParams) => ({
  ...rest,
  name,
  label,
  placeholder,
  rules: rules.length > 0 ? rules : validationNumberFieldPositive,
  input: NumberInput({
    prefix,
    decimalScale,
    placeholder,
    allowNegative,
    disabled,
  }),
  onChange: onChange
    ? onChange
    : (changed: any, values: any) =>
        changed[name] ? currencyToNumber(changed[name], prefix) : values[name],
});
