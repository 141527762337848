import styled from "styled-components";

export const CardContainer = styled.div`
  width: 100%;
  height: 72px;
  padding: 24px;
  cursor: pointer;
  position: relative;
  border-radius: 8px;
  margin-bottom: 4px;
  background-color: var(--light-gray);
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > svg {
    width: 20px;
    height: 20px;
    opacity: 0.7;
    stroke: var(--dark-gray);
  }

  a {
    width: 100%;
    height: 100%;
    padding: 12px 12px 12px 0;
  }

  figure {
    margin: 0 16px 0 0;

    svg {
      width: 24px;
      height: 24px;
      &:not(.no-fill) {
        fill: var(--ant-primary-color);
      }
      &.no-fill {
        stroke: var(--ant-primary-color);
      }
    }
  }

  .content {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1rem;
    color: var(--velotax-font-color);
    flex-grow: 1;

    .ant-row {
      width: calc(100% - 16px);
    }

    .ant-typography {
      color: inherit;
      position: relative;
      text-align: left;
      font-weight: 600;
      font-size: 14px;
    }

    & > div {
      flex-grow: 1;
    }
  }

  @media only screen and (max-device-width: 812px) {
    &.no-plan {
      & > svg {
        display: none;
      }
    }
    .content {
      font-size: 0.875rem;
      .ant-row {
        flex-wrap: nowrap;
        width: 100% !important;
        .ant-col:first-of-type {
          max-width: 70%;
        }
      }
    }
  }
`;
