import Button from "../Button";
import { isIOS } from "../../utils";
import { Container } from "./styles";

interface BannerAppProps {}

export const BannerApp: React.FC<BannerAppProps> = () => {
  return (
    <Container>
      <div className="left-side">
        <span className="veloicon" />
        <div>
          <h1>Velotax app</h1>
          <p>Baixe o aplicativo na {isIOS() ? "App Store" : "Play Store"}</p>
        </div>
      </div>
      <Button
        size="large"
        type="primary"
        href={
          isIOS()
            ? "https://apps.apple.com/app/velotax-declare-o-irpf-2023/id6446606745"
            : "https://play.google.com/store/apps/details?id=br.com.velotax.irpf.app"
        }
        {...(!isIOS() ? { target: "_blank" } : {})}
      >
        Baixar
      </Button>
    </Container>
  );
};
