import styled from "styled-components";

export const CardContainer = styled.div`
  width: 160px;
  height: 160px;
  border-radius: 8px;
  transition: opacity 0.3s ease-in-out;
  background-color: var(--light-gray);

  &.disabled {
    cursor: default !important;
  }

  &,
  a {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  a {
    width: 100%;
    height: 100%;
  }

  figure {
    margin: 0;

    svg {
      width: 56px;
      height: 56px;
      fill: var(--ant-primary-color);
    }
  }

  .content {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: var(--velotax-font-color);
    text-align: center;

    .ant-typography {
      color: inherit;
    }
  }

  :hover {
    opacity: 0.8;
    cursor: pointer;
  }
  :active {
    opacity: 0.7;
    filter: contrast(0.95);
    cursor: pointer;
  }

  &.add {
    background-color: var(--velotax-background-color-ghost);
    border-bottom: 4px solid var(--velotax-ghost);
    svg {
      fill: var(--velotax-font-color-ghost);
    }
    .content {
      color: var(--velotax-font-color-ghost);
    }
  }
`;
