import { Typography } from "antd";
import { RiMoneyDollarCircleLine, RiFileSearchLine } from "react-icons/ri";

export const cards = [
  {
    id: "anual-report",
    icon: (
      <RiFileSearchLine
        size={24}
        className="no-fill"
        color="var(--ant-primary-color)"
      />
    ),
    content: <Typography>Relatórios anuais</Typography>,
  },
  {
    id: "gain",
    icon: (
      <RiMoneyDollarCircleLine
        size={24}
        className="no-fill"
        color="var(--ant-primary-color)"
      />
    ),
    content: <Typography>Ganho de capital</Typography>,
  },
];
