import { message } from "antd";
import React, { useEffect, useState } from "react";
import apis from "../../../services/apiCrypto";
import IntegrationButton from "../IntegrationButton";
import { useBroker } from "../../../contexts/BrokerContext";
import mercadoBitcoinLogo from "../../../assets/mercado-bitcoin.svg";
import MercadoBitcoinIntegrationModal from "./MercadoBitcoinIntegrationModal";
import { IntegrationStatusExterior } from "../ExteriorIntegration";

interface IGetEstadoIntegracao {
  success: (data: any) => void;
  error: (data: any) => void;
}

interface IMercadoBitcoinIntegrationProps {
  dependentId?: string;
  integrationCallback: (type: IntegrationStatusExterior) => void;
}

const MercadoBitcoinIntegration: React.FC<IMercadoBitcoinIntegrationProps> = ({
  dependentId,
  integrationCallback,
}) => {
  const { integrations, setIntegrations } = useBroker();
  const [loading, setLoading] = useState(false);
  const [integrationModalVisible, setIntegrationModalVisible] = useState(false);

  const getEstadoIntegracao = ({ success, error }: IGetEstadoIntegracao) =>
    apis
      .get("/mercado-bitcoin/integration-status")
      .then(({ data }) => {
        setIntegrations((integrations) => ({
          ...integrations,
          mercadobitcoin: data,
        }));
        success(data);
      })
      .catch((err) => {
        error(err);
      });

  useEffect(() => {
    getEstadoIntegracao({
      success: (data) => {
        if (!data.integrated && data.providerExists) {
          setLoading(true);
          integrationCallback("carregando");
        } else if (data.integrated) {
          setLoading(false);
          integrationCallback("integrado");
        } else {
          setLoading(false);
          integrationCallback("nao-integrado")
        }
      },
      error: (err: any) => {
        setLoading(false);
        integrationCallback("erro");
        if (err.response?.data?.message) {
          return message.error(err.response?.data?.message);
        }
        return message.error(
          "Algo deu errado na integração com o Mercado Bitcoin, verifique suas credenciais"
        );
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (loading) {
      interval = setInterval(
        () => {
          getEstadoIntegracao({
            success: (data: any) => {
              if (!data.integrated && data.providerExists) {
                integrationCallback("carregando");
              } else if (data.integrated) {
                setLoading(false);
                message.success("Integração feita com sucesso!");
                integrationCallback("integrado");
              } else {
                setLoading(false);
                integrationCallback("nao-integrado");
              }
            },
            error: (err: any) => {
              setLoading(false);
              integrationCallback("erro");
              if (err.response?.data?.message) {
                return message.error(err.response?.data?.message);
              }
              return message.error(
                "Algo deu errado na integração com o Mercado Bitcoin, verifique suas credenciais"
              );
            },
          });
        },

        3000
      );
    } else {
      if (interval) {
        clearInterval(interval);
      }
    }
    return () => clearInterval(interval as unknown as NodeJS.Timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <>
      <IntegrationButton
        loading={loading}
        integrado={
          integrations.mercadobitcoin?.integrated ||
          integrations.mercadobitcoin?.status === "sucesso"
        }
        logo={mercadoBitcoinLogo}
        alt="Integrar com o Mercado bitcoin"
        setIntegrationModalVisible={setIntegrationModalVisible}
        style={{ height: "100%", width: "auto" }}
      />
      <MercadoBitcoinIntegrationModal
        visible={integrationModalVisible}
        setVisible={setIntegrationModalVisible}
        setLoadingIntegration={setLoading}
      />
    </>
  );
};

export default MercadoBitcoinIntegration;
