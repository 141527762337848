import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { CardContainer } from "./styles";

interface CardProps {
  id: string;
  icon: ReactNode;
  children: ReactNode;
  link?: string;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
}

const Container: React.FC<Pick<CardProps, "link">> = ({ children, link }) => {
  return link && link.includes("http") ? (
    <a href={link} target="_blank" rel="noreferrer">
      {children}
    </a>
  ) : (
    <>{children}</>
  );
};

export const Card = ({
  id,
  icon,
  link,
  children,
  disabled,
  onClick,
  className,
}: CardProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (disabled) return;
    if (onClick) {
      onClick();
    } else if (link && !link.includes("http")) {
      navigate(link);
    }
  };

  return (
    <CardContainer id={id} className={className} onClick={handleClick}>
      <Container link={link}>
        <figure>{icon}</figure>
        <div className="content">{children}</div>
      </Container>
    </CardContainer>
  );
};
