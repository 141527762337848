import { Typography } from "antd";
import { Card } from "../Bolsa/Cards";
import { HomeContainer } from "../Home/styles";
import { itensAutonomos } from "./itensAutonomos";

export const Autonomos = () => {
  return (
    <HomeContainer>
      <div className="content module">
        <div>
          <Typography.Title level={1}>Carnê-leão</Typography.Title>
          <Typography.Text strong className="destak">
            Selecione a opção que deseja utilizar
          </Typography.Text>
          <div className="cards cards-2">
            {itensAutonomos.slice(0, 2).map((item) => (
              <Card
                id={item.id}
                key={item.id}
                link={item.link}
                icon={item.icon}
              >
                {item.content}
                {item.description && (
                  <div className="description">{item.description}</div>
                )}
              </Card>
            ))}
          </div>
        </div>
      </div>
    </HomeContainer>
  );
};
