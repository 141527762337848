import clsx from "clsx";
import moment from "moment-timezone";
import { BsEye } from "react-icons/bs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  AiOutlineCalendar,
  AiOutlineLock,
  AiOutlineUnlock,
} from "react-icons/ai";
import {
  Col,
  List,
  message,
  Row,
  Select,
  Skeleton,
  Space,
  Tooltip,
  Typography,
} from "antd";
import apis from "../../services/apis";
import { DarfBolsa } from "../DarfBolsa";
import Button from "../../components/Button";
import { Page } from "../../constants/brokers";
import HandleTag from "../../services/handleTag";
import { itensBolsa } from "../Bolsa/itensBolsa";
import { useAuth } from "../../contexts/AuthContext";
import { vendasIsentas, vendasTotais } from "./utils";
import { useBroker } from "../../contexts/BrokerContext";
import UploadVelotax from "../../components/UploadVelotax";
import { DrawerModal } from "../../components/DrawerModal";
import { PaymentModal } from "../../components/PaymentModal";
import { PayDarfButton } from "../../components/PayDarfButton";
import { CardHorizontal } from "../../components/CardHorizontal";
import { useB3Integration } from "../../contexts/B3IntegrationContext";
import { useOldTransactions } from "../../contexts/OldTransactionsContext";
import { useNotasCorretagem } from "../IntegrationBolsa/NotasCorretagem/useNotasCorretagem";
import {
  maxCreditCardPayment,
  maxDarfPrice,
  maxPixPayment,
  minDarfPrice,
} from "../../constants/darf";
import {
  mensagemDarfsRetroativos,
  ativarDarfsRetroativos,
  download,
  errorMessage,
  formatCurrency,
  isMobile,
  monthsExtended,
  getLastBusinessDay,
  getInitialYear,
  getZeroBuyPrice,
} from "../../utils";
import { instanceMsCalc } from "../../services/msCalc";
import { NotasHistoricoModal } from "../IntegrationBolsa/NotasHistoricoModal";
import { PayButton } from "./PayButton";
import { ModalZeroBuyPrice } from "../../components/ModalZeroBuyPrice";

const INITIAL_VALID_YEAR = 2020;

interface ShowCalcModal {
  visible: boolean;
  view?: boolean;
  month?: number;
  year?: number;
}

interface HistoricProps {
  item: Page;
}

export const HistoricBolsa: React.FC<HistoricProps> = ({ item }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const { oldPositionList } = useOldTransactions();
  const { user, hasPlan, showUserPlanModal } = useAuth();
  const {
    b3Authorized,
    handleIntegrate,
    interval,
    backgroundIntegration,
    notas: {
      getHistoricoNotasCorretagem,
      setShowHistoricoNotasCorretagemModal,
    },
  } = useB3Integration();
  const { currentPage, integration, integrouHoje, initIntegration } =
    useBroker();
  const {
    sendNotas,
    loadingSend,
    uploadProps,
    showNotasCorretagemModal,
    setShowNotasCorretagemModal,
  } = useNotasCorretagem();

  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();
  const stateYear = (state as any)?.year;
  const initialYear = getInitialYear(currentYear, currentMonth);
  const queryYear = Number(searchParams.get("year"));
  const queryYearValid =
    queryYear >= INITIAL_VALID_YEAR && queryYear <= initialYear
      ? queryYear
      : undefined;
  window.history.replaceState({}, document.title);

  const [data, setData] = useState<any[]>();
  const [holidays, setHolidays] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [emitting, setEmitting] = useState(false);
  const [itemToPay, setItemToPay] = useState<any>();
  const [paymentModal, setPaymentModal] = useState(false);
  const [year, setYear] = useState(queryYearValid ?? stateYear ?? initialYear);
  const [showCalcModal, setShowCalcModal] = useState<ShowCalcModal>({
    visible: false,
  });
  const [ignoreZeroBuyPrice, setIgnoreZeroBuyPrice] = useState(false);
  const [dataZeroBuyPrice, setDataZeroBuyPrice] = useState<any[]>([]);
  const [modalZeroBuyPrice, setModalZeroBuyPrice] = useState(false);

  const planMonthSignature = new Date(
    user?.user?.userPlanInfoVelotax?.currentPlan?.createdAt
  ).getMonth();
  const planYearSignature = new Date(
    user?.user?.userPlanInfoVelotax?.currentPlan?.createdAt
  ).getFullYear();

  const isBasic = user.user?.userPlanInfoVelotax?.type?.includes("BASIC");

  const historicFeature = item.features[0];
  const payFeature = item.features[1];

  const yearOptions = Array.from(Array(initialYear - INITIAL_VALID_YEAR + 1))
    .map((y, i) => ({
      value: INITIAL_VALID_YEAR + i,
      label: `${INITIAL_VALID_YEAR + i}`,
    }))
    .reverse();

  const getHistoric = useCallback(() => {
    setLoading(true);
    (currentPage?.api || apis)
      .get(historicFeature.apiUrl, { params: { year } })
      .then((response) => setData((response.data ?? []).reverse()))
      .catch(() => message.error(errorMessage))
      .finally(() => setLoading(false));
  }, [historicFeature, year, currentPage]);

  const pay = useCallback(
    (payed: boolean, item: any) => {
      setLoading(true);
      (currentPage?.api || apis)
        .put(`${payFeature.apiUrl}/${item._id}`, { payed })
        .then(() => {
          getHistoric();
          setData((data) =>
            (data || []).map((darf) => ({
              ...darf,
              payed: item._id === darf._id ? payed : darf.payed,
            }))
          );
        })
        .catch(() => {
          message.error(errorMessage);
          setLoading(false);
        });
    },
    [payFeature, getHistoric, currentPage]
  );

  // const downloadAction = (record: any, index: number) => {
  //   if (!hasPlan) {
  //     handleOpenPlanModal();
  //   } else {
  //     (currentPage?.api || apis)
  //       .get(`${item.features[2].apiUrl}/${record._id}`)
  //       .then((res) => {
  //         if (res.data.url) {
  //           download(res.data.url);
  //         }
  //       });
  //   }
  // };

  const handleOpenNotas = () => {
    setShowNotasCorretagemModal(true);
  };

  const editAction = (
    record: any,
    view: boolean,
    index: number,
    noPlanMode?: boolean
  ) => {
    if (!hasPlan && !noPlanMode) {
      handleOpenPlanModal();
    } else {
      const { month, year } = record;
      setShowCalcModal({ visible: true, view, month, year });
      if (view) {
        setItemToPay(record);
      }
    }
  };

  const payAction = (value: boolean, record: any) => {
    if (!hasPlan) {
      handleOpenPlanModal();
    } else {
      pay(value, record);
    }
  };

  const onChangeYear = (value: string) => {
    setYear(parseInt(value));
  };

  const handleOpenPlanModal = () => {
    showUserPlanModal(true);
  };

  const isAntesTerceiroDiaUtil = () => {
    const today = moment();
    // Verifica se a data de hoje é um sábado
    if (today.isoWeekday() === 6) {
      return false;
    }
    // Verifica se a data de hoje é anterior ao terceiro dia útil
    const firstDayOfMonth = moment(today).startOf("month");
    let businessDaysCount = 0;
    while (businessDaysCount < 2) {
      if (firstDayOfMonth.isoWeekday() < 6) {
        businessDaysCount++;
      }
      firstDayOfMonth.add(1, "day");
    }
    return today.isBefore(firstDayOfMonth);
  };

  useEffect(() => {
    if (ignoreZeroBuyPrice) handlePayButton(item);
  }, [ignoreZeroBuyPrice]);

  const handlePayButton = async (item: any) => {
    if (!hasPlan || isBasic) {
      handleOpenPlanModal();
    } else {
      if (!ignoreZeroBuyPrice) {
        const hasZeroBuyPrice = await getZeroBuyPrice(item?.memoriaCalculo);
        if (hasZeroBuyPrice.length > 0) {
          setModalZeroBuyPrice(true);
          setDataZeroBuyPrice(hasZeroBuyPrice);
          return;
        }
      }
      getTaxes(item.month, false, false, false, true, false);
      setPaymentModal(!paymentModal);
    }
  };

  const handleDarf = (justSave?: boolean, obj?: any) => {
    const url = "/darf";
    const darfData = obj ? obj : itemToPay;
    const payload = {
      ...darfData,
      memoriaCalculo: [],
      transactions: darfData?.memoriaCalculo,
      totalImpostoDevido: darfData?.impostoTotalFinal,
      impostoDevido: darfData?.impostoTotal,
      juros: darfData?.jurosAux,
      multa: darfData?.multaAux,
      year,
      month: darfData?.month,
      name: user.user.name,
      insertPix: true,
      paymentInfo: {
        clientInfo: {
          name: user?.user?.name,
          email: user?.user?.email,
          document: user?.user?.cpf,
        },
      },
      justSave,
      payed: justSave,
    };
    setEmitting(true);
    (currentPage?.api || apis)
      .post(url, payload)
      .then((res) => {
        getHistoric();
        setEmitting(false);
        if (!justSave) {
          setPaymentModal((paymentModal) => !paymentModal);
          if (res.data?.url) {
            download(res.data?.url);
          } else if (res.data?.darfUrl) {
            download(res.data?.darfUrl);
          }
        }
      })
      .catch((err) => {
        message.error(err.response?.data?.message || errorMessage);
        setEmitting(false);
      });
  };

  const handleOpenDarfModal = () => {
    handleDarf();
  };

  const checkBlockedOption = (maxValue: number) =>
    (itemToPay?.impostoTotalFinal || 0) > maxValue;

  const valorPrincipal = useMemo(
    () =>
      Math.max(
        0,
        Number(
          (itemToPay?.impostoComumFinal || 0) +
            (itemToPay?.impostoDTFinal || 0) +
            (itemToPay?.impostoFIIFinal || 0)
        ) +
          (Number(itemToPay?.["impostoAcumulado"] || 0) -
            Number(itemToPay?.["irrfTotal"] || 0))
      ),
    [itemToPay]
  );

  const impostoCharge = useMemo(
    () =>
      valorPrincipal +
      (valorPrincipal < minDarfPrice
        ? 0
        : (itemToPay?.multaAux ?? 0) + (itemToPay?.jurosAux ?? 0)),
    [itemToPay, valorPrincipal]
  );

  const handleValue = (item: any) => {
    const valorPrincipal = Math.max(
      0,
      Number(
        (item.impostoComumFinal || 0) +
          (item.impostoDTFinal || 0) +
          (item.impostoFIIFinal || 0)
      ) +
        (Number(item["impostoAcumulado"] || 0) - Number(item["irrfTotal"] || 0))
    );
    return (
      valorPrincipal +
      (valorPrincipal < minDarfPrice
        ? 0
        : (item?.multaAux ?? 0) + (item?.jurosAux ?? 0))
    );
  };

  const valueIsGreaterThanMin = (item: any) =>
    Math.max(
      0,
      Number(
        (item?.impostoComumFinal || 0) +
          (item?.impostoDTFinal || 0) +
          (item?.impostoFIIFinal || 0)
      ) +
        (Number(item?.["impostoAcumulado"] || 0) -
          Number(item?.["irrfTotal"] || 0))
    ) >= minDarfPrice;

  const valueIsLessThanMax = (item: any) =>
    (item?.impostoTotalFinal || 0) <= maxDarfPrice;

  const getHolidays = async () => {
    instanceMsCalc
      .get(`/rates/get-holidays`)
      .then(({ data }) => {
        const dataFormated = data?.map((el: any) => {
          return moment(el).tz("Europe/London").format("MM-DD");
        });
        setHolidays(dataFormated);
      })
      .catch(() => console.log("Erro ao buscar feriados"));
  };

  useEffect(() => {
    getHistoric();
    getHolidays();
  }, [getHistoric, year, initIntegration]);

  const getTaxes = useCallback(
    (
      month: number,
      nAtualizarDarfsPassadas?: boolean,
      nAtualizarIrrf?: boolean,
      atualizouImpostoAcumulado?: boolean,
      nAtualizarPrejuizosAcumulados?: boolean,
      atualizarTotalmente?: boolean,
      callback?: (obj: any) => void
    ) => {
      (currentPage?.api || apis)
        .get("/bolsa/transaction", {
          params: {
            month,
            year,
            nAtualizarDarfsPassadas: nAtualizarDarfsPassadas || false,
            nAtualizarIrrf: nAtualizarIrrf || false,
            atualizouImpostoAcumulado: atualizouImpostoAcumulado || false,
            nAtualizarPrejuizosAcumulados:
              nAtualizarPrejuizosAcumulados || false,
            atualizarTotalmente: atualizarTotalmente || false,
          },
        })
        .then((response) => {
          if (response.data.memoriaCalculo) {
            const data = {
              ...response.data,
              year,
              month,
              multa: response.data.multaAux,
              juros: response.data.jurosAux,
              impostoDevido: response.data.impostoTotal,
              name: user.user.name,
              memoriaCalculo: [],
              transactions: response.data.memoriaCalculo.map(
                (memoCalc: any) => ({
                  ...memoCalc,
                  date: moment(memoCalc.date.slice(0, 10)).format("DD/MM/YYYY"),
                  originalDate: memoCalc.date,
                  market:
                    memoCalc.market === "Opção de Compra" ||
                    memoCalc.market === "Opção de Venda"
                      ? "Mercado de opções"
                      : memoCalc.market,
                })
              ),
            };
            setItemToPay(data);
            callback?.(data);
          }
        })
        .catch(() => {});
    },
    [year, currentPage, user.user.name]
  );

  const handleCloseCalcModal = () => {
    setShowCalcModal({ visible: false });
    getHistoric();
  };

  const showPayButton = (item: any) =>
    valueIsGreaterThanMin(item) &&
    valueIsLessThanMax(item) &&
    (item.status !== "PAYED" || !item.payed);

  useEffect(() => {
    if (
      user.user &&
      b3Authorized &&
      !integrouHoje &&
      !interval.current &&
      integration.key !== "unset"
    ) {
      handleIntegrate({ integrationInBackground: true });
    }
  }, [
    interval,
    user.user,
    integrouHoje,
    b3Authorized,
    handleIntegrate,
    integration.key,
  ]);

  const backgroundIntegrationDisabled = (item: any) =>
    backgroundIntegration.loading &&
    (item.year > backgroundIntegration.year ||
      (item.year === backgroundIntegration.year &&
        item.month >= backgroundIntegration.month));

  const disableCurrentMonth = (item: any) =>
    item.year === currentYear && item.month === currentMonth + 1;

  return (
    <div className="velotax-container">
      <div className="velotax-content historic">
        <Typography.Title level={1} className="page-title">
          Impostos devidos (DARF)
          {false && !hasPlan && (
            <Button
              size="large"
              type="primary"
              onClick={handleOpenPlanModal}
              className="btn-contratar-plano-historic">
              Contratar plano
              <AiOutlineUnlock />
            </Button>
          )}
        </Typography.Title>
        <Select
          size="large"
          options={yearOptions}
          value={year.toString()}
          onChange={onChangeYear}
          className="year-select"
          placeholder="Selecione o ano"
          suffixIcon={<AiOutlineCalendar size={24} />}
          getPopupContainer={(target) => target.parentElement}
        />

        <List
          loading={loading}
          dataSource={
            data?.filter(
              (item) =>
                !(item.year === currentYear && item.month > currentMonth + 1)
            ) ?? []
          }
          itemLayout="horizontal"
          className={clsx("velotax-historic-list", { "no-plan": !hasPlan })}
          renderItem={(item, index) => {
            const ultimoDiaUtilDoMes = getLastBusinessDay(
              new Date(item.year, item.month + 1, 0),
              holidays
            );

            const vencimento = moment(ultimoDiaUtilDoMes).format("DD/MM/YYYY");
            const currentDarfDate = new Date(year, item.month - 1, 1);
            const month =
              planMonthSignature === 0 ? 11 : planMonthSignature - 1;
            const yearSign =
              planMonthSignature === 0
                ? planYearSignature - 1
                : planYearSignature;

            const dateAllowedOnBasic = new Date(yearSign, month, 1);
            const checkMonth =
              currentDarfDate.getTime() < dateAllowedOnBasic.getTime();
            const hideOnBasic = isBasic && checkMonth;
            const disabled = backgroundIntegrationDisabled(item);

            return (
              <List.Item
                actions={
                  !hasPlan
                    ? !b3Authorized &&
                      user?.user?.skippedB3Integration &&
                      !isMobile()
                      ? [
                          <Button
                            type="link"
                            icon={<BsEye size={16} />}
                            disabled={disabled}
                            onClick={() => editAction(item, true, index, true)}>
                            <strong>Visualizar</strong>
                          </Button>,
                        ]
                      : []
                    : !isMobile()
                    ? [
                        showPayButton(item) && (
                          <PayButton
                            onClick={() => handlePayButton(item)}
                            title={
                              disableCurrentMonth(item)
                                ? "Aguarde o encerramento do mês para pagar seu DARF"
                                : item?.month === currentMonth &&
                                  isAntesTerceiroDiaUtil()
                                ? "Aguarde o terceiro dia útil deste mês para pagar seu DARF"
                                : ""
                            }
                            disabled={
                              disableCurrentMonth(item) ||
                              disabled ||
                              (item?.month === currentMonth &&
                                isAntesTerceiroDiaUtil())
                            }
                          />
                        ),
                        <Button
                          type="link"
                          icon={<BsEye size={16} />}
                          disabled={disabled}
                          onClick={() =>
                            editAction(
                              item,
                              item.status === "PAYED" &&
                                valueIsGreaterThanMin(item),
                              index
                            )
                          }>
                          {showPayButton(item) ? (
                            ""
                          ) : (
                            <strong>Visualizar</strong>
                          )}
                        </Button>,
                        // item.fullPath && (
                        //   <Button
                        //     type="link"
                        //     icon={<BsDownload size={16} />}
                        //     onClick={() => downloadAction(item, index)}
                        //   />
                        // ),
                      ]
                    : []
                }>
                <Skeleton title={false} loading={item.loading} active>
                  <List.Item.Meta
                    title={
                      <>
                        {monthsExtended[item.month - 1]}
                        {!isMobile() ? ` de ${item.year}` : ""}
                        {!hasPlan &&
                          item.transactions?.length > 0 &&
                          !disabled &&
                          isMobile() && (
                            <Button
                              type="link"
                              onClick={() =>
                                editAction(item, true, index, true)
                              }
                              icon={
                                <BsEye
                                  size={16}
                                  style={{
                                    minWidth: "24px",
                                    minHeight: "24px",
                                    marginLeft: "12px",
                                    strokeWidth: "0.2px",
                                    fill: "var(--ant-primary-color)",
                                  }}
                                />
                              }
                            />
                          )}
                        {hasPlan ? (
                          <div
                            style={{ display: "flex", alignItems: "center" }}>
                            {valueIsGreaterThanMin(item) &&
                            !disableCurrentMonth(item) &&
                            !hideOnBasic &&
                            !disabled ? (
                              <PayDarfButton
                                checked={item.payed}
                                checkedChildren="Pago"
                                unCheckedChildren="Em aberto"
                                ativarDarfsRetroativos={ativarDarfsRetroativos({
                                  item,
                                  hasPlan,
                                  currentYear,
                                  currentMonth,
                                  planMonthSignature:
                                    planMonthSignature === 0
                                      ? 12
                                      : planMonthSignature,
                                  planYearSignature:
                                    planMonthSignature === 0
                                      ? planYearSignature - 1
                                      : planYearSignature,
                                })}
                                onChange={() => {
                                  if (
                                    !ativarDarfsRetroativos({
                                      item,
                                      hasPlan,
                                      currentYear,
                                      currentMonth,
                                      planMonthSignature:
                                        planMonthSignature === 0
                                          ? 12
                                          : planMonthSignature,
                                      planYearSignature:
                                        planMonthSignature === 0
                                          ? planYearSignature - 1
                                          : planYearSignature,
                                    })
                                  ) {
                                    showUserPlanModal(true);
                                  } else if (
                                    item._id &&
                                    item.institution !== "b3"
                                  ) {
                                    payAction(!item.payed, item);
                                  } else {
                                    getTaxes(
                                      item.month,
                                      false,
                                      false,
                                      false,
                                      true,
                                      false,
                                      (obj: any) => {
                                        handleDarf(true, obj);
                                      }
                                    );
                                  }
                                }}
                              />
                            ) : undefined}
                            {isMobile() && !disabled && (
                              <Button
                                type="link"
                                icon={
                                  <BsEye
                                    size={16}
                                    style={{
                                      minWidth: "24px",
                                      minHeight: "24px",
                                      marginLeft: "12px",
                                      strokeWidth: "0.2px",
                                      fill: "var(--ant-primary-color)",
                                    }}
                                  />
                                }
                                onClick={() =>
                                  editAction(
                                    item,
                                    item.status === "PAYED" &&
                                      valueIsGreaterThanMin(item),
                                    index
                                  )
                                }
                              />
                            )}
                          </div>
                        ) : undefined}
                      </>
                    }
                    description={
                      <Space direction="vertical">
                        {!hasPlan || hideOnBasic ? (
                          <>
                            {item.transactions?.length > 0 || disabled ? (
                              <>
                                <Typography.Text>
                                  <span>Vendas totais: </span>
                                  <strong>
                                    {disabled ? (
                                      <>
                                        <Typography.Text
                                          style={{
                                            fontSize: "14px",
                                            marginRight: "12px",
                                          }}>
                                          Atualizando...
                                        </Typography.Text>
                                        <LoadingOutlined />
                                      </>
                                    ) : (
                                      formatCurrency(vendasTotais(item))
                                    )}
                                  </strong>
                                </Typography.Text>
                                <Typography.Text className="no-plan-imposto-a-pagar">
                                  <span>Imposto a pagar: </span>
                                  <Button
                                    type="link"
                                    disabled={disabled}
                                    onClick={() => {
                                      handleOpenPlanModal();
                                    }}>
                                    <strong>
                                      Clique para verificar&nbsp;
                                      <AiOutlineLock size={14} />
                                    </strong>
                                  </Button>
                                </Typography.Text>
                              </>
                            ) : (
                              <Typography.Text className="no-transactions">
                                Não houve movimentações tributáveis neste mês
                              </Typography.Text>
                            )}
                          </>
                        ) : (
                          <>
                            {item.transactions?.length > 0 ? (
                              <>
                                <Typography.Text>
                                  <span>Vendas totais: </span>
                                  <strong>
                                    {disabled ? (
                                      <>
                                        <Typography.Text
                                          style={{
                                            fontSize: "14px",
                                            marginRight: "12px",
                                          }}>
                                          Atualizando...
                                        </Typography.Text>
                                        <LoadingOutlined />
                                      </>
                                    ) : (
                                      formatCurrency(vendasTotais(item))
                                    )}
                                  </strong>
                                </Typography.Text>
                                {disableCurrentMonth(item) && (
                                  <Typography.Text>
                                    <span>Vendas isentas: </span>
                                    <strong>
                                      {disabled ? (
                                        <>
                                          <Typography.Text
                                            style={{
                                              fontSize: "14px",
                                              marginRight: "12px",
                                            }}>
                                            Atualizando...
                                          </Typography.Text>
                                          <LoadingOutlined />
                                        </>
                                      ) : (
                                        <>
                                          {formatCurrency(vendasIsentas(item))}
                                          &nbsp;
                                          <Tooltip title="Vendas mensais de até R$ 20 mil no mercado comum são isentas de imposto de renda">
                                            <InfoCircleOutlined />
                                          </Tooltip>
                                        </>
                                      )}
                                    </strong>
                                  </Typography.Text>
                                )}
                                {!ativarDarfsRetroativos({
                                  item,
                                  hasPlan,
                                  currentYear,
                                  currentMonth,
                                  planMonthSignature:
                                    planMonthSignature === 0
                                      ? 12
                                      : planMonthSignature,
                                  planYearSignature:
                                    planMonthSignature === 0
                                      ? planYearSignature - 1
                                      : planYearSignature,
                                }) ? (
                                  <Typography.Text className="no-plan-imposto-a-pagar">
                                    <span>Imposto a pagar: </span>
                                    <Button
                                      type="link"
                                      disabled={backgroundIntegrationDisabled(
                                        item
                                      )}
                                      onClick={() => {
                                        handleOpenPlanModal();
                                      }}>
                                      <strong>
                                        Clique para verificar&nbsp;
                                        <AiOutlineLock size={14} />
                                      </strong>
                                    </Button>
                                  </Typography.Text>
                                ) : (
                                  <Typography.Text>
                                    <span>Imposto a pagar: </span>
                                    <span className="tax-value">
                                      {disabled ? (
                                        <>
                                          <Typography.Text
                                            style={{
                                              fontSize: "14px",
                                              marginRight: "12px",
                                            }}>
                                            Atualizando...
                                          </Typography.Text>
                                          <LoadingOutlined />
                                        </>
                                      ) : handleValue(item) > 0 ? (
                                        formatCurrency(handleValue(item))
                                      ) : (
                                        "Isento"
                                      )}
                                    </span>
                                  </Typography.Text>
                                )}
                                {handleValue(item) > 0 &&
                                  ativarDarfsRetroativos({
                                    item,
                                    hasPlan,
                                    currentYear,
                                    currentMonth,
                                    planMonthSignature:
                                      planMonthSignature === 0
                                        ? 12
                                        : planMonthSignature,
                                    planYearSignature:
                                      planMonthSignature === 0
                                        ? planYearSignature - 1
                                        : planYearSignature,
                                  }) && (
                                    <Typography.Text>
                                      <span>Vencimento: </span>
                                      <span>
                                        {disabled ? (
                                          <>
                                            <Typography.Text
                                              style={{
                                                fontSize: "14px",
                                                marginRight: "12px",
                                              }}>
                                              Atualizando...
                                            </Typography.Text>
                                            <LoadingOutlined />
                                          </>
                                        ) : (
                                          vencimento
                                        )}
                                      </span>
                                    </Typography.Text>
                                  )}
                                {isMobile() && showPayButton(item) && (
                                  <PayButton
                                    fullWidth
                                    onClick={() => handlePayButton(item)}
                                    title={
                                      disableCurrentMonth(item)
                                        ? "Aguarde o encerramento do mês para pagar seu DARF"
                                        : item?.month === currentMonth &&
                                          isAntesTerceiroDiaUtil()
                                        ? "Aguarde o terceiro dia útil deste mês para pagar seu DARF"
                                        : ""
                                    }
                                    disabled={
                                      disableCurrentMonth(item) ||
                                      disabled ||
                                      (item?.month === currentMonth &&
                                        isAntesTerceiroDiaUtil())
                                    }
                                  />
                                )}
                              </>
                            ) : (
                              <Typography.Text>
                                {disabled ? (
                                  <>
                                    <Col style={{ width: "100%" }}>
                                      <Typography.Text>
                                        <span>Vendas totais: </span>
                                        <strong>
                                          {disabled ? (
                                            <>
                                              <Typography.Text
                                                style={{
                                                  fontSize: "14px",
                                                  marginRight: "12px",
                                                }}>
                                                Atualizando...
                                              </Typography.Text>
                                              <LoadingOutlined />
                                            </>
                                          ) : (
                                            formatCurrency(vendasTotais(item))
                                          )}
                                        </strong>
                                      </Typography.Text>
                                    </Col>

                                    <Col style={{ width: "100%" }}>
                                      {" "}
                                      <Typography.Text>
                                        <span>Imposto a pagar: </span>
                                        <span className="tax-value">
                                          {disabled ? (
                                            <>
                                              <Typography.Text
                                                style={{
                                                  fontSize: "14px",
                                                  marginRight: "12px",
                                                }}>
                                                Atualizando...
                                              </Typography.Text>
                                              <LoadingOutlined />
                                            </>
                                          ) : handleValue(item) > 0 ? (
                                            formatCurrency(handleValue(item))
                                          ) : (
                                            "Isento"
                                          )}
                                        </span>
                                      </Typography.Text>
                                    </Col>
                                  </>
                                ) : (
                                  <Typography.Text className="no-transactions">
                                    Não houve movimentações tributáveis neste
                                    mês
                                  </Typography.Text>
                                )}
                              </Typography.Text>
                            )}
                          </>
                        )}
                      </Space>
                    }
                  />
                </Skeleton>
              </List.Item>
            );
          }}
        />

        <Typography.Title level={2} className="subtitle-lined">
          <span>Outras opções</span>
        </Typography.Title>

        <div>
          {[...itensBolsa.slice(1)]
            .filter(
              (item) =>
                item.id !== "insert-manual" || oldPositionList?.length > 0
            )
            .filter((item) => (b3Authorized ? item.id !== "integration" : true))
            .map((item) => (
              <CardHorizontal
                id={item.id}
                key={item.id}
                icon={item.icon}
                link={item.link}
                className={clsx({ "no-plan": !hasPlan })}
                onClick={
                  item.id === "integration"
                    ? () => navigate("/velotax/bolsa-integration")
                    : item.id === "brokerage-note"
                    ? () => handleOpenNotas()
                    : hasPlan && !isBasic
                    ? undefined
                    : () => {
                        handleOpenPlanModal();
                      }
                }>
                {(hasPlan && !isBasic) ||
                item.id === "integration" ||
                item.id === "brokerage-note" ? (
                  <>{item.content}</>
                ) : (
                  <Row align="middle" justify="space-between" gutter={[8, 8]}>
                    <Col>{item.content}</Col>
                    <Button
                      type="primary"
                      className="btn-premium"
                      icon={<AiOutlineLock size={18} />}
                      onClick={() => {
                        handleOpenPlanModal();
                      }}>
                      PREMIUM
                    </Button>
                  </Row>
                )}
              </CardHorizontal>
            ))}
        </div>
      </div>
      <PaymentModal
        item={item}
        show={paymentModal}
        emitting={emitting}
        selectedYear={year}
        maxPixValue={maxPixPayment}
        maxCCValue={maxCreditCardPayment}
        openPlanModal={handleOpenPlanModal}
        disablePixOption={checkBlockedOption(maxPixPayment)}
        disableCreditCardOption={checkBlockedOption(maxCreditCardPayment)}
        closeDarfModal={() => {
          setPaymentModal(false);
          getHistoric();
        }}
        onCancel={() => {
          setPaymentModal(!paymentModal);
        }}
        callDarf={() => {
          handleOpenDarfModal();
          HandleTag("76");
        }}
        paymentData={{
          ...itemToPay,
          impostoTotalFinal: impostoCharge,
        }}
      />
      <DrawerModal
        title=""
        width={700}
        className="darf-modal"
        visible={showCalcModal.visible}
        onCancel={handleCloseCalcModal}
        bodyStyle={{
          padding: "0",
        }}>
        <DarfBolsa
          view={showCalcModal.view}
          viewEdit={!showCalcModal.view}
          closeModal={handleCloseCalcModal}
          darf={
            hasPlan && !isBasic && showCalcModal.view
              ? {
                  ...itemToPay,
                  id: itemToPay.id ?? itemToPay._id,
                }
              : undefined
          }
          date={{
            year: showCalcModal.year,
            month: showCalcModal.month ? showCalcModal.month - 1 : 0,
          }}
          item={{
            month: showCalcModal?.month,
            year: showCalcModal?.year,
            header: true,
            sidebar: true,
            path: "/velotax/bolsa-darf",
            name: "Calculadora",
            title: "",
            settings: {
              integrationPath: "/velotax/bolsa-integration",
              showNavigateToIntegrationButton: false,
            },
            features: [
              {
                apiUrl: "/bolsa/transaction",
              },
              {
                apiUrl: "/darf",
                buttonLabel: "Emitir DARF",
              },
              {
                apiUrl: "/darf/year",
              },
              {
                apiUrl: "/velotax/prejuizos",
              },
              {
                apiUrl: "/velotax/irrf",
              },
            ],
          }}
        />
      </DrawerModal>
      <DrawerModal
        destroyOnClose={true}
        footer={null}
        visible={showNotasCorretagemModal}
        title="Carregar notas de corretagem"
        onCancel={() => {
          if (!loadingSend) {
            setShowNotasCorretagemModal(false);
          }
        }}>
        <Typography.Paragraph>
          <InfoCircleOutlined /> Você pode carregar as notas de corretagem para
          verificar o imposto de renda sobre operações realizadas no mercado de
          balcão
        </Typography.Paragraph>
        <UploadVelotax loading={loadingSend} {...(uploadProps as any)} />
        <Row justify="end">
          <Col>
            <Button
              size="large"
              color="secondary"
              // variant="contained"
              disabled={loadingSend}
              onClick={() => sendNotas()}>
              Finalizar
            </Button>
          </Col>
        </Row>
        <Typography.Link
          style={{
            display: "block",
            margin: "32px auto",
            textAlign: "center",
            textDecoration: "underline",
          }}
          onClick={() => {
            setShowHistoricoNotasCorretagemModal(true);
            getHistoricoNotasCorretagem({ filters: {} });
          }}>
          Histórico de notas
        </Typography.Link>
      </DrawerModal>
      <ModalZeroBuyPrice
        visible={modalZeroBuyPrice}
        title={"Atenção"}
        dataZeroBuyPrice={dataZeroBuyPrice}
        setIgnoreZeroBuyPrice={setIgnoreZeroBuyPrice}
        setModalZeroBuyPrice={setModalZeroBuyPrice}></ModalZeroBuyPrice>
      <NotasHistoricoModal />
    </div>
  );
};
