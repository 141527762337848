import { LoadingOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Button from "../Button";
import { useAuth } from "../../contexts/AuthContext";

interface ReCaptchaProps {
  token?: string;
  setToken: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const ReCaptcha: React.FC<ReCaptchaProps> = ({ token, setToken }) => {
  const { disabledRecaptcha } = useAuth();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [loading, setLoading] = useState(true);

  const handleReCaptchaVerify = useCallback(async () => {
    setLoading(true);
    if (!executeRecaptcha) {
      setLoading(false);
      return;
    }
    const token = await executeRecaptcha("yourAction");
    setLoading(false);
    setToken(token);
  }, [executeRecaptcha, setToken]);

  useEffect(() => {
    if (!disabledRecaptcha) {
      setTimeout(() => {
        handleReCaptchaVerify();
      }, 500);
    }
  }, [disabledRecaptcha, handleReCaptchaVerify]);

  return !disabledRecaptcha && !token ? (
    <Button
      size="large"
      onClick={handleReCaptchaVerify}
      style={{ margin: "0 auto" }}
    >
      {loading ? <LoadingOutlined /> : "Não sou um robô"}
    </Button>
  ) : (
    <></>
  );
};
