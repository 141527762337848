import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const OnlyPublicRoute: React.FC = () => {
  const { signed, loadingSession, cameFromRegister, fromCheckout } = useAuth();

  if (loadingSession) {
    return <></>;
  }

  return !signed ? (
    <Outlet />
  ) : cameFromRegister.bolsa ? (
    // <Navigate to="/velotax/bolsa-integration" /> // Manter código comentado para lembrar da auteração feita para o IRPF
    <Navigate to="/declaracao-irpf" />
  ) : fromCheckout ? (
    <Navigate to="/sucesso2" />
  ) : (
    <Navigate to="/" />
  );
};

export default OnlyPublicRoute;
