import clsx from "clsx";
import moment from "moment";
import { FaRegEdit } from "react-icons/fa";
import { BsCalendar3 } from "react-icons/bs";
import NumberFormat from "react-number-format";
import { useLocation } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { InfoCircleOutlined } from "@ant-design/icons";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { AiOutlineDelete, AiOutlineLock } from "react-icons/ai";
import {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
  ReactNode,
} from "react";
import {
  Typography,
  Row,
  Col,
  DatePicker,
  Space,
  Descriptions,
  Spin,
  InputNumber,
  Collapse,
  List,
  Skeleton,
  Tooltip,
  message,
} from "antd";
import MsDarf from "../../services/msDarf";
import Button from "../../components/Button";
import { Page } from "../../constants/brokers";
import { useAuth } from "../../contexts/AuthContext";
import { FormModal } from "../../components/FormModal";
import { apiPayment } from "../../services/apiPayment";
import apiRendimentos from "../../services/rendimentos";
import { useBroker } from "../../contexts/BrokerContext";
import { DrawerModal } from "../../components/DrawerModal";
import { IMonthStock, Iimpostos } from "./interfaces/index";
import rendimentosBackend from "../../services/rendimentos";
import { PaymentModal } from "../../components/PaymentModal";
import { Container, Content, BolsaOperations } from "./styles";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import {
  ImpostoTotalRendimentos,
  ModalAutonomos,
  ModalDependentes,
  ModalPensao,
  ModalPrevidencia,
  RendimentoFormItemRows,
  RendimentosImpostosDevidos,
} from "../../constants/rendimentos";
import {
  formatCurrency,
  monthsExtended,
  antDatePickerLocale,
  maskCpf,
  currencyToNumber,
  download,
  errorMessage,
} from "../../utils";
import {
  minDarfPrice,
  maxDarfPrice,
  maxPixPayment,
  maxCreditCardPayment,
  yearConfigPL4173
} from "../../constants/darf";

interface DarfBolsaProps {
  item: Page;
  yearView: number;
  monthView: number;
  closeModal: () => void;
  view?: boolean;
  viewEdit?: boolean;
  darf?: {
    id: string;
  };
  allow?: boolean,
}

export const Rendimentos: React.FC<DarfBolsaProps> = ({
  darf,
  view,
  viewEdit,
  yearView,
  monthView,
  closeModal,
  item: itemprops,
  allow
}) => {
  const { state } = useLocation();
  const { currentBroker } = useBroker();
  const { user, showUserPlanModal, userPlanModal, hasPlan } = useAuth();

  const today = new Date();
  const queryYear = (state as any)?.year as number;
  const queryMonth = (state as any)?.month as number;
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();
  const oldModel = queryYear < yearConfigPL4173;

  const initialMonth = queryMonth
    ? queryMonth - 1
    : typeof monthView === "number"
    ? monthView
    : today.getMonth();
  const initialYear = queryYear
    ? queryYear
    : typeof yearView === "number"
    ? yearView
    : today.getFullYear();
  const defaultValue = moment({ month: initialMonth, year: initialYear });
  const currentDate = moment({
    month: currentMonth,
    year: currentYear,
  });
  window.history.replaceState({}, document.title);

  const isSaveItens = useRef(false);
  const [id, setId] = useState<string>("");
  const [year, setYear] = useState(initialYear);
  const [loading, setLoading] = useState(false);
  const [itemIndex, setItemIndex] = useState(0);
  const [emitting, setEmitting] = useState(false);
  const [month, setMonth] = useState(initialMonth);
  const [helpModal, setHelpModal] = useState<any>();
  const [paymentData, setPaymentData] = useState<any>();
  const [paymentModal, setPaymentModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [monthStock, setMonthStock] = useState<IMonthStock[]>([]);
  const [datePickerYear, setDatePickerYear] = useState(initialYear);
  const [showRendimentoModal, setShowRendimentoModal] = useState(false);
  const [loadingDarfButton, setLoadingDarfButton] = useState<boolean>(false);
  const [item, setItem] = useState<any>({
    valorRendimento: 0,
    tipo: "",
  });
  const [impostos, setImpostos] = useState({
    totalRendimentos: 0,
    baseTributaria: 0,
    aliquotaDevida: 0,
    impostoDevido: 0,
    impostoTotal: 0,
    totalDeducoes: 0,
    totalDependentes: 0,
    totalDespesas: 0,
    totalPensao: 0,
    totalPrevidencia: 0,
    impostoAcumulado: 0,
    juros: 0,
    multa: 0,
    gastosExterior: 0,
    payed: false,
    emitted: false,
  });

  const valorImpostoTotal =
    Number(
      Math.max(
        0,
        impostos.impostoDevido +
          (impostos.impostoAcumulado || 0) -
          (impostos.gastosExterior || 0) +
          (impostos.multa || 0) +
          (impostos.juros || 0)
      )
    ) ?? 0;


  console.log(impostos.impostoDevido,
    (impostos.impostoAcumulado || 0),
    (impostos.gastosExterior || 0),
    (impostos.multa || 0),
    (impostos.juros || 0));

  const handlePlanModal = () => {
    showUserPlanModal(!userPlanModal);
  };

  const handleSaveMonth = () => {
    handleEventDarfButton(true);
  };

  const handleEventDarfButton = async (justSave?: boolean) => {
    setLoadingDarfButton(true);
    let retPlanInfo = {
      data: {
        active: false,
        type: "",
      },
    };
    try {
      retPlanInfo = await apiPayment.get("/user-plan/plan-info");
    } catch (err) {
      console.error(err);
    }

    !retPlanInfo.data?.active && !retPlanInfo.data?.type
      ? handlePlanModal()
      : justSave
      ? generateDarf(true, true)
      : impostos.impostoDevido || 0 <= maxDarfPrice
      ? handleOpenPaymentModal()
      : handleOpenDarfModal();

    setLoadingDarfButton(false);
  };

  const handleLabelButton: ReactNode = useMemo(() => {
    if ((impostos?.impostoDevido || 0) <= maxDarfPrice) return "Pagar DARF";
    else return "Emitir DARF";
  }, [impostos?.impostoDevido]);

  const ActionButtons = (
    <Col span={24}>
      {!view && (
        <Row gutter={[16, 16]} justify="end" className="column-reverse">
          {!(month === currentMonth && year === currentYear) &&
            (valorImpostoTotal ?? 0) < minDarfPrice &&
            (!view
              ? monthStock.filter((m) => m.tipo !== "Pensão Alimentícia")
              : monthStock
            )?.length > 0 && (
              <Col xs={24}>
                <br />
                <Tooltip
                  title={
                    <>
                      O valor do imposto devido é menor que{" "}
                      {formatCurrency(minDarfPrice)}. Você não precisa emitir
                      uma DARF para este mês. Clique aqui para regularizar este
                      mês.
                    </>
                  }
                >
                  <Button
                    block
                    size="large"
                    type="primary"
                    loading={emitting || loadingDarfButton}
                    disabled={loading || emitting}
                    onClick={() => {
                      handleRegularize(true);
                    }}
                  >
                    Salvar sem emitir DARF
                  </Button>
                </Tooltip>
              </Col>
            )}
          {valorImpostoTotal >= minDarfPrice && allow && (
            <Col xs={24}>
              <br />
              <Tooltip
                getPopupContainer={(target) => target.parentElement!}
                title={
                  month === currentMonth && year === currentYear
                    ? "Aguarde o encerramento do mês para pagar seu DARF"
                    : ""
                }
              >
                <Button
                  block
                  size="large"
                  type="primary"
                  loading={emitting || loadingDarfButton}
                  onClick={() => handleEventDarfButton()}
                  disabled={
                    loading ||
                    emitting ||
                    (month === currentMonth && year === currentYear)
                  }
                >
                  {handleLabelButton}
                </Button>{" "}
              </Tooltip>
            </Col>
          )}
        </Row>
      )}
      {/* {!(month === currentMonth && year === currentYear) && (
        <Row gutter={[16, 16]} justify="end" align="middle">
          <Col>
            <Button
              type="link"
              size="large"
              onClick={handleSaveMonth}
              disabled={loading || emitting || loadingDarfButton}
            >
              Salvar apenas
            </Button>
          </Col>
        </Row>
      )} */}
    </Col>
  );

  const buildCalcTaxBody = useCallback(() => {
    return {
      numeroDependentes: impostos.totalDependentes,
      gastosAutonomos: impostos.totalDespesas,
      gastosPrevidencia: impostos.totalPrevidencia,
      gastosPensao: impostos.totalPensao,
      gastosExterior: impostos.gastosExterior,
    };
  }, [
    impostos.totalDependentes,
    impostos.totalDespesas,
    impostos.totalPensao,
    impostos.totalPrevidencia,
    impostos.gastosExterior,
  ]);

  const calcTaxes = useCallback(async () => {
    setLoading(true);
    if (!view) {
      const deducoes = buildCalcTaxBody();
      const response = await rendimentosBackend.post("/rendimentos/calc-tax", {
        ...deducoes,
        rendimentos: monthStock,
        year,
        month: month + 1,
        impostoAcumulado: impostos.impostoAcumulado,
        gastosExterior: impostos.gastosExterior,
      });
      const { data } = response;
      setImpostos((currentValue) => {
        return {
          ...currentValue,
          aliquotaDevida: data.aliquotaDevida,
          baseTributaria: data.baseTributaria,
          impostoDevido: data.impostoDevido,
          impostoTotal: data.impostoTotal,
          juros: data.juros,
          multa: data.multa,
          totalDeducoes: data.totalDeducoes,
          totalRendimentos: data.totalRendimentos,
        };
      });
    }
    setLoading(false);
  }, [
    view,
    buildCalcTaxBody,
    monthStock,
    year,
    month,
    impostos.impostoAcumulado,
    impostos.gastosExterior,
  ]);

  const setPayData = useCallback(() => {
    const valueToCharge = Math.max(
      0,
      (impostos?.impostoDevido || 0) +
        (impostos.impostoAcumulado || 0) -
        (impostos.gastosExterior || 0) +
        (impostos.juros || 0) +
        (impostos.multa || 0)
    );

    const dataToPayment = {
      cpf: user.user.cpf,
      name: user.user.name,
      valueCharge: valueToCharge,
      institution: "velotax-rendimentos",
      impostoDevido: impostos.impostoDevido,
      totalImpostoDevido: impostos.impostoTotal,
      impostoTotalFinal: valueToCharge,
      totalRendimentos: impostos.totalRendimentos,
      baseTributaria: impostos.baseTributaria,
      totalDependentes: impostos.totalDependentes,
      totalDespesas: impostos.totalDespesas,
      totalPrevidencia: impostos.totalPrevidencia,
      totalPensao: impostos.totalPensao,
      totalDeducoes: impostos.totalDeducoes,
      aliquotaDevida: impostos.aliquotaDevida,
      impostoAcumulado: impostos.impostoAcumulado,
      gastosExterior: impostos.gastosExterior,
      juros: impostos.juros,
      multa: impostos.multa,
      transactions: monthStock,
      year,
      month: month + 1,
    };

    setPaymentData(dataToPayment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [impostos, month, year]);

  const getRendimentos = useCallback(async () => {
    setLoading(true);
    const response = await rendimentosBackend.get(
      `/rendimentos/${year}/${month + 1}`
    );
    const { data } = response;
    setId(data._id || "");
    setMonthStock(data.monthStock || []);
    const currentImpostos = {
      totalRendimentos: data?.totalRendimentos || 0,
      baseTributaria: data?.baseTributaria || 0,
      aliquotaDevida: data?.aliquotaDevida || 0,
      impostoDevido: data?.impostoDevido || 0,
      totalDeducoes: data?.totalDeducoes || 0,
      totalDependentes: data?.totalDependentes || 0,
      totalDespesas: data?.totalDespesas || 0,
      totalPensao: data?.totalPensao || 0,
      totalPrevidencia: data?.totalPrevidencia || 0,
      juros: data?.juros || 0,
      multa: data?.multa || 0,
      impostoTotal:
        (data.impostoDevido || 0) + (data.juros || 0) + (data.multa || 0),
      impostoAcumulado: data?.impostoAcumulado || 0,
      gastosExterior: data?.gastosExterior || 0,
      payed: data?.payed || false,
      emitted: data?.emitted || false,
    };
    setImpostos(currentImpostos);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, year]);

  const getRendimento = useCallback(async () => {
    setLoading(true);
    const response = await rendimentosBackend.get("/darf/getById", {
      params: { id: darf?.id },
    });
    const { data } = response;
    setMonthStock(data.transactions || []);
    const currentImpostos = {
      totalRendimentos: data.totalRendimentos || 0,
      baseTributaria: data.baseTributaria || 0,
      aliquotaDevida: data.aliquotaDevida || 0,
      impostoDevido: data.impostoDevido || 0,
      totalDeducoes: data.totalDeducoes || 0,
      totalDependentes: data.totalDependentes || 0,
      totalDespesas: data.totalDespesas || 0,
      totalPensao: data.totalPensao || 0,
      totalPrevidencia: data.totalPrevidencia || 0,
      juros: data.juros || 0,
      multa: data.multa || 0,
      impostoAcumulado: data.impostoAcumulado || 0,
      impostoTotal:
        (data.impostoDevido || 0) + (data.juros || 0) + (data.multa || 0),
      gastosExterior: data.gastosExterior || 0,
      payed: data.payed || false,
      emitted: data.emitted || false,
    };
    setImpostos(currentImpostos);
    setLoading(false);
  }, [darf]);

  useEffect(() => {
    setPayData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPayData, impostos]);

  useEffect(() => {
    if (view && darf) getRendimento();
    else getRendimentos();
  }, [getRendimentos, getRendimento, view, darf]);

  const buildNewImpostos = useCallback(() => {
    return {
      monthStock,
      year,
      month: month + 1,
      juros: impostos.juros,
      multa: impostos.multa,
      userCode: user.user.cpf,
      total: impostos.impostoTotal,
      impostoDevido: impostos.impostoDevido,
      totalRendimentos: impostos.totalRendimentos,
      baseTributaria: impostos.baseTributaria,
      aliquotaDevida: impostos.aliquotaDevida,
      totalDependentes: impostos.totalDependentes,
      totalDespesas: impostos.totalDespesas,
      totalPrevidencia: impostos.totalPrevidencia,
      totalPensao: impostos.totalPensao,
      totalDeducoes: impostos.totalDeducoes,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [impostos, year, month, user]);

  const saveItems = useCallback(
    async (rendimentos, variable?, value?) => {
      setLoading(true);
      const newImpostos = buildNewImpostos();
      if (id !== "") {
        await rendimentosBackend.put("/rendimentos/", {
          _id: id,
          ...newImpostos,
          monthStock: rendimentos || monthStock,
          [variable]: value,
        });
      } else {
        const { data } = await rendimentosBackend.post("/rendimentos", {
          ...newImpostos,
          monthStock: rendimentos || monthStock,
          [variable]: value,
        });
        setId(data._id);
      }
      setLoading(false);
      getRendimentos();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, buildNewImpostos, getRendimentos, year]
  );

  const generateDarf = async (pixValue: boolean, justSave?: boolean) => {
    setLoading(true);
    const { data } = await MsDarf.post("/darf", {
      month: month + 1,
      year,
      cpf: user.user.cpf,
      name: user.user.name,
      paymentInfo: {
        clientInfo: {
          document: user.user.cpf,
          email: user.user.email,
          name: user.user.name,
          phone: "",
        },
      },
      insertPix: pixValue,
      valueCharge: Math.max(
        0,
        impostos.impostoDevido +
          impostos.impostoAcumulado -
          impostos.gastosExterior +
          impostos.juros +
          impostos.multa
      ),
      institution: "velotax-rendimentos",
      impostoDevido: impostos.impostoDevido,
      totalImpostoDevido: impostos.impostoDevido,
      totalRendimentos: impostos.totalRendimentos,
      baseTributaria: impostos.baseTributaria,
      totalDependentes: impostos.totalDependentes,
      totalDespesas: impostos.totalDespesas,
      totalPrevidencia: impostos.totalPrevidencia,
      totalPensao: impostos.totalPensao,
      totalDeducoes: impostos.totalDeducoes,
      aliquotaDevida: impostos.aliquotaDevida,
      impostoAcumulado: impostos.impostoAcumulado,
      juros: impostos.juros,
      multa: impostos.multa,
      transactions: monthStock,
      from: 18,
      gastosExterior: impostos.gastosExterior,
      justSave,
    });

    if (!justSave) {
      window.location.href = data.darfUrl;
    }
    setLoading(false);
    closeModal();
    if (impostos.impostoTotal <= maxDarfPrice) {
      handleOpenPaymentModal();
    }
  };

  const handleRegularize = (regular: boolean) => {
    setLoading(true);
    setEmitting(true);
    apiRendimentos
      .post("transaction/regularize", {
        year,
        month: month + 1,
        regular,
      })
      .then((res) => {
        if (res.data?.darfUrl) {
          download(res.data.darfUrl);
        }
        closeModal();
        message.success("Dados salvos");
      })
      .catch(() => message.error(errorMessage))
      .catch((err) => console.log("err", err))
      .finally(() => {
        setEmitting(false);
        setLoading(false);
      });
  };

  const handleCloseItemModal = useCallback(() => {
    setShowRendimentoModal(false);
    unsetItem();
  }, []);

  const addItem = async (data: IMonthStock) => {
    if (data.tipo === "Exterior") {
      data.impostoPagoExterior = data?.impostoPagoExterior || 0;
    }
    const rendimentos = [...monthStock, data];
    setMonthStock(rendimentos);
    saveItems(rendimentos);
    handleCloseItemModal();
  };

  const editItem = (data: IMonthStock) => {
    if (data.tipo === "Exterior") {
      data.impostoPagoExterior = data?.impostoPagoExterior || 0;
    }
    const aux = [...monthStock];
    aux.splice(itemIndex, 1, data);
    setMonthStock(aux);
    saveItems(aux);
    handleCloseItemModal();
  };

  const removeAsset = async () => {
    const aux = [...monthStock];
    aux.splice(itemIndex, 1);
    setMonthStock(aux);
    saveItems(aux);
    if (!isSaveItens.current) isSaveItens.current = true;
  };

  const onChangeMonth = (value: moment.Moment | null, dateString: string) => {
    const [month, year] = dateString.split("-");
    setMonth(parseInt(month) - 1);
    setYear(parseInt(year));
  };

  const handleAdd = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    unsetItem();
    setShowRendimentoModal(true);
  };

  const handleEdit = (item: IMonthStock, index: number) => {
    setShowRendimentoModal(true);
    setItemIndex(index);
    setItem(item);
  };

  const handleRemove = (item: IMonthStock, index: number) => {
    setShowDeleteModal(true);
    setItemIndex(index);
    setItem(item);
  };

  const unsetItem = () => {
    setItem(null);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    unsetItem();
  };

  const onCloseHelpModal = () => {
    setHelpModal(undefined);
  };

  const handleOpenDarfModal = (value?: boolean) => {
    const pixValue = value ?? false;
    generateDarf(pixValue);
  };

  const handleOpenPaymentModal = () => {
    setPaymentModal(!paymentModal);
  };

  const changeInputState = (variable: string, value: string) => {
    const auxValue =
      variable !== "totalDependentes" ? currencyToNumber(value) : Number(value);
    setImpostos((currentValue) => {
      return {
        ...currentValue,
        [variable]: auxValue,
      };
    });
    saveItems(undefined, variable, auxValue);
  };

  return (
    <Container
      className={clsx({
        view,
        "view-edit": viewEdit,
        "velotax-container": !view && !viewEdit,
      })}
    >
      <Content
        className={clsx({
          view,
          "view-edit": viewEdit,
          "velotax-content": !view && !viewEdit,
        })}
      >
        <Space direction="vertical" className="full-width">
          <Row gutter={[16, 16]} align="middle" justify="space-between">
            <Col xs={24} sm={18}>
              <Typography.Title level={3} className="date-title">
                <BsCalendar3 />
                {hasPlan && !view && !viewEdit && (
                  <>
                    {currentBroker.initialYear === year && month === 0 ? (
                      <div className="month-chevron empty" />
                    ) : (
                      <BiChevronLeft
                        className="month-chevron"
                        onClick={() => {
                          if (month === 0) {
                            setYear((year) => {
                              const newYear = year - 1;
                              setDatePickerYear(newYear);
                              return newYear;
                            });
                            setMonth(11);
                          } else {
                            setMonth((month) => month - 1);
                          }
                        }}
                      />
                    )}
                  </>
                )}
                {monthsExtended[month]} de {year}
                {hasPlan && !view && !viewEdit && (
                  <>
                    {currentYear === year && currentMonth === month ? (
                      <div className="month-chevron empty" />
                    ) : (
                      <BiChevronRight
                        className="month-chevron"
                        onClick={() => {
                          if (month === 11) {
                            setYear((year) => {
                              const newYear = year + 1;
                              setDatePickerYear(newYear);
                              return newYear;
                            });
                            setMonth(0);
                          } else {
                            setMonth((month) => month + 1);
                          }
                        }}
                      />
                    )}
                  </>
                )}
              </Typography.Title>
            </Col>
            {!view && !viewEdit && (
              <Col xs={22} sm={6}>
                <DatePicker
                  size="large"
                  picker="month"
                  format="MM-YYYY"
                  allowClear={false}
                  inputReadOnly={true}
                  onChange={onChangeMonth}
                  defaultValue={defaultValue}
                  locale={antDatePickerLocale}
                  suffixIcon={<BsCalendar3 />}
                  disabled={loading || !hasPlan}
                  value={moment({ year: datePickerYear, month })}
                  onSelect={(e) => {
                    if (e.month() === month && year !== datePickerYear) {
                      onChangeMonth(e, `${month + 1}-${datePickerYear}`);
                    }
                  }}
                  onPanelChange={(e) => {
                    setDatePickerYear(e.year());
                  }}
                  getPopupContainer={(target) => target.parentElement!}
                  disabledDate={(current) =>
                    current &&
                    (current > currentDate.endOf("month") ||
                      current < moment(`${currentBroker.initialYear}-01-01`) ||
                      current < moment(`2020-01-01`))
                  }
                />
              </Col>
            )}
          </Row>

          <br />

          <div style={{ position: "relative" }}>
            {(!hasPlan || !allow) && (
              <div className="no-plan-container">
                <Typography>
                  Contrate o plano premium para ver os impostos devidos
                </Typography>
                <Button
                  type="primary"
                  icon={<AiOutlineLock size={18} />}
                  onClick={() => {
                    try {
                      handlePlanModal();
                      closeModal();
                    } catch (error) {
                      console.warn(error);
                    }
                  }}
                >
                  PREMIUM
                </Button>
              </div>
            )}
            <Descriptions size="small" bordered layout="horizontal">
              {RendimentosImpostosDevidos.map((description) => (
                <Descriptions.Item
                  span={24}
                  key={description.id}
                  label={description.label(setHelpModal, { year, month })}
                >
                  {loading ? <Spin /> : description.render(impostos)}
                </Descriptions.Item>
              ))}
            </Descriptions>
          </div>

          <br />

          <Collapse
            ghost
            bordered={false}
            expandIconPosition="right"
            defaultActiveKey={!hasPlan ? ["1"] : []}
            {...(!hasPlan ? { collapsible: "disabled" } : {})}
          >
            <Collapse.Panel
              key="1"
              header={
                <Typography.Title level={3}>Rendimentos</Typography.Title>
              }
            >
              {hasPlan && !view ? (
                <Button
                  type="primary"
                  className="add"
                  disabled={loading}
                  onClick={handleAdd}
                  icon={<PlusOutlined />}
                >
                  Adicionar
                </Button>
              ) : null}
              <List
                loading={loading}
                itemLayout="horizontal"
                dataSource={
                  !view
                    ? monthStock.filter((m) => m.tipo !== "Pensão Alimentícia")
                    : monthStock
                }
                renderItem={(item, index) => (
                  <List.Item
                    actions={
                      view || item.notEditable || !hasPlan
                        ? []
                        : [
                            <Button
                              type="text"
                              onClick={() => handleEdit(item, index)}
                              icon={<FaRegEdit size={16} />}
                            />,
                            <Button
                              type="text"
                              onClick={() => handleRemove(item, index)}
                              icon={<AiOutlineDelete size={16} />}
                            />,
                          ]
                    }
                  >
                    <Skeleton title={false} loading={item.loading} active>
                      <List.Item.Meta
                        title={<strong>Rendimento #{index + 1}</strong>}
                        description={
                          <div className="list-description">
                            <div>Tipo: {item.tipo}</div>
                            <div>
                              Valor: {formatCurrency(item.valorRendimento || 0)}
                            </div>
                            {item.cpfPagador && (
                              <div>
                                CPF do pagador: {maskCpf(item.cpfPagador)}
                              </div>
                            )}
                            {item.cpfBeneficiario && (
                              <div>
                                CPF do beneficiário:{" "}
                                {maskCpf(item.cpfBeneficiario)}
                              </div>
                            )}
                            {(item?.tipo === "Exterior" ||
                              item?.tipo === "Dividendos no exterior") && (
                              <div>
                                Imposto Pago no Exterior:{" "}
                                {formatCurrency(item.impostoPagoExterior ?? 0)}
                              </div>
                            )}
                            {item?.tipo === "Dividendos no exterior" && (
                              <>
                                <div>Ativo: {item.ativo ?? ""}</div>
                              </>
                            )}
                          </div>
                        }
                      />
                    </Skeleton>
                  </List.Item>
                )}
              />
            </Collapse.Panel>
          </Collapse>

          {hasPlan && <br />}

          {hasPlan && (
            <>
              <div className="total-tax-header" style={{ width: "100%" }}>
                <Typography.Title level={3}>
                  Deduções Tributárias
                </Typography.Title>
              </div>
              <BolsaOperations>
                <div className="header-deducoes-tributarias">
                  <span>
                    <InfoCircleOutlined
                      onClick={() => setHelpModal(ModalDependentes)}
                    />
                    Dependentes
                  </span>

                  <span>
                    <InfoCircleOutlined
                      onClick={() => setHelpModal(ModalAutonomos)}
                    />
                    Gastos Autônomos
                  </span>

                  <span>
                    <InfoCircleOutlined
                      onClick={() => setHelpModal(ModalPrevidencia)}
                    />
                    Gastos Previdência
                  </span>

                  <span>
                    <InfoCircleOutlined
                      onClick={() => setHelpModal(ModalPensao)}
                    />
                    Gastos Pensão
                  </span>
                </div>
                <div className="body-deducoes-tributarias">
                  <div className="row-deducoes-tributarias">
                    <span>
                      <InputNumber
                        min={0}
                        controls={true}
                        defaultValue={0}
                        disabled={view ?? loading}
                        value={impostos.totalDependentes ?? 0}
                        className="ant-input ant-input-sm"
                        onChange={(e: any) => {
                          changeInputState("totalDependentes", e);
                        }}
                      />
                    </span>
                    <span>
                      <NumberFormat
                        prefix="R$ "
                        disabled={view ?? loading}
                        defaultValue={0}
                        decimalScale={2}
                        allowNegative={false}
                        decimalSeparator=","
                        thousandSeparator="."
                        placeholder="R$ 0,00"
                        className="ant-input"
                        value={impostos.totalDespesas}
                        onBlur={(e: any) =>
                          changeInputState("totalDespesas", e.target.value)
                        }
                      />
                    </span>
                    <span>
                      <NumberFormat
                        prefix="R$ "
                        defaultValue={0}
                        decimalScale={2}
                        disabled={view ?? loading}
                        allowNegative={false}
                        decimalSeparator=","
                        thousandSeparator="."
                        placeholder="R$ 0,00"
                        className="ant-input"
                        value={impostos.totalPrevidencia}
                        onBlur={(e: any) =>
                          changeInputState("totalPrevidencia", e.target.value)
                        }
                      />
                    </span>
                    <span>
                      <NumberFormat
                        prefix="R$ "
                        disabled={view ?? loading}
                        defaultValue={0}
                        decimalScale={2}
                        allowNegative={false}
                        decimalSeparator=","
                        thousandSeparator="."
                        placeholder="R$ 0,00"
                        className="ant-input"
                        value={impostos.totalPensao}
                        onBlur={(e: any) =>
                          changeInputState("totalPensao", e.target.value)
                        }
                      />
                    </span>
                  </div>
                </div>
              </BolsaOperations>
            </>
          )}

          {hasPlan && <br />}

          {hasPlan && (!hasPlan.basic || allow) && (
            <>
              <div
                className="total-tax-header original"
                style={{ width: "100%" }}
              >
                <Typography.Title level={3}>Imposto total</Typography.Title>
                <Typography.Title level={3} className="text-center">
                  {loading ? (
                    <Spin style={{ marginRight: "32px" }} />
                  ) : (
                    formatCurrency(valorImpostoTotal)
                  )}
                </Typography.Title>
              </div>
              <Descriptions size="small" bordered layout="horizontal">
                {ImpostoTotalRendimentos.map((description: any) => {
                  if (description?.id === 'gastosExterior' && year >= 2024) return null;
                  return (
                    <Descriptions.Item
                      span={24}
                      key={description.id}
                      label={description.label(setHelpModal)}
                    >
                      {loading ? (
                        <Spin />
                      ) : description.Component ? (
                        <description.Component
                          data={impostos}
                          year={year}
                          month={month}
                          loading={loading}
                          setDataCripto={setImpostos}
                          disabled={!view}
                          view={view}
                          id={id}
                          monthStock={monthStock}
                          user={user}
                        />
                      ) : (
                        formatCurrency(
                          Number(impostos[description.id as keyof Iimpostos] || 0)
                        )
                      )}
                    </Descriptions.Item>
                  )
                })}
              </Descriptions>
            </>
          )}

          {!view && hasPlan && <Row>{ActionButtons}</Row>}
        </Space>
      </Content>

      <PaymentModal
        item={item}
        disablePixOption={(paymentData?.valueCharge || 0) > maxPixPayment}
        disableCreditCardOption={
          (paymentData?.valueCharge || 0) > maxCreditCardPayment
        }
        show={paymentModal}
        onCancel={handleOpenPaymentModal}
        paymentData={paymentData}
        callDarf={(value: boolean) => handleOpenDarfModal(value)}
        maxPixValue={maxPixPayment}
        maxCCValue={maxCreditCardPayment}
        selectedYear={year}
        closeDarfModal={closeModal}
      />
      <DrawerModal
        footer={null}
        visible={helpModal}
        title={helpModal?.title}
        onCancel={onCloseHelpModal}
      >
        {helpModal?.content}
      </DrawerModal>
      <FormModal
        add={addItem}
        edit={editItem}
        itemToEdit={item}
        loading={loading}
        rows={RendimentoFormItemRows(month, year)}
        onCancel={handleCloseItemModal}
        visibility={showRendimentoModal}
        title={item ? "Editar rendimento" : "Adicionar rendimento"}
      />
      <DeleteConfirmationModal
        title="Remover rendimentos"
        visibility={showDeleteModal}
        onCancel={handleCloseDeleteModal}
        setVisibility={setShowDeleteModal}
        onOk={() => Promise.resolve(removeAsset())}
        body="Você realmente quer deletar esse ativo?"
      />
    </Container>
  );
};
