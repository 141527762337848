import React, { useState } from "react";
import { Form, message } from "antd";
import ReactInputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import Button from "../../components/Button";
import { validationCpf } from "../../utils/formValidations";
import { AuthContainer } from "../../styles/LoginSignUp/authContainer";

interface IData {
  cpf: string;
}

export const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm<IData>();
  const [loading, setLoading] = useState(false);

  const onFinish = async (data: IData) => {
    try {
      setLoading(true);

      const res = await api.post("/user/forgot-password", { cpf: data.cpf });
      res.data?.message?.includes("@")
        ? message.success(res.data?.message)
        : message.success(
            "Um email com um link foi enviado para recuperar sua senha!",
            5
          );

      setTimeout(() => {
        setLoading(false);
        navigate("/p/login");
      }, 500);
    } catch (err: any) {
      if (err?.response?.status === 400) {
        message.error(err.response?.data?.message || "Ocorreu um erro");
      }
      setLoading(false);
      return;
    }
  };

  const onValuesChange = (changed: any, values: any) => {
    const cpf = changed.cpf ? changed.cpf.replace(/[_.-/-]/g, "") : values.cpf;
    form.setFieldsValue({
      cpf,
    });
  };

  const handleNavigateCreateAccount = () => {
    navigate("/p/cadastrar");
  };

  const handleNavigateLogin = () => {
    navigate("/p/login");
  };

  return (
    <AuthContainer className="beauty-scrollbar">
      <div className="auth-content">
        <div className="form-container">
          <h1>Recupere sua senha</h1>
          <Form
            form={form}
            size="large"
            onFinish={onFinish}
            onValuesChange={onValuesChange}
          >
            <Form.Item name="cpf" rules={validationCpf}>
              <ReactInputMask
                placeholder="CPF"
                mask="999.999.999-99"
                className="ant-input ant-input-lg"
              />
            </Form.Item>

            <div className="buttons">
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                className="full-width"
                loading={loading}
              >
                Recuperar
              </Button>
            </div>
            <div className="bottom-acessar">
              <Button
                type="link"
                onClick={handleNavigateLogin}
                style={{
                  fontSize: "11px",
                  width: "150px",
                }}
              >
                Voltar para o login
              </Button>
            </div>
          </Form>

          <div className="footer">
            Ainda não possui uma conta?
            <Button
              type="link"
              onClick={handleNavigateCreateAccount}
              className="btn-underlined"
            >
              Começar
            </Button>
          </div>
        </div>
      </div>
    </AuthContainer>
  );
};
