import { useEffect } from "react";
import { Col, Row, Typography } from "antd";
import { BsArrowLeftRight } from "react-icons/bs";
import Button from "../../components/Button";
import { Page } from "../../constants/brokers";
import b3Logo from "../../assets/b3-logo-azul.png";
import { useAuth } from "../../contexts/AuthContext";
import { Content, Parceiros, Subtitle } from "./styles";
import { useBroker } from "../../contexts/BrokerContext";
import { B3IntegrationButtons } from "./B3IntegrationButtons";
import { useB3Integration } from "../../contexts/B3IntegrationContext";
import { AuthContainer } from "../../styles/LoginSignUp/authContainer";
import { ReactComponent as VeloIcon } from "../../assets/velotax/veloicon.svg";
import { LoadingIntegration } from "../../components/LoadingIntegration";
import { useNavigate } from "react-router-dom";

interface IntegrationProps {
  item: Page;
}

export const IntegrationBolsa: React.FC<IntegrationProps> = () => {
  const { integration, initIntegration } = useBroker();
  const { user, cameFromRegister, setCameFromRegister } = useAuth();
  const {
    interval,
    b3Authorized,
    handleIntegrate,
    handleSkipB3Integration,
    loadingSkipB3Integration,
  } = useB3Integration();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      user.user &&
      !b3Authorized &&
      !interval.current &&
      cameFromRegister.bolsa &&
      integration.key !== "unset"
    ) {
      setCameFromRegister((data) => ({ ...data, bolsa: false }));
      handleIntegrate();
    }
  }, [
    interval,
    user.user,
    b3Authorized,
    handleIntegrate,
    integration.key,
    setCameFromRegister,
    cameFromRegister.bolsa,
  ]);

  useEffect(() => {
    if (integration?.integrated) {
      navigate("/velotax/bolsa");
    }
  }, [integration?.integrated]);

  return (
    <>
      {(b3Authorized && initIntegration) && (
        <LoadingIntegration
          show={initIntegration}
          hasError={integration.hasError}
        />
      )}
      <AuthContainer className="beauty-scrollbar">
        <div className="auth-content">
          <div className="form-container">
            <Typography.Title level={1}>
              <Row align="bottom" justify="space-between">
                <Col>Conecte sua conta à B3</Col>
                <Col>
                  <Parceiros>
                    Parceiros oficiais
                    <div className="parceiros">
                      <VeloIcon className="veloicon" />
                      <BsArrowLeftRight className="arrow" />
                      <img src={b3Logo} alt="b3-logo" />
                    </div>
                  </Parceiros>
                </Col>
              </Row>
            </Typography.Title>
            <Typography.Title level={4}>
              <Row align="bottom" justify="space-between">
                <Subtitle>
                  <Col>
                    Precisamos das informações da B3 para saber com precisão
                    qual o valor de Imposto de Renda incidirá sobre seus
                    investimentos.
                  </Col>
                </Subtitle>
              </Row>
            </Typography.Title>
            <B3IntegrationButtons checkOnlyExistsUserB3={true} />

            <Content>
              <div className="skip-btn">
                <Button
                  type="link"
                  size="small"
                  loading={loadingSkipB3Integration}
                  onClick={handleSkipB3Integration}>
                  Não tenho investimento na bolsa
                  {/* <AiOutlineArrowRight /> */}
                </Button>
              </div>
            </Content>
          </div>
        </div>
      </AuthContainer>
    </>
  );
};
