import { Input, Select } from "antd";
import Masked from "react-input-mask";
import { ReactNode } from "react";
import NumberFormat from "react-number-format";

interface InputParams {
  disabled?: boolean;
  placeholder?: string;
}

interface MaskInputParams extends InputParams {
  mask?: string;
}

interface NumberInputParams extends InputParams {
  prefix?: string;
  decimalScale?: number;
  allowNegative?: boolean;
}

interface SelectInputParams extends InputParams {
  defaultValue?: any;
  label?: any;
  style?: any;
  options?: any;
  value?: any;
  id?: string
}

export const TextInput = ({ placeholder, disabled }: InputParams) => (
  <Input placeholder={placeholder} disabled={disabled} />
);

export const SelectInput = ({
  placeholder,
  disabled,
  options,
  id
}: SelectInputParams) => {
  return (
    <Select
      placeholder={placeholder}
      disabled={disabled}
      options={options}
      getPopupContainer={(target) => target.parentElement}
      id={id}
    />
  );
};

export const NumberInput = ({
  prefix = "",
  disabled = false,
  decimalScale = 2,
  placeholder = "",
  allowNegative = false,
}: NumberInputParams) => (
  <NumberFormat
    disabled={disabled}
    prefix={prefix}
    decimalSeparator=","
    thousandSeparator="."
    placeholder={placeholder}
    decimalScale={decimalScale}
    allowNegative={allowNegative}
    className="ant-input ant-input-lg"
  />
);

export const MaskInput = ({
  mask = "",
  placeholder = "",
  disabled = false,
}: MaskInputParams) => (
  <Masked
    mask={mask}
    disabled={disabled}
    placeholder={placeholder}
    className="ant-input ant-input-lg"
  />
);