import clsx from "clsx";
import moment from "moment";
import { FaRegEdit } from "react-icons/fa";
import { BsCalendar3, BsDownload } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import {  PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import {
  AiOutlineDelete,
  AiOutlineDownload,
  AiOutlineLock,
  AiOutlineUnlock,
} from "react-icons/ai";
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Col,
  Collapse,
  DatePicker,
  Descriptions,
  List,
  message,
  Row,
  Skeleton,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography,
} from "antd";
import apis from "../../services/apis";
import Button from "../../components/Button";
import { Page } from "../../constants/brokers";
import HandleTag from "../../services/handleTag";
import { useAuth } from "../../contexts/AuthContext";
import { FormModal } from "../../components/FormModal";
import { apiPayment } from "../../services/apiPayment";
import { useBroker } from "../../contexts/BrokerContext";
import { DrawerModal } from "../../components/DrawerModal";
import { LoadingModal } from "../../components/LoadingModal";
import { PaymentModal } from "../../components/PaymentModal";
import { Container, Content, BolsaOperations } from "./styles";
import { handleDownloadSheet } from "../../utils/handleDownloadSheet";
import { useOldTransactions } from "../../contexts/OldTransactionsContext";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import { DatePickerExtraFooter } from "../Darf/Components/DatePickerExtraFooter";
import {
  DatePickerMonthCell,
  YearResume,
} from "../Darf/Components/DatePickerMonthCell";
import {
  DarfResultDescriptions,
  defaultDarfBolsa,
  DarfBolsaFormItemRows,
  PrejuFormItemRows,
  DarfBolsaTabs,
  IrrfFormItemRows,
} from "../../constants/darfBolsa";
import {
  antDatePickerLocale,
  ativarDarfsRetroativos,
  download,
  errorMessage,
  formatCurrency,
  getZeroBuyPrice,
  isMobile,
  isSecondBusinessDay,
  mensagemDarfsRetroativos,
  monthsExtended,
  planInfoToHasPlan,
} from "../../utils";
import {
  IDarfBolsa,
  YearResumeStatusFromBackEnum,
  historicoVendasEmptyText,
  maxCreditCardPayment,
  maxPixPayment,
  maxDarfPrice,
  minDarfPrice,
} from "../../constants/darf";
import { ModalZeroBuyPrice } from "../../components/ModalZeroBuyPrice";

interface DarfBolsaProps {
  item: Page;
  view?: boolean;
  viewEdit?: boolean;
  className?: string;
  closeModal: () => void;
  darf?: {
    id: string;
    fullPath: string;
    payed?: boolean;
  };
  date?: {
    year?: number;
    month?: number;
  };
}

export const DarfBolsa: React.FC<DarfBolsaProps> = ({
  view,
  item,
  darf,
  date,
  viewEdit,
  closeModal,
}) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { oldPositionList } = useOldTransactions();
  const { currentBroker, currentPage } = useBroker();
  const { user, showUserPlanModal, userPlanModal, hasPlan } = useAuth();

  const today = new Date();
  const currentMonth = today.getMonth() + 1;
  const currentYear = today.getFullYear();
  const queryYear = (state as any)?.year as number;
  const queryMonth = (state as any)?.month as number;
  const initialMonth = queryMonth
    ? queryMonth - 1
    : today.getMonth() - 1 >= 0
    ? today.getMonth() - 1
    : 11;
  const initialYear = queryYear
    ? queryYear
    : today.getMonth() - 1 >= 0
    ? today.getFullYear()
    : today.getFullYear() - 1;
  const defaultValue = moment({ month: initialMonth, year: initialYear });
  const currentDate = moment({
    month: currentMonth,
    year: currentYear,
  }).subtract(1, "M");
  window.history.replaceState({}, document.title);

  const year = date?.year ?? initialYear;
  const month = date?.month ?? initialMonth;


  const planMonthSignature = new Date(user?.user?.userPlanInfoVelotax?.currentPlan?.createdAt).getMonth();
  const planYearSignature = new Date(user?.user?.userPlanInfoVelotax?.currentPlan?.createdAt).getFullYear();

  const [asset, setAsset] = useState<any>();
  const [loadingResetPreju] = useState(false);
  const [, setYear] = useState(initialYear);
  const [loading, setLoading] = useState(false);
  const [resumo, setResumo] = useState<any[]>([]);
  const [emitting, setEmitting] = useState(false);
  const [, setMonth] = useState(initialMonth);
  const [hasItems, sethasItems] = useState(false);
  const [helpModal, setHelpModal] = useState<any>();
  const [, setBuffer] = useState<Array<any>>();
  const [isBatchDarf, setIsBatchDarf] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [yearBatch, setYearBatch] = useState(initialYear);
  const [dataToEditPreju, setDataToEditPreju] = useState();
  const [totalPagarBatch, setTotalPagarBatch] = useState(0);
  const [showAssetModal, setShowAssetModal] = useState(false);
  const [paymentData, setPaymentData] = useState<IDarfBolsa>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [data, setData] = useState<IDarfBolsa>(defaultDarfBolsa);
  const [yearResume, setYearResume] = useState<YearResume[]>([]);
  const [loadingEditPreju, setLoadingEditPreju] = useState(false);
  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const [datePickerYear, setDatePickerYear] = useState(initialYear);
  const [gettingYearResume, setGettingYearResume] = useState(false);
  const [loadingDarfButton, setLoadingDarfButton] = useState<boolean>(false);
  const [showEditPrejuConfirmation, setShowEditPrejuConfirmation] =
    useState<boolean>(false);

  const hideOldPositionsText =
    oldPositionList?.length < 1 || oldPositionList === null;

  const valorPrincipal = Math.max(
    0,
    Number(
      (data.impostoComumFinal || 0) +
        (data.impostoDTFinal || 0) +
        (data.impostoFIIFinal || 0)
    ) +
      (Number(data["impostoAcumulado"] || 0) - Number(data["irrfTotal"] || 0))
  );
  const impostoCharge = useMemo(() => {
    return (
      valorPrincipal +
      (valorPrincipal < minDarfPrice
        ? 0
        : (data.multaAux ?? 0) + (data.jurosAux ?? 0))
    );
  }, [data, valorPrincipal]);

  const transactionFeature = item.features[0];
  const taxFeature = item.features[1];
  const yearResumeFeature = item.features[2];
  const prejuizoFeature = item.features[3];
  const irrfFeature = item.features[4];

  const { emitted } = useMemo(() => {
    if (gettingYearResume) {
    }
    const monthResume = yearResume
      .find((resume) => resume.year === year)
      ?.months.find((m) => m.month - 1 === month);
    return {
      emitted:
        monthResume?.status === YearResumeStatusFromBackEnum.PAYED ||
        monthResume?.status === YearResumeStatusFromBackEnum.NOT_PAYED,
      payed: !!monthResume?.payed,
      status: monthResume?.status,
    };
  }, [yearResume, year, month, gettingYearResume]);

  const handleLabelButton: ReactNode = useMemo(() => {
    if ((data?.impostoTotalFinal || 0) <= maxDarfPrice) return "Pagar DARF";
    else {
      if (emitted) return "Emitir novo DARF";
      else return taxFeature?.buttonLabel;
    }
  }, [data?.impostoTotalFinal, taxFeature?.buttonLabel, emitted]);

  const getDarf = useCallback(
    (id: string) => {
      view && setLoading(true);
      view &&
        (currentPage?.api || apis)
          .get(`${taxFeature.apiUrl}/${id}`)
          .then((response) => {
            setYear(response.data?.year);
            setMonth(response.data?.month - 1);
            setData({
              ...response.data,
              emitted: true,
            });
          })
          .catch((err) => {
            message.error(err.response?.data?.message || errorMessage);
          })
          .finally(() => setLoading(false));
    },
    [view, taxFeature.apiUrl, currentPage]
  );

  const getYearResume = useCallback(
    (year: number, force?: boolean, url?: string) => {
      setGettingYearResume(true);
      !view &&
        year <= new Date().getFullYear() &&
        year >= moment(`2020-01-01`).get("year") &&
        (force ||
          (!yearResumeFeature.disabled &&
            !yearResume.find((resume) => resume.year === year))) &&
        (currentPage?.api || apis)
          .get(yearResumeFeature.apiUrl, { params: { year } })
          .then((response) => {
            const index = yearResume.findIndex(
              (resume) => resume.year === year
            );
            if (index >= 0) {
              setYearResume((yearResume) => {
                yearResume.splice(index, 1, response.data);
                return yearResume;
              });
            } else {
              setYearResume((yearResume) => [...yearResume, response.data]);
            }

            if (url) {
              download(url);
            }
          })
          .catch((err) => console.log(err))
          .finally(() => setGettingYearResume(false));
    },
    [yearResumeFeature, yearResume, view, currentPage]
  );

  const getTaxes = useCallback(
    (
      nAtualizarDarfsPassadas?: boolean,
      nAtualizarIrrf?: boolean,
      atualizouImpostoAcumulado?: boolean,
      nAtualizarPrejuizosAcumulados?: boolean,
      atualizarTotalmente?: boolean
    ) => {
      setLoading(true);
        (currentPage?.api || apis)
          .get(transactionFeature.apiUrl, {
            params: {
              month: month + 1,
              year,
              nAtualizarDarfsPassadas: nAtualizarDarfsPassadas || false,
              nAtualizarIrrf: nAtualizarIrrf || false,
              atualizouImpostoAcumulado: atualizouImpostoAcumulado || false,
              nAtualizarPrejuizosAcumulados:
                nAtualizarPrejuizosAcumulados || false,
              atualizarTotalmente: atualizarTotalmente || false,
            },
          })
          .then((response) => {
            if (response.data.memoriaCalculo) {
              setData({
                ...response.data,
                multa: response.data.multaAux,
                juros: response.data.jurosAux,
                impostoDevido: response.data.impostoTotal,
                memoriaCalculo: response.data.memoriaCalculo.map(
                  (memoCalc: any) => ({
                    ...memoCalc,
                    date: moment(memoCalc.date.slice(0, 10)).format(
                      "DD/MM/YYYY"
                    ),
                    originalDate: memoCalc.date,
                    market:
                      memoCalc.market === "Opção de Compra" ||
                      memoCalc.market === "Opção de Venda"
                        ? "Mercado de opções"
                        : memoCalc.market,
                  })
                ),
              });
            }
          })
          .catch((err) => {
            setData(defaultDarfBolsa);
            message.error(errorMessage);
          })
          .finally(() => setLoading(false));
    },
    [month, year, transactionFeature, view, currentPage, hasPlan]
  );

  const isAntesTerceiroDiaUtil = () => {
    const today = moment();
    // Verifica se a data de hoje é um sábado
    if (today.isoWeekday() === 6) {
      return false;
    }
    // Verifica se a data de hoje é anterior ao terceiro dia útil
    const firstDayOfMonth = moment(today).startOf('month');
    let businessDaysCount = 0;
    while (businessDaysCount < 2) {
      if (firstDayOfMonth.isoWeekday() < 6) {
        businessDaysCount++;
      }
      firstDayOfMonth.add(1, 'day');
    }
    return today.isBefore(firstDayOfMonth);
  }


  const handleDarf = (justSave?: boolean) => {
    const url = isBatchDarf ? "/darf/batch" : taxFeature.apiUrl;
    const batchPayload = {
      name: user.user.name,
      amount: totalPagarBatch,
      paymentInfo: {
        clientInfo: {
          name: user?.user?.name,
          email: user?.user?.email,
          document: user?.user?.cpf,
        },
      },
      insertPix: true,
      paymentForm: "pix",
      resumo,
      isDownloadPDF: true,
    };
    const payload = {
      ...data,
      memoriaCalculo: [],
      transactions: data.memoriaCalculo,
      totalImpostoDevido: data.impostoTotalFinal,
      impostoDevido: data.impostoTotal,
      juros: data.jurosAux,
      multa: data?.multaAux,
      year,
      month: month + 1,
      name: user.user.name,
      insertPix: true,
      paymentInfo: {
        clientInfo: {
          name: user?.user?.name,
          email: user?.user?.email,
          document: user?.user?.cpf,
        },
      },
      justSave,
      payed: false,
    };
    setEmitting(true);
    (currentPage?.api || apis)
      .post(url, isBatchDarf ? batchPayload : payload)
      .then((res) => {
        closeModal();
        if (!isBatchDarf) {
          setData((data) => ({ ...data, emitted: true }));
          getYearResume(year, true, justSave ? "" : res.data.darfUrl);
        } else if (res.data?.url) {
          download(res.data?.url);
        }
        setEmitting(false);
      })
      .catch((err) => {
        message.error(err.response?.data?.message || errorMessage);
        setEmitting(false);
      });
  };

  const handleRegularize = (regular: boolean) => {
    setEmitting(true);
    (currentPage?.api || apis)
      .post("transaction/regularize", {
        year,
        month: month + 1,
        regular,
        impostoAcumulado:
          (data.impostoTotal ?? 0) + (data.impostoAcumulado ?? 0),
      })
      .then((res) => {
        getYearResume(year, true, res.data.darfUrl);
        getTaxes(false, false, false, true);
        message.success("Dados salvos");
        closeModal();
      })
      .catch(() => message.error(errorMessage))
      .finally(() => setEmitting(false));
  };

  const onChangeMonth = (value: moment.Moment | null, dateString: string) => {
    const [month, year] = dateString.split("-");
    setMonth(parseInt(month) - 1);
    setYear(parseInt(year));
  };

  const unsetAsset = () => {
    setAsset(undefined);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    unsetAsset();
  };

  const handleCloseAssetModal = () => {
    setShowAssetModal(false);
    unsetAsset();
  };

  const onCloseHelpModal = () => {
    setHelpModal(undefined);
  };

  const handleOpenDarfModal = () => {
    if (
      ativarDarfsRetroativos({
        item: { month: month + 1, year },
        currentMonth,
        currentYear,
        hasPlan,
        planMonthSignature: planMonthSignature === 0 ? 11 : planMonthSignature - 1,
        planYearSignature: planMonthSignature === 0 ? planYearSignature - 1 : planYearSignature

      })
    ) {
      handleDarf();
    } else {
      handlePlanModal();
    }
  };

  const handleOpenPaymentModal = () => {
    setPaymentModal(!paymentModal);
    setIsBatchDarf(false);
  };

  const handleAdd = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    setShowAssetModal(true);
  };

  const handleEdit = (item: any, index: number) => {
    setShowAssetModal(true);
    setAsset({
      ...item,
      id: item._id,
      feeB3: item.fee,
      price: item.sellPrice,
      avgBuyPrice: item.buyPrice,
      data: moment.utc(item.date.toLocaleString()).format("DD/MM/YYYY"),
    });
  };

  const handleRemove = (item: any, index: number) => {
    setShowDeleteModal(true);
    setAsset(item);
  };

  const addAsset = (asset: any) => {
    setLoading(true);
    (currentPage?.api || apis)
      .post(transactionFeature.apiUrl, {
        ...asset,
        operation: "V",
        avgSellPrice: asset.price,
        totalValue: asset.price * asset.quantity,
      })
      .then(() => {
        getTaxes(true, false, false, true, true);
        getYearResume(year, true);
      })
      .catch(() => message.error(errorMessage))
      .finally(() => {
        setLoading(false);
        handleCloseAssetModal();
      });
  };

  const editAsset = (asset: any) => {
    setLoading(true);
    (currentPage?.api || apis)
      .put(`${transactionFeature.apiUrl}/${asset.id}`, {
        ...asset,
        operation: "V",
        avgSellPrice: asset.price,
        totalValue: asset.price * asset.quantity,
      })
      .then(() => {
        getTaxes(true, false, false, true, true);
        getYearResume(year, true);
      })
      .catch(() => message.error(errorMessage))
      .finally(() => {
        setLoading(false);
        handleCloseAssetModal();
      });
  };

  const removeAsset = () => {
    setLoading(true);
    (currentPage?.api || apis)
      .delete(`${transactionFeature.apiUrl}/${asset.id ?? asset._id}`, {
        params: {
          year,
          month,
        },
      })
      .then(() => {
        getTaxes(true, false, false, true, true);
        getYearResume(year, true);
      })
      .catch(() => message.error(errorMessage))
      .finally(() => {
        setLoading(false);
        handleCloseDeleteModal();
      });
  };

  /* Manipular as tabs de imposto no mobile */
  const [tab, setTab] = useState(1);
  const bolsaOperationsRef = useRef<HTMLDivElement>(null);

  const handleNext = () => {
    setTab((tab) => tab + 1);
    const span = document.querySelector("div.sub-header > span.show");
    bolsaOperationsRef.current?.scrollBy({ left: span?.clientWidth ?? 155 });
  };

  const handlePrev = () => {
    setTab((tab) => tab - 1);
    const span = document.querySelector("div.sub-header > span.show");
    bolsaOperationsRef.current?.scrollBy({ left: -(span?.clientWidth ?? 155) });
  };
  /* Fim da manipulação das tabs de imposto no mobile */

  /* Editar prejuízo acumulado */
  const [resetedPreju, setResetedPreju] = useState(false);
  const [showPrejuModal, setShowPrejuModal] = useState(false);
  const [ignoreZeroBuyPrice, setIgnoreZeroBuyPrice] = useState(false);
  const [dataZeroBuyPrice, setDataZeroBuyPrice] = useState<any[]>([]);
  const [modalZeroBuyPrice, setModalZeroBuyPrice] = useState(false);

  const preju = useMemo(
    () => ({
      userPrejuizoComum: resetedPreju
        ? data.prejuizoComum
        : data.userPrejuizoComum ?? data.prejuizoComum,
      userPrejuizoDayTrade: resetedPreju
        ? data.prejuizoDayTrade
        : data.userPrejuizoDayTrade ?? data.prejuizoDayTrade,
      userPrejuizoFII: resetedPreju
        ? data.prejuizoFII
        : data.userPrejuizoFII ?? data.prejuizoFII,
    }),
    [data, resetedPreju]
  );

  // const handleResetPreju = () => {
  //   setResetedPreju(true);
  // };

  const handleEditPreju = () => {
    setResetedPreju(false);
    setShowPrejuModal(true);
  };

  const handleEditPrejuData = (preju: any) => {
    setDataToEditPreju(preju);
    setShowEditPrejuConfirmation(true);
  };

  const handleClosePrejuModal = () => {
    setShowPrejuModal(false);
  };

  const editPreju = () => {
    setLoadingEditPreju(true);
    setShowEditPrejuConfirmation(false);

    (currentPage?.api || apis)
      .put(
        `${prejuizoFeature.apiUrl}?month=${month + 1}&year=${year}`,
        dataToEditPreju
      )
      .then((res) => {
        message.success("Dados salvos");
        getTaxes(true, true, false, true);
        setLoadingEditPreju(false);
        handleClosePrejuModal();
      })
      .catch(() => {
        message.error(errorMessage);
        setLoadingEditPreju(false);
        handleClosePrejuModal();
      });
  };
  /* Fim do editar prejuízo acumulado */

  /* Editar IRRF */
  const [showIrrfModal, setShowIrrfModal] = useState(false);

  const irrf = useMemo(
    () => ({
      userIrrfComum: data.irrfComum,
      userIrrfDayTrade: data.irrfDayTrade,
      userIrrfFII: data.irrfFII,
      userIrrfTotal: data.irrfTotal,
    }),
    [data]
  );

  // const handleResetIrrf = () => {
  //   setResetedIrrf(true);
  // };

  const handleEditIrrf = () => {
    setShowIrrfModal(true);
  };

  const handleCloseIrrfModal = () => {
    setShowIrrfModal(false);
  };

  const editIrrf = (data: any) => {
    handleCloseIrrfModal();
    (currentPage?.api || apis)
      .put(`${irrfFeature.apiUrl}?month=${month + 1}&year=${year}`, data)
      .then((response) => {
        message.success("Dados salvos");
        getTaxes(true, true, false, true);
      })
      .catch(() => {
        message.error(errorMessage);
      });
  };
  /* Fim do editar prejuízo acumulado */

  useEffect(() => {
    // if (month === 0) {
    if (!darf?.payed) getTaxes(false, false, false, true, false);
    // } else {
    //   getTaxes(false, false, false, false, true);
    // }
  }, [getTaxes, month, year, darf?.payed]);

  useEffect(() => {
    if (view && darf) {
      getDarf(darf.id);
    }
  }, [getDarf, view, darf]);

  useEffect(() => {
    getYearResume(year);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data?.memoriaCalculo?.length > 0) sethasItems(true);
    setBuffer(data?.buffer?.data);
  }, [data]);

  useEffect(() => {
    const dataToPayment = {
      ...data,
      year,
      month: month + 1,
      name: user.user.name,
      memoriaCalculo: [],
      transactions: data.memoriaCalculo,
    };

    setPaymentData(dataToPayment);
  }, [data.impostoTotalFinal, data, month, user.user.name, year]);

  const handlePlanModal = (isBatch?: boolean) => {
    showUserPlanModal(!userPlanModal);
  };

  const handleSaveMonth = async () => {
    setLoadingDarfButton(true);
    try {
      const retPlanInfo = await apiPayment.get("/user-plan/plan-info");

      const hasPlan = planInfoToHasPlan(retPlanInfo.data);
      ativarDarfsRetroativos({
        item: { month: month + 1, year },
        currentMonth,
        currentYear,
        hasPlan,
        planMonthSignature: planMonthSignature === 0 ? 11 : planMonthSignature - 1,
        planYearSignature: planMonthSignature === 0 ? planYearSignature - 1 : planYearSignature

      })
        ? handleDarf(true)
        : handlePlanModal();
    } catch (err) {
      console.error(err);
    }
    setLoadingDarfButton(false);
  };

  useEffect(()=> {
    if (ignoreZeroBuyPrice) handleEventDarfButton();
  }, [ignoreZeroBuyPrice])

  const handleEventDarfButton = async () => {
    setLoadingDarfButton(true);
    let retPlanInfo = {
      data: {
        active: false,
        type: "",
      },
    };
    try {
      retPlanInfo = await apiPayment.get("/user-plan/plan-info");
    } catch (err) {
      console.error(err);
    }
    setLoadingDarfButton(false);

    if (!ignoreZeroBuyPrice) {
      const hasZeroBuyPrice = await getZeroBuyPrice(data?.memoriaCalculo);
      if (hasZeroBuyPrice.length > 0) {
        setModalZeroBuyPrice(true);
        setDataZeroBuyPrice(hasZeroBuyPrice);
        return;
      }
    }

    const hasPlan = planInfoToHasPlan(retPlanInfo.data);
    ativarDarfsRetroativos({
      item: { month: month + 1, year },
      currentMonth,
      currentYear,
      hasPlan,
      planMonthSignature: planMonthSignature === 0 ? 11 : planMonthSignature - 1,
      planYearSignature: planMonthSignature === 0 ? planYearSignature - 1 : planYearSignature

    })
      ? handleOpenPaymentModal()
      : handlePlanModal();
  };

  const handleBatchDarf = async () => {
    let retPlanInfo = {
      data: {
        active: false,
        type: "",
      },
    };
    let allow = false;
    try {
      retPlanInfo = await apiPayment.get("/user-plan/plan-info");

      const hasPlan = planInfoToHasPlan(retPlanInfo.data);
      allow = ativarDarfsRetroativos({
        item: { month: month + 1, year },
        currentMonth,
        currentYear,
        hasPlan,
        planMonthSignature: planMonthSignature === 0 ? 11 : planMonthSignature - 1,
        planYearSignature: planMonthSignature === 0 ? planYearSignature - 1 : planYearSignature

      });
      allow ? handleDarf(true) : handlePlanModal();
    } catch (err) {
      console.error(err);
    }

    if (!allow) {
      handlePlanModal(true);
    } else {
      setOpenLoadingModal(true);
      (currentPage?.api || apis)
        .get(`/darf/batch/calculate?year=${yearBatch}`)
        .then((res) => {
          setTotalPagarBatch(res.data.totalImpostoPagar);
          setResumo(res.data.resumo);
          setIsBatchDarf(true);
          setPaymentModal(true);
        })
        .finally(() => setOpenLoadingModal(false));
    }
  };

  const checkBlockedOption = (maxValue: number) => {
    if (isBatchDarf) {
      return (
        resumo.filter((darf) => (darf?.impostoTotalFinal || 0) > maxValue)
          .length > 0
      );
    } else {
      return (data?.impostoTotalFinal || 0) > maxValue;
    }
  };

  const downloadAction = (record: any) => {
    (currentPage?.api || apis)
      .get(`/darf/download/${record._id}`)
      .then((res) => {
        if (res.data.url) {
          download(res.data.url);
        }
      });
  };

  const allow = ativarDarfsRetroativos({
    item,
    currentMonth,
    currentYear,
    hasPlan,
    planMonthSignature: planMonthSignature === 0 ? 12 : planMonthSignature,
    planYearSignature: planMonthSignature === 0 ? planYearSignature - 1 : planYearSignature
  });

  return (
    <Container className={clsx({ view, "view-edit": viewEdit })}>
      {openLoadingModal && (
        <LoadingModal title="Preparando DARFs">
          {`Estamos calculando todas as DARFs pendentes para o ano de ${yearBatch}. Por favor, aguarde.`}
        </LoadingModal>
      )}
      <Content className={clsx({ view, "view-edit": viewEdit })}>
        <Space direction="vertical" className="full-width">
          <Row gutter={[16, 16]} align="middle" justify="space-between">
            <Col xs={24} sm={16}>
              <Typography.Title level={3} className="date-title">
                <BsCalendar3 />
                {!view && !viewEdit && (
                  <>
                    {currentBroker.initialYear === year && month === 0 ? (
                      <div className="month-chevron empty" />
                    ) : (
                      <BiChevronLeft
                        className="month-chevron"
                        onClick={() => {
                          if (month === 0) {
                            setYear((year) => {
                              const newYear = year - 1;
                              getYearResume(newYear);
                              setDatePickerYear(newYear);
                              return newYear;
                            });
                            setMonth(11);
                          } else {
                            setMonth((month) => month - 1);
                          }
                        }}
                      />
                    )}
                  </>
                )}
                {monthsExtended[month]} de {year}
                {!view && !viewEdit && (
                  <>
                    {currentYear === year && currentMonth - 1 === month ? (
                      <div className="month-chevron empty" />
                    ) : (
                      <BiChevronRight
                        className="month-chevron"
                        onClick={() => {
                          if (month === 11) {
                            setYear((year) => {
                              const newYear = year + 1;
                              getYearResume(newYear);
                              setDatePickerYear(newYear);
                              return newYear;
                            });
                            setMonth(0);
                          } else {
                            setMonth((month) => month + 1);
                          }
                        }}
                      />
                    )}
                  </>
                )}
              </Typography.Title>
            </Col>
            {!view && !viewEdit && (
              <Col xs={24} sm={8}>
                <DatePicker
                  size="large"
                  picker="month"
                  format="MM-YYYY"
                  disabled={loading || paymentModal}
                  allowClear={false}
                  inputReadOnly={true}
                  onChange={onChangeMonth}
                  suffixIcon={<BsCalendar3 />}
                  defaultValue={defaultValue}
                  locale={antDatePickerLocale}
                  value={moment({ year: datePickerYear, month })}
                  onSelect={(e) => {
                    if (e.month() === month && year !== datePickerYear) {
                      onChangeMonth(e, `${month + 1}-${datePickerYear}`);
                    }
                  }}
                  onPanelChange={(e) => {
                    setYearBatch(e.year());
                    getYearResume(e.year());
                    setDatePickerYear(e.year());
                  }}
                  renderExtraFooter={() =>
                    yearResumeFeature.disabled ? (
                      <></>
                    ) : (
                      <DatePickerExtraFooter
                        year={yearBatch}
                        onClick={() => handleBatchDarf()}
                      />
                    )
                  }
                  disabledDate={(current) =>
                    current &&
                    (current > currentDate.endOf("month") ||
                      current < moment(`${currentBroker.initialYear}-01-01`) ||
                      current < moment(`2020-01-01`))
                  }
                  monthCellRender={(e) => (
                    <DatePickerMonthCell event={e} yearResume={yearResume} />
                  )}
                />
              </Col>
            )}
          </Row>
        </Space>
      </Content>

      <br />

      <Content className={clsx("second", { view, "view-edit": viewEdit })}>
        <Space direction="vertical" className="full-width">
          {allow && view && darf?.fullPath && (
            <Row justify="end">
              <Col>
                <Button
                  type="link"
                  icon={<BsDownload size={16} />}
                  onClick={() => downloadAction(darf)}
                  style={{ marginTop: "-32px", padding: "4px 0" }}
                >
                  BAIXAR DARF
                </Button>
              </Col>
            </Row>
          )}
          <BolsaOperations ref={bolsaOperationsRef}>
            {!allow && (
              <div className="no-plan-container" style={{ minHeight: "300px" }}>
                <Typography>
                  Contrate o plano premium para ver os impostos devidos
                </Typography>
                <Button
                  type="primary"
                  icon={<AiOutlineLock size={18} />}
                  onClick={() => {
                    handlePlanModal();
                    closeModal();
                  }}
                >
                  PREMIUM
                </Button>
              </div>
            )}
            {DarfBolsaTabs(data, {
              handleEditPreju,
              view,
              month,
              year,
            }).map((container, rowIndex) => (
              <div key={rowIndex} className={container.class}>
                {container.tabs.map((item, colIndex) => (
                  <span
                    key={colIndex}
                    className={clsx(
                      colIndex === 0 || tab === colIndex ? "show" : "",
                      {
                        "no-plan": !allow && rowIndex > 0 && colIndex > 0,
                      }
                    )}
                  >
                    {rowIndex === 0 && (
                      <Button
                        type="text"
                        size="small"
                        icon={<GrFormPrevious />}
                        onClick={
                          !allow || colIndex === 0 || tab === 1
                            ? undefined
                            : handlePrev
                        }
                        style={{
                          visibility:
                            !allow || colIndex === 0 || tab === 1
                              ? "hidden"
                              : "visible",
                        }}
                      />
                    )}
                    {!allow && rowIndex > 0 && colIndex > 0
                      ? formatCurrency(13443)
                      : isMobile() && !allow && rowIndex === 0
                      ? ""
                      : item.content}
                    {rowIndex === 0 && (
                      <Button
                        type="text"
                        size="small"
                        icon={<GrFormNext />}
                        onClick={
                          !allow || colIndex === 0 || tab === 3
                            ? undefined
                            : handleNext
                        }
                        style={{
                          visibility:
                            !allow || colIndex === 0 || tab === 3
                              ? "hidden"
                              : "visible",
                        }}
                      />
                    )}
                  </span>
                ))}
              </div>
            ))}
          </BolsaOperations>

          <br />

          <Collapse
            ghost
            bordered={false}
            expandIconPosition="right"
            defaultActiveKey={!allow ? ["1"] : []}
          >
            <Collapse.Panel
              key="1"
              header={
                <Typography.Title level={3}>
                  Vendas realizadas no mês
                </Typography.Title>
              }
            >
              {hasItems && (
                <Button
                  type="link"
                  disabled={loading}
                  onClick={() => {
                    const serializedData: any[] = [];
                    data.memoriaCalculo
                      ?.sort(
                        (a, b) =>
                          new Date(b.originalDate).getTime() -
                          new Date(a.originalDate).getTime()
                      )
                      .forEach((item) => {
                        const ret =
                          item.sellPrice && item.buyPrice
                            ? (item.sellPrice / item.buyPrice - 1) * 100
                            : 0;
                        serializedData.push({
                          Data: item?.date,
                          Operação: item?.operacao,
                          Mercado: item?.market,
                          Ativo: item?.code,
                          Quantidade: item?.quantity,
                          "Preço de Venda": `R$${(item?.sellPrice).toFixed(2)}`,
                          "Custo de Compra": `R$${(item?.buyPrice).toFixed(2)}`,
                          Taxas: `R$${(item?.fee).toFixed(2)}`,
                          Ganho: `R$${(item?.capitalGain).toFixed(2)}`,
                          Retorno: `${ret ? ret.toFixed(2) + "%" : "-"}`,
                        });
                      });
                    handleDownloadSheet(
                      serializedData,
                      "Vendas",
                      "Vendas.xlsx"
                    );
                  }}
                  style={{
                    fontSize: "13px",
                    paddingLeft: "0",
                    alignItems: "center",
                    display: "inline-flex",
                  }}
                  icon={
                    <AiOutlineDownload
                      style={{
                        width: "16px",
                        height: "16px",
                      }}
                    />
                  }
                >
                  Baixar planilha
                </Button>
              )}
              {!view && (
                <Button
                  type="primary"
                  className="add"
                  disabled={loading}
                  onClick={handleAdd}
                  icon={<PlusOutlined />}
                >
                  Adicionar
                </Button>
              )}
              <List
                loading={loading}
                itemLayout="horizontal"
                locale={{
                  emptyText: historicoVendasEmptyText,
                }}
                dataSource={data.memoriaCalculo?.sort(
                  (a, b) =>
                    new Date(b.originalDate).getTime() -
                    new Date(a.originalDate).getTime()
                )}
                renderItem={(item, index) => (
                  <List.Item
                    actions={
                      view
                        ? []
                        : [
                            <Button
                              type="text"
                              onClick={() => handleEdit(item, index)}
                              icon={<FaRegEdit size={16} />}
                            />,
                            <Button
                              type="text"
                              onClick={() => handleRemove(item, index)}
                              icon={<AiOutlineDelete size={16} />}
                            />,
                          ]
                    }
                  >
                    <Skeleton title={false} loading={item.loading} active>
                      <List.Item.Meta
                        title={<strong>{item.code}</strong>}
                        description={
                          <div className="list-description">
                            {/* <div className="market">
                              Op.{" "}
                              {Object.values(TipoOperacao).find(
                                (value) =>
                                  value.toLocaleLowerCase() ===
                                    item.operacao?.toLocaleLowerCase() ?? ""
                              )}{" "}
                              -{" "}
                              {Object.values(TipoMercado).find(
                                (value) =>
                                  value.toLocaleLowerCase() ===
                                    item.market?.toLocaleLowerCase() ?? ""
                              )}
                            </div> */}
                            <div>
                              Data:{" "}
                              {moment(item.date, [
                                "DD/MM/YYYY",
                                "YYYY-MM-DD",
                              ]).format("DD/MM/YYYY")}
                            </div>
                            <div>Quantidade: {item.quantity}</div>
                            {view && (
                              <>
                                <div>
                                  Custo médio de compra:{" "}
                                  <strong>
                                    {formatCurrency(item.buyPrice)}
                                  </strong>
                                </div>
                                <div>
                                  Preço de venda:{" "}
                                  <strong>
                                    {formatCurrency(item.sellPrice)}
                                  </strong>
                                </div>
                              </>
                            )}
                            <div>
                              Ganho:{" "}
                              <strong>
                                {formatCurrency(item.capitalGain)}
                              </strong>
                            </div>
                          </div>
                        }
                      />
                    </Skeleton>
                  </List.Item>
                )}
              />
            </Collapse.Panel>
          </Collapse>

          {allow && <br />}

          {allow && (
            <div
              className="total-tax-header original"
              style={{ width: "100%" }}
            >
              <Typography.Title level={3}>Imposto total</Typography.Title>
              <Typography.Title level={3} className="text-center">
                {loading ? (
                  <Spin style={{ marginRight: "32px" }} />
                ) : (
                  formatCurrency(impostoCharge)
                )}
              </Typography.Title>
            </div>
          )}
          {allow && (
            <Descriptions size="small" bordered layout="horizontal">
              {DarfResultDescriptions.map((description) => (
                <Descriptions.Item
                  span={24}
                  key={description.id}
                  label={description.label(setHelpModal)}
                >
                  {loading ? (
                    <Spin />
                  ) : description.Component ? (
                    <description.Component
                      data={data}
                      year={year}
                      month={month}
                      loading={loading}
                      setDataBolsa={setData}
                      url={transactionFeature.apiUrl}
                      disabled={!view}
                      view={view}
                      valorPrincipal={valorPrincipal}
                      handleEdit={
                        description.id === "irrfTotal"
                          ? handleEditIrrf
                          : undefined
                      }
                      getTaxes={getTaxes}
                    />
                  ) : (
                    formatCurrency(
                      Number(data[description.id as keyof IDarfBolsa] || 0)
                    )
                  )}
                </Descriptions.Item>
              ))}
            </Descriptions>
          )}

          {!view && (
            <>
              <Row
                justify="end"
                gutter={[16, 16]}
                className="column-reverse"
                style={{ marginTop: "16px" }}
              >
                {/* <Col xs={24} sm={12}>
                <Button
                  block
                  size="large"
                  disabled={loading || emitting}
                  onClick={handleNavigateToHistoric}
                >
                  Ver no histórico
                </Button>
              </Col> */}
                {allow &&
                  !(month === currentMonth && year === currentYear) &&
                  valorPrincipal < minDarfPrice &&
                  data?.memoriaCalculo?.length > 0 && (
                    <Col xs={24}>
                      <br />
                      <Tooltip
                        title={
                          <>
                            O valor do imposto devido é menor que{" "}
                            {formatCurrency(minDarfPrice)}. Você não precisa
                            emitir um DARF para este mês. Clique aqui para
                            regularizar este mês.
                          </>
                        }
                      >
                        <Button
                          block
                          size="large"
                          type="primary"
                          disabled={loading || emitting}
                          loading={emitting || loadingDarfButton}
                          onClick={() => {
                            handleRegularize(true);
                          }}
                        >
                          Salvar sem emitir DARF
                        </Button>{" "}
                      </Tooltip>
                    </Col>
                  )}
                {valorPrincipal >= minDarfPrice && (
                  <Col xs={24}>
                    <br />
                    <Tooltip
                      getPopupContainer={(target) => target.parentElement!}
                      title={
                        !allow
                          ? mensagemDarfsRetroativos
                          : item?.month === currentMonth && item?.year === currentYear
                          ? "Aguarde o encerramento do mês para pagar seu DARF"
                            : item?.month === currentMonth - 1 && year === currentYear
                          ? isAntesTerceiroDiaUtil()
                            ? "Aguarde o terceiro dia útil deste mês para pagar seu DARF"
                            : ""
                          : ""
                      }
                    >
                      <Button
                        block
                        size="large"
                        type="primary"
                        icon={!allow && <AiOutlineUnlock />}
                        loading={emitting || loadingDarfButton}
                        onClick={() => handleEventDarfButton()}
                        disabled={
                          loading ||
                          emitting ||
                            (item?.month === currentMonth - 1 && year === currentYear) ?
                            isAntesTerceiroDiaUtil()
                            : item?.month === currentMonth && item?.year === currentYear
                        }
                      >
                        {handleLabelButton}
                      </Button>{" "}
                    </Tooltip>
                  </Col>
                )}
              </Row>
              {allow && !(month === currentMonth && year === currentYear) && (
                <Row gutter={[16, 16]} justify="end" align="middle">
                  <Col>
                    <Button
                      type="link"
                      size="large"
                      onClick={handleSaveMonth}
                      disabled={loading || emitting}
                      icon={!allow && <AiOutlineUnlock />}
                    >
                      Salvar apenas
                    </Button>
                  </Col>
                </Row>
              )}
            </>
          )}

          {!view && !hideOldPositionsText && (
            <div className="juridic-messages-container">
              <div className="message">
                <p>
                  <QuestionCircleOutlined /> Você tinha investimentos em
                  31/12/2019 e ainda não preencheu o seu custo médio?{" "}
                  <span
                    onClick={() =>
                      navigate(`/${currentBroker.path}/bolsa-insert-manual`)
                    }
                    style={{
                      cursor: "pointer",
                      color: "var(--ant-primary-color)",
                    }}
                  >
                    Clique aqui
                  </span>
                </p>
              </div>
            </div>
          )}
        </Space>
      </Content>
      <DrawerModal
        footer={null}
        visible={helpModal}
        title={helpModal?.title}
        onCancel={onCloseHelpModal}
      >
        {helpModal?.content}
      </DrawerModal>
      <FormModal
        add={editPreju}
        edit={() => {}}
        rows={[]}
        itemToEdit={null}
        loading={loadingEditPreju}
        visibility={showEditPrejuConfirmation}
        saveButtonText={"Sim"}
        title={"Editar prejuízos"}
        onCancel={() => setShowEditPrejuConfirmation(false)}
      >
        <div
          style={{
            color: "var(--velotax-font-color-dark)",
          }}
        >
          Você tem certeza que deseja editar os valores calculados de prejuízos
          acumulados?
        </div>
      </FormModal>
      <FormModal
        add={addAsset}
        edit={editAsset}
        loading={loading}
        itemToEdit={asset}
        visibility={showAssetModal}
        onCancel={handleCloseAssetModal}
        rows={DarfBolsaFormItemRows(month, year)}
        title={asset ? "Editar ativo" : "Adicionar ativo"}
      />
      <FormModal
        add={() => {}}
        edit={(data: any) => handleEditPrejuData(data)}
        itemToEdit={preju}
        loading={loadingEditPreju}
        disableButtons={loadingResetPreju || loadingEditPreju}
        rows={PrejuFormItemRows(loadingResetPreju || loadingEditPreju)}
        visibility={showPrejuModal}
        onCancel={handleClosePrejuModal}
        title="Editar prejuízos a compensar"
      >
        {/* <Divider />
        <Row gutter={[16, 16]} align="middle">
          <Col span={24}>
            <Typography>
              Caso altere algum desses valores, você pode voltar aos valores
              calculados automaticamente pelo sistema (clique em aplicar e
              salvar):
            </Typography>
          </Col>
          <Col xs={24} sm={14}>
            <Row>
              <Col span={16}>Prejuízo Comum:</Col>
              <Col span={8}>{formatCurrency(data.prejuizoComum ?? 0)}</Col>
              <Col span={16}>Prejuízo Day Trade:</Col>
              <Col span={8}>{formatCurrency(data.prejuizoDayTrade ?? 0)}</Col>
              <Col span={16}>Prejuízo FII:</Col>
              <Col span={8}>{formatCurrency(data.prejuizoFII ?? 0)}</Col>
            </Row>
          </Col>
          <Col xs={24} sm={10}>
            <Button onClick={handleResetPreju} style={{ marginLeft: "auto" }}>
              Aplicar
            </Button>
          </Col>
        </Row> */}
      </FormModal>
      <FormModal
        add={() => {}}
        edit={editIrrf}
        itemToEdit={irrf}
        rows={IrrfFormItemRows}
        visibility={showIrrfModal}
        onCancel={handleCloseIrrfModal}
        title="Editar IRRF"
      >
        {/* <Divider />
        <Row gutter={[16, 16]} align="middle">
          <Col span={24}>
            <Typography>
              Caso altere algum desses valores, você pode voltar aos valores
              calculados automaticamente pelo sistema (clique em aplicar e
              salvar):
            </Typography>
          </Col>
          <Col xs={24} sm={14}>
            <Row>
              <Col span={16}>IRRF Comum:</Col>
              <Col span={8}>{formatCurrency(data.irrfComum ?? 0)}</Col>
              <Col span={16}>IRRF Day Trade:</Col>
              <Col span={8}>{formatCurrency(data.irrfDayTrade ?? 0)}</Col>
              <Col span={16}>IRRF FII:</Col>
              <Col span={8}>{formatCurrency(data.irrfFII ?? 0)}</Col>
              <Col span={16}>IRRF Total:</Col>
              <Col span={8}>{formatCurrency(data.irrfTotal ?? 0)}</Col>
            </Row>
          </Col>
          <Col xs={24} sm={10}>
            <Button onClick={handleResetIrrf} style={{ marginLeft: "auto" }}>
              Aplicar
            </Button>
          </Col>
        </Row> */}
      </FormModal>
      <PaymentModal
        item={item}
        show={paymentModal}
        emitting={emitting}
        selectedYear={year}
        maxPixValue={maxPixPayment}
        onCancel={handleOpenPaymentModal}
        maxCCValue={maxCreditCardPayment}
        isBatchDarf={isBatchDarf}
        yearBatch={yearBatch}
        resumo={resumo}
        closeDarfModal={closeModal}
        callDarf={() => {
          handleOpenDarfModal();
          HandleTag("76");
        }}
        disablePixOption={checkBlockedOption(maxPixPayment)}
        paymentData={{
          ...paymentData,
          impostoTotalFinal: isBatchDarf ? totalPagarBatch : impostoCharge,
        }}
        disableCreditCardOption={checkBlockedOption(maxCreditCardPayment)}
        openPlanModal={handlePlanModal}
      />
      <DeleteConfirmationModal
        showToast={false}
        title="Remover ativo"
        visibility={showDeleteModal}
        onCancel={handleCloseDeleteModal}
        setVisibility={setShowDeleteModal}
        onOk={() => Promise.resolve(removeAsset())}
        body="Você realmente quer deletar esse ativo?"
      />
      <ModalZeroBuyPrice
        visible={modalZeroBuyPrice}
        title={'Atenção'}
        dataZeroBuyPrice={dataZeroBuyPrice}
        setIgnoreZeroBuyPrice={setIgnoreZeroBuyPrice}
        setModalZeroBuyPrice={setModalZeroBuyPrice}
      >
      </ModalZeroBuyPrice>
    </Container>
  );
};
