import { useEffect } from "react";
import { Button, Col, Row, Typography } from "antd";
import { BsArrowLeftRight, } from "react-icons/bs";
import {  Content, Parceiros, Subtitle } from "./styles";
import { Page } from "../../constants/brokers";
import b3Logo from "../../assets/b3-logo-azul.png";
import { useAuth } from "../../contexts/AuthContext";
import { useBroker } from "../../contexts/BrokerContext";
import { useB3Integration } from "../../contexts/B3IntegrationContext";
import { AuthContainer } from "../../styles/LoginSignUp/authContainer";
import { ReactComponent as VeloIcon } from "../../assets/velotax/veloicon.svg";

interface UserNoHasAccountProps {
  item: Page;
}

export const UserNoHasAccount: React.FC<UserNoHasAccountProps> = () => {
  const { integration } = useBroker();
  const { user, cameFromRegister, setCameFromRegister } = useAuth();
  const {
    interval,
    b3Authorized,
    handleIntegrate,
    handleSkipB3Integration,
  } = useB3Integration();

  useEffect(() => {
    if (
      user.user &&
      !b3Authorized &&
      !interval.current &&
      cameFromRegister.bolsa &&
      integration.key !== "unset"
    ) {
      setCameFromRegister((data) => ({ ...data, bolsa: false }));
      handleIntegrate();
    }
  }, [
    interval,
    user.user,
    b3Authorized,
    handleIntegrate,
    integration.key,
    setCameFromRegister,
    cameFromRegister.bolsa,
  ]);

  return (
    <AuthContainer className="beauty-scrollbar">
      <div className="auth-content">
        <div className="form-container">
          <Typography.Title level={1}>
            <Row align="bottom" justify="space-between">
              <Col>Conecte sua conta à B3</Col>
              <Col>
                <Parceiros>
                  Parceiros oficiais
                  <div className="parceiros">
                    <VeloIcon className="veloicon" />
                    <BsArrowLeftRight className="arrow" />
                    <img src={b3Logo} alt="b3-logo" />
                  </div>
                </Parceiros>
              </Col>
            </Row>
          </Typography.Title>
          <Typography.Title level={4}>
            <Row align="bottom" justify="space-between">
              <Subtitle>
                <Col>
                  Não localizamos o seu cadastro na Área do Investidor da B3.
                </Col>
              </Subtitle>
            </Row>
          </Typography.Title>
          
          <Content>
            <div className="skip-btn">
              <Button
                type="link"
                size="small"
                onClick={handleSkipB3Integration}
              >
                Não tenho investimento na bolsa
                {/* <AiOutlineArrowRight /> */}
              </Button>
            </div>
          </Content>

        </div>
      </div>
    </AuthContainer>
  );
};
