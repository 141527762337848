import ReactInputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import { AiFillWarning } from "react-icons/ai";
import React, { useEffect, useState } from "react";
import { CheckOutlined, LoadingOutlined } from "@ant-design/icons";
import { Col, Form, message, Row, Input, Typography, Divider } from "antd";
import api from "../../services/api";
import { Container } from "./styles";
import { MeuPlano } from "./MeuPlano";
import { cpfInputMask } from "../../utils";
import Button from "../../components/Button";
import { useAuth } from "../../contexts/AuthContext";
import { DrawerModal } from "../../components/DrawerModal";
import { FooterModal } from "../../components/FooterModal";
import {
  validationCpf,
  validationDateNotRequired,
  validationEmail,
  validationFieldRequired,
  validationFullName,
  validationPassword,
  validationPhone,
} from "../../utils/formValidations";

const KEY_PASS = "DELETAR";

export const MinhaConta: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [formKey] = Form.useForm();
  const { user, setUser, signOut } = useAuth();

  const [keyPass, setKeyPass] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [warningDeleteModal, showWarningDeleteModal] = useState(false);
  const [loadingExclude, setLoadingExclude] = useState(false);

  useEffect(() => {
    if (user?.user) {
      form.setFieldsValue({
        cpf: user.user.cpf,
        email: user.user.email,
        birthdate: user.user.birthdate ?? "",
        cellphone: user.user.cellphone ?? "",
        name: user.user.name ?? "",
      });
    }
  }, [user.user, form]);

  const onFinish = (data: any) => {
    if (loading) {
      return;
    }
    setLoading(true);
    api
      .put("/configuracoes/minhaConta", {
        email: data.email,
        birthdate: data.birthdate,
        cellphone: data.cellphone,
        name: data.name,
        ...(showPassword
          ? {
              pass: data.pass,
              currentPass: data.currentPass,
            }
          : {}),
      })
      .then(() => {
        message.config({
          top: 50,
        });
        message.success("Dados atualizados com sucesso");
        setLoading(false);
        setUser({
          ...user,
          user: {
            ...user.user,
            email: data.email,
            birthdate: data.birthdate,
            cellphone: data.cellphone,
            name: data.name,
          },
        });
      })
      .catch((err) => {
        message.error(
          err.response?.data?.message ||
            "Erro ao atualizar dados. Tente novamente mais tarde!"
        );
        setLoading(false);
      });
  };

  const removeAccount = () => {
    showWarningDeleteModal(true);
  };

  const onCancel = () => {
    if (loading) {
      return;
    }
    navigate("/carteira");
  };

  const handlePassword = () => {
    setShowPassword((show) => !show);
  };

  const sendDeleteReq = () => {
    setLoadingExclude(true);

    if (keyPass.toLowerCase() !== KEY_PASS.toLowerCase()) {
      message.error(`Digite ${KEY_PASS} corretamente!`);
      return;
    }

    api
      .post("/user/remove-user", {
        from: "CALCULADORAV2",
        pass: keyPass,
        cpf: user.user.cpf,
      })
      .then(() => {
        message.success("Conta excluída com sucesso");
        setLoadingExclude(false);
        signOut();
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
        setLoadingExclude(false);
      });
  };

  const onDeleteCancel = () => {
    showWarningDeleteModal(false);
    formKey.resetFields();
    setKeyPass("");
  };

  return (
    <Container className="velotax-container">
      <MeuPlano />
      <div className="velotax-content">
        <Typography.Title level={1} className="page-title">
          Dados do usuário
        </Typography.Title>
        <p>Mantenha os seus dados de cadastro sempre atualizados.</p>
        <Form form={form} size="large" layout="vertical" onFinish={onFinish}>
          <Form.Item
            name="name"
            label="Nome completo"
            rules={validationFullName}
          >
            <Input placeholder="Nome completo" disabled={loading} />
          </Form.Item>

          <Form.Item name="cpf" label="CPF" rules={validationCpf}>
            <ReactInputMask
              disabled={true}
              placeholder="CPF"
              mask={cpfInputMask}
              className="ant-input ant-input-lg"
            />
          </Form.Item>

          <Form.Item
            name="birthdate"
            label="Nascimento"
            rules={validationDateNotRequired}
          >
            <ReactInputMask
              mask="99/99/9999"
              disabled={loading}
              placeholder="Nascimento"
              className="ant-input ant-input-lg"
            />
          </Form.Item>

          <Form.Item name="email" label="E-mail" rules={validationEmail}>
            <Input placeholder="E-mail" disabled={loading} />
          </Form.Item>

          <Form.Item name="cellphone" label="Celular" rules={validationPhone}>
            <ReactInputMask
              disabled={loading}
              mask="(99) 99999-9999"
              placeholder="Celular"
              className="ant-input ant-input-lg"
            />
          </Form.Item>

          <Button type="link" onClick={handlePassword} style={{ padding: 0 }}>
            {showPassword ? "Não alterar senha" : "Alterar senha"}
          </Button>

          {showPassword && (
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  name="currentPass"
                  label="Senha atual"
                  rules={validationFieldRequired}
                >
                  <Input.Password
                    visibilityToggle={false}
                    placeholder="*****"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="pass"
                  label="Nova senha"
                  rules={validationPassword}
                >
                  <Input.Password
                    visibilityToggle={false}
                    placeholder="*****"
                  />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Divider />

          <Row gutter={[16, 16]} justify="space-between">
            <Col>
              <Button size="large" onClick={onCancel} disabled={loading}>
                Cancelar
              </Button>
            </Col>
            <Col>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Salvar
                <CheckOutlined />
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      <Row justify="end" className="excluir-container">
        <Col>
          <Button
            danger
            type="text"
            size="small"
            disabled={loading}
            onClick={removeAccount}
          >
            Excluir Conta
          </Button>
        </Col>
      </Row>
      <DrawerModal
        okText="Excluir"
        cancelText="Cancelar"
        title="Exclusão de conta"
        confirmLoading={loadingExclude}
        onOk={sendDeleteReq}
        onCancel={onDeleteCancel}
        visible={warningDeleteModal}
        footer={
          <FooterModal
            onOk={sendDeleteReq}
            loading={loadingExclude}
            onCancel={onDeleteCancel}
            saveButtonText="EXCLUIR"
          />
        }
      >
        {loadingExclude ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <LoadingOutlined />
          </div>
        ) : (
          <Row gutter={[16, 16]} align="middle">
            <Col span={10}>
              <AiFillWarning
                size={100}
                color="var(--ant-warning-color)"
                style={{ display: "block", margin: "0 auto" }}
              />
            </Col>
            <Col span={14}>
              <Typography.Paragraph strong style={{ fontSize: "1rem" }}>
                Deseja realmente excluir sua conta?
              </Typography.Paragraph>
              <Typography.Paragraph>
                Lembre-se de que todos os seus dados também serão excluídos.
                Caso possua um plano ativo, você também perderá todas as
                funcionalidades.
              </Typography.Paragraph>
            </Col>
            <Col span={24}>
              <Typography style={{ fontSize: "1rem" }}>
                Digite <strong>{KEY_PASS}</strong> para prosseguir:
              </Typography>
            </Col>
            <Col span={24}>
              <Form
                form={formKey}
                onChange={(event: any) => {
                  setKeyPass(event.target.value);
                }}
              >
                <Form.Item name="key">
                  <Input placeholder={KEY_PASS} />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        )}
      </DrawerModal>
    </Container>
  );
};
