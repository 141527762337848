import styled from "styled-components";

export const ReportTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;

  .divider {
    width: 100%;
    max-width: 80px;
    background-color: var(--ant-primary-color);
    height: 2px;
    margin-bottom: 1rem;
  }

  .loading {
    display: flex;

    h5 {
      margin-left: 1rem;
    }
  }

  button.ant-btn.buttonDownload {
    svg {
      width: 22px;
      height: 22px;
    }
  }

  .textInfo {
    display: flex;
    align-items: start;
    gap: 12px;
    padding: 2px;
    font-size: 11px;
    color: var(--velotax-font-color-ghost);
  }

  button.ant-btn {
    font-size: 12px !important;
    text-transform: none !important;
    svg {
      width: 16px;
      height: 16px;
    }
  }
`;
