import { Col, Image, Row, Typography } from "antd";
import { DrawerModal, DrawerModalProps } from "../../DrawerModal";
import { ReactComponent as WarrenLogo } from "../../../assets/warren-logo.svg";
import warrenIntegrationExample from "../../../assets/integracao_warren_exterior.gif";

interface WarrenIntegrationModalProps extends DrawerModalProps {}

export const WarrenIntegrationModal: React.FC<WarrenIntegrationModalProps> = (
  props
) => {
  return (
    <DrawerModal
      {...props}
      title={
        <Row align="middle" gutter={16}>
          <Col>
            <WarrenLogo />
          </Col>
          <Col>Integrar com a Warren</Col>
        </Row>
      }
    >
      <Typography.Paragraph>
        Para integrar com a Warren, realize os seguintes passos:
      </Typography.Paragraph>
      <ul style={{ listStyle: "inside" }}>
        <li>Acesse o aplicativo da Warren</li>
        <li>
          Na parte inferior da tela principal clique em{" "}
          <b>"Imposto de renda e DARF"</b>
        </li>
        <li>
          {" "}
          Clique no botão <b>"Continuar"</b>{" "}
        </li>
      </ul>
      <div
        style={{
          display: "flex",
          width: "100%",
          margin: "2rem 0",
          justifyContent: "center",
        }}
      >
        <Image
          width={430}
          src={warrenIntegrationExample}
        />
      </div>
      <Typography.Paragraph>
        Após realizar esses passos, sua integração será concluída. Você então
        poderá atualizar a página atual para confirmar que a conexão funcionou
        corretamente.
      </Typography.Paragraph>
    </DrawerModal>
  );
};
