import clsx from "clsx";
import moment from "moment";
import { useState } from "react";
import { Form, message } from "antd";
import { VscClose } from "react-icons/vsc";
import { FaRegEdit } from "react-icons/fa";
import NumberFormat from "react-number-format";
import { InfoCircleOutlined } from "@ant-design/icons";
import apis from "../../services/apis";
import Button from "../../components/Button";
import { useBroker } from "../../contexts/BrokerContext";
import { CurrencyFormItem, DateFormItem, NumberFormItem, SelectFormItem, TextFormItem } from "../formItems";
import {
  currencyToNumber,
  errorMessage,
  formatCurrency,
  isMobile,
  isValidDate,
  months,
  zero,
} from "../../utils";
import {
  IDarf,
  IDarfBolsa,
  IQuotes,
  JurosModalProps,
  minDarfPrice,
  MinPriceModalProps,
  MultaModalProps,
  yearConfigPL4173,
} from "../darf";
import {
  validationDateIsOnReferenceMonth,
  validationDateIsOnReferenceMonthCompra,
  validationFieldRequired,
  validationNumberAllowNegativeField,
  validationNumberFieldPositive,
} from "../../utils/formValidations";

interface IEdit {
  value: any;
  id: string;
  isEditting: boolean;
}

interface DarfResultDescriptionsProps {
  id: string;
  label: (onClick: Function) => React.ReactNode;
  Component: React.FC<{
    data: IDarf | IDarfBolsa;
    url: string;
    year: number;
    month: number;
    loading: boolean;
    disabled: boolean;
    view?: boolean;
    handleEdit?: Function;
    setDataCripto?: React.Dispatch<React.SetStateAction<IDarf>>;
    setDataBolsa?: React.Dispatch<React.SetStateAction<IDarfBolsa>>;
  }>;
}

export const DarfResultDescriptionsExterior: DarfResultDescriptionsProps[] = [
  {
    id: "impostoDevido",
    label: () => "(+) Valor do principal",
    Component: ({ data, view }) =>
      formatCurrency(
        Math.max(Number(data["impostoDevido"] || 0) - Number(data["irrfExterior"] || 0), 0)
      ),
  },
  {
    id: "impostoDevido",
    label: () => (
      <div
        className="desc-label"
        style={{ fontSize: "12px", paddingLeft: "24px" }}
      >
        (+) Imposto devido
      </div>
    ),
    Component: ({ data, view }) =>
      formatCurrency(Number(data["impostoDevido"] || 0)),
  },
  {
    id: "impostoAcumulado",
    label: (onClick: any) => (
      <div
        className="desc-label"
        style={{ fontSize: "12px", paddingLeft: "24px" }}
      >
        (+) DARFs passados abaixo de R$ {minDarfPrice}
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          onClick={() => onClick(MinPriceModalProps)}
        />
      </div>
    ),
    Component: ({
      data,
      year,
      month,
      loading,
      disabled,
      setDataBolsa,
      setDataCripto,
      url,
    }) => {
      const { currentPage } = useBroker();
      const [edit, setEdit] = useState<IEdit>();
      const [editForm] = Form.useForm();
      const handleEditValue = (id: string) => {
        const value = data[id as keyof (IDarf | IDarfBolsa)];
        setEdit({ id, isEditting: true, value });
        editForm.setFieldsValue({ [id]: value });
      };

      const handleEditValueCancel = () => {
        setEdit(undefined);
        editForm.resetFields();
      };
      const handleEditValueConfirm = () => {
        if (edit && edit.value >= 0 && edit.value < minDarfPrice) {
          (currentPage?.api || apis)
            .post(`${url}/updateDataDarf`, {
              year,
              month: month + 1,
              [edit.id]: edit.value,
            })
            .then(() =>
              (setDataCripto ?? setDataBolsa)?.((data: any) => ({
                ...data,
                [edit.id]: edit.value,
              }))
            )
            .catch(() => message.error(errorMessage))
            .finally(() => handleEditValueCancel());
        } else {
          handleEditValueCancel();
        }
      };
      return edit && edit.isEditting && edit.id === "impostoAcumulado" ? (
        <div className="desc-content is-editting">
          {!isMobile() && (
            <Button icon={<VscClose />} onClick={handleEditValueCancel} />
          )}
          <Form
            form={editForm}
            onValuesChange={(changed) => {
              const value = currencyToNumber(changed.impostoAcumulado);
              editForm.setFieldsValue({ impostoAcumulado: value });
              setEdit((edit) => ({ ...edit!, value }));
            }}
          >
            <Form.Item
              name="impostoAcumulado"
              rules={[
                {
                  message: `Deve ser menor que ${minDarfPrice}`,
                  validator: (rule, value) =>
                    typeof value === "number" &&
                    value >= 0 &&
                    value < minDarfPrice
                      ? Promise.resolve()
                      : Promise.reject(),
                },
              ]}
            >
              <NumberFormat
                prefix="R$ "
                decimalScale={2}
                disabled={loading}
                decimalSeparator=","
                thousandSeparator="."
                className="ant-input"
                allowNegative={false}
              />
            </Form.Item>
          </Form>
          <Button
            type="primary"
            onClick={handleEditValueConfirm}
            disabled={edit.value < 0 || edit.value >= minDarfPrice}
          >
            Ok
          </Button>
          {isMobile() && (
            <Button icon={<VscClose />} onClick={handleEditValueCancel} />
          )}
        </div>
      ) : (
        <div
          className={clsx("desc-content", {
            "ml-40": disabled,
          })}
        >
          {formatCurrency(Number(data.impostoAcumulado || 0))}
          {disabled && (
            <Button
              type="text"
              icon={<FaRegEdit />}
              onClick={() => handleEditValue("impostoAcumulado")}
            />
          )}
        </div>
      );
    },
  },
  {
    id: "irrfTotal",
    label: () => (
      <div
        className="desc-label"
        style={{ fontSize: "12px", paddingLeft: "24px" }}
      >
        (-) Imposto de Renda Retido na Fonte
      </div>
    ),
    Component: ({ data, handleEdit }) => (
      <div className="desc-content ml-40">
        {formatCurrency(
          Number(
            (data as IDarfBolsa)["userIrrfTotal"] ||
              (data as IDarfBolsa)["irrfTotal"] ||
              0
          )
        )}
        <Button
          type="text"
          icon={<FaRegEdit />}
          onClick={() => handleEdit?.()}
        />
      </div>
    ),
  },
  {
    id: "irrfExterior",
    label: () => (
      <div
        className="desc-label"
        style={{ fontSize: "12px", paddingLeft: "24px" }}
      >
        (-) Imposto de Renda Retido na Fonte
      </div>
    ),
    Component: ({ data, handleEdit }) => {
      return (
      <div className="desc-content">
        {formatCurrency(
          Number(
              (data as IDarfBolsa)["irrfExterior"] ||
              0
          )
        )}
        {/* <Button
          type="text"
          icon={<FaRegEdit />}
          onClick={() => handleEdit?.()}
        /> */}
      </div>
    )},
  },
  {
    id: "multa",
    label: (onClick: any) => (
      <div className="desc-label">
        (+) Multa de atraso{" "}
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          onClick={() => onClick(MultaModalProps)}
        />
      </div>
    ),
    Component: ({ data }) =>
      formatCurrency(
        (data["impostoDevido"] || 0) + (data["impostoAcumulado"] || 0) - (data["irrfExterior"] || 0) >=
          minDarfPrice
          ? Number(data["multa"])
          : 0
      ),
  },
  {
    id: "juros",
    label: (onClick: any) => (
      <div className="desc-label">
        (+) Juros e/ou encargos de atraso{" "}
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          onClick={() => onClick(JurosModalProps)}
        />
      </div>
    ),
    Component: ({ data }) =>
      formatCurrency(
        (data["impostoDevido"] || 0) + (data["impostoAcumulado"] || 0) - (data["irrfExterior"] || 0) >=
          minDarfPrice
          ? Number(data["juros"])
          : 0
      ),
  },
];

export enum FormMovimentTypeMode {
  VENDA = "Venda de ativos",
  JUROS = "Juros de aplicações",
}

export enum FormMovimentTypeModeNew {
  VENDA = "Venda de ativos",
  JUROS = "Juros de bonds",
  DIVIDENDOS = "Dividendos",
  OUTROS = "Outros rendimentos",
}


const FormItemsExterior = {
  id: TextFormItem({
    name: "id",
    label: "Id",
    hidden: true,
  }),
  movimentType: (movimentType: any, setMovimentType: any, year: number, fromApi?: boolean) =>
    SelectFormItem({
      name: "movimentType",
      label: "Tipo de movimentação",
      value: movimentType,
      disabled: fromApi,
      defaultValue: FormMovimentTypeMode.VENDA,
      rules: [],
      options: year < yearConfigPL4173 
      ? [
        { label: "Venda de ativos", value: FormMovimentTypeMode.VENDA },
        { label: "Juros de aplicações", value: FormMovimentTypeMode.JUROS },
      ] 
      : [
        { label: "Venda de ativos", value: FormMovimentTypeModeNew.VENDA },
        { label: "Juros de bonds", value: FormMovimentTypeModeNew.JUROS },
        { label: "Dividendos", value: FormMovimentTypeModeNew.DIVIDENDOS },
        { label: "Outros rendimentos", value: FormMovimentTypeModeNew.OUTROS },
      ],
      onChange(changed, values) {
        setMovimentType(
          changed.movimentType !== undefined
            ? changed.movimentType
            : movimentType
        );
        return changed.movimentType !== undefined
          ? changed.movimentType
          : movimentType;
      },
    }),
  ativo: (placeholder: string, fromApi?: boolean) =>
    TextFormItem({
      name: "ativo",
      label: "Ativo",
      disabled: fromApi,
      placeholder,
      rules: validationFieldRequired,
    }),
  valueOriginBRA: CurrencyFormItem({
    prefix: "US$ ",
    name: "valueOriginBRA",
    label: "Origem BRA (US$)",
    placeholder: "US$ 0,00",
  }),
  valueOriginEUA: CurrencyFormItem({
    prefix: "US$ ",
    name: "valueOriginEUA",
    label: "Origem USA (US$)",
    placeholder: "US$ 0,00",
  }),
  dataCompra: () =>
    DateFormItem({
      name: "dataCompra",
      label: "Data da Liquidação",
      placeholder: "DD/MM/YYYY",
      mask: `99/99/9999`,
      rules: validationDateIsOnReferenceMonthCompra,
    }),
  cotacaoCompra: (quotations: IQuotes) =>
    TextFormItem({
      disabled: true,
      name: "cambioCompra",
      label: "Cotação dólar",
      onChange: (changed, values) => {
        if (isValidDate(values.dataCompra)) {
          let i = 0;
          let keycompra = changed.dataCompra || values.dataCompra;
          const isComplete = !String(keycompra).includes("_");
          if (!keycompra || !isComplete) return values.cotacaoCompra;
          while (isComplete && !quotations[keycompra] && i <= 10) {
            keycompra = moment(keycompra, "DD/MM/YYYY")
              .subtract(1, "days")
              .format("DD/MM/YYYY");
            i++;
          }
          const { venda } = quotations[keycompra] || { venda: 0 };
          return venda;
        }
      },
    }),
  precoVenda: CurrencyFormItem({
    prefix: "US$ ",
    name: "valor",
    placeholder: "US$ 0,00",
    label: "Valor Bruto (US$)",
  }),
  fee: CurrencyFormItem({
    name: "fee",
    label: "Taxa de corretagem (US$)",
    placeholder: "US$ 0,00",
    prefix: "US$ ",
  }),
  dataVenda: (month: number, year: number) =>
    DateFormItem({
      name: "data",
      label: "Data da Liquidação",
      placeholder: `01/${zero(month + 1)}/${year}`,
      mask: `99/${zero(month + 1).replace(/[9]/g, `\\9`)}/${year
        .toString()
        .replace(/[9]/g, `\\9`)}`,
      rules: validationDateIsOnReferenceMonth(month, year),
    }),
  dataVendaFee: (month: number, year: number) =>
    DateFormItem({
      name: "dataFee",
      label: "Data da Liquidação",
      placeholder: `01/${zero(month + 1)}/${year}`,
      mask: `99/${zero(month + 1).replace(/[9]/g, `\\9`)}/${year
        .toString()
        .replace(/[9]/g, `\\9`)}`,
      rules: validationDateIsOnReferenceMonth(month, year),
    }),
  cotacaoVenda: (quotations: IQuotes) =>
    TextFormItem({
      disabled: true,
      name: "cambioVenda",
      label: "Cotação dólar",
      onChange: (changed, values) => {
        if (isValidDate(values.data)) {
          let i = 0;
          let keyvenda = changed.data || values.data;
          const isComplete = !String(keyvenda).includes("_");
          if (!keyvenda || !isComplete) return values.cotacaoVenda;
          while ((isComplete && !quotations[keyvenda]) || i === 5) {
            keyvenda = moment(keyvenda, "DD/MM/YYYY")
              .subtract(1, "days")
              .format("DD/MM/YYYY");
            i++;
          }
          const { compra } = quotations[keyvenda] || { compra: 0 };
          return compra;
        }
      },
    }),
  cotacaoVendaFee: (quotations: IQuotes) =>
    TextFormItem({
      disabled: true,
      name: "cambioVendaFee",
      label: "Cotação dólar",
      onChange: (changed, values) => {
        if (isValidDate(values.dataFee)) {
          let i = 0;
          let keyvenda = changed.dataFee || values.dataFee;
          const isComplete = !String(keyvenda).includes("_");
          if (!keyvenda || !isComplete) return values.cotacaoVendaFee;
          while ((isComplete && !quotations[keyvenda]) || i === 5) {
            keyvenda = moment(keyvenda, "DD/MM/YYYY")
              .subtract(1, "days")
              .format("DD/MM/YYYY");
            i++;
          }
          const { compra } = quotations[keyvenda] || { compra: 0 };
          return compra;
        }
      },
    }),
  ganho: CurrencyFormItem({
    name: "ganho",
    label: `Ganho (R$)`,
    disabled: true,
    rules: [],
    onChange: (changed, values) => {
      // TODO Atualmente há perda de centavos entre a conversão sucessiva entre BRL e USD
      let A = changed.valueOriginBRA
        ? currencyToNumber(changed.valueOriginBRA, "US$")
        : values.valueOriginBRA || 0;
      let B = changed.valueOriginEUA
        ? currencyToNumber(changed.valueOriginEUA, "US$")
        : values.valueOriginEUA || 0;
      const C = values.cambioCompra || 1;
      const D = changed.valor
        ? currencyToNumber(changed.valor, "US$")
        : values.valor || 0;
      const E = changed.fee
        ? currencyToNumber(changed.fee, "US$")
        : values.fee || 0;
      const F = values.cambioVenda || 1;

      const correctionZero = 1e-50;
      if (A === 0) A = correctionZero;
      if (B === 0) B = correctionZero;

      const ganho =
        (A / (A + B)) * (D - E) * F - A * C + ((B / (A + B)) * (D - E) - B) * F;

      return Number(Math.max(ganho, 0).toFixed(2));
    },
  }),
  ganhoFee: CurrencyFormItem({
    name: "ganhoFee",
    label: `Rendimento (R$)`,
    disabled: true,
    rules: [],
    onChange: (changed, values) => {
      // TODO Atualmente há perda de centavos entre a conversão sucessiva entre BRL e USD
      const A = values.cambioVendaFee || 1;

      const B = changed.valor
        ? currencyToNumber(changed.valor, "US$")
        : values.valor || 0;

      const C = changed.fee
        ? currencyToNumber(changed.fee, "US$")
        : values.fee || 0;

      const ganho = (B - C) * A;
      return Number(Math.max(ganho, 0).toFixed(2));
    },
  }),
  date: (month: number, year: number, fromApi?: boolean) =>
    DateFormItem({
      name: "data",
      disabled: fromApi,
      label: `Dia do mês de ${months[month]}/${year}`,
      placeholder: `01/${zero(month + 1)}/${year}`,
      mask: `99/${zero(month + 1).replace(/[9]/g, `\\9`)}/${year
        .toString()
        .replace(/[9]/g, `\\9`)}`,
      rules: validationDateIsOnReferenceMonth(month, year),
    }),
  quantidade: (placeholder: string, fromApi?: boolean) =>
    NumberFormItem({
      disabled: fromApi,
      name: "quantidade",
      label: "Quantidade",
      placeholder,
      decimalScale: 12,
      rules: validationNumberFieldPositive,
    }),
  valor: CurrencyFormItem({ name: "sellPrice_BRL", label: "Preço de venda (R$)" }),
  valorNewModel: (fromApi?: boolean) => 
    CurrencyFormItem({ name: "sellPrice_BRL", disabled: fromApi, label: "Preço de venda (R$)" }),
  capitalGain: CurrencyFormItem({
    name: "ganho",
    label: "Ganho",
    disabled: true,
    allowNegative: true,
    rules: validationNumberAllowNegativeField,
    onChange: (changed, values) => {
      const precoVenda = changed.valor
        ? currencyToNumber(changed.valor)
        : values.valor;
      const custoCompra = changed.valueOriginBRA
        ? currencyToNumber(changed.valueOriginBRA)
        : values.valueOriginBRA;
      const taxas = changed.fee
        ? currencyToNumber(changed.fee)
        : values.fee;
      return Number(values.quantidade) * (precoVenda - custoCompra) - taxas;
    },
  }),
  capitalGainBRL: CurrencyFormItem({
    name: "varTotalBRL",
    label: "Ganho",
    disabled: true,
    allowNegative: true,
    rules: validationNumberAllowNegativeField,
    onChange: (changed, values) => {
      const precoVenda = changed.sellPrice_BRL
        ? currencyToNumber(changed.sellPrice_BRL)
        : values.sellPrice_BRL;
      const custoCompra = changed.buyPrice_BRL
        ? currencyToNumber(changed.buyPrice_BRL)
        : values.buyPrice_BRL;
      const taxas = changed.fee_BRL
        ? currencyToNumber(changed.fee_BRL)
        : values.fee_BRL;
      return Number(values.quantidade) * (precoVenda - custoCompra) - taxas;
    },
  }),
  valueOriginBRL: (fromApi?: boolean) => CurrencyFormItem({
    disabled: fromApi,
    prefix: "R$ ",
    name: "buyPrice_BRL",
    label: "Custo de Compra (R$)",
    placeholder: "R$ 0,00",
  }),
  feeBRL: (fromApi?: boolean) => CurrencyFormItem({
    disabled: fromApi,
    name: "fee_BRL",
    label: "Taxa de corretagem (R$)",
    placeholder: "R$ 0,00",
    prefix: "R$ ",
  }),
};

export const ExteriorFormItemRows = (
  month: number,
  year: number,
  quotations: IQuotes
) => [
  [FormItemsExterior.ativo("Ex: GOOGL"), FormItemsExterior.id],
  [
    {
      label: "",
      name: "",
      input: <div className="exterior-content-title">Compra</div>,
    },
  ],
  [FormItemsExterior.valueOriginBRA, FormItemsExterior.valueOriginEUA],
  [FormItemsExterior.dataCompra(), FormItemsExterior.cotacaoCompra(quotations)],
  [
    {
      label: "",
      name: "",
      input: <div className="exterior-content-title">Venda</div>,
    },
  ],
  [FormItemsExterior.precoVenda, FormItemsExterior.fee],
  [
    FormItemsExterior.dataVenda(month, year),
    FormItemsExterior.cotacaoVenda(quotations),
  ],
  [FormItemsExterior.ganho],
];

export const ExteriorNewFormItemRows = (
  month: number,
  year: number,
  quotations: IQuotes,
  editAsset: any,
  asset: any,
  movimentType: string,
  setMovimentType: React.Dispatch<React.SetStateAction<string>>,
  fromApi?: boolean
) => {
  return [
    asset ? []
      : movimentType === "Venda de ativos" ? [FormItemsExterior.movimentType(movimentType, setMovimentType, year, fromApi)] 
      : [
        FormItemsExterior.movimentType(movimentType, setMovimentType, year, fromApi),
      ],
    movimentType === "Venda de ativos" ? [] : [
          {
            label: "",
            name: "",
            input: (
              <div className="exterior-content-title">
                {
                  ['Juros de bonds'].includes(movimentType || '') ? "Juros" 
                  : ['Dividendos'].includes(movimentType || '') ? "Dividendos" 
                  : "Rendimentos"}
              </div>
            ),
          },
        ],
    movimentType === "Venda de ativos"
      ? []
      : [FormItemsExterior.ativo("Ex: XP", fromApi)],
    movimentType === "Venda de ativos"
      ? [FormItemsExterior.date(month, year, fromApi), FormItemsExterior.ativo("Ex: XP", fromApi)]
      : [],
    movimentType === "Venda de ativos"
      ? [
          FormItemsExterior.quantidade("Ex: 100", fromApi),
          FormItemsExterior.valorNewModel(fromApi),
        ]
      : [],
    movimentType === 'Venda de ativos' ? [FormItemsExterior.valueOriginBRL(fromApi), FormItemsExterior.feeBRL(fromApi)]
      : [FormItemsExterior.precoVenda, FormItemsExterior.fee],
    movimentType === "Venda de ativos"
      ? [
          FormItemsExterior.capitalGainBRL,
        ]
      : [
          FormItemsExterior.dataVendaFee(month, year),
          FormItemsExterior.cotacaoVendaFee(quotations),
        ],
    movimentType === "Venda de ativos"
      ? []
      : [FormItemsExterior.ganhoFee],
  ];
};
