import _ from "lodash";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import { BsDownload } from "react-icons/bs";
import { Select, Tooltip, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { AiOutlineLock, AiOutlineSearch } from "react-icons/ai";
import Loading from "../Loading";
import { ChartBar } from "../ChartBar";
import { BlurContent } from "./styles";
import apiBolsa from "../../services/apiBolsa";
import AntButton from "../../components/Button";
import HandleTag from "../../services/handleTag";
import { ProventosModal } from "../ProventosModal";
import { download, downloadPDF } from "../../utils";
import { useAuth } from "../../contexts/AuthContext";
import { ContainerProventos, Content } from "./styles";
import { ReportTypeDrawer } from "../ReportType/ReportType";
import { useNotification } from "../../contexts/NotificationContext";
import * as XLSX from "xlsx";
import { downloadExcelFromApi, isIosPlatform } from "../../utils/webview";

export const Proventos = ({ hideValues }: any) => {
  const { getNotification } = useNotification();

  const { user, hasPlan, showUserPlanModal } = useAuth();
  const isBasic = user.user?.userPlanInfoVelotax?.type?.includes('BASIC');

  const [openModal, setOpenModal] = useState(false);
  const [proventos, setProventos] = useState<any[]>([]);
  const [monthProventos, setMonthProventos] = useState<any>({});
  const [loadingDownload, setLoadingDownload] = useState(false);
  // const [appliedFilters, setAppliedFilters] = useState<any>({});
  const [proventosLoading, setProventosLoading] = useState<any>({});
  const [loadingReport, setLoadingReport] = useState<boolean>(false);
  const [typeProventos, setTypeProventos] = useState<any>("provento");
  const [reportDrawerProventos, setReportDrawerProventos] = useState(false);
  const [yearProventos, setYearProventos] = useState<any>(
    new Date().getFullYear()
  );

  useEffect(() => {
    setProventosLoading(true);
    apiBolsa
      .get(`/wallet/get-proventos`, {
        params: { year: yearProventos },
      })
      .then((res) => {
        setProventos(res.data.proventos);
        if (res?.data?.updated) {
          getNotification();
        }
      })
      .finally(() => setProventosLoading(false));
  }, [user?.user?.cpf, getNotification, yearProventos]);

  const monthReport = (data?: any, index?: any) => {
    setOpenModal(true);
    if (data) {
      setMonthProventos(data);
    } else {
      const date = new Date();
      const y = date.getFullYear();
      const m = date.toLocaleString("pt-BR", { month: "short" });
      const name = `${m[0].toUpperCase()}${m.substring(1, 3)}`;
      setMonthProventos({ name });
      setYearProventos(y);
    }
  };

  const serializeDataExcel = (data: any) => {
    return data
      .filter(
        (el: any) => yearProventos === Number(moment(el?.date).format("Y"))
      )
      .map((el: any) => {
        return {
          Produto: el?.code,
          Pagamento: moment(el?.date).format("DD/MM/YYYY"),
          Tipo: _.capitalize(el?.corporateActionTypeDescription),
          Instituição: el?.corretora,
          "Qtde.": el?.quantity || "-",
          "Preço unit.":
            el?.unitPrice === null || el?.unitPrice === undefined
              ? "-"
              : "R$ " +
                el?.unitPrice?.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }),
          "Valor líquido":
            "R$ " +
            el?.grossAmount?.toLocaleString("pt-br", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
        };
      });
  };

  const downloadExcel = (data: any) => {
    const serializedData = serializeDataExcel(data);
    setLoadingReport(true);
    apiBolsa
      .post("/wallet/report-proventos", {
        data: serializedData,
      })
      .then((result) => {
        if (isIosPlatform()) {
          downloadExcelFromApi(result);
          return;
        }

        const worksheet = XLSX.utils.json_to_sheet(serializedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Proventos");
        XLSX.writeFile(workbook, "Proventos.xlsx");
      })
      .finally(() => {
        message.success("O relatório foi enviado para o seu e-mail");
        setLoadingReport(false);
      });
  };

  const downloadRelatorio = async (type: string, item: any) => {
    try {
      setLoadingDownload(true);
      if (type === "pdf") {
        let fileUrl = "";
        let file = "";
        const newProventos = proventos.map((el: any) => {
          return {
            code: el.code || "",
            date: moment(el?.date).format("DD/MM/YYYY"),
            corporateActionTypeDescription: _.capitalize(
              el?.corporateActionTypeDescription
            ),
            corretora: el?.corretora,
            quantity: el?.quantity || "-",
            unitPrice: el?.unitPrice || "-",
            grossAmount: el?.grossAmount,
          };
        });
        const ret = await apiBolsa.post("/wallet/report-proventos-pdf", {
          proventos: newProventos,
        });
        if (ret) {
          fileUrl = ret?.data?.fileUrl;
          file = ret?.data?.file;
        }
        if (file) {
          downloadPDF(file, "Proventos");
        } else if (fileUrl) {
          download(fileUrl);
        }
      } else if (type === "xlsx") {
        downloadExcel(proventos);
      }
      setLoadingDownload(false);
    } catch (err: any) {
      setLoadingDownload(false);
      message.error(
        "Não foi possível baixar o relatório de Proventos. Tente novamente mais tarde"
      );
    }
  };

  return (
    <Content className="velotax-container" style={{ padding: "0px" }}>
      <Content className="velotax-content">
        <ContainerProventos>
          {!hasPlan || !hasPlan.premium || isBasic ? (
            proventosLoading ? (
              <Loading />
            ) : (
              <BlurContent hasPlan={hasPlan && hasPlan.premium && !isBasic}>
                <div className="blur-content-title">
                  Contrate o plano premium para ver a lista completa de
                  proventos
                  <AntButton
                    type="primary"
                    icon={<AiOutlineLock size={18} />}
                    onClick={() => {
                      showUserPlanModal(true);
                      HandleTag("54");
                    }}
                  >
                    PREMIUM
                  </AntButton>
                </div>
                <ChartBar
                  monthReport={monthReport}
                  proventos={proventos}
                  yearProventos={yearProventos}
                  typeProventos={typeProventos}
                  hideValues={hideValues}
                />
              </BlurContent>
            )
          ) : (
            <>
              <div className="filter-proventos">
                <Select
                  value={yearProventos}
                  disabled={proventosLoading}
                  defaultValue={yearProventos}
                  onChange={(e) => setYearProventos(e)}
                  options={[
                    {
                      value: new Date().getFullYear() + 1,
                      label: new Date().getFullYear() + 1,
                    },
                    {
                      value: new Date().getFullYear(),
                      label: new Date().getFullYear(),
                    },
                    {
                      value: new Date().getFullYear() - 1,
                      label: new Date().getFullYear() - 1,
                    },
                    {
                      value: new Date().getFullYear() - 2,
                      label: new Date().getFullYear() - 2,
                    },
                    {
                      value: new Date().getFullYear() - 3,
                      label: new Date().getFullYear() - 3,
                    },
                  ]}
                />
                <Select
                  value={typeProventos}
                  defaultValue="provento"
                  disabled={proventosLoading}
                  onChange={(e) => {
                    setTypeProventos(e);
                    (document.activeElement as HTMLElement)?.blur();
                  }}
                  options={[
                    {
                      label: "Por tipo de provento",
                      value: "provento",
                    },
                    {
                      label: "Por corretora",
                      value: "corretora",
                    },
                    {
                      label: "Por data de recebimento",
                      value: "data",
                    },
                  ]}
                />
                <div className="proventos-btns">
                  <AntButton
                    type="text"
                    size="large"
                    onClick={() => monthReport()}
                    icon={<AiOutlineSearch className="fill" />}
                    disabled={proventosLoading || loadingReport}
                  >
                    Ver detalhes
                  </AntButton>
                  <Tooltip
                    placement="topRight"
                    autoAdjustOverflow={true}
                    title="Baixar Relatório de proventos"
                    getPopupContainer={(target) => target.parentElement!}
                  >
                    <AntButton
                      type="text"
                      size="large"
                      onClick={() => {
                        setReportDrawerProventos(true);
                      }}
                      disabled={proventosLoading || loadingReport}
                      icon={
                        loadingReport ? <LoadingOutlined /> : <BsDownload />
                      }
                    >
                      Baixar
                    </AntButton>
                  </Tooltip>
                </div>
              </div>
              {proventosLoading ? (
                <Loading />
              ) : (
                <ChartBar
                  monthReport={monthReport}
                  proventos={proventos}
                  yearProventos={yearProventos}
                  typeProventos={typeProventos}
                  hideValues={hideValues}
                />
              )}
              <ProventosModal
                open={openModal}
                setOpenModal={setOpenModal}
                month={monthProventos.name}
                monthProventos={monthProventos}
                proventos={proventos}
                yearProventos={yearProventos}
                downloadExcel={downloadExcel}
                setYearProventos={setYearProventos}
                proventosLoading={proventosLoading}
                setMonthProventos={setMonthProventos}
              />
            </>
          )}
        </ContainerProventos>
      </Content>
      <ReportTypeDrawer
        loading={loadingDownload}
        open={reportDrawerProventos}
        onClose={() => setReportDrawerProventos(false)}
        setType={(type: string) => downloadRelatorio(type, proventos)}
      />
    </Content>
  );
};
