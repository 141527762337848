import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "antd/dist/antd.variable.min.css";
import "react-credit-cards/es/styles-compiled.css";
import GoogleTagManagerHead from "./components/GoogleTagManagerHead";
import GoogleTagManagerBody from "./components/GoogleTagManagerBody";

ReactDOM.render(
  <React.StrictMode>
    <App />
    <GoogleTagManagerHead />
    <GoogleTagManagerBody />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
