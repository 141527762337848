import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { HiOutlineChevronRight } from "react-icons/hi";
import { isMobile } from "../../utils";
import { CardContainer } from "./style";

interface CardHorizontalProps {
  id: string;
  icon: ReactNode;
  children: ReactNode;
  link?: string;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
}

const Container: React.FC<Pick<CardHorizontalProps, "link">> = ({
  children,
  link,
}) => {
  return link && link.includes("http") ? (
    <a
      href={link}
      {...(!isMobile()
        ? {
            target: "_blank",
            rel: "noreferrer",
          }
        : {})}
    >
      {children}
    </a>
  ) : (
    <>{children}</>
  );
};

export const CardHorizontal: React.FC<CardHorizontalProps> = ({
  id,
  icon,
  link,
  onClick,
  children,
  disabled,
  className,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (disabled) return;
    if (onClick) {
      onClick();
    } else if (link && !link.includes("http")) {
      navigate(link);
    }
  };

  return (
    <CardContainer id={id} className={className} onClick={handleClick}>
      <Container link={link}>
        <div className="content">
          <figure>{icon}</figure>
          <div>{children}</div>
        </div>
        <HiOutlineChevronRight />
      </Container>
    </CardContainer>
  );
};
