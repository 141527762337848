import { Form, message, Spin } from "antd";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  useMemo,
} from "react";
import { Container } from "./styles";
import Footer from "./components/Footer";
import { CardList } from "../../CardList";
import { CardForm } from "./components/CardForm";
import AddressForm from "./components/AddressForm";
import HandleTag from "../../../services/handleTag";
import { useAuth } from "../../../contexts/AuthContext";
import { ResultScreen } from "./components/ResultScreen";
import { apiPayment } from "../../../services/apiPayment";
import { useBroker } from "../../../contexts/BrokerContext";
import { errorMessage, removeSpecialCharacters } from "../../../utils";
import { PendingScreen } from "./components/ResultScreen/components/PendingScreen";
import {
  ETypePurchase,
  Plan as IPlan,
  UserPlan,
  UserPlansTypes,
} from "../../../constants/plans";

interface ICreditCardStepProps {
  status: string;
  initialized: boolean;
  handleBack?: () => void;
  typePurchase: ETypePurchase;
  handleSuccess?: (res: any) => void;
  setInitialized: Dispatch<SetStateAction<boolean>>;
  handleClosableModal: Dispatch<SetStateAction<boolean>>;
  plan?: IPlan | UserPlan;
  darfData?: any;
  getCardCurrentStep?: (value: number) => void;
  handleIdBack?: (event: any) => void;
  customInstallments?: number;
  backToRoot?: () => void;
  cupom?: any;
  closeMainModal?: () => void;
  changeCardAction?: boolean;
  addInfo?: Record<string, any>;
  reCaptchaToken?: string;
  setReCaptchaToken: Dispatch<SetStateAction<string | undefined>>;
}

export const CreditCardStep: React.FC<ICreditCardStepProps> = ({
  plan,
  handleBack,
  handleClosableModal,
  typePurchase,
  darfData,
  handleSuccess,
  handleIdBack,
  customInstallments,
  backToRoot,
  cupom,
  closeMainModal,
  changeCardAction,
  addInfo,
  reCaptchaToken,
  setReCaptchaToken,
}) => {
  const [cardForm] = Form.useForm();
  const [addressForm] = Form.useForm();

  const { user } = useAuth();
  const [cvc, setCvc] = useState("");
  const [name, setName] = useState("");
  const [status] = useState("");
  const [number, setNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [focused, setFocused] = useState<any>();
  const [holderDocument, setHolderDocument] = useState("");
  const [installments, setInstallments] = useState(1);
  const [selectedCard, setSelectedCard] = useState<any>();

  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  // const [isValidCard, setIsValidCard] = useState(false);
  const [isValidCardForm, setIsValidCardForm] = useState(false);
  const [isValidAddressForm, setIsValidAddressForm] = useState(false);

  const isPlan = useMemo(() => {
    return UserPlansTypes.includes(plan?.type || "");
  }, [plan]);

  const value = useMemo(() => {
    if (isPlan) {
      var isMonth = (plan as any).interval === "Mensal";

      var planPrice =
        (!isMonth
          ? ((addInfo?.hasDiscount ? plan?.price : plan?.originalPrice) || 0) *
            12
          : plan?.price) || 0;

      if (cupom?.type === "absolute") {
        planPrice = planPrice - Number(cupom?.discountValue);
      } else if (cupom?.type === "percent") {
        planPrice = planPrice - planPrice * Number(cupom?.discountValue / 100);
      }

      planPrice = Number(planPrice.toFixed(2));

      return planPrice && isMonth ? planPrice * 12 : planPrice;
    }
    return darfData?.impostoTotalFinal;
  }, [
    darfData?.impostoTotalFinal,
    plan,
    isPlan,
    cupom?.discountValue,
    cupom?.type,
    addInfo?.hasDiscount,
  ]);

  const { currentPage } = useBroker();

  const getCardInfo = () => {
    const addressInfo = addressForm.getFieldsValue();
    const monthExp = expiry?.split("/")?.[0];
    const monthYear = expiry?.split("/")?.[1];

    let cardInfo: any = {};

    const cardId = selectedCard?.id;

    if (cardId) {
      cardInfo.card_id = cardId;
    } else {
      cardInfo = {
        line_1: `${addressInfo?.neighborhood} - ${addressInfo?.street} - ${addressInfo?.number}`,
        zip_code: addressInfo?.cep?.replace("-", ""),
        city: addressInfo?.city,
        state: addressInfo?.state.toUpperCase(),
        holder_name: removeSpecialCharacters(name),
        number,
        exp_month: monthExp,
        exp_year: monthYear,
        holderDocument,
        cvv: cvc,
      };
    }

    return cardInfo;
  };

  const getCustomerInfo = () => {
    let customerInfo;
    if (selectedCard?.customer_id) {
      customerInfo = { customer_id: selectedCard?.customer_id };
    } else {
      customerInfo = {
        name: name ?? user?.user?.name,
        email: user?.user?.email,
        document: user?.user?.cpf,
        phone:
          user?.user?.cellphone?.replace(/[()_-]/g, "").replace(/\s/g, "") ||
          "",
      };
    }

    return customerInfo;
  };

  useEffect(() => {
    if (currentStep < 0) {
      backToRoot && backToRoot();
    }
  }, [currentStep, backToRoot]);

  const createPlanMainVelotax = () => {
    let cardInfo: any = getCardInfo();
    let clientInfo: any = getCustomerInfo();

    const url = "/user-plan/create";

    const isMei = ["MEI_PREMIUM", "MEI_PREMIUM_MONTH"].includes(typePurchase);

    setLoading(true);
    handleClosableModal(false);

    apiPayment
      .post(
        url,
        {
          ...darfData,
          type: plan?.type,
          from: "VELOTAX",
          cupomCode: cupom?.cupomCode.toUpperCase(),
          paymentForm: "credit_card",
          installments: 12,
          cardInfo,
          clientInfo,
        },
        {
          headers: {
            recaptcha: reCaptchaToken || "",
            "x-api-provider": isMei ? "mei" : "crypto-velotax",
          },
        }
      )
      .then((res: any) => {
        if (res.data.paymentId || res.data.paymentRecurringId) {
          handleSuccess && handleSuccess(res.data);
          if (cupom?.cupomCode.toUpperCase() === "VELOTAXER20") {
            HandleTag("171");
          }
        }
        // setCurrentStep(4);
      })
      .catch((err) => {
        message.error(err.response?.data?.message);
      })
      .finally(() => {
        handleClosableModal(true);
      });
  };

  const createUniquePayment = () => {
    let cardInfo: any = getCardInfo();
    let clientInfo: any = getCustomerInfo();

    let url = "/darf";

    setLoading(true);
    handleClosableModal(false);

    (currentPage?.api || apiPayment)
      .post(
        url,
        {
          ...darfData,
          paymentForm: "credit_card",
          paymentInfo: {
            installments: installments,
            cardInfo: cardInfo,
            clientInfo: clientInfo,
          },
        },
        {
          headers: {
            recaptcha: reCaptchaToken || "",
          },
        }
      )
      .then((res: any) => {
        if (handleIdBack) handleIdBack(res?.data);
        setCurrentStep(3);
      })
      .catch((err) => {
        message.error(err.response?.data?.message || errorMessage);
      })
      .finally(() => {
        handleClosableModal(true);
      });
  };

  const changeCardService = async () => {
    let cardInfo: any = getCardInfo();

    setLoading(true);

    apiPayment
      .post(`/user-plan/change_signature_card`, {
        card: cardInfo,
      })
      .then((ret) => {
        message.success("Cartão atualizado com sucesso");
      })
      .catch((err) => {
        message.error("Ocorreu um erro ao alterar cartão");
      })
      .finally(() => {
        setLoading(false);
        closeMainModal && closeMainModal();
      });
  };

  const handleNextStep = () => {
    if (getConditionToDisable()) {
      if (currentStep === 1) cardForm.validateFields();
      if (currentStep === 2) addressForm.validateFields();
      message.warning("Preencha corretamente o formulário");
      return;
    }

    if (currentStep === 1) {
      if (!darfData?.impostoTotalFinal) {
        HandleTag("79");
      }
      setCurrentStep(2);
    } else if (currentStep === 2 || currentStep === 0) {
      if (changeCardAction) {
        changeCardService();
      } else if (typePurchase === "UNIQUE") {
        createUniquePayment();
      } else if (UserPlansTypes.includes(typePurchase)) {
        createPlanMainVelotax();
      }
    }
  };

  const getConditionToDisable = () => {
    if (currentStep === 0 && !selectedCard?.id) return true;
    if (currentStep === 1 && !isValidCardForm) return true;
    if (currentStep === 2 && !isValidAddressForm) return true;
    return false;
  };

  const changeInstallments = (value: any) => {
    setInstallments(value);
  };

  return (
    <Container>
      {currentStep === 0 && (
        <>
          <CardList
            installmentSelected={installments}
            onChangeInstallments={changeInstallments}
            plan={plan}
            value={value}
            selectedCardId={selectedCard?.id}
            onSelectCard={(card: any) => {
              setSelectedCard(card);

              if (!card) setCurrentStep(1);
            }}
          />
        </>
      )}

      <Spin spinning={loading}>
        {currentStep === 1 && (
          <>
            {/* <InfoCard
              cvc={cvc}
              plan={plan}
              value={value}
              name={name}
              expiry={expiry}
              number={number}
              focused={focused}
              // setIsValidCard={setIsValidCard}
              uniqueValue={value}
              changeCardAction={changeCardAction}
            /> */}

            <CardForm
              form={cardForm}
              number={number}
              setCvc={setCvc}
              setName={setName}
              focused={focused}
              setNumber={setNumber}
              setExpiry={setExpiry}
              setFocused={setFocused}
              setHolderDocument={setHolderDocument}
              setIsValidCardForm={setIsValidCardForm}
              uniqueValue={value}
              addInfo={{ isPlan }}
              signature={(plan as UserPlan)?.interval === "Mensal"}
              fixedInstallments={isPlan ? 12 : undefined}
              handleBack={changeInstallments}
              installmentSelected={installments}
              customInstallments={customInstallments}
            />
          </>
        )}

        {currentStep === 2 && (
          <AddressForm
            form={addressForm}
            setLoading={setLoading}
            setIsValidAddressForm={setIsValidAddressForm}
          />
        )}

        {currentStep === 3 && (
          <ResultScreen
            plan={plan as IPlan}
            status={status}
            setCurrentStep={setCurrentStep}
          />
        )}

        {currentStep === 4 && <PendingScreen />}
        <Footer
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          selectedCardId={selectedCard?.id}
          handleProceed={handleNextStep}
          typePurchase={typePurchase}
          backtoRoot={backToRoot}
          reCaptchaToken={reCaptchaToken}
          setReCaptchaToken={setReCaptchaToken}
          changeCardAction={changeCardAction}
        />
      </Spin>
    </Container>
  );
};

export default CreditCardStep;
