import { LoadingOutlined } from "@ant-design/icons";
import { RiDownloadCloudLine } from "react-icons/ri";
import { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Col, List, message, Row, Select, Skeleton, Typography } from "antd";
import { Card } from "./Cards";
import { cards } from "./items";
import apis from "../../services/apis";
import Button from "../../components/Button";
import { Page } from "../../constants/brokers";
import { useAuth } from "../../contexts/AuthContext";
import { useBroker } from "../../contexts/BrokerContext";
import { DrawerModal } from "../../components/DrawerModal";
import { ReportContainer, Container, Content } from "./styles";
import {
  download,
  downloadPDF,
  errorMessage,
  getInitialYear,
  monthsExtended,
  zero,
} from "../../utils";

interface ReportProps {
  item: Page;
}

export const ReportBolsa: React.FC<ReportProps> = ({ item }) => {
  const { showUserPlanModal, userPlanModal, hasPlan } = useAuth();
  const { state } = useLocation();
  const navigate = useNavigate();

  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();
  const stateYear = (state as any)?.year;
  const initialYear = getInitialYear(currentYear, currentMonth);
  window.history.replaceState({}, document.title);

  const { currentPage } = useBroker();

  const [action, setAction] = useState<string>();

  const [data, setData] = useState<any[]>();
  const [infoModal, toggleModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingLinks] = useState(false);
  const [year, setYear] = useState(stateYear ?? initialYear);

  const historicFeature = item.features[0];
  const downloadFeature = item.features[1];
  // const sendEmailFeature = item.features[2];

  const yearOptions = Array.from(Array(initialYear - 2020 + 1))
    .map((y, i) => ({
      value: 2020 + i,
      label: `${2020 + i}`,
    }))
    .reverse();

  const onChangeYear = (value: string) => {
    setYear(parseInt(value));
  };

  const getHistoric = useCallback(() => {
    if (!action) return;
    if (action === "anual-report")
      return setData(
        Array.from(Array(new Date().getFullYear() - 2022))
          .map((y, i) => ({
            year: 2022 + i,
            label: `${2022 + i}`,
          }))
          .reverse()
      );

    setLoading(true);
    (currentPage?.api || apis)
      .get(historicFeature.apiUrl, { params: { year } })
      .then((response) => setData((response.data ?? []).reverse()))
      .catch(() => message.error(errorMessage))
      .finally(() => setLoading(false));
  }, [historicFeature, year, currentPage, action]);

  // const getDowloadLinks = useCallback(
  //   (data: any[], action: string) => {
  //     setLoadingLinks(true);
  //     const dataLinks = data.map((item) =>
  //       !!item._id
  //         ? (currentPage?.api || apis).post(
  //             `${downloadFeature.apiUrl}/${action}`,
  //             {
  //               id: item._id,
  //               year: item.year,
  //               month: item.month,
  //             }
  //           )
  //         : Promise.resolve({ data: item })
  //     );
  //     Promise.allSettled(dataLinks).then((response) => {
  //       setData(
  //         response.map((item, index) =>
  //           item.status === "fulfilled" && item.value.data?.file
  //             ? {
  //                 ...data?.[index],
  //                 url: `data:application/pdf;base64,${item.value.data.file}`,
  //               }
  //             : data?.[index]
  //         )
  //       );
  //       setLoadingLinks(false);
  //     });
  //   },
  //   [currentPage, downloadFeature]
  // );

  // const sendDarfToEmail = (reportId: string) => {
  //   (currentPage?.api || apis)
  //     .get(`${sendEmailFeature.apiUrl}/${reportId}`)
  //     .then(() => {
  //       message.success("O DARF foi enviado para o seu e-mail");
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const downloadAction = (record: any) => {
    setLoading(true);
    (currentPage?.api || apis)
      .post(`${downloadFeature.apiUrl}/${action}`, {
        id: record._id,
        month: record.month,
        year: record.year,
      })
      .then((res) => {
        message.success("Relatório enviado para o seu e-mail");
        if (res.data.file) {
          downloadPDF(res.data.file);
        } else if (res.data.fileUrl) {
          download(res.data.fileUrl);
        }
      })
      .catch(({ response }) =>
        message.error(response?.data?.message ?? errorMessage)
      )
      .finally(() => setLoading(false));
  };

  const checkProcessorAction = (record: any) => {
    setLoading(true);
    let showToast = false;
    toggleModal(record);
    const itvl = setInterval(() => {
      (currentPage?.api || apis)
        .get(`${downloadFeature.apiUrl}/${action}?year=${record.year}`)
        .then((res) => {
          const { data } = res;
          const { year, status } = data;
          const isProcessing = record.year === year && status === "processing";
          if (isProcessing && showToast) return;
          if (isProcessing && !showToast) {
            showToast = true;
            return message.success(
              `Estamos processando os dados para seu relatório anual de ${year}`
            );
          }
          setLoading(false);
          clearInterval(itvl);
        })
        .catch(({ response }) => {
          const { data, status } = response;
          if (status === 401)
            setTimeout(() => navigate("/velotax/bolsa-integration"), 2000);
          message.error(data?.message ?? errorMessage);
          setLoading(false);
          clearInterval(itvl);
        });
    }, 5000);
  };

  const handlePlanModal = () => {
    showUserPlanModal(!userPlanModal);
  };

  const handleActionButton = (id: string) => {
    if (!hasPlan) {
      handlePlanModal();
    } else {
      setAction(id);
    }
  };

  useEffect(() => {
    getHistoric();
  }, [getHistoric, year]);

  return (
    <ReportContainer>
      <div className="velotax-container">
        <div className="velotax-content">
          <Typography.Title level={1} className="page-title">
            Relatórios auxiliares
          </Typography.Title>
          <Typography.Paragraph>
            Confira os relatórios{" "}
            {action !== "anual-report" ? "auxiliares" : "anuais"} para a
            declaração do seu IR na bolsa de valores.
          </Typography.Paragraph>

          {!action && (
            <div className="cards">
              {cards.map((item) => (
                <Card
                  key={item.id}
                  icon={
                    loading && item.id === "anual-report" ? (
                      <LoadingOutlined />
                    ) : (
                      item.icon
                    )
                  }
                  handleClick={() => !loading && handleActionButton(item.id)}
                >
                  {item.content}
                </Card>
              ))}
            </div>
          )}
          {action && (
            <Container>
              <Content>
                <Typography.Paragraph>Selecione o ano:</Typography.Paragraph>
                {action !== "anual-report" && (
                  <Select
                    size="large"
                    options={yearOptions}
                    value={year.toString()}
                    onChange={onChangeYear}
                    placeholder="Selecione o ano"
                    style={{ minWidth: "150px" }}
                  />
                )}
                <List
                  loading={loading}
                  dataSource={
                    data?.filter(
                      (item) => {
                        return (
                          action === "anual-report" ||
                          item.year === year ||
                          item.year === currentYear)
                      }
                    ) ?? []
                  }
                  itemLayout="horizontal"
                  renderItem={(item, index) => (
                    <List.Item
                      actions={[
                        <Button
                          type="text"
                          size="large"
                          href={item.url}
                          disabled={loadingLinks}
                          onClick={
                            () =>
                              action === "anual-report"
                                ? checkProcessorAction(item)
                                : downloadAction(
                                  item
                                ) /*sendDarfToEmail(item._id)*/
                          }
                          download={`Relatório-${item.year}-${zero(
                            item.month
                          )}.pdf`}
                        >
                          Baixar
                          {loadingLinks ? (
                            <LoadingOutlined size={16} />
                          ) : (
                            <RiDownloadCloudLine size={16} />
                          )}
                        </Button>,
                      ]}
                    >
                      <Skeleton title={true} loading={item.loading} active>
                        <List.Item.Meta
                          title={
                            action !== "anual-report"
                              ? `${monthsExtended[item.month - 1]}`
                              : `${item.year}`
                          }
                        />
                      </Skeleton>
                    </List.Item>
                  )}
                />
                <Button
                  type="default"
                  size="large"
                  onClick={() => handleActionButton("")}
                  style={{ marginTop: "1rem" }}
                >
                  Voltar
                </Button>
              </Content>
              <DrawerModal
                title="Atenção"
                visible={!!infoModal}
                onCancel={() => toggleModal(null)}
                footer={
                  <Row
                    justify="end"
                    gutter={[16, 16]}
                    className="column-reverse"
                  >
                    <Col xs={24} sm={12}>
                      <Button
                        block
                        size="large"
                        loading={loading}
                        onClick={() => toggleModal(null)}
                      >
                        Cancelar
                      </Button>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Button
                        block
                        size="large"
                        type="primary"
                        loading={loading}
                        onClick={() => {
                          downloadAction(infoModal);
                          toggleModal(null);
                        }}
                      >
                        Continuar
                      </Button>
                    </Col>
                  </Row>
                }
              >
                <Typography.Paragraph>
                  Você deve estar com as suas operações na bolsa salvas na aba{" "}
                  <Link to="/velotax/bolsa-historic">
                    <b>Calculadora</b>
                  </Link>{" "}
                  para todos os meses do ano selecionado.
                </Typography.Paragraph>
              </DrawerModal>
            </Container>
          )}
        </div>
      </div>
    </ReportContainer>
  );
};
