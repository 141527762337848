import clsx from "clsx";
import { Typography } from "antd";
import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useB3Integration } from "../../contexts/B3IntegrationContext";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  row-gap: 16px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #fffd;
  backdrop-filter: blur(1px);
  z-index: 999;
  h3 {
    width: 100%;
    font-size: 2rem;
    text-align: center;
    position: relative;
    margin: 0 !important;
    span.timer-text {
      left: 0;
      opacity: 0;
      width: 100%;
      position: absolute;
      transition-delay: 400ms;
      transition-duration: 800ms;
      transition-property: opacity;
      transition-timing-function: ease-in-out;
      &.active {
        opacity: 1;
      }
    }
  }
  .loader {
    display: flex;
    align-items: center;
    .timer {
      font-weight: 500;
      font-size: 1.125rem;
      margin-left: 16px;
    }
    .anticon.anticon-loading.anticon-spin {
      width: 40px;
      height: 40px;
      svg {
        width: 40px;
        height: 40px;
        fill: var(--ant-primary-color);
      }
    }
  }
  @media only screen and (max-device-width: 812px) {
    h3 {
      font-size: 1.5rem;
    }
  }
`;

interface LoadingIntegrationProps {
  show: boolean;
  hasError?: boolean;
}

const integrationMessages = [
  "Iniciando integração com a B3",
  "Organizando suas operações",
  "Calculando custo médio das operações",
  "Calculando suas DARFs",
  "Integração concluída",
];

export const LoadingIntegration: React.FC<LoadingIntegrationProps> = ({
  show,
  hasError,
}) => {
  const { integrationMessage } = useB3Integration();
  // const [timer, setTimer] = useState(0);
  // const [close, setClose] = useState<boolean>();
  // const finishTimer = useRef<ReturnType<typeof setInterval> | null>(null);
  // const initialTimer = useRef<ReturnType<typeof setInterval> | null>(null);
  
  const intervalTimer = useRef<ReturnType<typeof setInterval> | null>(null);
  const [percent, setPercent] = useState(0);
  const percentRef = useRef(0);
  const navigate = useNavigate();

  useEffect(() => {
    const message = integrationMessage ?? "";
    const index = integrationMessages.findIndex((m) => message === m);
    if (index === -1) {
      percentRef.current = -1;
      return;
    }
    const newPercent = ((index + 1) * 100) / integrationMessages.length;
    percentRef.current = newPercent;
  }, [integrationMessage]);

  useEffect(() => {
    intervalTimer.current = setInterval(() => {
      setPercent((prev) => {
        if (percentRef.current === 0) return 0;
        if (percentRef.current === 100) return 100;
        return prev < percentRef.current ? prev + 1 : prev;
      });
    }, 1000);

    function clearPercentInterval() {
      if (intervalTimer.current) clearInterval(intervalTimer.current);
      intervalTimer.current = null;
    }

    if (percent === 100) {
      clearPercentInterval();
      setTimeout(() => {
        navigate("/velotax/bolsa-historic");
      }, 1000);
    }

    return () => {
      clearPercentInterval();
    };
  }, [percent]);

  // useEffect(() => {
  //   if (show && close === undefined) {
  //     setClose(false);
  //     initialTimer.current = setInterval(() => {
  //       setTimer((timer) => (timer + 1 >= 100 ? 100 : timer + 1));
  //     }, 1500);
  //   }
  //   if (!show && close === false) {
  //     if (initialTimer.current) {
  //       clearInterval(initialTimer.current);
  //     }
  //     finishTimer.current = setInterval(() => {
  //       setTimer((timer) => {
  //         if (finishTimer.current && timer >= 100) {
  //           setClose(undefined);
  //           clearInterval(finishTimer.current);
  //           return 100;
  //         }
  //         return timer + 1;
  //       });
  //     }, 25);
  //   }
  //   if (!show && close === undefined) {
  //     setTimer(0);
  //     finishTimer.current = null;
  //     initialTimer.current = null;
  //   }
  // }, [show, close]);

  // useEffect(() => {
  //   if (timer === 100) {
  //     setClose(true);
  //     message.info({
  //       content:
  //         "Você possui um número alto de operações realizadas na bolsa de valores. Por isso, precisaremos de um pouco mais de tempo para finalizar os seus cálculos de imposto de renda. Volte novamente em 30 minutos.",
  //       duration: 40,
  //     });
  //   }
  // }, [timer]);

  return (
    <Container>
      <div className="loader">
        <LoadingOutlined />
        {/* <span className="timer">{timer} %</span> */}
        {percentRef.current !== -1 && (
          <span className="timer">{percent} %</span>
        )}
      </div>
      <Typography.Title level={3}>
        {integrationMessages.map((message) => {
          return (
            <span
              className={clsx("timer-text", {
                active: integrationMessage === message,
              })}>
              {message}
            </span>
          );
        })}
        <span
          className={clsx("timer-text", {
            active: !integrationMessages.includes(integrationMessage ?? "-"),
          })}>
          Analisando dados da integração...
        </span>
      </Typography.Title>

      {/* <Typography.Title level={3}>
    <span className={clsx("timer-text", { active: timer < 2 })}>
      Lendo suas transações...
    </span>
    <span
      className={clsx("timer-text", { active: timer >= 2 && timer < 4 })}
    >
      Conferindo seus impostos...
    </span>
    <span className={clsx("timer-text", { active: timer >= 6 })}>
      Preparando seu painel de controle...
    </span>
  </Typography.Title> */}
    </Container>
  );
};
