import moment from "moment";
import { message } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAuth } from "./AuthContext";
import { errorMessage } from "../utils";
import apiExterior from "../services/apiExterior";
import { useBroker } from "./BrokerContext";

type LoadingOperation = "get" | "put" | "delete";

type Loading = {
  get: boolean;
  put: boolean;
  delete: boolean;
};

interface Ativo {
  id: string;
  codigo: string;
  quantidade: number;
  dataCompra?: string;
  valorCompraUSDOrigemBRA?: number;
  valorCompraUSDOrigemEUA?: number;
  orderId: string;
  type: string;
}

interface ITransferenciaCustodiaContext {
  ativo?: Ativo;
  ativos: Ativo[];
  loading: Loading;
  getAtivos: any;
  showFormModal: boolean;
  showDeleteModal: boolean;
  closeFormModal: () => void;
  closeDeleteModal: () => void;
  handleAdd: () => void;
  handleEdit: (item: Ativo, index: number) => void;
  handleRemove: (item: Ativo, index: number) => void;
  add: (item: Ativo) => void;
  edit: (item: Ativo) => void;
  remove: (item: Ativo) => void;
  saveAtivos: () => void;
  isInvalidItem: (item: Ativo) => boolean;
}

export const TransferenciaCustodiaContext =
  createContext<ITransferenciaCustodiaContext>(
    {} as ITransferenciaCustodiaContext
  );

export const TransferenciaCustodiaProvider: React.FC = ({ children }) => {
  const { user } = useAuth();
  const { currentBroker } = useBroker();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [ativo, setAtivo] = useState<Ativo>();
  const [ativos, setAtivos] = useState<Ativo[]>([]);
  const [indexAtivo, setIndexAtivo] = useState(-1);
  const [redirected, setRedirected] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading, setLoading] = useState<Loading>({
    get: false,
    put: false,
    delete: false,
  });

  const isInvalidItem = (item: Ativo) =>
    !item.dataCompra ||
    (!item.valorCompraUSDOrigemBRA && !item.valorCompraUSDOrigemEUA);

  const handleLoading = (operation: LoadingOperation, value: boolean) => {
    setLoading((loading) => ({ ...loading, [operation]: value }));
  };

  const closeFormModal = () => {
    setShowFormModal(false);
    setAtivo(undefined);
    setIndexAtivo(-1);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setAtivo(undefined);
    setIndexAtivo(-1);
  };

  const handleAdd = () => {
    setShowFormModal(true);
  };

  const handleEdit = (item: Ativo, index: number) => {
    setShowFormModal(true);
    setIndexAtivo(index);
    const dataCompra = !item.dataCompra
      ? ""
      : item.dataCompra.includes("/")
      ? item.dataCompra
      : moment.utc(item.dataCompra)?.format("DD/MM/YYYY");
    setAtivo({
      ...item,
      dataCompra,
    });
  };

  const handleRemove = (item: Ativo, index: number) => {
    setShowDeleteModal(true);
    setIndexAtivo(index);
    setAtivo(item);
  };

  const getAtivos = useCallback(() => {
    handleLoading("get", true);
    apiExterior
      .get("/custodia")
      .then(({ data }) => {
        setAtivos(data || []);
        closeFormModal();
      })
      .catch(() => {
        message.error(errorMessage);
      })
      .finally(() => {
        handleLoading("get", false);
      });
  }, []);

  const saveAtivos = () => {
    handleLoading("put", true);
    apiExterior
      .post("/custodia", ativos)
      .then(({ data }) => {
        setAtivos(data ?? []);
        navigate("/exterior-historic");
      })
      .catch(() => {
        message.error(errorMessage);
      })
      .finally(() => {
        handleLoading("put", false);
      });
  };

  const add = (item: Ativo) => {
    setAtivos((ativos) => [...ativos, item]);
    closeFormModal();
  };

  const edit = (item: Ativo) => {
    setAtivos((ativos) =>
      ativos.map((ativo, index) =>
        index === indexAtivo ? { ...item, type: ativo.type } : ativo
      )
    );
    closeFormModal();
  };

  const remove = () => {
    setAtivos((ativos) =>
      ativos.filter((ativo, index) => index !== indexAtivo)
    );
    closeDeleteModal();
  };

  useEffect(() => {
    if (
      !redirected &&
      pathname.includes("exterior") &&
      ativos.filter((item: Ativo) => isInvalidItem(item)).length > 0
    ) {
      navigate("/transferencia-custodia");
      setRedirected(true);
    }
  }, [pathname, navigate, ativos, redirected]);

  useEffect(() => {
    if (user.user && currentBroker.useTransferenciaCustodia) {
      getAtivos();
    }
  }, [user.user, currentBroker.useTransferenciaCustodia, getAtivos]);

  return (
    <TransferenciaCustodiaContext.Provider
      value={{
        ativo,
        ativos,
        add,
        getAtivos,
        edit,
        remove,
        loading,
        handleAdd,
        handleEdit,
        handleRemove,
        showFormModal,
        showDeleteModal,
        closeFormModal,
        closeDeleteModal,
        saveAtivos,
        isInvalidItem,
      }}
    >
      {children}
    </TransferenciaCustodiaContext.Provider>
  );
};

export const useTransferenciaCustodia = () =>
  useContext(TransferenciaCustodiaContext);
