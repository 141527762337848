import styled from "styled-components";

export const Container = styled.div`
  min-height: 100%;
  padding: 64px 32px 128px;
  @media only screen and (max-device-width: 812px) {
    padding: 24px 0 0;
    background-color: var(--velotax-background-color);
  }
`;

export const Content = styled.div`
  padding: 32px;
  margin: 0 auto;
  max-width: 920px;
  border-radius: 16px;
  background-color: var(--velotax-background-color);
  border: 1px solid var(--velotax-background-color-ghost);

  p {
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--velotax-font-color);
  }

  h3 {
    color: var(--velotax-font-color);
  }

  span.link {
    cursor: pointer;
    color: var(--ant-primary-color);
  }

  .ant-typography:not(h3) {
    text-align: justify;
  }

  a.ant-typography,
  .ant-typography a {
    color: var(--ant-primary-color);
  }

  .ant-typography i strong {
    font-size: 13px;
    letter-spacing: 0.625px;
    word-spacing: 0.625px;
  }

  iframe {
    max-width: 100%;
  }

  .ant-list {
    margin-bottom: 1em;
  }

  .ant-collapse,
  .ant-collapse.ant-collapse-borderless {
    border-radius: 8px;
    background-color: transparent;
    border: 1px solid var(--velotax-background-color-ghost);
    .ant-collapse-item {
      .ant-collapse-header {
        padding: 16px;
        font-size: 1.125rem;
        color: var(--velotax-font-color);
        .ant-collapse-arrow {
          font-size: 0.875rem;
        }
      }
      .ant-collapse-content {
        border-bottom: 1px solid;
        border-color: var(--velotax-background-color-ghost);
      }
    }

    .ant-collapse-item:last-child {
      .ant-collapse-content {
        border-bottom: none;
        border-radius: 0 0 8px 8px;
      }
    }
  }

  @media only screen and (max-device-width: 812px) {
    padding: 0 24px 32px;
    border-radius: 0;
    border: none;
  }
`;
