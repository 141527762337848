import React, { useState } from "react";
import ReactInputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowDown } from "react-icons/ai";
import { Checkbox, Form, Input, Typography } from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import Button from "../../components/Button";
import { useAuth } from "../../contexts/AuthContext";
import { AuthContainer } from "../../styles/LoginSignUp/authContainer";
import {
  validationCpf,
  validationEmail,
  validationFullName,
  validationPassword,
} from "../../utils/formValidations";

interface IData {
  cpf: string;
  email: string;
  phone: string;
  isAd: boolean;
}

interface IProps {
  className?: string;
}

export const Cadastro: React.FC<IProps> = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm<IData>();
  const { handleSignUp, loading } = useAuth();
  const [visiblePass, setVisiblePass] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const isAd = Boolean(queryParams.get("ad"));

  const onFinish = (data: IData) => {
    handleSignUp({ ...data, isAd }, {});
  };

  const onValuesChange = (changed: any, values: any) => {
    const cpf = changed.cpf ? changed.cpf.replace(/[_.-/-]/g, "") : values.cpf;
    form.setFieldsValue({
      ...values,
      cpf,
    });
  };

  return (
    <AuthContainer className="beauty-scrollbar">
      <div className="auth-content">
        <div className="form-container">
          <Form
            form={form}
            size="large"
            onFinish={onFinish}
            onValuesChange={onValuesChange}
          >
            <Typography.Title level={1}>Faça seu cadastro</Typography.Title>

            <Form.Item name="name" rules={validationFullName}>
              <Input placeholder="Nome completo" />
            </Form.Item>

            <Form.Item name="cpf" rules={validationCpf}>
              <ReactInputMask
                placeholder="CPF"
                mask="999.999.999-99"
                className="ant-input ant-input-lg"
              />
            </Form.Item>

            <Form.Item name="email" rules={validationEmail}>
              <Input placeholder="Email" />
            </Form.Item>

            <div className="password-with-eye">
              <Form.Item name="pass" rules={validationPassword}>
                {visiblePass ? (
                  <Input placeholder="Senha" />
                ) : (
                  <Input.Password
                    placeholder="Senha"
                    visibilityToggle={false}
                  />
                )}
              </Form.Item>
              <Button
                size="large"
                type="text"
                onClick={() => setVisiblePass((visible) => !visible)}
                icon={visiblePass ? <EyeInvisibleOutlined /> : <EyeOutlined />}
              />
            </div>

            <Button
              block
              size="large"
              id="next-btn"
              type="primary"
              loading={loading}
              htmlType="submit"
              icon={<AiOutlineArrowDown size={18} />}
            >
              Começar
            </Button>

            <div className="footer">
              <Form.Item
                name="policiesAccepted"
                className="full-width max-width-100"
                getValueFromEvent={(e) => e.target.checked}
              >
                <Checkbox>
                  Li, entendi e estou de acordo com os{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://velotax.com.br/termos"
                  >
                    Termos de Uso
                  </a>
                  ,{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://velotax.com.br/termos"
                  >
                    Política de Privacidade
                  </a>{" "}
                  e{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://velotax.com.br/termos"
                  >
                    Política de Cookies
                  </a>{" "}
                  do Velotax
                </Checkbox>
              </Form.Item>
            </div>
          </Form>

          <div className="footer">
            Já possui uma conta?
            <Button
              type="link"
              disabled={loading}
              className="btn-underlined"
              onClick={() => navigate("/p/login")}
            >
              Entrar
            </Button>
          </div>
        </div>
      </div>
    </AuthContainer>
  );
};
