import { InfoCircleOutlined } from "@ant-design/icons";
import clsx from "clsx";
import Button from "../../components/Button";
import { formatCurrency, currencyToNumber, isMobile } from "../../utils";
import NumberFormat from "react-number-format";
import { useState } from "react";
import { Form, message } from "antd";
import { FaRegEdit } from "react-icons/fa";
import { VscClose } from "react-icons/vsc";
import imoveisBackend from "../../services/imoveis";
import {
  JurosModalProps,
  MinPriceModalProps,
  MultaModalProps,
  minDarfPrice,
} from "../darf";

export const DarfImoveisTabs = (
  data: any,
  valorVenda: number,
  valorAquisicao: number,
  custoCorretagem: number
) => [
  {
    class: "sub-header",
    tabs: [
      {
        content: "Valor da Venda",
      },
      {
        content: formatCurrency(valorVenda ?? 0),
      },
    ],
  },
  {
    class: "sub-header",
    tabs: [
      {
        content: "(-) Valor da aquisição",
      },
      {
        content: formatCurrency(valorAquisicao ?? 0),
      },
    ],
  },
  {
    class: "sub-header",
    tabs: [
      {
        content: "(-) Custos de Corretagem",
      },
      {
        content: formatCurrency(custoCorretagem ?? 0),
      },
    ],
  },
  {
    class: "sub-header",
    tabs: [
      {
        content: "(-) Deduções Legais",
      },
      {
        content: formatCurrency(data.deducoesLegais ?? 0),
      },
    ],
  },
  {
    class: "sub-header",
    tabs: [
      {
        content: "(x) Aliquota Devida",
      },
      {
        content: `${data?.aliquota?.toFixed(2) * 100}%`,
      },
    ],
  },
  {
    class: "sub-header strong",
    tabs: [
      {
        content: "Imposto Devido",
      },
      {
        content: formatCurrency(data.impostoDevido ?? 0),
      },
    ],
  },
];

export const ImpostoTotalImoveis = [
  {
    id: "impostoDevido",
    label: () => "(+) Valor do principal",
    Component: ({ data }: any) => {
      const impostoPrincipal =
        (data?.impostoDevido ?? 0) + (data?.impostoAcumulado ?? 0);

      return formatCurrency(impostoPrincipal);
    },
  },
  {
    id: "impostoDevido",
    label: () => (
      <div
        className="desc-label"
        style={{ fontSize: "12px", paddingLeft: "24px" }}
      >
        (+) Imposto devido
      </div>
    ),
    Component: ({ data }: any) =>
      formatCurrency(Number(data?.impostoDevido || 0)),
  },
  {
    id: "impostoAcumulado",
    label: (onClick: any) => (
      <div
        className="desc-label"
        style={{ fontSize: "12px", paddingLeft: "24px" }}
      >
        (+) DARFs passadas abaixo de R$ 10
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          onClick={() => onClick(MinPriceModalProps)}
        />
      </div>
    ),
    Component: ({ data, loading, disabled, docData, setData }: any) => {
      const [edit, setEdit] = useState<any>();
      const [editForm] = Form.useForm();
      const handleEditValue = (id: string) => {
        const value = data.impostoAcumulado;
        setEdit({ id, isEditting: true, value });
        editForm.setFieldsValue({ [id]: value });
      };

      const handleEditValueCancel = () => {
        setEdit(undefined);
        editForm.resetFields();
      };
      const handleEditValueConfirm = () => {
        if (edit && edit.value >= 0 && edit.value < minDarfPrice) {
          setData((currentValue: any) => {
            return {
              ...currentValue,
              impostoAcumulado: edit.value,
            };
          });
          imoveisBackend
            .put("/imoveis/edit", {
              _id: docData._id,
              impostoAcumulado: edit.value,
            })
            .then(() =>
              setData((data: any) => ({
                ...data,
                [edit.id]: edit.value,
              }))
            )
            .catch(() =>
              message.error(
                "Algo inesperado aconteceu! Tente novamente mais tarde."
              )
            )
            .finally(() => handleEditValueCancel());
        } else {
          handleEditValueCancel();
        }
      };
      return edit && edit.isEditting && edit.id === "impostoAcumulado" ? (
        <div className="desc-content is-editting">
          {!isMobile() && (
            <Button icon={<VscClose />} onClick={handleEditValueCancel} />
          )}
          <Form
            form={editForm}
            onValuesChange={(changed) => {
              const value = currencyToNumber(changed.impostoAcumulado);
              editForm.setFieldsValue({ impostoAcumulado: value });
              setEdit((edit: any) => ({ ...edit!, value }));
            }}
          >
            <Form.Item
              name="impostoAcumulado"
              rules={[
                {
                  message: `Deve ser menor que ${minDarfPrice}`,
                  validator: (rule, value) =>
                    typeof value === "number" &&
                    value >= 0 &&
                    value < minDarfPrice
                      ? Promise.resolve()
                      : Promise.reject(),
                },
              ]}
            >
              <NumberFormat
                prefix="R$ "
                decimalScale={2}
                disabled={loading}
                decimalSeparator=","
                thousandSeparator="."
                className="ant-input"
                allowNegative={false}
              />
            </Form.Item>
          </Form>
          <Button
            type="primary"
            onClick={handleEditValueConfirm}
            disabled={edit.value < 0 || edit.value >= minDarfPrice}
          >
            OK
          </Button>
          {isMobile() && (
            <Button icon={<VscClose />} onClick={handleEditValueCancel} />
          )}
        </div>
      ) : (
        <div
          className={clsx("desc-content", {
            "ml-40": disabled,
          })}
        >
          {formatCurrency(Number(data.impostoAcumulado || 0))}
          {disabled && (
            <Button
              type="text"
              icon={<FaRegEdit />}
              onClick={() => handleEditValue("impostoAcumulado")}
            />
          )}
        </div>
      );
    },
  },
  {
    id: "multa",
    label: (onClick: any) => (
      <div className="desc-label">
        (+) Multa de atraso{" "}
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          onClick={() => onClick(MultaModalProps)}
        />
      </div>
    ),
    Component: ({ data }: any) =>
      formatCurrency(
        Number(data?.["impostoDevido"] || 0) +
          Number(data?.["impostoAcumulado"] || 0) <
          minDarfPrice
          ? 0
          : Number(data?.["multa"] || 0)
      ),
  },
  {
    id: "juros",
    label: (onClick: any) => (
      <div className="desc-label">
        (+) Juros e/ou encargos de atraso{" "}
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          onClick={() => onClick(JurosModalProps)}
        />
      </div>
    ),
    Component: ({ data }: any) =>
      formatCurrency(
        Number(data?.["impostoDevido"] || 0) +
          Number(data?.["impostoAcumulado"] || 0) <
          minDarfPrice
          ? 0
          : Number(data?.["juros"] || 0)
      ),
  },
];
