import { Typography } from "antd";
import { Card } from "../Bolsa/Cards";
import { itensCripto } from "./itensCripto";
import { HomeContainer } from "../Home/styles";
import { CardHorizontal } from "../../components/CardHorizontal";
import { useAuth } from "../../contexts/AuthContext";
import { AiOutlineLock } from "react-icons/ai";
import Button from "../../components/Button";
import { isMobile } from "../../utils";
import { itensCriptoSemLogo } from "./itensCriptoSemLogos";

export const Cripto = () => {
  const { hasPlan, showUserPlanModal } = useAuth();

  const handleOpenPlanModal = () => {
    showUserPlanModal(true);
  };

  return (
    <HomeContainer>
      <div className="content module">
        <div>
          <Typography.Title level={1}>Criptoativos</Typography.Title>
          <Typography.Text strong className="destak">
            Selecione a opção que deseja utilizar
          </Typography.Text>
          <div className="cards cards-2">
            {(itensCriptoSemLogo).map((item) => (
              <Card
                id={item.id}
                key={item.id}
                link={item.link}
                icon={item.icon}
                onClick={
                  (true) ?
                    undefined
                    : (item.premium && (!hasPlan || !hasPlan.premium)) ||
                      (item.basic && !hasPlan)
                      ? () => {
                        handleOpenPlanModal();
                      }
                      : undefined
                }
              >
                {item.content}
                {item.description && (
                  <div className="description">{item.description}</div>
                )}
                {(true)
                  ?
                  null
                  : item.premium && (!hasPlan || !hasPlan.premium) && (
                    <Button
                      type="primary"
                      className="btn-premium"
                      icon={<AiOutlineLock size={18} />}
                      onClick={() => {
                        handleOpenPlanModal();
                      }}
                    >
                      PREMIUM
                    </Button>
                  )}
              </Card>
            ))}
          </div>
        </div>
      </div>
    </HomeContainer>
  );
};
