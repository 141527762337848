import { Darf } from "../../pages/Darf";
import { Bolsa } from "../../pages/Bolsa";
import { Cripto } from "../../pages/Cripto";
import { Imoveis } from "../../pages/Imoveis";
import { Exterior } from "../../pages/Exterior";
import { Historic } from "../../pages/Historic";
import { HistoricCrypto } from "../../pages/HistoricCrypto";
import { Autonomos } from "../../pages/Autonomos";
import { DarfBolsa } from "../../pages/DarfBolsa";
import { Regulation } from "../../pages/Regulation";
import { ReportBolsa } from "../../pages/ReportBolsa";
import { BolsaManual } from "../../pages/BolsaManual";
import { Rendimentos } from "../../pages/Rendimentos";
import { DarfExterior } from "../../pages/DarfExterior";
import { NovoCadastro } from "../../pages/NovoCadastro";
import { HistoricBolsa } from "../../pages/HistoricBolsa";
import { HistoricAutonomos } from "../../pages/HistoricAutonomos";
import { ReportExterior } from "../../pages/ReportExterior";
import { IntegrationBolsa } from "../../pages/IntegrationBolsa";
import { IntegrationCrypto } from "../../pages/IntegrationCrypto";
import { ReportCripto } from "../../pages/ReportCripto";
import { ImoveisCalculadora } from "../../pages/ImoveisCalculadora";
import { DividendosBolsa } from "../../pages/DividendosBolsa";
import { DividendosExterior } from "../../pages/DividendosExterior";
import { IntegrationExterior } from "../../pages/IntegrationExterior";
import {UserHasAccount} from "../../pages/IntegrationBolsa/UserHasAccount";
import {UserNoHasAccount} from "../../pages/IntegrationBolsa/UserNoHasAccount";
import {UserForgotPasswordB3} from "../../pages/IntegrationBolsa/UserForgotPasswordB3";
import { TransferenciaCustodia } from "../../pages/TransferenciaCustodia";
import { DoacoesHeranca } from "../../pages/DoacoesHeranca";

export const Pages: { [key: string]: any } = {
  TransferenciaCustodia,
  Bolsa,
  Cripto,
  Imoveis,
  Exterior,
  Autonomos,
  IntegrationBolsa,
  Darf,
  DarfBolsa,
  DarfExterior,
  Historic,
  HistoricCrypto,
  HistoricBolsa,
  HistoricAutonomos,
  ReportBolsa,
  ReportExterior,
  ReportCripto,
  Regulation,
  Rendimentos,
  IntegrationCrypto,
  IntegrationExterior,
  BolsaManual,
  DoacoesHeranca,
  ImoveisCalculadora,
  DividendosBolsa,
  DividendosExterior,
  NovoCadastro,
  UserHasAccount,
  UserNoHasAccount,
  UserForgotPasswordB3
};
