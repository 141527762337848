import { useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";

const GoogleTagManagerHead = () => {
  const { isIOSAPP } = useAuth();
  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV === "prd" && !isIOSAPP) {
      // const scriptElement = document.createElement("script");
      // scriptElement.innerHTML = `
      //   (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      //   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      //   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      //   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      //   })(window,document,'script','dataLayer','GTM-M4QZ393');
      // `;
      // document.head.appendChild(scriptElement);
    }
  }, [isIOSAPP]);

  return null;
};

export default GoogleTagManagerHead;
