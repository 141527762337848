import styled from "styled-components";

export const Container = styled.div`
  &.view {
    padding: 0;
  }
`;

export const ButtonContent = styled.div`
  width: 700px;
  margin: 0 auto 8px;
  div {
    display: flex;
    justify-content: flex-end;
  }

  @media only screen and (max-device-width: 812px) {
    width: 100%;
    div {
      width: 100%;
      padding: 16px 24px 0;
      button {
        span {
          font-size: 11px;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  @media only screen and (max-device-width: 576px) {
    div {
      button {
        width: 100%;
      }
    }
  }
`;

export const Content = styled.div`
  &.velotax-content.second {
    opacity: 0.8;
    padding: 24px 0;
    box-shadow: none;
    font-weight: 400;
    font-size: 0.75rem;
    text-align: justify;
    background-color: transparent;
    color: var(--velotax-font-color);
  }
  h3 {
    display: flex;
    align-items: baseline;
    column-gap: 16px;
    color: var(--velotax-font-color);
    margin-bottom: 0;
    font-size: 18px;
    &.imovel-title {
      font-size: 20px;
    }
  }
  .desc-label {
    display: flex;
    align-items: center;
    svg {
      fill: var(--ant-primary-color);
    }
  }
  .desc-content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    column-gap: 8px;
    svg {
      fill: var(--ant-primary-color);
    }
    span {
      align-self: center;
    }
    button {
      min-width: 32px;
    }
  }
  .market {
    opacity: 0.6;
    font-weight: bold;
  }
  .ml-40 {
    margin-left: 40px;
  }
  .add {
    min-width: 128px;
    margin: 0 0 16px auto;
  }
  .ant-descriptions-item-label {
    width: 50%;
  }
  .ant-descriptions-item-content {
    text-align: center;
  }
  .ant-list.ant-list-split {
    padding: 0 16px;
    background-color: var(--velotax-ghost);
    border: 1px solid var(--velotax-ghost);
    border-radius: 8px;
    .ant-list-item-action > li {
      padding: 0;
    }
  }
  .ant-list-item-action > li,
  .ant-list-item-meta-title,
  .ant-list-item-meta-description {
    color: var(--velotax-font-color);
  }
  .ant-list-item-meta-description {
    opacity: 0.75;
  }
  .list-description {
    display: flex;
    flex-direction: column;
  }
  .min-darf-price {
    margin-top: 24px;
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
  .text-center {
    display: block;
    text-align: center;
  }
  .ant-collapse-header {
    padding: 12px 0 !important;
  }
  .ant-collapse-content-box {
    padding: 16px 0 !important;
  }
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow
    svg {
    transform: rotate(-90deg);
  }

  .total-tax-header {
    width: calc(100% - 48px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      margin: 0;
    }
  }

  &.view .total-tax-header.impostos-devidos h3:first-of-type {
    font-size: 18px;
  }

  @media only screen and (max-device-width: 812px) {
    width: 100%;
    border: none;
    padding: 24px;
    border-radius: 0;
    &.second {
      padding: 0 24px;
    }
    h3 {
      font-size: 16px;
      column-gap: 8px;
      &.imovel-title {
        font-size: 18px;
      }
    }
    .total-tax-header {
      width: calc(100% - 20px);
      h3.text-center {
        span {
          font-size: 16px;
        }
      }
    }
    .anticon.anticon-right.ant-collapse-arrow {
      top: 24px;
      right: 0;
    }
    .desc-content {
      column-gap: 2px;
      flex-direction: row;
      &.is-editting {
        row-gap: 8px;
        flex-direction: column;
        button {
          width: 100%;
        }
      }
      &:not(.is-editting) {
        span {
          line-height: 32px;
        }
        button {
          margin-top: -2px;
        }
      }
      &.ml-40 {
        margin-left: 0;
      }
    }
    .market {
      font-size: 11px;
      letter-spacing: -0.1px;
    }
  }
`;

export const BolsaOperations = styled.div`
  border-radius: 8px;
  color: var(--velotax-font-color);
  background-color: var(--velotax-ghost);
  border: 1px solid var(--velotax-background-color-ghost);

  div.strong span {
    font-weight: 600 !important;
  }
  div.opacity {
    opacity: 0.8;
  }

  div.header,
  div.sub-header,
  div.row {
    display: grid;
    grid-template-columns: 2fr 2fr;
    span + span {
      text-align: center;
      justify-content: center;
    }
  }

  div.sub-header,
  div.row {
    height: auto;
    min-height: 48px;
    border-top: none;
    & + div.sub-header {
      border-top: 1px solid var(--velotax-background-color-ghost);
    }
    & > span + span {
      background-color: var(--velotax-background-color);
    }
    & > span {
      padding: 8px 16px;
      display: flex;
      align-items: center;
      border-left: none;
      & + span {
        border-left: 1px solid var(--velotax-background-color-ghost);
      }
    }
    &:last-of-type > span:last-of-type {
      border-radius: 0 0 8px 0;
    }
  }

  div.header {
    padding: 16px;
    border-radius: 8px 8px 0 0;
    background-color: var(--velotax-background-color-ghost);
    span {
      font-weight: 700;
    }
    button {
      display: none;
    }
  }

  div.sub-header {
    font-size: 14px;
    & > span {
      font-weight: 500;
    }
  }

  div.row {
    opacity: 0.85;
    font-size: 13px;
    border-top: 1px solid var(--velotax-background-color-ghost);
  }

  @media only screen and (max-device-width: 812px) {
    position: relative;
    overflow-x: hidden;
    span {
      font-size: 12px;
      button {
        max-width: 24px;
        min-width: 24px;
      }
    }
    div.header,
    div.sub-header,
    div.row {
      width: 200%;
      height: auto;
      min-height: 48px;
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    div.sub-header,
    div.row {
      & > span {
        padding: 12px;
      }
    }

    div.header > span:first-of-type,
    div.sub-header > span:first-of-type {
      position: sticky;
      left: 0;
    }
    div.header > span.show,
    div.sub-header > span.show {
      opacity: 1;
    }
    div.header > span,
    div.sub-header > span {
      opacity: 0;
    }
    div.header > span {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 8px;
    }
    div.header {
      button {
        display: flex;
      }
    }
  }
`;
