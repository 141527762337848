import { LoadingOutlined } from "@ant-design/icons";
import { useEffect, useState, useMemo } from "react";
import { AiFillWarning, AiOutlineUnlock } from "react-icons/ai";
import { Col, Descriptions, Form, Input, message, Row, Typography } from "antd";
import api from "../../../services/api";
import { formatDate } from "../../../utils";
import { planos } from "../../Planos/planos";
import Button from "../../../components/Button";
import HandleTag from "../../../services/handleTag";
import apiVeloPro from "../../../services/apiVeloPro";
import { useAuth } from "../../../contexts/AuthContext";
import { apiPayment } from "../../../services/apiPayment";
import { FooterModal } from "../../../components/FooterModal";
import { DrawerModal } from "../../../components/DrawerModal";
import { PaymentModal } from "../../../components/PaymentModal";
import CreditCardScheduledPayment from "../../../components/Payment/CreditCardScheduledPayment";

const KEY_PASS = "DELETAR";

export const MeuPlano: React.FC = () => {
  const [formKey] = Form.useForm();
  const { user, getUserInfo, showUserPlanModal } = useAuth();

  const [data, setData] = useState<any>();
  const [keyPass, setKeyPass] = useState("");
  const [loading, setLoading] = useState(false);
  const [isVelopro, setVelopro] = useState(false);
  const [cancelModal, showCancelModal] = useState(false);
  const [changeCardModal, showChangeCardModal] = useState(false);
  const [creditCardModal, showCreditCardModal] = useState(false);
  const [scheduledPaymentData, setScheduledPaymentData] = useState<any>();

  const pendingCancel =
    data?.signature?.useDate > 0 &&
    (!data?.signature?.status || data?.signature?.status === "canceled");

  const checkPlanVelopro = () => {
    apiVeloPro
      .get(`/clients/hasPlanActive/${user.user.userId}`)
      .then(({ data }) => {
        if (data?.isPlanVelopro) setVelopro(true);
        else setVelopro(false);
      })
      .catch(() =>
        message.error("Ocorreu um erro, tente novamente mais tarde.")
      );
    return;
  };

  useEffect(() => {
    checkPlanVelopro();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVelopro]);

  const getData = () => {
    setLoading(true);
    apiPayment
      .get("/user-plan/get-plan-details")
      .then((ret: any) => {
        setLoading(false);
        setData(ret.data);
      })
      .catch(() => {
        setLoading(false);
        message.error(
          "Houve um erro ao buscar os detalhes. Contate nosso suporte"
        );
      });
  };

  const cancelPlan = () => {
    HandleTag("74");
    if (keyPass.toLowerCase() !== KEY_PASS.toLowerCase()) {
      message.error(`Digite ${KEY_PASS} corretamente!`);
      return;
    }

    if (isVelopro) {
      apiVeloPro
        .put(`/clients/cancelRecuring/${user.user.userId}`)
        .then(() => {
          message.success("Plano cancelado");
          showCancelModal(false);
          getUserInfo();
          getData();
        })
        .catch(() =>
          message.error(
            "Ocorreu um erro ao cancelar a recorrência, tente novamente mais tarde."
          )
        );
      return;
    }

    apiPayment
      .post("/user-plan/cancel_signature")
      .then((ret: any) => {
        message.success("Recorrência cancelado");
        showCancelModal(false);
        getUserInfo();
        getData();
      })
      .catch(() => {
        message.error("Ocorreu um erro contate nosso suporte");
      });
  };

  const getScheduledPayment = () => {
    setLoading(true);
    api
      .get("/scheduledPayment")
      .then((ret: any) => {
        setLoading(false);
        setScheduledPaymentData(ret.data);
      })
      .catch(() => {
        setLoading(false);
        message.error(
          "Houve um erro ao buscar os detalhes. Contate nosso suporte"
        );
      });
  };

  useEffect(() => {
    getData();
    getScheduledPayment();
  }, []);

  // const whitelist = ["41761601873", "45088456069", "00079692010"];
  // const isUserInWhitelist = whitelist.includes(user.user.cpf);
  const payForm: Record<string, string> = {
    pix: "Pix",
    credit_card: "Cartão de Crédito",
  };

  const planBasicInfo = user.user?.userPlanInfoVelotax;

  const currentPlan = useMemo(() => {
    const plansTypes: Record<string, string> = {
      MEI: "MEI",
      UNIQUE: "UNIQUE",
      VELOTAX_MAIN_BASIC: "Velotax Básico Anual",
      VELOTAX_MAIN_BASIC_MONTH: "Velotax Básico Mensal",
      VELOTAX_MAIN_PRO: "Velotax Premium Anual",
      VELOTAX_MAIN_PRO_MONTH: "Velotax Premium Mensal",
      VELOTAX_MAIN_SILVER: "Velotax Básico Anual",
      VELOTAX_MAIN_PLATINUM: "Velotax Premium Anual",
      VELOTAX_MAIN_CONCIERGE: "Velotax Concierge Anual",
      VELOTAX_MAIN_CONCIERGE_MONTH: "Velotax Concierge Mensal",
    };

    if (planBasicInfo?.active) return plansTypes[planBasicInfo?.type];

    return "Gratuito";
  }, [planBasicInfo?.type, planBasicInfo?.active]);

  const planStatus = useMemo(
    () =>
      currentPlan === "Gratuito"
        ? "Ativo"
        : planBasicInfo.active
        ? "Ativo"
        : "Inativo",
    [planBasicInfo.active, currentPlan]
  );

  const isActive = useMemo(() => planStatus === "Ativo", [planStatus]);

  const onDeleteCancel = () => {
    showCancelModal(false);
    showCreditCardModal(false);
    formKey.resetFields();
    setKeyPass("");
  };

  const showButtons = useMemo(
    () =>
      currentPlan !== "Gratuito" &&
      !pendingCancel &&
      data?.signature?.paymentForm !== "pix" &&
      !data?.signature?.fromAccountant,
    [
      currentPlan,
      pendingCancel,
      data?.signature?.fromAccountant,
      data?.signature?.paymentForm,
    ]
  );

  const contentStyle = {
    color: "var(--velotax-font-color-ghost)",
    fontSize: 15,
    marginBottom: 0,
  };

  return (
    <div className="velotax-content plano">
      <Typography.Title level={1} className="page-title">
        Dados do plano
      </Typography.Title>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <LoadingOutlined />{" "}
        </div>
      ) : (
        <>
          <Descriptions
            contentStyle={contentStyle}
            title={<h4>Plano Atual</h4>}
          >
            <Descriptions.Item>{currentPlan}</Descriptions.Item>
          </Descriptions>
          <Descriptions
            contentStyle={{ ...contentStyle, marginBottom: 5 }}
            title={<h4>Status do Plano</h4>}
          >
            <Descriptions.Item
              style={{ display: "flex", alignItems: "center" }}
            >
              <div
                style={{
                  width: 60,
                  padding: 4,
                  fontWeight: 500,
                  borderRadius: 4,
                  textAlign: "center",
                  color: "var(--white)",
                  backgroundColor: isActive
                    ? "var(--ant-success-color)"
                    : "var(--ant-error-color)",
                }}
              >
                {planStatus}
              </div>
            </Descriptions.Item>
          </Descriptions>
          {data?.signature?.next_billing_at &&
            planBasicInfo.active &&
            !pendingCancel && (
              <Descriptions
                contentStyle={contentStyle}
                title={<h4>{"Próxima Cobrança"}</h4>}
              >
                <Descriptions.Item>
                  {formatDate(data?.signature?.next_billing_at)}
                </Descriptions.Item>
              </Descriptions>
            )}
          {data?.signature?.start_at && planBasicInfo.active && (
            <Descriptions
              contentStyle={contentStyle}
              title={<h4>{"Data da Assinatura"}</h4>}
            >
              {data?.signature?.start_at && (
                <Descriptions.Item>
                  {formatDate(data?.signature?.start_at)}
                </Descriptions.Item>
              )}
            </Descriptions>
          )}
          {data?.signature?.dueDate && planBasicInfo.active && (
            <Descriptions
              contentStyle={contentStyle}
              title={<h4>{"Vencimento da Assinatura"}</h4>}
            >
              {data?.signature?.dueDate && (
                <Descriptions.Item>
                  {formatDate(data?.signature?.dueDate)}
                </Descriptions.Item>
              )}
            </Descriptions>
          )}
          {data?.signature?.paymentForm && planBasicInfo.active && (
            <Descriptions
              contentStyle={contentStyle}
              title={<h4>{"Forma de pagamento"}</h4>}
            >
              {scheduledPaymentData?.hasRequested ? (
                <Descriptions.Item>{<>Cartão de Crédito</>}</Descriptions.Item>
              ) : (
                data?.signature?.paymentForm && (
                  <Descriptions.Item>
                    {<>{payForm[data?.signature?.paymentForm]}</>}
                  </Descriptions.Item>
                )
              )}
            </Descriptions>
          )}

          {data?.signature?.paymentForm !== "pix" &&
            planBasicInfo.active &&
            data?.signature?.card?.lastFourDigits && (
              <Descriptions
                contentStyle={contentStyle}
                title={<h4>{"Cartão de cobrança"}</h4>}
              >
                <Descriptions.Item>
                  {<>**** **** **** {data?.signature?.card?.lastFourDigits}</>}
                </Descriptions.Item>
              </Descriptions>
            )}

          {currentPlan === "Gratuito" && (
            <div className="content-button">
              <Button
                block
                size="large"
                type="primary"
                icon={<AiOutlineUnlock size={22} />}
                onClick={() => {
                  HandleTag("72");
                  showUserPlanModal(true);
                }}
              >
                CONTRATAR PLANO PREMIUM
              </Button>
            </div>
          )}

          {data?.signature?.paymentForm === "pix" &&
            !scheduledPaymentData?.hasRequested && (
              <div className="content-button">
                <Button
                  block
                  size="large"
                  type="primary"
                  icon={<AiOutlineUnlock size={22} />}
                  onClick={() => {
                    showCreditCardModal(true);
                  }}
                >
                  ALTERAR FORMA DE PAGAMENTO
                </Button>
              </div>
            )}

          {showButtons && (
            <Row justify="end">
              <Col span={24}>
                <Row justify="end">
                  <Col>
                    <Button
                      style={{ opacity: 0.8, marginBottom: "16px" }}
                      onClick={() => {
                        showChangeCardModal(true);
                      }}
                    >
                      Alterar cartão de cobrança
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Button
                  danger
                  type="text"
                  style={{ opacity: 0.8 }}
                  onClick={() => {
                    showCancelModal(true);
                    HandleTag("73");
                  }}
                >
                  Cancelar recorrência
                </Button>
              </Col>
            </Row>
          )}
        </>
      )}
      {changeCardModal && (
        <PaymentModal
          hideDarfDownload
          closeModal={() => {
            showChangeCardModal(false);
            getData();
          }}
          changeCardAction
          onSuccessGeneral={() => {
            showChangeCardModal(false);
            getData();
          }}
          planCurrent={planos.find(
            (plan) => plan?.type === user?.user?.userPlanInfoVelotax?.type
          )}
          hidePixButton
          blockListCard={[data?.signature?.card?.id]}
          show={changeCardModal}
          onCancel={() => showChangeCardModal(false)}
          paymentData={{
            type: planBasicInfo?.type,
            isPlan: true,
          }}
          callDarf={(value: boolean) => null}
          customInstallments={12}
        />
      )}

      <DrawerModal
        cancelText="Não"
        okText="Cancelar recorrência"
        onOk={cancelPlan}
        visible={creditCardModal}
        onCancel={onDeleteCancel}
      >
        <CreditCardScheduledPayment
          // initialized={true}
          // changeCardAction={changeCardAction}
          // typePurchase={getPurchaseType()}
          handleClosableModal={showCreditCardModal}
          closeMainModal={() => getScheduledPayment()}
          // status=""
          // plan={planCurrent}
          // addInfo={{ hasDiscount }}
          // handleSuccess={onSucessCreditCard}
          // setInitialized={() => {}}
          // darfData={paymentData}
          // handleIdBack={changePaymentId}
          // customInstallments={customInstallments}
          // backToRoot={() => setStep(EPlanStep.CHOOSE_PAYMENT)}
          // cupom={cupom}
          // reCaptchaToken={reCaptchaToken}
          // setReCaptchaToken={setReCaptchaToken}
        />
      </DrawerModal>

      <DrawerModal
        cancelText="Não"
        okText="Cancelar recorrência"
        onOk={cancelPlan}
        visible={creditCardModal}
        onCancel={onDeleteCancel}
      >
        <CreditCardScheduledPayment
          // initialized={true}
          // changeCardAction={changeCardAction}
          // typePurchase={getPurchaseType()}
          handleClosableModal={showCreditCardModal}
          closeMainModal={() => getScheduledPayment()}
          // status=""
          // plan={planCurrent}
          // addInfo={{ hasDiscount }}
          // handleSuccess={onSucessCreditCard}
          // setInitialized={() => {}}
          // darfData={paymentData}
          // handleIdBack={changePaymentId}
          // customInstallments={customInstallments}
          // backToRoot={() => setStep(EPlanStep.CHOOSE_PAYMENT)}
          // cupom={cupom}
          // reCaptchaToken={reCaptchaToken}
          // setReCaptchaToken={setReCaptchaToken}
        />
      </DrawerModal>

      <DrawerModal
        cancelText="Não"
        okText="Cancelar recorrência"
        onOk={cancelPlan}
        visible={cancelModal}
        onCancel={onDeleteCancel}
        footer={
          <FooterModal
            onOk={cancelPlan}
            onCancel={onDeleteCancel}
            saveButtonText="Excluir"
          />
        }
      >
        <p
          style={{
            fontSize: "18px",
            letterSpacing: "-0.5px",
            width: "calc(100% - 48px)",
          }}
        >
          Deseja realmente cancelar a recorrência?
        </p>
        <Row gutter={[16, 16]} align="middle">
          <Col span={10}>
            <AiFillWarning
              size={100}
              color="var(--ant-warning-color)"
              style={{ display: "block", margin: "0 auto" }}
            />
          </Col>
          <Col span={14}>
            <Typography.Paragraph strong style={{ fontSize: "1rem" }}>
              Deseja realmente cancelar sua recorrência?
            </Typography.Paragraph>
            <Typography.Paragraph>
              <strong>Atenção:</strong> todos os seus dados de impostos,
              inclusive a memória dos cálculos e o histórico dos DARFs pagos,
              serão excluídos definitivamente da plataforma. Você poderá ser
              obrigado pela Receita Federal a apresentar tais informações, caso
              seja questionado.
            </Typography.Paragraph>
          </Col>
          <Col span={24}>
            <Typography style={{ fontSize: "1rem" }}>
              Digite <strong>{KEY_PASS}</strong> para prosseguir:
            </Typography>
          </Col>
          <Col span={24}>
            <Form
              form={formKey}
              onChange={(event: any) => {
                setKeyPass(event.target.value);
              }}
            >
              <Form.Item name="key">
                <Input placeholder={KEY_PASS} />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </DrawerModal>
    </div>
  );
};
