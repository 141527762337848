import { Typography } from "antd";
import { GiProfit } from "react-icons/gi";
import { RiFileList3Line } from "react-icons/ri";
import { HiOutlineCalculator } from "react-icons/hi";
import { isMobile } from "../../utils";
import { ReactComponent as IntegrationIcon } from "../../assets/icons/integration.svg";
import WarrenLogo from "../../assets/warren-logo.svg";
import XP_black_logo from "../../assets/xp-black.svg";
import BTGLogo from "../../assets/btgpactual.svg";

export const itensExterior = [
  {
    id: "darf",
    premium: false,
    showToBasic: true,
    link: "/velotax/exterior-historic",
    content: <Typography>Calculadora de DARF</Typography>,
    description: <Typography>Cálculo de IR e emissão de DARF</Typography>,
    icon: (
      <HiOutlineCalculator
        className="no-fill"
        color="var(--ant-primary-color)"
      />
    ),
  },
  {
    id: "report",
    basic: true,
    premium: true,
    link: "/velotax/exterior-report",
    content: (
      <Typography>
        Relatórios {!isMobile() && "(padrão Receita Federal)"}
      </Typography>
    ),
    description: (
      <Typography>Relatórios auxiliares para declaração de IR</Typography>
    ),
    icon: (
      <RiFileList3Line className="no-fill" color="var(--ant-primary-color)" />
    ),
  },
  {
    id: "dividendos",
    premium: true,
    link: "/velotax/exterior-dividendos",
    content: <Typography>Dividendos</Typography>,
    icon: <GiProfit className="no-fill" color="var(--ant-primary-color)" />,
    description: <Typography>Gestão dos dividendos recebidos</Typography>,
  },
  {
    id: "integration",
    premium: false,
    showToBasic: true,
    link: "/velotax/exterior-integrations",
    content: <div>
      <Typography>Integrações</Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: "24px",
          marginBottom: "8px",
          paddingTop: "16px"
        }}
      >
        {!isMobile() &&
        <>
          <img
            src={XP_black_logo}
            alt="xp-logo"
            style={{
              height: "20px",
            }}
          />
          <img
            src={WarrenLogo}
            alt="warren-logo"
            style={{
              height: "20px",
            }}
          />
          <img
            src={BTGLogo}
            alt="btg-logo"
            style={{
              height: "20px",
            }}
          />
          {/* <img
            src={SprotifiLogo}
            alt="sproutfi-logo"
            style={{
              height: "20px",
            }}
          /> */}
        </>
        }

      </div>
    </div>,
    icon: <IntegrationIcon className="no-fill" />,
    description: <Typography>Conexões com as suas corretoras</Typography>,
  },
];
