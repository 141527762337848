import clsx from "clsx";
import { ConfigProvider } from "antd";
import { Navigate, Outlet, Route, Routes as Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoutes";
import OnlyPublicRoute from "./OnlyPublicRoute";
import PageNotFound from "../pages/PageNotFound";
import { useAuth } from "../contexts/AuthContext";
import PageWrapper from "../components/PageWrapper";
import { useBroker } from "../contexts/BrokerContext";
import { PublicWrapper } from "../components/PublicWrapper";

function Routes() {
  const { showIRPFAPPBanner } = useAuth();
  const {
    mixedRoutes,
    brokerRoutes,
    publicRoutes,
    privateRoutes,
    currentBroker,
    registerRoutes,
    privatePublicWrapperRoutes,
  } = useBroker();

  ConfigProvider.config({
    theme: {
      primaryColor: currentBroker?.colors.primary || "#1634ff",
      infoColor: currentBroker?.colors.secondary || "#000058",
      successColor: "#00cc8b",
    },
  });

  return (
    <>
      <Switch>
        <Route
          path="p"
          element={
            <PublicWrapper className={clsx({ showIRPFAPPBanner })}>
              <OnlyPublicRoute />
            </PublicWrapper>
          }
        >
          {publicRoutes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
          <Route path="" element={<Navigate to="login" />} />
          <Route path="*" element={<Navigate to="login" />} />
        </Route>

        <Route
          path={currentBroker.path}
          element={
            <PageWrapper overflow={true}>
              <OnlyPublicRoute />
            </PageWrapper>
          }
        >
          {registerRoutes}
        </Route>

        <Route
          path={currentBroker.path}
          element={
            <PageWrapper overflow={true}>
              <Outlet />
            </PageWrapper>
          }
        >
          {mixedRoutes}
        </Route>

        <Route
          path={currentBroker.path}
          element={
            <PublicWrapper className={clsx({ showIRPFAPPBanner })}>
              <PrivateRoute />
            </PublicWrapper>
          }
        >
          {privatePublicWrapperRoutes}
        </Route>

        <Route
          path="/"
          element={
            <PageWrapper overflow={true}>
              <PrivateRoute />
            </PageWrapper>
          }
        >
          {brokerRoutes}
          {privateRoutes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
          <Route path="" element={<Navigate to="/" />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>

        <Route path="" element={<PageNotFound />} />
        <Route path="*" element={<PageNotFound />} />
      </Switch>
    </>
  );
}

export default Routes;
