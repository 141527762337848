import { ReactNode } from "react";
import { CardContainer } from "./styles";

interface CardProps {
  icon: ReactNode;
  children: ReactNode;
  handleClick?: any;
  className?: string;
}

export const Card = ({ icon, handleClick, children, className }: CardProps) => {
  return (
    <CardContainer className={className} onClick={handleClick}>
      <figure>{icon}</figure>
      <div className="content">{children}</div>
    </CardContainer>
  );
};
