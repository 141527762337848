import { LoadingOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import Button from "../Button";

interface FooterModalProps {
  onCancel: () => void;
  onOk?: () => void;
  disabled?: boolean;
  loading?: boolean;
  saveButtonText?: string;
}

export const FooterModal: React.FC<FooterModalProps> = ({ onCancel, onOk, disabled, loading, saveButtonText }) => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Button size="large" className="full-width" onClick={onCancel} disabled={disabled}>
          Cancelar
        </Button>
      </Col>
      <Col span={12}>
        <Button
          size="large"
          type="primary"
          className="full-width"
          disabled={disabled || loading}
          onClick={onOk ? onOk : undefined}
          htmlType={onOk ? undefined : "submit"}
        >
          {saveButtonText || 'Salvar'}
          {
            loading && <LoadingOutlined />
          }
        </Button>
      </Col>
    </Row>
  );
};
