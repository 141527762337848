import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineCheck } from "react-icons/ai";
import { Col, Divider, Row, Typography } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import Button from "../../components/Button";
import Loading from "../../components/Loading";
import { BolsaManualContainer } from "./styles";
import { useBroker } from "../../contexts/BrokerContext";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import {
  OldTransactions,
  OldTransactionsProps,
} from "../Darf/Components/OldTransactions";
import { PrePreenchidaModal } from "../PrePreenchidaModal";
import { useOldTransactions } from "../../contexts/OldTransactionsContext";
import { useUploadDec } from "../../contexts/UploadDecContext";

interface BolsaManualProps {}

export const BolsaManual: React.FC<BolsaManualProps> = () => {
  const navigate = useNavigate();
  const { currentBroker } = useBroker();
  const [showAlertModal, setShowAlertModal] = useState(false);
  const { loadingGet, changed, getOldTransactionsInit, ...rest } =
    useOldTransactions();
  const [showModalPrePreenchida, setShowModalPrePreenchida] = useState(false);
  const { loading } = useUploadDec();

  const handleAlertModalVisibility = (value: boolean) => {
    setShowAlertModal(value);
  };

  const handleSubmit = async () => {
    try {
      if (rest.oldPositionList.some((oldPosition) => oldPosition.price === 0)) {
        handleAlertModalVisibility(true);
        return;
      }
      await rest.saveOldPositions();
      setTimeout(() => {
        navigate(`/${currentBroker.path}/bolsa-historic`);
      }, 1000);
    } catch (err) {}
  };

  useEffect(() => {
    if (rest.oldPositionList?.length < 1 && rest.oldPositionList !== null) {
      navigate(`/${currentBroker.path}/bolsa-historic`);
    }
  }, [rest.oldPositionList, navigate, currentBroker.path]);

  useEffect(() => {
    getOldTransactionsInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModalPrePreenchida, loading]);

  return loadingGet ? (
    <Loading />
  ) : (
    <BolsaManualContainer>
      <div className="velotax-container">
        <div className="velotax-content">
          <Typography.Title level={1} className="page-title">
            Complete sua carteira de 31/12/2019
          </Typography.Title>
          <Typography style={{ marginBottom: "32px", textAlign: "justify" }}>
            <InfoCircleOutlined />
            &nbsp;A B3 armazena dados somente a partir de 2020. Por isso, você
            precisará preencher manualmente o custo médio dos seus investimentos
            em 31/12/2019. Você pode encontrar essas informações na sua
            Declaração de Imposto de Renda de 2019/2020.
          </Typography>
          <Row justify="start" className="btn-preencher-pos2019">
            <Col span={24}>
              <Button
                block
                size="large"
                type="primary"
                loading={rest.loadingPut}
                onClick={() => setShowModalPrePreenchida(true)}
              >
                Preencher automaticamente
              </Button>
            </Col>
          </Row>
          <OldTransactions
            {...({ ...rest, loadingGet, changed, getOldTransactionsInit, } as OldTransactionsProps)}
          />
          <Typography style={{ marginTop: "32px", textAlign: "right" }}>
            Lembre-se de clicar em finalizar para salvar seus dados
          </Typography>
          <Divider />
          <Row justify="end">
            <Col>
              <Button
                size="large"
                type="primary"
                id="submit-button"
                onClick={handleSubmit}
                loading={rest.loadingPut}
                icon={<AiOutlineCheck size={18} />}
              >
                Finalizar
              </Button>
            </Col>
          </Row>
        </div>
      </div>

      <DeleteConfirmationModal
        buttonLabel="Prosseguir"
        visibility={showAlertModal}
        title="Deseja realmente prosseguir?"
        onCancel={() => setShowAlertModal(false)}
        setVisibility={handleAlertModalVisibility}
        successMessage="Ativos salvos com sucesso!"
        onOk={() => Promise.resolve(rest.saveOldPositions())}
        body="O valor de algumas posições não foram preenchidas!"
      />

      <PrePreenchidaModal
        visible={showModalPrePreenchida}
        setShowModalPrePreenchida={setShowModalPrePreenchida}
        onCancel={() => setShowModalPrePreenchida(false)}
      />
    </BolsaManualContainer>
  );
};
