import React from "react";
import { Col, Row } from "antd";
import { GrMenu } from "react-icons/gr";
import { BsChevronLeft } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../Button";
import { isMobile } from "../../utils";
import { LayoutHeader } from "./styles";
import { useAuth } from "../../contexts/AuthContext";
import { useHistory } from "../../contexts/HistoryContext";

interface IHeaderProps {}

const Header: React.FC<IHeaderProps> = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { handleBackHistory } = useHistory();
  const { user, setShowDrawer } = useAuth();

  const registerPage =
    !user.user &&
    (pathname.includes("integration") || pathname.includes("cadastro"));

  return (
    <>
      <LayoutHeader>
        {isMobile() && (
          <Row
            align="middle"
            justify="space-between"
            style={{ width: "100%", flexWrap: "nowrap" }}
          >
            {!user.user && registerPage ? (
              <Col>
                <Button
                  type="text"
                  className="login-button"
                  onClick={() => {
                    navigate("/p/login");
                  }}
                >
                  Já possui conta? <strong>Faça seu login</strong>
                </Button>
              </Col>
            ) : (
              <Col>
                <Button
                  type="link"
                  className="back-button"
                  icon={<BsChevronLeft />}
                  onClick={handleBackHistory}
                />
              </Col>
            )}
            <Col>
              <Button
                type="text"
                size="large"
                className="menu-button"
                icon={<GrMenu />}
                onClick={() => setShowDrawer(true)}
              />
            </Col>
          </Row>
        )}
      </LayoutHeader>
    </>
  );
};

export default Header;
