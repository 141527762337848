import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  .velotax-historic-list.ant-list.ant-list-split {
    padding: 0;
    margin: 32px 0 0;
    .ant-spin-nested-loading {
      .ant-spin-container {
        .ant-list-items {
          .ant-list-item {
            padding: 0;
            border-radius: 8px;
            margin-bottom: 16px;
            position: relative;
            border: 2px solid var(--light-gray);
            .ant-list-item-meta {
              .ant-list-item-meta-content {
                .ant-list-item-meta-title {
                  margin: 0;
                  height: 48px;
                  display: flex;
                  font-size: 1rem;
                  font-weight: 700;
                  padding: 12px 24px;
                  line-height: 1.5rem;
                  align-items: center;
                  justify-content: space-between;
                  color: var(--velotax-font-color);
                  background-color: var(--light-gray);
                  .ant-switch {
                    &:not(.ant-switch-checked) {
                      padding-right: 6px;
                    }
                    &.ant-switch-checked {
                      padding-left: 6px;
                      background-color: var(--ant-success-color);
                    }
                  }
                }
                .ant-list-item-meta-description {
                  padding: 16px 24px;
                  color: var(--velotax-font-color);
                  .ant-space.ant-space-vertical {
                    gap: 2px !important;
                    .ant-space-item {
                      .ant-typography {
                        & > span {
                          width: 120px;
                          display: inline-block;
                        }
                        strong {
                          font-weight: 700;
                          word-spacing: 2px;
                        }
                      }
                      .tax-value {
                        font-weight: 700;
                        word-spacing: 2px;
                        letter-spacing: -1px;
                        color: var(--velotax-font-color);
                      }
                      .no-plan-imposto-a-pagar {
                        display: flex;
                        align-items: baseline;
                        .ant-btn {
                          padding: 0;
                          height: 24px;
                          font-size: 14px;
                          font-weight: 600;
                          display: inline-flex;
                          strong {
                            color: blue;
                            display: flex;
                            font-weight: 500;
                            align-items: center;
                            word-spacing: -0.5px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .ant-list-item-action {
              right: 16px;
              display: flex;
              margin-left: 24px;
              position: absolute;
              top: calc(50% + 6px);
              li + li {
                margin-left: 8px;
              }
              & > li {
                padding: 0;
                color: var(--velotax-font-color);
                .ant-btn-primary {
                  height: 36px;
                  padding: 4px 24px;
                  border-radius: 4px;
                }
                .ant-btn-link {
                  height: 36px;
                  border-radius: 4px;
                  background-color: var(--velotax-ghost);
                  &.ant-btn-icon-only {
                    width: 36px;
                  }
                  :hover {
                    opacity: 0.7;
                  }
                  svg {
                    stroke-width: 0.3px;
                    fill: var(--ant-primary-color);
                  }
                }
                .ant-list-item-action-split {
                  opacity: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-device-width: 812px) {
    .velotax-historic-list.ant-list.ant-list-split {
      .ant-spin-nested-loading {
        .ant-spin-container {
          .ant-list-items {
            .ant-list-item {
              flex-wrap: nowrap;
              align-items: unset;
              flex-direction: column;
              justify-content: unset;
              .ant-list-item-meta {
                .ant-list-item-meta-content {
                  .ant-list-item-meta-title {
                    padding: 12px 16px;
                  }
                  .ant-list-item-meta-description {
                    padding: 16px;
                    .ant-space.ant-space-vertical {
                      width: 100%;
                      .ant-space-item {
                        .ant-typography {
                          & > * {
                            width: 50%;
                            text-align: right;
                            display: inline-block;
                          }
                          .tax-value {
                            text-align: right;
                          }
                          span + span {
                            text-align: right;
                          }
                          & > span {
                            width: 50%;
                            text-align: left;
                          }
                        }
                        .no-plan-imposto-a-pagar {
                          flex-wrap: nowrap;
                          white-space: nowrap;
                          .ant-btn {
                            display: flex;
                            margin-left: 4px;
                            padding: 0 !important;
                            justify-content: end;
                          }
                        }
                      }
                    }
                  }
                }
              }
              .ant-list-item-action {
                top: unset;
                right: unset;
                margin-left: 0;
                position: relative;
                justify-content: end;
                padding: 8px 12px;
                border-top: 2px solid var(--velotax-background-color-ghost);
                .ant-btn-primary {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
`;
