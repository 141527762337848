import Cards from "react-credit-cards";
import { MdCheck, MdWarningAmber } from "react-icons/md";
import { useNavigate, useLocation } from "react-router-dom";
import React, { useMemo, useState, useEffect, useCallback } from "react";
import {
  InfoCircleOutlined,
  LoadingOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import {
  Row,
  Col,
  Spin,
  message,
  Typography,
  Select,
  Popover,
  Form,
  Input,
} from "antd";
import Button from "../Button";
import { ReCaptcha } from "../ReCaptcha";
import { DrawerModal } from "../DrawerModal";
import { cardAttributes } from "../CardList";
import { Page } from "../../constants/brokers";
import HandleTag from "../../services/handleTag";
import { useAuth } from "../../contexts/AuthContext";
import { apiPayment } from "../../services/apiPayment";
import PixPaymentStep from "../Payment/PixPaymentStep";
import CreditCardStep from "../Payment/CreditCardStep";
import { useBroker } from "../../contexts/BrokerContext";
import { ETypePurchase, UserPlansTypes } from "../../constants/plans";
import { validationFieldRequired } from "../../utils/formValidations";
import getInstallmentsByPrice from "../../utils/getInstallmentsListByPrice";
import {
  errorMessage,
  formatCurrency,
  months,
  oneDayTimestamp,
} from "../../utils";
import {
  CardButton,
  PopoverItem,
  PopoverItemValue,
  PopoverLabel,
} from "./style";

interface PaymentModalProps {
  item?: Page;
  impostoTotalFinal?: number;
  show: boolean;
  onCancel?: (e: any) => void;
  paymentData: any;
  callDarf: any;
  hideDarfDownload?: boolean;
  onSucessPix?: (event: any) => void;
  onSucessCreditCard?: (event: any) => void;
  planCurrent?: any;
  disablePixOption?: boolean;
  disableCreditCardOption?: boolean;
  customInstallments?: number;
  maxPixValue?: number;
  maxCCValue?: number;
  selectedYear?: number;
  emitting?: boolean;
  cupom?: any;
  setCupom?: any;
  hidePixButton?: boolean;
  blockListCard?: string[];
  changeCardAction?: boolean;
  onSuccessGeneral?: () => void;
  closeModal?: () => void;
  closeDarfModal?: () => void;
  addInfo?: Record<string, any>;
  isBatchDarf?: Boolean;
  yearBatch?: number;
  resumo?: any[];
  openPlanModal?: () => void;
}

interface PaymentInfoContentProps {
  content: JSX.Element;
  title?: string;
  amount?: number;
}

export interface PaymentDetailsProp {
  value?: number;
  paymentId: string;
  qrCodeLink?: string;
  name?: string;
}

enum EPlanStep {
  CHOOSE_PAYMENT = "CHOOSE_PAYMENT",
  PIX_PAYMENT = "PIX_PAYMENT",
  PIX_FORM = "PIX_FORM",
  CC_PAYMENT = "CC_PAYMENT",
  LOADING = "LOADING",
  FINISHED = "FINISHED",
  NO_VALUE = "NO_VALUE",
}

let getStatus: any;

export const PaymentModal: React.FC<PaymentModalProps> = ({
  item,
  show,
  onCancel,
  paymentData,
  callDarf,
  hideDarfDownload,
  disablePixOption,
  disableCreditCardOption,
  onSucessPix,
  planCurrent,
  onSucessCreditCard,
  customInstallments,
  maxPixValue,
  maxCCValue,
  selectedYear,
  emitting = false,
  cupom,
  hidePixButton,
  blockListCard,
  changeCardAction,
  onSuccessGeneral,
  closeModal,
  closeDarfModal,
  addInfo,
  isBatchDarf,
  yearBatch,
  resumo,
  openPlanModal,
}) => {

  const [pixForm] = Form.useForm();
  const broker = useBroker();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { currentPage } = useBroker();
  const { showUserPlanModal, isIOSAPP, ssoToken, getSsoToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const [installment, setInstallment] = useState(1);
  const [loadingCards, setLoadingCards] = useState(false);
  const [pixClientInfo, setPixClientInfo] = useState<any>();
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [cardList, setCardList] = useState<cardAttributes[]>([]);
  const [reCaptchaToken, setReCaptchaToken] = useState<string>();
  const [selectedCard, setSelectedCard] = useState<cardAttributes>();
  const [step, setStep] = useState<EPlanStep>(EPlanStep.CHOOSE_PAYMENT);
  const [data, setData] = useState({
    darf: { _id: "" },
    payment: { paymentId: 0 },
  });

  const pagarmeOff = false;
  const hasDiscount =
    (addInfo?.lastTimeOpenPlanModalVeloApp || 0) + 3 * oneDayTimestamp >
    new Date().getTime();

  const isPlan = useMemo(() => {
    return UserPlansTypes.includes(planCurrent?.type || "");
  }, [planCurrent]);

  const value = useMemo(() => {
    if (isPlan) {
      var isMonth = (planCurrent as any).interval === "Mensal";

      var planPrice =
        (!isMonth
          ? (hasDiscount
              ? planCurrent?.price
              : planCurrent.originalPrice || 0) * 12
          : planCurrent?.price) || 0;

      planPrice = Number(planPrice.toFixed(2));

      return planPrice && isMonth ? planPrice * 12 : planPrice;
    }

    return paymentData?.impostoTotalFinal; //caso nao seja plano
  }, [paymentData?.impostoTotalFinal, planCurrent, isPlan, hasDiscount]);

  const fixedInstallments = useMemo(
    () => (!!planCurrent?.type ? 12 : undefined),
    [planCurrent?.type]
  );

  const installments = useMemo(
    () => getInstallmentsByPrice(value, !!planCurrent?.type, fixedInstallments),
    [planCurrent, value, fixedInstallments]
  );

  const checkWasPaid = useCallback(
    (delay: number = 5) => {
      let attempts = 0;
      let check_id: any = data?.payment?.paymentId;

      if (step === "PIX_PAYMENT") {
        check_id = data;
      }

      if (!check_id) return;

      getStatus = setInterval(async () => {
        apiPayment
          .get(`/payment?id=${check_id}`)
          .then((res: any) => {
            if (res.data?.status === "paid") {
              clearInterval(getStatus);
              setStep(EPlanStep.FINISHED);
              setLoading(false);
              message.success("DARF pago com sucesso!");
              setTimeout(() => {
                if (item?.settings?.historicUrl) {
                  closeDarfModal?.();
                  setTimeout(() => {
                    navigate(item?.settings?.historicUrl, {
                      state: { year: selectedYear ?? new Date().getFullYear() },
                    });
                  }, 500);
                } else {
                  setTimeout(() => {
                    closeDarfModal?.();
                    setTimeout(() => {
                      setStep(EPlanStep.CHOOSE_PAYMENT);
                    }, 500);
                  }, 1000);
                }
              }, 500);
            }
          })
          .finally(() => {
            attempts += 1;
            if (attempts > 100) {
              clearInterval(getStatus);
            }
          });
      }, delay * 1000);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      data,
      step,
      item,
      navigate,
      pathname,
      selectedYear,
      broker?.currentBroker?.path,
    ]
  );

  const createPlanMainVelotax = () => {
    const url = "/user-plan/create";

    const cardInfo = { card_id: selectedCard?.id };
    const clientInfo = { customer_id: selectedCard?.customer_id };

    setLoadingPayment(true);

    apiPayment
      .post(
        url,
        {
          ...paymentData,
          type: planCurrent?.type,
          from: "VELOTAX",
          paymentForm: "credit_card",
          installments: 12,
          cupomCode: cupom?.cupomCode,
          cardInfo,
          clientInfo,
        },
        {
          headers: {
            recaptcha: reCaptchaToken || "",
          },
        }
      )
      .then((res: any) => {
        if (res.data.paymentId || res.data.paymentRecurringId)
          onSucessCreditCard && onSucessCreditCard(res.data);

        setLoading(true);
        setStep(EPlanStep.LOADING);
      })
      .catch((err) => {
        message.error(err.response?.data?.status);
      })
      .finally(() => {
        setLoadingPayment(false);
      });
  };

  const purchaseType = useMemo(() => {
    if (planCurrent?.type in ETypePurchase) return planCurrent?.type;

    return ETypePurchase.UNIQUE;
  }, [planCurrent]);

  const createUniquePayment = () => {
    apiPayment
      .get("/user-plan/plan-info")
      .then((res: any) => {
        const url = isBatchDarf ? "/darf/batch" : "/darf";

        const cardInfo = { card_id: selectedCard?.id };
        const clientInfo = { customer_id: selectedCard?.customer_id };

        if (!res.data?.active) {
          if (closeModal) closeModal();
          if (openPlanModal) openPlanModal();
        } else {
          const paymentDataPayload = isBatchDarf
            ? {
                amount: paymentData.impostoTotalFinal,
                resumo,
              }
            : {
                ...paymentData,
              };
          setLoadingPayment(true);

          (currentPage?.api || apiPayment)
            .post(
              url,
              {
                ...paymentDataPayload,
                paymentForm: "credit_card",
                paymentInfo: {
                  installments: installment,
                  cardInfo: cardInfo,
                  clientInfo: clientInfo,
                },
              },
              {
                headers: {
                  recaptcha: reCaptchaToken || "",
                },
              }
            )
            .then((res: any) => {
              changePaymentId(res?.data);
              setStep(EPlanStep.LOADING);
              setLoading(true);
            })
            .catch((err) => {
              message.error(err.response?.data?.message || errorMessage);
            })
            .finally(() => {
              setLoadingPayment(false);
            });
        }
      })
      .catch((e: any) => console.log(e));
  };

  useEffect(() => {
    if (data?.payment?.paymentId !== 0) {
      checkWasPaid();
    }
    return () => getStatus && clearInterval(getStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (isBatchDarf) {
      if (paymentData.impostoTotalFinal > 0) setStep(EPlanStep.CHOOSE_PAYMENT);
      else setStep(EPlanStep.NO_VALUE);
    }
  }, [paymentData, isBatchDarf]);

  useEffect(() => {
    if (show) {
      if (!isIOSAPP) {
        getCardList();
      }
    } else {
      setSelectedCard(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const navigateToBrowser = () => {
    getSsoToken();
    window.location.href = !process.env.REACT_APP_URL?.includes("dev")
      ? `https://app.velotax.com.br/p/sso/${ssoToken}`
      : `https://app-dev.velotax.com.br/p/sso/${ssoToken}`;
  };

  const changeCardService = async () => {
    if (!selectedCard?.id) return;

    let cardInfo: any = { card_id: selectedCard?.id };

    setLoadingPayment(true);

    apiPayment
      .post(`/user-plan/change_signature_card`, {
        card_id: cardInfo.card_id,
      })
      .then((ret) => {
        message.success("Cartão atualizado com sucesso!");
      })
      .catch((err) => {
        message.error("Ocorreu um erro ao alterar cartão!");
      })
      .finally(() => {
        setLoadingPayment(false);
        onSuccessGeneral && onSuccessGeneral();
      });
  };

  const handlePay = () => {
    if (changeCardAction) {
      changeCardService();
    } else if (purchaseType === "UNIQUE") {
      createUniquePayment();
    } else if (UserPlansTypes.includes(purchaseType)) {
      createPlanMainVelotax();
    }
  };

  const changePaymentId = (value: any) => {
    if (step === "CC_PAYMENT") {
      setStep(EPlanStep.LOADING);
      setLoading(true);
    }
    setData(value);
  };

  const getPurchaseType = () => {
    if (planCurrent?.type in ETypePurchase) return planCurrent?.type;

    return ETypePurchase.UNIQUE;
  };

  const onChangeInstallments = (value: any) => {
    setInstallment(value);
  };

  const cancelActions = (e: any) => {
    setStep(EPlanStep.CHOOSE_PAYMENT);
    clearInterval(getStatus);
    setData({ darf: { _id: "" }, payment: { paymentId: 0 } });

    if (onCancel) {
      onCancel(e);
    }
  };

  const getCardList = () => {
    setLoadingCards(true);
    apiPayment
      .get(`/payment/list_cards`)
      .then((ret) => {
        setCardList(ret?.data?.list || []);
      })
      .catch((err) => {
        message.error("Ocorreu um erro ao buscar cartões");
      })
      .finally(() => {
        setLoadingCards(false);
      });
  };

  const resumoPopoverContent = (
    <>
      {resumo?.map((darf, i) => {
        const impostoTotal = Math.max(
          0,
          Number(
            (darf?.impostoComumFinal || 0) +
              (darf?.impostoDTFinal || 0) +
              (darf?.impostoFIIFinal || 0)
          ) +
            (Number(darf["impostoAcumulado"] || 0) -
              Number(darf["irrfTotal"] || 0))
        );
        if (Number(impostoTotal) >= 10)
          return (
            <PopoverItem key={i}>
              <PopoverLabel>{months[darf?.month - 1]}</PopoverLabel>
              <PopoverItemValue>
                {formatCurrency(
                  impostoTotal + (darf?.multaAux || 0) + (darf?.jurosAux || 0)
                )}
              </PopoverItemValue>
            </PopoverItem>
          );
        else return <></>;
      })}
    </>
  );

  const plansContentDisplay: Record<EPlanStep, PaymentInfoContentProps> = {
    [EPlanStep.CHOOSE_PAYMENT]: {
      content: (
        <Spin spinning={emitting}>
          <Row gutter={[16, 8]}>
            {(disablePixOption || disableCreditCardOption) && (
              <Col span={24}>
                <Typography.Paragraph>
                  <InfoCircleOutlined />
                  &nbsp;&nbsp;Não aceitamos o pagamento de DARFs com valor acima
                  de <strong>{formatCurrency(maxPixValue || 0)}</strong> através
                  de PIX ou cartão de crédito. Faça o download do seu DARF e
                  realize o pagamento diretamente no aplicativo do seu banco.{" "}
                  {/* <Popover content={resumoPopoverContent} title="DARFs" trigger="hover" placement="bottomRight">
                    <EyeOutlined style={{ margin: '0 0 0 0.5rem' }} />
                  </Popover> */}
                </Typography.Paragraph>
              </Col>
            )}
            {!disableCreditCardOption && !pagarmeOff && (
              <>
                {!isIOSAPP && loadingCards ? (
                  <Col span={24} style={{ marginBottom: "16px" }}>
                    <Row justify="center" align="middle">
                      <Col>
                        <LoadingOutlined />
                        &nbsp;&nbsp;&nbsp;Carregando seus cartões
                      </Col>
                    </Row>
                  </Col>
                ) : !isIOSAPP ? (
                  <>
                    {isBatchDarf && (
                      <Col span={24}>
                        <div
                          style={{
                            padding: "0 0 1rem 0.5rem",
                          }}
                        >
                          <Typography.Text>
                            Saldo total a pagar:
                            <Typography.Text strong>
                              {" "}
                              {formatCurrency(paymentData.impostoTotalFinal)}
                            </Typography.Text>
                          </Typography.Text>
                          <Popover
                            content={resumoPopoverContent}
                            title="DARFs"
                            trigger="hover"
                            placement="bottomRight"
                          >
                            <EyeOutlined style={{ margin: "0 0 0 0.5rem" }} />
                          </Popover>
                        </div>
                      </Col>
                    )}
                    {cardList
                      .filter(
                        (card) => !selectedCard || selectedCard.id === card.id
                      )
                      .map((card) => {
                        if (
                          blockListCard?.length &&
                          blockListCard.includes(card.id)
                        )
                          return undefined;

                        return (
                          <Col span={24} key={card.id}>
                            <CardButton
                              block
                              size="large"
                              type="default"
                              onClick={() => setSelectedCard(card)}
                              style={{
                                cursor:
                                  selectedCard?.id === card.id
                                    ? "default"
                                    : "pointer",
                              }}
                            >
                              <Row
                                style={{ width: "100%" }}
                                align="middle"
                                justify="space-between"
                              >
                                <Col>
                                  <Cards
                                    preview
                                    cvc="***"
                                    name=""
                                    expiry=""
                                    number={
                                      card.first_six_digits +
                                      "** *****" +
                                      card.last_four_digits
                                    }
                                  />
                                </Col>{" "}
                                <Col>
                                  {"**** **** **** " + card.last_four_digits}
                                </Col>
                                <Col span={24}>
                                  {selectedCard && !isPlan && (
                                    <Form.Item>
                                      <Select
                                        size="large"
                                        listHeight={400}
                                        options={installments}
                                        style={{
                                          minWidth: "150px",
                                          fontSize: 16,
                                        }}
                                        value={
                                          fixedInstallments || planCurrent?.type
                                            ? installments[0].value
                                            : installment
                                        }
                                        placeholder="Selecione a(s) parcela(s)"
                                        onChange={onChangeInstallments}
                                        getPopupContainer={(target) =>
                                          target.parentElement
                                        }
                                      />
                                    </Form.Item>
                                  )}
                                </Col>
                              </Row>
                            </CardButton>
                          </Col>
                        );
                      })}
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
            {!isIOSAPP && selectedCard ? (
              <>
                <ReCaptcha
                  token={reCaptchaToken}
                  setToken={setReCaptchaToken}
                />
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 25,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "97%",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      size="large"
                      type="default"
                      onClick={() => setSelectedCard(undefined)}
                    >
                      Voltar
                    </Button>

                    <Button
                      size="large"
                      type="primary"
                      onClick={handlePay}
                      disabled={loadingPayment}
                      loading={loadingPayment}
                      style={{ textAlign: "center" }}
                    >
                      {changeCardAction ? "Selecionar" : "Finalizar pagamento"}
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <Col span={24}>
                  <Button
                    block
                    size="large"
                    type="primary"
                    disabled={disableCreditCardOption || pagarmeOff}
                    onClick={() => {
                      if (!isIOSAPP) {
                        setStep(EPlanStep.CC_PAYMENT);
                      } else {
                        navigateToBrowser();
                      }
                    }}
                  >
                    {cardList.length > 0 ? "NOVO " : ""}CARTÃO DE CRÉDITO
                  </Button>
                </Col>
                {!hidePixButton && (
                  <Col span={24}>
                    <Button
                      block
                      size="large"
                      type="primary"
                      disabled={disablePixOption}
                      onClick={() => {
                        if (isIOSAPP) {
                          navigateToBrowser();
                        } else if (!paymentData?.impostoTotalFinal) {
                          HandleTag("37");
                          setStep(EPlanStep.PIX_FORM);
                        } else {
                          setStep(EPlanStep.PIX_PAYMENT);
                        }
                      }}
                    >
                      PIX À VISTA
                    </Button>
                  </Col>
                )}

                {!hideDarfDownload ? (
                  <Col span={24}>
                    <Button
                      block
                      size="large"
                      type="primary"
                      onClick={() => callDarf(true)}
                    >
                      BOLETO DARF
                    </Button>
                  </Col>
                ) : null}
              </>
            )}

            {!selectedCard ? (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 25,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "97%",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {purchaseType !== "UNIQUE" && (
                    <Button
                      size="large"
                      type="default"
                      onClick={(e) => {
                        onCancel && onCancel(e);
                        showUserPlanModal(true);
                      }}
                    >
                      Voltar
                    </Button>
                  )}
                </div>
              </div>
            ) : undefined}
          </Row>
        </Spin>
      ),
      title: selectedCard
        ? "Cartão de crédito"
        : changeCardAction
        ? "Trocar Cartão"
        : "Escolha sua forma de pagamento",
    },
    [EPlanStep.PIX_PAYMENT]: {
      content: (
        <PixPaymentStep
          paymentData={paymentData}
          addInfo={{ hasDiscount, pixClientInfo }}
          handleIdBack={changePaymentId}
          onSucess={onSucessPix}
          isDarfBatch={isBatchDarf || false}
          resumo={resumo || []}
          backStep={() => {
            setStep(EPlanStep.CHOOSE_PAYMENT);
            if (paymentData.stopIntervalPix) paymentData.stopIntervalPix();
          }}
          cupom={cupom}
          closeModal={cancelActions}
          reCaptchaToken={reCaptchaToken}
          setReCaptchaToken={setReCaptchaToken}
        />
      ),
      title: "Pagamento com PIX à vista",
    },
    [EPlanStep.CC_PAYMENT]: {
      content: (
        <CreditCardStep
          initialized={true}
          closeMainModal={() => closeModal && closeModal()}
          changeCardAction={changeCardAction}
          typePurchase={getPurchaseType()}
          handleClosableModal={() => {}}
          status=""
          plan={planCurrent}
          addInfo={{ hasDiscount }}
          handleSuccess={onSucessCreditCard}
          setInitialized={() => {}}
          darfData={paymentData}
          handleIdBack={changePaymentId}
          customInstallments={customInstallments}
          backToRoot={() => setStep(EPlanStep.CHOOSE_PAYMENT)}
          cupom={cupom}
          reCaptchaToken={reCaptchaToken}
          setReCaptchaToken={setReCaptchaToken}
        />
      ),
      title: "Cartão de crédito",
    },
    [EPlanStep.PIX_FORM]: {
      title: "Pagamento com PIX à vista",
      content: (
        <Form
          size="large"
          form={pixForm}
          layout="vertical"
          onFinish={(data) => {
            setStep(EPlanStep.PIX_PAYMENT);
            setPixClientInfo(data);
          }}
          onValuesChange={(values) => {
            pixForm.setFieldsValue({
              ...value,
              phone:
                values.phone?.replace(/[()_-]/g, "")?.replace(" ", "") ?? "",
            });
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                name="name"
                label="Nome completo"
                rules={validationFieldRequired}
              >
                <Input maxLength={48} placeholder="Nome completo" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Button block size="large" type="primary" htmlType="submit">
                Prosseguir
              </Button>
            </Col>
            <Col span={24}>
              <Button
                block
                size="large"
                onClick={() => {
                  setStep(EPlanStep.CHOOSE_PAYMENT);
                }}
              >
                Voltar
              </Button>
            </Col>
            {paymentData?.isPlan && (
              <Col span={24}>
                <Typography.Paragraph
                  style={{ textAlign: "center", fontSize: 12 }}
                >
                  Ao prosseguir, você declara que aceita nosso{" "}
                  <a
                    href="https://velotax-termos-e-condicoes.s3.amazonaws.com/TERMO%2BDE%2BACEITE%2BE%2BCONTRATAC%CC%A7A%CC%83O_11.10.2022.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Termo de Contratação
                  </a>
                  .
                </Typography.Paragraph>
              </Col>
            )}
          </Row>
        </Form>
      ),
    },
    [EPlanStep.LOADING]: {
      content: (
        <Row>
          <Col
            span={24}
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "15px",
            }}
          >
            <Spin spinning={loading}></Spin>
            <span style={{ marginLeft: "10px" }}>Processando pagamento...</span>
          </Col>
        </Row>
      ),
    },
    [EPlanStep.FINISHED]: {
      content: (
        <Row>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <MdCheck color={"var(--ant-success-color)"} size={120} />
          </Col>
          <span
            style={{
              marginTop: "10px",
              fontSize: "16px",
              textAlign: "center",
              marginBottom: "35px",
              fontWeight: "500",
              width: "100%",
            }}
          >
            Seu pagamento foi realizado com sucesso!
          </span>
        </Row>
      ),
      title: "Confirmação de Pagamento",
    },
    [EPlanStep.NO_VALUE]: {
      content: (
        <Row>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <MdWarningAmber color={"var(--ant-warning-color)"} size={120} />
          </Col>
          <span
            style={{
              marginTop: "10px",
              fontSize: "16px",
              textAlign: "center",
              marginBottom: "35px",
              fontWeight: "500",
              width: "100%",
            }}
          >
            Não há valor de imposto de renda a ser pago para o ano de{" "}
            {yearBatch}
          </span>
        </Row>
      ),
      title: "Operação inválida",
    },
  };

  const title = plansContentDisplay?.[step]?.title;
  const content = plansContentDisplay?.[step]?.content;

  return (
    <DrawerModal
      title={title}
      visible={show}
      footer={null}
      width="900px"
      className="min-siz-title"
      height={"100%"}
      maskClosable={false}
      keyboard={false}
      onCancel={(e) => cancelActions(e)}
    >
      {content}
    </DrawerModal>
  );
};
