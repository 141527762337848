import { message } from "antd";
import {
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import api from "../services/apiBolsa";
import { useAuth } from "./AuthContext";

interface INotification {
  message: string;
  type: string;
  date: Date;
  viewed: boolean;
  viewedDate?: Date;
  meta: any;
}

interface INotificationContext {
  notification: INotification[];
  setNotification: React.Dispatch<SetStateAction<INotification[]>>;
  loading: boolean;
  getNotification: () => void;
}

export const NotificationContext = createContext<INotificationContext>(
  {} as INotificationContext
);

export const NotificationProvider: React.FC = ({ children }) => {
  const [notification, setNotification] = useState<INotification[]>([]);
  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState(false);

  const { user } = useAuth();

  const getNotification = useCallback(() => {
    if (user?.token) {
      setLoading(true);
      api.defaults.headers.common["Authorization"] = `Bearer ${user?.token}`;
      api
        .get(`/wallet/notification`)
        .then((res) => {
          setFetched(true);
          setNotification(res.data);
        })
        .catch(() => message.error("Não foi possível buscar notificações"))
        .finally(() => setLoading(false));
    }
  }, [user?.token]);

  useEffect(() => {
    if (!fetched) {
      getNotification();
    }
  }, [fetched, getNotification]);

  return (
    <NotificationContext.Provider
      value={{ notification, setNotification, loading, getNotification }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);
