import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_IRPF_URL || "http://localhost:3333",
  headers: {
    "x-api-provider": "VELOTAX",
  },
});

export default api;
