import { Layout } from "antd";
import styled from "styled-components";

export const LayoutHeader = styled(Layout.Header)`
  display: none;
  z-index: 1;
  height: 96px;
  padding: 0 32px;
  background: var(--velotax-background-color);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 102, 0.16);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  span {
    display: flex;
  }

  .ant-btn {
    &.login-button {
      padding: 4px 6px 4px 0;
      strong {
        color: var(--ant-primary-color);
      }
    }
    &.menu-button {
      z-index: 1;
      width: 48px;
      height: 48px;
      margin-right: -8px;
      svg {
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
        * {
          stroke: var(--ant-primary-color) !important;
        }
      }
    }
    &.back-button {
      width: 48px;
      height: 48px;
      margin-left: -16px;
      svg {
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
        * {
          fill: var(--ant-primary-color) !important;
        }
      }
    }
  }

  #span-title svg {
    width: 32px;
    height: 32px;
    margin-right: 16px;
  }

  #span-title img {
    height: 32px;
    margin-right: 16px;
  }

  @media only screen and (max-device-width: 812px) {
    height: 64px;
    display: flex;
    padding: 0 24px;
    column-gap: 8px;
    justify-content: space-between;

    .ant-image {
      margin: 0;
      img {
        margin-bottom: 4px;
        height: 30px !important;
      }
    }

    .user-button {
      svg {
        fill: var(--ant-primary-color) !important;
      }
    }
  }
`;

export const ContainerIcon = styled.div`
  display: flex;
  align-items: center;
  background: var(--white);
  svg {
    width: 32px;
    height: 32px;
    margin-right: 16px;
  }
`;

export const User = styled.div`
  height: 64px;
  display: flex;
  column-gap: 2px;
  cursor: pointer;
  position: relative;
  align-items: center;
  transition: opacity 0.3s;
  article.ant-typography {
    margin-left: 6px;
    display: flex;
    flex-direction: column;
    row-gap: 0px;
    font-size: 12px;
    line-height: 16px;
    strong {
      text-transform: capitalize;
    }
  }
  &.hover {
    nav {
      opacity: 1;
      visibility: visible;
    }
  }

  nav {
    opacity: 0;
    z-index: 1000;
    visibility: hidden;
    right: 0;
    top: 48px;
    padding-top: 8px;
    position: absolute;
    transition: opacity var(--transition-duration-default)
      var(--transition-timing-function-default);

    ul {
      margin: 0;
      display: flex;
      flex-direction: column;
      border-radius: 8px 8px 0 0;
      border-bottom: 4px solid var(--ant-primary-color);
      background-color: var(--velotax-background-color);
      box-shadow: 0px 8px 24px rgba(0, 0, 88, 0.16);

      > li {
        display: flex;
        column-gap: 8px;
        align-items: center;
        white-space: nowrap;
        height: 56px;
        font-weight: 600;
        border-radius: 4px;
        padding: 6px 32px 6px 16px;
        transition: color var(--transition-duration-default)
          var(--transition-timing-function-default);
        transition: background-color var(--transition-duration-default)
          var(--transition-timing-function-default);

        border-bottom: 1px solid var(--velotax-background-color-ghost);

        :last-of-type {
          border-bottom: none;
        }

        :hover {
          color: var(--ant-primary-color);
          background-color: rgba(22, 52, 255, 0.06);
        }

        &.logout {
          :hover {
            color: var(--ant-error-color);
          }
        }

        svg {
          cursor: pointer;
          width: 1.25rem;
          height: 1.25rem;
          margin-right: 2px;

          :not(.stroke-color) path {
            transition: fill var(--transition-duration-default)
              var(--transition-timing-function-default);
          }

          &.stroke-color path {
            stroke-width: 1.6px;
            transition: stroke var(--transition-duration-default)
              var(--transition-timing-function-default);
          }
        }
      }
    }
  }
`;
