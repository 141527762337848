import { Typography, Spin, Table } from "antd";
import { AiOutlineDownload } from "react-icons/ai";
import Button from "../Button";
import { Container } from "./styles";
import { useCallback, useEffect, useState } from "react";
import { formatCurrency, formatDate } from "../../utils";
import { handleDownloadSheet } from "../../utils/handleDownloadSheet";

export const DividendosModalExterior = ({ item, year, quotes }: any) => {
  const [total, setTotal] = useState<any>({});
  const [object, setObject] = useState<any>({});
  const [loading, setLoading] = useState<any>(false);

  const checkType = (el: any) => {
    if (el.type === "DIVIDEND" && el.operation === "BUY") {
      return "valorRendimento";
    } else return "impostoPagoExterior";
  };

  const getTotal = useCallback(async () => {
    let auxTotal: any = {
      totalRendimento: 0,
      totalRendimentoReal: 0,
      totalImposto: 0,
      totalImpostoReal: 0,
    };
    Object.keys(object).forEach((item: any) => {
      auxTotal = {
        ...auxTotal,
        totalRendimento:
          (auxTotal.totalRendimento ?? 0) + (object[item].valorRendimento ?? 0),
        totalRendimentoReal:
          (auxTotal.totalRendimentoReal ?? 0) +
          (object[item].valorRendimentoReal ?? 0),
        totalImposto:
          (auxTotal.totalImposto ?? 0) +
          (object[item].impostoPagoExterior ?? 0),
        totalImpostoReal:
          (auxTotal.totalImpostoReal ?? 0) +
          (object[item].impostoPagoExteriorReal ?? 0),
      };
    });

    setTotal(auxTotal);
  }, [object]);

  const groupBycode = useCallback(async () => {
    setLoading(true);
    const aux: any = {};
    for (const el of item.orders ?? []) {
      if (!aux[el.code]) {
        if (checkType(el) === "valorRendimento") {
          aux[el.code] = {
            valorRendimento: el.value,
            valorRendimentoReal: el.value * item.dolarQuote,
            date: formatDate(el.date),
          };
        } else {
          aux[el.code] = {
            impostoPagoExterior: el.value,
            impostoPagoExteriorReal: el.value * item.dolarQuote,
            date: formatDate(el.date),
          };
        }
      } else {
        if (checkType(el) === "valorRendimento") {
          aux[el.code] = {
            ...aux[el.code],
            valorRendimento: (aux[el.code]?.valorRendimento ?? 0) + el.value,
            valorRendimentoReal:
              (aux[el.code]?.valorRendimentoReal ?? 0) +
              el.value * item.dolarQuote,
          };
        } else {
          aux[el.code] = {
            ...aux[el.code],
            impostoPagoExterior:
              (aux[el.code]?.impostoPagoExterior ?? 0) + el.value,
            impostoPagoExteriorReal:
              (aux[el.code]?.impostoPagoExteriorReal ?? 0) +
              el.value * item.dolarQuote,
          };
        }
      }
    }

    setObject(aux);
    setLoading(false);
  }, [item.orders, item.dolarQuote]);

  useEffect(() => {
    groupBycode();
  }, [groupBycode, item.month, year]);

  useEffect(() => {
    getTotal();
  }, [getTotal, object]);

  return (
    <Container>
      {loading ? (
        <Spin style={{ display: "flex", justifyContent: "center" }} />
      ) : (
        <>
          {Object.keys(object)?.length > 0 && (
            <Button
              type="text"
              icon={<AiOutlineDownload />}
              onClick={() => {
                const serializedData: any[] = [];
                Object.keys(object)
                  .map((key) => ({
                    ...object[key],
                    code: key,
                  }))
                  .forEach((item: any) => {
                    serializedData.push({
                      Ativo: item?.code,
                      Data: item?.date,
                      "Valor em USD": Number(
                        item?.valorRendimento
                      ).toLocaleString("en", {
                        style: "currency",
                        currency: "USD",
                      }),
                      "Valor em BRL": Number(
                        item?.valorRendimentoReal
                      ).toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }),
                    });
                  });
                handleDownloadSheet(
                  serializedData,
                  "Dividendos",
                  `Dividendos-${year}-${item.month}.xlsx`
                );
              }}
            >
              Baixar planilha
            </Button>
          )}
          <Table
            pagination={false}
            dataSource={Object.keys(object).map((key) => ({
              ...object[key],
              ticker: key,
            }))}
            locale={{ emptyText: "Nenhum dividendo recebido neste mês" }}
            columns={[
              {
                align: "left",
                title: "Ativo",
                dataIndex: "ticker",
                render: (value) => (
                  <strong style={{ fontWeight: "500" }}>{value}</strong>
                ),
              },
              {
                align: "left",
                title: "Data",
                dataIndex: "date",
              },
              {
                width: "30%",
                align: "left",
                dataIndex: "valorRendimento",
                title: "Valor em USD",
                render: (value) => formatCurrency(value ?? 0, "US$ "),
              },
              {
                width: "30%",
                align: "left",
                dataIndex: "valorRendimentoReal",
                title: "Valor em BRL",
                render: (value) => formatCurrency(value ?? 0, "R$ "),
              },
            ]}
          />
          <div className="mobile-list exterior">
            {Object.keys(object)
              .map((key) => ({
                ...object[key],
                ticker: key,
              }))
              ?.map((op: any, index: number) => (
                <div className="list-item" key={index}>
                  <p>{op.ticker}</p>
                  <span className="list-item-label">Data:</span>
                  <span className="list-item-value">{op.date}</span>
                  <span className="list-item-label">Valor em USD:</span>
                  <span className="list-item-value">
                    {formatCurrency(op.valorRendimento ?? 0, "US$ ")}
                  </span>
                  <span className="list-item-label">Valor em BRL:</span>
                  <span className="list-item-value">
                    {formatCurrency(op.valorRendimentoReal ?? 0, "R$ ")}
                  </span>
                </div>
              ))}
            {!Object.keys(object).length && (
              <div className="list-empty">
                Nenhum dividendo recebido neste mês
              </div>
            )}
          </div>
          {Object.keys(object).length > 0 && (
            <div className="table-total exterior">
              <Typography.Title level={3}>
                Total recebido no mês:
              </Typography.Title>
              <Typography.Text>
                {formatCurrency(total.totalRendimentoReal ?? 0)}
              </Typography.Text>
            </div>
          )}
        </>
      )}
    </Container>
  );
};
