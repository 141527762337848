import clsx from "clsx";
import moment from "moment";
import { GrUpdate } from "react-icons/gr";
import { AiOutlineLock } from "react-icons/ai";
import { BiBell, BiCheck } from "react-icons/bi";
import { BsEye, BsEyeSlash, BsDownload } from "react-icons/bs";
import { Fragment, useEffect, useMemo, useState } from "react";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  Badge,
  Checkbox,
  Col,
  Collapse,
  Row,
  Select,
  Tabs,
  Tooltip,
  Typography,
  message,
} from "antd";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
} from "recharts";
import api from "../../services/api";
import Button from "../../components/Button";
import apiBolsa from "../../services/apiBolsa";
import HandleTag from "../../services/handleTag";
import { Avisos } from "../../components/Avisos";
import apiExterior from "../../services/apiExterior";
import { useAuth } from "../../contexts/AuthContext";
import { Proventos } from "../../components/Proventos";
import { useBroker } from "../../contexts/BrokerContext";
import { DrawerModal } from "../../components/DrawerModal";
import { getProviderColor } from "../../utils/walletCharts";
import { BlurContent, Container, IsentCardStyled } from "./styles";
import { useNotification } from "../../contexts/NotificationContext";
import { Posicao, useCarteira } from "../../contexts/CarteiraContext";
import { vendasIsentas, vendasTotaisExt } from "../HistoricBolsa/utils";
import { NotificationDrawer } from "../../components/NotificationDrawer";
import { ReportTypeDrawer } from "../../components/ReportType/ReportType";
import { ReactComponent as FiltroIcon } from "../../assets/icons/filtro.svg";
import { serializePatrimonio, getNomeCorretora } from "../../utils/patrimonio";
import {
  ExpandIcon,
  HidenValue,
  LucroPrejuizo,
  PosicaoCard,
} from "./PosicaoCard";
import {
  download,
  downloadPDF,
  formatCurrency,
  isMobile,
  monthsExtended,
  numberToCurrency,
} from "../../utils";

type ChartType = "classe" | "posicoes" | "corretora";

interface CarteiraProps {}

export const Carteira: React.FC<CarteiraProps> = () => {
  const { notification } = useNotification();
  const { integration, hasExteriorIntegration } = useBroker();
  const {
    user,
    hasPlan,
    hasExpired,
    hasDismissed,
    setDirectPayment,
    showUserPlanModal,
  } = useAuth();
  const isBasic = user.user?.userPlanInfoVelotax?.type?.includes('BASIC');
  const { carteira, lastUpdate, loading, getCarteira, getPatrimonios } =
    useCarteira();
  const [helpModal, setHelpModal] = useState<any>();
  const [hideValues, setHideValues] = useState(false);
  const [ativosChart, setAtivosChart] = useState<any>();
  const [vendasBolsa, setVendasBolsa] = useState<any>(0);
  const [expiredModal, setExpiredModal] = useState(false);
  const [, setDetailedActiveKeys] = useState<string[]>([]);
  const [loadingReport, setLoadingReport] = useState(false);
  const [corretoras, setCorretoras] = useState<string[]>([]);
  const [activeKeys, setActiveKeys] = useState<string[]>([]);
  const [vendasExterior, setVendasExterior] = useState<any>(0);
  const [chartType, setChartType] = useState<ChartType>("classe");
  const [reportDrawerWallet, setReportDrawerWallet] = useState(false);
  const [classesAtivosChart, setClassesAtivosChart] = useState<any>();
  const [detailedPositions, setDetailedPositions] = useState<any[]>([]);
  const [vendasRestantesBolsa, setVendasRestantesBolsa] = useState<any>(0);
  const [corretorasAtivosChart, setCorretorasAtivosChart] = useState<any>();
  const [checkboxCorretoras, setCheckboxCorretoras] = useState<string[]>([]);
  const [selectedCorretoras, setSelectedCorretoras] = useState<string[]>([]);
  const [notificationDrawerOpen, setNotificationDrawerOpen] = useState(false);
  const [filterCorretoraAtivo, setFilterCorretoraAtivo] = useState<string[]>(
    []
  );
  const [vendasRestantesExterior, setVendasRestantesExterior] =
    useState<any>(0);
  const [showCorretoraFilterModal, setShowCorretoraFilterModal] =
    useState(false);
  const [detailedLoadingIndex, setDetailedLoadingIndex] = useState<
    number | undefined
  >(undefined);

  const onCloseHelpModal = () => setHelpModal(undefined);

  const checkAll = corretoras.length === checkboxCorretoras.length;
  const indeterminate =
    checkboxCorretoras.length > 0 &&
    checkboxCorretoras.length < corretoras.length;

  const largeChart =
    [
      ...(chartType === "classe"
        ? classesAtivosChart || []
        : chartType === "corretora"
        ? corretorasAtivosChart || []
        : ativosChart || []),
    ].length > 7;

  const carteiraCategorizada = useMemo(
    () =>
      carteira.posicoes?.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.categoria]: [...(acc[cur.categoria] || []), cur],
        }),
        {} as { [key: string]: Posicao[] }
      ) || {},
    [carteira.posicoes]
  );

  const patrimonioTotalFiltrado = useMemo(
    () =>
      carteira?.posicoes?.reduce(
        (acc, cur) =>
          filterCorretoraAtivo.includes(cur.corretora)
            ? acc + cur.posicao
            : acc,
        0
      ) || 0,
    [carteira?.posicoes, filterCorretoraAtivo]
  );

  const retornoFiltrado = useMemo(
    () =>
      carteira?.posicoes?.reduce(
        (acc, cur) =>
          filterCorretoraAtivo.includes(cur.corretora)
            ? {
                lucroPrejuizoDiario:
                  acc.lucroPrejuizoDiario + cur.lucroPrejuizoDiario,
                lucroPrejuizoDiarioPercentualNumerator:
                  acc.lucroPrejuizoDiarioPercentualNumerator +
                  (cur.categoria === "TesouroDireto"
                    ? 0
                    : cur.resumo?.lucroPrejuizoDiarioPercentualNumerator),
                lucroPrejuizoDiarioPercentualDenominator:
                  acc.lucroPrejuizoDiarioPercentualDenominator +
                  (cur.categoria === "TesouroDireto"
                    ? 0
                    : cur.resumo?.lucroPrejuizoDiarioPercentualDenominator),
                lucroPrejuizoTotal:
                  acc.lucroPrejuizoTotal + cur.lucroPrejuizoTotal,
                lucroPrejuizoTotalPercentualNumerator:
                  acc.lucroPrejuizoTotalPercentualNumerator +
                  cur.resumo?.lucroPrejuizoTotalPercentualNumerator,
                lucroPrejuizoTotalPercentualDenominator:
                  acc.lucroPrejuizoTotalPercentualDenominator +
                  cur.resumo?.lucroPrejuizoTotalPercentualDenominator,
              }
            : acc,
        {
          lucroPrejuizoDiario: 0,
          lucroPrejuizoDiarioPercentualNumerator: 0,
          lucroPrejuizoDiarioPercentualDenominator: 0,
          lucroPrejuizoTotal: 0,
          lucroPrejuizoTotalPercentualNumerator: 0,
          lucroPrejuizoTotalPercentualDenominator: 0,
        }
      ) || {
        lucroPrejuizoDiario: 0,
        lucroPrejuizoDiarioPercentualNumerator: 0,
        lucroPrejuizoDiarioPercentualDenominator: 0,
        lucroPrejuizoTotal: 0,
        lucroPrejuizoTotalPercentualNumerator: 0,
        lucroPrejuizoTotalPercentualDenominator: 0,
      },
    [carteira?.posicoes, filterCorretoraAtivo]
  );

  const onChangeActiveKeys = (keys: string | string[]) => {
    const isOpenEvent = keys.length > activeKeys.length;
    const lastOpened = keys[keys.length - 1];

    setActiveKeys(keys as string[]);
    let codigo =
      lastOpened?.replaceAll(" ", "").split("-")[3] ||
      lastOpened?.replaceAll(" ", "").split("-")[2];
    let market =
      lastOpened?.replaceAll(" ", "").split("-")[0] ||
      lastOpened?.replaceAll(" ", "").split("-")[1];
    let index = lastOpened?.replaceAll(" ", "").split("-")[1];
    if (
      lastOpened.includes("Imobiliário") ||
      lastOpened.includes("Traded") ||
      lastOpened.includes("Brazilian")
    ) {
      codigo = lastOpened?.replaceAll(" ", "").replace("-", "").split("-")[2];
      index = lastOpened?.replaceAll(" ", "").replace("-", "").split("-")[1];
    }

    if (lastOpened && isOpenEvent) {
      setDetailedActiveKeys((detailedActiveKeys) => {
        if (
          !detailedActiveKeys.includes(lastOpened) &&
          keys.length > detailedActiveKeys.length
        ) {
          setDetailedLoadingIndex(Number(index));
          apiBolsa
            .get(`/wallet/detalied/${codigo}`, {
              params: {
                exterior:
                  market.toLowerCase() === "exterior" ? true : undefined,
              },
            })
            .then(({ data }: any) => {
              setDetailedPositions((positions) => [
                ...positions,
                {
                  index,
                  codigo: codigo,
                  details: data,
                },
              ]);
              return [...detailedActiveKeys, lastOpened];
            })
            .catch(() => {})
            .finally(() => {
              setDetailedLoadingIndex(undefined);
            });
        }
        return detailedActiveKeys;
      });
    }
  };

  useEffect(() => {
    getCarteira();
  }, [getCarteira]);

  useEffect(() => {
    apiBolsa
      .get("/velotax/historic", {
        params: {
          month: new Date().getMonth() + 1,
        },
      })
      .then((res) => {
        const isentas = vendasIsentas(res?.data)
        setVendasBolsa(isentas);
        setVendasRestantesBolsa(20000 - isentas);
      });
  }, []);

  useEffect(() => {
    apiExterior
      .get("/velotax/exterior/historic", {
        params: {
          month: new Date().getMonth() + 1,
        },
      })
      .then((res) => {
        const isentas = vendasTotaisExt(res?.data)
        setVendasExterior(isentas);
        setVendasRestantesExterior(35000 - isentas);
      });
  }, []);

  useEffect(() => {
    if (hasExpired && !hasDismissed) {
      setTimeout(() => {
        setExpiredModal(true);
      }, 1500);
    }
  }, [hasExpired, hasDismissed]);

  // useEffect(() => {
  //   getLucroPrejuizo();
  // }, [getLucroPrejuizo]);

  useEffect(() => {
    const { classesAtivos, patrimonio, posicoes } =
      serializePatrimonio(carteira);
    setClassesAtivosChart(classesAtivos);
    setCorretorasAtivosChart(patrimonio);
    setAtivosChart(posicoes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    const { classesAtivos, patrimonio, posicoes, corretoras } =
      serializePatrimonio(carteira, selectedCorretoras);
    setClassesAtivosChart(classesAtivos);
    setCorretorasAtivosChart(patrimonio);
    setAtivosChart(posicoes);
    setCorretoras(corretoras);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, selectedCorretoras]);

  const renderLegend = (value: string, entry: any) => {
    return <span>{value}</span>;
  };

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    payload,
  }: any) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        fontSize={10}
        textAnchor="middle"
        dominantBaseline="central"
        style={{ margin: "10px" }}
      >
        {percent * 100 > 5 && `${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const downloadAction = async (type: string) => {
    try {
      setLoadingReport(true);
      if (type === "pdf") {
        const queryCorretoras =
          filterCorretoraAtivo?.length > 0
            ? `?corretoras=${JSON.stringify(filterCorretoraAtivo).replace(
                /\]|"|\[/g,
                ""
              )}`
            : "";
        const res = await apiBolsa.get(`/wallet/report${queryCorretoras}`);
        if (res.data.file) {
          downloadPDF(res.data.file, "Meus investimentos");
        } else if (res.data.fileUrl) {
          download(res.data.fileUrl);
        }
        message.success("O relatório foi enviado para o seu e-mail");
      } else if (type === "xlsx") {
        if (filterCorretoraAtivo?.length > 0) {
          const res = await apiBolsa.post("/wallet/report-xlsx", {
            name: user.user.name,
            email: user.user.email,
            username: user.user.cpf,
            corretoras: filterCorretoraAtivo,
          });
          download(res?.data?.fileUrl);
        } else {
          const data = await getPatrimonios(user?.user?.cpf);
          download(data.fileUrl);
        }
      }
    } catch (err: any) {
      message.error(
        `Não foi possível baixar o relatório de investimentos. Tente novamente mais tarde`
      );
    } finally {
      setLoadingReport(false);
    }
  };

  const renderIsentCard = (categoria: string) => {
    if (categoria === "Ações" || categoria === "Exterior") {
      const vendas = categoria === "Ações" ? vendasBolsa : vendasExterior;
      const restante =
        categoria === "Ações" ? vendasRestantesBolsa : vendasRestantesExterior;
      const limit = categoria === "Ações" ? 20000 : 35000;
      const tipVendas = vendas
        ? `R$ ${String(
            vendas.toLocaleString("pt-br", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          )}`
        : "R$ 0,00";
      const complete = vendas ? (vendas / limit) * 100 : 0;

      return (
        <IsentCardStyled>
          {restante <= 0 ? (
            <Typography.Text className="overviewTitleIsent">
              O seu limite de vendas isentas foi atingido neste mês
            </Typography.Text>
          ) : (
            <Typography.Text className="overviewTitleIsent">
              Você ainda possui{" "}
              <span style={{ fontWeight: "700" }}>
                {formatCurrency(restante)}{" "}
              </span>{" "}
              em vendas isentas em{" "}
              <span style={{ fontWeight: "700" }}>
                {monthsExtended[new Date().getMonth()]}{" "}
                {new Date().getFullYear()}
              </span>
            </Typography.Text>
          )}
          <div className="progress_container">
            <Tooltip
              title={`Vendas no mês: ${tipVendas}`}
              getPopupContainer={(target) => target.parentElement!}
            >
              <div
                className="loading_bar"
                style={{
                  width: `${complete >= 100 ? 100 : complete}%`,
                  borderBottomRightRadius: complete >= 100 ? "50px" : 0,
                  borderTopRightRadius: complete >= 100 ? "50px" : 0,
                }}
              >
                <div className="progress_container_text_align_center">
                  {vendas > 2500 ? formatCurrency(vendas) : ""}
                </div>
              </div>
            </Tooltip>
          </div>
        </IsentCardStyled>
      );
    }
  };

  return (
    <Container>
      <div className="velotax-container">
        <div className={clsx("velotax-content", { largeChart })}>
          <Typography.Title level={1} className="page-title">
            Meus investimentos
            <Button
              type="link"
              size="large"
              className="hide-values-btn"
              icon={hideValues ? <BsEyeSlash /> : <BsEye />}
              onClick={() => setHideValues((value) => !value)}
            />
            {hasPlan && hasPlan.premium && !isBasic && (
              <div className="notification-button">
                <Badge
                  size="small"
                  showZero={false}
                  offset={[-12, 48]}
                  count={notification?.filter((n) => !n.viewed)?.length || 0}
                >
                  <Button
                    loading={false}
                    icon={<BiBell />}
                    onClick={() => {
                      setNotificationDrawerOpen(true);
                    }}
                  />
                </Badge>
              </div>
            )}
          </Typography.Title>

          <Avisos />
          {!integration.integrated && !hasExteriorIntegration ? (
            <></>
          ) : (
            <Tabs className="tabs-wallet">
              <Tabs.TabPane
                tab="INVESTIMENTOS"
                key="item-1"
                className="tab-carteira"
              >
                {hasPlan && hasPlan.premium && !isBasic && (
                  <div className="container-btn">
                    <Button
                      type="text"
                      size="large"
                      icon={<FiltroIcon />}
                      className="download-report-btn filter-btn"
                      onClick={() => setShowCorretoraFilterModal(true)}
                    >
                      Filtrar
                    </Button>
                    <Tooltip
                      title="Baixar Relatório de investimentos"
                      getPopupContainer={(target) => target.parentElement!}
                    >
                      <Button
                        type="text"
                        size="large"
                        className="download-report-btn"
                        style={{ width: "32px", height: "32px" }}
                        onClick={() => setReportDrawerWallet(true)}
                        icon={
                          loadingReport ? <LoadingOutlined /> : <BsDownload />
                        }
                      />
                    </Tooltip>
                  </div>
                )}
                <Row align="middle" className="patrimonio">
                  <Col xs={24} sm={14}>
                    <Typography.Title level={2}>
                      Investimento total:
                    </Typography.Title>
                    <Typography.Title level={1}>
                      {loading ? (
                        <LoadingOutlined />
                      ) : !hideValues ? (
                        formatCurrency(
                          filterCorretoraAtivo.length > 0
                            ? patrimonioTotalFiltrado
                            : carteira.patrimonioTotal
                        )
                      ) : (
                        <HidenValue />
                      )}
                    </Typography.Title>
                  </Col>
                  <Col xs={24} sm={10} className="lucro-prejuizo">
                    <Row>
                      <Col xs={12} sm={24}>
                        <Typography.Paragraph>
                          Retorno diário:{" "}
                          {loading ? (
                            <LoadingOutlined />
                          ) : !hideValues ? (
                            <LucroPrejuizo
                              n={
                                filterCorretoraAtivo.length > 0
                                  ? retornoFiltrado.lucroPrejuizoDiario
                                  : carteira.lucroPrejuizoDiario
                              }
                              p={
                                filterCorretoraAtivo.length > 0
                                  ? retornoFiltrado.lucroPrejuizoDiarioPercentualNumerator /
                                    retornoFiltrado.lucroPrejuizoDiarioPercentualDenominator
                                  : carteira.lucroPrejuizoDiarioPercentual
                              }
                            />
                          ) : (
                            <HidenValue block strong />
                          )}
                        </Typography.Paragraph>
                      </Col>
                      <Col xs={12} sm={24}>
                        <Typography.Paragraph>
                          Retorno total:{" "}
                          {loading ? (
                            <LoadingOutlined />
                          ) : !hideValues ? (
                            <LucroPrejuizo
                              n={
                                filterCorretoraAtivo.length > 0
                                  ? retornoFiltrado.lucroPrejuizoTotal
                                  : carteira.lucroPrejuizoTotal
                              }
                              p={
                                filterCorretoraAtivo.length > 0
                                  ? retornoFiltrado.lucroPrejuizoTotalPercentualNumerator /
                                    retornoFiltrado.lucroPrejuizoTotalPercentualDenominator
                                  : carteira.lucroPrejuizoTotalPercentual
                              }
                            />
                          ) : (
                            <HidenValue block strong />
                          )}
                        </Typography.Paragraph>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {loading ? (
                  <Row justify="center">
                    <LoadingOutlined />
                  </Row>
                ) : carteira?.posicoes?.length > 0 ? (
                  <>
                    {!loading ? (
                      <ResponsiveContainer width="100%">
                        <>
                          <PieChart
                            {...(isMobile()
                              ? {
                                  width: 300,
                                  height: 260,
                                }
                              : {
                                  width: 240,
                                  height: 240,
                                })}
                          >
                            <Legend
                              align="right"
                              layout="vertical"
                              iconType="square"
                              verticalAlign="middle"
                              formatter={renderLegend}
                            />
                            <Pie
                              cx={isMobile() ? 100 : 115}
                              cy="50%"
                              dataKey="value"
                              fill="#1634FF"
                              innerRadius={isMobile() ? 40 : 50}
                              outerRadius={isMobile() ? 100 : 120}
                              labelLine={false}
                              legendType="circle"
                              label={renderCustomizedLabel}
                              data={
                                chartType === "classe"
                                  ? classesAtivosChart
                                  : chartType === "posicoes"
                                  ? ativosChart
                                  : corretorasAtivosChart
                              }
                            >
                              {(chartType === "classe"
                                ? classesAtivosChart
                                : chartType === "posicoes"
                                ? ativosChart
                                : corretorasAtivosChart
                              )?.map((entry: any, index: number) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={getProviderColor(entry.name, index)}
                                />
                              ))}
                            </Pie>
                            <RechartsTooltip
                              formatter={(value, _, { payload }) => {
                                return `${numberToCurrency(
                                  Number(value)
                                )} (${String(payload.percentage).replace(
                                  ".",
                                  ","
                                )}%)`;
                              }}
                            />
                          </PieChart>
                          <div className="select-container">
                            <Select
                              value={chartType}
                              onChange={(value) => setChartType(value)}
                              getPopupContainer={(target) =>
                                target.parentElement!
                              }
                            >
                              <Select.Option value="classe">
                                Por classe de ativo
                              </Select.Option>
                              <Select.Option value="corretora">
                                Por corretora
                              </Select.Option>
                              <Select.Option value="posicoes">
                                Por ativo
                              </Select.Option>
                            </Select>
                          </div>
                        </>
                      </ResponsiveContainer>
                    ) : undefined}
                    {!hasPlan || !hasPlan.premium || isBasic ? (
                      <>
                        <Collapse
                          expandIconPosition="right"
                          expandIcon={({ isActive }) =>
                            ExpandIcon({ isActive })
                          }
                        >
                          {carteira.posicoes
                            .slice(0, 1)
                            .map((posicao, index) => (
                              <Fragment key={index}>
                                {PosicaoCard({
                                  key: index.toString(),
                                  posicao,
                                  hideValues,
                                  setHelpModal,
                                })}
                              </Fragment>
                            ))}
                        </Collapse>
                        <BlurContent>
                          <Collapse
                            collapsible="disabled"
                            className="blur-content"
                            expandIconPosition="right"
                            expandIcon={({ isActive }) =>
                              ExpandIcon({ isActive })
                            }
                          >
                            {Array.from(Array(3).keys()).map((index) => (
                              <Fragment key={index}>
                                {PosicaoCard({
                                  key: index.toString(),
                                  hideValues,
                                  setHelpModal,
                                  posicao: {
                                    lucroPrejuizoDiario: 0.01,
                                  },
                                })}
                              </Fragment>
                            ))}
                          </Collapse>
                          <div className="blur-content-title">
                            Contrate o plano premium para ver a lista completa
                            de ativos
                            <Button
                              type="primary"
                              icon={<AiOutlineLock size={18} />}
                              onClick={() => {
                                showUserPlanModal(true);
                                HandleTag("54");
                              }}
                            >
                              PREMIUM
                            </Button>
                          </div>
                        </BlurContent>
                      </>
                    ) : (
                      <Collapse
                        expandIconPosition="right"
                        defaultActiveKey={Object.keys(carteiraCategorizada)}
                        expandIcon={({ isActive }) => ExpandIcon({ isActive })}
                      >
                        {(filterCorretoraAtivo.length > 0
                          ? Object.keys(carteiraCategorizada).filter(
                              (categoria) =>
                                carteiraCategorizada[categoria].filter(
                                  (item: any) =>
                                    filterCorretoraAtivo.includes(
                                      item?.corretora
                                    )
                                ).length > 0
                            )
                          : Object.keys(carteiraCategorizada)
                        ).map((categoria) => (
                          <Collapse.Panel
                            key={categoria}
                            className="ativos-categoria"
                            header={
                              <>
                                <Typography.Title level={2}>
                                  {categoria === "TesouroDireto"
                                    ? "Tesouro Direto"
                                    : categoria}
                                </Typography.Title>
                                <span className="collapse-title-divider" />
                                <span className="collapse-title-hidden" />
                              </>
                            }
                          >
                            <Collapse
                              activeKey={activeKeys}
                              expandIconPosition="right"
                              onChange={onChangeActiveKeys}
                              expandIcon={({ isActive }) =>
                                ExpandIcon({ isActive })
                              }
                            >
                              {(categoria !== "Exterior") && renderIsentCard(categoria)}
                              {(filterCorretoraAtivo.length > 0
                                ? carteiraCategorizada[categoria].filter(
                                    (item: any) =>
                                      filterCorretoraAtivo.includes(
                                        item?.corretora
                                      )
                                  )
                                : carteiraCategorizada[categoria]
                              )
                                .sort(
                                  (a, b) =>
                                    ((b.categoria === "TesouroDireto"
                                      ? b.precoAtual
                                      : b.posicao) || 0) -
                                    ((a.categoria === "TesouroDireto"
                                      ? a.precoAtual
                                      : a.posicao) || 0)
                                )
                                .map((posicao, index) => (
                                  <Fragment key={`${categoria}-${index}`}>
                                    {PosicaoCard({
                                      key: `${categoria}-${index}-${posicao.codigo}`,
                                      posicao,
                                      hideValues,
                                      setHelpModal,
                                      loading: detailedLoadingIndex === index,
                                      details: detailedPositions?.find(
                                        (el: any) =>
                                          el.codigo === posicao.codigo &&
                                          Number(el.index) === index
                                      )?.details,
                                    })}
                                  </Fragment>
                                ))}
                            </Collapse>
                          </Collapse.Panel>
                        ))}
                      </Collapse>
                    )}
                  </>
                ) : (
                  <Typography
                    className="list-empty"
                    style={{ textAlign: "center" }}
                  >
                    Nenhuma posição encontrada
                  </Typography>
                )}
                <Typography.Text className="last-update">
                  <Button
                    type="link"
                    size="small"
                    loading={loading}
                    icon={<GrUpdate />}
                    onClick={() => getCarteira(true)}
                    className="button-refresh-wallet"
                  />
                  Atualizado em{" "}
                  {moment(lastUpdate)
                    .utc()
                    .local()
                    .format("DD/MM/YYYY à[s] HH:mm")}
                </Typography.Text>
                {hasPlan &&
                  hasPlan.premium && !isBasic &&
                  carteira.posicoes?.length > 0 && (
                    <ul className="messages">
                      <li>
                        <InfoCircleOutlined />
                        As posições são atualizadas em até quatro dias úteis
                        após sua negociação (D+4)
                      </li>
                      <li>
                        <InfoCircleOutlined />
                        Bonds e/ou operações fora da bolsa de valores não são
                        apresentadas na listagem acima.
                      </li>
                    </ul>
                  )}
              </Tabs.TabPane>
              <Tabs.TabPane tab="PROVENTOS" key="item-2">
                <Proventos hideValues={hideValues} />
              </Tabs.TabPane>
            </Tabs>
          )}
        </div>
        <DrawerModal
          footer={null}
          height="auto"
          visible={helpModal}
          title={helpModal?.title}
          onCancel={onCloseHelpModal}
        >
          {helpModal?.content}
        </DrawerModal>
        <DrawerModal
          footer={null}
          height="auto"
          visible={expiredModal}
          title={"Seu plano premium expirou"}
          maskClosable={false}
          onCancel={() => {
            api.post("/user/dismiss-modal", { userId: user?.user?.userId });
            setExpiredModal(false);
          }}
        >
          <Row style={{ alignItems: "center" }}>
            <InfoCircleOutlined />
            <Typography style={{ fontSize: "13px", paddingLeft: "8px" }}>
              Você precisa renovar o seu plano para continuar utilzando o
              Velotax.
            </Typography>
          </Row>
          <Button
            type="primary"
            onClick={() => {
              showUserPlanModal(true);
              setDirectPayment(true);
            }}
            style={{
              width: "100%",
              marginTop: "30px",
              marginBottom: "10px",
              height: "50px",
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            Clique aqui para renovar
          </Button>
        </DrawerModal>
      </div>
      <NotificationDrawer
        open={notificationDrawerOpen}
        onClose={() => setNotificationDrawerOpen(false)}
      />
      <ReportTypeDrawer
        loading={loadingReport}
        open={reportDrawerWallet}
        onClose={() => setReportDrawerWallet(false)}
        setType={(type: string) => downloadAction(type)}
      />
      <DrawerModal
        className="filter-modal"
        title="Filtrar por corretora"
        visible={showCorretoraFilterModal}
        onCancel={() => {
          setShowCorretoraFilterModal(false);
        }}
        footer={
          <Button
            block
            size="large"
            type="primary"
            icon={<BiCheck />}
            onClick={() => {
              setShowCorretoraFilterModal(false);
              setSelectedCorretoras(checkboxCorretoras);
              setFilterCorretoraAtivo(
                checkboxCorretoras.map((corretora: string) =>
                  getNomeCorretora({ shortName: corretora })
                )
              );
            }}
          >
            Confirmar
          </Button>
        }
      >
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Typography.Text strong>Corretoras</Typography.Text>
              </Col>
              <Col span={24}>
                <Checkbox
                  checked={checkAll}
                  indeterminate={indeterminate}
                  className="corretoras-allcheck"
                  onChange={(e: any) => {
                    setCheckboxCorretoras(e.target.checked ? corretoras : []);
                  }}
                >
                  Todas as corretoras
                </Checkbox>
                <Checkbox.Group
                  value={checkboxCorretoras}
                  onChange={(e: any) => {
                    setCheckboxCorretoras(e);
                  }}
                >
                  <Row gutter={[4, 4]}>
                    {corretoras.map((corretora, i) => (
                      <Col span={24} key={i}>
                        <Checkbox value={corretora}>{corretora}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                </Checkbox.Group>
              </Col>
            </Row>
          </Col>
        </Row>
      </DrawerModal>
    </Container>
  );
};
