import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { IntegrationExteriorXP } from "../XpIntegration";
import { useBroker } from "../../../contexts/BrokerContext";
import { IntegrationExteriorWarren } from "../WarrenIntegration";
import { IntegrationExteriorBTG } from "../BTGIntegration";

interface IExteriorIntegrationProps {
  id?: string;
  data?: any;
  dependentId?: string;
  setLoadingIntegration?: React.Dispatch<React.SetStateAction<boolean>>;
  setData?: ({ id, value, customData, from }: any, patch?: any) => void;
  customActions?: { [id: string]: (data: any) => void };
}

export type IntegrationStatusExterior = "carregando" | "integrado" | "erro" | "nao-integrado";

const ExteriorIntegration = (props: IExteriorIntegrationProps) => {
  const { id, setData } = props;
  const navigate = useNavigate();
  const { currentBroker, searchParams } = useBroker();

  const handleIntegration = (type: IntegrationStatusExterior, blockRedirect?: boolean) => {
    setData?.({ id, value: type === "carregando" }, false);
    if (type === "carregando") return;
    else {
      props.setLoadingIntegration?.(false);
    }
  };

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={8}>
        <IntegrationExteriorXP integrationCallback={handleIntegration} />
      </Col>
      <Col xs={24} sm={8}>
        <IntegrationExteriorBTG integrationCallback={handleIntegration} />
      </Col>
      <Col xs={24} sm={8}>
        <IntegrationExteriorWarren integrationCallback={handleIntegration} />
      </Col>
    </Row>
  );
};

export default ExteriorIntegration;
