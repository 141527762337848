import { Col, Row, Typography } from "antd";
import React, { Dispatch, SetStateAction } from "react";
import Button from "../../../../Button";
import { ReCaptcha } from "../../../../ReCaptcha";
import { useAuth } from "../../../../../contexts/AuthContext";

interface IFooterProps {
  currentStep: number;
  handleProceed: () => void;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  setReCaptchaToken: Dispatch<SetStateAction<string | undefined>>;
  backtoRoot?: any;
  typePurchase: string;
  reCaptchaToken?: string;
  selectedCardId?: string;
  changeCardAction?: boolean;
}

const Footer: React.FC<IFooterProps> = ({
  backtoRoot,
  currentStep,
  typePurchase,
  handleProceed,
  setCurrentStep,
  reCaptchaToken,
  changeCardAction,
  setReCaptchaToken,
}) => {
  const { disabledRecaptcha } = useAuth();
  return (
    <Row
      align="middle"
      gutter={[8, 8]}
      justify="space-between"
      style={{ marginTop: "16px" }}
    >
      {currentStep === 2 && (
        <Col span={24} style={{ margin: "16px 0" }}>
          <ReCaptcha token={reCaptchaToken} setToken={setReCaptchaToken} />
        </Col>
      )}
      {currentStep !== 3 && currentStep !== 4 && (
        <Col xs={24}>
          <Button
            block
            size="large"
            type="primary"
            onClick={handleProceed}
            disabled={
              currentStep === 2 && !reCaptchaToken && !disabledRecaptcha
            }
          >
            {currentStep === 1
              ? "Prosseguir"
              : typePurchase === "UNIQUE"
              ? "Finalizar pagamento"
              : changeCardAction
              ? "Finalizar"
              : "Finalizar compra"}
          </Button>
        </Col>
      )}
      {currentStep <= 2 && (
        <Col xs={24}>
          <Button
            block
            size="large"
            onClick={() => {
              if (currentStep === 1 || currentStep === 2) {
                backtoRoot();
                return;
              }
              setCurrentStep((step) => step - 1);
            }}
          >
            Voltar
          </Button>
        </Col>
      )}
      {currentStep !== 2 && typePurchase !== "UNIQUE" && (
        <Col span={24} style={{ marginTop: "8px" }}>
          <Typography.Paragraph style={{ textAlign: "center", fontSize: 12 }}>
            Ao prosseguir, você declara que aceita nosso{" "}
            <a
              href="https://velotax-termos-e-condicoes.s3.amazonaws.com/TERMO%2BDE%2BACEITE%2BE%2BCONTRATAC%CC%A7A%CC%83O_11.10.2022.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Termo de Contratação
            </a>
            .
          </Typography.Paragraph>
        </Col>
      )}
    </Row>
  );
};

export default Footer;
