import { AxiosResponse } from "axios";

export const callWebviewHandler = (name: string, ...args: any[]) => {
  (window as any).flutter_inappwebview.callHandler(name, ...args);
};

export const handleInAppPurchaseIfIsIos = (): boolean => {
  const isIos = isIosPlatform();

  if (isIos) {
    callWebviewHandler("open_in_app_purchase");
  }

  return isIos;
};

export const isIosPlatform = (): boolean => {
  const platform = localStorage.getItem("platform");
  const isIos = platform === "ios";
  return isIos;
};

export const downloadExcelFromApi = (result: AxiosResponse) => {
  const fileUrl = result.data.fileUrl;
  callWebviewHandler("download_file_link", fileUrl);
};
