import styled from "styled-components";

export const AvisosContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .client-integration-card {
    padding: 24px;
    display: flex;
    align-items: start;
    border-radius: 8px;
    margin-bottom: 1rem;
    background-color: var(--ant-primary-1);
    & > svg {
      min-width: 32px;
      min-height: 32px;
      color: var(--ant-primary-color);
      stroke: var(--ant-primary-color);
      &.fill {
        fill: var(--ant-primary-color);
      }
    }

    .client-integration-card-content {
      width: 100%;
      margin-left: 16px;
      h3 {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 8px;
        color: var(--ant-primary-color);
      }
      div.ant-typography {
        color: var(--dark-gray);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        width: calc(100% - 48px);
      }
      .ant-btn {
        padding: 0;
        border: none;
        height: 40px;
        font-size: 14px;
        margin: -12px 0;
        span {
          font-weight: 700;
        }
      }
      button {
        display: flex;
        align-items: center;

        svg {
          margin-left: 4px;
        }
      }
    }
  }

  div:first-of-type {
    width: 100%;
  }

  .title {
    h3 {
      font-weight: 600;
    }
  }
  .divider {
    width: 100%;
    background-color: var(--ant-primary-color);
    height: 2px;
    margin-bottom: 2rem;
  }

  .plan-boxes {
    flex-wrap: nowrap;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .ant-divider {
    margin: 8px 0;
  }

  .ant-btn {
    height: 56px;

    span {
      font-weight: 500;
      svg {
        display: none;
      }
    }
  }
  .total-contratado {
    display: none;
  }
  .dismiss {
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      color: var(--ant-primary-7);
      transition: 0.3s;
    }
  }

  @media only screen and (max-device-width: 812px) {
    .ant-card-body {
      padding: 16px;
    }
  }
`;
