import clsx from "clsx";
import React from "react";
// import { Typography } from "antd";
import { CheckCircleTwoTone, LoadingOutlined } from "@ant-design/icons";
import { Container } from "../IntegrationButton/styles";
import { Typography } from "antd";

interface IIntegrationButtonProps {
  alt: string;
  loading: boolean;
  integrado: boolean;
  setIntegrationModalVisible: Function;
  logo?: string;
  imgHeight?: boolean;
  handleClick?: Function;
  b3Link?: string;
  isB3LinkRedirect?: boolean;
}

const XpIntegrationButton: React.FC<IIntegrationButtonProps> = ({
  alt,
  logo,
  b3Link,
  loading,
  integrado,
  imgHeight,
  isB3LinkRedirect,
  setIntegrationModalVisible,
}) => {
  return (
    // <Tooltip title={integrado ? "" : "Clique para integrar"}>
    <Container
      imgHeight={imgHeight}
      style={{ padding: "24px" }}
      className={clsx({ loading, integrado })}
      onClick={() => !loading && setIntegrationModalVisible(true)}
      // style={{ cursor: "default"}}
    >
      {isB3LinkRedirect && b3Link && (
        <a href={b3Link} className="b3-link">
          .
        </a>
      )}
      <img src={logo} alt={alt} />
      <Typography.Paragraph className="click-here">
        {integrado ? "Integrado!" : "Clique para integrar"}
      </Typography.Paragraph>
      {!loading && integrado && (
        <div className="integrado-check">
          <CheckCircleTwoTone twoToneColor="var(--ant-success-color)" />
        </div>
      )}
      {loading && (
        <div className="loading-animation">
          <LoadingOutlined color="fff" />
          <span>Isso pode demorar alguns minutos, aguarde</span>
        </div>
      )}
    </Container>
    // </Tooltip>
  );
};

export default XpIntegrationButton;
