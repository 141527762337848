import { Typography } from "antd";
import { AiOutlineApi } from "react-icons/ai";
import { HiOutlineCalculator } from "react-icons/hi";
import myntLogo from "../../assets/mynt.svg";
import binanceLogo from "../../assets/binance-logo.svg";
import mercadoBitcoinLogo from "../../assets/mercadobitcoin-favicon.png";
import bitcoinTradeLogo from "../../assets/bitcointrade-logo.svg";
import { isMobile } from "../../utils";
import { RiFileList3Line } from "react-icons/ri";

export const itensCriptoSemLogo = [
  {
    id: "darf",
    premium: true,
    basic: false,
    link: "/velotax/cripto-historic",
    showToBasic: true,
    content: <Typography>Calculadora de DARF</Typography>,
    description: <Typography>Cálculo de IR e emissão de DARF</Typography>,
    icon: (
      <HiOutlineCalculator
        className="no-fill"
        color="var(--ant-primary-color)"
      />
    ),
  },
  {
    id: "integration",
    premium: true,
    basic: false,
    link: "/velotax/cripto-integrations",
    showToBasic: true,
    content: <div>
      <Typography>Integrações</Typography>
    </div>,
    description: <Typography>Conexões com as suas corretoras</Typography>,
    icon: <AiOutlineApi className="no-fill" color="var(--ant-primary-color)" />,
  },
  {
    id: "report",
    basic: false,
    premium: true,
    link: "/velotax/cripto-report",
    content: (
      <Typography>
        Relatórios {!isMobile() && "(padrão Receita Federal)"}
      </Typography>
    ),
    description: (
      <Typography>Relatórios auxiliares para declaração de IR</Typography>
    ),
    icon: (
      <RiFileList3Line className="no-fill" color="var(--ant-primary-color)" />
    ),
  },
];
