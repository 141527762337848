import clsx from "clsx";
import { useState } from "react";
import { VscClose } from "react-icons/vsc";
import { FaRegEdit } from "react-icons/fa";
import { Form, List, message } from "antd";
import NumberFormat from "react-number-format";
import { InfoCircleOutlined } from "@ant-design/icons";
import apis from "../../services/apis";
import Button from "../../components/Button";
import { useBroker } from "../../contexts/BrokerContext";
import {
  currencyToNumber,
  errorMessage,
  formatCurrency,
  isMobile,
  numberToPercentage,
  replaceDotByComma,
  zero,
} from "../../utils";
import {
  validationDateIsOnReferenceMonth,
  validationFieldRequired,
  validationNumberAllowNegativeField,
  validationNumberFieldPositive,
} from "../../utils/formValidations";
import {
  CurrencyFormItem,
  DateFormItem,
  NumberFormItem,
  TextFormItem,
  SelectFormItem
} from "../formItems";
import { Link } from "react-router-dom";
import {
  IDarf,
  IDarfBolsa,
  JurosModalProps,
  MinPriceModalProps,
  MultaModalProps,
  minDarfPrice,
} from "../darf";

export const TipoMercado: { [key: string]: string } = {
  mercado: "Mercado à vista",
  mercadoAVista: "Mercado a Vista",
  mercadoOpcoes: "Mercado de opções",
  outros: "Outros mercados",
  mercadoFracionario: "Merc. Fracionário",
  opcaoCompra: "Opção de Compra",
  opcaoVenda: "Opção de Venda",
  dayTrade: "Day Trade",
};

export const TipoOperacao: { [key: string]: string } = {
  comum: "Comum",
  daytrade: "Day Trade",
  fii: "FII",
};

export const defaultDarf: IDarf = {
  totalAlienado: 0,
  impostoDevido: 0,
  impostoTotal: 0,
  impostoAcumulado: 0,
  totalImpostoDevido: 0,
  aliquotaDevida: 0,
  totalGanho: 0,
  juros: 0,
  multa: 0,
  memoriaCalculo: [],
  status: "REGULAR",
};

export const defaultDarfBolsa: IDarfBolsa = {
  alienacoesComum: 0,
  alienacoesDT: 0,
  alienacoesFII: 0,
  impostoComumFinal: 0,
  impostoDTFinal: 0,
  impostoFIIFinal: 0,
  impostoTotal: 0,
  impostoTotalFinal: 0,
  irrfComum: 0,
  irrfDayTrade: 0,
  irrfFII: 0,
  irrfTotal: 0,
  jurosAux: 0,
  mercadoOpcoesDT: 0,
  mercadoOutrosComum: 0,
  mercadoOutrosDT: 0,
  mercadoVistaComum: 0,
  mercadoVistaDT: 0,
  multaAux: 0,
  memoriaCalculo: [],
  impostoAcumulado: 0,
  prejuizoComum: 0,
  prejuizoDayTrade: 0,
  prejuizoFII: 0,
};

const IRRFModalProps = {
  title: <>IRRF (Imposto de Renda Retido na Fonte)</>,
  content: (
    <div>
      <p>
        Operações Comuns e FIIs: As operações comuns realizadas em bolsas de
        valores, de mercadorias, de futuros e de FIIs estão sujeitas à retenção
        do imposto sobre a renda incidente na fonte à alíquota de 0,005% sobre o
        volume alienado.
      </p>
      <p>
        Operações Day Trade: As operações de Day Trade estão sujeitas à retenção
        do imposto sobre a renda incidente na fonte à alíquota de 1% do ganho,
        independente do volume alienado no mês.
      </p>
    </div>
  ),
};

const AliquotaModalProps = {
  title: "Alíquota sobre ganhos com criptoativos",
  content: (
    <div>
      <p>
        Caso o valor total vendido no mês seja superior a R$ 35 mil, os ganhos
        obtidos serão tributados a título de ganho de capital. As alíquotas
        (percentuais) aplicadas sobre o lucro são progressivas:
      </p>
      <List>
        <List.Item>
          a) 15% sobre a parcela dos ganhos que não ultrapassar R$ 5.000.000,00;
        </List.Item>
        <List.Item>
          b) 17,5% sobre a parcela dos ganhos que exceder R$ 5.000.000,00 e não
          ultrapassar R$ 10.000.000,00;
        </List.Item>
        <List.Item>
          c) 20% sobre a parcela dos ganhos que exceder R$ 10.000.000,00 e não
          ultrapassar R$ 30.000.000,00; e
        </List.Item>
        <List.Item>
          d) 22,5% sobre a parcela dos ganhos que ultrapassar R$ 30.000.000,00.
        </List.Item>
      </List>
    </div>
  ),
};

interface IEdit {
  value: any;
  id: string;
  isEditting: boolean;
}
interface DarfResultDescriptionsProps {
  id: string;
  label: (onClick: Function) => React.ReactNode;
  Component: React.FC<{
    data: IDarfBolsa;
    url: string;
    year: number;
    month: number;
    loading: boolean;
    disabled: boolean;
    view?: boolean;
    handleEdit?: Function;
    setDataCripto?: React.Dispatch<React.SetStateAction<IDarf>>;
    setDataBolsa?: React.Dispatch<React.SetStateAction<IDarfBolsa>>;
    valorPrincipal?: number;
    getTaxes?: (
      a?: boolean,
      b?: boolean,
      atualizouImpostoAcumulado?: boolean,
      nAtualizarPrejuizosAcumulados?: boolean
    ) => void;
  }>;
}

export const DarfResultDescriptions: DarfResultDescriptionsProps[] = [
  {
    id: "impostoTotal",
    label: () => "(+) Valor do principal",
    Component: ({ data, view }) => {
      return formatCurrency(
        Math.max(
          0,
          Number(
            (data.impostoComumFinal || 0) +
              (data.impostoDTFinal || 0) +
              (data.impostoFIIFinal || 0)
          ) +
            (Number(data["impostoAcumulado"] || 0) -
              Number(data["irrfTotal"] || 0))
        )
      );
    },
  },
  {
    id: "impostoTotal",
    label: () => (
      <div
        className="desc-label"
        style={{ fontSize: "12px", paddingLeft: "24px" }}
      >
        (+) Imposto devido
      </div>
    ),
    Component: ({ data, view }) => {
      return formatCurrency(
        Number(
          (data.impostoComumFinal || 0) +
            (data.impostoDTFinal || 0) +
            (data.impostoFIIFinal || 0)
        )
      );
    },
  },
  {
    id: "impostoAcumulado",
    label: (onClick: any) => (
      <div
        className="desc-label"
        style={{ fontSize: "12px", paddingLeft: "24px" }}
      >
        (+) DARFs passados abaixo de R$ {minDarfPrice}
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          onClick={() => onClick(MinPriceModalProps)}
        />
      </div>
    ),
    Component: ({
      data,
      year,
      month,
      loading,
      disabled,
      setDataBolsa,
      setDataCripto,
      url,
      getTaxes,
    }) => {
      const { currentPage } = useBroker();
      const [edit, setEdit] = useState<IEdit>();
      const [editForm] = Form.useForm();
      const handleEditValue = (id: string) => {
        const value = data[id as keyof (IDarf | IDarfBolsa)];
        setEdit({ id, isEditting: true, value });
        editForm.setFieldsValue({ [id]: value });
      };

      const handleEditValueCancel = () => {
        setEdit(undefined);
        editForm.resetFields();
      };
      const handleEditValueConfirm = () => {
        if (edit && edit.value >= 0 && edit.value < minDarfPrice) {
          (currentPage?.api || apis)
            .post(`${url}/updateDataDarf`, {
              year,
              month: month + 1,
              [edit.id]: edit.value,
            })
            .then(() => {
              getTaxes?.(true, true, true, true);
              return (setDataCripto ?? setDataBolsa)?.((data: any) => ({
                ...data,
                [edit.id]: edit.value,
              }));
            })
            .catch(() => message.error(errorMessage))
            .finally(() => handleEditValueCancel());
        } else {
          handleEditValueCancel();
        }
      };
      return edit && edit.isEditting && edit.id === "impostoAcumulado" ? (
        <div className="desc-content is-editting">
          {!isMobile() && (
            <Button icon={<VscClose />} onClick={handleEditValueCancel} />
          )}
          <Form
            form={editForm}
            onValuesChange={(changed) => {
              const value = currencyToNumber(changed.impostoAcumulado);
              editForm.setFieldsValue({ impostoAcumulado: value });
              setEdit((edit) => ({ ...edit!, value }));
            }}
          >
            <Form.Item
              name="impostoAcumulado"
              rules={[
                {
                  message: `Deve ser menor que ${minDarfPrice}`,
                  validator: (rule, value) =>
                    typeof value === "number" &&
                    value >= 0 &&
                    value < minDarfPrice
                      ? Promise.resolve()
                      : Promise.reject(),
                },
              ]}
            >
              <NumberFormat
                prefix="R$ "
                decimalScale={2}
                disabled={loading}
                decimalSeparator=","
                thousandSeparator="."
                className="ant-input"
                allowNegative={false}
              />
            </Form.Item>
          </Form>
          <Button
            type="primary"
            onClick={handleEditValueConfirm}
            disabled={edit.value < 0 || edit.value >= minDarfPrice}
          >
            OK
          </Button>
          {isMobile() && (
            <Button icon={<VscClose />} onClick={handleEditValueCancel} />
          )}
        </div>
      ) : (
        <div
          className={clsx("desc-content", {
            "ml-40": disabled,
          })}
        >
          {formatCurrency(Number(data.impostoAcumulado || 0))}
          {disabled && (
            <Button
              type="text"
              icon={<FaRegEdit />}
              onClick={() => handleEditValue("impostoAcumulado")}
            />
          )}
        </div>
      );
    },
  },
  {
    id: "irrfTotal",
    label: (onClick: any) => (
      <div
        className="desc-label"
        style={{ fontSize: "12px", paddingLeft: "24px" }}
      >
        (-) Imposto de Renda Retido na Fonte
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          onClick={() => onClick(IRRFModalProps)}
        />
      </div>
    ),
    Component: ({ data, handleEdit, view }) => (
      <div
        className={`desc-content ${!view ? "ml-40" : ""}`}
        style={{ textAlign: "center" }}
      >
        {formatCurrency(Number((data as IDarfBolsa)["irrfTotal"] || 0))}
        {!view && (
          <Button
            type="text"
            icon={<FaRegEdit />}
            onClick={() => handleEdit?.()}
          />
        )}
      </div>
    ),
  },
  {
    id: "multa",
    label: (onClick: any) => (
      <div className="desc-label">
        (+) Multa de atraso{" "}
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          onClick={() => onClick(MultaModalProps)}
        />
      </div>
    ),
    Component: ({ data, view, valorPrincipal }) =>
      // view
      //   ? formatCurrency(data["multa"] || 0)
      //   :
      formatCurrency(
        (valorPrincipal || 0) < minDarfPrice ? 0 : data["multa"] || 0
      ),
  },
  {
    id: "juros",
    label: (onClick: any) => (
      <div className="desc-label">
        (+) Juros e/ou encargos de atraso{" "}
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          onClick={() => onClick(JurosModalProps)}
        />
      </div>
    ),
    Component: ({ data, view, valorPrincipal }) =>
      // view
      //   ? formatCurrency(data["juros"] || 0)
      //   :
      formatCurrency(
        (valorPrincipal || 0) < minDarfPrice ? 0 : Number(data["juros"] || 0)
      ),
  },
];

export const DarfImpostosDevidosDescriptions = [
  {
    id: "totalGanho",
    label: () => "Ganho total",
  },
  {
    id: "aliquotaDevida",
    label: (onClick: any) => (
      <div className="desc-label">
        (x) Alíquota devida
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          onClick={() => onClick(AliquotaModalProps)}
        />
      </div>
    ),
    render: (data: any) =>
      `${replaceDotByComma(
        Number((data["aliquotaDevida"] || 0).toFixed(2))
      )} %`,
  },
  {
    id: "impostoDevido",
    label: () => "Imposto devido",
    render: (data: any, view?: boolean) =>
      formatCurrency(
        !view
          ? Number(data["impostoDevido"] || 0)
          : Number(data["impostoDevido"] || 0) -
              Number(data["impostoDevido"] ? data["impostoAcumulado"] || 0 : 0)
      ),
  },
];

export const AddTransactionFormItemRows = (hasLandingSide: boolean) => {
  if (!hasLandingSide)
    return [
      [FormItemsBolsa.id],
      [FormItemsBolsa.code("Ex: PETR3")],
      [FormItemsBolsa.quantity("Ex: 100")],
      [FormItemsBolsa.avgBuyPrice],
    ];

  return [
    [FormItemsBolsa.id],
    [FormItemsBolsa.code("Ex: PETR3")],
    [FormItemsBolsa.quantity("Ex: 100")],
    [FormItemsBolsa.avgBuyPrice],
    [FormItemsBolsa.lendingSide],
  ];
};

export const AddTransactionGOVFormItemRows = (hasLandingSide: boolean) => {
  if (!hasLandingSide)
    return [
      [FormItemsBolsa.id],
      [FormItemsBolsa.code("Ex: PETR3")],
      [FormItemsBolsa.quantity("Ex: 100")],
      [FormItemsBolsa.avgBuyPriceGOV],
    ];

  return [
    [FormItemsBolsa.id],
    [FormItemsBolsa.code("Ex: PETR3")],
    [FormItemsBolsa.quantity("Ex: 100")],
    [FormItemsBolsa.avgBuyPriceGOV],
    [FormItemsBolsa.lendingSide],
  ];
};

const FormItemsBolsa = {
  id: TextFormItem({
    name: "id",
    label: "Id",
    hidden: true,
  }),
  date: (month: number, year: number) =>
    DateFormItem({
      name: "date",
      label: "Data",
      placeholder: `01/${zero(month + 1)}/${year}`,
      mask: `99/${zero(month + 1).replace(/[9]/g, `\\9`)}/${year
        .toString()
        .replace(/[9]/g, `\\9`)}`,
      rules: validationDateIsOnReferenceMonth(month, year),
    }),
  code: (placeholder: string) =>
    TextFormItem({
      name: "code",
      label: "Ativo",
      placeholder,
      rules: validationFieldRequired,
    }),
  quantity: (placeholder: string) =>
    NumberFormItem({
      name: "quantity",
      label: "Quantidade",
      placeholder,
      decimalScale: 12,
      rules: validationNumberFieldPositive,
    }),
  avgBuyPrice: CurrencyFormItem({
    name: "avgBuyPrice",
    // label: "Custo médio de compra (por ação)",
    label: "Custo de compra",
  }),
  avgBuyPriceGOV: CurrencyFormItem({
    name: "avgBuyPrice",
    label: "Custo médio de compra (por ação)",
  }),
  price: CurrencyFormItem({ name: "price", label: "Preço de venda" }),
  feeB3: CurrencyFormItem({ name: "feeB3", label: "Taxas" }),
  capitalGain: CurrencyFormItem({
    name: "capitalGain",
    label: "Ganho",
    disabled: true,
    allowNegative: true,
    rules: validationNumberAllowNegativeField,
    onChange: (changed, values) => {
      const precoVenda = changed.price
        ? currencyToNumber(changed.price)
        : values.price;
      const custoCompra = changed.avgBuyPrice
        ? currencyToNumber(changed.avgBuyPrice)
        : values.avgBuyPrice;
      const taxas = changed.feeB3
        ? currencyToNumber(changed.feeB3)
        : values.feeB3;
      return Number(values.quantity) * (precoVenda - custoCompra) - taxas;
    },
  }),
  operacao: SelectFormItem({
    name: "operacao",
    label: "Operação",
    placeholder: "Selecione",
    mustUpdate: true,
    options: [
      { label: "Comum", value: "Comum" },
      { label: "Day Trade", value: "Day Trade" },
      { label: "Fundos Imobiliários", value: "FII" },
    ],
  }),
  market: ({ data }: any) =>
    SelectFormItem({
      name: "market",
      label: "Mercado",
      placeholder: "Selecione",
      options: [
        { label: "Mercado à vista", value: "Mercado a Vista" },
        ...(data.operacao !== "FII"
          ? [
              { label: "Mercado de opções", value: "Mercado de opções" },
              { label: "Outros mercados", value: "Outros mercados" },
            ]
          : []),
      ],
      disabled: data.operacao === "FII",
      onChange: (changed, values) =>
        values.operacao === "FII" && values.market !== "Mercado a Vista"
          ? "Mercado a Vista"
          : values.market,
    }),
  lendingSide: SelectFormItem({
    name: "lendingSide",
    label: "Posição",
    placeholder: "Selecione",
    mustUpdate: true,
    options: [
      { label: "Doador", value: "Doador" },
      { label: "Tomador", value: "Tomador" },
    ],
  }),
};

const FormItemsDoacoesHerancas = {
  _id: TextFormItem({
    name: "_id",
    label: "Id",
    hidden: true,
  }),
  dateView: DateFormItem({
    name: "dateView",
    label: "Data",
    placeholder: `01/01/2021`,
    mask: `99/99/9999`,
  }),
  code: (placeholder: string) =>
    TextFormItem({
      name: "code",
      label: "Ativo",
      placeholder,
      rules: validationFieldRequired,
    }),
  quantity: (placeholder: string) =>
    NumberFormItem({
      name: "quantity",
      label: "Quantidade",
      placeholder,
      decimalScale: 12,
      rules: validationNumberFieldPositive,
    }),
  price: CurrencyFormItem({ name: "price", label: "Custo médio de compra (por ação)" }),
};

const PrejuFormItems = {
  userPrejuizoComum: (disabled: boolean) =>
    CurrencyFormItem({
      disabled,
      name: "userPrejuizoComum",
      label: "Prejuízo Comum",
    }),
  userPrejuizoDayTrade: (disabled: boolean) =>
    CurrencyFormItem({
      disabled,
      name: "userPrejuizoDayTrade",
      label: "Prejuízo Day Trade",
    }),
  userPrejuizoFII: (disabled: boolean) =>
    CurrencyFormItem({
      disabled,
      name: "userPrejuizoFII",
      label: "Prejuízo FII",
    }),
};

const IrrfFormItem = {
  userIrrfComum: CurrencyFormItem({
    name: "userIrrfComum",
    label: "IRRF Comum",
  }),
  userIrrfDayTrade: CurrencyFormItem({
    name: "userIrrfDayTrade",
    label: "IRRF Day Trade",
  }),
  userIrrfFII: CurrencyFormItem({
    name: "userIrrfFII",
    label: "IRRF FII",
  }),
  userIrrfTotal: CurrencyFormItem({
    name: "userIrrfTotal",
    label: "IRRF Total (Comum + Day Trade + FII)",
    disabled: true,
    onChange: (changed, values) => {
      const userIrrfComum = changed.userIrrfComum
        ? currencyToNumber(changed.userIrrfComum)
        : values.userIrrfComum;
      const userIrrfDayTrade = changed.userIrrfDayTrade
        ? currencyToNumber(changed.userIrrfDayTrade)
        : values.userIrrfDayTrade;
      const userIrrfFII = changed.userIrrfFII
        ? currencyToNumber(changed.userIrrfFII)
        : values.userIrrfFII;
      return (
        Number(userIrrfComum ?? 0) +
        Number(userIrrfDayTrade ?? 0) +
        Number(userIrrfFII ?? 0)
      );
    },
  }),
};

export const CustoMedioModal = {
  title: "Custo Médio",
  content: (
    <div>
      <p>
        Preencha o custo médio do ativo na aba{" "}
        <Link to="/velotax/bolsa-insert-manual">
          <b>Posição 31-12-2019</b>
        </Link>
        .
      </p>
    </div>
  ),
};

export const IrrfFormItemRows = [
  [IrrfFormItem.userIrrfComum],
  [IrrfFormItem.userIrrfDayTrade],
  [IrrfFormItem.userIrrfFII],
  [IrrfFormItem.userIrrfTotal],
];

export const PrejuFormItemRows = (disabled: boolean) => [
  [PrejuFormItems.userPrejuizoComum(disabled)],
  [PrejuFormItems.userPrejuizoDayTrade(disabled)],
  [PrejuFormItems.userPrejuizoFII(disabled)],
];

export const DarfBolsaFormItemRows = (month: number, year: number) => [
  [FormItemsBolsa.id],
  [FormItemsBolsa.operacao, FormItemsBolsa.market],
  [FormItemsBolsa.date(month, year), FormItemsBolsa.code("Ex: PETR3")],
  [FormItemsBolsa.quantity("Ex: 100"), FormItemsBolsa.price],
  [FormItemsBolsa.avgBuyPrice, FormItemsBolsa.feeB3],
  [FormItemsBolsa.capitalGain],
];

export const DoacoesHerancasRows = () => [
  [FormItemsDoacoesHerancas._id],
  [FormItemsDoacoesHerancas.code("Ex: PETR3")],
  [FormItemsDoacoesHerancas.dateView],
  [FormItemsDoacoesHerancas.quantity("Ex: 100")],
  [FormItemsDoacoesHerancas.price],
];

export const DarfBolsaTabs: (
  data: IDarfBolsa,
  rest: any
) => { class: string; tabs: { content: React.ReactNode }[] }[] = (
  data,
  rest
) => [
  {
    class: "header",
    tabs: [
      {
        content: "",
      },
      {
        content: "Comum",
      },
      {
        content: "Day Trade",
      },
      {
        content: "FII",
      },
    ],
  },
  {
    class: "sub-header",
    tabs: [
      {
        content: "Resultado",
      },
      {
        content: formatCurrency(data.varTotaisComum ?? 0),
      },
      {
        content: formatCurrency(data.varTotaisDT ?? 0),
      },
      {
        content: formatCurrency(data.varTotaisFII ?? 0),
      },
    ],
  },
  {
    class: "sub-header",
    tabs: [
      {
        content: "Ganhos tributáveis",
      },
      {
        content: formatCurrency(data.ganhoComum ?? 0),
      },
      {
        content: formatCurrency(data.ganhoDT ?? 0),
      },
      {
        content: formatCurrency(data.ganhoFII ?? 0),
      },
    ],
  },
  {
    class: "sub-header",
    tabs: [
      {
        content: (
          <>
            (-) Prejuízos a compensar
            {!rest.view && (
              <Button
                type="text"
                icon={<FaRegEdit />}
                onClick={rest.handleEditPreju}
              />
            )}
          </>
        ),
      },
      {
        content: formatCurrency(
          data.userPrejuizoComum ?? data.prejuizoComum ?? 0
        ),
      },
      {
        content: formatCurrency(
          data.userPrejuizoDayTrade ?? data.prejuizoDayTrade ?? 0
        ),
      },
      {
        content: formatCurrency(data.userPrejuizoFII ?? data.prejuizoFII ?? 0),
      },
    ],
  },
  // {
  //   class: "sub-header",
  //   tabs: [
  //     {
  //       content: "Base de cálculo",
  //     },
  //     {
  //       content: formatCurrency(
  //         Math.max(
  //           (data.ganhoComum ?? 0) -
  //             (data.userPrejuizoComum
  //               ? data.userPrejuizoComum ?? 0
  //               : data.prejuizoComum ?? 0),
  //           0
  //         )
  //       ),
  //     },
  //     {
  //       content: formatCurrency(
  //         Math.max(
  //           (data.ganhoDT ?? 0) -
  //             (data.userPrejuizoDayTrade
  //               ? data.userPrejuizoDayTrade ?? 0
  //               : data.prejuizoDayTrade ?? 0),
  //           0
  //         )
  //       ),
  //     },
  //     {
  //       content: formatCurrency(
  //         Math.max(
  //           (data.ganhoFII ?? 0) -
  //             (data.userPrejuizoFII
  //               ? data.userPrejuizoFII ?? 0
  //               : data.prejuizoFII ?? 0),
  //           0
  //         )
  //       ),
  //     },
  //   ],
  // },
  {
    class: "sub-header",
    tabs: [
      {
        content: "(x) Alíquota devida",
      },
      {
        content: <span>{numberToPercentage(15, 0)}</span>,
      },
      {
        content: <span>{numberToPercentage(20, 0)}</span>,
      },
      {
        content: <span>{numberToPercentage(20, 0)}</span>,
      },
    ],
  },
  {
    class: "sub-header strong",
    tabs: [
      {
        content: "Imposto devido",
      },
      {
        content: formatCurrency(data.impostoComumFinal ?? 0),
      },
      {
        content: formatCurrency(data.impostoDTFinal ?? 0),
      },
      {
        content: formatCurrency(data.impostoFIIFinal ?? 0),
      },
    ],
  },
];
