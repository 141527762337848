import clsx from "clsx";
import moment from "moment-timezone";
import { BsEye } from "react-icons/bs";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  AiOutlineCalendar,
  AiOutlineLock,
  AiOutlineUnlock,
} from "react-icons/ai";
import {
  Col,
  List,
  message,
  Row,
  Select,
  Skeleton,
  Space,
  Typography,
} from "antd";
import { Darf } from "../Darf";
import apis from "../../services/apis";
import MsDarf from "../../services/msDarf";
import Button from "../../components/Button";
import imoveis from "../../services/imoveis";
import { Rendimentos } from "../Rendimentos";
import { Page } from "../../constants/brokers";
import { DarfExterior } from "../DarfExterior";
import darfBackend from "../../services/msDarf";
import HandleTag from "../../services/handleTag";
import apiCrypto from "../../services/apiCrypto";
import { itensCriptoSemLogo } from "../Cripto/itensCriptoSemLogos";
import { useAuth } from "../../contexts/AuthContext";
import apiExterior from "../../services/apiExterior";
import { useBroker } from "../../contexts/BrokerContext";
import { itensExteriorSemLogo } from "../Exterior/itensExteriorSemLogos";
import { DrawerModal } from "../../components/DrawerModal";
import rendimentosBackend from "../../services/rendimentos";
import { PaymentModal } from "../../components/PaymentModal";
import { PayDarfButton } from "../../components/PayDarfButton";
import { CardHorizontal } from "../../components/CardHorizontal";
import {
  DarfIMoveis,
  maxImoveisCreditCardPayment,
  maxImoveisDarfPrice,
  maxImoveisPixPayment,
} from "../../components/ImoveisImposto";
import {
  maxCreditCardPayment,
  maxDarfPrice,
  maxPixPayment,
  minDarfPrice,
  yearConfigPL4173,
} from "../../constants/darf";
import {
  ativarDarfsRetroativos,
  download,
  errorMessage,
  formatCurrency,
  getLastBusinessDay,
  isMobile,
  mensagemDarfsRetroativos,
  monthsExtended,
} from "../../utils";
import { instanceMsCalc } from "../../services/msCalc";
import { PayButton } from "./PayButton";
import { itensCripto } from "../Cripto/itensCripto";

interface ShowCalcModal {
  visible: boolean;
  view?: boolean;
  month?: number;
  year?: number;
  calc?: "cripto" | "exterior" | "autonomos" | "imoveis";
}

interface HistoricProps {
  item: Page;
}

export const Historic: React.FC<HistoricProps> = ({ item }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { state, pathname } = useLocation();
  const { currentBroker, currentPage, integrations } = useBroker();
  const { user, hasPlan, showUserPlanModal } = useAuth();

  const integrationSproutfi = Boolean(integrations?.sproutfi?.isIntegrated)

  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();
  const stateYear = (state as any)?.year;
  const initialYear = currentYear;
  const queryYear = Number(searchParams.get("year"));
  const queryYearValid =
    queryYear >= currentBroker.initialYear && queryYear <= initialYear
      ? queryYear
      : undefined;
  window.history.replaceState({}, document.title);

  const [data, setData] = useState<any[]>();
  const [holidays, setHolidays] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [emitting, setEmitting] = useState(false);
  const [itemToPay, setItemToPay] = useState<any>();
  const [paymentModal, setPaymentModal] = useState(false);
  const [year, setYear] = useState(queryYearValid ?? stateYear ?? initialYear);
  const [showCalcModal, setShowCalcModal] = useState<ShowCalcModal>({
    visible: false,
  });
  const isBasic = user.user?.userPlanInfoVelotax?.type?.includes('BASIC');

  const planMonthSignature = new Date(user?.user?.userPlanInfoVelotax?.currentPlan?.createdAt).getMonth();
  const planYearSignature = new Date(user?.user?.userPlanInfoVelotax?.currentPlan?.createdAt).getFullYear();

  const historicFeature = item.features[0];
  const payFeature = item.features[1];
  const isCripto = pathname.includes("cripto");
  const isImoveis = pathname.includes("imoveis");
  const isExterior = pathname.includes("exterior");
  const isAutonomos = pathname.includes("autonomos");

  const backend = isExterior
    ? apiExterior
    : isAutonomos
    ? rendimentosBackend
    : isImoveis
    ? imoveis
    : isCripto
    ? apiCrypto
    : apis;
  const oldModel = year < yearConfigPL4173;

  const yearOptions = Array.from(
    Array(initialYear - currentBroker.initialYear + 1)
  )
    .map((y, i) => ({
      value: currentBroker.initialYear + i,
      label: `${currentBroker.initialYear + i}`,
    }))
    .reverse();

  const getHistoric = useCallback(() => {
    setLoading(true);
    backend
      .get(historicFeature.apiUrl, {
        params: { year },
      })
      .then((response) => setData((response.data ?? []).reverse()))
      .catch(() => message.error(errorMessage))
      .finally(() => setLoading(false));
  }, [historicFeature, year, backend]);

  const pay = useCallback(
    (payed: boolean, item: any) => {
      setLoading(true);
      backend
        .put(`${payFeature.apiUrl}/${item._id}`, { payed })
        .then(() => {
          getHistoric();
          setData((data) =>
            (data || []).map((darf) => ({
              ...darf,
              payed: item._id === darf._id ? payed : darf.payed,
            }))
          );
        })
        .catch(() => message.error(errorMessage))
        .finally(() => setLoading(false));
    },
    [payFeature, getHistoric, backend]
  );

  const sendDarfToEmail = (darfId: string) => {
    backend
      .get(`/darf/sendEmail/${darfId}`)
      .then(() => {
        oldModel
        ? message.success("O DARF foi enviado para o seu e-mail")
        : message.success("O Relatório foi enviado para o seu e-mail");
      })
      .catch(() => {
        console.log("Algo inesperado aconteceu. Tente novamente!");
      });
  };

  // const downloadAction = (record: any, index: number) => {
  //   if (!hasPlan) {
  //     handleOpenPlanModal();
  //   } else {
  //     backend.get(`${item.features[2].apiUrl}/${record._id}`).then((res) => {
  //       if (res.data.url) {
  //         download(res.data.url);
  //       }
  //     });
  //   }
  // };

  const editAction = (
    record: any,
    view: boolean,
    index: number,
    noPlanMode?: boolean
  ) => {
    if (!hasPlan && !noPlanMode) {
      handleOpenPlanModal();
    } else if (isImoveis) {
      if (view) {
        setItemToPay(record);
        setShowCalcModal({
          visible: true,
          view,
          calc: "imoveis",
        });
      } else {
        navigate(`/${currentBroker.path}/imoveis-calculadora`, {
          state: record,
        });
      }
    } else {
      const { month, year } = record;
      setShowCalcModal({
        visible: true,
        view,
        year,
        month,
        calc: isAutonomos ? "autonomos" : isExterior ? "exterior" : "cripto",
      });
      if (view) {
        setItemToPay(record);
      }
    }
  };

  const payAction = (value: boolean, record: any) => {
    if (!hasPlan) {
      handleOpenPlanModal();
    } else {
      pay(value, record);
    }
  };

  const handleOpenPlanModal = () => {
    navigate('/planos');
  };

  const handlePayButton = (item: any, allow: boolean) => {
    if(year >= yearConfigPL4173) {
      handleDarf(false, item)
    } else if (!hasPlan || !allow) {
      handleOpenPlanModal();
    } else {
      getTaxes(item.month);
      setPaymentModal(!paymentModal);
    }
  };

  const onChangeYear = (value: string) => {
    setYear(parseInt(value));
  };

  const handleTitle = (data: any) => {
    if (isImoveis) {
      return `CEP: ${data.cep}`;
    } else
      return `${monthsExtended[data.month - 1]}${
        !isMobile() ? ` de ${data.year}` : ""
      }`;
  };

  const valueIsGreaterThanMin = (data: any) =>
    (isAutonomos
      ? Number(
          Math.max(
            0,
            data.impostoDevido +
            (data.impostoAcumulado || 0) -
            (data.gastosExterior || 0) +
            (data.multa || 0) +
            (data.juros || 0)
          )
        ) ?? 0
      : data?.impostoDevido + (data?.impostoAcumulado || 0) - (data.irrfExterior || 0)) >= minDarfPrice;

  const valueIsLessThanMax = (data: any) =>
    isImoveis
      ? data?.impostoDevido + (data?.impostoAcumulado || 0) <=
        maxImoveisDarfPrice
      : (isAutonomos ? data?.impostoDevido || 0 : data?.totalImpostoDevido) <=
        maxDarfPrice;

  const handleDarf = (justSave?: boolean, obj?: any) => {
    setLoading(true);
    setEmitting(true);
    const darfData = obj ? obj : itemToPay;
    (currentPage?.api || apis)
      .post("/darf", {
        ...darfData,
        year,
        month: darfData?.month,
        name: user.user.name,
        memoriaCalculo: [],
        transactions: darfData?.memoriaCalculo,
        paymentInfo: {
          clientInfo: {
            name: user?.user?.name,
            email: user?.user?.email,
            document: user?.user?.cpf,
          },
        },
        insertPix: true,
        justSave: false,
      })
      .then((res) => {
        getHistoric();
        setEmitting(false);
        if (!justSave) {
          setPaymentModal(year >= yearConfigPL4173 ? false : !paymentModal);
          oldModel
          ? message.success("O DARF foi enviado para o seu e-mail")
          : message.success("O Relatório foi enviado para o seu e-mail");

          if (res.data?.id) {
            sendDarfToEmail(res.data.id);
          }
          download(res.data.darfUrl);
        } else {
          payAction(true, { ...data, _id: res.data?.darf?._id });
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || errorMessage);
        setEmitting(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const generateDarf = async (
    pixValue: boolean,
    justSave?: boolean,
    obj?: any
  ) => {
    setEmitting(true);
    const data = obj ? obj : itemToPay;
    const response = await MsDarf.post("/darf", {
      month: data?.month,
      year,
      cpf: user.user.cpf,
      name: user.user.name,
      paymentInfo: {
        clientInfo: {
          document: user.user.cpf,
          email: user.user.email,
          name: user.user.name,
          phone: "",
        },
      },
      insertPix: pixValue,
      valueCharge: Math.max(
        0,
        data?.impostoDevido +
          data?.impostoAcumulado -
          data?.gastosExterior +
          data?.juros +
          data?.multa
      ),
      institution: "velotax-rendimentos",
      impostoDevido: data?.impostoDevido,
      totalImpostoDevido: data?.impostoDevido,
      totalRendimentos: data?.totalRendimentos,
      baseTributaria: data?.baseTributaria,
      totalDependentes: data?.totalDependentes,
      totalDespesas: data?.totalDespesas,
      totalPrevidencia: data?.totalPrevidencia,
      totalPensao: data?.totalPensao,
      totalDeducoes: data?.totalDeducoes,
      aliquotaDevida: data?.aliquotaDevida,
      impostoAcumulado: data?.impostoAcumulado,
      juros: data?.juros,
      multa: data?.multa,
      transactions: data?.monthStock,
      from: 18,
      gastosExterior: data?.gastosExterior,
      justSave: false,
    });
    setEmitting(false);
    setLoading(false);
    if (!justSave) {
      window.location.href = response.data?.darfUrl;
      setPaymentModal(!paymentModal);
    } else {
      payAction(true, { ...data, _id: response.data?.darf?._id });
    }
  };

  const generateDarfImoveis = async (
    pixValue: boolean,
    justPay?: boolean,
    obj?: any
  ) => {
    setEmitting(true);
    const data = obj ? obj : itemToPay;
    const valueCharge =
      (data?.impostoDevido ?? 0) +
      (data?.impostoAcumulado ?? 0) +
      (data?.juros ?? 0) +
      (data?.multa ?? 0);

    const response = await darfBackend.post("/darf", {
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      cpf: user.user.cpf,
      cep: data?.cep,
      _id: data?.imovelId,
      name: user.user.name,
      paymentInfo: {
        clientInfo: {
          document: user.user.cpf,
          email: user.user.email,
          name: user.user.name,
          phone: "",
        },
      },
      insertPix: pixValue,
      valueCharge,
      totalImpostoDevido: data?.impostoDevido,
      institution: "imoveis-velotax",
      impostoDevido: data?.impostoDevido || 0,
      impostoAcumulado: data?.impostoAcumulado || 0,
      juros: data?.juros || 0,
      multa: data?.multa || 0,
      from: 18,
    });
    setLoading(false);
    setEmitting(false);
    if (justPay) {
      payAction(true, { ...data, _id: response.data?.darf?._id });
    } else {
      setPaymentModal(!paymentModal);
      window.location.href = response.data?.darfUrl;
    }
  };

  const handleOpenDarfModal = (justSave?: boolean, obj?: any) => {
    if (isAutonomos) {
      generateDarf(true, justSave, obj);
    } else if (isImoveis) {
      generateDarfImoveis(true, false, obj);
    } else {
      handleDarf(justSave, obj);
    }
  };

  const getHolidays = async () => {
    instanceMsCalc.get(`/rates/get-holidays`)
      .then(({ data }) => {
        const dataFormated = data?.map((el: any) => {
          return moment(el).tz('Europe/London').format('MM-DD');
        });
        setHolidays(dataFormated);
      })
      .catch(() => console.log('Erro ao buscar feriados'));

  }

  useEffect(() => {
    getHistoric();
    getHolidays();
  }, [getHistoric, year]);

  const getTaxes = useCallback(
    (month: number, callback?: (obj: any) => void) => {
      (currentPage?.api || apis)
        .get("/transaction", { params: { month, year } })
        .then((response) => {
          const data = {
            ...response.data,
            year,
            month,
            name: user.user.name,
            memoriaCalculo: [],
            transactions: response.data.memoriaCalculo,
          };
          setItemToPay(data);
          callback?.(data);
        })
        .catch(() => {});
    },
    [currentPage?.api, year, user.user.name]
  );

  const getRendimentos = useCallback(
    async (month: number, callback?: (obj: any) => void) => {
      const response = await rendimentosBackend.get(
        `/rendimentos/${year}/${month}`
      );
      const { data } = response;
      const monthStock = data.monthStock || [];
      const currentImpostos = {
        totalRendimentos: data?.totalRendimentos || 0,
        baseTributaria: data?.baseTributaria || 0,
        aliquotaDevida: data?.aliquotaDevida || 0,
        impostoDevido: data?.impostoDevido || 0,
        totalDeducoes: data?.totalDeducoes || 0,
        totalDependentes: data?.totalDependentes || 0,
        totalDespesas: data?.totalDespesas || 0,
        totalPensao: data?.totalPensao || 0,
        totalPrevidencia: data?.totalPrevidencia || 0,
        juros: data?.juros || 0,
        multa: data?.multa || 0,
        impostoTotal:
          (data.impostoDevido || 0) + (data.juros || 0) + (data.multa || 0),
        impostoAcumulado: data?.impostoAcumulado || 0,
        gastosExterior: data?.gastosExterior || 0,
        payed: data?.payed || false,
        emitted: data?.emitted || false,
      };

      const valueToCharge = Math.max(
        0,
        (currentImpostos?.impostoDevido || 0) +
          (currentImpostos.impostoAcumulado || 0) -
          (currentImpostos.gastosExterior || 0) +
          (currentImpostos.juros || 0) +
          (currentImpostos.multa || 0)
      );

      const dataToPayment = {
        cpf: user.user.cpf,
        name: user.user.name,
        valueCharge: valueToCharge,
        institution: "velotax-rendimentos",
        impostoDevido: currentImpostos.impostoDevido,
        totalImpostoDevido: currentImpostos.impostoTotal,
        impostoTotalFinal: valueToCharge,
        totalRendimentos: currentImpostos.totalRendimentos,
        baseTributaria: currentImpostos.baseTributaria,
        totalDependentes: currentImpostos.totalDependentes,
        totalDespesas: currentImpostos.totalDespesas,
        totalPrevidencia: currentImpostos.totalPrevidencia,
        totalPensao: currentImpostos.totalPensao,
        totalDeducoes: currentImpostos.totalDeducoes,
        aliquotaDevida: currentImpostos.aliquotaDevida,
        impostoAcumulado: currentImpostos.impostoAcumulado,
        gastosExterior: currentImpostos.gastosExterior,
        juros: currentImpostos.juros,
        multa: currentImpostos.multa,
        transactions: monthStock,
        year,
        month,
      };

      setItemToPay(dataToPayment);
      callback?.({ ...data, ...dataToPayment });
    },
    [user.user.cpf, user.user.name, year]
  );

  const setImovelToPay = (item: any) => {
    setItemToPay({
      from: 18,
      paymentForm: "pix",
      cep: item.cep,
      _id: item.imovelId,
      cpf: user.user.cpf,
      name: user.user.name,
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      paymentInfo: {
        clientInfo: {
          document: user.user.cpf,
          email: user.user.email,
          name: user.user.name,
          phone: "",
        },
      },
      juros: item.juros,
      multa: item.multa,
      institution: "imoveis-velotax",
      impostoDevido: item.impostoDevido,
      totalImpostoDevido: item.impostoDevido,
      impostoAcumulado: item.impostoAcumulado ?? 0,
      valueCharge:
        (item.impostoDevido ?? 0) +
        (item.impostoAcumulado ?? 0) +
        (item.juros ?? 0) +
        (item.multa ?? 0),
    });
  };

  const handleFinalValue = (item: any) => {
    if (isAutonomos) {
      return (
        item.totalImpostoDevido ??
        Number(
          Math.max(
            0,
            item.impostoDevido +
              (item.impostoAcumulado || 0) -
              (item.gastosExterior || 0) +
              (item.juros || 0) +
              (item.multa || 0)
          )
        ) ??
        0
      );
    } else if (isImoveis) {
      return Number(
        (item.impostoDevido || 0) +
          (item.impostoAcumulado || 0) +
          (item.juros || 0) +
          (item.multa || 0)
      );
    } else {
      return Number(
        (item.impostoDevido || 0) +
          (item.impostoAcumulado || 0) -
          (item.irrfExterior || 0 ) +
          ((item.impostoDevido || 0) + (item.impostoAcumulado || 0) <
          minDarfPrice
            ? 0
            : (item.juros || 0) + (item.multa || 0) || !oldModel)
      );
    }
  };

  const handleCloseCalcModal = () => {
    setShowCalcModal({ visible: false });
    getHistoric();
  };

  const exteriorTransactionsFilter = (el: any) =>
    (el.operation === "SELL" && !el?.type) ||
    (el.operation === "SELL" && el.manual) ||
    (el.operation === "SELL" && el.type === "TRADE");

  const handleVendasTotais = (item: any) => {
    return isExterior
      ? item.transactions
        ?.filter(exteriorTransactionsFilter)
        .reduce((a: number, t: any) => a + (oldModel ? ((t.valor || 0) * (t.cambioVenda || 0)) : (t.sellPrice_BRL || 0) * (t.quantidade || 0)), 0)
      : pathname.includes('autonomos') ? 0
        : item.transactions.reduce(
        (a: number, t: any) => a + t.quantity * t.sellPrice,
        0
      );
  };

  const ImpostoAPagarNoPlan = (
    <Typography.Text className="no-plan-imposto-a-pagar">
      <span>Imposto a pagar: </span>
      <Button
        type="link"
        onClick={() => {
          handleOpenPlanModal();
        }}
      >
        <strong>
          Clique para verificar&nbsp;
          <AiOutlineLock size={14} />
        </strong>
      </Button>
    </Typography.Text>
  );

  const ImpostoAPagarWithPlan: React.FC<{ item: any }> = ({ item }) => {
    const value = handleFinalValue(item);
    return (
      <Typography.Text>
        <span>Imposto a pagar: </span>
        <strong className="tax-value">
          {value > 0 ? formatCurrency(value) : "Isento"}
        </strong>
      </Typography.Text>
    );
  };

  const showPayButton = (item: any) =>
    (valueIsGreaterThanMin(item) &&
      valueIsLessThanMax(item) &&
      (item.status !== "PAYED" || !item.payed)) ||
    (year >= yearConfigPL4173
      && item.transactions?.filter((t: any) => ['TRADE', 'DIVIDEND', 'INTEREST'].includes(t.type)).length > 0
    );

  // const PayButton: React.FC<{ item: any; block?: boolean }> = ({
  //   item,
  //   block,
  // }) => {
  //   const allow = ativarDarfsRetroativos({
  //     item,
  //     hasPlan,
  //     currentYear,
  //     currentMonth,
  //   });
  //   return (
  //     <Tooltip
  //       getPopupContainer={(target) => target.parentElement!}
  //       title={
  //         !allow
  //           ? mensagemDarfsRetroativos
  //           : disableCurrentMonth(item)
  //           ? "Aguarde o encerramento do mês para pagar seu DARF"
  //           : ""
  //       }
  //     >
  //       <Button
  //         block={block}
  //         type="primary"
  //         disabled={disableCurrentMonth(item)}
  //         icon={!allow && <AiOutlineUnlock />}
  //         style={block ? { marginTop: "16px", height: "40px" } : {}}
  //         onClick={() => {
  //           if (!allow) {
  //             handleOpenPlanModal();
  //           } else {
  //             if (isAutonomos) {
  //               getRendimentos(item.month);
  //             } else if (isImoveis) {
  //               setImovelToPay(item);
  //             } else {
  //               getTaxes(item.month);
  //             }
  //             setPaymentModal(!paymentModal);
  //           }
  //         }}
  //       >
  //         Pagar
  //       </Button>{" "}
  //     </Tooltip>
  //   );
  // };

  const disableCurrentMonth = (item: any) =>
    item.year === currentYear && item.month === currentMonth + 1 && item.year < yearConfigPL4173;

  return (
    <div className="velotax-container">
      <div className="velotax-content">
        <Typography.Title level={1} className="page-title">
          Impostos devidos (DARF)
          {false && !hasPlan && (
            <Button
              size="large"
              type="primary"
              onClick={handleOpenPlanModal}
              className="btn-contratar-plano-historic"
            >
              Contratar plano
              <AiOutlineUnlock />
            </Button>
          )}
        </Typography.Title>
        {!isImoveis && (
          <Select
            size="large"
            options={yearOptions}
            value={year.toString()}
            onChange={onChangeYear}
            className="year-select"
            placeholder="Selecione o ano"
            suffixIcon={<AiOutlineCalendar size={24} />}
            getPopupContainer={(target) => target.parentElement}
          />
        )}

        <List
          loading={loading}
          dataSource={
            data?.filter(
              (item) =>
                !(item.year === currentYear && item.month > currentMonth + 1)
            ) ?? []
          }
          itemLayout="horizontal"
          className={clsx("velotax-historic-list", { "no-plan": !hasPlan })}
          renderItem={(item, index) => {
            const ultimoDiaUtilDoMes = getLastBusinessDay(
              new Date(item.year, item.month + 1, 0),
              holidays,
            );
            const vencimento = moment(ultimoDiaUtilDoMes).format("DD/MM/YYYY");
            const isBasic = user.user?.userPlanInfoVelotax?.type?.includes('BASIC');

            const month = planMonthSignature === 0 ? 11 : planMonthSignature - 1;
            const yearSign = planMonthSignature === 0 ? planYearSignature - 1 : planYearSignature;

            const dateAllowedOnBasic = new Date(yearSign, month, 1);
            const currentDarfDate = new Date(year, item.month - 1, 1);
            const checkMonth = (currentDarfDate.getTime() < dateAllowedOnBasic.getTime());
            const hideOnBasic = isBasic && checkMonth;

            const allow = ativarDarfsRetroativos({
              item,
              hasPlan,
              currentYear,
              currentMonth,
              planMonthSignature: planMonthSignature === 0 ? 12 : planMonthSignature,
              planYearSignature: planMonthSignature === 0 ? planYearSignature - 1 : planYearSignature
            });

            return <List.Item
              actions={
                (!hasPlan)
                  ? []
                  : !isMobile()
                  ? [
                      showPayButton(item) && (
                        <PayButton
                        onClick={() => handlePayButton(item, allow)}
                          disabled={disableCurrentMonth(item)}
                        allow={allow}
                          title={
                            allow
                              ? disableCurrentMonth(item) ? "Aguarde o encerramento do mês para pagar seu DARF"
                                : ""
                              : mensagemDarfsRetroativos
                          }
                          isReport={!oldModel}
                        />
                      ),
                      <Button
                        type="link"
                        icon={<BsEye size={16} />}
                        onClick={() =>
                          editAction(
                            item,
                            item.status === "PAYED" &&
                              valueIsGreaterThanMin(item),
                            index
                          )
                        }
                      >
                        {showPayButton(item) ? "" : <strong>Visualizar</strong>}
                      </Button>,
                    ]
                  : []
              }
            >
              <Skeleton title={false} loading={item.loading} active>
                <List.Item.Meta
                  title={
                    <>
                      {handleTitle(item)}
                      {(!hasPlan || hideOnBasic) &&
                        (isAutonomos ? item.monthStock : item.transactions)
                          ?.length > 0 &&
                        isMobile() && (
                          <Button
                            type="link"
                            icon={
                              <BsEye
                                size={16}
                                style={{
                                  minWidth: "24px",
                                  minHeight: "24px",
                                  marginLeft: "12px",
                                  strokeWidth: "0.2px",
                                  fill: "var(--ant-primary-color)",
                                }}
                              />
                            }
                            onClick={() => editAction(item, true, index, true)}
                          />
                        )}
                      {(hasPlan && !hideOnBasic && oldModel) ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {valueIsGreaterThanMin(item) &&
                            !hideOnBasic &&
                          !disableCurrentMonth(item) ? (
                            <PayDarfButton
                              checked={item.payed}
                              checkedChildren="Pago"
                              unCheckedChildren="Em aberto"
                              ativarDarfsRetroativos={ativarDarfsRetroativos({
                                item,
                                hasPlan,
                                currentYear,
                                currentMonth,
                                planMonthSignature: planMonthSignature === 0 ? 12 : planMonthSignature,
                                planYearSignature: planMonthSignature === 0 ? planYearSignature - 1 : planYearSignature
                              })}
                              onChange={() => {
                                if (
                                  !ativarDarfsRetroativos({
                                    item,
                                    hasPlan,
                                    currentYear,
                                    currentMonth,
                                    planMonthSignature: planMonthSignature === 0 ? 12 : planMonthSignature,
                                    planYearSignature: planMonthSignature === 0 ? planYearSignature - 1 : planYearSignature
                                  })
                                ) {
                                  showUserPlanModal(true);
                                } else if (item._id && item.fullPath) {
                                  payAction(!item.payed, item);
                                } else if (isAutonomos) {
                                  setLoading(true);
                                  getRendimentos(item.month, (obj: any) => {
                                    handleOpenDarfModal(true, obj);
                                  });
                                } else if (isImoveis) {
                                  setItemToPay(item);
                                  generateDarfImoveis(true, true, item);
                                } else {
                                  setLoading(true);
                                  getTaxes(item.month, (obj: any) => {
                                    handleOpenDarfModal(true, obj);
                                  });
                                }
                              }}
                            />
                          ) : undefined}
                          {isMobile() && (
                            <Button
                              type="link"
                              icon={
                                <BsEye
                                  size={16}
                                  style={{
                                    minWidth: "24px",
                                    minHeight: "24px",
                                    marginLeft: "12px",
                                    strokeWidth: "0.2px",
                                    fill: "var(--ant-primary-color)",
                                  }}
                                />
                              }
                              onClick={() =>
                                editAction(
                                  item,
                                  item.status === "PAYED" &&
                                    valueIsGreaterThanMin(item),
                                  index
                                )
                              }
                            />
                          )}
                        </div>
                      ) : undefined}
                    </>
                  }
                  description={
                    <Space direction="vertical">
                      {!hasPlan || hideOnBasic ? (
                        <>
                          {isImoveis ? (
                            ImpostoAPagarNoPlan
                          ) : (isAutonomos
                              ? item.monthStock
                              : isExterior
                              ? item.transactions?.filter(
                                  exteriorTransactionsFilter
                                )
                              : item.transactions
                            )?.length > 0 ? (
                            <>
                              <Typography.Text>
                                <span>Vendas totais: </span>
                                <strong>
                                  {formatCurrency(handleVendasTotais(item))}
                                </strong>
                              </Typography.Text>
                              {ImpostoAPagarNoPlan}
                            </>
                          ) : (
                            <Typography.Text className="no-transactions">
                              Não houve movimentações tributáveis neste mês
                            </Typography.Text>
                          )}
                        </>
                      ) : (
                        <>
                          {isImoveis ? (
                            <ImpostoAPagarWithPlan item={item} />
                          ) : (isAutonomos
                              ? item.monthStock
                              : item.transactions
                            )?.length > 0 ? (
                            <>
                              <Typography.Text>
                                <span>Vendas totais: </span>
                                <strong>
                                  {formatCurrency(handleVendasTotais(item))}
                                </strong>
                              </Typography.Text>
                              {!ativarDarfsRetroativos({
                                item,
                                hasPlan,
                                currentYear,
                                currentMonth,
                                planMonthSignature: planMonthSignature === 0 ? 12 : planMonthSignature,
                                planYearSignature: planMonthSignature === 0 ? planYearSignature - 1 : planYearSignature
                              }) ? (
                                <Typography.Text className="no-plan-imposto-a-pagar">
                                  <span>Imposto a pagar: </span>
                                  <Button
                                    type="link"
                                    onClick={() => {
                                      handleOpenPlanModal();
                                    }}
                                  >
                                    <strong>
                                              Clique para verificar&nbsp;
                                      <AiOutlineLock size={14} />
                                    </strong>
                                  </Button>
                                </Typography.Text>
                              ) : (
                                <ImpostoAPagarWithPlan item={item} />
                              )}
                              {handleFinalValue(item) > 0 && oldModel &&
                                ativarDarfsRetroativos({
                                  item,
                                  hasPlan,
                                  currentYear,
                                  currentMonth,
                                  planMonthSignature: planMonthSignature === 0 ? 12 : planMonthSignature,
                                  planYearSignature: planMonthSignature === 0 ? planYearSignature - 1 : planYearSignature
                                }) && (
                                  <Typography.Text>
                                    <span>Vencimento: </span>
                                    <span>
                                          {vencimento}
                                    </span>
                                  </Typography.Text>
                                )}
                                {isMobile() && showPayButton(item) && (
                                  <PayButton
                                    fullWidth
                                        allow={allow}
                                        onClick={() => handlePayButton(item, allow)}
                                    disabled={disableCurrentMonth(item)}
                                    title={
                                      allow
                                        ? disableCurrentMonth(item) ? "Aguarde o terceiro dia útil deste mês para pagar seu DARF"
                                          : ""
                                        : mensagemDarfsRetroativos
                                    }
                                    isReport={!oldModel}
                                  />
                                )}
                            </>
                          ) : (
                            <Typography.Text className="no-transactions">
                              Não houve movimentações tributáveis neste mês
                            </Typography.Text>
                          )}
                        </>
                      )}
                    </Space>
                  }
                />
              </Skeleton>
            </List.Item>
          }}
        />

        {(isCripto || isExterior) && (
          <Typography.Title level={2} className="subtitle-lined">
            <span>Outras opções</span>
          </Typography.Title>
        )}

        <div>
          {(isCripto
            ? [...itensCripto.slice(1)]
            : isExterior
            ? [...itensExteriorSemLogo.slice(1)]
            : []
          ).map((item: any) => {
            if (item.sprotifi && !integrationSproutfi) {
              return null;
            } 
            return (
              <CardHorizontal
                id={item.id}
                key={item.id}
                icon={item.icon}
                link={item.link}
                className={clsx({ "no-plan": !hasPlan })}
                onClick={
                  (hasPlan && !isBasic) || item.id === "integration"
                    ? undefined
                    : () => {
                        handleOpenPlanModal();
                      }
                }
              >
                {(hasPlan && !isBasic) || item.id === "integration" ? (
                  <>{item.content}</>
                ) : (
                  <Row align="middle" justify="space-between" gutter={[8, 8]}>
                    <Col>{item.content}</Col>
                    <Button
                      type="primary"
                      className="btn-premium"
                      icon={<AiOutlineLock size={18} />}
                      onClick={() => {
                        handleOpenPlanModal();
                      }}
                    >
                      PREMIUM
                    </Button>
                  </Row>
                )}
              </CardHorizontal>
            )
          })}
        </div>
      </div>

      <PaymentModal
        item={item}
        show={paymentModal}
        emitting={emitting}
        selectedYear={year}
        openPlanModal={handleOpenPlanModal}
        maxPixValue={isImoveis ? maxImoveisPixPayment : maxPixPayment}
        maxCCValue={
          isImoveis ? maxImoveisCreditCardPayment : maxCreditCardPayment
        }
        disablePixOption={
          (handleFinalValue(itemToPay || {}) || 0) >
          (isImoveis ? maxImoveisPixPayment : maxPixPayment)
        }
        disableCreditCardOption={
          (handleFinalValue(itemToPay || {}) || 0) >
          (isImoveis ? maxImoveisCreditCardPayment : maxCreditCardPayment)
        }
        closeDarfModal={() => {
          setPaymentModal(false);
          getHistoric();
        }}
        onCancel={() => {
          setPaymentModal(!paymentModal);
        }}
        callDarf={() => {
          handleOpenDarfModal(false, itemToPay);
          if (isCripto) {
            HandleTag("78");
          }
          if (isExterior) {
            HandleTag("77");
          }
        }}
        paymentData={{
          ...itemToPay,
          impostoTotalFinal: isImoveis
            ? (itemToPay?.impostoDevido ?? 0) +
              (itemToPay?.juros ?? 0) +
              (itemToPay?.multa ?? 0) +
              (itemToPay?.impostoAcumulado ?? 0)
            : itemToPay?.totalImpostoDevido + itemToPay?.impostoAcumulado,
        }}
        {...(isImoveis ? { customInstallments: 12 } : {})}
      />
      <DrawerModal
        title=""
        width={700}
        className="darf-modal"
        visible={showCalcModal.visible}
        onCancel={handleCloseCalcModal}
        bodyStyle={{
          padding: "0",
        }}
      >
        {isImoveis && itemToPay ? (
          <DarfIMoveis
            view={showCalcModal.view}
            taxData={itemToPay}
            docData={itemToPay}
            cep={itemToPay.cep}
            valorVenda={itemToPay.valorVenda}
            valorAquisicao={itemToPay.valorAquisicao}
            custoCorretagem={itemToPay.custoCorretagem}
          />
        ) : isAutonomos ? (
          <Rendimentos
            view={showCalcModal.view}
            viewEdit={!showCalcModal.view}
            closeModal={handleCloseCalcModal}
            yearView={showCalcModal.year ?? 0}
            monthView={showCalcModal.month ? showCalcModal.month - 1 : 0}
            item={{
              header: true,
              sidebar: true,
              component: "Rendimentos",
              path: "/velotax/autonomos-darf",
              name: "Calculadora",
              title: "",
              features: [
                {
                  apiUrl: "/transaction",
                },
                {
                  apiUrl: "/darf",
                  buttonLabel: "Emitir DARF",
                },
                {
                  apiUrl: "/darf/year",
                },
              ],
            }}
            darf={
              hasPlan && showCalcModal.view
                ? {
                    ...itemToPay,
                    id: itemToPay.id ?? itemToPay._id,
                  }
                : undefined
            }
          />
        ) : isExterior ? (
          <DarfExterior
            view={showCalcModal.view}
            viewEdit={!showCalcModal.view}
            closeModal={handleCloseCalcModal}
            date={{
              year: showCalcModal.year ?? 0,
              month: showCalcModal.month ? showCalcModal.month - 1 : 0,
            }}
            darf={
              hasPlan && !isBasic && showCalcModal.view
                ? {
                    ...itemToPay,
                    id: itemToPay.id ?? itemToPay._id,
                  }
                : undefined
            }
            item={{
              premium: true,
              integratedOnly: true,
              header: true,
              sidebar: true,
              component: "DarfExterior",
              path: "/velotax/exterior-darf",
              name: "Calculadora",
              title: "",
              settings: {
                integrationUrl: "/velotax/exterior-integrations",
                showNavigateToIntegrationButton: false,
              },
              features: [
                {
                  apiUrl: "/transaction",
                },
                {
                  apiUrl: "/darf",
                  buttonLabel: "Emitir DARF",
                },
                {
                  apiUrl: "/darf/year",
                },
              ],
            }}
          />
        ) : (
          <Darf
            view={showCalcModal.view}
            viewEdit={!showCalcModal.view}
            closeModal={handleCloseCalcModal}
            date={{
              year: showCalcModal.year ?? 0,
              month: showCalcModal.month ? showCalcModal.month - 1 : 0,
            }}
            darf={
              hasPlan && showCalcModal.view
                ? {
                    ...itemToPay,
                    id: itemToPay.id ?? itemToPay._id,
                  }
                : undefined
            }
            item={{
              premium: true,
              integratedOnly: true,
              header: true,
              sidebar: true,
              component: "Darf",
              path: "/velotax/cripto-darf",
              name: "Calculadora",
              title: "",
              settings: {
                integrationUrl: "/velotax/cripto-integrations",
                showNavigateToIntegrationButton: false,
              },
              features: [
                {
                  apiUrl: "/transaction",
                },
                {
                  apiUrl: "/darf",
                  buttonLabel: "Emitir DARF",
                },
                {
                  apiUrl: "/darf/year",
                },
              ],
            }}
          />
        )}
      </DrawerModal>
    </div>
  );
};
