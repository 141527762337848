import CalendarLocale from "rc-picker/lib/locale/pt_BR";

export const months = [
  "Jan",
  "Fev",
  "Mar",
  "Abr",
  "Mai",
  "Jun",
  "Jul",
  "Ago",
  "Set",
  "Out",
  "Nov",
  "Dez",
];

export const monthsExtended = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

export const antDatePickerLocale = {
  lang: {
    placeholder: "",
    ...CalendarLocale,
  },
  timePickerLocale: {},
};

export const getInitialYear = (year: number, month: number) =>
  year;

