import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  .ant-drawer.plan-drawer-modal {
    .ant-drawer-content-wrapper {
      .ant-drawer-content {
        background-color: var(--light-gray);
        .ant-drawer-wrapper-body {
          padding-top: 24px;
          .ant-drawer-header {
            padding: 8px 24px;
            background-color: var(--light-gray);
            .ant-drawer-header-title {
              .ant-drawer-close {
                margin: -8px 0 0 12px;
              }
              .ant-drawer-title {
                font-weight: 800;
                p {
                  margin: 0;
                }
              }
            }
          }
          .ant-drawer-body {
            overflow: unset;
            padding: 8px 24px 48px;
          }
        }
      }
    }
  }

  .ant-modal.plan-drawer-modal {
    .ant-modal-content {
      background-color: var(--light-gray);
      .ant-modal-header {
        padding: 48px 64px 0;
        background-color: var(--light-gray);
        .ant-modal-title {
          &:before {
            left: -64px;
          }
          font-weight: 800;
          line-height: 40px;
          font-size: 1.75rem;
        }
      }
      .ant-modal-body {
        padding: 8px 64px 48px;
      }
    }
  }

  @media only screen and (max-device-width: 812px) {
    .plan-drawer-modal {
      .ant-modal-title,
      .ant-drawer-title {
        font-size: 1.5rem !important;
      }
    }
  }
`;
