export const vendasTotais = (item: any) =>
  item.transactions.reduce((a: number, t: any) => a + fator(t), 0);

export const fator = (item: any) => {
  const value = (item.quantity || 0) * (item.sellPrice || 0);
  const code = item.code?.substring(0, 3)?.toUpperCase() || "";
  switch (code) {
    case "WIN":
      return value / 5;
    case "WDO":
      return value / 10;
    case "DOL":
      return value / 50;
    case "IAP":
      return value / 25;
    case "ETN":
      return value / 30;
    case "ETH":
      return value / 30;
    case "SOY":
      return value / 34;
    case "CNY":
      return value / 35;
    case "EUR":
      return value / 50;
    case "ICF":
      return value / 100;
    case "KFE":
      return value / 100;
    case "OZ1":
      return value / 250;
    case "CCM":
      return value / 450;
    case "SFI":
      return value / 450;
    case "SJC":
      return value / 450;
    case "ACF":
      return value / 508;
    default:
      return value;
  }
};

export const vendasIsentas = (item: any) => {
  const codigosIsentos = [
    "FRTA3",
    "FRTA3F",
    "GSHP3",
    "GSHP3F",
    "NUTR3",
    "NUTR3F",
    "CRDE3",
    "CRDE3F",
    "PRNR3",
    "PRNR3F",
  ];
  const vendasMercadoIsento = item.mercadoIsento || 0;
  const vendasMercadoComum =
    item.transactions
      ?.filter(
        (t: any) =>
          t.operacao === "Comum" &&
          !codigosIsentos.includes(t.code.toUpperCase())
      )
      ?.reduce((a: number, t: any) => a + fator(t), 0) || 0;
  return (
    vendasMercadoIsento + vendasMercadoComum
  );
};

const exteriorTransactionsFilter = (el: any) =>
  (el.operation === "SELL" && !el?.type) ||
  (el.operation === "SELL" && el.manual) ||
  (el.operation === "SELL" &&
    (el.type === "TRADE" || el.type === "WITHDRAW")) ||
  el.type === "INTEREST";

export const vendasTotaisExt = (item: any) => {
  const vendasTotais = item.transactions
    ?.filter(exteriorTransactionsFilter)
    .reduce((a: number, t: any) => a + t.valor * t.cambioVenda, 0);
  return item.transactions ? vendasTotais : 0;
};
