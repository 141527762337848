import { createContext, useContext, useState } from "react";

export const today = new Date();

const year = today.getFullYear();

const anoCalendario = year - 1;

interface IYearContext {
  year: number;
  currentMonth: number;
  anoCalendario: number;
  changeYear: (year: number) => void;
}

const YearContext = createContext<IYearContext>({} as IYearContext);

export const YearProvider: React.FC = ({ children }) => {
  const [Year, setYear] = useState(year);
  const [AnoCalendario, setAnoCalendario] = useState(anoCalendario);

  const changeYear = (year: number) => {
    setYear(year);
    setAnoCalendario(year - 1);
  };

  return (
    <YearContext.Provider
      value={{
        changeYear,
        year: Year,
        anoCalendario: AnoCalendario,
        currentMonth: today.getMonth()
      }}
    >
      {children}
    </YearContext.Provider>
  );
};

export const useYear = () => useContext(YearContext);
