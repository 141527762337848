import React, { useEffect, useState, useMemo } from "react";
import { Col, Row, Typography, Spin, message } from "antd";
import Button from "../../Button";
import apis from "../../../services/apis";
import { CopyText } from "../../CopyText";
import { ReCaptcha } from "../../ReCaptcha";
import { formatCurrency } from "../../../utils";
import { useAuth } from "../../../contexts/AuthContext";
import { apiPayment } from "../../../services/apiPayment";
import { useBroker } from "../../../contexts/BrokerContext";
import { getPlanPrice, planos } from "../../../pages/Planos/planos";

interface IPixPaymentProps {
  paymentData: any;
  handleIdBack: (event: any) => void;
  onSucess?: (event: any, delay: number) => void;
  backStep?: () => void;
  cupom?: any;
  closeModal: (event: any) => void;
  addInfo?: any;
  isDarfBatch: Boolean;
  resumo: any[];
  reCaptchaToken?: string;
  setReCaptchaToken: React.Dispatch<React.SetStateAction<string | undefined>>;
}

// const pixTaxesByInsitution: Record<string, number> = {
//   "imoveis-velotax": 350,
//   "rendimentos-velotax": 14.9,
//   "bolsa-velotax": 14.9,
//   "exterior-velotax": 14.9,
//   "crypto-velotax": 14.9,
// };

const PixPaymentStep: React.FC<IPixPaymentProps> = ({
  paymentData,
  handleIdBack,
  onSucess,
  backStep,
  cupom,
  closeModal,
  addInfo,
  isDarfBatch,
  resumo,
  reCaptchaToken,
  setReCaptchaToken,
}) => {
  const { currentPage } = useBroker();
  const {
    user,
    hasPlan,
    isIOSAPP,
    isAndroidAPP,
    showUserPlanModal,
    disabledRecaptcha,
  } = useAuth();
  const [loading, setLoading] = useState(false);
  const [qrCodeLink, setQRCodeLink] = useState(null);
  const [qrCodeLinkUrl, setQRCodeLinkUrl] = useState(null);
  const [brCode, setBrCode] = useState("");
  const provider = paymentData.isPlan ? "pagarme" : "starkbank";

  useEffect(() => {
    if (!!reCaptchaToken || disabledRecaptcha) {
      if (provider === "pagarme") {
        if (addInfo?.pixClientInfo) {
          callUserPlanPayment();
        }
      } else {
        callDarfPix();
      }
    }
    // eslint-disable-next-line
  }, [reCaptchaToken, disabledRecaptcha, addInfo?.pixClientInfo]);

  // const planPixInfo = addInfo?.hasDiscount
  //   ? {
  //       value: 279.9,
  //       oldValue: 310.8,
  //     }
  //   : {
  //       ...paymentData,
  //       value: 645.9,
  //       oldValue: ["prd"].includes(process.env.REACT_APP_NODE_ENV + "")
  //         ? 718.8
  //         : 718.8,
  //     };

  // const priceNew = useMemo(() => {
  //   if (paymentData.isPlan) {
  //     var planPrice = planPixInfo?.value || 0;

  //     if (cupom?.type === "absolute") {
  //       planPrice =
  //         Number(((planPrice - Number(cupom?.discountValue)) / 12).toFixed(2)) *
  //         12;
  //     } else if (cupom?.type === "percent") {
  //       planPrice =
  //         Number(
  //           (
  //             (planPrice - planPrice * Number(cupom?.discountValue / 100)) /
  //             12
  //           ).toFixed(2)
  //         ) * 12;
  //     }

  //     return planPrice;
  //   }
  // }, [
  //   cupom?.discountValue,
  //   cupom?.type,
  //   paymentData.isPlan,
  //   planPixInfo?.value,
  // ]);

  const showMsgPremiumYear = useMemo(() => {
    if (
      !paymentData.isPlan &&
      user.user.userPlanInfoVelotax?.type === "VELOTAX_MAIN_PRO_MONTH"
    )
      return true;
    if (!paymentData.isPlan && !user?.user?.userPlanInfoVelotax?.active)
      return true;
  }, [
    paymentData.isPlan,
    user.user.userPlanInfoVelotax.type,
    user?.user?.userPlanInfoVelotax?.active,
  ]);

  const callUserPlanPayment = () => {
    let url: string = "/user-plan/";
    setLoading(true);
    apiPayment
      .post(
        url,
        {
          ...paymentData,
          paymentForm: "pix",
          from: "VELOTAX",
          cupomCode: cupom?.cupomCode,
          clientInfo: {
            phone: addInfo?.pixClientInfo?.phone,
            name: addInfo?.pixClientInfo?.name ?? user?.user?.name,
            email: user?.user?.email,
            document: user?.user?.cpf,
          },
        },
        {
          headers: {
            recaptcha: reCaptchaToken || "",
          },
        }
      )
      .then((res: any) => {
        setLoading(false);
        if (onSucess && typeof onSucess === "function")
          onSucess(res?.data?.paymentId, 4);
        setQRCodeLinkUrl(res?.data?.qrCode);
        setBrCode(res?.data?.br_code);
      })
      .catch((err) => {
        message.error(err.response?.data?.message);
      });
  };

  const callDarfPix = () => {
    let url: string = isDarfBatch ? "/darf/batch" : "/darf";
    const paymentDataPayload = isDarfBatch
      ? {
          amount: paymentData.impostoTotalFinal,
          resumo,
        }
      : {
          ...paymentData,
        };

    setLoading(true);
    (currentPage?.api || apis)
      .post(
        url,
        {
          ...paymentDataPayload,
          paymentForm: "pix",
          paymentInfo: {
            clientInfo: {
              name: user?.user?.name,
              email: user?.user?.email,
              document: user?.user?.cpf,
            },
          },
        },
        {
          headers: {
            recaptcha: reCaptchaToken || "",
          },
        }
      )
      .then((res: any) => {
        setLoading(false);
        handleIdBack(res?.data?.payment?.paymentId);
        setQRCodeLink(res?.data?.qrCode);
        setBrCode(res?.data?.br_code);
      })
      .catch((err) => {
        message.error(err.response?.data?.message);
      });
  };

  const plan = planos.find((p) => p.type === paymentData?.type);
  const price = getPlanPrice(plan!, hasPlan) ?? 25.9;
  const installments = plan?.installments ?? 12;
  let totalPrice = price * installments;
  if (cupom && cupom.active && cupom.type === "absolute")
    totalPrice -= cupom.discountValue;
  if (cupom && cupom.active && cupom.type === "percent")
    totalPrice -= totalPrice * (cupom.discountValue / 100);
  const planPrice = totalPrice / installments;

  return (
    <Row>
      <Col style={{ justifyContent: "center", width: "100%" }}>
        {/* {paymentData.isPlan && (
          <>
            <Row justify="center">
              <div className="original-price">
                <Typography.Text style={{ fontSize: 15 }}>
                  <strong>
                    Plano Premium: {formatCurrency(priceNew)} por ano
                  </strong>
                </Typography.Text>
                <br />
                <Typography.Text
                  delete
                  style={{
                    color: "red",
                    fontSize: 12,
                    justifyContent: "right",
                    display: "flex",
                  }}
                  className="per-month"
                >
                  de {formatCurrency(planPixInfo.oldValue)} por ano
                </Typography.Text>
                {cupom?.type && (
                  <Typography.Text
                    style={{
                      fontSize: 12,
                      display: "flex",
                      justifyContent: "right",
                      color: "var(--velotax-disabled)",
                    }}
                    className="per-month"
                  >
                    cupom de desconto de{" "}
                    {cupom.type === "percent" ? (
                      cupom.discountValue + " %"
                    ) : (
                      <div style={{ marginLeft: 5 }}>
                        {formatCurrency(cupom.discountValue)}
                      </div>
                    )}
                  </Typography.Text>
                )}
              </div>
            </Row>
          </>
        )} */}
        {/* <Row>
          <Col
            style={{
              margin: "auto",
              marginTop: paymentData.isPlan ? -25 : -35,
            }}
          >
            <img
              alt="PIX Logo"
              style={{ width: 150, height: 150 }}
              src={pixLogo}
            />
          </Col>
        </Row> */}
        {/* {provider !== "pagarme" && (
          <Row justify="center">
            <Col
              style={{ fontSize: "14px", color: "var(--ant-primary-color)" }}
            >
              <strong>
                Taxa PIX:{" "}
                {formatCurrency(
                  pixTaxesByInsitution?.[paymentData.institution] || 9.9
                )}
              </strong>
            </Col>
          </Row>
        )} */}
        <Row justify="center">
          <Col>
            <Typography.Paragraph
              style={{
                color: "var(--velotax-font-color-dark)",
                marginBottom: 15,
                textAlign: "justify",
              }}
            >
              Abra o aplicativo do banco em que você possui o PIX cadastrado e
              selecione a opção de escanear QR Code ou PIX copia e cola.
            </Typography.Paragraph>
          </Col>
          {paymentData.isPlan && (
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginBottom: "16px",
                width: "100%",
              }}
            >
              <Typography.Text strong style={{ textAlign: "center" }}>
                Plano anual
              </Typography.Text>
              <Typography.Text style={{ textAlign: "center" }}>
                12x de {formatCurrency(planPrice)} ={" "}
                {formatCurrency(planPrice * 12)}
              </Typography.Text>
            </Col>
          )}
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "16px",
              width: "100%",
            }}
          >
            {qrCodeLink && (
              <img
                alt="PIX QRCode"
                style={{ width: 190 }}
                src={`data:image/jpeg;base64, ${qrCodeLink}`}
              />
            )}

            {!qrCodeLink && !qrCodeLinkUrl && (
              <Spin spinning={loading} style={{ margin: "32px" }}></Spin>
            )}

            {qrCodeLinkUrl && (
              <img
                alt="PIX QRCode"
                style={{ width: 190 }}
                src={qrCodeLinkUrl}
              />
            )}
          </Col>
        </Row>
        <Row justify="center">
          {brCode ? (
            <>
              {isIOSAPP || isAndroidAPP ? (
                <Col span={24}>
                  <Typography.Paragraph
                    style={{
                      textAlign: "center",
                      wordBreak: "break-all",
                      fontWeight: 500,
                      fontSize: "11px",
                      maxWidth: "240px",
                      margin: "0 auto 32px",
                      color: "var(--velotax-font-color)",
                    }}
                  >
                    <CopyText id="pix-code">{brCode}</CopyText>
                  </Typography.Paragraph>
                </Col>
              ) : (
                <Col
                  span={24}
                  style={{ justifyContent: "center", display: "flex" }}
                >
                  <Button
                    size="large"
                    type="link"
                    style={{
                      margin: "0 auto 16px",
                      color: "var(--ant-primary-color)",
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(brCode);
                      message.success("Pix copiado!");
                    }}
                  >
                    <span
                      style={{
                        borderBottom: "2px dashed var(--ant-primary-color)",
                      }}
                    >
                      Copiar código PIX
                    </span>
                  </Button>
                </Col>
              )}
              {/* <Col span={24}>
                <Typography.Paragraph
                  style={{
                    textAlign: "center",
                    wordBreak: "break-all",
                    fontWeight: 500,
                    fontSize: "11px",
                    maxWidth: "240px",
                    margin: "0 auto",
                    color: "var(--velotax-font-color-dark)",
                  }}
                >
                  {cutBrCode()}
                </Typography.Paragraph>
              </Col> */}
            </>
          ) : (
            ""
          )}
        </Row>
        {false &&
          showMsgPremiumYear &&
          paymentData?.institution !== "imoveis-velotax" && (
            <Row style={{ marginTop: "8%" }}>
              <Col>
                <Typography.Paragraph
                  style={{ textAlign: "left", fontSize: 12 }}
                >
                  <strong>
                    Usuários Premium Anual <u>não pagam</u> taxa de serviço para
                    pagamentos por PIX.
                  </strong>
                  <span
                    style={{ color: "blue", cursor: "pointer", marginLeft: 5 }}
                    onClick={(e) => {
                      closeModal(e);
                      showUserPlanModal(true);
                    }}
                  >
                    Clique para saber mais.
                  </span>
                </Typography.Paragraph>
              </Col>
            </Row>
          )}
        <Col span={24}>
          <ReCaptcha token={reCaptchaToken} setToken={setReCaptchaToken} />
        </Col>
        <Col span={24}>
          <Button
            block
            size="large"
            onClick={() => {
              backStep && backStep();
            }}
          >
            Voltar
          </Button>
        </Col>
      </Col>
    </Row>
  );
};

export default PixPaymentStep;
