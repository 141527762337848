import styled from "styled-components";
import check from "../../assets/icons/check.svg";

export const PayDarfButtonContainer = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: var(--white);
  height: 24px;
  border-radius: 12px;
  transition: filter 0.3s;
  padding: 4px 24px 4px 12px;
  background-color: var(--em-aberto);

  &.disabled {
    opacity: 0.4;
    cursor: default;
  }

  :hover {
    filter: contrast(0.75) brightness(1.15);
  }

  :after {
    content: "";
    top: 6px;
    right: 6px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    position: absolute;
    background-color: var(--white);
  }

  &.checked {
    background-color: var(--green);
    &::after {
      top: 5px;
      width: 14px;
      height: 14px;
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-color: var(--green);
      background-image: url(${check});
    }
  }
`;
