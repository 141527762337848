import { useLocation, useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { RiDownloadCloudLine } from "react-icons/ri";
import { useCallback, useEffect, useState } from "react";
import {
  List,
  message,
  Select,
  Skeleton,
  Typography,
} from "antd";
import { cards } from "./items";
import apis from "../../services/apis";
import { Card } from "../ReportBolsa/Cards";
import Button from "../../components/Button";
import { Page } from "../../constants/brokers";
import { useAuth } from "../../contexts/AuthContext";
import { useBroker } from "../../contexts/BrokerContext";
import { ReportContainer, Container, Content } from "../ReportBolsa/styles";
import {
  download,
  downloadPDF,
  errorMessage,
  getInitialYear,
  monthsExtended,
  zero,
} from "../../utils";

interface ReportProps {
  item: Page;
}

export const ReportCripto: React.FC<ReportProps> = ({ item }) => {
  const { showUserPlanModal, userPlanModal, hasPlan } = useAuth();

  const { state } = useLocation();
  const navigate = useNavigate();
  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();
  const stateYear = (state as any)?.year;
  const initialYear = getInitialYear(currentYear, currentMonth);
  window.history.replaceState({}, document.title);

  const { currentPage } = useBroker();
  const [action, setAction] = useState<string>();

  const [data, setData] = useState<any[]>();
  const [infoModal, toggleModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingLinks] = useState(false);
  const [year, setYear] = useState(stateYear ?? initialYear);

  const historicFeature = item.features[0];
  const downloadFeature = item.features[1];
  // const sendEmailFeature = item.features[2];

  const yearOptions = Array.from(Array(initialYear - 2020 + 1))
    .map((y, i) => ({
      value: 2020 + i,
      label: `${2020 + i}`,
    }))
    .reverse();

  const onChangeYear = (value: string) => {
    setYear(parseInt(value));
  };

  const getHistoric = useCallback(() => {
    if (!action) return;
    if (action === "anual-report")
      return setData(
        Array.from(Array(new Date().getFullYear() - 2022))
          .map((y, i) => ({
            year: 2022 + i,
            label: `${2022 + i}`,
          }))
          .reverse()
      );

    setLoading(true);
    (currentPage?.api || apis)
      .get(`${historicFeature.apiUrl}/${action || "gain"}`, {
        params: { year },
      })
      .then((response) => setData((response.data ?? []).reverse()))
      .catch(() => message.error(errorMessage))
      .finally(() => setLoading(false));
  }, [historicFeature, year, currentPage, action]);

  const downloadAction = (record: any) => {
    setLoading(true);
    (currentPage?.api || apis)
      .post(`${downloadFeature.apiUrl}/${action}`, {
        id: record._id,
        month: record.month,
        year: record.year,
      })
      .then((res) => {
        if (res.data.file) {
          downloadPDF(res.data.file, "crypto");
        } else if (res.data.fileUrl) {
          download(res.data.fileUrl);
        }
        message.success("O relatório foi enviado para o seu e-mail");
      })
      .catch(({ response }) =>
        message.error(response?.data?.message ?? errorMessage)
      )
      .finally(() => setLoading(false));
  };

  // const checkProcessorAction = (record: any) => {
  //   setLoading(true);
  //   let showToast = false;
  //   const itvl = setInterval(() => {
  //     (currentPage?.api || apis)
  //       .get(`${downloadFeature.apiUrl}/${action}?year=${record.year}`)
  //       .then((res) => {
  //         const { data } = res;
  //         const { year, status } = data;
  //         const isProcessing = record.year === year && status === "processing";
  //         if (isProcessing && showToast) return;
  //         if (isProcessing && !showToast) {
  //           showToast = true;
  //           return message.success(
  //             `Estamos processando os dados para seu relatório anual de ${year}`
  //           );
  //         }
  //         setLoading(false);
  //         clearInterval(itvl);
  //       })
  //       .catch(({ response }) => {
  //         const { data, status } = response;
  //         if (status === 401)
  //           setTimeout(() => navigate("/velotax/bolsa-integration"), 2000);
  //         message.error(data?.message ?? errorMessage);
  //         setLoading(false);
  //         clearInterval(itvl);
  //       });
  //   }, 5000);
  // };

  const handlePlanModal = () => {
    showUserPlanModal(!userPlanModal);
  };

  const handleActionButton = (id: string) => {
    if (!hasPlan) {
      handlePlanModal();
    } else {
      setAction(id);
    }
  };

  useEffect(() => {
    getHistoric();
  }, [getHistoric, year, action]);

  return (
    <ReportContainer>
      <div className="velotax-container">
        <div className="velotax-content">
          <Typography.Title level={1} className="page-title">
            Relatórios auxiliares
          </Typography.Title>
          <Typography.Paragraph>
            Confira os relatórios auxiliares de operações de cripto para a
            declaração do seu IR.
          </Typography.Paragraph>
          {!action && (
            <div className="cards">
              {cards.map((item) => (
                // <Badge.Ribbon
                //   key={item.id}
                //   text="Em breve"
                //   color="primary"
                //   style={{ display: item.id === "reports" ? "" : "none" }}
                // >
                <Card
                  key={item.id}
                  icon={item.icon}
                  handleClick={() => !loading && handleActionButton(item.id)}>
                  {item.content}
                </Card>
                // </Badge.Ribbon>
              ))}
            </div>
          )}
          {action && (
            <Container>
              <Content>
                <Typography.Paragraph>Selecione o ano:</Typography.Paragraph>
                {action !== "anual-report" && (
                  <Select
                    size="large"
                    options={yearOptions}
                    value={year.toString()}
                    onChange={onChangeYear}
                    placeholder="Selecione o ano"
                    style={{ minWidth: "150px" }}
                    getPopupContainer={(trigger) => trigger.parentElement}
                  />
                )}
                <List
                  loading={loading}
                  dataSource={
                    data?.filter((item) => {
                      return (
                        action === "anual-report" ||
                        item.year === year ||
                        item.year === currentYear
                      );
                    }) ?? []
                  }
                  itemLayout="horizontal"
                  renderItem={(item, index) => (
                    <List.Item
                      actions={[
                        <Button
                          type="text"
                          size="large"
                          href={item.url}
                          disabled={loadingLinks}
                          onClick={
                            () => downloadAction(item)
                            // action === "anual-report"
                            //   ? toggleModal(item)
                            //   : downloadAction(
                            //     item
                            //   ) /*sendDarfToEmail(item._id)*/
                          }
                          download={`Relatório-${item.year}-${zero(
                            item.month
                          )}.pdf`}>
                          Baixar
                          {loadingLinks ? (
                            <LoadingOutlined size={16} />
                          ) : (
                            <RiDownloadCloudLine size={16} />
                          )}
                        </Button>,
                      ]}>
                      <Skeleton title={true} loading={item.loading} active>
                        <List.Item.Meta
                          title={
                            action !== "anual-report"
                              ? `${monthsExtended[item.month - 1]}`
                              : `${item.year}`
                          }
                        />
                      </Skeleton>
                    </List.Item>
                  )}
                />
                <Button
                  type="default"
                  size="large"
                  onClick={() => handleActionButton("")}
                  style={{ marginTop: "1rem" }}>
                  Voltar
                </Button>
              </Content>
            </Container>
          )}
        </div>
        {/* <Modal
          title="Aviso"
          maskClosable={true}
          visible={!!infoModal}
          onCancel={() => {
            toggleModal(null);
          }}
          footer={
            <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
              <Col span={24}>
                <MaterialButton
                  fullWidth
                  size="large"
                  style={{ backgroundColor: "#1634ff" }}
                  variant="contained"
                  onClick={() => {
                    downloadAction(infoModal);
                    toggleModal(null);
                  }}>
                  Continuar
                </MaterialButton>
              </Col>
              <Col xs={24} sm={12}>
                <MaterialButton
                  fullWidth
                  size="small"
                  variant="text"
                  color="info"
                  onClick={() => {
                    navigate("/velotax/cripto-historic");
                  }}>
                  Ir para calculadora de DARF
                </MaterialButton>
              </Col>
            </Row>
          }>
          <Typography.Paragraph style={{ marginTop: "-24px" }}>
            <strong>
              Caso você tenha realizado operações de venda de ativos ou recebido
              cupom de bonds neste ano,
            </strong>{" "}
            certifique-se de que todos os meses estejam com a situação "Regular"
            na Calculadora de DARF.
          </Typography.Paragraph>
        </Modal> */}
      </div>
    </ReportContainer>
  );
};
