import { message } from "antd";
import { useEffect, useState } from "react";
import apis from "../../../services/apiExterior";
import WarrenLogo from "../../../assets/warren-logo.svg";
import { useBroker } from "../../../contexts/BrokerContext";
import WarrenIntegrationButton from "./WarrenIntegrationButton";
import { WarrenIntegrationModal } from "./WarrenIntegrationModal";
import { IntegrationStatusExterior } from "../ExteriorIntegration";

interface IGetEstadoIntegracao {
  success: (data: any) => void;
  error: (data: any) => void;
}
interface IntegrationExteriorProps {
  item?: any;
  integrationCallback: (type: IntegrationStatusExterior) => void;
}

export const IntegrationExteriorWarren: React.FC<IntegrationExteriorProps> = ({
  integrationCallback,
}) => {
  const { setIntegrations } = useBroker();
  const [, setLoading] = useState(true);
  const [integrado, setIntegrado] = useState(false);
  const [integrationModalVisible, setIntegrationModalVisible] = useState(false);

  const getEstadoIntegracao = ({ success, error }: IGetEstadoIntegracao) =>
    apis
      .get("/warren/integration-status")
      .then(({ data }) => {
        setIntegrado(data.isIntegrated);
        success(data);
        setLoading(false);
        setIntegrations((integrations) => ({ ...integrations, warren: data }));
      })
      .catch((err) => {
        error(err);
      });

  useEffect(() => {
    getEstadoIntegracao({
      success: (data: any) => {
        if (data.isIntegrated) {
          setLoading(false);
          setIntegrado(true);
          integrationCallback("integrado");
        } else {
          integrationCallback("nao-integrado");
        }
      },
      error: () => {
        integrationCallback("erro");
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (integrationModalVisible) {
      interval = setInterval(
        () => {
          integrationCallback("carregando");
          getEstadoIntegracao({
            success: (data: any) => {
              if (data.estado === "carregando") {
                integrationCallback("carregando");
              }
              if (data.estado === "erro") {
                setLoading(false);
                setIntegrado(false);
                integrationCallback("erro");
                setIntegrationModalVisible(false);
                message.error(
                  "Algo deu errado na integração com a Warren, verifique suas credenciais"
                );
              }

              if (data.isIntegrated) {
                setLoading(false);
                setIntegrado(true);
                setIntegrationModalVisible(false);
                message.success("Integração feita com sucesso!");
                integrationCallback("integrado");
              }
            },
            error: (err: any) => {
              setLoading(false);
              integrationCallback("erro");
              setIntegrationModalVisible(false);
              if (err.response?.data?.message) {
                return message.error(err.response?.data?.message);
              }
              return message.error(
                "Algo deu errado na integração com a Warren, verifique suas credenciais"
              );
            },
          });
        },

        3000
      );
    } else {
      if (interval) {
        clearInterval(interval);
      }
    }
    return () => clearInterval(interval as unknown as NodeJS.Timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationModalVisible, integrado]);

  return (
    <>
      <WarrenIntegrationButton
        loading={false}
        imgHeight={true}
        logo={WarrenLogo}
        integrado={integrado}
        alt="Integrar com a Warren Exterior"
        setIntegrationModalVisible={setIntegrationModalVisible}
      />
      <WarrenIntegrationModal
        visible={integrationModalVisible}
        onCancel={() => {
          setIntegrationModalVisible(false);
        }}
      />
    </>
  );
};
