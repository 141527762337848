import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

interface IPrivateRouteProps {}

const PrivateRoute: React.FC<IPrivateRouteProps> = () => {
  const { pathname, search } = useLocation();
  const { signed, loadingSession } = useAuth();

  if (loadingSession) {
    return <></>;
  }

  if (signed) {
    return <Outlet />;
  } else {
    if (pathname === "/plano" && search === "?redirect=true") {
      return <Navigate to="/p/login?redirect=true" />;
    } else {
      return <Navigate to="/p/login" />;
    }
  }
};

export default PrivateRoute;
