import { Rnd } from "react-rnd";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import Button from "../Button";
import { PIPContainer } from "./styles";

interface PIPProps {
  src: string;
  show: boolean;
  handleClose: () => void;
}

const closeTagNames = ["path", "svg", "button"];

export const PIP = ({ src, show, handleClose }: PIPProps) => {
  const [pip, setPip] = useState({ dragging: false });
  return (
    <PIPContainer show={show}>
      <Rnd
        bounds="#root"
        minWidth={340}
        maxWidth={760}
        lockAspectRatio={16 / 9}
        lockAspectRatioExtraHeight={32}
        onDragStart={(e) => {
          if (closeTagNames.includes((e.target as HTMLElement)?.tagName)) {
            handleClose();
          }
          setPip((pip) => ({ ...pip, dragging: true }));
        }}
        onDragStop={(e, d) => {
          setPip((pip) => ({
            ...pip,
            dragging: false,
          }));
        }}
      >
        <div className="header">
          <div className="handler">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <Button type="text" icon={<AiOutlineClose />} onClick={handleClose} />
        </div>
        <div className="iframe-content">
          {pip.dragging && <div className="placeholder" />}
          {show && (
            <iframe
              id="video"
              src={src}
              width="560"
              height="315"
              frameBorder="0"
              allowFullScreen
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            />
          )}
        </div>
      </Rnd>
    </PIPContainer>
  );
};
