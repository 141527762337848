import styled from "styled-components";
import { DrawerModal } from "../../components/DrawerModal";

export const ModalStyled = styled(DrawerModal)`
`;

export const BolsaOperations = styled.div`
  margin-top: 20px;
  border-radius: 8px;
  color: var(--velotax-font-color-light);
  background-color: var(--velotax-ghost);
  border: 1px solid var(--velotax-background-color-ghost);

  div.strong span {
    font-weight: 700 !important;
  }
  div.opacity {
    opacity: 0.8;
  }

  span.dividendos-header {
    background-color: var(--velotax-background-color-ghost) !important;
    font-weight: 700 !important;
  }
  div.header,
  div.sub-header,
  div.row {
    display: grid;
    grid-template-columns: 0.7fr 1fr 0.5fr;
    span {
      text-align: center;
      justify-content: center;
    }
  }

  div.sub-header,
  div.row {
    height: auto;
    min-height: 48px;
    border-top: none;
    & + div.sub-header {
      border-top: 1px solid var(--velotax-background-color-ghost);
    }
    & > span + span {
      background-color: var(--velotax-background-color);
    }
    & > span {
      display: flex;
      align-items: center;
      border-left: none;
    }
    &:last-of-type > span:last-of-type {
      border-radius: 0 0 8px 0;
    }
  }

  div.header {
    padding: 16px;
    border-radius: 8px 8px 0 0;
    background-color: var(--velotax-background-color-ghost);
    span {
      font-weight: 700;
    }
    button {
      display: none;
    }
  }

  div.sub-header {
    font-size: 14px;
    & > span {
      font-weight: 300;
    }
  }

  div.row {
    opacity: 0.85;
    font-size: 13px;
    border-top: 1px solid var(--velotax-background-color-ghost);
  }

  @media only screen and (max-device-width: 812px) {
    div.sub-header {
      font-size: 12px;
    }
  }
`;

export const Box = styled.div`
  width: 100%;
  padding: 16px 32px;
  &,
  h4 {
    color: var(--velotax-font-color-light);
  }
  @media only screen and (max-device-width: 812px) {
    padding: 16px 0;
  }
`;
