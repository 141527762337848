import { message } from "antd";
import React, { useEffect, useState } from "react";
import IntegrationButton from "../IntegrationButton";
import apiIrpf2023 from "../../../services/apiIrpf2023";
import bitcoinTradeLogo from "../../../assets/mynt.svg";
import MyntIntegrationModal from "./MyntIntegrationModal";
import { useBroker } from "../../../contexts/BrokerContext";
import { IntegrationStatusExterior } from "../ExteriorIntegration";

interface IGetEstadoIntegracao {
  success: (data: any) => void;
  error: (data: any) => void;
}

interface IBitcoinTradeIntegrationProps {
  dependentId?: string;
  integrationCallback: (type: IntegrationStatusExterior) => void;
}

const MyntIntegration: React.FC<IBitcoinTradeIntegrationProps> = ({
  dependentId,
  integrationCallback,
}) => {
  const { setIntegrations } = useBroker();
  const [loading, setLoading] = useState(false);
  const [integrado, setIntegrado] = useState(false);
  const [integrationModalVisible, setIntegrationModalVisible] = useState(false);

  const getEstadoIntegracao = ({ success, error }: IGetEstadoIntegracao) =>
    apiIrpf2023
      .get("/operacoes/cripto/check")
      .then(({ data }) => {
        setIntegrado(data.isIntegrated);
        success(data);
        setLoading(false);
        setIntegrations((integrations) => ({ ...integrations, mynt: data }));
      })
      .catch((err) => {
        error(err);
      });

  useEffect(() => {
    getEstadoIntegracao({
      success: (data) => {
        if (data.isIntegrated) {
          setLoading(false);
          setIntegrado(true);
          integrationCallback("integrado");
        } else {
          integrationCallback("nao-integrado");
        }
      },
      error: () => {
        integrationCallback("erro");
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (integrationModalVisible) {
      interval = setInterval(
        () => {
          integrationCallback("carregando");
          getEstadoIntegracao({
            success: (data: any) => {
              if (data.estado === "carregando") {
                integrationCallback("carregando");
              }
              if (data.estado === "erro") {
                setLoading(false);
                setIntegrado(false);
                integrationCallback("erro");
                setIntegrationModalVisible(false);
                message.error(
                  "Algo deu errado na integração com a Mynt, verifique suas credenciais"
                );
              }

              if (data.isIntegrated) {
                setLoading(false);
                setIntegrado(true);
                setIntegrationModalVisible(false);
                message.success("Integração feita com sucesso!");
                integrationCallback("integrado");
              }
            },
            error: (err: any) => {
              setLoading(false);
              integrationCallback("erro");
              setIntegrationModalVisible(false);
              if (err.response?.data?.message) {
                return message.error(err.response?.data?.message);
              }
              return message.error(
                "Algo deu errado na integração com a Mynt, verifique suas credenciais"
              );
            },
          });
        },

        3000
      );
    } else {
      if (interval) {
        clearInterval(interval);
      }
    }
    return () => clearInterval(interval as unknown as NodeJS.Timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationModalVisible, integrado]);

  return (
    <>
      <IntegrationButton
        loading={loading}
        integrado={integrado}
        logo={bitcoinTradeLogo}
        alt="Integrar com a Mynt"
        style={{ height: "100%", width: "auto" }}
        setIntegrationModalVisible={setIntegrationModalVisible}
      />
      <MyntIntegrationModal
        dependentId={dependentId}
        visible={integrationModalVisible}
        setVisible={setIntegrationModalVisible}
        setLoadingIntegration={setLoading}
      />
    </>
  );
};

export default MyntIntegration;
