import { Col, Image, Row, Typography } from "antd";
import { DrawerModal, DrawerModalProps } from "../../DrawerModal";
import { ReactComponent as XpBlackLogo } from "../../../assets/xp-black.svg";
import xpIntegrationExample from "../../../assets/integracao_xp_exterior_fundo_branco.gif";

interface XpIntegrationModalProps extends DrawerModalProps { }

export const XpIntegrationModal: React.FC<XpIntegrationModalProps> = (
  props
) => {
  return (
    <DrawerModal
      {...props}
      title={
        <Row align="middle" gutter={16}>
          <Col>
            <XpBlackLogo />
          </Col>
          <Col>Integrar com a XP</Col>
        </Row>
      }
    >
      <Typography.Paragraph>
        Para integrar com a XP, realize os seguintes passos:
      </Typography.Paragraph>
      <ul style={{ listStyle: "inside" }}>
        <li>Acesse o aplicativo da XP</li>
        <li>
          Clique na aba <b>"Mercado Internacional"</b>
        </li>
        <li>
          Role até o fim da página e clique no botão <b>"IR/DARF"</b>
        </li>
        <li>
          Clique no botão <b>"Calcular Imposto de Renda"</b>
        </li>
        <li>
          Aceite os Termos de Uso e clique em <b>"Continuar"</b>
        </li>
      </ul>
      <div
        style={{
          display: "flex",
          width: "100%",
          margin: "2rem 0",
          justifyContent: "center",
        }}
      >
        <Image
          width={430}
          src={xpIntegrationExample}
        />
      </div>
      <Typography.Paragraph>
        Após realizar esses passos, sua integração será concluída. Você então
        poderá atualizar a página atual para confirmar que a conexão funcionou
        corretamente.
      </Typography.Paragraph>
    </DrawerModal>
  );
};
