import styled from "styled-components";

export const Container = styled.div`
  .ant-list {
    padding: 0;
    border-radius: 4px;
    background-color: var(--velotax-background-color-ghost);
    .ant-list-item {
      padding: 16px;
      &.error-border {
        border: 1px solid var(--ant-error-color);
      }
      :first-child {
        border-radius: 4px 4px 0 0;
      }
      :last-child {
        border-radius: 0 0 4px 4px;
      }
    }
    .error-border + .error-border {
      border-top: none;
    }
    .ant-list-item-action > li {
      padding: 0;
    }
  }
  .ant-list-item-action > li,
  .ant-list-item-meta-title,
  .ant-list-item-meta-description {
    color: var(--velotax-font-color);
  }
  .ant-list-item-meta-description {
    opacity: 0.75;
  }

  .alert-icon {
    fill: var(--ant-error-color) !important;
  }

  .row-margin {
    margin-top: 24px;
  }
`;
