import { AxiosInstance } from "axios";
import { GiProfit } from "react-icons/gi";
import { VscHistory } from "react-icons/vsc";
import { AiOutlineApi } from "react-icons/ai";
import { RiFileList3Line } from "react-icons/ri";
import { HiOutlineCalculator } from "react-icons/hi";
import { MdSwapVerticalCircle } from "react-icons/md";
import { B3AuthURL } from "../../utils";
import msDarf from "../../services/msDarf";
import apiBolsa from "../../services/apiBolsa";
import apiCrypto from "../../services/apiCrypto";
import apiExterior from "../../services/apiExterior";
import { ReactComponent as BolsaIcon } from "../../assets/icons/sidebar/bolsa.svg";
import { ReactComponent as CriptoIcon } from "../../assets/icons/sidebar/cripto.svg";
import { ReactComponent as ImoveisIcon } from "../../assets/icons/sidebar/imoveis.svg";
import { ReactComponent as ExteriorIcon } from "../../assets/icons/sidebar/exterior.svg";
import { ReactComponent as AutonomosIcon } from "../../assets/icons/sidebar/autonomos.svg";

interface HTML {
  favicon: string;
  title: string;
}

interface Input {
  name: string;
  label: string;
  placeholder: string;
  rules: any[];
}

interface Feature {
  apiUrl: string;
  title?: string;
  inputs?: Input[];
  disabled?: boolean;
  buttonLabel?: string;
}

export interface Page {
  month?: number;
  year?: number;
  calcId?: string;
  path: string;
  title: React.ReactNode;
  name: string;
  header: boolean;
  sidebar: boolean;
  features: Feature[];
  component?: string;
  disabled?: boolean;
  icon?: React.ReactNode;
  settings?: any;
  subPages?: Page[];
  api?: AxiosInstance;
  legacyHistory?: string;
  premium?: boolean;
  integratedOnly?: boolean;
  showOnSidebar?: boolean;
  mixedRoute?: boolean; // rota pública e privada ao mesmo tempo
  publicRoute?: boolean;
  publicWrapper?: boolean; // página privada com layout de pública
}

export interface Broker {
  id: string;
  dns: string;
  name: string;
  dark: boolean;
  link: string;
  path: string;
  apiUrl: string;
  html: HTML;
  pages: Page[];
  colors: { [key: string]: string };
  initialYear: number;
  icon: any;
  iconStyle?: any;
  iconAlternative?: any;
  iconAlternativeStyle?: any;
  disabled?: boolean;
  defaultPath?: string;
  defaultProviderExistsPath?: string;
  useSSO?: boolean;
  useCarteira?: boolean;
  useSettings?: boolean;
  hidePoweredBy?: boolean;
  hideJuridicMessage?: boolean;
  useTransferenciaCustodia?: boolean;
}

export const brokers: Broker[] = [
  {
    id: "crypto-velotax",
    name: "Velotax",
    dns: "wldemo.velotax",
    path: "velotax",
    apiUrl: "/bitcoin-trade",
    link: "https://velotax.com.br",
    dark: false,
    useSSO: true,
    useSettings: false,
    useCarteira: true,
    useTransferenciaCustodia: true,
    hideJuridicMessage: true,
    colors: {
      primary: "#1634ff",
      secondary: "#000058",
    },
    html: {
      favicon: "",
      title: "",
    },
    icon: null,
    iconStyle: {
      width: "auto",
      height: "32px",
    },
    initialYear: 2020,
    hidePoweredBy: true,
    iconAlternative: null,
    defaultPath: "/velotax/bolsa-integration",
    defaultProviderExistsPath: "/velotax/bolsa-historic",
    pages: [
      {
        calcId: "bolsa",
        integratedOnly: true,
        header: true,
        sidebar: true,
        path: "/velotax/bolsa",
        name: "Bolsa de valores",
        icon: <BolsaIcon className="stroke" />,
        title: "",
        api: apiBolsa,
        features: [],
        component: "Bolsa",
        showOnSidebar: true,
        subPages: [
          {
            premium: true,
            integratedOnly: true,
            header: true,
            sidebar: true,
            component: "ReportBolsa",
            path: "/velotax/bolsa-report",
            name: "Relatórios",
            title: "",
            icon: <RiFileList3Line size={24} className="no-fill" />,
            features: [
              {
                apiUrl: "/darf/report/transactions",
              },
              {
                apiUrl: "/darf/report/download",
              },
              {
                apiUrl: "/darf/report/sendEmail",
              },
            ],
          },
          {
            integratedOnly: true,
            header: true,
            sidebar: true,
            component: "HistoricBolsa",
            path: "/velotax/bolsa-historic",
            name: "Histórico",
            title: "",
            icon: <VscHistory size={24} className="no-fill" />,
            settings: {
              darfComponent: "DarfBolsa",
            },
            features: [
              {
                apiUrl: "/velotax/historic",
              },
              {
                apiUrl: "/darf/pay",
              },
              {
                apiUrl: "/darf/download",
              },
            ],
          },
          {
            premium: true,
            integratedOnly: true,
            header: true,
            sidebar: true,
            component: "DividendosBolsa",
            path: "/velotax/bolsa-dividendos",
            name: "Dividendos",
            title: "",
            icon: <GiProfit size={24} className="no-fill" />,
            settings: {
              darfComponent: "HistoricBolsa",
              darfUrl: "/velotax/bolsa-historic",
            },
            features: [
              {
                apiUrl: "/velotax/historic",
              },
            ],
          },
          {
            header: true,
            sidebar: true,
            publicWrapper: true,
            component: "IntegrationBolsa",
            path: "/velotax/bolsa-integration",
            name: "Integração com B3",
            title: "",
            icon: <AiOutlineApi size={24} className="no-fill" />,
            features: [
              {
                apiUrl: "/b3/integrate",
                buttonLabel: "Integrar",
                title:
                  "Clique para autorizar que a Velotax possa acessar suas informações",
              },
              {
                apiUrl: B3AuthURL,
                buttonLabel: "Permita o nosso app acessar suas transações!",
              },
              {
                apiUrl: "/b3/verify-user",
              },
            ],
          },
          {
            premium: true,
            integratedOnly: true,
            header: true,
            sidebar: true,
            component: "BolsaManual",
            path: "/velotax/bolsa-insert-manual",
            name: "Posição 31/12/2019",
            title: "",
            icon: <MdSwapVerticalCircle size={24} className="no-fill" />,
            features: [],
          },
          {
            integratedOnly: true,
            header: true,
            sidebar: true,
            component: "DoacoesHeranca",
            path: "/velotax/bolsa-doacoes-heranca",
            name: "Doações e heranças",
            title: "",
            icon: (
              <MdSwapVerticalCircle
                size={24}
                className="no-fill"
                color="var(--ant-primary-color)"
              />
            ),
            features: [],
          },
          {
            header: true,
            sidebar: true,
            publicWrapper: true,
            component: "UserHasAccount",
            path: "/velotax/bolsa-integration-user-has-account",
            name: "Integração com B3",
            title: "",
            icon: <AiOutlineApi size={24} className="no-fill" />,
            features: [],
          },
          {
            header: true,
            sidebar: true,
            publicWrapper: true,
            component: "UserNoHasAccount",
            path: "/velotax/bolsa-integration-user-no-has-account",
            name: "Integração com B3",
            title: "",
            icon: <AiOutlineApi size={24} className="no-fill" />,
            features: [],
          },
          {
            header: true,
            sidebar: true,
            publicWrapper: true,
            component: "UserForgotPasswordB3",
            path: "/velotax/forgot-password-b3",
            name: "Integração com B3",
            title: "",
            icon: <AiOutlineApi size={24} className="no-fill" />,
            features: [],
          },
        ],
      },
      {
        showOnSidebar: true,
        integratedOnly: true,
        header: true,
        calcId: "cripto",
        sidebar: true,
        name: "Criptoativos",
        path: "/velotax/cripto",
        icon: <CriptoIcon className="stroke" />,
        title: "",
        api: apiCrypto,
        component: "Cripto",
        legacyHistory: "https://www.velotax.com.br/historico/crypto",
        features: [],
        subPages: [
          {
            integratedOnly: true,
            header: true,
            sidebar: true,
            component: "HistoricCrypto",
            path: "/velotax/cripto-historic",
            name: "Histórico",
            title: "",
            icon: <VscHistory size={24} className="no-fill" />,
            settings: {
              darfComponent: "Darf",
              darfUrl: "/velotax/cripto-darf",
            },
            features: [
              {
                apiUrl: "/velotax/cripto/historic",
              },
              {
                apiUrl: "/darf/pay",
              },
              {
                apiUrl: "/darf/download",
              },
            ],
          },
          {
            header: true,
            sidebar: true,
            component: "IntegrationCrypto",
            path: "/velotax/cripto-integrations",
            name: "Integrações",
            title: "",
            icon: <AiOutlineApi size={24} className="no-fill" />,
            features: [],
          },
          {
            header: true,
            sidebar: true,
            component: "ReportCripto",
            path: "/velotax/cripto-report",
            name: "Relatórios",
            title: "",
            icon: <AiOutlineApi size={24} className="no-fill" />,
            features: [
              {
                apiUrl: "/darf/report/transactions",
              },
              {
                apiUrl: "/darf/report/download",
              },
              {
                apiUrl: "/darf/report/sendEmail",
              },
            ],
          },
        ],
      },
      {
        showOnSidebar: true,
        integratedOnly: true,
        calcId: "exterior",
        header: true,
        sidebar: true,
        name: "Ativos no exterior",
        path: "/velotax/exterior",
        icon: <ExteriorIcon className="stroke" />,
        title: "",
        api: apiExterior,
        legacyHistory: "https://www.velotax.com.br/historico/exterior",
        features: [],
        component: "Exterior",
        subPages: [
          {
            premium: true,
            integratedOnly: true,
            header: true,
            sidebar: true,
            component: "TransferenciaCustodia",
            path: "/velotax/transferencia-custodia",
            name: "Transferência de custódia",
            title: "",
            icon: <RiFileList3Line size={24} className="no-fill" />,
            features: [
              {
                apiUrl: "/darf/report/transactions",
              },
              {
                apiUrl: "/darf/report/download",
              },
              {
                apiUrl: "/darf/report/sendEmail",
              },
            ],
          },
          {
            premium: true,
            integratedOnly: true,
            header: true,
            sidebar: true,
            component: "ReportExterior",
            path: "/velotax/exterior-report",
            name: "Relatórios",
            title: "",
            icon: <RiFileList3Line size={24} className="no-fill" />,
            features: [
              {
                apiUrl: "/darf/report/transactions",
              },
              {
                apiUrl: "/darf/report/download",
              },
              {
                apiUrl: "/darf/report/sendEmail",
              },
              {
                apiUrl: "/velotax/exterior/historic"
              },
              {
                apiUrl: "/darf"
              }
            ],
          },
          {
            integratedOnly: true,
            header: true,
            sidebar: true,
            component: "Historic",
            path: "/velotax/exterior-historic",
            name: "Histórico",
            title: "",
            icon: <VscHistory size={24} className="no-fill" />,
            settings: {
              darfComponent: "DarfExterior",
              darfUrl: "/velotax/exterior-darf",
            },
            features: [
              {
                apiUrl: "/velotax/exterior/historic",
              },
              {
                apiUrl: "/darf/pay",
              },
              {
                apiUrl: "/darf/download",
              },
            ],
          },
          {
            premium: true,
            integratedOnly: true,
            header: true,
            sidebar: true,
            component: "DividendosExterior",
            path: "/velotax/exterior-dividendos",
            name: "Dividendos",
            title: "",
            icon: <GiProfit size={24} className="no-fill" />,
            settings: {
              darfComponent: "DarfExterior",
              darfUrl: "/velotax/exterior-darf",
            },
            features: [
              {
                apiUrl: "/velotax/exterior/historic",
              },
            ],
          },
          {
            header: true,
            sidebar: true,
            component: "IntegrationExterior",
            path: "/velotax/exterior-integrations",
            name: "Integrações",
            title: "",
            icon: <AiOutlineApi size={24} className="no-fill" />,
            features: [],
          },
        ],
      },
      {
        showOnSidebar: true,
        header: true,
        sidebar: true,
        calcId: "autonomos",
        name: "Carnê-leão",
        path: "/velotax/autonomos-historico",
        title: "",
        api: msDarf,
        component: "HistoricAutonomos",
        icon: <AutonomosIcon className="stroke" />,
        legacyHistory: "https://www.velotax.com.br/rendimentos/historico",
        settings: {
          darfComponent: "Rendimentos",
          historicUrl: "/velotax/autonomos-historico",
        },
        features: [
          {
            apiUrl: "/rendimentos/historic",
          },
          {
            apiUrl: "/darf/pay",
          },
          {
            apiUrl: "/darf/download",
          },
        ],
        subPages: [
          {
            header: true,
            sidebar: true,
            publicRoute: true,
            component: "NovoCadastro",
            title: "",
            name: "Cadastro",
            path: "/velotax/autonomos-cadastro",
            features: [],
            settings: {
              okText: "Próximo",
              title: "Carnê-leão",
              subTitle: "Seus impostos são calculados para o CPF informado",
            },
          },
          {
            header: true,
            sidebar: true,
            component: "Rendimentos",
            path: "/velotax/autonomos-darf",
            name: "Calculadora",
            icon: <HiOutlineCalculator size={24} className="no-fill" />,
            title: "",
            settings: {
              historicUrl: "/velotax/autonomos-historico",
            },
            features: [
              {
                apiUrl: "/transaction",
              },
              {
                apiUrl: "/darf",
                buttonLabel: "Emitir DARF",
              },
              {
                apiUrl: "/darf/year",
              },
            ],
          },
          {
            header: true,
            sidebar: true,
            component: "HistoricAutonomos",
            path: "/velotax/autonomos-historico",
            name: "Histórico",
            title: "",
            icon: <VscHistory size={24} className="no-fill" />,
            settings: {
              darfComponent: "DarfExterior",
              darfUrl: "/velotax/exterior-darf",
            },
            features: [
              {
                apiUrl: "/rendimentos/historic",
              },
              {
                apiUrl: "/darf/pay",
              },
              {
                apiUrl: "/darf/download",
              },
            ],
          },
        ],
      },
      {
        showOnSidebar: false,
        header: true,
        sidebar: true,
        calcId: "imoveis",
        name: "Venda de imóveis",
        path: "/velotax/imoveis",
        title: "",
        api: msDarf,
        component: "Imoveis",
        icon: <ImoveisIcon className="stroke" />,
        features: [],
        subPages: [
          {
            header: true,
            sidebar: true,
            component: "ImoveisCalculadora",
            path: "/velotax/imoveis-calculadora",
            name: "Calculadora",
            icon: <HiOutlineCalculator size={24} className="no-fill" />,
            title: "",
            features: [],
            settings: {
              historicUrl: "/velotax/imoveis-historico",
            },
          },
          {
            header: true,
            sidebar: true,
            settings: {
              darfComponent: "ImoveisCalculadora",
            },
            component: "Historic",
            path: "/velotax/imoveis-historico",
            name: "Histórico",
            icon: <VscHistory size={24} className="no-fill" />,
            title: "",
            features: [
              {
                apiUrl: "/imoveis/historic",
              },
              {
                apiUrl: "/darf/pay",
              },
              {
                apiUrl: "/darf/download",
              },
            ],
          },
          {
            header: true,
            sidebar: true,
            publicRoute: true,
            component: "NovoCadastro",
            title: "",
            name: "Cadastro",
            path: "/velotax/imoveis-cadastro",
            features: [],
            settings: {
              okText: "Próximo",
              title: "Venda de imóveis",
              subTitle: "Seus impostos são calculados para o CPF informado",
            },
          },
        ],
      },
      // {
      //   header: true,
      //   sidebar: true,
      //   features: [],
      //   name: "MEI",
      //   title: "MEI",
      //   calcId: "mei",
      //   path:
      //     process.env.REACT_APP_MEI_FRONT_URL ?? "https://mei.velotax.com.br",
      //   icon: (
      //     <MeiIcon
      //       width="28px"
      //       height="28px"
      //       style={{ marginLeft: "-4px" }}
      //       fill="var(--velotax-font-color-ghost)"
      //     />
      //   ),
      // },
    ],
  },
];
