import { Table, Typography } from "antd";
import { DrawerModal } from "../DrawerModal";
import Button from "../Button";
import { useNavigate } from "react-router-dom";

interface ModalZeroBuyPrice {
    title?: string;
    visible?: boolean;
    dataZeroBuyPrice: any[];
    setModalZeroBuyPrice: Function;
    setIgnoreZeroBuyPrice: Function;
}

export const ModalZeroBuyPrice: React.FC<ModalZeroBuyPrice> = ({
    title,
    visible,
    dataZeroBuyPrice,
    setModalZeroBuyPrice,
    setIgnoreZeroBuyPrice
}) => {
    const navigate = useNavigate();

    return (
        <DrawerModal
            footer={null}
            visible={visible}
            width={600}
            title={title}
            onCancel={() => setModalZeroBuyPrice(false)}
        >
            <Typography.Text>
                Neste mês, verificamos que alguns de seus ativos estão com custo de compra igual a zero.
            </Typography.Text>
            <Table
                style={{ marginTop: '20px', marginBottom: '20px' }}
                pagination={false}
                dataSource={dataZeroBuyPrice}
                locale={{ emptyText: "Nenhuma operação disponível" }}
                columns={[
                    {
                        align: "center",
                        title: "Ativo",
                        dataIndex: "code",
                        render: (value) => (
                            <strong style={{ fontWeight: "700" }}>{value}</strong>
                        ),
                    },
                    {
                        align: "center",
                        title: "Motivo",
                        dataIndex: "reason",
                        render: (value) => (
                            <>
                                {value?.text ?
                                    <>
                                        {value?.text}
                                        <a onClick={() => { navigate(value?.link) }} target="_blank" rel="noreferrer">
                                            {" "} {value?.textLink} {" "}
                                        </a>
                                    </>
                                    :
                                    <>
                                        Entre em contato através de
                                        <a href="mailto:suporte@velotax.com.br" target="_blank" rel="noreferrer">
                                            {" "} suporte@velotax.com.br {" "}
                                        </a>
                                        para avaliarmos a situação.
                                    </>
                                }
                            </>
                        ),
                    },
                ]}
            />
            <Button
                block
                size="large"
                type="primary"
                style={{
                    width: '100%',
                    display: 'flex',
                    textAlign: 'justify',
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    padding: '10px',
                    fontSize: '13px',
                    marginBottom: '20px'
                }}
                onClick={() => {
                    setIgnoreZeroBuyPrice(true);
                    setModalZeroBuyPrice(false);
                }}
            >
                ESTOU CIENTE. EMITIR DARF, MESMO COM O CUSTO DE COMPRA ZERADO.
            </Button>
        </DrawerModal>
    )
}