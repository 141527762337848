import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import MyntIntegration from "../MyntIntegration/index";
import BinanceIntegration from "../BinanceIntegration";
import { useBroker } from "../../../contexts/BrokerContext";
import BitcoinTradeIntegration from "../BitcoinTradeIntegration";
import MercadoBitcoinIntegration from "../MercadoBitcoinIntegration";
import { IntegrationStatusExterior } from "../ExteriorIntegration";
import GenericIntegration from "../GenericIntegration";

interface ICryptoIntegrationProps {
  id?: string;
  data?: any;
  dependentId?: string;
  setLoadingIntegration?: React.Dispatch<React.SetStateAction<boolean>>;
  setData?: ({ id, value, customData, from }: any, patch?: any) => void;
  customActions?: { [id: string]: (data: any) => void };
}

const CryptoIntegration = (props: ICryptoIntegrationProps, blockRedirect?: boolean) => {
  const navigate = useNavigate();
  const { currentBroker } = useBroker();
  const { id, dependentId, setData } = props;

  const handleIntegration = (type: IntegrationStatusExterior) => {
    setData?.({ id, value: type === "carregando" }, false);
    if (type === "carregando") return;
    else {
      props.setLoadingIntegration?.(false);
    }
  };

  return (
    <Row gutter={[8, 8]} justify="center" style={{ marginTop: "24px" }}>
      {/* <Col xs={14} sm={10}>
        <FoxbitIntegration
          dependentId={dependentId}
          integrationCallback={handleIntegration}
        />
      </Col> */}
      <Col xs={12} sm={8}>
        <MercadoBitcoinIntegration
          dependentId={dependentId}
          integrationCallback={handleIntegration}
        />
      </Col>
      {/* <Col xs={24} sm={12}>
        <BitcoinTradeIntegration
          dependentId={dependentId}
          integrationCallback={handleIntegration}
        />
      </Col> */}
      <Col xs={12} sm={8}>
        <MyntIntegration
          dependentId={dependentId}
          integrationCallback={handleIntegration}
        />
      </Col>
      <Col xs={12} sm={8}>
        <GenericIntegration/>
      </Col>
      {/* <Col xs={24} sm={12}>
        <BinanceIntegration integrationCallback={handleIntegration} />
      </Col> */}
    </Row>
  );
};

export default CryptoIntegration;
