import { HasPlan } from "../contexts/AuthContext";
import apiBolsa from "../services/apiBolsa";
import { callWebviewHandler, isIosPlatform } from "./webview";

export const B3AuthURL =
  "https://b3Investidor.b2clogin.com/b3Investidor.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_FINTECH&client_id=069ecfdf-7471-48eb-be82-d50799c99e5c&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fwww.investidor.b3.com.br&scope=openid&response_type=code&prompt=login";

export function isNotUndefOrNull(value: any) {
  if (value === null) return false;
  return typeof value !== 'undefined';
}

export const getQuizLabel = (username: string, message: string): string => {
  return username !== "Declarante"
    ? `${username} ${message}`
    : `Você ${message}`;
};

export const isSafari = /^((?!chrome|android).)*safari/i.test(
  navigator.userAgent
);

const oneSecond = 1000;
const oneMinute = oneSecond * 60;
const oneHour = oneMinute * 60;
export const oneDayTimestamp = oneHour * 24;

export const getReturnValues = (countDown: number) => {
  // calculate time left
  const days = countDown / oneDayTimestamp;
  const hours = Math.floor(days * 24);
  const minutes = Math.floor((countDown - hours * oneHour) / oneMinute);
  const seconds = Math.floor(
    (countDown - hours * oneHour - minutes * oneMinute) / oneSecond
  );
  return [hours, minutes, seconds];
};

export const isMobile = (): boolean => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
};

const iOSList = [
  "iPad Simulator",
  "iPhone Simulator",
  "iPod Simulator",
  "iPad",
  "iPhone",
  "iPod",
];

export const isIOS = () =>
  iOSList.includes(navigator.platform) ||
  iOSList.includes((navigator as any).userAgentData?.platform) ||
  navigator.userAgent.includes("Mac") ||
  (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

export const isExternalLink = (link: string): boolean => {
  return /http:\/\/|https:\/\//i.test(link);
};

export const errorMessage =
  "Algo inesperado aconteceu! Tente novamente mais tarde.";

export const tenteIntegrarNovamente = "Tente integrar novamente.";

export const reintegrateMessage =
  "Faça novamente a integração para buscar dados mais novos";

export const download = (link: string) => {
  if (isIosPlatform()) {
    callWebviewHandler("download_file_link", link);
    return;
  }
  const userAgentString = navigator.userAgent;
  let chromeAgent = userAgentString.indexOf("Chrome") > -1;
  let safariAgent = userAgentString.indexOf("Safari") > -1;
  if (chromeAgent && safariAgent) safariAgent = false;
  if (safariAgent) {
    window.location.href = link;
    return;
  } else {
    window.open(link);
  }
};

export const downloadPDF = (base64: string, where?: string) => {
  const linkSource = `data:application/pdf;base64,${base64}`;
  const downloadLink = document.createElement("a");
  const fileName = `Relatório${where ? `-${where}` : ""}-${Date.now()}.pdf`;

  if (isIosPlatform()) {
    callWebviewHandler("download_file_base64", {
      name: fileName,
      file: base64,
    });
    return;
  }

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const ativarDarfsRetroativos = ({
  item,
  hasPlan,
  currentYear,
  currentMonth,
  planMonthSignature,
  planYearSignature
}: {
  item: any;
  currentYear: number;
  currentMonth: number;
    planMonthSignature: number;
    planYearSignature: number; 
  hasPlan: false | HasPlan;
  }) => {
  return (hasPlan && hasPlan.premium) ||
  (hasPlan &&
    hasPlan.basic &&
    ((item.year > planYearSignature || (item.year === planYearSignature && item.month >= planMonthSignature)) ||
    (item.month === 0 &&
        item.year === item.year - 1 &&
          item.month === 12)));
}

export const planInfoToHasPlan = (planInfo: any) =>
  !!planInfo?.type && !!planInfo?.active
    ? {
        basic: !!planInfo?.type,
        premium: [
          "VELOTAX_MAIN_PRO",
          "VELOTAX_MAIN_PRO_MONTH",
          "VELOTAX_MAIN_CONCIERGE"
        ].includes(planInfo?.type),
        concierge: ["VELOTAX_MAIN_CONCIERGE"].includes(planInfo?.type),
      }
    : false;

export const mensagemDarfsRetroativos =
  "Contrate o plano Premium ou Concierge para emitir DARFs retroativos";

export const getZeroBuyPrice = async (data: any) => {
  let hasZero = [];
  const donations = await apiBolsa.get("/operation/donations?operationType=Credito", {});
  const pos2019 = await apiBolsa.get("/operation?month=12&year=2019", {});
  const elementsNotRepeat: string[] = [];

  for (const element of data) {
    const code = element?.code;
    const isTaxa = (code.toLowerCase()).includes('taxa');
    if (isTaxa) continue;
    if (elementsNotRepeat.includes(code)) continue;
    else if (!element?.buyPrice) elementsNotRepeat.push(code);
    else continue;
    const market = element?.market;
    const buyPrice = element?.buyPrice;
    if (market.includes('opções') || market.includes('Opção')) continue;
    const reasonDictionary = [
      { text: "Preencha o custo de compra do ativo na aba", textLink: "Doações e heranças", link: "/velotax/bolsa-doacoes-heranca" },
      { text: "Preencha o custo de compra do ativo na aba", textLink: "Posição 31/12/2019", link: "/velotax/bolsa-insert-manual" }
    ];
    const hasDonations: any[] = [];
    donations?.data?.filter((op: any) => {
      op?.orders?.filter((ord: any) => {
        if (code === ord?.code) {
          hasDonations.push(ord); 
          return;
        }
        else return;
      })
    })
    const hasPos2019: any[] = [];
    pos2019?.data?.filter((op: any) => {
      op?.orders?.filter((ord: any) => {
        if (code === ord?.code) {
          hasPos2019.push(ord); 
          return;
        }
        else return;
      })
    })
    const reason = hasDonations.length ? 0 : (hasPos2019.length ? 1 : "");
    if ((buyPrice == 0 || !buyPrice)
      && ((hasDonations.length > 0 && hasDonations[0]?.price == 0)
        || (hasPos2019.length > 0 && hasPos2019[0]?.price == 0))) {
      hasZero.push({ code: code, reason: reason !== "" ? reasonDictionary[reason] : "" });
    }
    else if ((buyPrice == 0 || !buyPrice) && hasDonations.length == 0 && hasPos2019.length == 0) {
      hasZero.push({ code: code, reason: "" });
    }
  }
  return hasZero;
}