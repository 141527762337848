import clsx from "clsx";
import { FaRegEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useState, useCallback, useMemo, ReactNode, useEffect } from "react";
import {
  Col,
  Collapse,
  Descriptions,
  Row,
  Space,
  Typography,
  Spin,
  Divider,
  Tooltip,
} from "antd";
import Button from "../Button";
import { DrawerModal } from "../DrawerModal";
import { formatCurrency } from "../../utils";
import darfBackend from "../../services/msDarf";
import imoveisBackend from "../../services/imoveis";
import { useAuth } from "../../contexts/AuthContext";
import { apiPayment } from "../../services/apiPayment";
import { PaymentModal } from "../../components/PaymentModal";
import { Container, Content, BolsaOperations } from "./styles";
import { YearResumeStatusFromBackEnum } from "../../constants/darf";
import {
  DarfImoveisTabs,
  ImpostoTotalImoveis,
} from "../../constants/darfImoveis";
import {
  YearResumeStatusCSSClassEnum,
  minDarfPrice,
} from "../../constants/darf";

const InfoTax = {
  title: "Isenção de IR para imóveis",
  content: (
    <div>
      <p>
        Venda de imóveis por valor igual ou menor a R$ 440.000,00 podem se
        beneficiar da isenção do imposto de renda.
      </p>
      <p>Para isso deve atender os seguintes requisitos:</p>
      <p>
        1º - O ímovel negociado deve ser o único pertecente ao contribuinte;
      </p>
      <p>
        2º - O contribuinte não pode ter efetuado operação semelhante e
        usufruído desse benefício nos últimos 5 anos.
      </p>
      <p>
        Venda de imóveis por valor igual ou menor a R$35.000,00 são isentas de
        IR.
      </p>
      <p>
        Caso não atenda a esses requisitos você deverá calcular e recolher o
        Imposto de Renda.
      </p>
    </div>
  ),
};

export const maxImoveisDarfPrice = 30000;
export const maxImoveisPixPayment = 30000;
export const maxImoveisCreditCardPayment = 30000;

export const DarfIMoveis: React.FC<any> = ({
  item,
  taxData,
  setTaxData,
  cep,
  valorVenda,
  valorAquisicao,
  custoCorretagem,
  docData,
  view,
  userCode,
  setIsForm,
  setCurrentQuestion,
}) => {
  const [loading, setLoading] = useState(false);
  const [helpModal, setHelpModal] = useState<any>();
  const [isYearResume, setYeatResume] = useState<any>(false);
  const [situacao, setSituacao] =
    useState<keyof typeof YearResumeStatusCSSClassEnum>();
  const [emited, setEmited] = useState(false);
  const { user, showUserPlanModal, userPlanModal } = useAuth();
  const [paymentData, setPaymentData] = useState<any>();

  const [paymentModal, setPaymentModal] = useState(false);
  const navigate = useNavigate();

  const impostoCharge = useMemo(() => {
    return (
      (taxData.impostoDevido ?? 0) +
      (taxData.juros ?? 0) +
      (taxData.multa ?? 0) +
      (taxData?.impostoAcumulado ?? 0)
    );
  }, [taxData]);

  const getYearResume = useCallback(() => {
    setYeatResume(true);
    imoveisBackend
      .get(`/imoveis/status?cep=${cep}&_id=${docData.imovelId ?? docData._id}`)
      .then(({ data }) => {
        const imovelInfo = data.imovel.find((el: any) => el.cep === cep);
        setSituacao(imovelInfo.status);
        setEmited(() => {
          return (
            situacao === YearResumeStatusFromBackEnum.PAYED ||
            situacao === YearResumeStatusFromBackEnum.NOT_PAYED
          );
        });
      })
      .catch((err) => console.log(err))
      .finally(() => setYeatResume(false));
  }, [cep, docData._id, docData.imovelId, situacao]);

  // const getImovel = useCallback(async () => {
  //   const { data } = await imoveisBackend.get(`/imoveis/get/${cep}`);
  //   setTaxData((currentValue: any) => {
  //     return {
  //       ...currentValue,
  //       impostoAcumulado: data.impostoAcumulado,
  //     };
  //   });
  // }, [setTaxData, cep]);

  useEffect(() => {
    getYearResume();
  }, [getYearResume]);

  const handleLabelButton: ReactNode = useMemo(() => {
    if ((taxData?.impostoDevido || 0) <= maxImoveisDarfPrice) {
      return "Pagar DARF";
    } else {
      if (emited) return "Emitir novo DARF";
      else return "Emitir DARF";
    }
  }, [emited, taxData?.impostoDevido]);

  const handleRegularize = (regular: boolean) => {
    setLoading(true);
    imoveisBackend
      .post("/transaction/regularize", {
        userCode: user.user.cpf,
        cep,
        regular,
      })
      .then(() => {
        getYearResume();
        navigate("/velotax/imoveis-historico");
      })
      .catch((err) => console.log("err", err))
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePlanModal = () => {
    showUserPlanModal(!userPlanModal);
  };

  const handleOpenPaymentModal = () => {
    const valueCharge =
      (taxData.impostoDevido ?? 0) +
      (taxData.impostoAcumulado ?? 0) +
      (taxData.juros ?? 0) +
      (taxData.multa ?? 0);

    setPaymentData({
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      cpf: user.user.cpf,
      cep: cep,
      _id: docData._id,
      name: user.user.name,
      paymentInfo: {
        clientInfo: {
          document: user.user.cpf,
          email: user.user.email,
          name: user.user.name,
          phone: "",
        },
      },
      paymentForm: "pix",
      valueCharge,
      totalImpostoDevido: taxData.impostoDevido,
      institution: "imoveis-velotax",
      impostoDevido: taxData.impostoDevido,
      impostoAcumulado: taxData.impostoAcumulado ?? 0,
      juros: taxData.juros,
      multa: taxData.multa,
      from: 18,
    });
    setPaymentModal(!paymentModal);
  };

  const handleOpenDarfModal = (value?: boolean) => {
    generateDarf(value ?? false);
  };

  const goToHistoric = () => {
    navigate("/velotax/imoveis-historico");
  };

  const handleEventDarfButton = async () => {
    let retPlanInfo = {
      data: {
        active: false,
        type: "",
      },
    };
    try {
      retPlanInfo = await apiPayment.get("/user-plan/plan-info");
    } catch (err) {
      console.error(err);
    }

    !retPlanInfo.data?.active && !retPlanInfo.data?.type
      ? handlePlanModal()
      : (taxData.impostoDevido || 0) <= maxImoveisDarfPrice
      ? handleOpenPaymentModal()
      : handleOpenDarfModal();
  };

  const generateDarf = async (pixValue: boolean) => {
    setLoading(true);
    const valueCharge =
      (taxData.impostoDevido ?? 0) +
      (taxData.impostoAcumulado ?? 0) +
      (taxData.juros ?? 0) +
      (taxData.multa ?? 0);

    const { data } = await darfBackend.post("/darf", {
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      cpf: user.user.cpf,
      cep: cep,
      _id: docData._id,
      name: user.user.name,
      paymentInfo: {
        clientInfo: {
          document: user.user.cpf,
          email: user.user.email,
          name: user.user.name,
          phone: "",
        },
      },
      insertPix: pixValue,
      valueCharge,
      totalImpostoDevido: taxData.impostoDevido,
      institution: "imoveis-velotax",
      impostoDevido: taxData.impostoDevido || 0,
      impostoAcumulado: taxData.impostoAcumulado || 0,
      juros: taxData.juros || 0,
      multa: taxData.multa || 0,
      from: 18,
    });

    window.location.href = data.darfUrl;
    setLoading(false);
    if (taxData.impostoTotal <= maxImoveisDarfPrice) {
      handleOpenPaymentModal();
    }
    goToHistoric();
  };

  const returnRightButton = () => {
    return (
      <Col span={24}>
        {!view && (
          <>
            <Divider />
            <Row justify="end" gutter={[16, 16]} className="column-reverse">
              <Col xs={24} sm={12}>
                <Button
                  block
                  size="large"
                  disabled={loading}
                  onClick={() => navigate("/velotax/imoveis-historico")}
                >
                  Ver no histórico
                </Button>
              </Col>
              {(taxData.impostoDevido ?? 0) + (taxData.impostoAcumulado ?? 0) <
                minDarfPrice && (
                <Col xs={24} sm={12}>
                  <Tooltip
                    title={
                      <>
                        O valor do imposto devido é menor que{" "}
                        {formatCurrency(minDarfPrice)}. Você não precisa emitir
                        um DARF para este mês. Clique aqui para regularizar este
                        mês.
                      </>
                    }
                  >
                    <Button
                      block
                      size="large"
                      type="primary"
                      loading={false}
                      disabled={loading}
                      onClick={() => handleRegularize(true)}
                    >
                      Salvar sem emitir DARF
                    </Button>
                  </Tooltip>
                </Col>
              )}
              {(taxData.impostoDevido ?? 0) + (taxData.impostoAcumulado ?? 0) >=
                minDarfPrice && (
                <Col xs={24} sm={12}>
                  <Button
                    block
                    size="large"
                    type="primary"
                    loading={false}
                    disabled={loading}
                    onClick={() => handleEventDarfButton()}
                  >
                    {handleLabelButton}
                  </Button>
                </Col>
              )}
            </Row>
          </>
        )}
      </Col>
    );
  };

  const onCloseHelpModal = () => {
    setHelpModal(undefined);
  };

  const editRedirect = () => {
    setCurrentQuestion(0);
    setIsForm(true);
  };

  return (
    <Container className={clsx("velotax-container", { view })}>
      <Content className={clsx("velotax-content", { view })}>
        <Space direction="vertical" className="full-width">
          <Row gutter={[16, 16]} align="middle">
            <Col span={24}>
              <Typography.Title level={1} className="page-title imovel">
                Imóvel no CEP {cep}
                {!view && <FaRegEdit size={18} onClick={editRedirect} />}
              </Typography.Title>
            </Col>
          </Row>
          <Row gutter={[16, 16]} align="middle" justify="space-between">
            {isYearResume ? (
              <Spin />
            ) : (
              <Col>
                <Typography.Text strong>Data da Venda:{"  "} </Typography.Text>
                <Typography.Text>
                  {"  "}
                  {`${docData.mesVenda < 10 ? "0" : ""}${docData.mesVenda}/${
                    docData.anoVenda
                  }`}
                </Typography.Text>
              </Col>
            )}
          </Row>
          <BolsaOperations>
            {DarfImoveisTabs(
              taxData,
              valorVenda,
              valorAquisicao,
              custoCorretagem
            ).map((container: any, rowIndex: any) => (
              <div key={rowIndex} className={container.class}>
                {container.tabs.map((item: any, colIndex: any) => (
                  <span key={colIndex} className={"show"}>
                    {item.content}
                  </span>
                ))}
              </div>
            ))}
          </BolsaOperations>
          <br />
          <Collapse
            ghost
            bordered={false}
            defaultActiveKey={[""]}
            expandIconPosition="right"
          >
            <Collapse.Panel
              key="1"
              header={
                <div className="total-tax-header">
                  <Typography.Title level={3}>Imposto total</Typography.Title>
                  <Typography.Title level={3} className="text-center">
                    {formatCurrency(impostoCharge)}
                  </Typography.Title>
                </div>
              }
            >
              <Descriptions size="small" bordered layout="horizontal">
                {ImpostoTotalImoveis.map((description) => (
                  <Descriptions.Item
                    span={24}
                    key={description.id}
                    label={description.label(setHelpModal)}
                  >
                    {loading ? (
                      <Spin />
                    ) : description.Component ? (
                      <description.Component
                        data={taxData}
                        setData={setTaxData}
                        loading={loading}
                        disabled={!view}
                        docData={docData}
                      />
                    ) : (
                      formatCurrency(
                        Number(taxData[description.id as keyof any] || 0)
                      )
                    )}
                  </Descriptions.Item>
                ))}
              </Descriptions>
            </Collapse.Panel>
          </Collapse>
          {returnRightButton()}
        </Space>
      </Content>
      {!view ? (
        <Content className="velotax-content second">
          <Typography.Paragraph>
            Recomendamos sempre que o usuário revise os valores antes de pagar
            qualquer imposto. Conforme os{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://velotax-termos-e-condicoes.s3.us-east-1.amazonaws.com/Termos%20de%20Uso%20-%20Velotax%20v1.pdf"
            >
              termos de uso
            </a>{" "}
            , não nos responsabilizamos por qualquer omissão de cálculos ou
            valores.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Seu imóvel foi vendido por menos de R$ 440 mil?{" "}
            <strong
              style={{ cursor: "pointer" }}
              onClick={() => setHelpModal(InfoTax)}
            >
              Clique aqui
            </strong>{" "}
            para saber se você pode estar isento de impostos.
          </Typography.Paragraph>
        </Content>
      ) : null}
      <PaymentModal
        item={item}
        disablePixOption={impostoCharge > maxImoveisPixPayment}
        disableCreditCardOption={impostoCharge > maxImoveisCreditCardPayment}
        show={paymentModal}
        onCancel={handleOpenPaymentModal}
        paymentData={{ ...paymentData, impostoTotalFinal: impostoCharge }}
        callDarf={(value: boolean) => handleOpenDarfModal(value)}
        customInstallments={12}
        maxPixValue={maxImoveisPixPayment}
        maxCCValue={maxImoveisCreditCardPayment}
      />
      <DrawerModal
        footer={null}
        visible={helpModal}
        title={helpModal?.title}
        onCancel={onCloseHelpModal}
      >
        {helpModal?.content}
      </DrawerModal>
    </Container>
  );
};
