import axios from "axios";

export const apiPayment = axios.create({
  baseURL:
    process.env.REACT_APP_PAYMENT_AUTH ||
    "http://localhost:4000",
  headers: {
    "x-api-provider": "crypto-velotax",
  },
});
