import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import Loading from "../Loading";
import { isMobile } from "../../utils";

export const ChartBar = ({
  monthReport,
  proventos,
  yearProventos,
  typeProventos,
  hideValues,
}: any) => {
  const [data, setDataChart] = useState([]);
  const [bars, setBars] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalDiv, setTotalDiv] = useState(0);
  const [totalJscp, setTotalJscp] = useState(0);
  const [totalBonds, setTotalBonds] = useState(0);
  const [totalRendi, setTotalRendi] = useState(0);
  const [totalAmort, setTotalAmort] = useState(0);

  const chartColors = [
    { background: "#1634ff", label: "white" },
    { background: "#C8D200", label: "black" },
    { background: "#FF2B01", label: "white" },
    { background: "#E50045", label: "white" },
    { background: "#CC13AF", label: "white" },
    { background: "#5B0084", label: "white" },
    { background: "#79002B", label: "white" },
    { background: "#0A9E5A", label: "white" },
    { background: "#FF7F00", label: "white" },
  ];

  const getProviderColor = (provider: string, index: number) => {
    while (index > 8) {
      const differnce = index - 8;
      index = differnce - 1;
    }
    return chartColors[index].background;
  };

  useEffect(() => {
    setLoading(true);
    let totalJscp = 0;
    let totalDiv = 0;
    let totalBonds = 0;
    let totalRendi = 0;
    let totalAmort = 0;
    if (typeProventos === "provento") {
      const proventoByMonth: any = [
        {
          name: "Jan",
          juros: 0,
          dividendo: 0,
          rendimento: 0,
          amortizacao: 0,
          bonds: 0,
        },
        {
          name: "Fev",
          juros: 0,
          dividendo: 0,
          rendimento: 0,
          amortizacao: 0,
          bonds: 0,
        },
        {
          name: "Mar",
          juros: 0,
          dividendo: 0,
          rendimento: 0,
          amortizacao: 0,
          bonds: 0,
        },
        {
          name: "Abr",
          juros: 0,
          dividendo: 0,
          rendimento: 0,
          amortizacao: 0,
          bonds: 0,
        },
        {
          name: "Mai",
          juros: 0,
          dividendo: 0,
          rendimento: 0,
          amortizacao: 0,
          bonds: 0,
        },
        {
          name: "Jun",
          juros: 0,
          dividendo: 0,
          rendimento: 0,
          amortizacao: 0,
          bonds: 0,
        },
        {
          name: "Jul",
          juros: 0,
          dividendo: 0,
          rendimento: 0,
          amortizacao: 0,
          bonds: 0,
        },
        {
          name: "Ago",
          juros: 0,
          dividendo: 0,
          rendimento: 0,
          amortizacao: 0,
          bonds: 0,
        },
        {
          name: "Set",
          juros: 0,
          dividendo: 0,
          rendimento: 0,
          amortizacao: 0,
          bonds: 0,
        },
        {
          name: "Out",
          juros: 0,
          dividendo: 0,
          rendimento: 0,
          amortizacao: 0,
          bonds: 0,
        },
        {
          name: "Nov",
          juros: 0,
          dividendo: 0,
          rendimento: 0,
          amortizacao: 0,
          bonds: 0,
        },
        {
          name: "Dez",
          juros: 0,
          dividendo: 0,
          rendimento: 0,
          amortizacao: 0,
          bonds: 0,
        },
      ];

      const groupedProventos = _.groupBy(proventos, (item) => {
        return moment(item.date).format("YYYY-MM");
      });

      for (const key in groupedProventos) {
        const year = +moment(key).format("Y");
        const month = +moment(key).format("M");
        const operations = groupedProventos[key];
        if (Number(year) === yearProventos) {
          operations.forEach((el: any) => {
            if (
              el?.corporateActionTypeDescription?.toLowerCase() === "rendimento"
            ) {
              proventoByMonth[month - 1].rendimento =
                proventoByMonth[month - 1]?.rendimento + el.grossAmount;
            }
            if (
              el?.corporateActionTypeDescription?.toLowerCase() ===
                "dividendo" ||
              el?.corporateActionTypeDescription?.toLowerCase() === "dividendos"
            ) {
              proventoByMonth[month - 1].dividendo =
                proventoByMonth[month - 1]?.dividendo + el.grossAmount;
            }
            if (
              el?.corporateActionTypeDescription?.toLowerCase() ===
              "juros sobre capital próprio"
            ) {
              proventoByMonth[month - 1].juros =
                proventoByMonth[month - 1]?.juros + el.grossAmount;
            }
            if (
              el?.corporateActionTypeDescription?.toLowerCase() ===
              "amortização"
            ) {
              proventoByMonth[month - 1].amortizacao =
                proventoByMonth[month - 1]?.amortizacao + el.grossAmount;
            }
            if (el?.corporateActionTypeDescription?.toLowerCase() === "cupom") {
              proventoByMonth[month - 1].bonds =
                proventoByMonth[month - 1]?.bonds + el.grossAmount;
            }
          });
        }
      }
      for (const el of proventoByMonth) {
        totalAmort += el.amortizacao || 0;
        totalJscp += el.juros || 0;
        totalDiv += el.dividendo || 0;
        totalBonds += el.bonds || 0;
        totalRendi += el.rendimento || 0;
      }

      setTotalAmort(totalAmort);
      setTotalBonds(totalBonds);
      setTotalDiv(totalDiv);
      setTotalJscp(totalJscp);
      setTotalRendi(totalRendi);
      setDataChart(proventoByMonth);
    }
    if (typeProventos === "corretora") {
      const proventoByMonth: any = [
        {
          name: "Jan",
        },
        {
          name: "Fev",
        },
        {
          name: "Mar",
        },
        {
          name: "Abr",
        },
        {
          name: "Mai",
        },
        {
          name: "Jun",
        },
        {
          name: "Jul",
        },
        {
          name: "Ago",
        },
        {
          name: "Set",
        },
        {
          name: "Out",
        },
        {
          name: "Nov",
        },
        {
          name: "Dez",
        },
      ];
      const barsChart: any = [];

      const groupedProventos = _.groupBy(proventos, (item) => {
        return item.corretora;
      });

      for (const key in groupedProventos) {
        barsChart.push(key);
        const operations = groupedProventos[key];
        operations.forEach((el: any) => {
          if (Number(moment(el.date).format("YYYY")) === yearProventos) {
            const month = Number(moment(el.date).format("M"));
            if (
              el?.corporateActionTypeDescription?.toLowerCase() === "rendimento"
            ) {
              proventoByMonth[month - 1][key] =
                (proventoByMonth[month - 1][key] || 0) + el.grossAmount;
            }
            if (
              el?.corporateActionTypeDescription?.toLowerCase() ===
                "dividendo" ||
              el?.corporateActionTypeDescription?.toLowerCase() === "dividendos"
            ) {
              proventoByMonth[month - 1][key] =
                (proventoByMonth[month - 1][key] || 0) + el.grossAmount;
            }
            if (
              el?.corporateActionTypeDescription?.toLowerCase() ===
              "juros sobre capital próprio"
            ) {
              proventoByMonth[month - 1][key] =
                (proventoByMonth[month - 1][key] || 0) + el.grossAmount;
            }
            if (
              el?.corporateActionTypeDescription?.toLowerCase() ===
              "amortização"
            ) {
              proventoByMonth[month - 1][key] =
                (proventoByMonth[month - 1][key] || 0) + el.grossAmount;
            }
            if (el?.corporateActionTypeDescription?.toLowerCase() === "cupom") {
              proventoByMonth[month - 1][key] =
                (proventoByMonth[month - 1][key] || 0) + el.grossAmount;
            }
          }
        });
      }

      setBars(barsChart);
      setDataChart(proventoByMonth);
    }
    if (typeProventos === "data") {
      const proventoByMonth: any = [
        {
          name: "Jan",
          futuro: 0,
          liquidado: 0,
        },
        {
          name: "Fev",
          futuro: 0,
          liquidado: 0,
        },
        {
          name: "Mar",
          futuro: 0,
          liquidado: 0,
        },
        {
          name: "Abr",
          futuro: 0,
          liquidado: 0,
        },
        {
          name: "Mai",
          futuro: 0,
          liquidado: 0,
        },
        {
          name: "Jun",
          futuro: 0,
          liquidado: 0,
        },
        {
          name: "Jul",
          futuro: 0,
          liquidado: 0,
        },
        {
          name: "Ago",
          futuro: 0,
          liquidado: 0,
        },
        {
          name: "Set",
          futuro: 0,
          liquidado: 0,
        },
        {
          name: "Out",
          futuro: 0,
          liquidado: 0,
        },
        {
          name: "Nov",
          futuro: 0,
          liquidado: 0,
        },
        {
          name: "Dez",
          futuro: 0,
          liquidado: 0,
        },
      ];
      const types = [
        "rendimento",
        "juros sobre capital próprio",
        "dividendo",
        "dividendos",
        "amortização",
        "cupom",
        "cupom",
      ];

      proventos?.forEach((el: any) => {
        const year = Number(moment(el.date).format("Y"));
        if (
          year === yearProventos &&
          types.includes(el.corporateActionTypeDescription.toLowerCase())
        ) {
          const month = Number(moment(el.date).format("M"));
          if (el.isFuturo) {
            proventoByMonth[month - 1].futuro += el.grossAmount;
          } else {
            proventoByMonth[month - 1].liquidado += el.grossAmount;
          }
        }
      });
      setDataChart(proventoByMonth);
    }
    setLoading(false);
  }, [yearProventos, typeProventos, proventos]);

  const renderLegend = (value: string, entry: any) => {
    return <span style={{ color: "black", fontWeight: 400 }}>{value}</span>;
  };

  const labelFormatter = (value: any) => {
    return value > 0
      ? hideValues
        ? "R$ ***"
        : "R$ " +
          value?.toLocaleString?.("pt-br", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
      : "";
  };

  const renderTooltip = (value: any) => {
    const payload = value?.payload || [];

    const verificacaoValorFinal = payload.reduce(
      (acumulador: any, item: any) => {
        if (item.value !== undefined) {
          return acumulador + item.value;
        }
        return acumulador;
      },
      0
    );

    if (
      typeProventos === "provento" &&
      payload?.[0] &&
      verificacaoValorFinal !== 0
    ) {
      const total =
        payload[0]?.payload?.dividendo +
        payload[0]?.payload?.rendimento +
        payload[0]?.payload?.juros +
        payload[0]?.payload?.amortizacao +
        payload[0]?.payload?.bonds;
      return (
        <>
          <div className="container-tooltip">
            {payload[0]?.payload?.dividendo > 0 && (
              <span style={{ fontSize: "12px", color: "black" }}>
                Dividendos: R${" "}
                {payload[0]?.payload?.dividendo?.toLocaleString?.("pt-br", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                (
                {total > 0
                  ? ((payload[0]?.payload?.dividendo * 100) / total).toFixed(2)
                  : 0}
                %)
              </span>
            )}
            {payload[0]?.payload?.rendimento > 0 && (
              <span style={{ fontSize: "12px", color: "black" }}>
                Rendimento: R${" "}
                {payload[0]?.payload?.rendimento?.toLocaleString?.("pt-br", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                (
                {total > 0
                  ? ((payload[0]?.payload?.rendimento * 100) / total).toFixed(2)
                  : 0}
                %)
              </span>
            )}
            {payload[0]?.payload?.juros > 0 && (
              <span style={{ fontSize: "12px", color: "black" }}>
                Juros sobre capital próprio: R${" "}
                {payload[0]?.payload?.juros?.toLocaleString?.("pt-br", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                (
                {total > 0
                  ? ((payload[0]?.payload?.juros * 100) / total).toFixed(2)
                  : 0}
                %)
              </span>
            )}
            {payload[0]?.payload?.amortizacao > 0 && (
              <span style={{ fontSize: "12px", color: "black" }}>
                Amortização: R${" "}
                {payload[0]?.payload?.amortizacao?.toLocaleString?.("pt-br", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                (
                {total > 0
                  ? ((payload[0]?.payload?.amortizacao * 100) / total).toFixed(
                      2
                    )
                  : 0}
                %)
              </span>
            )}
            {payload[0]?.payload?.bonds > 0 && (
              <span style={{ fontSize: "12px", color: "black" }}>
                Cupom: R${" "}
                {payload[0]?.payload?.bonds?.toLocaleString?.("pt-br", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                (
                {total > 0
                  ? ((payload[0]?.payload?.bonds * 100) / total).toFixed(2)
                  : 0}
                %)
              </span>
            )}
          </div>
        </>
      );
    }
    if (typeProventos === "corretora" && payload?.[0]) {
      const keys = Object.keys(payload[0]?.payload || {});
      let total = 0;
      keys.forEach((el) => {
        if (el !== "name") {
          total += payload[0]?.payload[el] || 0;
        }
      });

      return (
        <div className="container-tooltip">
          {keys.map((el) => {
            if (el !== "name") {
              return (
                <span style={{ fontSize: "12px", color: "black" }}>
                  {el}: R${" "}
                  {payload[0]?.payload[el]?.toLocaleString?.("pt-br", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                  (
                  {total > 0
                    ? ((payload[0]?.payload[el] * 100) / total).toFixed(2)
                    : 0}
                  %)
                </span>
              );
            }
            return <></>;
          })}
        </div>
      );
    }
    if (typeProventos === "data" && payload?.[0]) {
      const total =
        payload[0]?.payload?.futuro + payload[0]?.payload?.liquidado;
      return (
        <>
          <div className="container-tooltip">
            <span style={{ fontSize: "12px", color: "black" }}>
              Futuro: R${" "}
              {payload[0]?.payload?.futuro?.toLocaleString?.("pt-br", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              (
              {total > 0
                ? ((payload[0]?.payload?.futuro * 100) / total).toFixed(2)
                : 0}
              %)
            </span>
            <span style={{ fontSize: "12px", color: "black" }}>
              Liquidado: R${" "}
              {payload[0]?.payload?.liquidado?.toLocaleString?.("pt-br", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              (
              {total > 0
                ? ((payload[0]?.payload?.liquidado * 100) / total).toFixed(2)
                : 0}
              %)
            </span>
          </div>
        </>
      );
    }
    return <></>;
  };
  return loading ? (
    <Loading />
  ) : (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={data}
        margin={{
          top: 40,
          left: -60,
        }}
      >
        <XAxis dataKey="name" style={{ fontSize: "10px" }} />
        <YAxis axisLine={false} tick={false} />
        <Tooltip content={renderTooltip} />
        <Legend
          style={{ fill: "white" }}
          align="center"
          layout="horizontal"
          iconType="circle"
          verticalAlign="bottom"
          formatter={renderLegend}
        />
        {typeProventos === "provento" && (
          <>
            {totalJscp > 0 && (
              <Bar
                dataKey="juros"
                stackId="a"
                fill="var(--ant-error-color-active)"
                name="Juros sobre capital próprio"
                onClick={monthReport}
              />
            )}
            {totalRendi > 0 && (
              <Bar
                dataKey="rendimento"
                stackId="a"
                fill="#ff7a00"
                name="Rendimento"
                onClick={monthReport}
              />
            )}
            {totalDiv > 0 && (
              <Bar
                dataKey="dividendo"
                stackId="a"
                fill="var(--ant-primary-color)"
                name="Dividendo"
                onClick={monthReport}
              />
            )}
            {totalAmort > 0 && (
              <Bar
                dataKey="amortizacao"
                stackId="a"
                fill="#4B0082"
                name="Amortização"
                onClick={monthReport}
              />
            )}
            {totalBonds > 0 && (
              <Bar
                dataKey="bonds"
                stackId="a"
                fill="#CC13AF"
                name="Cupom"
                onClick={monthReport}
              />
            )}
            {!isMobile() && (
              <Bar
                dataKey=""
                stackId="a"
                fill="transparent"
                name=""
                label={{ position: "top", formatter: labelFormatter }}
              />
            )}
          </>
        )}
        {typeProventos === "corretora" && (
          <>
            {bars.map((el, index) => {
              return (
                <Bar
                  dataKey={el}
                  stackId="a"
                  fill={getProviderColor("", index)}
                  name={el}
                  onClick={monthReport}
                />
              );
            })}
            {!isMobile() && (
              <Bar
                dataKey=""
                stackId="a"
                fill="transparent"
                name=""
                label={{ position: "top", formatter: labelFormatter }}
              />
            )}
          </>
        )}
        {typeProventos === "data" && (
          <>
            <Bar
              dataKey="futuro"
              stackId="a"
              fill="var(--ant-error-color-active)"
              onClick={monthReport}
              name="Futuro"
            />
            <Bar
              dataKey="liquidado"
              stackId="a"
              fill="#ff7a00"
              name="Liquidado"
              onClick={monthReport}
            />
            {!isMobile() && (
              <Bar
                dataKey=""
                stackId="a"
                fill="transparent"
                name=""
                label={{ position: "top", formatter: labelFormatter }}
              />
            )}
          </>
        )}
      </BarChart>
    </ResponsiveContainer>
  );
};
