import { UserPlanEnum } from "../../constants/plans";
import { HasPlan } from "../../contexts/AuthContext";

export const planos = [
  {
    type: UserPlanEnum.VELOTAX_MAIN_BASIC,
    name: "Basic",
    price: 25.9,
    originalPrice: 25.9,
    installments: 12,
    for: "Pra quem começou a investir agora",
    includes: "Basic inclui:",
    benefits: [
      "Cálculos automáticos de IR",
      "Emissão de DARF do mês corrente",
    ],
  },
  {
    type: UserPlanEnum.VELOTAX_MAIN_PRO,
    name: "Premium",
    price: 59.9,
    originalPrice: 59.9,
    installments: 12,
    for: "Pra quem investe com maior frequência",
    includes: "Tudo do Basic, mais:",
    benefits: [
      "DARFs retroativos ilimitados",
      "Carteira de investimentos atualizada",
      "Notificações de dividendos",
      "Isentômetro de impostos",
      "Informes de rendimento em PDF",
    ],
  },
  {
    type: UserPlanEnum.VELOTAX_MAIN_CONCIERGE,
    name: "Concierge",
    price: 79.9,
    originalPrice: 79.9,
    installments: 12,
    for: "Pra quem não quer ter nenhum trabalho",
    includes: "Tudo do Premium, mais:",
    benefits: [
      "Especialista dedicado para você",
      "DARFs revisadas por profissional",
      "Contato por WhatsApp exclusivo",
    ],
    popular: true,
  },
];

export enum UserPlanUpgradeEnum {
  BASIC_TO_PRO_VELOTAX = "BASIC_TO_PRO_VELOTAX",
  BASIC_TO_CONCIERGE_VELOTAX = "BASIC_TO_CONCIERGE_VELOTAX",
  PRO_TO_CONCIERGE = "PRO_TO_CONCIERGE",
}

export const planUpgrade = {
  [UserPlanUpgradeEnum.BASIC_TO_PRO_VELOTAX]: 34,
  [UserPlanUpgradeEnum.BASIC_TO_CONCIERGE_VELOTAX]: 54,
  [UserPlanUpgradeEnum.PRO_TO_CONCIERGE]: 20,
};

export const getPlanPrice = (
  plan: { price: number; type: UserPlanEnum },
  hasPlan: false | HasPlan
) =>
  !hasPlan
    ? plan?.price
    : hasPlan.premium && plan?.type === UserPlanEnum.VELOTAX_MAIN_CONCIERGE
    ? planUpgrade[UserPlanUpgradeEnum.PRO_TO_CONCIERGE]
    : !hasPlan.premium && plan?.type === UserPlanEnum.VELOTAX_MAIN_PRO
    ? planUpgrade[UserPlanUpgradeEnum.BASIC_TO_PRO_VELOTAX]
    : !hasPlan.premium && plan?.type === UserPlanEnum.VELOTAX_MAIN_CONCIERGE
    ? planUpgrade[UserPlanUpgradeEnum.BASIC_TO_CONCIERGE_VELOTAX]
    : plan?.price;
