import styled from "styled-components";

export const BlurContent: any = styled.div`
  .recharts-responsive-container {
    filter: ${(props: any) => (!props.hasPlan ? "blur(10px)" : "")};
  }
  height: 100%;
  margin-top: 8px;
  position: relative;
  user-select: none;
  border-radius: 4px;
  .blur-content {
    opacity: 0.5;
    filter: blur(4px);
  }
  .blur-content-title {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 100px;
    font-weight: 500;
    font-size: 1.25rem;
    text-align: center;
    row-gap: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: var(--velotax-font-color);
  }

  @media only screen and (max-device-width: 812px) {
    .blur-content-title {
      padding: 0 16px;
      font-size: 1rem;
    }
  }
`;

export const ContainerProventos: any = styled.div`
  @media only screen and (max-device-width: 812px) {
    .recharts-responsive-container
      .recharts-legend-wrapper
      .recharts-legend-item
      .recharts-legend-item-text {
      max-width: 100%;
    }
  }
  height: 400px;
  width: 100%;
  .recharts-tooltip-wrapper {
    box-shadow: 0px 8px 24px rgba(0, 0, 88, 0.08);
  }
  .container-tooltip {
    display: flex;
    flex-direction: column;
    background-color: white;
    margin-top: 5px;
    padding: 5px;
  }
  .recharts-default-legend {
    display: inline !important;
    li {
      margin-right: 15px !important;
    }
  }
  .recharts-bar-rectangle {
    cursor: pointer;
  }
  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }

  .recharts-surface {
    margin-left: 0px !important;
  }

  .recharts-legend-wrapper {
    left: 10px !important;
  }

  .recharts-responsive-container .recharts-wrapper .recharts-legend-wrapper {
    width: 100% !important;
  }

  .recharts-layer > text {
    font-size: 12px;
    font-weight: 400;
  }
  .ant-btn {
    border-radius: 10px;
    align-items: center;
    display: flex;
  }
  .filter-proventos {
    width: 100%;
    display: flex;
    margin-bottom: 16px;
    button.ant-btn.ant-btn-default {
      height: 40px;
      svg.fill {
        fill: var(--ant-primary-color);
      }
    }
    .ant-select {
      height: 38px;
      font-size: 16px;
      margin-right: 8px;
      .ant-select-selector {
        height: 38px;
      }
    }
    .ant-select:not(:first-of-type) {
      margin-right: auto;
    }
    .proventos-btns {
      display: flex;
      column-gap: 8px;
      align-items: center;
      button.ant-btn {
        min-width: 32px;
        font-size: 14px;
        font-weight: 500;
        padding: 5px 12px;
        border-radius: 4px;
        border-radius: 24px;
        display: inline-flex;
        height: 38px !important;
        background-color: var(--white);
        border: 1px solid var(--light-gray) !important;
        svg {
          width: 18px;
          height: 18px;
          fill: var(--ant-primary-color);
          stroke: var(--ant-primary-color);
          &.fill {
            path {
              stroke: var(--ant-primary-color);
            }
          }
        }
      }
    }
  }
  @media only screen and (max-device-width: 812px) {
    .filter-proventos {
      flex-wrap: wrap;
      .ant-select:first-of-type {
        width: 25%;
      }
      .ant-select:not(:first-of-type) {
        margin-right: 0;
        width: calc(75% - 8px);
      }
      .proventos-btns {
        width: 100%;
        margin-top: 8px;
        button.ant-btn.ant-btn-text {
          width: 50%;
        }
      }
    }
  }
`;

export const Content = styled.div`
  width: 100% !important;
  .ant-tooltip {
    z-index: 999;
  }
  &.velotax-content {
    box-shadow: none !important;
    padding: 0 4px 64px !important;
  }
  .range {
    display: flex;
    justify-content: space-between;

    div.ant-typography {
      font-size: 13px;
    }
  }
  .isentometro {
    margin-bottom: 5px;
    font-size: 14px;
  }
  .box {
    margin: 50px 0px;
  }
  .overview {
    color: var(--ant-primary-color);
    font-size: 18px;
    font-weight: bold;
  }
  .ant-progress-success-bg {
    background-color: var(--ant-primary-color);
    height: 13px !important;
  }
  .ant-progress-inner {
    height: 13px !important;
  }
  .ant-progress-bg {
    height: 13px !important;
  }
  & > .ant-collapse {
    border: none;
    & > .ant-collapse-item.ativos-categoria {
      margin-bottom: 48px;
      &:last-of-type {
        margin-bottom: 0;
      }
      & > .ant-collapse-header {
        padding: 0;
        align-items: center;
        .ant-collapse-arrow {
          right: 0;
        }
        h2 {
          margin: 0;
          font-weight: 700;
          font-size: 1.125rem;
          line-height: 1.5rem;
          color: var(--ant-primary-color);
        }
        .collapse-title-divider {
          flex-grow: 1;
          display: block;
          margin: 0 16px;
          border-bottom: 2px solid var(--light-gray);
        }
        .collapse-title-hidden {
          width: 24px;
          height: 24px;
          display: block;
          visibility: hidden;
        }
      }
      & > .ant-collapse-content {
        border: none;
        background-color: transparent;
        & > .ant-collapse-content-box {
          padding: 24px 0 0;
          & > .ant-collapse {
            border: none;
          }
          & > div {
            font-size: 13px;
          }
        }
      }
    }
  }

  @media only screen and (max-device-width: 812px) {
    width: 100%;
    margin-bottom: 0;
    border-radius: 0;
    &.velotax-content {
      padding: 24px 0 96px !important;
    }

    h1,
    h2 {
      padding: 0;
    }
  }
`;
