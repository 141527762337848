import React, { useState } from "react";
import { SpreadsheetModal } from "./SpreadsheetModal/index";
import { Container } from "../IntegrationButton/styles";
import { Tooltip, Typography } from "antd";
import { BsFileSpreadsheet } from "react-icons/bs";

interface IGenericIntegrationProps {}

const GenericIntegration: React.FC<IGenericIntegrationProps> = ({}) => {
    const [modalSpreadsheet, setModalSpreadsheet] = useState(false);

    return (
        <>
            <Tooltip
                title={"Clique para integrar"}
            >
                <Container
                    onClick={() => setModalSpreadsheet(true)}
                    imgHeight={true}
                >
                    <div className="icon-spreadsheet">
                        <BsFileSpreadsheet size={23}></BsFileSpreadsheet>
                    </div>
                    <Typography.Paragraph className="click-here-generic">
                        <strong>Integração </strong>
                    </Typography.Paragraph>
                    <Typography.Paragraph className="click-here-generic"> via planilha</Typography.Paragraph>
                </Container>
            </Tooltip>
            <SpreadsheetModal visible={modalSpreadsheet} setModalSpreadsheet={setModalSpreadsheet}></SpreadsheetModal>
        </>
    );
};

export default GenericIntegration;
