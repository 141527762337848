import { Image } from "antd";
import { useNavigate } from "react-router-dom";
import { LogoStyled } from "./styles";
import { useBroker } from "../../contexts/BrokerContext";
import VelotaxLogo from "../../assets/velotax/velotax-light.svg";

export const Logo: React.FC = () => {
  const { currentBroker } = useBroker();
  const navigate = useNavigate();
  return (
    <LogoStyled>
      <button onClick={() => navigate("/carteira")} type="button">
        <Image
          preview={false}
          alt={currentBroker?.name || "Velotax logo light"}
          style={currentBroker?.iconStyle || {}}
          src={
            currentBroker.iconAlternative
              ? currentBroker.iconAlternative
              : currentBroker?.icon
              ? currentBroker?.icon
              : VelotaxLogo
          }
        />
      </button>
    </LogoStyled>
  );
};
