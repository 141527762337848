import { Drawer, Typography } from "antd";
import { AiOutlineArrowLeft, AiOutlineDownload } from "react-icons/ai";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import Button from "../Button";
import { isMobile } from "../../utils";
import { ReportTypeContainer } from "./styles";

interface IProps {
  open: boolean;
  loading?: boolean;
  setType: Function;
  onClose:
    | ((
        e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>
      ) => void)
    | undefined;
}

export const ReportTypeDrawer = ({
  open,
  loading,
  setType,
  onClose,
}: IProps) => {
  return (
    <Drawer
      visible={open}
      placement="right"
      onClose={onClose}
      closeIcon={<AiOutlineArrowLeft />}
      title={
        <div onClick={onClose} style={{ cursor: "pointer" }}>
          Voltar
        </div>
      }
      {...(isMobile() ? { width: "100%" } : { width: "420px" })}
    >
      <ReportTypeContainer>
        <Typography.Title level={4}>Baixar</Typography.Title>
        <div>
          <Typography.Text style={{ fontSize: "14px" }}>
            Qual tipo de arquivo deseja baixar?
          </Typography.Text>
        </div>
        <div className="textInfo">
          <InfoCircleOutlined style={{ marginTop: "5px" }} />
          <span>
            {" "}
            O arquivo baixado mostrará suas informações de acordo com os filtros
            selecionados no momento do download.
          </span>
        </div>
        <Button
          block
          size="large"
          type="primary"
          disabled={loading}
          className="buttonDownload"
          icon={<AiOutlineDownload />}
          onClick={() => setType("pdf")}
        >
          <span>
            Arquivo em <b> PDF </b>
            {!isMobile() ? " para simples conferência de dados." : ""}
          </span>
        </Button>
        <Button
          block
          size="large"
          type="primary"
          disabled={loading}
          className="buttonDownload"
          icon={<AiOutlineDownload />}
          onClick={() => setType("xlsx")}
        >
          <span>
            Arquivo em <b> Excel </b>
            {!isMobile() ? " para ser importado em planilhas." : ""}
          </span>
        </Button>
        {loading && (
          <LoadingOutlined
            style={{
              position: "absolute",
              top: "60%",
              left: "45%",
              color: "var(--ant-primary-color)",
            }}
          ></LoadingOutlined>
        )}
      </ReportTypeContainer>
    </Drawer>
  );
};
