import styled from "styled-components";

export const Container = styled.div`
  &.velotax-container {
    height: 100%;
    padding: 64px 32px;
    .velotax-content {
      min-height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .top-content {
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
        .success {
          width: 80px;
          height: 80px;
          display: flex;
          margin-top: 64px;
          position: relative;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
          background-color: var(--ant-primary-color);
          :after,
          :before {
            content: "";
            position: absolute;
            border-radius: 50%;
            background-color: var(--ant-primary-color);
          }
          :before {
            top: -16px;
            left: -16px;
            width: 112px;
            height: 112px;
            opacity: 0.1;
          }
          :after {
            top: -28px;
            left: -28px;
            width: 136px;
            height: 136px;
            opacity: 0.08;
          }
          svg {
            width: 40px;
            height: 40px;
            fill: var(--white);
          }
        }
        h1 {
          font-size: 24px;
          font-weight: 700;
          line-height: 32px;
          font-style: normal;
          margin: 48px 0 8px;
          letter-spacing: -0.24px;
        }
        div.ant-typography {
          font-size: 16px;
          font-weight: 400;
          max-width: 450px;
          line-height: 24px;
          font-style: normal;
          text-align: center;
          margin-bottom: 40px;
          color: var(--dark-gray);
        }
        .ant-btn-primary {
          width: 200px;
        }
      }
      .footer {
        opacity: 0.6;
        display: flex;
        align-items: center;
        justify-content: center;
        .ant-typography {
          display: block;
          font-size: 11px;
          max-width: 400px;
          text-align: center;
          strong {
            font-weight: 500;
            white-space: nowrap;
          }
        }
      }
    }
  }

  @media only screen and (max-device-width: 812px) {
    &.velotax-container {
      height: auto;
      min-height: 100%;
      .velotax-content {
        .footer {
          margin-top: 48px;
        }
      }
    }
  }
`;
