import { Typography } from "antd";
import { GiProfit } from "react-icons/gi";
import { RiFileList3Line } from "react-icons/ri";
import { HiOutlineCalculator } from "react-icons/hi";
import { isMobile } from "../../utils";
import { ReactComponent as IntegrationIcon } from "../../assets/icons/integration.svg";
import WarrenLogo from "../../assets/warren-logo.svg";
import XP_black_logo from "../../assets/xp-black.svg";
import SprotifiLogo from "../../assets/sprotifi-logo.svg";
import { BiTransferAlt } from "react-icons/bi";

export const itensExteriorSemLogo = [
  {
    id: "darf",
    premium: true,
    link: "/velotax/exterior-historic",
    content: <Typography>Calculadora de DARF</Typography>,
    description: <Typography>Cálculo de IR e emissão de DARF</Typography>,
    icon: (
      <HiOutlineCalculator
        className="no-fill"
        color="var(--ant-primary-color)"
      />
    ),
  },
  {
    id: "report",
    basic: true,
    premium: true,
    link: "/velotax/exterior-report",
    content: (
      <Typography>
        Relatórios {!isMobile() && "(padrão Receita Federal)"}
      </Typography>
    ),
    description: (
      <Typography>Relatórios auxiliares para declaração de IR</Typography>
    ),
    icon: (
      <RiFileList3Line className="no-fill" color="var(--ant-primary-color)" />
    ),
  },
  {
    id: "insert-manual",
    sprotifi: true,
    link: "/velotax/transferencia-custodia",
    content: <Typography>Transferência de custódia</Typography>,
    icon: (
      <BiTransferAlt className="no-fill" color="var(--ant-primary-color)" />
    ),
  },
  {
    id: "dividendos",
    premium: true,
    link: "/velotax/exterior-dividendos",
    content: <Typography>Dividendos</Typography>,
    icon: <GiProfit className="no-fill" color="var(--ant-primary-color)" />,
    description: <Typography>Gestão dos dividendos recebidos</Typography>,
  },
  {
    id: "integration",
    premium: true,
    link: "/velotax/exterior-integrations",
    content: <div>
      <Typography>Integrações</Typography>
    </div>,
    icon: <IntegrationIcon className="no-fill" />,
    description: <Typography>Conexões com as suas corretoras</Typography>,
  },
];
