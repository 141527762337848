import { Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { AiOutlineLock } from "react-icons/ai";
import { Card } from "./Cards";
import { itensBolsa } from "./itensBolsa";
import Button from "../../components/Button";
import { HomeContainer } from "../Home/styles";
import { useAuth } from "../../contexts/AuthContext";
import { useBroker } from "../../contexts/BrokerContext";

export const Bolsa = () => {
  const navigate = useNavigate();
  const { integration } = useBroker();
  const { hasPlan, showUserPlanModal } = useAuth();

  const handleOpenPlanModal = () => {
    showUserPlanModal(true);
  };

  return (
    <HomeContainer>
      <div className="content module">
        <div>
          <Typography.Title level={1}>Bolsa de valores</Typography.Title>
          <Typography.Text strong className="destak">
            Selecione a opção que deseja utilizar
          </Typography.Text>
          <div className="cards cards-2">
            {itensBolsa.slice(0, 3).map((item) => (
              <Card
                id={item.id}
                key={item.id}
                link={item.link}
                icon={item.icon}
                onClick={
                  item.id === "darf" && !integration.integrated
                    ? () => {
                        navigate("/velotax/bolsa-integration");
                      }
                    : (item.premium && (!hasPlan || !hasPlan.premium))
                    ? () => {
                        handleOpenPlanModal();
                      }
                    : undefined
                }
              >
                {item.content}
                {item.description && (
                  <div className="description">{item.description}</div>
                )}
                {item.premium && (!hasPlan || !hasPlan.premium) && (
                  <Button
                    type="primary"
                    className="btn-premium"
                    icon={<AiOutlineLock size={18} />}
                    onClick={() => {
                      handleOpenPlanModal();
                    }}
                  >
                    PREMIUM
                  </Button>
                )}
              </Card>
            ))}
          </div>
        </div>
      </div>
    </HomeContainer>
  );
};
