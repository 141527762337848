import styled from "styled-components";

export const HeaderContainer = styled.header`
  z-index: 1;
  display: flex;
  column-gap: 32px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 96px;
  padding: 26px 48px;
  top: 0;
  position: fixed;
  border-bottom: 1px solid transparent;
  background-color: var(--velotax-background-color);
  box-shadow: 0px 8px 24px rgba(0, 0, 88, 0.08);

  &.showIRPFAPPBanner {
    position: absolute;
  }

  @media only screen and (max-device-width: 812px) {
    height: 80px;
    column-gap: 2px;
    padding: 13px 12px;
  }

  .buttons {
    display: flex;
    align-items: center;
    column-gap: 16px;

    .login-desc {
      margin-right: -14px;
    }

    .ant-btn-text {
      padding: 4px 12px;
      color: var(--ant-primary-color);
    }

    @media only screen and (max-device-width: 812px) {
      column-gap: 2px;
    }

    button {
      height: 40px;
      font-size: 16px;
      font-weight: 500;
      padding: 4px 8px;
      &.ant-btn-primary {
        border-color: var(--ant-error-color);
        background-color: var(--ant-error-color);
      }
    }
  }
`;

export const Progress = styled.div<{ value: number }>`
  left: 0;
  height: 4px;
  bottom: -4px;
  position: absolute;
  transition: 300ms width;
  width: ${({ value }) => value}%;
  background-color: var(--ant-primary-color);
`;
