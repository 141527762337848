import { Typography } from "antd";
import { AiOutlineApi } from "react-icons/ai";
import { HiOutlineCalculator } from "react-icons/hi";
import myntLogo from "../../assets/mynt.svg";
import mercadoBitcoinLogo from "../../assets/mercadobitcoin-favicon.png";
import { isMobile } from "../../utils";
import { RiFileList3Line } from "react-icons/ri";
import { ItensLogo } from "./styles";

export const itensCripto = [
  {
    id: "darf",
    premium: true,
    basic: false,
    showToBasic: true,
    link: "/velotax/cripto-historic",
    content: <Typography>Calculadora de DARF</Typography>,
    description: <Typography>Cálculo de IR e emissão de DARF</Typography>,
    icon: (
      <HiOutlineCalculator
        className="no-fill"
        color="var(--ant-primary-color)"
      />
    ),
  },
  {
    id: "integration",
    premium: true,
    basic: false,
    showToBasic: true,
    link: "/velotax/cripto-integrations",
    content: (
      <div>
        <Typography>Integrações</Typography>
        {!isMobile() && (
          <ItensLogo>
            <img
              src={mercadoBitcoinLogo}
              alt="mercadobitcoin-logo"
              style={{
                height: "20px",
              }}
            />
            {/* <img
              src={bitcoinTradeLogo}
              alt="bitcointrade-logo"
              style={{
                height: "20px",
              }}
            /> */}
            <img
              src={myntLogo}
              alt="mynt-logo"
              style={{
                height: "20px",
              }}
            />
            {/* <img
              src={binanceLogo}
              alt="binance-logo"
              style={{
                height: "20px",
              }}
            /> */}
          </ItensLogo>
        )}
      </div>
    ),
    description: <Typography>Conexões com as suas corretoras</Typography>,
    icon: <AiOutlineApi className="no-fill" color="var(--ant-primary-color)" />,
  },
  {
    id: "report",
    basic: false,
    premium: true,
    link: "/velotax/cripto-report",
    content: (
      <Typography>
        Relatórios {!isMobile() && "(padrão Receita Federal)"}
      </Typography>
    ),
    description: (
      <Typography>Relatórios auxiliares para declaração de IR</Typography>
    ),
    icon: (
      <RiFileList3Line className="no-fill" color="var(--ant-primary-color)" />
    ),
  },
];
