import { RcFile } from "antd/lib/upload";
import { Form, message, Typography } from "antd";
import { UploadRequestOption } from "rc-upload/lib/interface";
import React, { Dispatch, SetStateAction, useState } from "react";
import apis from "../../../services/apiCrypto";
import UploadVelotax from "../../UploadVelotax";
import { DrawerModal } from "../../DrawerModal";
import { useAuth } from "../../../contexts/AuthContext";
import mercadoBitcoinLogo from "../../../assets/mercado-bitcoin.svg";
// import EmbedCollapse from '../EmbedCollapse';

interface IMercadoBitcoinIntegrationModalProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  setLoadingIntegration: Dispatch<SetStateAction<boolean>>;
}

const MercadoBitcoinIntegrationModal: React.FC<
  IMercadoBitcoinIntegrationModalProps
> = ({ visible, setVisible, setLoadingIntegration }) => {
  const { user } = useAuth();
  const [form] = Form.useForm();
  const [loadingFile, setLoadingFile] = useState(false);
  const [sendRequest, setSendRequest] = React.useState(true);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  const beforeUpload = (file: RcFile) => {
    setSendRequest(true);
    if (
      ![
        "text/csv",
        "application/vnd.ms-excel",
        "text/x-csv",
        "text/plain",
        "text/tab-separated-values",
        "text/x-comma-separated-values",
        "text/comma-separated-values",
        "application/x-csv",
        "application/csv",
      ].includes(file.type)
    ) {
      message.error("Envie um arquivo do tipo CSV");
      setSendRequest(false);
    }
  };

  const customRequest = ({ file }: UploadRequestOption) => {
    const formData = new FormData();
    if (!sendRequest) return;
    formData.append("file", file);
    setLoadingFile(true);
    apis
      .post("/mercado-bitcoin/integrate", formData, {
        params: { email: user.user.email, key: "mercadoBitcoin" },
      })
      .then(() => {
        setVisible(false);
        setLoadingFile(false);
        setLoadingIntegration(true);
        message.success("Integração iniciada com sucesso");
      })
      .catch(() => {
        setVisible(false);
        setLoadingFile(false);
        setLoadingIntegration(false);
        message.error("Não foi possivel realizar a integração");
      });
  };

  return (
    <DrawerModal
      footer={null}
      destroyOnClose
      visible={visible}
      onCancel={onCancel}
      maskClosable={false}
      title={
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <img
            src={mercadoBitcoinLogo}
            alt="Mercado Bitcoin logo"
            style={{ height: "48px", marginBottom: "8px" }}
          />
          Integrar com o Mercado Bitcoin
        </span>
      }
    >
      {/* <EmbedCollapse embedId='hUTrg2kT9kY' /> */}
      <Typography.Paragraph>
        Faça o upload do arquivo csv de integração com o Mercado Bitcoin
      </Typography.Paragraph>
      <UploadVelotax
        accept="all"
        loading={loadingFile}
        beforeUpload={beforeUpload}
        customRequest={customRequest}
      />
    </DrawerModal>
  );
};

export default MercadoBitcoinIntegrationModal;
