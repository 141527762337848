import { message } from "antd";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Upload, { RcFile } from "antd/lib/upload";
import {
  UploadFile,
  UploadFileStatus,
  UploadProps,
} from "antd/lib/upload/interface";
import apiBolsa from "../../../services/apiBolsa";
import apiExterior from "../../../services/apiExterior";
import { useTransferenciaCustodia } from "../../../contexts/TransferenciaCustodiaContext";

const isValidFile = (file: UploadFile) => file.type === "application/pdf";
const sameNameFile = (file: UploadFile, files: UploadFile[]) =>
  files.findIndex((item) => item.name === file.name) >= 0;

export const useNotasCorretagem = () => {
  const navigate = useNavigate();
  const sameNameFiles = useRef<string[]>([]);
  const [loadingSend, setLoadingSend] = useState(false);
  const [notas, setNotas] = useState<UploadFile<any>[]>([]);
  const [showNotasCorretagemModal, setShowNotasCorretagemModal] =
    useState(false);
  const { getAtivos } = useTransferenciaCustodia();

  const sendNotasCustodyTransfer = (
    setLoadingSend: any,
    setShowNotasCorretagemModal: any
  ) => {
    const formData = new FormData();

    notas.forEach((nota) => {
      formData.append("files", nota.originFileObj as File);
    });
    setLoadingSend(true);
    apiExterior
      .post("/xpinvestimentos/custodia/notes", formData)
      .then(() => {
        message.success("Informações extraídas com sucesso");
      })
      .catch((err) => {
        const errorMessage =
          err.reponse?.message?.error || "Não foi possivel extrair informações";
        message.error(errorMessage);
      })
      .finally(() => {
        getAtivos();
        setShowNotasCorretagemModal(false);
        setLoadingSend(false);
      });
  };

  const beforeUploadNota = (file: RcFile) => {
    if (!isValidFile(file)) {
      message.error("Você só pode fazer upload de arquivos pdf");
      return Upload.LIST_IGNORE;
    }
    if (sameNameFile(file, notas)) {
      sameNameFiles.current.push(file.name);
      message.error("Este arquivo já foi adicionado");
      return Upload.LIST_IGNORE;
    }
  };

  const onChangeNotas = ({
    file,
    fileList,
  }: {
    file: UploadFile;
    fileList: UploadFile[];
  }) => {
    if (file && file.status === "removed") return;
    if (!isValidFile(file)) return;
    if (sameNameFiles.current.includes(file.name)) {
      sameNameFiles.current = sameNameFiles.current.filter(
        (name) => name !== file.name
      );
      return;
    }
    setNotas(
      fileList
        .filter((file) => file.status !== "removed")
        .map((file) => ({ ...file, status: "done" as UploadFileStatus }))
    );
  };

  const onRemoveNota = (file: UploadFile) => {
    setNotas((notas) => notas.filter((nota) => nota.uid !== file.uid));
  };

  const sendNotas = () => {
    const formData = new FormData();
    notas.forEach((nota) => {
      formData.append("files", nota.originFileObj as File);
    });
    setLoadingSend(true);
    apiBolsa
      .post("/brokerage-notes/read", formData)
      .then(() => {
        message.success("Informações extraídas com sucesso");
        setShowNotasCorretagemModal(false);
        setNotas([]);
        navigate("/velotax/bolsa-historic");
      })
      .catch((err) => {
        const errorMessage =
          err.reponse?.message?.error || "Não foi possivel extrair informações";
        message.error(errorMessage);
      })
      .finally(() => {
        setLoadingSend(false);
      });
  };

  const uploadProps: UploadProps = {
    fileList: notas,

    multiple: true,
    listType: "text",
    accept: "application/pdf",
    onRemove: onRemoveNota,
    onChange: onChangeNotas,
    beforeUpload: beforeUploadNota,
    customRequest: () => {},
    showUploadList: {
      showRemoveIcon: true,
      showPreviewIcon: false,
      showDownloadIcon: false,
    },
  };

  return {
    sendNotas,
    loadingSend,
    uploadProps,
    showNotasCorretagemModal,
    setShowNotasCorretagemModal,
    sendNotasCustodyTransfer,
  };
};
