import styled from "styled-components";

export const ItensLogo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 16px;
    margin-bottom: 8px;
    padding-top: 16px;

    @media (max-device-width: 576px) {
        display: none;
    }
`;