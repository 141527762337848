import { ConfigProvider } from "antd";
import ptBR from "antd/lib/locale/pt_BR";
import { Provider } from "@rollbar/react";
import { BrowserRouter as Router } from "react-router-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Routes from "./routes";
import GlobalStyles from "./styles/global";
import { ChatGPT } from "./components/ChatGPT";
import ContainerStyles from "./styles/container";
import PlanModalStyles from "./styles/plan-modal";
import ContextWrapper from "./contexts/ContextWrapper";
import HistoricListStyles from "./styles/historic-list";
import ErrorBoundaryRollbar from "./components/ErrorBoundary";

function App() {
  const { host } = window.location;
  const isDev = host.includes("localhost") || host.includes("dev");
  const rollbarConfig = {
    accessToken: "30ca0272bd6e40e0bd69c225fa9e2c64",
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: isDev ? "development" : "production",
    },
  };

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundaryRollbar>
        <GoogleReCaptchaProvider
          language="pt-BR"
          reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY || ""}
        >
          <ConfigProvider
            locale={ptBR}
            renderEmpty={() => "Nenhum dado encontrado"}
          >
            <Router>
              <ContextWrapper>
                <Routes />
                <GlobalStyles />
                <ContainerStyles />
                <PlanModalStyles />
                <HistoricListStyles />
                <ChatGPT />
              </ContextWrapper>
            </Router>
          </ConfigProvider>
        </GoogleReCaptchaProvider>
      </ErrorBoundaryRollbar>
    </Provider>
  );
}

export default App;
