import styled from "styled-components";

export const HomeContainer = styled.div`
  height: 100%;
  display: flex;
  padding: 64px 32px 32px 48px;

  & > .content {
    margin: 0 auto;
    width: 700px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.module {
      .cards.cards-2 > div {
        width: 100%;
      }
    }
  }

  h1 {
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 1rem;
    color: var(--velotax-font-color);

    strong {
      font-weight: 800;
      color: var(--ant-primary-color);
    }
  }

  div.ant-typography {
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--velotax-font-color);
  }

  .ant-typography.destak {
    margin: 32px 0 24px;
    display: block;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--velotax-font-color);
    padding-left: 16px;
    border-left: 4px solid var(--ant-primary-color);
  }

  .cards {
    margin: 0;
    padding-bottom: 32px;
    width: 100%;
    display: grid;
    row-gap: 1rem;
    column-gap: 1rem;
    grid-template-columns: repeat(4, 1fr);

    &.cards-3 {
      grid-template-columns: repeat(3, 1fr);
    }
    &.cards-2 {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .home-footer {
    font-size: 12px;
    font-style: italic;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.625px;
    color: var(--velotax-font-color);
  }

  .anticon.anticon-loading.anticon-spin {
    width: 32px;
    height: 32px;
    svg {
      width: 32px;
      height: 32px;
    }
  }

  @media only screen and (max-device-width: 812px) {
    height: auto;
    padding: 24px 24px 64px;
    & > .content {
      width: 100%;
      &.module {
        .cards.cards-2 > div {
          width: 100%;
        }
      }
    }

    .cards {
      width: 100%;
      grid-template-columns: repeat(2, 1fr) !important;
      > div {
        width: auto;
      }
    }
  }

  @media only screen and (max-device-width: 400px) {
    padding: 24px 12px 64px;
    .cards {
      row-gap: 0.5rem;
      column-gap: 0.5rem;
    }
  }
`;
