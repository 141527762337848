import { Typography } from "antd";
import { HiCheck } from "react-icons/hi";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { Container } from "./styles";
import Button from "../../components/Button";
import { useAuth } from "../../contexts/AuthContext";

export const Sucesso = () => {
  const navigate = useNavigate();
  const { user, b3Authorized } = useAuth();

  useEffect(() => {
    const isProd = process.env.REACT_APP_NODE_ENV === "prd";
    isProd &&
      (window as any).gtag &&
      (window as any).gtag("event", "conversion", {
        send_to: "AW-414293187/T0L8CI76-MUYEMO5xsUB",
      });
  }, []);

  return (
    <Container className="velotax-container">
      <div className="velotax-content velotax-content-transparent">
        <div className="top-content">
          <div className="success">
            <HiCheck />
          </div>
          <Typography.Title level={1}>Sucesso!</Typography.Title>
          <Typography.Paragraph>
            Agora você já consegue acessar todos os módulos da plataforma e
            emitir DARFs ilimitados sem custo adicional.
          </Typography.Paragraph>
          <Button
            size="large"
            type="primary"
            onClick={() => {
              if (!b3Authorized) {
                navigate("/velotax/bolsa-integration");
              } else {
                navigate("/carteira");
              }
            }}
          >
            Continuar
          </Button>
        </div>
        <div className="footer">
          <Typography.Text>
            O comprovante de compra foi enviado para o e-mail{" "}
            <strong>{user?.user?.email}</strong> e para o WhatsApp{" "}
            <strong>{user?.user?.cellphone}</strong>.
          </Typography.Text>
        </div>
      </div>
    </Container>
  );
};
