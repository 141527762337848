import moment from "moment";
import { useLocation } from "react-router-dom";
import { useCallback, useState, useEffect } from "react";
import { Col, Divider, Form, Row, Typography } from "antd";
import {
  AiOutlineArrowDown,
  AiOutlineArrowUp,
  AiOutlineCheck,
} from "react-icons/ai";
import { questions } from "./items";
import Button from "../../components/Button";
import { currencyToNumber } from "../../utils";
import Loading from "../../components/Loading";
import { ImoveisManualContainer } from "./styles";
import backendImoveis from "../../services/imoveis";
import { useAuth } from "../../contexts/AuthContext";
import { DarfIMoveis } from "../../components/ImoveisImposto";

const imoveisManualId = "imoveis-manual-container";

export const ImoveisCalculadora = ({ view, item }: any) => {
  const { state } = useLocation();
  const [form] = Form.useForm<any>();
  const { user, hasPlan, showUserPlanModal } = useAuth();

  const [data, setData] = useState({});
  const [isForm, setIsForm] = useState(true);
  const [taxData, setTaxData] = useState({});
  const [docData, setDocData] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [isValorAPlicado, setIsValorAPlicado] = useState(false);

  const filteredQuestions = questions.filter(
    (question) => !question.skip?.({ data })
  );

  const setFields = useCallback(() => {
    const monthVenda = state?.mesVenda - 1;
    const monthAquisicao = state?.mesAquisicao - 1;
    if (!view) {
      setIsValorAPlicado(state?.isAplicado ?? false);
      form.setFieldsValue({
        valorAplicado: state?.valorAplicado ?? "",
        cep: state?.cep ?? "",
        mesVenda: moment({
          month: isNaN(monthVenda) ? new Date().getMonth() : monthVenda,
          year: state?.anoVenda ?? new Date().getFullYear(),
        }),
        valorVenda: state?.valorVenda ?? "",
        custoCorretagem: state?.custoCorretagem ?? "",
        mesAquisicao:
          moment({
            month: isNaN(monthAquisicao)
              ? new Date().getMonth()
              : monthAquisicao,
            year: state?.anoAquisicao ?? new Date().getFullYear(),
          }) ?? "",
        valorAquisicao: state?.valorAquisicao ?? "",
        isResidencial: state?.isResidencial ?? "",
        isAplicado: state?.isAplicado ?? false,
      });
      setData(state ?? {});
    } else {
      setDocData(item);
      setTaxData(item);
      setIsForm(false);
    }
  }, [form, state, view, item]);

  useEffect(() => {
    if (isForm) {
      document.getElementById("question-0")?.classList.add("show");
    }
  }, [isForm]);

  useEffect(() => {
    setFields();
  }, [setFields]);

  const event = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();
        const button = document.getElementById(
          currentQuestion < questions.length - 1
            ? `next-button-${currentQuestion}`
            : "submit-button"
        );
        button?.click();
      }
    },
    [currentQuestion]
  );

  useEffect(() => {
    document.addEventListener("keydown", event);
    return () => {
      document.removeEventListener("keydown", event);
    };
  }, [event]);

  const handleNav = useCallback(
    (value: 1 | -1) => {
      if (form.getFieldValue("isResidencial") === false) {
        form.setFieldsValue({ isAplicado: false });
        form.setFieldsValue({ valorAplicado: 0 });
      }
      form
        .validateFields(
          value === 1
            ? questions[currentQuestion].formItems.map(
                (formItem) => formItem.name ?? ""
              )
            : []
        )
        .then(() => {
          (document.activeElement as HTMLElement)?.blur();
          setTimeout(() => {
            setCurrentQuestion((current) => {
              const newValue = current + value;
              const target =
                newValue >= 0 && newValue < questions.length
                  ? newValue
                  : current;

              const targetIndex = filteredQuestions.findIndex(
                (q) => filteredQuestions?.[target]?.id === q?.id
              );
              const currentIndex = filteredQuestions.findIndex(
                (q) => filteredQuestions?.[current]?.id === q?.id
              );
              const targetElement = document.getElementById(
                targetIndex < 0 ? "question-0" : `question-${targetIndex}`
              );
              const currentElement = document.getElementById(
                `question-${currentIndex}`
              );
              currentElement?.classList.remove("show");
              setTimeout(() => {
                targetElement?.classList.add("show");
                targetElement?.scrollIntoView();
              }, 250);
              return target;
            });
          }, 300);
        })
        .catch(() => {});
    },
    [currentQuestion, setCurrentQuestion, form, filteredQuestions]
  );

  const handleSubmit = async (data: any) => {
    if (!hasPlan) {
      showUserPlanModal(true);
      return;
    }
    setLoading(true);
    if (!data.isResidencial) {
      data.isAplicado = false;
      data.valorAplicado = 0;
    }
    const mesVenda = data.mesVenda.month() + 1;
    const anoVenda = data.mesVenda.year();
    const mesAquisicao = data.mesAquisicao.month() + 1;
    const anoAquisicao = data.mesAquisicao.year();

    const calcResponse = await backendImoveis.post("/imoveis/calculate-tax", {
      mesVenda,
      mesAquisicao,
      anoVenda,
      anoAquisicao,
      valorVenda:
        typeof data.valorVenda === "string"
          ? currencyToNumber(data.valorVenda)
          : data.valorVenda,
      valorAquisicao:
        typeof data.valorAquisicao === "string"
          ? currencyToNumber(data.valorAquisicao)
          : data.valorAquisicao,
      custoCorretagem:
        typeof data.custoCorretagem === "string"
          ? currencyToNumber(data.custoCorretagem)
          : data.custoCorretagem,
      valorAplicado:
        typeof data.valorAplicado === "string"
          ? currencyToNumber(data.valorAplicado)
          : data.valorAplicado ?? 0,
    });

    let docData: any = {};

    if (state !== null) {
      docData = await backendImoveis.put("/imoveis/edit", {
        _id: state.imovelId,
        cep: data.cep,
        isResidencial: data.isResidencial,
        isAplicado: data.isAplicado,
        userCode: user.user.cpf,
        mesVenda,
        institution: "imoveis-velotax",
        mesAquisicao,
        anoVenda,
        anoAquisicao,
        valorVenda:
          typeof data.valorVenda === "string"
            ? currencyToNumber(data.valorVenda)
            : data.valorVenda,
        valorAquisicao:
          typeof data.valorAquisicao === "string"
            ? currencyToNumber(data.valorAquisicao)
            : data.valorAquisicao,
        custoCorretagem:
          typeof data.custoCorretagem === "string"
            ? currencyToNumber(data.custoCorretagem)
            : data.custoCorretagem,
        valorAplicado:
          typeof data.valorAplicado === "string"
            ? currencyToNumber(data.valorAplicado)
            : data.valorAplicado ?? 0,
        deducoesLegais: calcResponse.data.deducoesLegais,
        impostoDevido: calcResponse.data.impostoDevido,
        juros: calcResponse.data.juros,
        multa: calcResponse.data.multa,
        aliquota: calcResponse.data.aliquota,
      });
    } else {
      docData = await backendImoveis.post("/imoveis/create", {
        cep: data.cep,
        isResidencial: data.isResidencial,
        isAplicado: data.isAplicado,
        userCode: user.user.cpf,
        mesVenda,
        institution: "imoveis-velotax",
        mesAquisicao,
        anoVenda,
        anoAquisicao,
        valorVenda:
          typeof data.valorVenda === "string"
            ? currencyToNumber(data.valorVenda)
            : data.valorVenda,
        valorAquisicao:
          typeof data.valorAquisicao === "string"
            ? currencyToNumber(data.valorAquisicao)
            : data.valorAquisicao,
        custoCorretagem:
          typeof data.custoCorretagem === "string"
            ? currencyToNumber(data.custoCorretagem)
            : data.custoCorretagem,
        valorAplicado:
          typeof data.valorAplicado === "string"
            ? currencyToNumber(data.valorAplicado)
            : data.valorAplicado ?? 0,
        deducoesLegais: calcResponse.data.deducoesLegais,
        impostoDevido: calcResponse.data.impostoDevido,
        juros: calcResponse.data.juros,
        multa: calcResponse.data.multa,
        aliquota: calcResponse.data.aliquota,
      });
    }
    setTaxData({ ...calcResponse.data, impostoAcumulado: 0 });
    setDocData(docData.data);
    setLoading(false);
    setIsForm(false);
  };

  const onValuesChange = (changed: any, values: any) => {
    if ("isResidencial" in changed) {
      setData(values);
    }
    setIsValorAPlicado(values.isAplicado);
  };

  return loading ? (
    <Loading />
  ) : isForm ? (
    <Form
      form={form}
      size="large"
      layout="vertical"
      onFinish={handleSubmit}
      onValuesChange={onValuesChange}
      style={{ height: "100%", overflow: "hidden" }}
    >
      <ImoveisManualContainer id={imoveisManualId}>
        {filteredQuestions.map((question: any, index: any, array: any[]) => (
          <div
            key={`question-${index}-${question.id}`}
            data-id={`question-${question.id}`}
            id={`question-${index}`}
            className="question-container beauty-scrollbar"
          >
            <div className="velotax-container">
              <div className="velotax-content">
                <Typography.Title level={1} className="page-title">
                  {question.title}
                </Typography.Title>
                <Typography style={{ marginBottom: "32px" }}>
                  {question.subTitle}
                </Typography>
                {question.formItems.map(({ FormItem, name }: any) => (
                  <FormItem
                    key={name}
                    setIsValorAPlicado={setIsValorAPlicado}
                    form={form}
                    item={state}
                    isValorAPlicado={isValorAPlicado}
                    tabIndex={currentQuestion === index ? 1 : -1}
                  />
                ))}
                {question.children}
                <Divider />
                <Row justify={index === 0 ? "end" : "space-between"}>
                  {index > 0 && (
                    <Col>
                      <Button
                        size="large"
                        onClick={() => handleNav(-1)}
                        icon={<AiOutlineArrowUp size={18} />}
                        tabIndex={currentQuestion === index ? 2 : -1}
                      >
                        Anterior
                      </Button>
                    </Col>
                  )}
                  {index < array.length - 1 ? (
                    <Col>
                      <Button
                        size="large"
                        type="primary"
                        id={`next-button-${index}`}
                        onClick={() => handleNav(1)}
                        icon={<AiOutlineArrowDown size={18} />}
                        tabIndex={currentQuestion === index ? 3 : -1}
                      >
                        Próximo
                      </Button>
                    </Col>
                  ) : (
                    <Col>
                      <Button
                        size="large"
                        type="primary"
                        htmlType="submit"
                        id="submit-button"
                        loading={loading}
                        icon={<AiOutlineCheck size={18} />}
                        tabIndex={currentQuestion === index ? 3 : -1}
                      >
                        Finalizar
                      </Button>
                    </Col>
                  )}
                </Row>
              </div>
            </div>
          </div>
        ))}
      </ImoveisManualContainer>
    </Form>
  ) : (
    <DarfIMoveis
      item={item}
      taxData={taxData}
      view={view}
      docData={docData}
      userCode={user.user.cpf}
      setIsForm={setIsForm}
      setCurrentQuestion={setCurrentQuestion}
      cep={form.getFieldValue("cep") ?? item.cep}
      valorVenda={form.getFieldValue("valorVenda") ?? item.valorVenda}
      valorAquisicao={
        form.getFieldValue("valorAquisicao") ?? item.valorAquisicao
      }
      custoCorretagem={
        form.getFieldValue("custoCorretagem") ?? item.custoCorretagem
      }
      setTaxData={setTaxData}
    />
  );
};
