import { HTMLAttributes } from "react";
import styled, { css } from "styled-components";

interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
  qtt: number;
}

export const Container = styled.nav<ContainerProps>`
  display: none;
  bottom: 0px;
  width: 100%;
  height: 64px;
  position: fixed;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 88, 0.16);
  background-color: var(--velotax-background-color);
  border-top: 1px solid var(--velotax-background-color-ghost);
  .hidden {
    display: none;
  }
  .ant-menu {
    width: 100%;
    display: flex;
    &.ant-menu-horizontal {
      border-bottom: none;
    }
    .ant-menu-item {
      opacity: 0.8;
      ${({ qtt }) =>
        css`
          width: calc(100% / ${qtt});
        `}
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      .ant-menu-title-content {
        opacity: 0.8;
        margin: 6px 0 0;
        font-size: ${({ qtt }) =>
          qtt === 6 ? "11px" : qtt === 5 ? "12px" : "13px"};
        line-height: 13px;
        color: var(--velotax-font-color);
      }
      .ant-menu-item-icon {
        width: 24px;
        height: 24px;
        opacity: 0.7;
        fill: var(--velotax-font-color);
      }
      :after {
        top: 0;
        left: 5px;
        bottom: unset;
        width: calc(100% - 10px);
      }
      &:not(.ant-menu-item-active):after {
        border-bottom-color: transparent;
      }
      &.ant-menu-item-active  {
        opacity: 1;
        :before {
          content: "";
          position: absolute;
          top: 6px;
          left: 5px;
          opacity: 0.1;
          border-radius: 4px;
          width: calc(100% - 10px);
          height: calc(100% - 12px);
          // background-color: var(--ant-primary-color) !important;
        }
        .stroke {
          stroke: var(--ant-primary-color);
        }
        .ant-menu-title-content {
          opacity: 1;
          font-weight: 500;
          color: var(--ant-primary-color)!important;
        }
        .ant-menu-item-icon {
          opacity: 1;
          fill: var(--ant-primary-color);
        }
      }
      &.finalizar {
        .ant-menu-title-content {
          margin: 4px 0 0;
        }
        .ant-menu-item-icon {
          width: 26px;
          height: 26px;
        }
      }
    }
  }
  @media only screen and (max-device-width: 812px) {
    display: flex;
  }
  svg {
    &.stroke {
      stroke:  var(--velotax-font-color);
      fill: transparent !important;
      transition: stroke 0.3s;
    }
  }
`;
