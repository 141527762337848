import { v4 } from "uuid";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { errorMessage } from "../utils";
import apiBolsa from "../services/apiBolsa";
import { useAuth } from "../contexts/AuthContext";
import { useBroker } from "../contexts/BrokerContext";
import moment from "moment";

interface IOldTransactionsContext {
  changed: boolean;
  loadingPut: boolean;
  loadingGet: boolean;
  setLoadingPut: Dispatch<SetStateAction<boolean>>;
  setLoadingGet: Dispatch<SetStateAction<boolean>>;
  oldPositionEdit: any;
  doacaoHerancaEdit: any;
  oldPositionList: any[];
  doacoesHerancas: any[];
  showAddPositionModal: boolean;
  setOldPositionEdit: Dispatch<SetStateAction<any>>;
  setDoacaoHerancaEdit: Dispatch<SetStateAction<any>>;
  setShowAddPositionModal: Dispatch<SetStateAction<boolean>>;
  downloadDoacoesHerancasExcel: () => Promise<Blob | null>;
  saveOldPositions: () => void;
  saveDoacoesHerancas: () => void;
  addOldPosition: (item: any) => void;
  addDoacaoHeranca: (item: any) => void;
  editOldPosition: (item: any) => void;
  editDoacaoHeranca: (item: any) => void;
  handleEditOldPosition: (item: any, index: number) => void;
  handleRemoveOldPosition: (item: any, index: number) => void;
  handleRemoveDoacaoHeranca: (item: any, index: number) => void;
  handleEditDoacaoHeranca: (item: any, index: number) => void;
  getOldTransactionsInit: () => void;
  getDoacoesHerancasInit: () => void;
}

const OldTransactionsContext = createContext({} as IOldTransactionsContext);

export const OldTransactionsProvider: React.FC = ({ children }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { initIntegration, currentBroker } = useBroker();
  const [changed, setChanged] = useState(false);
  const [loadingGet, setLoadingGet] = useState(false);
  const [loadingPut, setLoadingPut] = useState(false);
  const [oldPositionEdit, setOldPositionEdit] = useState<any>({});
  const [doacaoHerancaEdit, setDoacaoHerancaEdit] = useState<any>({});
  const [oldPositionList, setOldPositionList] = useState<any>(null);
  const [doacoesHerancas, setDoacoesHerancas] = useState<any>(null);
  const [showAddPositionModal, setShowAddPositionModal] = useState(false);

  const handleEditOldPosition = (item: any, index: number) => {
    setShowAddPositionModal(true);
    setOldPositionEdit(item);
  };

  const handleEditDoacaoHeranca = (item: any, index: number) => {
    setShowAddPositionModal(true);
    setDoacaoHerancaEdit(item);
  };

  const handleRemoveOldPosition = (item: any, index: number) => {
    const pojo = oldPositionList
      .filter((e: any) =>
        "lendingSide" in item ? e.lendingSide : !e.lendingSide
      )
      .find((item: any, i: number) => i === index);
    const listOldPosition = oldPositionList.filter(
      (item: any) => JSON.stringify(pojo) !== JSON.stringify(item)
    );
    setOldPositionList(listOldPosition);
    setChanged(true);
  };

  const handleRemoveDoacaoHeranca = (item: any, index: number) => {
    const itemParam = item;
    const list = doacoesHerancas.map(
      (item: any) => {
        const newOrders = item?.orders.filter((or: any) => {
          delete or.dateView;
          if (JSON.stringify(itemParam) !== JSON.stringify(or)) return or;
        })
        return { ...item, orders: newOrders };
      }
    );
    setDoacoesHerancas(list);
    setChanged(true);
  }

  const editOldPosition = (editItem: any) => {
    const listOldPositions = oldPositionList.map((item: any) =>
      editItem.id === item.id
        ? {
            ...item,
            ...editItem,
            price: editItem?.avgBuyPrice,
            operation:
              editItem?.lendingSide && editItem?.lendingSide === "Tomador"
                ? "V"
                : "C",
          }
        : item
    );
    setOldPositionList(listOldPositions);
    setShowAddPositionModal(false);
    setOldPositionEdit({});
    setChanged(true);
  };

  const editDoacaoHeranca = (editItem: any) => {
    const list = doacoesHerancas.map((item: any) => {
      const newOrders = item?.orders.map((or: any) => {
        delete or.dateView;
        const newDate = new Date(
          `${moment(editItem?.dateView, 'DD/MM/YYYY', true).format(
            'YYYY-MM-DD',
          )}T03:00:00.000Z`);
        return editItem?._id === or?._id
          ? {
            ...or,
            date: newDate,
            code: editItem?.code,
            price: editItem?.price,
            quantity: editItem?.quantity,
            totalValue: editItem?.price * editItem?.quantity,
          }
          : or
      })
      return { ...item, orders: newOrders };
    }
    );
    setDoacoesHerancas(list);
    setShowAddPositionModal(false);
    setDoacaoHerancaEdit({});
    setChanged(true);
  };

  const addOldPosition = (item: any) => {
    const listOldPosition: any[] = [...oldPositionList];
    const newPostion = {
      operation:
        item?.lendingSide && item?.lendingSide === "Tomador" ? "V" : "C",
      avgBuyPrice: item?.avgBuyPrice,
      price: item?.avgBuyPrice,
      code: item?.code,
      feeB3: item?.feeB3 || 0,
      institution: "Desconhecida",
      date: new Date(2019, 11, 31),
      market: "Mercado a Vista",
      quantity: item?.quantity,
      month: 11,
      year: 2019,
      id: v4(),
    };

    if (item.lendingSide)
      Object.assign(newPostion, { lendingSide: item?.lendingSide });
    listOldPosition.push(newPostion);

    setOldPositionList(listOldPosition);
    setShowAddPositionModal(false);
    setChanged(true);
  };

  const addDoacaoHeranca = (addItem: any) => {
    const list = [...doacoesHerancas];
    const month = Number((addItem?.dateView)?.split('/')[1]);
    const year = Number((addItem?.dateView)?.split('/')[2]);
    const now = new Date();
    const newDate = new Date(
      `${moment(addItem?.dateView, 'DD/MM/YYYY', true).format(
        'YYYY-MM-DD',
      )}T03:00:00.000Z`);
    const retExisting = doacoesHerancas.filter((item: any) => {
      const newOrders = item?.orders;
      if (item?.month == month && item?.year == year) {
        newOrders.push(
          {
            code: addItem?.code,
            operation: "C",
            date: newDate,
            quantity: addItem?.quantity,
            price: addItem?.price,
            totalValue: addItem?.price * addItem?.quantity,
            totalFeeNota: 0,
            name: addItem?.code,
            market: "Mercado a Vista",
            expirationDate: null,
            institution: "Desconhecida",
            operationType: "Credito"
          }
        )
        return { ...item, orders: newOrders };
      }
    })
    if (!retExisting || retExisting.length == 0) {
      const newOperation = {
        cpf: user?.user?.cpf,
        month: month,
        year: year,
        provider: 'b3',
        createdAt: now,
        updatedAt: now,
        orders: [
          {
            code: addItem?.code,
            operation: "C",
            date: newDate,
            quantity: addItem?.quantity,
            price: addItem?.price,
            totalValue: addItem?.price * addItem?.quantity,
            totalFeeNota: 0,
            name: addItem?.code,
            market: "Mercado a Vista",
            expirationDate: null,
            institution: "Desconhecida",
            operationType: "Credito"
          }
        ]
      };
  
      list.push(newOperation);
    }

    setDoacoesHerancas(list);
    setShowAddPositionModal(false);
    setChanged(true);
  };

  const saveOldPositions = useCallback(async () => {
    const orders = oldPositionList.map((item: any) => ({
      ...item,
      id: undefined,
    }));
    try {
      setLoadingPut(true);
      await apiBolsa.post("/operation", { orders });
      // message.success("Dados salvos com sucesso!");
      navigate(`/${currentBroker?.path}/bolsa-historic`);
    } catch (err) {
      message.error(errorMessage);
      throw Error;
    } finally {
      setLoadingPut(false);
    }
  }, [oldPositionList, currentBroker, navigate]);

  const saveDoacoesHerancas = useCallback(async () => {
    const operations = doacoesHerancas.map((item: any) => {
      const newOrders = item?.orders.map((or: any) => {
        return { ...or }
      })
      return { ...item, orders: newOrders };
    }
    )
    try {
      setLoadingPut(true);
      await apiBolsa.post("/operation/donations", { operations });
      message.success("Dados salvos com sucesso!");
    } catch (err) {
      message.error(errorMessage);
      throw Error;
    } finally {
      navigate(`/${currentBroker?.path}/bolsa-historic`);
      setLoadingPut(false);
    }
  }, [doacoesHerancas, currentBroker, navigate]);

  const downloadOldTransactionsExcel = async () => {
    return await apiBolsa
      .get("/operation/download?month=12&year=2019", {
        responseType: "blob",
      })
      .then((res) => {
        return res.data
      })
      .catch(() => {
        message.error(errorMessage);
        return null
      })
  }

  const downloadDoacoesHerancasExcel = async () => {
    return await apiBolsa
      .get("/operation/download/donations?operationType=Credito", {
        responseType: "blob",
      })
      .then((res) => {
        return res.data
      })
      .catch(() => {
        message.error(errorMessage);
        return null
      })
  }

  const getOldTransactionsInit = useCallback(
    (setShowModalPrePreenchida?: (p: boolean) => void) => {
      setLoadingGet(true);
      apiBolsa
        .get("/operation?month=12&year=2019", {})
        .then((response) => {
          setOldPositionList(
            response.data?.[0]?.orders.length
              ? response.data?.[0].orders.map((item: any) => ({
                  ...item,
                  avgBuyPrice: item.price,
                  id: item._id,
                }))
              : []
          );
          setLoadingGet(false);
        })
        .catch(() => {
          setLoadingGet(false);
          setOldPositionList([]);
          message.error(errorMessage);
        })
        .finally(() => setShowModalPrePreenchida?.(false));
    },
    [setOldPositionList]
  );

  const getDoacoesHerancasInit = useCallback(
    (setShowModalPrePreenchida?: (p: boolean) => void) => {
      setLoadingGet(true);
      apiBolsa
        .get("/operation/donations?operationType=Credito", {})
        .then((response) => {
          setDoacoesHerancas(response.data || []);
          setLoadingGet(false);
        })
        .catch(() => {
          setLoadingGet(false);
          setDoacoesHerancas([]);
          message.error(errorMessage);
        })
        .finally(() => setShowModalPrePreenchida?.(false));
    },
    [setDoacoesHerancas]
  );

  useEffect(() => {
    if (user?.user?.cpf) {
      getOldTransactionsInit();
    }
  }, [getOldTransactionsInit, initIntegration, user.user]);

  return (
    <OldTransactionsContext.Provider
      value={{
        changed,
        loadingPut,
        setLoadingPut,
        loadingGet,
        setLoadingGet,
        oldPositionList,
        doacoesHerancas,
        oldPositionEdit,
        doacaoHerancaEdit,
        setOldPositionEdit,
        setDoacaoHerancaEdit,
        showAddPositionModal,
        setShowAddPositionModal,
        downloadDoacoesHerancasExcel,
        addOldPosition,
        addDoacaoHeranca,
        editOldPosition,
        editDoacaoHeranca,
        saveOldPositions,
        saveDoacoesHerancas,
        handleEditOldPosition,
        handleRemoveOldPosition,
        handleRemoveDoacaoHeranca,
        handleEditDoacaoHeranca,
        getOldTransactionsInit,
        getDoacoesHerancasInit
      }}
    >
      {children}
    </OldTransactionsContext.Provider>
  );
};
export const useOldTransactions = () => useContext(OldTransactionsContext);