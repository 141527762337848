import { Button, Typography, Spin } from "antd";
import { useAuth } from "../../contexts/AuthContext";
import WarrenLogo from "../../assets/warren-logo.svg";
import XP_black_logo from "../../assets/xp-black.svg";
import SprotifiLogo from "../../assets/sprotifi-logo.svg";
import { useBroker } from "../../contexts/BrokerContext";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ExteriorIntegration from "../../components/IntegrationComponents/ExteriorIntegration";
import { NovoCadastro } from "../NovoCadastro";

interface IntegrationExteriorProps {}

export const IntegrationExterior: React.FC<IntegrationExteriorProps> = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { currentBroker } = useBroker();

  const title = "Conecte com suas corretoras";
  const subTitle =
    "Seus impostos são calculados automaticamente após a conexão";

  return user.user ? (
    <div>
      <Spin spinning={(loading)} >
        <div className="velotax-container">
          <Typography.Title level={1} className="page-title-outside">
            {title}
          </Typography.Title>
          <Typography.Paragraph className="destak" strong>
            {subTitle}
          </Typography.Paragraph>
          <div className="velotax-content">
            <ExteriorIntegration setLoadingIntegration={setLoading} />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "24px",
              }}>
              <Button
                type="ghost"
                style={{
                  marginTop: "16px",
                }}
                onClick={() => {
                  navigate(`/${currentBroker.path}/exterior`);
                }}>
                Continuar sem integração
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  ) : (
    <NovoCadastro
      corretoras={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            columnGap: "24px",
            marginBottom: "8px",
          }}>
          <img
            src={XP_black_logo}
            alt="xp-logo"
            style={{
              height: "24px",
            }}
          />
          <img
            src={SprotifiLogo}
            alt="sproutfi-logo"
            style={{
              height: "24px",
            }}
          />
          <img
            src={WarrenLogo}
            alt="warren-logo"
            style={{
              height: "24px",
            }}
          />
        </div>
      }
      item={{
        settings: {
          title,
          subTitle,
          okText: "Conectar com corretoras",
        },
      }}
    />
  );
};
