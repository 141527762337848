// Config
import { useBroker } from "../../contexts/BrokerContext";
// Components
import { LoadingOutlined } from "@ant-design/icons";
import { LoadingContainer, LogoContainer, Paragraph, Title } from "./styles";
// Assets
import veloLogo from "../../assets/velotax/velologo.svg";
import { isMobile } from "../../utils";

// Types
interface IProps {
  children: string;
  title: string;
}

export const LoadingModal = ({ title, children }: IProps) => {
  const { currentBroker } = useBroker();
  const mobile = isMobile();

  return (
    <LoadingContainer isMobile={mobile}>
      <LogoContainer isMobile={mobile}>
        <img
          src={
            currentBroker?.iconAlternative || currentBroker?.icon || veloLogo
          }
          style={currentBroker?.iconAlternativeStyle || {}}
          alt={currentBroker?.name || "Velotax Logo"}
        />
        <LoadingOutlined style={{ fontSize: "40px" }} spin />
      </LogoContainer>
      <Title level={3}>{title}</Title>
      <Paragraph>{children}</Paragraph>
    </LoadingContainer>
  );
};
