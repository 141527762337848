import clsx from "clsx";
import moment from "moment";
import { FaRegEdit } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { BsCalendar3, BsEye, BsDownload } from "react-icons/bs";
import { useCallback, useEffect, useMemo, useState, ReactNode, useRef } from "react";
import {
  AiOutlineDelete,
  AiOutlineLock,
  AiOutlineUnlock,
} from "react-icons/ai";
import {
  Col,
  Collapse,
  DatePicker,
  Descriptions,
  List,
  message,
  Row,
  Skeleton,
  Space,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import apis, { NOT_AUTHORIZED } from "../../services/apis";
import Button from "../../components/Button";
import { Page } from "../../constants/brokers";
import HandleTag from "../../services/handleTag";
import { Container, Content } from "../Darf/styles";
import { useAuth } from "../../contexts/AuthContext";
import { FormModal } from "../../components/FormModal";
import { apiPayment } from "../../services/apiPayment";
import { InterestModal } from "./InterestModalContent";
import { useBroker } from "../../contexts/BrokerContext";
import { DrawerModal } from "../../components/DrawerModal";
import { PaymentModal } from "../../components/PaymentModal";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import {
  DarfResultDescriptionsExterior,
  ExteriorFormItemRows,
  ExteriorNewFormItemRows,
} from "../../constants/darfExterior";
import {
  DatePickerMonthCell,
  YearResume,
} from "../Darf/Components/DatePickerMonthCell";
import {
  ExteriorModalContent,
  ExteriorOperationType,
} from "./ExteriorModalContent";
import {
  antDatePickerLocale,
  ativarDarfsRetroativos,
  download,
  errorMessage,
  formatCurrency,
  isMobile,
  isNotUndefOrNull,
  mensagemDarfsRetroativos,
  monthsExtended,
  numberToPercentageWallet,
  planInfoToHasPlan,
} from "../../utils";
import {
  defaultDarf,
  IDarf,
  minDarfPrice,
  maxDarfPrice,
  YearResumeStatusFromBackEnum,
  IQuotes,
  historicoVendasEmptyText,
  maxPixPayment,
  maxCreditCardPayment,
  yearConfigPL4173,
  DarfExteriorTabs,
  PrejuFormItemRows,
  DarfImpostosDevidosDescriptions
} from "../../constants/darf";
import { BolsaOperations } from "../DarfBolsa/styles";
import { useDarfExterior } from "./useDarfExterior";

interface DarfProps {
  item: Page;
  closeModal: () => void;
  view?: boolean;
  viewEdit?: boolean;
  date?: {
    year?: number;
    month?: number;
  };
  darf?: {
    id: string;
    fullPath: string;
  };
}

export const DarfExterior: React.FC<DarfProps> = ({
  item,
  view,
  darf,
  date,
  viewEdit,
  closeModal,
}) => {
  const { state } = useLocation();
  const { currentBroker, currentPage } = useBroker();
  const { user, hasPlan, showUserPlanModal, userPlanModal } = useAuth();
  const { getBuyPrice, getFee, getGain, getSellPrice } = useDarfExterior()

  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();
  const queryYear = (state as any)?.year as number;
  const queryMonth = (state as any)?.month as number;
  const initialMonth = queryMonth
    ? queryMonth - 1
    : today.getMonth() - 1 >= 0
    ? today.getMonth() - 1
    : 11;
  const initialYear = queryYear
    ? queryYear
    : today.getMonth() - 1 >= 0
    ? today.getFullYear()
    : today.getFullYear() - 1;
  const defaultValue = moment({ month: initialMonth, year: initialYear });
  const currentDate = moment({
    month: currentMonth,
    year: currentYear,
  }).subtract(1, "M");
  window.history.replaceState({}, document.title);

  const [asset, setAsset] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [, setYear] = useState(initialYear);
  const [emitting, setEmitting] = useState(false);
  const [, setMonth] = useState(initialMonth);
  const [helpModal, setHelpModal] = useState<any>();
  const [data, setData] = useState<IDarf>(defaultDarf);
  const [paymentData, setPaymentData] = useState<IDarf>();
  const [movimentType, setMovimentType] = useState<string>("Venda de ativos");
  const [paymentModal, setPaymentModal] = useState(false);
  const [dataToEditPreju, setDataToEditPreju] = useState();
  const [loadingResetPreju] = useState(false);
  const [loadingEditPreju, setLoadingEditPreju] = useState(false);
  const [quotations, setQuotations] = useState<IQuotes>({});
  const [notAuthorized, setNotAuthorized] = useState(false);
  const [showAssetModal, setShowAssetModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [yearResume, setYearResume] = useState<YearResume[]>([]);
  const [datePickerYear, setDatePickerYear] = useState(initialYear);
  const [gettingYearResume, setGettingYearResume] = useState(false);
  const [loadingDarfButton, setLoadingDarfButton] = useState<boolean>(false);
  const [showEditPrejuConfirmation, setShowEditPrejuConfirmation] = useState<boolean>(false);
  const [showNotAuthorizedModal, setShowNotAuthorizedModal] = useState(false);

  const planMonthSignature = new Date(user?.user?.userPlanInfoVelotax?.currentPlan?.createdAt).getMonth();
  const planYearSignature = new Date(user?.user?.userPlanInfoVelotax?.currentPlan?.createdAt).getFullYear();

  const year = date?.year ?? initialYear;
  const month = date?.month ?? initialMonth;
  const currentDarfDate = new Date(year, month, 15)
  const dateAllowedOnBasic = new Date(today.getFullYear(), today.getMonth() - 1, 1)
  const hideOnBasic = (currentDarfDate.getTime() < dateAllowedOnBasic.getTime() && user.user?.userPlanInfoVelotax?.type?.includes('BASIC'))
  const oldModel = year < yearConfigPL4173;

  const transactionFeature = item.features[0];
  const taxFeature = item.features[1];
  const yearResumeFeature = item.features[2];
  const prejuizoFeature = item.features[3];

  const memoriaCalculoFiltered = data.memoriaCalculo.filter(
    (el: any) =>
      (el.operation === "SELL" &&
        (el.type === "TRADE" || (el.type === "WITHDRAW" || el.type === "WITHDRAWAL"))) ||
      el.type === "INTEREST" ||
      (el.type === "DIVIDEND" && !oldModel)
  );

  const { emitted } = useMemo(() => {
    if (gettingYearResume) {
    }
    const monthResume = yearResume
      .find((resume) => resume.year === year)
      ?.months.find((m) => m.month - 1 === month);
    return {
      emitted:
        monthResume?.status === YearResumeStatusFromBackEnum.PAYED ||
        monthResume?.status === YearResumeStatusFromBackEnum.NOT_PAYED,
      payed: !!monthResume?.payed,
      status: monthResume?.status,
    };
  }, [yearResume, year, month, gettingYearResume]);

  const getDarf = useCallback(
    (id: string) => {
      view && setLoading(true);
      view &&
        (currentPage?.api || apis)
          .get(`${taxFeature.apiUrl}/${id}`)
          .then((response) => {
            setYear(response.data?.year);
            setMonth(response.data?.month - 1);
            setData({
              ...response.data,
              emitted: true,
            });
          })
          .catch(() => message.error(errorMessage))
          .finally(() => setLoading(false));
    },
    [view, taxFeature.apiUrl, currentPage]
  );

  const verifyFromApi = (item = {} as any) => {
    return (Object.values(item).includes(item.saldoBRA) 
      || Object.values(item).includes(item.rendimentoAplicacao)) && oldModel
  }

  const getYearResume = useCallback(
    (year: number, force?: boolean, url?: string) => {
      setGettingYearResume(true);
      !view &&
        year <= new Date().getFullYear() &&
        year >= currentBroker.initialYear &&
        (force ||
          (!yearResumeFeature.disabled &&
            !yearResume.find((resume) => resume.year === year))) &&
        (currentPage?.api || apis)
          .get(yearResumeFeature.apiUrl, { params: { year } })
          .then((response) => {
            const index = yearResume.findIndex(
              (resume) => resume.year === year
            );
            if (index >= 0) {
              setYearResume((yearResume) => {
                yearResume.splice(index, 1, response.data);
                return yearResume;
              });
            } else {
              setYearResume((yearResume) => [...yearResume, response.data]);
            }

            if (url) {
              download(url);
            }
          })
          .catch((err) => console.log(err))
          .finally(() => setGettingYearResume(false));
    },
    [
      yearResumeFeature,
      yearResume,
      view,
      currentBroker.initialYear,
      currentPage,
    ]
  );

  const getTaxes = useCallback(
    (
      nAtualizarDarfsPassadas?: boolean,
      nAtualizarIrrf?: boolean,
      atualizouImpostoAcumulado?: boolean,
      nAtualizarPrejuizosAcumulados?: boolean,
      atualizarTotalmente?: boolean
    ) => {
      (!hasPlan || !view) && setLoading(true);
      (!hasPlan || !view) &&
        (currentPage?.api || apis)
          .get(transactionFeature.apiUrl, {
            params: {
              month: month + 1,
              year,
              nAtualizarDarfsPassadas: nAtualizarDarfsPassadas || false,
              nAtualizarIrrf: nAtualizarIrrf || false,
              atualizouImpostoAcumulado: atualizouImpostoAcumulado || false,
              nAtualizarPrejuizosAcumulados:
                nAtualizarPrejuizosAcumulados || false,
              atualizarTotalmente: atualizarTotalmente || false,
            },
          })
          .then((response) => {
            if (response.data.memoriaCalculo) {
              setData({
                ...response.data,
                multa: response.data?.multaAux || response.data?.multa,
                juros: response.data?.jurosAux || response.data?.juros,
                impostoDevido: response.data?.impostoTotal || response.data?.impostoDevido,
                memoriaCalculo: response.data?.memoriaCalculo.map(
                  (memoCalc: any) => ({
                    ...memoCalc,
                    date: memoCalc?.date ? moment(memoCalc?.date?.slice(0, 10)).format(
                      "DD/MM/YYYY"
                    ) : moment(new Date(memoCalc?.data)).format(
                      "DD/MM/YYYY"
                    ),
                    originalDate: memoCalc?.date,
                    market:
                      memoCalc?.market === "Opção de Compra" ||
                      memoCalc?.market === "Opção de Venda"
                        ? "Mercado de opções"
                        : memoCalc?.market,
                  })
                ),
              });
              setNotAuthorized(false);
            }
          })
          .catch((err) => {
            setData(defaultDarf);
            if (err?.response?.data === NOT_AUTHORIZED) {
              setNotAuthorized(true);
              setShowNotAuthorizedModal(true);
            } else {
              setNotAuthorized(false);
              message.error(errorMessage);
            }
          })
          .finally(() => setLoading(false));
    },
    [month, year, transactionFeature, view, currentPage, hasPlan]
  );

  const getQuotations = useCallback(() => {
    !view && setLoading(true);
    !view &&
      (currentPage?.api || apis)
        .get("/transaction/get-cotacoes")
        .then((response) => {
          const { value: cotacoes } = response.data;
          const pojo = {};
          for (let cotacao of cotacoes) {
            const {
              dataHoraCotacao,
              cotacaoCompra: compra,
              cotacaoVenda: venda,
            } = cotacao;
            const [year, month, day] = String(dataHoraCotacao)
              .split("T")[0]
              .split("-");
            Object.assign(pojo, {
              [`${day}/${month}/${year}`]: {
                compra,
                venda,
              },
            });
          }
          setQuotations(pojo);
        })
        .catch((err) => {
          setData(defaultDarf);
          if (err?.response?.data === NOT_AUTHORIZED) {
            setNotAuthorized(true);
            setShowNotAuthorizedModal(true);
          } else {
            setNotAuthorized(false);
            message.error(errorMessage);
          }
        })
        .finally(() => setLoading(false));
  }, [view, currentPage?.api]);

  const handleDarf = (justSave?: boolean) => {
    setEmitting(true);
    (currentPage?.api || apis)
      .post(taxFeature.apiUrl, {
        ...data,
        year,
        month: month + 1,
        name: user.user.name,
        memoriaCalculo: [],
        transactions: data.memoriaCalculo,
        paymentInfo: {
          clientInfo: {
            name: user?.user?.name,
            email: user?.user?.email,
            document: user?.user?.cpf,
          },
        },
        insertPix: true,
        justSave,
        payed: false,
      })
      .then((res) => {
        setData((data) => ({ ...data, emitted: true }));
        getYearResume(year, true, justSave ? "" : res.data.darfUrl);
        setEmitting(false);
        closeModal();
        if (!justSave) {
          oldModel
          ? message.success("O DARF foi enviado para o seu e-mail")
          : message.success("O Relatório foi enviado para o seu e-mail"); 
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || errorMessage);
        setEmitting(false);
      });
  };

  const handleRegularize = (regular: boolean) => {
    setEmitting(true);
    (currentPage?.api || apis)
      .post("transaction/regularize", {
        year,
        month: month + 1,
        regular,
        impostoDevido: data.impostoDevido,
        totalImpostoDevido: data.totalImpostoDevido,
      })
      .then((res) => {
        getYearResume(year, true, res.data.darfUrl);
        getTaxes(false, false, false, true);
        closeModal();
        message.success("Dados salvos");
      })
      .catch(() => message.error(errorMessage))
      .finally(() => setEmitting(false));
  };

  const onChangeMonth = (value: moment.Moment | null, dateString: string) => {
    const [month, year] = dateString.split("-");
    setMonth(parseInt(month) - 1);
    setYear(parseInt(year));
  };

  const unsetAsset = () => {
    setAsset(undefined);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    unsetAsset();
  };

  const handleCloseAssetModal = () => {
    setShowAssetModal(false);
    unsetAsset();
  };

  const onCloseHelpModal = () => {
    setHelpModal(undefined);
  };

  const handleOpenDarfModal = () => {
    if (
      ativarDarfsRetroativos({
        item: { month: month + 1, year },
        currentMonth,
        currentYear,
        hasPlan: hasPlan,
        planMonthSignature: planMonthSignature === 0 ? 11 : planMonthSignature - 1,
        planYearSignature: planMonthSignature === 0 ? planYearSignature - 1 : planYearSignature
      })
    ) {
      handleDarf();
    } else {
      handlePlanModal();
    }
  };

  const handleOpenPaymentModal = () => {
    setPaymentModal(!paymentModal);
  };

  const handleAdd = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    setShowAssetModal(true);
  };

  const handleEdit = (item: any, index: number) => {
    setShowAssetModal(true);
    const data = moment.utc(item.data.toLocaleString()).format("DD/MM/YYYY");
    let i = 0;
    let key = data;
    while (!quotations[key] && i <= 7) {
      key = moment(key, "DD/MM/YYYY").subtract(1, "days").format("DD/MM/YYYY");
      i++;
    }

    setAsset({
      ...item,
      data,
      cotacaoVenda: quotations[key]?.venda,
      cotacaoCompra: quotations[key]?.compra,
      dataVenda: moment
        .utc(item?.dataVenda?.toLocaleString())
        ?.format("DD/MM/YYYY"),
      dataCompra: moment
        .utc(item?.dataCompra?.toLocaleString())
        ?.format("DD/MM/YYYY"),
    });
  };

  const handleRemove = (item: any, index: number) => {
    setShowDeleteModal(true);
    setAsset(item);
  };

  const addAsset = (asset: any) => {
    setLoading(true);

    const ganho = getGain(asset, oldModel)
    const sellPrice = getSellPrice(asset, oldModel)
    const buyPrice = getBuyPrice(asset, oldModel)
    const fee = getFee(asset, oldModel)

    const dataToCreate = {
      ...{[sellPrice.key]: sellPrice.value},
      ...{[fee.key]: fee.value},
      ...{[ganho.key]: ganho.value},
      ...{[buyPrice.key]: buyPrice.value},
      data: asset.data, // date liquidação venda
      dataCompra: asset.dataCompra, // pegar a cotação
      operation: "SELL", // fixo
      ativo: asset.ativo, // Tiker que o usuário colocar (Ativo)
      valor: asset.valor, // (Valor Bruto (US$))
      quantidade: asset.quantidade,
      fee: asset.fee, // (Taxa de Corretagem (US$))
      type: 'TRADE',
      // "saldoBRA": 10000,
      // "saldoEUA": 870.7999999999993,
      // "capitalGainBRA_BRL": 0, (colocar no ganho)
      // "capitalGainEUA_BRL": 0,
      ganho: asset.ganho, // (capitalGainBRA_BRL)
      cambioVenda: asset.cambioVenda, // (C) o cambio venda na pagina 1 ta na venda
      cambioCompra: asset.cambioCompra, // (C) o cambio venda na pagina 1 ta na venda
      // "cambioReferencia": 0,
      valueOriginBRA: asset.valueOriginBRA, // Origem BRA (US$) ??
      valueOriginEUA: asset.valueOriginEUA, // Origem USA (US$) ??
      movimentType: asset.movimentType
    };
    
    const dataFeeToCreate = {
      ...{[ganho.key]: ganho.value},
      // ganho: asset.ganhoFee,
      ...{[sellPrice.key]: sellPrice.value},
      ...{[fee.key]: fee.value}, // TODO: VALIDATE THIS
      data: asset.dataFee,
      dataCompra: asset.dataFee, // date liquidação venda
      operation: "BUY", // fixo
      type: asset.movimentType === 'Dividendos' ? "DIVIDEND" : "INTEREST", // fixo
      ativo: asset.ativo, // Tiker que o usuário colocar (Ativo)
      valor: asset.valor, // (Valor Bruto (US$))
      year,
      month,
      fee: asset.fee, // (Taxa de Corretagem (US$))
      ganho: asset.ganhoFee,
      rendimentoAplicacao: asset.ganhoFee, // (capitalGainBRA_BRL)
      cambioVenda: asset.cambioVendaFee, // (C) o cambio venda na pagina 1 ta na venda
      movimentType: asset.movimentType
    };

    (currentPage?.api || apis)
      .post(
        `${transactionFeature.apiUrl}?manual=true`,
        (asset.movimentType && asset.movimentType !== "Venda de ativos")
          ? dataFeeToCreate
          : dataToCreate,
        {
          headers: {
            "x-email": user.user.email,
          },
        }
      )
      .then(() => {
        getTaxes(true, false, false, true, true);
        getYearResume(year, true);
      })
      .catch(() => message.error(errorMessage))
      .finally(() => {
        setLoading(false);
        handleCloseAssetModal();
      });
  };

  const editAsset = (data: any) => {
    const ganho = getGain(data);
    const sellPrice = getSellPrice(data, oldModel)
    const buyPrice = getBuyPrice(data, oldModel)
    const fee = getFee(data, oldModel)
    const dataToEdit = {
      ...{[ganho.key]: ganho.value}, // (capitalGainBRA_BRL)
      ...{[fee.key]: fee.value},
      ...{[sellPrice.key]: sellPrice.value},
      ...{[buyPrice.key]: buyPrice.value},
      data: data.data, // date liquidação venda
      dataCompra: data.dataCompra, // pegar a cotação
      type: "TRADE",
      operation: "SELL", // fixo
      ativo: data.ativo, // Tiker que o usuário colocar (Ativo)
      valor: data.valor, // (Valor Bruto (US$))
      "quantidade": data.quantidade,
      fee: data.fee, // (Taxa de Corretagem (US$))
      // "saldoBRA": 10000,
      // "saldoEUA": 870.7999999999993,
      // "capitalGainBRA_BRL": 0, (colocar no ganho)
      // "capitalGainEUA_BRL": 0,
      ganho: data.ganho, // (capitalGainBRA_BRL)
      cambioVenda: data.cambioVenda, // (C) o cambio venda na pagina 1 ta na venda
      cambioCompra: data.cambioCompra, // (C) o cambio venda na pagina 1 ta na venda
      // "cambioReferencia": 0,
      valueOriginBRA: data.valueOriginBRA, // Origem BRA (US$) ??
      valueOriginEUA: data.valueOriginEUA, // Origem USA (US$) ??
      manual: data.manual,
    };
    setLoading(true);
    (currentPage?.api || apis)
      .put(`${transactionFeature.apiUrl}/${asset._id}`, dataToEdit)
      .then(() => {
        getTaxes(true, false, false, true, true);
        getYearResume(year, true);
      })
      .catch(() => message.error(errorMessage))
      .finally(() => {
        setLoading(false);
        handleCloseAssetModal();
      });
  };

  const removeAsset = () => {
    setLoading(true);
    (currentPage?.api || apis)
      .delete(`${transactionFeature.apiUrl}/${asset._id}`)
      .then(() => {
        getTaxes();
        getYearResume(year, true);
      })
      .catch(() => message.error(errorMessage))
      .finally(() => {
        setLoading(false);
        handleCloseDeleteModal();
      });
  };

  const [tab] = useState(1);
  const bolsaOperationsRef = useRef<HTMLDivElement>(null);

  /* Editar prejuízo acumulado */
  const [resetedPreju, setResetedPreju] = useState(false);
  const [showPrejuModal, setShowPrejuModal] = useState(false);

  const preju = useMemo(
    () => ({
      userPrejuizoComum: resetedPreju
        ? data.prejuizoComum
        : data.userPrejuizoComum ?? data.prejuizoComum,
      userPrejuizoDayTrade: resetedPreju
        ? data.prejuizoDayTrade
        : data.userPrejuizoDayTrade ?? data.prejuizoDayTrade,
      userPrejuizoFII: resetedPreju
        ? data.prejuizoFII
        : data.userPrejuizoFII ?? data.prejuizoFII,
    }),
    [data, resetedPreju]
  );

  // const handleResetPreju = () => {
  //   setResetedPreju(true);
  // };

  const handleEditPreju = () => {
    setResetedPreju(false);
    setShowPrejuModal(true);
  };

  const handleEditPrejuData = (preju: any) => {
    setDataToEditPreju(preju);
    setShowEditPrejuConfirmation(true);
  };

  const handleClosePrejuModal = () => {
    setShowPrejuModal(false);
  };

  const editPreju = () => {
    setLoadingEditPreju(true);
    setShowEditPrejuConfirmation(false);

    (currentPage?.api || apis)
      .put(
        `${prejuizoFeature.apiUrl}?month=${month + 1}&year=${year}`,
        dataToEditPreju
      )
      .then((res) => {
        message.success("Dados salvos");
        getTaxes(true, true, false, true);
        setLoadingEditPreju(false);
        handleClosePrejuModal();
      })
      .catch(() => {
        message.error(errorMessage);
        setLoadingEditPreju(false);
        handleClosePrejuModal();
      });
  };
  /* Fim do editar prejuízo acumulado */

  const handlePlanModal = () => {
    showUserPlanModal(!userPlanModal);
  };

  const handleSaveMonth = async () => {
    setLoadingDarfButton(true);
    try {
      const retPlanInfo = await apiPayment.get("/user-plan/plan-info");

      const hasPlan = planInfoToHasPlan(retPlanInfo.data);
      ativarDarfsRetroativos({
        item: { month: month + 1, year },
        currentMonth,
        currentYear,
        hasPlan,
        planMonthSignature: planMonthSignature === 0 ? 11 : planMonthSignature - 1,
        planYearSignature: planMonthSignature === 0 ? planYearSignature - 1 : planYearSignature
      })
        ? handleDarf(true)
        : handlePlanModal();
    } catch (err) {
      console.error(err);
    }
    setLoadingDarfButton(false);
  };

  const handleEventDarfButton = async () => {
    setLoadingDarfButton(true);
    let retPlanInfo = {
      data: {
        active: false,
        type: "",
      },
    };
    try {
      retPlanInfo = await apiPayment.get("/user-plan/plan-info");
    } catch (err) {
      console.error(err);
    }
    setLoadingDarfButton(false);

    const hasPlan = planInfoToHasPlan(retPlanInfo.data);
    ativarDarfsRetroativos({
      item: { month: month + 1, year },
      currentMonth,
      currentYear,
      hasPlan,
      planMonthSignature: planMonthSignature === 0 ? 11 : planMonthSignature - 1,
      planYearSignature: planMonthSignature === 0 ? planYearSignature - 1 : planYearSignature

    })
      ? handleOpenPaymentModal()
      : handlePlanModal();
  };

  const handleLabelButton: ReactNode = useMemo(() => {
    if(!oldModel) return "EMITIR RELATÓRIO"
    else if (data?.totalImpostoDevido <= maxDarfPrice) return "Pagar DARF";
    else {
      if (emitted) return "Emitir novo DARF";
      else return taxFeature?.buttonLabel;
    }
  }, [data?.totalImpostoDevido, emitted, oldModel, taxFeature?.buttonLabel]);

  const impostoCharge = useMemo(() => {
    const taxes = ((data.impostoDevido ?? 0) - (data.irrfExterior ?? 0))
    const isExceedTax = taxes < minDarfPrice;
    const additionalTax = oldModel ? data.multa + data.juros : 0;
    return !view
      ? taxes +
          (isExceedTax ? 0 : additionalTax) 
      : Math.max(
          taxes +
            (isExceedTax ? 0 : additionalTax) ,
          0
        );
  }, [data, view, oldModel]);

  const calcReturn = (
    sellPrice: number,
    buyPrice: number,
    quantity: number,
    fee: number,
    ganho?: number
  ) => {
    let ret = 0
    if(ganho) {
      const qtt = quantity ? quantity : 1;
      ret =
      sellPrice && buyPrice
      ? (sellPrice * qtt - (fee || 0)) / (buyPrice * qtt) - 1
      : 0;
    }
    return (
      <strong
        style={{
          color: `${ret >= 0
            ? "var(--velotax-green-amount)"
            : "var(--velotax-red-prejuizo)"
            }`,
        }}
      >
        {ret ? numberToPercentageWallet(ret) : "-"}
      </strong>
    );
  };

  useEffect(() => {
    getTaxes();
  }, [getTaxes, month, year]);

  useEffect(() => {
    getQuotations();
  }, [getQuotations, month, year, item]);

  useEffect(() => {
    if (view && darf) {
      getDarf(darf.id);
    }
  }, [getDarf, view, darf]);

  useEffect(() => {
    getYearResume(year);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const dataToPayment = {
      ...data,
      year,
      month: month + 1,
      name: user.user.name,
      memoriaCalculo: [],
      transactions: data.memoriaCalculo,
    };

    setPaymentData(dataToPayment);
  }, [data.totalImpostoDevido, data, month, user.user.name, year]);

  const downloadAction = (record: any) => {
    (currentPage?.api || apis)
      .get(`/darf/download/${record._id}`)
      .then((res) => {
        if (res.data.url) {
          download(res.data.url);
        }
      });
  };

  const allow = ativarDarfsRetroativos({
    item: { month: month + 1, year },
    currentMonth,
    currentYear,
    hasPlan,
    planMonthSignature: planMonthSignature === 0 ? 12 : planMonthSignature,
    planYearSignature: planMonthSignature === 0 ? planYearSignature - 1 : planYearSignature

  });
  console.log(month, year, planMonthSignature, planYearSignature);

  return (
    <Container className={clsx({ view, "view-edit": viewEdit })}>
      <Content className={clsx({ view, "view-edit": viewEdit })}>
        <Space direction="vertical" className="full-width">
          <Row gutter={[8, 16]} align="middle" justify="space-between">
            <Col xs={24} sm={16}>
              <Typography.Title level={3}>
                <BsCalendar3 />
                {!view && !viewEdit && (
                  <>
                    {currentBroker.initialYear === year && month === 0 ? (
                      <div className="month-chevron empty" />
                    ) : (
                      <BiChevronLeft
                        className="month-chevron"
                        onClick={() => {
                          if (month === 0) {
                            setYear((year) => {
                              const newYear = year - 1;
                              getYearResume(newYear);
                              setDatePickerYear(newYear);
                              return newYear;
                            });
                            setMonth(11);
                          } else {
                            setMonth((month) => month - 1);
                          }
                        }}
                      />
                    )}
                  </>
                )}
                {monthsExtended[month]} de {year}
                {!view && !viewEdit && (
                  <>
                    {currentYear === year && currentMonth - 1 === month ? (
                      <div className="month-chevron empty" />
                    ) : (
                      <BiChevronRight
                        className="month-chevron"
                        onClick={() => {
                          if (month === 11) {
                            setYear((year) => {
                              const newYear = year + 1;
                              getYearResume(newYear);
                              setDatePickerYear(newYear);
                              return newYear;
                            });
                            setMonth(0);
                          } else {
                            setMonth((month) => month + 1);
                          }
                        }}
                      />
                    )}
                  </>
                )}
              </Typography.Title>
            </Col>
            {!view && !viewEdit && (
              <Col xs={24} sm={8}>
                <DatePicker
                  size="large"
                  picker="month"
                  format="MM-YYYY"
                  disabled={loading}
                  allowClear={false}
                  inputReadOnly={true}
                  onChange={onChangeMonth}
                  suffixIcon={<BsCalendar3 />}
                  defaultValue={defaultValue}
                  locale={antDatePickerLocale}
                  value={moment({ year: datePickerYear, month })}
                  onSelect={(e) => {
                    if (e.month() === month && year !== datePickerYear) {
                      onChangeMonth(e, `${month + 1}-${datePickerYear}`);
                    }
                  }}
                  onPanelChange={(e) => {
                    getYearResume(e.year());
                    setDatePickerYear(e.year());
                  }}
                  getPopupContainer={(target) => target.parentElement!}
                  disabledDate={(current) =>
                    current &&
                    (current > currentDate.endOf("month") ||
                      current < moment(`${currentBroker.initialYear}-01-01`) ||
                      current < moment(`2020-01-01`))
                  }
                  monthCellRender={(e) => (
                    <DatePickerMonthCell event={e} yearResume={yearResume} />
                  )}
                />
              </Col>
            )}
          </Row>
        </Space>
      </Content>

      <br />

      <Content className={clsx("second", { view, "view-edit": viewEdit })}>
        <Space direction="vertical" className="full-width">
          {allow && view && darf?.fullPath && (
            <Row justify="end">
              <Col>
                <Button
                  type="link"
                  icon={<BsDownload size={16} />}
                  onClick={() => downloadAction(darf)}
                  style={{ marginTop: "-32px", padding: "4px 0" }}
                >
                  BAIXAR DARF
                </Button>
              </Col>
            </Row>
          )}
          <BolsaOperations ref={bolsaOperationsRef}>
            {!allow && (
              <div className="no-plan-container">
                <Typography>
                  Contrate o plano premium para ver os impostos devidos
                </Typography>
                <Button
                  type="primary"
                  icon={<AiOutlineLock size={18} />}
                  onClick={() => {
                    handlePlanModal();
                    closeModal();
                  }}
                >
                  PREMIUM
                </Button>
              </div>
            )}
            <Descriptions size="small" bordered column={1}>
              {DarfImpostosDevidosDescriptions.map((description) => {
                if (description.id === 'prejuizoExterior' && oldModel) return null

                return (
                  <Descriptions.Item
                    key={description.id}
                    label={description.label(
                      setHelpModal,
                      year
                      // () => {setOpenPrejuizoModal(true)}
                    )}
                  >
                    {loading ? (
                      <Spin />
                    ) : description.render ? (
                      description.render(data, view, year)
                    ) : (
                      formatCurrency(
                        Number(data[description.id as keyof IDarf] || 0)
                      )
                    )}
                  </Descriptions.Item>
                )
              })}
            </Descriptions>
            {/* {DarfExteriorTabs(data, {
              handleEditPreju,
              view,
              month,
              year,
            }).map((container, rowIndex) => (
              <div key={rowIndex} className={container.class}>
                {container.tabs.map((item, colIndex) => (
                  <span
                    key={colIndex}
                    className={clsx(
                      colIndex === 0 || tab === colIndex ? "show" : "",
                      {
                        "no-plan": !allow && rowIndex > 0 && colIndex > 0,
                      }
                    )}
                  >
                    {!allow && rowIndex > 0 && colIndex > 0
                      ? formatCurrency(13443)
                      : isMobile() && !allow && rowIndex === 0
                      ? ""
                      : item.content}
                  </span>
                ))}
              </div>
            ))} */}
          </BolsaOperations>

          <br />

          <Collapse
            ghost
            bordered={false}
            expandIconPosition="right"
            defaultActiveKey={!allow ? ["1"] : []}
            {...(!allow ? { collapsible: "disabled" } : {})}
          >
            <Collapse.Panel
              key="1"
              header={
                <Typography.Title level={3}>
                  Vendas realizadas no mês
                </Typography.Title>
              }
            >
              {!view && (
                <Button
                  type="primary"
                  className="add"
                  disabled={loading}
                  onClick={handleAdd}
                  icon={<PlusOutlined />}
                >
                  Adicionar
                </Button>
              )}
              <List
                loading={loading}
                itemLayout="horizontal"
                locale={{
                  emptyText: historicoVendasEmptyText,
                }}
                dataSource={
                  memoriaCalculoFiltered
                }
                renderItem={(item, index) => {
                  return (
                    <List.Item
                      actions={
                        view
                          ? []
                          : [
                              <Button
                                type="text"
                                onClick={() => handleEdit(item, index)}
                                icon={
                                    verifyFromApi(item) || (item.type !== 'TRADE' && !oldModel) ? (
                                    <BsEye size={18} />
                                    ) : (
                                    <FaRegEdit size={18} />
                                  )
                                }
                              />,
                              <Button
                                type="text"
                                onClick={() => handleRemove(item, index)}
                                icon={<AiOutlineDelete size={18} />}
                              />,
                            ]
                        }
                      >
                        {oldModel ?
                          <Skeleton title={false} loading={item.loading} active>
                            <List.Item.Meta
                              title={<strong>{item.ativo}</strong>}
                              description={
                                <div className="list-description">
                                  {item.data && (
                                    <div>
                                      Data:{" "}
                                      {moment
                                        .utc(item?.data?.toLocaleString())
                                        .format("DD/MM/YYYY")}
                                    </div>
                                  )}
                                  <div>
                                    {item.type !== "INTEREST" || !item.type
                                      ? "Ganho"
                                      : "Rendimento total"}
                                    :{" "}
                                    <strong>
                                      {formatCurrency(
                                        item.type === "INTEREST"
                                          ? item.rendimentoAplicacao
                                          : getGain(item, oldModel).value
                                      )}
                                    </strong>
                                  </div>
                                  {view && (
                                    <>
                                      <div>
                                        {item.type !== "INTEREST"
                                          ? "Valor de venda"
                                          : "Valor bruto"}
                                        :{" "}
                                        <strong>
                                          {formatCurrency(item.valor, "US$ ")}
                                        </strong>
                                      </div>
                                      <div>
                                        Taxa de corretagem:{" "}
                                        <strong>
                                          {formatCurrency(item.fee, "US$ ")}
                                        </strong>
                                      </div>
                                      <div>
                                        Cotação dólar:{" "}
                                        <strong>{item.cambioVenda}</strong>
                                      </div>
                                      {item.type !== "INTEREST" && (
                                        <>
                                          <div>
                                            Valor de compra (Origem BRA):{" "}
                                            <strong>
                                              {formatCurrency(
                                                item.valueOriginBRA,
                                                "US$ "
                                              )}
                                            </strong>
                                          </div>
                                          <div>
                                            Valor de compra (Origem EUA):{" "}
                                            <strong>
                                              {formatCurrency(
                                                item.valueOriginEUA,
                                                "US$ "
                                              )}
                                            </strong>
                                          </div>
                                        </>
                                      )}
                                    </>
                                  )}
                                </div>
                              }
                            />
                          </Skeleton>
                        : <Skeleton title={false} loading={item.loading} active>
                            <List.Item.Meta
                              title={
                                <strong>{item.ativoTranslated ? item.ativoTranslated : item.ativo}</strong>
                              }
                              description={
                                <div className="list-description">
                                  <div>
                                    Data:{" "}
                                    {moment
                                      .utc(item?.data?.toLocaleString())
                                      .format("DD/MM/YYYY")}
                                  </div>
                                  {item.type === "TRADE" && <div>Quantidade: {item.quantidade}</div>}
                                  <div>
                                    {item.type === "TRADE"
                                      ? "Ganho"
                                      : "Rendimento total"}
                                    :{" "}
                                    <strong>
                                      {formatCurrency(
                                        item.type !== "TRADE"
                                          ? item.rendimentoAplicacao
                                          : (item.varTotalBRL ?? 0)
                                      )}
                                    </strong>
                                  </div>
                                  {item.type === 'TRADE' && <div>
                                    Retorno: {calcReturn(
                                      getSellPrice(item, oldModel).value,
                                      getBuyPrice(item, oldModel).value,
                                      item.quantidade,
                                      getFee(item, oldModel).value,
                                      getGain(item, oldModel).value
                                    )}
                                  </div>}
                                </div>
                              }
                            />
                          </Skeleton>
                        }
                      </List.Item>
                  )
                }}
              />
            </Collapse.Panel>
          </Collapse>

          {allow && <br />}

          {allow && (
            <div
              className="total-tax-header original"
              style={{ width: "100%" }}
            >
              <Typography.Title level={3}>Imposto total</Typography.Title>
              <Typography.Title level={3} className="text-center">
                {loading ? (
                  <Spin style={{ marginRight: "32px" }} />
                ) : (
                  formatCurrency(Math.max(impostoCharge, 0))
                )}
              </Typography.Title>
            </div>
          )}

          {allow && (
            <Descriptions size="small" bordered column={1}>
              {DarfResultDescriptionsExterior.map((description, i) => {
                if (['impostoAcumulado', 'multa', 'juros'].includes(description.id) && !oldModel) return <></>
                return (
                  <Descriptions.Item
                    key={description.id}
                    label={description.label(setHelpModal)}
                    style={{
                      display:
                        i === 3 ? "none"
                          : i === 4 && oldModel ? "none"
                            : "table-cell",
                    }}
                  >
                    {loading ? (
                      <Spin />
                    ) : description.Component ? (
                      <description.Component
                        data={data}
                        year={year}
                        month={month}
                        loading={loading}
                        setDataCripto={setData}
                        url={transactionFeature.apiUrl}
                        disabled={!notAuthorized && !view}
                        view={view}
                      />
                    ) : (
                      formatCurrency(
                        Number(data[description.id as keyof IDarf] || 0)
                      )
                    )}
                  </Descriptions.Item>
                )
              })}
            </Descriptions>
          )}

          {!view && (
            <>
              <Row justify="end" gutter={[16, 16]} className="column-reverse">
                {allow &&
                  !(month === currentMonth && year === currentYear) &&
                  oldModel &&
                  (data.impostoDevido + data.impostoAcumulado ?? 0) <
                    minDarfPrice &&
                    memoriaCalculoFiltered?.length > 0 && (
                    <Col xs={24}>
                      <br />
                      <Tooltip
                        title={
                          <>
                            O valor do imposto devido é menor que{" "}
                            {formatCurrency(minDarfPrice)}. Você não precisa
                            emitir um DARF para este mês. Clique aqui para
                            regularizar este mês.
                          </>
                        }
                      >
                        <Button
                          block
                          size="large"
                          type="primary"
                          loading={emitting}
                          disabled={loading || emitting}
                          onClick={() => handleRegularize(true)}
                        >
                          Salvar sem emitir DARF
                        </Button>
                      </Tooltip>
                    </Col>
                  )}
                  {(((data.impostoDevido + data.impostoAcumulado - (data?.irrfExterior ?? 0)) >= minDarfPrice) || (!oldModel && memoriaCalculoFiltered.length > 0 )) && (
                  <Col xs={24}>
                    <br />
                    <Tooltip
                      getPopupContainer={(target) => target.parentElement!}
                      title={
                        !allow
                          ? mensagemDarfsRetroativos
                          : month === currentMonth && year === currentYear && oldModel
                          ? "Aguarde o encerramento do mês para pagar seu DARF"
                          : ""
                      }
                    >
                      <Button
                        block
                        size="large"
                        type="primary"
                        onClick={() => {
                          oldModel 
                          ? handleEventDarfButton()
                          : handleDarf()
                        }}
                        icon={!allow && <AiOutlineUnlock />}
                        loading={emitting || loadingDarfButton}
                        disabled={
                          loading ||
                          emitting ||
                          (month === currentMonth && year === currentYear && oldModel)
                        }
                      >
                        {handleLabelButton}
                      </Button>{" "}
                    </Tooltip>
                  </Col>
                )}
              </Row>
                {(!(month === currentMonth && year === currentYear) && oldModel) && (
                <Row gutter={[16, 16]} justify="end" align="middle">
                  <Col>
                    <Button
                      type="link"
                      size="large"
                      onClick={handleSaveMonth}
                      disabled={loading || emitting}
                      icon={!allow && <AiOutlineUnlock />}
                    >
                      Salvar apenas
                    </Button>
                  </Col>
                </Row>
              )}
            </>
          )}
        </Space>
      </Content>

      <DrawerModal
        footer={null}
        visible={helpModal}
        title={helpModal?.title}
        onCancel={onCloseHelpModal}
      >
        {helpModal?.content}
      </DrawerModal>
      <FormModal
        add={editPreju}
        edit={() => {}}
        rows={[]}
        itemToEdit={null}
        loading={loadingEditPreju}
        visibility={showEditPrejuConfirmation}
        saveButtonText={"Sim"}
        title={"Editar prejuízos"}
        onCancel={() => setShowEditPrejuConfirmation(false)}
      >
        <div
          style={{
            color: "var(--velotax-font-color-dark)",
          }}
        >
          Você tem certeza que deseja editar os valores calculados de prejuízos
          acumulados?
        </div>
      </FormModal>
      <FormModal
        add={addAsset}
        edit={editAsset}
        loading={loading}
        itemToEdit={asset}
        visibility={showAssetModal}
        onCancel={handleCloseAssetModal}
        hideFooter={
          asset &&
          (Object.values(asset).includes(asset?.saldoBRA) ||
            Object.values(asset).includes(asset?.rendimentoAplicacao))
          && oldModel
        }
        rows={
          asset && (
            (Object.values(asset).includes(asset.saldoBRA) &&
              asset.saldoBRA !== 0 && oldModel) ||
            (!oldModel && asset.type !== 'TRADE')
          )
            ? []
            : oldModel ? ExteriorFormItemRows(
              month,
              year,
              quotations,
            ) : ExteriorNewFormItemRows(
              month,
              year,
              quotations,
              editAsset,
              asset,
              movimentType,
              setMovimentType,
              false
            )
        }
        title={
          asset
            ? asset.type !== "TRADE"
              ? "Movimentação"
              : (Object.values(asset).includes(asset.saldoBRA) && oldModel)
                ? asset.ativoTranslated || asset.ativo
                : oldModel ? "Editar movimentação"
                  : asset.ativoTranslated || asset.ativo
            : "Adicionar movimentação"
        }
        children={
          !asset ? null : asset.type !== "TRADE" ? (
            <InterestModal asset={asset} />
          ) : (
            Object.values(asset).includes(asset.saldoBRA) && oldModel && (
              <ExteriorModalContent asset={asset} />
            )
          )
        }
      />
      <FormModal
        add={() => {}}
        edit={(data: any) => handleEditPrejuData(data)}
        itemToEdit={preju}
        loading={loadingEditPreju}
        disableButtons={loadingResetPreju || loadingEditPreju}
        rows={PrejuFormItemRows(loadingResetPreju || loadingEditPreju)}
        visibility={showPrejuModal}
        onCancel={handleClosePrejuModal}
        title="Editar prejuízos a compensar"
      >
      </FormModal>
      <PaymentModal
        item={item}
        show={paymentModal}
        selectedYear={year}
        emitting={emitting}
        maxPixValue={maxPixPayment}
        closeDarfModal={closeModal}
        onCancel={handleOpenPaymentModal}
        maxCCValue={maxCreditCardPayment}
        callDarf={() => {
          handleOpenDarfModal();
          HandleTag("77");
        }}
        disablePixOption={data?.totalImpostoDevido > maxPixPayment}
        disableCreditCardOption={
          data?.totalImpostoDevido > maxCreditCardPayment
        }
        paymentData={{
          ...paymentData,
          impostoTotalFinal: data.totalImpostoDevido + data.impostoAcumulado,
        }}
      />
      <DeleteConfirmationModal
        title="Remover ativo"
        visibility={showDeleteModal}
        onCancel={handleCloseDeleteModal}
        setVisibility={setShowDeleteModal}
        onOk={() => Promise.resolve(removeAsset())}
        body="Você realmente quer deletar esse ativo?"
      />
    </Container>
  );
};
