import styled from "styled-components";
import VeloIcon from "../../assets/velotax/veloicon.svg";
import VelotaxBackground from "../../assets/velotax/velotax-background.svg";

export const Container = styled.div`
  display: none;

  @media only screen and (max-device-width: 812px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 64px;
    padding: 0 20px;
    background-color: var(--ant-info-color);
    background-repeat: no-repeat;
    background-image: url(${VelotaxBackground});
    .left-side {
      display: flex;
      align-items: center;
      span.veloicon {
        height: 36px;
        width: 23.2px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(${VeloIcon});
        margin-right: 8px;
      }
      div {
        display: flex;
        flex-direction: column;
      }
      h1 {
        margin: 0;
        font-size: 0.875rem;
        color: var(--white);
        font-weight: bold;
      }
      p {
        margin: 0;
        font-size: 0.75rem;
        color: var(--white);
      }
    }
    .ant-btn.ant-btn-lg.ant-btn-primary {
      height: 40px;
      font-size: 0.875rem;
      padding-top: 6.4px !important;
    }
  }
`;
