import { Typography } from "antd";
import Button from "../../../../components/Button";

export const DatePickerExtraFooter: React.FC<any> = ({ year, onClick }) => {
  return (
    <div>
      {year >= 2020 && (
        <Button
          type="primary"
          onClick={onClick}
          style={{ marginTop: "0.75rem", width: "100%" }}
        >
          <Typography.Text style={{ color: "white" }}>
            Emitir DARF de todos os meses pendentes
          </Typography.Text>
        </Button>
      )}
      <Typography.Text strong>Situação:</Typography.Text>
      <div className="sample-regular">
        <span>Imposto apurado</span>
      </div>
      <div className="sample-pending">
        <span>Imposto devido</span>
      </div>
      <div className="sample-not-payed">
        <span>Não há imposto devido</span>
      </div>
    </div>
  );
};
