import { Typography } from "antd";
import styled from "styled-components";

interface IContainerProps {
  isMobile: boolean;
}
export const LoadingContainer = styled.div<IContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: ${({ isMobile }) =>
    isMobile ? "rgb(0 0 0 / 70%)" : "rgb(0 0 0 / 60%)"};
  z-index: 9999;
  backdrop-filter: blur(4px);
  img {
    width: 250px;
    padding: 20px;
  }
  .anticon {
    color: var(--white);
    width: 40px !important;
    height: 40px !important;
    svg {
      width: 40px !important;
      height: 40px !important;
    }
  }
`;

export const LogoContainer = styled.div<IContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled(Typography.Title)`
  max-width: 600px;
  font-size: 16px;
  color: white !important;
  margin-top: 1rem;
`;

export const Paragraph = styled.p`
  max-width: 600px;
  text-align: center;
  font-size: 16px;
  color: white !important;
`;
