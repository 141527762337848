import { Typography } from "antd";
import { VscHistory } from "react-icons/vsc";
import { HiOutlineCalculator } from "react-icons/hi";

export const itensImoveis = [
  {
    id: "darf",
    link: "/velotax/imoveis-calculadora",
    content: <Typography>Calculadora de DARF</Typography>,
    description: <Typography>Cálculo de IR e emissão de DARF</Typography>,
    icon: (
      <HiOutlineCalculator
        className="no-fill"
        color="var(--ant-primary-color)"
      />
    ),
  },
  {
    id: "historic",
    link: "/velotax/imoveis-historico",
    content: <Typography>Histórico</Typography>,
    description: (
      <Typography>Histórico de impostos e pagamentos de DARF</Typography>
    ),
    icon: <VscHistory className="no-fill" color="var(--ant-primary-color)" />,
  },
];
