import { Button, Spin, Typography } from "antd";
import { NovoCadastro } from "../NovoCadastro";
import myntLogo from "../../assets/mynt.svg";
import { useAuth } from "../../contexts/AuthContext";
import mercadoBitcoinLogo from "../../assets/mercado-logo2.png";
import CryptoIntegration from "../../components/IntegrationComponents/CryptoIntegration";
import { useBroker } from "../../contexts/BrokerContext";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

interface IntegrationCryptoProps {}

export const IntegrationCrypto: React.FC<IntegrationCryptoProps> = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { currentBroker } = useBroker();

  const title = "Conecte com suas corretoras";
  const subTitle =
    "Seus impostos são calculados automaticamente após a conexão";

  return user.user ? (
    <div>
      <Spin spinning={loading}>
        <div className="velotax-container">
          <Typography.Title level={1} className="page-title-outside">
            {title}
          </Typography.Title>
          <Typography.Paragraph className="destak" strong>
            {subTitle}
          </Typography.Paragraph>
          <div className="velotax-content">
            {/* <Row justify="end" align="top">
              <Col xs={4}>
                <PlayVideoButton
                  size="large"
                  type="primary"
                  style={{ marginLeft: "auto" }}
                  tooltipProps={{ title: "Assistir vídeo tutorial" }}
                  videoSource="https://www.youtube-nocookie.com/embed/JYcaQC-ldjY"
                />
              </Col>
            </Row> */}
            <CryptoIntegration setLoadingIntegration={setLoading} />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "5px",
                marginTop: "24px",
              }}>
              <Typography.Paragraph style={{marginTop: "20px", marginRight: "5px"}}>Não deseja integrar agora?</Typography.Paragraph>
              <Button
                type="ghost"
                style={{
                  marginTop: "16px",
                }}
                onClick={() => {
                  navigate(`/${currentBroker.path}/cripto`);
                }}>
                Continuar sem integração
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  ) : (
    <NovoCadastro
      corretoras={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            columnGap: "16px",
            marginBottom: "8px",
          }}>
          <img
            src={mercadoBitcoinLogo}
            alt="mercadobitcoin-logo"
            style={{
              height: "24px",
            }}
          />
          {/* <img
            src={bitcoinTradeLogo}
            alt="bitcointrade-logo"
            style={{
              height: "20px",
            }}
          /> */}
          <img
            src={myntLogo}
            alt="mynt-logo"
            style={{
              height: "20px",
            }}
          />
          {/* <img
            src={binanceLogo}
            alt="binance-logo"
            style={{
              height: "20px",
            }}
          /> */}
        </div>
      }
      item={{
        settings: {
          title,
          subTitle,
          okText: "Conectar com corretoras",
        },
      }}
    />
  );
};
