import styled from "styled-components";

export const ReportContainer = styled.div`
  height: 100%;
  h3 {
    display: flex;
    align-items: center;
    column-gap: 16px;
    color: var(--velotax-font-color);
    margin-bottom: 0;
  }

  div.ant-typography {
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--velotax-font-color);
  }

  .ant-typography.destak {
    margin: 48px 0;
    display: block;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--velotax-font-color);
    padding-left: 16px;
    border-left: 4px solid var(--ant-primary-color);
  }

  .cards {
    margin: 32px 0 0;
    width: fit-content;
    display: grid;
    row-gap: 1rem;
    column-gap: 1rem;
    grid-template-columns: repeat(4, 1fr);
  }

  @media only screen and (max-device-width: 812px) {
    h1 {
      :after {
        left: -24px;
      }
    }

    .cards {
      width: 100%;
      grid-template-columns: repeat(2, 1fr);
      /* background-color: red !important; */
      > div {
        width: auto;
      }
    }
  }

  @media only screen and (max-device-width: 400px) {
    .cards {
      /* background-color: red !important; */
    }
  }
`;

export const Container = styled.div`
  h2.ant-typography {
    display: flex;
    align-items: center;
    column-gap: 8px;
    max-width: 600px;
    margin: 0 auto 1.5rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: var(--velotax-font-color);

    &.info {
      align-items: flex-start;
      font-size: 0.875rem;
      line-height: 1.125rem;
      opacity: 0.85;
      margin: 0 auto 2.5rem;
      svg {
        margin-top: 4px;
      }
    }
  }

  @media only screen and (max-device-width: 812px) {
    padding: 0;

    h1 {
      max-width: calc(100% - 48px);
      margin: 0 24px 1.5rem;
      padding: 24px 0 1rem;
    }

    h2.ant-typography {
      max-width: 100%;
      margin: 0 auto 1.5rem;
      padding: 0 24px;
    }
  }
`;

export const Content = styled.div`
  .ant-list.ant-list-split {
    margin: 24px 0 0;
    padding: 0 16px;
    background-color: var(--light-gray);
    border-radius: 8px;
    .ant-list-item-action > li {
      padding: 0;
    }
    .ant-list-item-meta-description {
      opacity: 0.75;
    }
    .ant-list-item-action > li > button {
      font-size: 16px;
      color: var(--ant-primary-color);
    }
    .ant-list-item-meta-title,
    .ant-list-item-meta-description {
      font-size: 16px;
      color: var(--velotax-font-color);
    }
    .ant-list-item-action-split {
      opacity: 0;
    }
  }
  .ant-btn.ant-btn-text {
    color: var(--ant-primary-color);
  }
  @media only screen and (max-device-width: 812px) {
    .ant-list.ant-list-split {
      .ant-list-item-action {
        align-items: end;
        grid-template-rows: 1fr 0.75fr;
        grid-template-columns: 1fr 1fr;
      }
    }
  }
`;
