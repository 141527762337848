import { Col, Row, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { Subtitle } from "./styles";
import Button from "../../components/Button";
import { Page } from "../../constants/brokers";
import { useAuth } from "../../contexts/AuthContext";
import { useBroker } from "../../contexts/BrokerContext";
import { AuthContainer } from "../../styles/LoginSignUp/authContainer";
import { useB3Integration } from "../../contexts/B3IntegrationContext";

interface UserHasAccountProps {
  item: Page;
}

export const UserHasAccount: React.FC<UserHasAccountProps> = () => {
  const navigate = useNavigate();
  const { integration } = useBroker();
  const { user, cameFromRegister, setCameFromRegister } = useAuth();

  const {
    interval,
    b3Authorized,
    openB3Window,
    handleIntegrate,
    verifyUserIsAuthorized,
  } = useB3Integration();

  useEffect(() => {
    if (
      user.user &&
      !b3Authorized &&
      !interval.current &&
      cameFromRegister.bolsa &&
      integration.key !== "unset"
    ) {
      handleIntegrate();
    }
  }, [
    interval,
    user.user,
    b3Authorized,
    handleIntegrate,
    integration.key,
    setCameFromRegister,
    cameFromRegister.bolsa,
  ]);

  const handleForgotPassword = useCallback(() => {
    setTimeout(() => {
      navigate("/velotax/forgot-password-b3");
    }, 1000);
  }, [navigate]);

  return (
    <AuthContainer className="beauty-scrollbar">
      <div className="auth-content">
        <div className="form-container">
          <Typography.Title level={1}>
            <Row align="bottom" justify="space-between">
              <Col>
                {" "}
                <Col>Conecte sua conta à B3</Col>
              </Col>
            </Row>
          </Typography.Title>
          <Typography.Title level={4}>
            <Row align="bottom" justify="space-between">
              <Subtitle>
                <Col>
                  Boa notícia! Selecione abaixo uma opção para prosseguir
                </Col>
              </Subtitle>
            </Row>
          </Typography.Title>

          <Row>
            <Col span={24}>
              <span
                style={{
                  width: "100%",
                  display: "flex",
                  margin: 15,
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <Button
                  size="large"
                  type="default"
                  onClick={() => {
                    openB3Window();
                    verifyUserIsAuthorized();
                  }}
                >
                  Eu sei minha senha&nbsp;
                </Button>
                <Button
                  size="large"
                  type="primary"
                  onClick={() => handleForgotPassword()}
                >
                  Não lembro minha senha&nbsp;
                </Button>
              </span>
            </Col>
          </Row>
        </div>
      </div>
    </AuthContainer>
  );
};
