import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  &,
  h4,
  .ant-typography {
    color: var(--velotax-font-color-dark);
  }
  .ant-btn {
    height: 40px;
    font-weight: 600;
    border: 1px solid #e8e8e8;
    margin: -60px 32px 32px auto;
    border-radius: 24px !important;
    text-transform: none !important;
    svg {
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      fill: var(--ant-primary-color) !important;
    }
  }
  .ant-table {
    .ant-table-container {
      border: none;
      .ant-table-thead {
        .ant-table-cell {
          font-weight: 700;
          background-color: transparent !important;
        }
      }
      .ant-table-cell {
        border-color: #e8e8e8 !important;
      }
    }
    strong {
      font-weight: 700 !important;
    }
  }
  .mobile-list {
    display: none;
  }
  .table-total {
    padding: 16px;
    display: flex;
    align-items: center;
    background-color: #e8e8e8;
    justify-content: space-between;
    h3 {
      margin: 0;
      font-size: 14px;
    }
    span.ant-typography {
      font-weight: 600;
      width: 116px;
    }
    &.exterior {
      margin-bottom: 16px;
      span.ant-typography {
        width: calc(30% - 22px);
      }
    }
  }
  @media only screen and (max-device-width: 812px) {
    .ant-btn {
      width: 60%;
      margin: -16px 0 0 0;
    }
    .ant-table {
      display: none;
    }
    .mobile-list {
      width: 100%;
      display: flex;
      margin: 24px 0 0;
      flex-direction: column;
      .list-item {
        width: 100%;
        padding: 16px 24px;
        border-top: 1px solid #e8e8e8;
        :last-of-type {
          border-bottom: 1px solid #e8e8e8;
        }
        p {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 4px;
        }
        .list-item-label {
          width: 48px;
          font-weight: 500;
          display: inline-block;
        }
        .list-item-value {
          width: calc(100% - 48px);
          display: inline-block;
        }
      }
      .list-empty {
        padding: 16px 0;
        margin-top: -24px;
        text-align: center;
        background-color: #e8e8e8;
      }
      &.exterior {
        .list-item-label {
          width: 100px;
        }
        .list-item-value {
          width: calc(100% - 100px);
        }
      }
    }
    .table-total {
      padding: 24px;
      span.ant-typography {
        width: auto !important;
        text-align: right;
      }
    }
  }
`;