import styled from "styled-components";

export const Container = styled.div`
  p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    margin: 0 auto 2rem;
    color: var(--velotax-font-color-ghost);
    span {
      cursor: pointer;
      font-weight: 500;
      color: var(--ant-primary-color);
    }
  }

  .ant-descriptions-header {
    margin-bottom: 0px;
  }

  h4 {
    color: var(--velotax-font-color);
  }

  .content-button {
    display: flex;
    margin: 16px auto;
    align-items: center;
    justify-content: right;
  }

  .excluir-container {
    width: 700px;
    margin: 16px auto;

    button {
      opacity: 0.8;
    }
  }

  @media only screen and (max-device-width: 812px) {
    p {
      margin: 0 0 16px;
    }

    .excluir-container {
      width: 100%;
      margin: 16px auto 32px;

      button {
        margin-right: 24px;
      }
    }
  }
`;
