import { Typography } from "antd";
// import api from "../../services/api";
import Button from "../../components/Button";
// import { useAuth } from "../../contexts/AuthContext";
import { Container, DeclaracaoContainer } from "./styles";

export const DeclaracaoIRPF = () => {
  // const { user } = useAuth();
  const year = new Date().getFullYear();

  // const handleRedirectToIrpfWeb = async () => {
  //   const { data } = await api.post("/user/sso-token", {
  //     cpf: user.user.cpf,
  //     from: "CALCULADORAV2",
  //   });
  //   if (data?.token) {
  //     window.open(
  //       `https://irpf${
  //         process.env.REACT_APP_URL?.includes("dev") ? "dev" : ""
  //       }.velotax.com.br/p/sso/${data.token}`
  //     );
  //   } else {
  //     message.warning("Tente novamente");
  //   }
  // };

  return (
    <Container className="velotax-container">
      <div className="velotax-content beauty-scrollbar">
        <Typography.Title level={1} className="page-title">
          Declaração IRPF {year}
        </Typography.Title>
        <Typography.Text className="subtitle">
          Você pode declarar seu Imposto de Renda conosco pelo celular
        </Typography.Text>

        <div className="container-mobile">
          <Typography.Text className="subtitle-mobile">
            A sua declaração de IRPF {year} já está pré-preenchida com as
            informações dos seus investimentos
          </Typography.Text>
          <Typography.Paragraph strong className="link-mobile">
            Baixe o novo aplicativo Velotax para revisar sua declaração:
          </Typography.Paragraph>

          <Button
            size="large"
            type="primary"
            className="button-mobile"
            onClick={() =>
              window.open("https://www.velotax.com.br/redirect/app")
            }
          >
            Baixar aplicativo
          </Button>
        </div>

        <DeclaracaoContainer>
          <section className="first-section">
            {/* <div className="declaracao-content-container lg-only">
              <img src="/img/from_desktop.svg" alt="From Desktop" />

              <div className="header-text">
                <h4 className="section-title">Declarar pelo computador</h4>
                <p className="web-only">
                  Para usar o aplicativo no seu computador, acesse nossa
                  plataforma clicando no link abaixo:
                </p>
              </div>

              <div style={{ width: "100%" }}>
                <Button
                  block
                  size="large"
                  type="primary"
                  onClick={handleRedirectToIrpfWeb}
                >
                  Começar agora
                </Button>
              </div>
            </div> */}

            <div className="declaracao-content-container">
              <img src="/img/from_cell.svg" alt="From Cell" />

              <div className="header-text">
                <h4 className="section-title">Declarar pelo celular</h4>
                <p className="web-only">
                  Para declarar usando o celular, aponte sua câmera para o QR
                  Code abaixo:
                </p>
                <span className="mobile-only">
                  Baixe o aplicativo <br /> para facilitar sua declaração:
                </span>
              </div>

              <div className="apk-btns mobile-only flex-center">
                <a href="https://onelink.to/mgetm">
                  <img src="/img/google-play-btn.png" alt="Google Play" />
                </a>
                <a href="https://apps.apple.com/us/app/velotax-irpf/id6499226151">
                  <img src="/img/app-store-btn.png" alt="App Store" />
                </a>
              </div>
              <span className="qr-code-app flex-center web-only">
                <img
                  src="/img/velotax-app-qr-code.png"
                  width="168px"
                  height="168px"
                  alt="QR Code"
                />
              </span>
            </div>
          </section>
        </DeclaracaoContainer>
      </div>
    </Container>
  );
};
