import moment from "moment";
import Button from "../Button";
import { Table, Typography } from "antd";
import { AiOutlineDownload } from "react-icons/ai";
import { Container } from "./styles";
import { formatCurrency } from "../../utils";
import { handleDownloadSheet } from "../../utils/handleDownloadSheet";

export const DividendosModal = ({ item }: any) => {
  return (
    <Container>
      {item?.operations?.length > 0 && (
        <Button
          type="text"
          icon={<AiOutlineDownload />}
          onClick={() => {
            const serializedData: any[] = [];
            item?.operations.forEach((op: any) => {
              serializedData.push({
                Data: moment(op?.referenceDate).format("DD/MM/YYYY"),
                Ativo: op?.ticker,
                "Tipo de rendimento": op?.tipo,
                "Rendimento recebido": `R$${(op?.valor).toFixed(2)}`,
              });
            });
            handleDownloadSheet(
              serializedData,
              "Dividendos",
              "Dividendos.xlsx"
            );
          }}
        >
          Baixar planilha
        </Button>
      )}
      <Table
        pagination={false}
        dataSource={item?.operations}
        locale={{ emptyText: "Nenhum dividendo recebido neste mês" }}
        columns={[
          {
            align: "left",
            title: "Ativo",
            dataIndex: "ticker",
            render: (value) => (
              <strong style={{ fontWeight: "500" }}>{value}</strong>
            ),
          },
          {
            align: "left",
            title: "Data",
            dataIndex: "referenceDate",
            render: (value) => moment(value).format("DD/MM/YYYY"),
          },
          {
            align: "left",
            dataIndex: "tipo",
            title: "Tipo de rendimento",
          },
          {
            align: "left",
            title: "Valor",
            width: "150px",
            dataIndex: "valor",
            render: (value) => formatCurrency(value ?? 0, "R$ "),
          },
        ]}
      />
      <div className="mobile-list">
        {item?.operations?.map((op: any, index: number) => (
          <div className="list-item" key={index}>
            <p>{op.ticker}</p>
            <span className="list-item-label">Tipo:</span>
            <span className="list-item-value">{op.tipo}</span>
            <span className="list-item-label">Data:</span>
            <span className="list-item-value">
              {moment(op.referenceDate).format("DD/MM/YYYY")}
            </span>
            <span className="list-item-label">Valor:</span>
            <span className="list-item-value">
              {formatCurrency(op.valor ?? 0, "R$ ")}
            </span>
          </div>
        ))}
        {!item?.operations?.length && (
          <div className="list-empty">Nenhum dividendo recebido neste mês</div>
        )}
      </div>
      {item?.operations?.length > 0 && (
        <div className="table-total">
          <Typography.Title level={3}>Total recebido no mês:</Typography.Title>
          <Typography.Text>{formatCurrency(item.value)}</Typography.Text>
        </div>
      )}
    </Container>
  );
};
