import Payment from "payment";
import Masked from "react-input-mask";
import Cards from "react-credit-cards";
import React, { useMemo } from "react";
import NumberFormat from "react-number-format";
import { Col, Form, FormInstance, Input, Row, Select } from "antd";
import { CardFlagContainer } from "./styles";
import { cpfInputMask } from "../../../../../utils";
import getInstallmentsListByPrice from "../../../../../utils/getInstallmentsListByPrice";
import {
  validationAmexCVV,
  validationCardShelfLife,
  validationCpf,
  validationCreditCardNumber,
  validationCVV,
  validationFieldRequired,
} from "../../../../../utils/formValidations";

interface ICardFormProps {
  number: string;
  focused?: any;
  form: FormInstance;
  uniqueValue?: number;
  setCvc: (cvc: string) => void;
  setName: (name: string) => void;
  setNumber: (number: string) => void;
  setExpiry: (expiry: string) => void;
  setFocused: (focused: any) => void;
  setHolderDocument: (value: string) => void;
  setIsValidCardForm: (isValidCardForm: boolean) => void;
  handleBack: (event: any) => void;
  fixedInstallments?: number;
  signature?: boolean;
  installmentSelected?: number;
  customInstallments?: number;
  addInfo?: Record<string, any>;
}

export const CardForm: React.FC<ICardFormProps> = ({
  form,
  number,
  setCvc,
  setName,
  focused,
  setNumber,
  setExpiry,
  setFocused,
  setHolderDocument,
  setIsValidCardForm,
  uniqueValue,
  fixedInstallments,
  handleBack,
  signature = true,
  installmentSelected,
  customInstallments,
  addInfo,
}) => {
  const cardType = useMemo(() => Payment.fns.cardType(number), [number]);

  const onFieldsChange = () => {
    form
      .validateFields()
      .then(() => setIsValidCardForm(true))
      .catch(() => setIsValidCardForm(false));
  };

  let installments = useMemo(
    () =>
      getInstallmentsListByPrice(
        uniqueValue || 0,
        !!signature,
        fixedInstallments
      ),
    [signature, fixedInstallments, uniqueValue]
  );

  return (
    <>
      <Form
        form={form}
        size="large"
        layout="vertical"
        onChange={onFieldsChange}
      >
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <CardFlagContainer className="flag-inside-input">
              <Form.Item
                name="number"
                label="Número do cartão"
                rules={validationCreditCardNumber}
                style={{ marginBottom: "5px" }}
              >
                <NumberFormat
                  placeholder="Número do cartão"
                  className="ant-input ant-input-lg"
                  mask="_"
                  format={
                    cardType === "dinersclub"
                      ? "#### ###### ####"
                      : cardType === "amex"
                      ? "#### ###### #####"
                      : "#### #### #### ####"
                  }
                  onValueChange={(e) => {
                    setNumber(e.value);
                    if (focused !== "number") setFocused("number");
                  }}
                />
              </Form.Item>
              <Cards
                preview
                cvc="***"
                name=""
                expiry=""
                style={{
                  border: "1px solid",
                }}
                number={
                  number.substring(0, 6) +
                  "** *****" +
                  number.substring(number.length - 4)
                }
              />
            </CardFlagContainer>
          </Col>

          <Col span={12}>
            <Form.Item
              name="expiry"
              label="Validade"
              rules={validationCardShelfLife}
            >
              <Masked
                mask="99/99"
                placeholder="00/00"
                className="ant-input ant-input-lg"
                onChange={(e) => {
                  setExpiry(e.target.value);
                  if (focused !== "expiry") setFocused("expiry");
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="cvv"
              label="Código de segurança"
              rules={
                cardType === "amex" || cardType === "discover"
                  ? validationAmexCVV
                  : validationCVV
              }
            >
              <NumberFormat
                mask="_"
                format={
                  cardType === "amex" || cardType === "discover"
                    ? "####"
                    : "###"
                }
                placeholder="Código de segurança"
                className="ant-input ant-input-lg"
                onValueChange={(e) => {
                  setCvc(e.value);
                  if (focused !== "cvc") setFocused("cvc");
                }}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="holderName"
              label="Nome completo"
              rules={validationFieldRequired}
            >
              <Input
                maxLength={48}
                placeholder="Nome completo"
                onChange={(e) => {
                  setName(e.target.value);
                  if (focused !== "name") setFocused("name");
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="cpf" label="CPF do titular" rules={validationCpf}>
              <Masked
                mask={cpfInputMask}
                placeholder="000.000.000-00"
                className="ant-input ant-input-lg"
                onChange={(e) => {
                  setHolderDocument(
                    e.target.value?.replace(/[_.-]/g, "") ?? ""
                  );
                }}
              />
            </Form.Item>
          </Col>
          {uniqueValue && !addInfo?.isPlan ? (
            <Col span={24}>
              <Form.Item
                name="installments"
                label={signature ? "Mensalidade" : "Parcelas"}
              >
                <Select
                  size="large"
                  listHeight={400}
                  options={installments}
                  placeholder="Selecione a(s) parcela(s)"
                  onChange={handleBack}
                  style={{ minWidth: "150px" }}
                  getPopupContainer={(target) => target.parentElement!}
                  defaultValue={
                    fixedInstallments || signature
                      ? installments[0].value
                      : installmentSelected
                  }
                />
              </Form.Item>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </Form>
    </>
  );
};
