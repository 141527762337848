import clsx from "clsx";
import moment from "moment";
import { FaRegEdit } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { BsCalendar3, BsDownload } from "react-icons/bs";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { useCallback, useEffect, useMemo, useState, ReactNode } from "react";
import {
  AiOutlineDelete,
  AiOutlineLock,
  AiOutlineUnlock,
} from "react-icons/ai";
import {
  Col,
  Collapse,
  DatePicker,
  Descriptions,
  List,
  message,
  Row,
  Skeleton,
  Space,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import apis from "../../services/apis";
import Button from "../../components/Button";
import { Container, Content } from "./styles";
import { Page } from "../../constants/brokers";
import HandleTag from "../../services/handleTag";
import apiCripto from "../../services/apiCrypto";
import { useAuth } from "../../contexts/AuthContext";
import { FormModal } from "../../components/FormModal";
import { apiPayment } from "../../services/apiPayment";
import { useBroker } from "../../contexts/BrokerContext";
import { DrawerModal } from "../../components/DrawerModal";
import { PaymentModal } from "../../components/PaymentModal";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import {
  DatePickerMonthCell,
  YearResume,
} from "./Components/DatePickerMonthCell";
import {
  antDatePickerLocale,
  ativarDarfsRetroativos,
  download,
  errorMessage,
  formatCurrency,
  mensagemDarfsRetroativos,
  monthsExtended,
  planInfoToHasPlan,
} from "../../utils";
import {
  DarfResultDescriptions,
  DarfFormItemRows,
  defaultDarf,
  IDarf,
  DarfImpostosDevidosDescriptions,
  minDarfPrice,
  maxDarfPrice,
  YearResumeStatusFromBackEnum,
  historicoVendasEmptyText,
  maxPixPayment,
  maxCreditCardPayment,
} from "../../constants/darf";

interface DarfProps {
  item: Page;
  closeModal: () => void;
  view?: boolean;
  viewEdit?: boolean;
  date?: {
    year?: number;
    month?: number;
  };
  darf?: {
    id: string;
    fullPath: string;
    payed?: boolean
  };
}

export const Darf: React.FC<DarfProps> = ({
  item,
  view,
  darf,
  date,
  viewEdit,
  closeModal,
}) => {
  const { state } = useLocation();
  const { currentBroker, currentPage } = useBroker();
  const { user, hasPlan, showUserPlanModal, userPlanModal } = useAuth();

  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();
  const queryYear = (state as any)?.year as number;
  const queryMonth = (state as any)?.month as number;
  const initialMonth = queryMonth
    ? queryMonth - 1
    : today.getMonth() - 1 >= 0
    ? today.getMonth() - 1
    : 11;
  const initialYear = queryYear
    ? queryYear
    : today.getMonth() - 1 >= 0
    ? today.getFullYear()
    : today.getFullYear() - 1;
  const defaultValue = moment({ month: initialMonth, year: initialYear });
  const currentDate = moment({
    month: currentMonth,
    year: currentYear,
  }).subtract(1, "M");
  window.history.replaceState({}, document.title);

  const [asset, setAsset] = useState<any>();
  const [, setYear] = useState(initialYear);
  const [, setMonth] = useState(initialMonth);
  const [loading, setLoading] = useState(false);
  const [emitting, setEmitting] = useState(false);
  const [firstTime, setFirstTime] = useState(true);
  const [helpModal, setHelpModal] = useState<any>();
  const [data, setData] = useState<IDarf>(defaultDarf);
  const [paymentData, setPaymentData] = useState<IDarf>();
  const [paymentModal, setPaymentModal] = useState(false);
  const [showAssetModal, setShowAssetModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [yearResume, setYearResume] = useState<YearResume[]>([]);
  const [datePickerYear, setDatePickerYear] = useState(initialYear);
  const [gettingYearResume, setGettingYearResume] = useState(false);
  const [loadingDarfButton, setLoadingDarfButton] = useState<boolean>(false);

  const planMonthSignature = new Date(user?.user?.userPlanInfoVelotax?.currentPlan?.createdAt).getMonth();
  const planYearSignature = new Date(user?.user?.userPlanInfoVelotax?.currentPlan?.createdAt).getFullYear();

  const year = date?.year ?? initialYear;
  const month = date?.month ?? initialMonth;

  const transactionFeature = item.features[0];
  const taxFeature = item.features[1];
  const yearResumeFeature = item.features[2];

  // useEffect(() => {
  //   apiCripto
  //     .post(`irpf/cripto/transaction-processed`)
  //     .then(() => {})
  //     .catch(() => message.error(errorMessage))
  //     .finally(() => {
  //       setFirstTime(false);
  //       setLoading(false);
  //     });
  // }, []);

  const { emitted } = useMemo(() => {
    if (gettingYearResume) {
    }
    const monthResume = yearResume
      .find((resume) => resume.year === year)
      ?.months.find((m) => m.month - 1 === month);
    return {
      emitted:
        monthResume?.status === YearResumeStatusFromBackEnum.PAYED ||
        monthResume?.status === YearResumeStatusFromBackEnum.NOT_PAYED,
      payed: !!monthResume?.payed,
      status: monthResume?.status,
    };
  }, [yearResume, year, month, gettingYearResume]);

  const getDarf = useCallback(
    (id: string) => {
      view && setLoading(true);
      view &&
        (currentPage?.api || apis)
          .get(`${taxFeature.apiUrl}/${id}`)
          .then((response) => {
            setYear(response.data?.year);
            setMonth(response.data?.month - 1);
            setData({
              ...response.data,
              emitted: true,
            });
          })
          .catch(() => message.error(errorMessage))
          .finally(() => setLoading(false));
    },
    [view, taxFeature.apiUrl, currentPage]
  );

  const getYearResume = useCallback(
    (year: number, force?: boolean, url?: string) => {
      setGettingYearResume(true);
      !view &&
        year <= new Date().getFullYear() &&
        year >= currentBroker.initialYear &&
        (force ||
          (!yearResumeFeature.disabled &&
            !yearResume.find((resume) => resume.year === year))) &&
        (currentPage?.api || apis)
          .get(yearResumeFeature.apiUrl, { params: { year } })
          .then((response) => {
            const index = yearResume.findIndex(
              (resume) => resume.year === year
            );
            if (index >= 0) {
              setYearResume((yearResume) => {
                yearResume.splice(index, 1, response.data);
                return yearResume;
              });
            } else {
              setYearResume((yearResume) => [...yearResume, response.data]);
            }

            if (url) {
              download(url);
            }
          })
          .catch((err) => console.log(err))
          .finally(() => setGettingYearResume(false));
    },
    [
      yearResumeFeature,
      yearResume,
      view,
      currentBroker.initialYear,
      currentPage,
    ]
  );

  const getTaxes = useCallback(() => {
    (!hasPlan || !view) && setLoading(true);
    (!hasPlan || !view) &&
      (currentPage?.api || apis)
        .get(transactionFeature.apiUrl, { params: { month: month + 1, year } })
        .then((response) => {
          setData(response.data);
        })
        .catch((err) => {
          setData(defaultDarf);
          message.error(errorMessage);
        })
        .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, [view, currentPage?.api, transactionFeature.apiUrl, month, year, hasPlan]);

  const handleDarf = (justSave?: boolean) => {
    setEmitting(true);
    (currentPage?.api || apis)
      .post(taxFeature.apiUrl, {
        ...data,
        year,
        month: month + 1,
        name: user.user.name,
        memoriaCalculo: [],
        transactions: data.memoriaCalculo,
        paymentInfo: {
          clientInfo: {
            name: user?.user?.name,
            email: user?.user?.email,
            document: user?.user?.cpf,
          },
        },
        insertPix: true,
        justSave,
        payed: false,
      })
      .then((res) => {
        closeModal();
        setData((data) => ({ ...data, emitted: true }));
        getYearResume(year, true, justSave ? "" : res.data.darfUrl);
        setEmitting(false);
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || errorMessage);
        setEmitting(false);
      });
  };

  const handleRegularize = (regular: boolean) => {
    setEmitting(true);
    (currentPage?.api || apis)
      .post("transaction/regularize", {
        year,
        month: month + 1,
        regular,
        impostoDevido: data.impostoDevido,
        totalImpostoDevido: data.totalImpostoDevido,
      })
      .then((res) => {
        closeModal();
        getYearResume(year, true, res.data.darfUrl);
        getTaxes();
        message.success("Dados salvos");
      })
      .catch(() => message.error(errorMessage))
      .finally(() => setEmitting(false));
  };

  const onChangeMonth = (value: moment.Moment | null, dateString: string) => {
    const [month, year] = dateString.split("-");
    setMonth(parseInt(month) - 1);
    setYear(parseInt(year));
  };

  const unsetAsset = () => {
    setAsset(undefined);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    unsetAsset();
  };

  const handleCloseAssetModal = () => {
    setShowAssetModal(false);
    unsetAsset();
  };

  const onCloseHelpModal = () => {
    setHelpModal(undefined);
  };

  const handleOpenDarfModal = () => {
    if (
      ativarDarfsRetroativos({
        item: { month: month + 1, year },
        currentMonth,
        currentYear,
        hasPlan,
        planMonthSignature: planMonthSignature === 0 ? 11 : planMonthSignature - 1,
        planYearSignature: planMonthSignature === 0 ? planYearSignature - 1 : planYearSignature

      })
    ) {
      handleDarf();
    } else {
      handlePlanModal();
    }
  };

  const handleOpenPaymentModal = () => {
    setPaymentModal(!paymentModal);
  };

  const handleAdd = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    setShowAssetModal(true);
  };

  const handleEdit = (item: any, index: number) => {
    setShowAssetModal(true);
    setAsset({
      ...item,
      data: moment.utc(item.data.toLocaleString()).format("DD/MM/YYYY"),
      dataVenda: moment
        .utc(item?.dataVenda?.toLocaleString())
        ?.format("DD/MM/YYYY"),
      dataCompra: moment
        .utc(item?.dataCompra?.toLocaleString())
        ?.format("DD/MM/YYYY"),
    });
  };

  const handleRemove = (item: any, index: number) => {
    setShowDeleteModal(true);
    setAsset(item);
  };

  const addAsset = (asset: any) => {
    setLoading(true);
    (currentPage?.api || apis)
      .post(`${transactionFeature.apiUrl}?manual=true`, asset, {
        headers: {
          "x-email": user.user.email,
        },
      })
      .then(() => {
        getTaxes();
        getYearResume(year, true);
      })
      .catch(() => message.error(errorMessage))
      .finally(() => {
        setLoading(false);
        handleCloseAssetModal();
      });
  };

  const editAsset = (asset: any) => {
    setLoading(true);
    (currentPage?.api || apis)
      .put(`${transactionFeature.apiUrl}/${asset.id}`, asset)
      .then(() => {
        getTaxes();
        getYearResume(year, true);
      })
      .catch(() => message.error(errorMessage))
      .finally(() => {
        setLoading(false);
        handleCloseAssetModal();
      });
  };

  const removeAsset = () => {
    setLoading(true);
    (currentPage?.api || apis)
      .delete(`${transactionFeature.apiUrl}/${asset.id}`)
      .then(() => {
        getTaxes();
        getYearResume(year, true);
      })
      .catch(() => message.error(errorMessage))
      .finally(() => {
        setLoading(false);
        handleCloseDeleteModal();
      });
  };

  const handlePlanModal = () => {
    showUserPlanModal(!userPlanModal);
  };

  const handleSaveMonth = async () => {
    setLoadingDarfButton(true);
    let retPlanInfo = {
      data: {
        active: false,
        type: "",
      },
    };
    try {
      retPlanInfo = await apiPayment.get("/user-plan/plan-info");

      const hasPlan = planInfoToHasPlan(retPlanInfo.data);
      ativarDarfsRetroativos({
        item: { month: month + 1, year },
        currentMonth,
        currentYear,
        hasPlan,
        planMonthSignature: planMonthSignature === 0 ? 11 : planMonthSignature - 1,
        planYearSignature: planMonthSignature === 0 ? planYearSignature - 1 : planYearSignature

      })
        ? handleDarf(true)
        : handlePlanModal();
    } catch (err) {
      console.error(err);
    }
    setLoadingDarfButton(false);
  };

  const handleEventDarfButton = async () => {
    setLoadingDarfButton(true);
    let retPlanInfo = {
      data: {
        active: false,
        type: "",
      },
    };
    try {
      retPlanInfo = await apiPayment.get("/user-plan/plan-info");
    } catch (err) {
      console.error(err);
    }
    setLoadingDarfButton(false);

    const hasPlan = planInfoToHasPlan(retPlanInfo.data);
    ativarDarfsRetroativos({
      item: { month: month + 1, year },
      currentMonth,
      currentYear,
      hasPlan,
      planMonthSignature: planMonthSignature === 0 ? 11 : planMonthSignature - 1,
      planYearSignature: planMonthSignature === 0 ? planYearSignature - 1 : planYearSignature
    })
      ? handleOpenPaymentModal()
      : handlePlanModal();
  };

  const handleLabelButton: ReactNode = useMemo(() => {
    // if (!userPlanInfoStatus) return "Assinar Plano Emissão de DARF";

    if (data?.totalImpostoDevido <= maxDarfPrice) return "Pagar DARF";
    else {
      if (emitted) return "Emitir novo DARF";
      else return taxFeature?.buttonLabel;
    }
  }, [data?.totalImpostoDevido, emitted, taxFeature?.buttonLabel]);

  const impostoCharge = useMemo(
    () =>
      !view
        ? data.impostoDevido +
          data.impostoAcumulado +
          (data.impostoDevido + data.impostoAcumulado < minDarfPrice
            ? 0
            : data.multa + data.juros)
        : Math.max(
            (data.impostoDevido || 0) +
              (data.impostoAcumulado || 0) +
              ((data.impostoDevido || 0) + (data.impostoAcumulado || 0) <
              minDarfPrice
                ? 0
                : data.multa + data.juros),
            0
          ),
    [view, data.impostoDevido, data.impostoAcumulado, data.juros, data.multa]
  );

  useEffect(() => {
    if (!darf?.payed) getTaxes();
  }, [darf?.payed, getTaxes, month, year]);

  useEffect(() => {
    if (view && darf) {
      getDarf(darf.id);
    }
  }, [getDarf, view, darf]);

  useEffect(() => {
    getYearResume(year);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const dataToPayment = {
      ...data,
      year,
      month: month + 1,
      name: user.user.name,
      memoriaCalculo: [],
      transactions: data.memoriaCalculo,
    };

    setPaymentData(dataToPayment);
  }, [data.totalImpostoDevido, data, month, user.user.name, year]);

  const downloadAction = (record: any) => {
    (currentPage?.api || apis)
      .get(`/darf/download/${record._id}`)
      .then((res) => {
        if (res.data.url) {
          download(res.data.url);
        }
      });
  };

  const allow = ativarDarfsRetroativos({
    item: { month: month + 1, year },
    currentMonth,
    currentYear,
    hasPlan,
    planMonthSignature: planMonthSignature === 0 ? 12 : planMonthSignature,
    planYearSignature: planMonthSignature === 0 ? planYearSignature - 1 : planYearSignature

  });
  console.log(month);

  return (
    <Container className={clsx({ view, "view-edit": viewEdit })}>
      <Content className={clsx({ view, "view-edit": viewEdit })}>
        <Space direction="vertical" className="full-width">
          <Row gutter={[8, 16]} align="middle" justify="space-between">
            <Col xs={24} sm={16}>
              <Typography.Title level={3} className="date-title">
                <BsCalendar3 />
                {!view && !viewEdit && (
                  <>
                    {currentBroker.initialYear === year && month === 0 ? (
                      <div className="month-chevron empty" />
                    ) : (
                      <BiChevronLeft
                        className="month-chevron"
                        onClick={() => {
                          if (month === 0) {
                            setYear((year) => {
                              const newYear = year - 1;
                              getYearResume(newYear);
                              setDatePickerYear(newYear);
                              return newYear;
                            });
                            setMonth(11);
                          } else {
                            setMonth((month) => month - 1);
                          }
                        }}
                      />
                    )}
                  </>
                )}
                {monthsExtended[month]} de {year}
                {!view && !viewEdit && (
                  <>
                    {currentYear === year && currentMonth - 1 === month ? (
                      <div className="month-chevron empty" />
                    ) : (
                      <BiChevronRight
                        className="month-chevron"
                        onClick={() => {
                          if (month === 11) {
                            setYear((year) => {
                              const newYear = year + 1;
                              getYearResume(newYear);
                              setDatePickerYear(newYear);
                              return newYear;
                            });
                            setMonth(0);
                          } else {
                            setMonth((month) => month + 1);
                          }
                        }}
                      />
                    )}
                  </>
                )}
              </Typography.Title>
            </Col>
            {!view && !viewEdit && (
              <Col xs={24} sm={8}>
                <DatePicker
                  size="large"
                  picker="month"
                  format="MM-YYYY"
                  disabled={loading}
                  allowClear={false}
                  inputReadOnly={true}
                  onChange={onChangeMonth}
                  suffixIcon={<BsCalendar3 />}
                  defaultValue={defaultValue}
                  locale={antDatePickerLocale}
                  value={moment({ year: datePickerYear, month })}
                  onSelect={(e) => {
                    if (e.month() === month && year !== datePickerYear) {
                      onChangeMonth(e, `${month + 1}-${datePickerYear}`);
                    }
                  }}
                  onPanelChange={(e) => {
                    getYearResume(e.year());
                    setDatePickerYear(e.year());
                  }}
                  getPopupContainer={(target) => target.parentElement!}
                  disabledDate={(current) =>
                    current &&
                    (current > currentDate.endOf("month") ||
                      current < moment(`${currentBroker.initialYear}-01-01`) ||
                      current < moment(`2020-01-01`))
                  }
                  monthCellRender={(e) => (
                    <DatePickerMonthCell event={e} yearResume={yearResume} />
                  )}
                />
              </Col>
            )}
          </Row>
        </Space>
      </Content>

      <br />

      <Content className={clsx("second", { view, "view-edit": viewEdit })}>
        <Space direction="vertical" className="full-width">
          {allow && view && darf?.fullPath && (
            <Row justify="end">
              <Col>
                <Button
                  type="link"
                  icon={<BsDownload size={16} />}
                  onClick={() => downloadAction(darf)}
                  style={{ marginTop: "-32px", padding: "4px 0" }}
                >
                  BAIXAR DARF
                </Button>
              </Col>
            </Row>
          )}
          <div style={{ position: "relative" }}>
            {!allow && (
              <div className="no-plan-container">
                <Typography>
                  Contrate o plano premium para ver os impostos devidos
                </Typography>
                <Button
                  type="primary"
                  icon={<AiOutlineLock size={18} />}
                  onClick={() => {
                    handlePlanModal();
                    closeModal();
                  }}
                >
                  PREMIUM
                </Button>
              </div>
            )}
            <Descriptions size="small" bordered column={1}>
              {DarfImpostosDevidosDescriptions.map((description) => {
                if(description.id === 'prejuizoExterior') return (<></>)
                return (
                <Descriptions.Item
                  key={description.id}
                  label={description.label(setHelpModal)}
                >
                  {loading ? (
                    <Spin />
                  ) : description.render ? (
                    description.render(data)
                  ) : (
                    formatCurrency(
                      !allow
                        ? 0
                        : Number(data[description.id as keyof IDarf] || 0)
                    )
                  )}
                </Descriptions.Item>
              )})}
            </Descriptions>
          </div>

          <br />

          <Collapse
            ghost
            bordered={false}
            expandIconPosition="right"
            defaultActiveKey={!allow ? ["1"] : []}
            {...(!allow ? { collapsible: "disabled" } : {})}
          >
            <Collapse.Panel
              key="1"
              header={
                <Typography.Title level={3}>
                  Vendas realizadas no mês
                </Typography.Title>
              }
            >
              {!view && (
                <Button
                  type="primary"
                  className="add"
                  disabled={loading}
                  onClick={handleAdd}
                  icon={<PlusOutlined />}
                >
                  Adicionar
                </Button>
              )}
              <List
                loading={loading}
                itemLayout="horizontal"
                dataSource={data.memoriaCalculo}
                locale={{
                  emptyText: historicoVendasEmptyText,
                }}
                renderItem={(item, index) => (
                  <List.Item
                    actions={
                      view
                        ? []
                        : [
                            <Button
                              type="text"
                              onClick={() => handleEdit(item, index)}
                              icon={<FaRegEdit size={18} />}
                            />,
                            <Button
                              type="text"
                              onClick={() => handleRemove(item, index)}
                              icon={<AiOutlineDelete size={18} />}
                            />,
                          ]
                    }
                  >
                    <Skeleton title={false} loading={item.loading} active>
                      <List.Item.Meta
                        title={<strong>{item.ativo}</strong>}
                        description={
                          <div className="list-description">
                            {item.data && (
                              <div>
                                Data:{" "}
                                {moment
                                  .utc(item?.data?.toLocaleString())
                                  .format("DD/MM/YYYY")}
                              </div>
                            )}
                            {item.quantidade && (
                              <div>Quantidade: {item.quantidade}</div>
                            )}
                            {view && (
                              <>
                                <div>
                                  Custo médio de compra:{" "}
                                  <strong>
                                    {formatCurrency(item.custoCompra)}
                                  </strong>
                                </div>
                                <div>
                                  Preço de venda:{" "}
                                  <strong>
                                    {formatCurrency(item.precoVenda)}
                                  </strong>
                                </div>
                              </>
                            )}
                            <div>
                              Ganho:{" "}
                              <strong>
                                {formatCurrency(
                                  item.ganho ??
                                    item.quantidade *
                                      (item.precoVenda - item.custoCompra) -
                                      item.taxas ??
                                    0
                                )}
                              </strong>
                            </div>
                          </div>
                        }
                      />
                    </Skeleton>
                  </List.Item>
                )}
              />
            </Collapse.Panel>
          </Collapse>

          {allow && <br />}

          {allow && (
            <div
              className="total-tax-header original"
              style={{ width: "100%" }}
            >
              <Typography.Title level={3}>Imposto total</Typography.Title>
              <Typography.Title level={3} className="text-center">
                {loading ? (
                  <Spin style={{ marginRight: "32px" }} />
                ) : (
                  formatCurrency(impostoCharge)
                )}
              </Typography.Title>
            </div>
          )}

          {allow && (
            <Descriptions size="small" bordered column={1}>
              {DarfResultDescriptions.map((description, i) => (
                <Descriptions.Item
                  key={description.id}
                  label={description.label(setHelpModal)}
                  style={{
                    display: i === 3 ? "none" : "table-cell",
                  }}
                >
                  {loading ? (
                    <Spin />
                  ) : description.Component ? (
                    <description.Component
                      data={data}
                      year={year}
                      view={view}
                      month={month}
                      disabled={!view}
                      loading={loading}
                      setDataCripto={setData}
                      url={transactionFeature.apiUrl}
                    />
                  ) : (
                    formatCurrency(
                      Number(data[description.id as keyof IDarf] || 0)
                    )
                  )}
                </Descriptions.Item>
              ))}
            </Descriptions>
          )}

          {!view && (
            <>
              <Row
                justify="end"
                gutter={[16, 16]}
                className="column-reverse"
                style={{ marginTop: "16px" }}
              >
                {allow &&
                  !(month === currentMonth && year === currentYear) &&
                  (data.impostoDevido + data.impostoAcumulado ?? 0) <
                    minDarfPrice &&
                  data?.memoriaCalculo?.length > 0 && (
                    <Col xs={24}>
                      <br />
                      <Tooltip
                        title={
                          <>
                            O valor do imposto devido é menor que{" "}
                            {formatCurrency(minDarfPrice)}. Você não precisa
                            emitir um DARF para este mês. Clique aqui para
                            regularizar este mês.
                          </>
                        }
                      >
                        <Button
                          block
                          size="large"
                          type="primary"
                          loading={emitting}
                          disabled={
                            loading ||
                            emitting ||
                            (month === currentMonth && year === currentYear)
                          }
                          onClick={() => {
                            handleRegularize(true);
                          }}
                        >
                          Salvar sem emitir DARF
                        </Button>
                      </Tooltip>
                    </Col>
                  )}
                {data.impostoDevido + data.impostoAcumulado >= minDarfPrice && (
                  <Col xs={24}>
                    <br />
                    <Tooltip
                      getPopupContainer={(target) => target.parentElement!}
                      title={
                        !allow
                          ? mensagemDarfsRetroativos
                          : month === currentMonth && year === currentYear
                          ? "Aguarde o encerramento do mês para pagar seu DARF"
                          : ""
                      }
                    >
                      <Button
                        block
                        size="large"
                        type="primary"
                        onClick={handleEventDarfButton}
                        icon={!allow && <AiOutlineUnlock />}
                        loading={emitting || loadingDarfButton}
                        disabled={
                          loading ||
                          emitting ||
                          (month === currentMonth && year === currentYear)
                        }
                      >
                        {handleLabelButton}
                      </Button>{" "}
                    </Tooltip>
                  </Col>
                )}
              </Row>
              {allow && !(month === currentMonth && year === currentYear) && (
                <Row gutter={[16, 16]} justify="end" align="middle">
                  <Col>
                    <Button
                      type="link"
                      size="large"
                      onClick={handleSaveMonth}
                      disabled={loading || emitting}
                      icon={!allow && <AiOutlineUnlock />}
                    >
                      Salvar apenas
                    </Button>
                  </Col>
                </Row>
              )}
            </>
          )}
        </Space>
      </Content>
      <DrawerModal
        footer={null}
        visible={helpModal}
        title={helpModal?.title}
        onCancel={onCloseHelpModal}
      >
        {helpModal?.content}
      </DrawerModal>
      <FormModal
        add={addAsset}
        edit={editAsset}
        loading={loading}
        itemToEdit={asset}
        visibility={showAssetModal}
        onCancel={handleCloseAssetModal}
        rows={DarfFormItemRows(month, year)}
        title={asset ? "Editar ativo" : "Adicionar ativo"}
      />
      <PaymentModal
        item={item}
        show={paymentModal}
        emitting={emitting}
        selectedYear={year}
        maxPixValue={maxPixPayment}
        closeDarfModal={closeModal}
        onCancel={handleOpenPaymentModal}
        maxCCValue={maxCreditCardPayment}
        callDarf={() => {
          handleOpenDarfModal();
          HandleTag("78");
        }}
        disablePixOption={data?.totalImpostoDevido > maxPixPayment}
        disableCreditCardOption={
          data?.totalImpostoDevido > maxCreditCardPayment
        }
        paymentData={{
          ...paymentData,
          impostoTotalFinal: data.totalImpostoDevido + data.impostoAcumulado,
        }}
      />
      <DeleteConfirmationModal
        title="Remover ativo"
        visibility={showDeleteModal}
        onCancel={handleCloseDeleteModal}
        setVisibility={setShowDeleteModal}
        onOk={() => Promise.resolve(removeAsset())}
        body="Você realmente quer deletar esse ativo?"
      />
    </Container>
  );
};
