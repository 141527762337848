import { message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import api from "../services/api";
import { useAuth } from "./AuthContext";
import apiBolsa from "../services/apiBolsa";
import { useBroker } from "./BrokerContext";
import HandleTag from "../services/handleTag";
import { B3AuthURL, errorMessage } from "../utils";
import { useWebSocket, WSClientEvents } from "./Notification.context";
import moment from "moment";
import { ModalAlertWaitIntegration } from "../components/LoadingIntegration/alert.modal";
import { ModalConfirmWaitIntegration } from "../components/LoadingIntegration/confirm.modal";

interface HandleIntegrationProps {
  integrationInBackground?: boolean;
}

export interface IntegrationStatus {
  description?: string;
  started?: boolean;
  finished?: boolean;
  error?: boolean;
  alert?: boolean;
  processing?: boolean;
  confirm?: boolean;
}

interface IB3IntegrationContext {
  backgroundIntegration: {
    loading: boolean;
    month: number;
    year: number;
  };
  integrationMessage?: string;
  timer: number;
  loading: boolean;
  timeOver: boolean;
  b3Authorized: boolean;
  b3AuthLoaded: boolean;
  checkingAuhtorization: boolean;
  integrationInBackground: boolean;
  loadingSkipB3Integration: boolean;
  integrate: () => void;
  handleIntegrate: (props?: HandleIntegrationProps) => void;
  handleSkipB3Integration: () => void;
  resetB3IntegrationContext: () => void;
  setLoadingSkipB3Integration: React.Dispatch<React.SetStateAction<boolean>>;
  verifyUserIsAuthorized: (
    once?: boolean,
    autoError?: boolean,
    autoSuccess?: boolean
  ) => void;
  interval: React.MutableRefObject<NodeJS.Timeout | undefined>;
  handleAuthorizationB3: () => void;
  openB3Window: () => void;
  notas: {
    downloadNotaCorretagem: (
      path: string,
      month: number,
      year: number,
      crypto?: boolean
    ) => void;
    historicoNotasCorretagem: any[];
    getHistoricoNotasCorretagem: (params: {
      crypto?: boolean;
      filters: any;
    }) => void;
    loadingHistoricoNotasCorretagem: boolean;
    showHistoricoNotasCorretagemModal: boolean;
    setHistoricoNotasCorretagem: Dispatch<SetStateAction<any[]>>;
    setShowHistoricoNotasCorretagemModal: Dispatch<SetStateAction<boolean>>;
  };
}

export const B3IntegrationContext = createContext<IB3IntegrationContext>(
  {} as IB3IntegrationContext
);

export const B3IntegrationProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [loadingIntegration, setLoadingIntegration] = useState(false);
  const {
    integration,
    initIntegration,
    handleIntegrationInit,
    getIntegrationStatus,
    setInitIntegration,
  } = useBroker();

  const { user, b3Authorized, setB3Authorized, updateUserInfo, fromCheckout } =
    useAuth();

  const [timer, setTimer] = useState(0);
  const [loading, setLoading] = useState(false);
  const [timeOver, setTimeOver] = useState(false);
  const [b3AuthLoaded, setB3AuthLoaded] = useState(false);
  const [checkingAuhtorization, setCheckingAuhtorization] = useState(false);
  const [integrationInBackground, setIntegrationInBackground] = useState(false);
  const [loadingSkipB3Integration, setLoadingSkipB3Integration] =
    useState(false);
  const interval = useRef<ReturnType<typeof setInterval>>();
  const timerInterval = useRef<ReturnType<typeof setInterval>>();
  const [historicoNotasCorretagem, setHistoricoNotasCorretagem] = useState<
    any[]
  >([]);
  const [loadingHistoricoNotasCorretagem, setLoadingHistoricoNotasCorretagem] =
    useState(false);
  const [
    showHistoricoNotasCorretagemModal,
    setShowHistoricoNotasCorretagemModal,
  ] = useState(false);
  const { addEventListener, removeEventListener, open } = useWebSocket();
  const [modalConfirm, setModalConfirm] = useState(false);
  const integrationLoadingRef = useRef<boolean>(false);

  const setIntegrationLoading = useCallback((status: boolean) => {
    if (status) {
      if (integrationLoadingRef.current) return;
      // console.log("INICIOU INTEGRAÇÃO", new Date())
      integrationLoadingRef.current = true;
      setInitIntegration(true);
      setLoadingIntegration(true);
    } else {
      // console.log("FINALIZOU INTEGRAÇÃO", new Date())
      integrationLoadingRef.current = false;
      setLoadingIntegration(false);
      setInitIntegration(false);
    }
  }, []);

  const downloadNotaCorretagem = async (
    path: string,
    month: number,
    year: number,
    crypto?: boolean
  ) => {
    try {
      const { data } = await apiBolsa.get("/brokerage-notes/download", {
        params: { path: path.replace("notas/", ""), month, year },
      });
      if (data.url) {
        window.open(data.url);
      }
    } catch (error) {
      message.error("Algo errado aconteceu. Tente novamente mais tarde.");
    }
  };

  const getHistoricoNotasCorretagem = async ({
    filters,
    crypto,
  }: {
    crypto?: boolean;
    filters: any;
  }) => {
    setLoadingHistoricoNotasCorretagem(true);
    const filterDay = filters?.day || null;
    const filterMonth = filters?.month || null;
    const filterYear = filters?.year || null;
    try {
      const { data } = await apiBolsa.get(`/brokerage-notes/history`, {
        params: {
          day: filterDay,
          month: filterMonth,
          year: filterYear,
        },
      });
      if (data?.message) {
        message.error(data.message);
      } else {
        setHistoricoNotasCorretagem(
          data.map((nota: any) => ({
            ...nota,
            id: `${nota.month}${nota.year}`,
          }))
        );
      }
    } catch (error: any) {
      message.error("Algo errado aconteceu. Tente novamente mais tarde.");
    } finally {
      setLoadingHistoricoNotasCorretagem(false);
    }
  };

  const openB3Window = useCallback(() => {
    window.open(
      B3AuthURL,
      "_blank",
      "location=yes,height=570,width=520,scrollbars=yes,status=yes"
    );
  }, []);

  const integrate = useCallback(() => {
    HandleTag("69");
    setIntegrationLoading(true);
    apiBolsa
      .post("/b3/integrate", { email: user.user.email })
      .then(() => {
        handleIntegrationInit({ onlyB3: true });
        // const isProd = process.env.REACT_APP_NODE_ENV === "prd";
        // isProd &&
        //   (window as any).gtag &&
        //   (window as any).gtag("event", "conversion", {
        //     send_to: "AW-414293187/uHuyCODaxrYYEMO5xsUB",
        //   });
      })
      .catch((err) => {
        if (err?.response?.data?.message?.includes("Aguarde")) {
          message.error(err?.response?.data?.message);
        } else {
          message.error(errorMessage);
        }
      })
      .finally(() => setIntegrationLoading(false));
  }, [setIntegrationLoading, user?.user?.email]);

  const verifyUserIsAuthorized = useCallback(
    (once?: boolean, autoError?: boolean, autoSuccess?: boolean) => {
      let count = 0;

      const requestUserAuthorization = (
        interval?: NodeJS.Timeout,
        firstTime?: boolean
      ) =>
        apiBolsa
          .get("/b3/verify-user")
          .then((response) => {
            count += 1;
            if (response?.data?.isAuthorized) {
              setB3Authorized(true);
              setCheckingAuhtorization(false);
              (interval || firstTime) &&
                message.success("Permissão concedida com sucesso!", 5);
              (interval || firstTime || autoSuccess) && integrate();
              interval && clearInterval(interval);
              if (!once && !fromCheckout && !pathname.includes("autonomos")) {
                navigate("/carteira");
              }
            } else if (autoError) {
              openB3Window();
              setCheckingAuhtorization(true);
              verifyUserIsAuthorized();
            }
            if (interval && count > 12) {
              setTimeOver(true);
              clearInterval(interval);
              setCheckingAuhtorization(false);
            }
            setTimeout(() => {
              setB3AuthLoaded(true);
            }, 500);
            return response;
          })
          .catch((err) => {
            if (err?.response?.data?.message) {
              message.error(err?.response?.data?.message);
            }
            if (interval) {
              clearInterval(interval);
              setCheckingAuhtorization(false);
            }
          });

      requestUserAuthorization(undefined, !once).then((response) => {
        if (!once && !response?.data?.isAuthorized) {
          if (interval.current) {
            clearInterval(interval.current);
          }
          interval.current = setInterval(() => {
            requestUserAuthorization(interval.current);
          }, 6000);
        }
      });
    },
    [integrate, navigate, setB3Authorized, fromCheckout, pathname, openB3Window]
  );

  const handleIntegrate = useCallback(
    (props?: HandleIntegrationProps) => {
      HandleTag("15");
      if (!checkingAuhtorization) {
        if (timeOver && !b3Authorized) {
          verifyUserIsAuthorized(true, true, true);
        } else if (b3Authorized) {
          setIntegrationInBackground(!!props?.integrationInBackground);
          integrate();
        } else {
          openB3Window();
          setCheckingAuhtorization(true);
          verifyUserIsAuthorized();
        }
      } else {
        openB3Window();
      }
    },
    [
      timeOver,
      b3Authorized,
      integrate,
      verifyUserIsAuthorized,
      openB3Window,
      checkingAuhtorization,
    ]
  );

  const resetB3IntegrationContext = () => {
    setLoading(false);
    setTimeOver(false);
    setCheckingAuhtorization(false);
    interval.current && clearInterval(interval.current);
  };

  const handleSkipB3Integration = () => {
    setLoadingSkipB3Integration(true);
    api
      .post("/user/skip-b3-integration")
      .then(() => {
        updateUserInfo();
        setTimeout(() => {
          navigate("/carteira");
          setLoadingSkipB3Integration(false);
        }, 1000);
      })
      .catch(() => {
        message.warning("Tente novamente");
        setLoadingSkipB3Integration(false);
      });
  };

  const handleAuthorizationB3 = () => {
    setLoadingSkipB3Integration(true);
    api
      .get("/b3/checkExistAccountB3")
      .then(({ data }) => {
        if (!data?.success) {
          setTimeout(() => {
            navigate("/velotax/bolsa-integration-user-no-has-account");
            setLoadingSkipB3Integration(false);
          }, 1000);
        } else {
          setTimeout(() => {
            navigate("/velotax/bolsa-integration-user-has-account");
            setLoadingSkipB3Integration(false);
          }, 1000);
        }
      })
      .catch((e) =>
        message.error("Falha ao conectar com a B3. Tente novamente!")
      )
      .finally(() => setLoadingSkipB3Integration(false));
  };

  const backgroundIntegration = useMemo(() => {
    const lastUpdateDate = moment(
      integration?.lastUpdate ?? new Date(2020, 0, 1)
    );
    const month = lastUpdateDate.get("month") + 1;
    const year = lastUpdateDate.get("year");
    return { loading: initIntegration, month, year };
  }, [initIntegration, integration?.lastUpdate]);

  useEffect(() => {
    if (checkingAuhtorization) {
      setTimer(90);
      timerInterval.current && clearInterval(timerInterval.current);
      timerInterval.current = setInterval(() => {
        setTimer((timer) => (timer - 1 <= 0 ? 0 : timer - 1));
      }, 1000);
    } else {
      setTimer(0);
      timerInterval.current && clearInterval(timerInterval.current);
    }
    return () => {
      timerInterval.current && clearInterval(timerInterval.current);
    };
  }, [checkingAuhtorization]);

  useEffect(() => {
    if (initIntegration && (integration.hasError || integration.integrated)) {
      resetB3IntegrationContext();
      verifyUserIsAuthorized(true, integration.hasError);
    }
  }, [
    initIntegration,
    integration.hasError,
    integration.integrated,
    verifyUserIsAuthorized,
  ]);

  useEffect(() => {
    !!user.token && !b3Authorized && verifyUserIsAuthorized(true);
  }, [user.token, b3Authorized, verifyUserIsAuthorized]);

  useEffect(() => {
    return () => {
      interval.current && clearInterval(interval.current);
    };
  }, []);

  const [integrationMessage, setIntegrationMessage] = useState<string>();
  useEffect(() => {
    const evListenerFn = (ev: IntegrationStatus | null) => {
      if (ev?.processing || ev?.started) {
        setIntegrationLoading(true);
        setIntegrationMessage(ev.description);
        return;
      }
      if (ev?.error) {
        setIntegrationMessage(ev.description);
        message.error(
          "Houve um erro durante sua integração. Tente novamente mais tarde."
        );
        setIntegrationLoading(false);
        setInitIntegration(false);
        setModalConfirm(false);
        getIntegrationStatus({
          token: user.token,
          hideLoading: true,
        });
        return;
      }
      if (ev?.finished) {
        setIntegrationMessage(ev.description);
        setInitIntegration(false);
        setModalConfirm(false);
        setIntegrationLoading(false);
        getIntegrationStatus({
          token: user.token,
          hideLoading: true,
        });
        return;
      }
    };
    if (open) {
      addEventListener<IntegrationStatus | null>(
        WSClientEvents.INTEGRATION_STATUS,
        evListenerFn
      );
    }
    return () => {
      removeEventListener(WSClientEvents.INTEGRATION_STATUS, evListenerFn);
    };
  }, [open, user.token]);

  useEffect(() => {
    if (initIntegration) {
      const tm = setTimeout(() => {
        if (integrationLoadingRef.current) {
          setModalConfirm(true);
        }
      }, 50000);

      return () => {
        if (tm) clearTimeout(tm);
      };
    }
  }, [initIntegration]);

  return (
    <B3IntegrationContext.Provider
      value={{
        timer,
        loading,
        interval,
        timeOver,
        integrate,
        b3AuthLoaded,
        b3Authorized,
        handleIntegrate,
        checkingAuhtorization,
        verifyUserIsAuthorized,
        integrationInBackground,
        handleSkipB3Integration,
        loadingSkipB3Integration,
        resetB3IntegrationContext,
        setLoadingSkipB3Integration,
        handleAuthorizationB3,
        openB3Window,
        integrationMessage,
        backgroundIntegration,
        notas: {
          downloadNotaCorretagem,
          historicoNotasCorretagem,
          setHistoricoNotasCorretagem,
          getHistoricoNotasCorretagem,
          loadingHistoricoNotasCorretagem,
          showHistoricoNotasCorretagemModal,
          setShowHistoricoNotasCorretagemModal,
        },
      }}>
      <ModalConfirmWaitIntegration
        action={(res) => {
          apiBolsa.post("/b3/send-integration-mail", { send: res });
          setModalConfirm(false);
        }}
        closeFn={() => setModalConfirm(false)}
        visible={modalConfirm}
      />
      {children}
    </B3IntegrationContext.Provider>
  );
};

export const useB3Integration = () => useContext(B3IntegrationContext);
