import styled from "styled-components";

export const Container = styled.div`
  padding: 64px 32px 128px;
  &.view-edit {
    padding: 0;
  }
  &.view {
    padding: 0;
    min-height: 100%;
  }

  .ant-picker-month-panel {
    width: 100%;
  }
  .ant-picker-footer-extra {
    button {
      span {
        color: white;
      }
    }
  }

  .integration-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    max-width: 700px;
    margin: 0 auto 16px;
    a {
      font-weight: 600;
    }
  }

  .juridic-messages-container {
    opacity: 0.8;
    padding: 24px 0 0;
    margin: 0 auto;
    max-width: 700px;
    border-radius: 16px;

    .message {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 1rem;
    }

    p {
      font-size: 0.75rem;
      font-weight: 400;
      text-align: justify;
      line-height: 1.4rem;
      color: var(--velotax-font-color);
      margin-bottom: 0;

      i {
        font-weight: bold;
        color: var(--velotax-font-color);
      }
    }
  }

  @media only screen and (max-device-width: 812px) {
    padding: 0;
    background-color: var(--velotax-background-color);

    .juridic-messages-container {
      border-top: 1px solid var(--velotax-ghost);
    }

    .integration-text {
      padding: 16px 16px 0;
    }
  }
`;

export const ButtonContent = styled.div`
  max-width: 700px;
  margin: 0 auto 8px;
  div {
    display: flex;
    justify-content: flex-end;
  }

  @media only screen and (max-device-width: 812px) {
    max-width: 100%;
    div {
      width: 100%;
      padding: 16px 24px 0;
      button {
        span {
          font-size: 11px;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  @media only screen and (max-device-width: 576px) {
    div {
      button {
        width: 100%;
      }
    }
  }
`;

export const Content = styled.div`
  padding: 32px;
  &:not(.view):not(.view-edit) {
    border: 1px solid var(--velotax-background-color-ghost);
  }
  &.second.view,
  &.second.view-edit {
    margin-top: -24px;
    padding: 0 32px 32px;
  }
  &.second {
    padding: 32px;
    border: none;
    background-color: transparent;
  }
  .ant-divider {
    margin: 8px 0 24px;
  }
  margin: 0 auto;
  max-width: 700px;
  border-radius: 16px;
  background-color: var(--velotax-background-color);

  h3.date-title {
    font-size: 24px;
    font-weight: 700;
  }

  div.ant-typography {
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0 auto;
    color: var(--velotax-font-color);

    span {
      cursor: pointer;
      font-weight: 500;
      color: var(--ant-primary-color);
    }
  }
  h3 {
    display: flex;
    align-items: center;
    font-size: 20px;
    column-gap: 16px;
    color: var(--velotax-font-color);
    margin-bottom: 0;
  }
  .month-chevron {
    width: 32px;
    height: 32px;
    cursor: pointer;
    &.empty {
      cursor: default;
    }
  }
  .desc-label {
    display: flex;
    align-items: center;
    svg {
      fill: var(--ant-primary-color);
    }
  }
  .desc-content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    column-gap: 8px;
    svg {
      fill: var(--ant-primary-color);
    }
    span {
      align-self: center;
    }
    button {
      min-width: 32px;
    }
  }
  .market {
    opacity: 0.6;
    font-weight: bold;
  }
  .ml-40 {
    margin-left: 40px;
  }
  .add {
    float: right;
    min-width: 128px;
    margin-bottom: 16px;
  }
  .ant-descriptions-view {
    border-radius: 4px !important;
    .ant-descriptions-item-label {
      width: 60%;
      padding: 10px 16px;
      color: var(--velotax-font-color) !important;
      background-color: var(--velotax-ghost) !important;
    }
    .ant-descriptions-item-content {
      padding: 10px 16px;
      text-align: center;
      background-color: var(--velotax-background-color) !important;
    }
  }
  .ant-list.ant-list-split {
    clear: both;
    padding: 0 16px;
    background-color: var(--velotax-ghost);
    border: 1px solid var(--velotax-background-color-ghost);
    border-radius: 8px;
    .ant-list-item-action > li {
      padding: 0;
    }
  }
  .ant-list-item-action > li,
  .ant-list-item-meta-title,
  .ant-list-item-meta-description {
    color: var(--velotax-font-color);
  }
  .ant-list-item-meta-description {
    opacity: 0.75;
  }
  .list-description {
    display: flex;
    flex-direction: column;
  }
  .min-darf-price {
    margin-top: 24px;
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
  .text-center {
    display: block;
    text-align: center;
  }
  .ant-collapse-header {
    padding: 12px 0 !important;
  }
  .ant-collapse-content-box {
    padding: 16px 0 !important;
  }
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow
    svg {
    transform: rotate(-90deg);
  }
  .ant-collapse-item-disabled {
    & > .ant-collapse-header {
      cursor: default;
      .ant-collapse-arrow {
        opacity: 0;
      }
    }
  }
  .ant-collapse-header-text {
    width: 100%;
  }
  .total-tax-header {
    width: calc(100% - 48px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.original {
      margin-bottom: 12px;
      h3:last-of-type {
        width: 40%;
        margin-bottom: -4px;
      }
    }
    h3 {
      margin: 0;
    }
  }

  &.view .total-tax-header.impostos-devidos h3:first-of-type {
    max-width: 60%;
    font-size: 18px;
    margin-left: 4px;
    line-height: 22px;
  }

  @media only screen and (max-device-width: 812px) {
    padding: 24px;
    border-radius: 0;
    border: none !important;
    &.second {
      padding: 24px !important;
      background-color: var(--velotax-background-color);
      border-top: 1px solid var(--velotax-background-color-ghost) !important;
    }
    h3 {
      font-size: 18px;
      column-gap: 8px;
    }
    .total-tax-header {
      width: calc(100% - 20px);
      h3.text-center {
        span {
          font-size: 16px;
        }
      }
    }
    .anticon.anticon-right.ant-collapse-arrow {
      top: 24px;
      right: 0;
    }
    .desc-content {
      column-gap: 2px;
      flex-direction: row;
      &.is-editting {
        row-gap: 8px;
        flex-direction: column;
        button {
          width: 100%;
        }
      }
      &:not(.is-editting) {
        span {
          line-height: 32px;
        }
        button {
          margin-top: -2px;
        }
      }
      &.ml-40 {
        margin-left: 0;
      }
    }
    .market {
      font-size: 11px;
      letter-spacing: -0.1px;
    }
  }
`;

export const BolsaOperations = styled.div`
  border-radius: 8px;
  position: relative;
  color: var(--velotax-font-color);
  background-color: var(--velotax-background-color);
  border: 1px solid var(--velotax-background-color-ghost);

  .no-plan {
    span {
      user-select: none;
    }
  }

  .no-plan-container {
    position: absolute;
    left: 97px;
    width: 60%;
    min-width: calc(100% - 150px);
    
    z-index: 3;
    display: flex;
    padding: 8px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #fff4;
    border-radius: 0 0 8px 0;
    backdrop-filter: blur(4px);
    border-left: 1px solid var(--velotax-background-color-ghost);
    .ant-btn {
      margin-top: 8px;
    }
  }

  @media only screen and (min-width: 476px) {
    .no-plan-container {
      left: unset;
      right: 0;
      min-width: 300px;
    }
  }

  div.strong span {
    font-weight: 700 !important;
  }
  div.opacity span {
    opacity: 0.85;
    font-weight: 100 !important;
  }

  div.header,
  div.sub-header {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    span + span {
      text-align: center;
      justify-content: center;
    }
  }

  div.header-ext {
    grid-template-columns: 2fr 2fr !important;
  }

  div.sub-header {
    height: auto;
    min-height: 48px;
    border-top: none;
    & + div.sub-header {
      border-top: 1px solid var(--velotax-background-color-ghost);
    }
    & > span + span {
      background-color: var(--velotax-background-color);
    }
    & > span {
      padding: 8px 16px;
      display: flex;
      align-items: center;
      border-left: none;
      & + span {
        border-left: 1px solid var(--velotax-background-color-ghost);
      }
    }
    &:last-of-type > span:last-of-type {
      border-radius: 0 0 8px 0;
    }
  }

  div.header {
    height: 54px;
    padding: 16px 0;
    border-radius: 8px 8px 0 0;
    background-color: var(--velotax-background-color-ghost);
    span {
      font-weight: 700;
    }
    button {
      display: none;
    }
  }

  div.sub-header {
    font-size: 14px;
    & > span {
      font-weight: 400;
      background-color: var(--velotax-ghost);
    }
  }

  @media only screen and (max-device-width: 812px) {
    position: relative;
    overflow-x: hidden;
    span {
      font-size: 12px;
      button {
        max-width: 24px;
        min-width: 24px;
      }
    }
    .no-plan-container {
      left: 50%;
      width: 50%;
      font-size: 14px;
      line-height: 18px;
    }
    div.header,
    div.sub-header {
      width: 200%;
      height: auto;
      min-height: 48px;
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    div.sub-header {
      & > span {
        padding: 12px;
      }
    }

    div.header {
      padding: 16px 0;
    }
    div.header > span + span {
      padding: 0 8px;
      justify-content: space-between;
    }

    div.header > span:first-of-type,
    div.sub-header > span:first-of-type {
      position: sticky;
      left: 0;
    }
    div.header > span.show,
    div.sub-header > span.show {
      opacity: 1;
      z-index: 2;
    }
    div.header > span,
    div.sub-header > span {
      opacity: 0;
      z-index: 1;
    }
    div.header > span {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 8px;
    }
    div.header {
      button {
        display: flex;
      }
    }
    div.header > span.show svg polyline {
      stroke: var(--velotax-font-color);
    }
  }
`;
