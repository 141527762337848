import { Typography } from "antd";
import React, { Dispatch, SetStateAction } from "react";
import { DrawerModal } from "../../DrawerModal";
import myntLogo from "../../../assets/mynt.svg";

interface IMyntIntegrationModalProps {
  visible: boolean;
  dependentId?: string;
  setVisible: Dispatch<SetStateAction<boolean>>;
  setLoadingIntegration: Dispatch<SetStateAction<boolean>>;
}

const MyntIntegrationModal: React.FC<IMyntIntegrationModalProps> = ({
  visible,
  setVisible,
  dependentId,
  setLoadingIntegration,
}) => {
  const onCancel = () => {
    setVisible(false);
  };

  return (
    <DrawerModal
      footer={null}
      destroyOnClose
      visible={visible}
      onCancel={onCancel}
      maskClosable={false}
      title={
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <img
            src={myntLogo}
            alt="Mynt logo"
            style={{ height: "32px", marginBottom: "8px" }}
          />
          Integrar com a Mynt
        </span>
      }
    >
      <div style={{ margin: "10px" }}>
        <ul style={{ listStyle: "inside" }}>
          <Typography.Text>
            Para integrar com a Mynt, realize os seguintes passos:
          </Typography.Text>
          <li>
            <Typography.Text>
              Acesse o aplicativo ou{" "}
              <u>
                <b>
                  <a
                    href="https://www.mynt.com.br/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    website da Mynt
                  </a>
                </b>
              </u>
            </Typography.Text>
          </li>
          <li>
            <Typography.Text>
              Clique na aba <b>“Carteira”</b>
            </Typography.Text>
          </li>
          <li>
            <Typography.Text>
              Clique no botão <b>“Calcular imposto”</b>
            </Typography.Text>
          </li>
          <li>
            <Typography.Text>
              Aceite os Termos de Uso e clique em <b>“Continuar”</b>
            </Typography.Text>
          </li>
        </ul>
        <Typography.Text>
          Após realizar esses passos, sua integração será concluída. Você então
          poderá atualizar a página atual para confirmar que o procedimento
          funcionou corretamente.
        </Typography.Text>
      </div>
    </DrawerModal>
  );
};

export default MyntIntegrationModal;
