import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineCheck } from "react-icons/ai";
import { LoadingOutlined } from "@ant-design/icons";
import { Col, Divider, Form, Row, Typography } from "antd";
import { questions } from "./items";
import Loading from "../../components/Loading";
import { DoacoesHerancaData } from "./interfaces";
import { Question, QuestionContainer, BolsaManualContainer } from "./styles";
import { PrePreenchidaModal } from "../PrePreenchidaModal";
import { useUploadDec } from "../../contexts/UploadDecContext";
import { useOldTransactions } from "../../contexts/OldTransactionsContext";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";

const bolsaManualId = "bolsa-manual-container";

interface DoacoesHerancaProps {}

export const DoacoesHeranca: React.FC<DoacoesHerancaProps> = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm<DoacoesHerancaData>();
  const [currentQuestion] = useState(0);
  const [showAlertModal, setShowAlertModal] = useState(false);

  const { loadingGet, changed, getDoacoesHerancasInit, ...rest } =
    useOldTransactions();
  const [showModalPrePreenchida, setShowModalPrePreenchida] = useState(false);
  const { loading } = useUploadDec();

  const handleAlertModalVisibility = (value: boolean) => {
    setShowAlertModal(value);
  };

  const handleSubmit = async (data: DoacoesHerancaData) => {
    try {
      await rest.saveDoacoesHerancas();
    } catch (err) {}
  };

  useEffect(() => {
    getDoacoesHerancasInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModalPrePreenchida, loading]);

  return loadingGet ? (
    <Loading />
  ) : (
    <Form
      form={form}
      size="large"
      onFinish={handleSubmit}
      style={{ height: "100%", overflow: "hidden" }}
    >
      <BolsaManualContainer id={bolsaManualId}>
        {questions.map((question, index) => (
          <QuestionContainer
            width="700px"
            className="beauty-scrollbar"
            id={`question-${index}`}
            key={question.id}
          >
            <Typography.Title level={2}>{question.title}</Typography.Title>

            <Question width="700px">
              {question.formItems.map(({ FormItem, name }) => (
                <FormItem
                  key={name}
                  rest={{ ...rest, loadingGet }}
                  tabIndex={currentQuestion === index ? 1 : -1}
                />
              ))}
              <Divider />
              <Row justify="space-between" align="middle" gutter={[16, 16]}>
                <Col>
                  <Button
                    size="large"
                    variant="text"
                    style={{backgroundColor: "var(--ant-primary-color)", color: "white"}}
                    onClick={() => {
                      navigate("/velotax/bolsa");
                    }}
                  >
                    Voltar
                  </Button>
                </Col>
                <Col>
                  <Button
                    size="large"
                    type="submit"
                    style={{backgroundColor: "var(--ant-primary-color)", color: "white"}}
                    variant="contained"
                    id="submit-button"
                    tabIndex={currentQuestion === index ? 3 : -1}
                    endIcon={<AiOutlineCheck size={18} />}
                    startIcon={rest.loadingPut && <LoadingOutlined />}
                  >
                    Finalizar
                  </Button>
                </Col>
              </Row>
            </Question>
          </QuestionContainer>
        ))}

        <PrePreenchidaModal
          visible={showModalPrePreenchida}
          setShowModalPrePreenchida={setShowModalPrePreenchida}
          onCancel={() => setShowModalPrePreenchida(false)}
        />

        <DeleteConfirmationModal
          title="Deseja realmente prosseguir?"
          visibility={showAlertModal}
          onCancel={() => setShowAlertModal(false)}
          setVisibility={handleAlertModalVisibility}
          onOk={() => {
            return Promise.resolve(rest.saveDoacoesHerancas());
          }}
          body="O valor de algumas posições não foram preenchidas!"
          buttonLabel="Prosseguir"
          successMessage="Ativos salvos com sucesso!"
        />
      </BolsaManualContainer>
    </Form>
  );
};
