import styled, { css } from "styled-components";

export const PIPContainer = styled.div<{ show: boolean }>`
  top: 0;
  left: 0;
  position: fixed;
  z-index: 9999999999999;

  ${({ show }) =>
    show
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}

  .react-draggable {
    border-radius: 8px;
    box-shadow: 0px 8px 24px rgba(0, 0, 88, 0.08);
    background-color: var(--velotax-background-color);
    border: 2px solid var(--velotax-background-color-ghost);
    .header {
      display: flex;
      column-gap: 16px;
      height: 32px;
      padding: 0 8px;
      .handler {
        flex-grow: 1;
        row-gap: 4px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        span {
          display: block;
          height: 1px;
          width: 100%;
          background-color: var(--velotax-background-color-ghost);
        }
      }
    }
    .iframe-content {
      margin: 0 auto;
      padding: 0 8px 8px;
      width: calc(100% - 16px);
      height: calc(100% - 48px);
      iframe {
        width: 100%;
        height: 100%;
      }
    }
    .placeholder {
      position: absolute;
      width: calc(100% - 32px);
      height: calc(100% - 44px);
      background-color: black;
      opacity: 0.4;
    }
  }
`;
