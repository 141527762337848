import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { PIP } from "../components/Pip";
import { useAuth } from "./AuthContext";

interface IPIP {
  src: string;
  show: boolean;
}

interface IPIPContext {
  pip: IPIP;
  setPip: Dispatch<SetStateAction<IPIP>>;
}

export const PIPContext = createContext<IPIPContext>({} as IPIPContext);

export const PIPProvider: React.FC = ({ children }) => {
  const { user } = useAuth();
  const [pip, setPip] = useState<IPIP>({ src: "", show: false });

  return (
    <PIPContext.Provider value={{ pip, setPip }}>
      {children}
      {user && (
        <PIP
          src={pip.src}
          show={pip.show}
          handleClose={() => setPip((pip) => ({ ...pip, show: false }))}
        />
      )}
    </PIPContext.Provider>
  );
};

export const usePIP = () => useContext(PIPContext);
