import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../Button";
import { usePublicProgress } from "..";
import { isMobile } from "../../../utils";
import { HeaderContainer, Progress } from "./styles";
import { useAuth } from "../../../contexts/AuthContext";
import VelotaxLogo from "../../../assets/velotax/velotax-light.svg";

export const Header: React.FC<{ className?: string }> = ({ className }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user, signOut } = useAuth();
  const { progress } = usePublicProgress();
  const notLogin = pathname !== "/p/login";
  const notRegister = pathname !== "/p/cadastrar";
  const queryParams = new URLSearchParams(window.location.search);
  const isAd = Boolean(queryParams.get("ad"));

  return (
    <HeaderContainer className={className}>
      <Progress value={progress} />
      <h1>
        <a href="https://www.velotax.com.br/">
          <img className="fixed" alt="Velotax Logo" src={VelotaxLogo} />
        </a>
      </h1>
      {!user.user ? (
        <div className="buttons">
          {notLogin && (
            <>
              {!isMobile() && (
                <span className="login-desc">Já possui uma conta?</span>
              )}
              <Button
                type="link"
                size="middle"
                onClick={() => isAd ? navigate("/p/login?ad=true") : navigate("/p/login") } 
              >
                {!isMobile() ? "Faça seu login" : "Login"}
              </Button>
            </>
          )}
          {notRegister && (
            <Button
              type="primary"
              size="middle"
              onClick={() => isAd ? navigate("/p/cadastrar?ad=true") : navigate("/p/cadastrar")}
            >
              Começar
            </Button>
          )}
        </div>
      ) : (
        <div className="buttons">
          <Button type="link" size="middle" onClick={signOut}>
            Sair
          </Button>
        </div>
      )}
    </HeaderContainer>
  );
};
