import styled from "styled-components";

export const Content = styled.div`
  margin-top: 20px;
  .titulo {
    font-size: 16px;
    font-weight: 500;
    color: var(--ant-primary-color);
  }
  .beneficios {
    margin: 16px 0;
    border-radius: 8px;
    border: 1px solid var(--light-gray);
    .beneficio {
      padding: 16px;
      .beneficio-titulo {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 4px;
        svg {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
      }
      .beneficio-descricao {
        font-size: 13px;
        text-align: justify;
      }
    }
  }
  .skip-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    .ant-btn {
      font-size: 11px;
    }
  }
  @media only screen and (max-device-width: 812px) {
    .beneficios {
      .beneficio {
        .beneficio-titulo {
          align-items: start;
        }
      }
    }
  }
`;

export const Parceiros = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: -0.5px;
  .parceiros {
    display: flex;
    align-items: center;
    margin-bottom: 6px;

    svg.veloicon {
      height: 24px;
      width: auto;
      * {
        fill: var(--ant-primary-color);
      }
    }
    svg.arrow {
      height: 18px;
      margin: 0 10px;
      fill: var(--dark-gray);
    }
    img[alt="b3-logo"] {
      height: 24px;
    }
  }
`;

export const Subtitle = styled.span`
  font-size:13px;
  font-weight:bold;
  margin-bottom:5px;
`;
