import { Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

interface Feature {
  name: JSX.Element | string;
  help?: string;
  desc?: string;
  icon?: JSX.Element;
  secondaryIcon?: JSX.Element;
  secondaryLabel?: string;
}

export enum PlanEnum {
  BASIC_V1 = "BASIC_V1",
  PRO_V1 = "PRO_V1",
}

export enum MEIPlanEnum {
  MEI_PREMIUM = "MEI_PREMIUM",
  MEI_PREMIUM_MONTH = "MEI_PREMIUM_MONTH",
}

export interface Plan {
  type: PlanEnum | UserPlanEnum | MEIPlanEnum;
  title: string;
  price: number;
  discount: number;
  originalPrice: number;
  includedFeatures: Feature[];
  otherFeatures: Feature[];
}

export interface IPlan {
  key: string;
  level: number;
  price: number;
  instalments: number;
  title: string;
  malhaFinaIncluded: boolean;
}

export const OtherFeatures: Feature[] = [
  {
    name: "Emissão de relatório anual (DASN-MEI)",
  },
  {
    name: "Emissão de guias mensais (DAS)",
  },
];

export enum ETypePurchase {
  MEI = "MEI",
  UNIQUE = "UNIQUE",
  VELOTAX_MAIN_BASIC = "VELOTAX_MAIN_BASIC",
  VELOTAX_MAIN_BASIC_MONTH = "VELOTAX_MAIN_BASIC_MONTH",
  VELOTAX_MAIN_PRO = "VELOTAX_MAIN_PRO",
  VELOTAX_MAIN_PRO_MONTH = "VELOTAX_MAIN_PRO_MONTH",
  VELOTAX_MAIN_CONCIERGE = "VELOTAX_MAIN_CONCIERGE",
  VELOTAX_MAIN_CONCIERGE_MONTH = "VELOTAX_MAIN_CONCIERGE_MONTH",
}

export enum UserPlanEnum {
  VELOTAX_MAIN_BASIC = "VELOTAX_MAIN_BASIC",
  VELOTAX_MAIN_BASIC_MONTH = "VELOTAX_MAIN_BASIC_MONTH",
  VELOTAX_MAIN_PRO = "VELOTAX_MAIN_PRO",
  VELOTAX_MAIN_PRO_MONTH = "VELOTAX_MAIN_PRO_MONTH",
  VELOTAX_MAIN_CONCIERGE = "VELOTAX_MAIN_CONCIERGE",
  VELOTAX_MAIN_CONCIERGE_MONTH = "VELOTAX_MAIN_CONCIERGE_MONTH",
}

export const UserPlansTypes = [
  "VELOTAX_MAIN_BASIC",
  "VELOTAX_MAIN_BASIC_MONTH",
  "VELOTAX_MAIN_PRO",
  "VELOTAX_MAIN_PRO_MONTH",
  "VELOTAX_MAIN_CONCIERGE",
  "VELOTAX_MAIN_CONCIERGE_MONTH",
  "MEI_PREMIUM",
  "MEI_PREMIUM_MONTH",
];

export interface UserPlan extends IPlan {
  type: UserPlanEnum | MEIPlanEnum;
  interval: "Anual" | "Mensal";
  title: string;
  price: number;
  discount?: number;
  originalPrice: number;
  includedFeatures: Feature[];
  otherFeatures?: Feature[];
  free?: boolean;
  maskValue?: boolean;
  percentDiscount: number;
  descriptions?: any;
  discountText?: string;
  showDiscount: boolean;
}

export const UserPlans: UserPlan[] = [
  {
    type: UserPlanEnum.VELOTAX_MAIN_PRO,
    interval: "Anual",
    title: "PLANO PREMIUM",
    price: 25.9,
    originalPrice: 25.9,
    discount: 35,
    showDiscount: true,
    percentDiscount: 56,
    discountText: "Só mais 3 dias com esse preço",
    maskValue: true,
    descriptions: [],
    includedFeatures: [],
    level: 1,
    instalments: 12,
    key: UserPlanEnum.VELOTAX_MAIN_PRO,
    malhaFinaIncluded: false,
  },
];

export const mainBenefits = [
  {
    name: "Cálculos 100% precisos",
  },
  {
    name: "Conexão oficial com a B3 e com as corretoras",
  },
  {
    name: "Emissão de DARFs ilimitados e sem custo adicional",
  },
  {
    name: "Informes auxiliares de IR em PDF",
  },
  {
    name: (
      <div className="garantia">
        <span className="underlined">Garantia de satisfação</span> ou seu
        dinheiro de volta{" "}
        <Tooltip title="Oferecemos uma garantia de satisfação de sete dias para a sua contratação. Se, por qualquer motivo, você não estiver satisfeito, reembolsaremos integralmente o valor investido, desde que não tenhamos emitido DARFs ou gerado relatórios durante esse período.">
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
  },
];
