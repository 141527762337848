import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  .velotax-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 64px 32px 128px;

    .page-title-outside {
      width: 700px;
      margin: 0 auto;
      font-size: 2rem;
      font-weight: 700;
      line-height: 2.5rem;
      color: var(--velotax-font-color);
    }

    & > .ant-typography {
      width: 700px;
      display: block;
      color: var(--velotax-font-color);
      &.destak {
        font-weight: 700;
        font-size: 1rem;
        padding-left: 16px;
        line-height: 1.5rem;
        margin: 32px auto 24px;
        border-left: 4px solid var(--ant-primary-color);
      }
    }

    .velotax-content {
      width: 700px;
      padding: 48px;
      margin: 0 auto;
      border-radius: 16px;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 88, 0.08);
      background-color: var(--velotax-background-color);

      &.plano {
        margin-bottom: 48px;
      }

      &.velotax-content-transparent {
        padding: 0;
        border: none;
        box-shadow: none;
        margin: 24px auto 0;
        background-color: transparent;
      }

      &.historic {
        .anticon.anticon-loading.anticon-spin {
          width: 16px !important;
          height: 16px !important;
          svg {
            width: 16px !important;
            height: 16px !important;
          }
        }
      }
    }
  }

  h1.page-title {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 0 auto 4rem;
    color: var(--velotax-font-color);
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: -48px;
      width: 6px;
      height: 100%;
      background-color: var(--ant-primary-color);
    }
    &:after {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: 2px;
      bottom: -2rem;
      background-color: var(--light-gray);
    }
    &.imovel {
      svg {
        right: 0;
        top: 12px;
        cursor: pointer;
        position: absolute;
      }
    }
  }

  h2.subtitle-lined {
    display: flex;
    white-space: nowrap;
    align-items: center;
    width: 100%;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin: 3.5rem 0 1.5rem;
    span {
      margin-right: 16px;
    }
    &:after {
      content: "";
      height: 2px;
      flex-grow: 1;
      background-color: var(--light-gray);
    }
  }

  .ant-btn.ant-btn-primary.btn-premium {
    z-index: 1;
    right: -6px;
    font-size: 13px;
    position: absolute;
    top: calc(50% - 16px);
    border-radius: 4px 4px 0 4px;
    svg {
      width: 15px;
      height: 15px;
    }
    :after {
      content: "";
      width: 0;
      height: 0;
      right: -1px;
      bottom: -10px;
      position: absolute;
      border-left: 0 solid transparent;
      border-right: 6px solid transparent;
      border-top: 10px solid var(--ant-primary-color-active);
    }
  }

  .btn-contratar-plano-historic {
    top: 0;
    right: 0;
    height: 40px;
    position: absolute;
  }

  @media only screen and (max-device-width: 812px) {
    .velotax-container {
      padding: 0;
      min-height: 100%;
      background-color: var(--velotax-background-color);

      .page-title-outside {
        width: 100%;
        font-size: 1.5rem;
        line-height: 2rem;
        padding: 24px 24px 0;
      }

      & > .ant-typography {
        width: 100%;
        &.destak {
          margin: 16px 0;
          font-size: 14px;
          line-height: 20px;
          padding-left: 20px;
          padding-right: 24px;
        }
      }

      .velotax-content {
        width: 100%;
        padding: 24px 24px 64px;
        border-radius: 0;
        box-shadow: none;

        &.plano {
          margin-bottom: -24px;
        }

        &.velotax-content-transparent {
          padding: 24px;
          margin: 0 auto;
          border-top: 1px solid var(--velotax-background-color-ghost);
        }
      }
    }

    h1.page-title {
      width: 100%;
      font-size: 1.5rem;
      line-height: 2rem;
      margin: 0 auto 1.75rem;
      &:before {
        left: -24px;
      }
      &:after {
        display: none;
      }
      &.imovel {
        font-size: 1.125rem;
        svg {
          top: 6px;
        }
      }
    }

    h2.subtitle-lined {
      font-size: 1.125rem;
      line-height: 1.5rem;
      margin: 3rem 0 1.5rem;
    }

    .ant-btn.ant-btn-primary.btn-premium {
      right: -6px;
      font-size: 11px;
      svg {
        width: 13px;
        height: 13px;
      }
    }

    .ant-btn.ant-btn-primary.btn-contratar-plano-historic {
      display: none;
    }
  }
`;
