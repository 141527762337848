import { Button, ButtonProps } from "antd";
import styled, { css } from "styled-components";

export const ButtonStyled = styled(Button)<ButtonProps>`
  &.ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    column-gap: 8px;
  }
  &[disabled] {
    border-color: var(--velotax-ghost) !important;
    color: var(--velotax-font-color-ghost) !important;
    background-color: var(--velotax-ghost) !important;
  }
  ${(props) =>
    props.type === "primary" &&
    css`
      svg {
        fill: var(--white);
      }
    `}
  ${(props) =>
    props.size === "large" &&
    css`
      height: 56px;
    `}
    ${(props) =>
    props.disabled &&
    css`
      svg {
        fill: var(--velotax-font-color-ghost) !important;
      }
    `}
`;
