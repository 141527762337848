import { FaRegEdit } from "react-icons/fa";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Col, List, Row, Skeleton, Tooltip, Typography } from "antd";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineInfoCircle } from "react-icons/ai";
import { Container } from "./styles";
import Button from "../../../../components/Button";
import { formatCurrency } from "../../../../utils";
import { FormModal } from "../../../../components/FormModal";
import { AddTransactionGOVFormItemRows } from "../../../../constants/darfBolsa";

export interface OldTransactionsProps {
  changed: boolean;
  loadingPut: boolean;
  loadingGet: boolean;
  setLoadingPut: Dispatch<SetStateAction<boolean>>;
  setLoadingGet: Dispatch<SetStateAction<boolean>>;
  oldPositionEdit: any;
  oldPositionList: any[];
  showAddPositionModal: boolean;
  setOldPositionEdit: Dispatch<SetStateAction<any>>;
  setShowAddPositionModal: Dispatch<SetStateAction<boolean>>;
  saveOldPositions: () => void;
  addOldPosition: (item: any) => void;
  editOldPosition: (item: any) => void;
  handleEditOldPosition: (item: any, index: number) => void;
  handleRemoveOldPosition: (item: any, index: number) => void;
  getOldTransactionsInit: () => void;
}

export const OldTransactions: React.FC<OldTransactionsProps> = ({
  loadingGet,
  loadingPut,
  oldPositionList,
  setOldPositionEdit,
  setShowAddPositionModal,
  handleEditOldPosition,
  handleRemoveOldPosition,
  addOldPosition,
  editOldPosition,
  showAddPositionModal,
  oldPositionEdit,
}) => {
  const [hasLendingPosition, setHasLendingPosition] = useState(false);

  useEffect(() => {
    if (oldPositionEdit?._id || oldPositionEdit?.id) {
      if (oldPositionEdit?.lendingSide) {
        setHasLendingPosition(true);
      } else {
        setHasLendingPosition(false);
      }
    }
  }, [oldPositionEdit]);

  return (
    <Container>
      <Row justify="end" align="top" gutter={[16, 16]}>
        <Col span={24}>
          <Typography.Title level={1} className="page-title">
            Ativos em carteira
          </Typography.Title>
        </Col>
        <Col>
          <Button
            size="middle"
            type="primary"
            icon={<PlusCircleOutlined />}
            loading={loadingPut || loadingGet}
            onClick={() => {
              setOldPositionEdit({});
              setHasLendingPosition(false);
              setShowAddPositionModal(true);
            }}
          >
            Adicionar Posição
          </Button>
        </Col>
        <Col span={24}>
          <List
            loading={loadingGet}
            itemLayout="horizontal"
            dataSource={
              oldPositionList?.filter(
                (oldPosition) => !oldPosition.lendingSide
              ) ?? []
            }
            renderItem={(item, index) => (
              <List.Item
                className={item.price === 0 ? "error-border" : ""}
                actions={[
                  ...(item.price === 0
                    ? [
                        <Tooltip title="Preencha o custo médio!">
                          <Button
                            onClick={() => handleEditOldPosition(item, index)}
                            icon={
                              <AiOutlineInfoCircle
                                className="alert-icon"
                                size={20}
                              />
                            }
                            type="text"
                          />
                        </Tooltip>,
                      ]
                    : []),
                  <Button
                    type="text"
                    disabled={loadingPut}
                    icon={<FaRegEdit />}
                    onClick={() => handleEditOldPosition(item, index)}
                  />,
                  <Button
                    type="text"
                    disabled={loadingPut}
                    icon={<AiOutlineDelete />}
                    onClick={() => handleRemoveOldPosition(item, index)}
                  />,
                ]}
              >
                <Skeleton active title={false} loading={loadingGet}>
                  <List.Item.Meta
                    title={<div>{item.code}</div>}
                    description={
                      <>
                        <div className="list-description">
                          <div>
                            Quantidade: <strong>{item.quantity}</strong>
                          </div>
                          <div>
                            Custo médio:{" "}
                            <strong>{formatCurrency(item.price ?? 0)}</strong>
                          </div>
                        </div>
                      </>
                    }
                  />
                </Skeleton>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <br />
      <Row className="row-margin" justify="end" align="top" gutter={[16, 16]}>
        <Col span={24}>
          <Typography.Title level={1} className="page-title">
            Empréstimo de ativos
          </Typography.Title>
        </Col>
        <Col>
          <Button
            size="middle"
            type="primary"
            icon={<PlusCircleOutlined />}
            loading={loadingPut || loadingGet}
            onClick={() => {
              setOldPositionEdit({});
              setHasLendingPosition(true);
              setShowAddPositionModal(true);
            }}
          >
            Adicionar Posição
          </Button>
        </Col>
        <Col span={24}>
          <List
            loading={loadingGet}
            itemLayout="horizontal"
            dataSource={
              oldPositionList?.filter(
                (oldPosition) => oldPosition.lendingSide
              ) ?? []
            }
            renderItem={(item, index) => (
              <List.Item
                className={item.price === 0 ? "error-border" : ""}
                actions={[
                  ...(item.price === 0
                    ? [
                        <Tooltip title="Preencha o custo médio!">
                          <Button
                            icon={
                              <AiOutlineInfoCircle
                                className="alert-icon"
                                size={20}
                              />
                            }
                            type="text"
                          />
                        </Tooltip>,
                      ]
                    : []),
                  <Button
                    type="text"
                    disabled={loadingPut}
                    icon={<FaRegEdit />}
                    onClick={() => handleEditOldPosition(item, index)}
                  />,
                  <Button
                    type="text"
                    disabled={loadingPut}
                    icon={<AiOutlineDelete />}
                    onClick={() => handleRemoveOldPosition(item, index)}
                  />,
                ]}
              >
                <Skeleton active title={false} loading={loadingGet}>
                  <List.Item.Meta
                    title={<div>{item.code}</div>}
                    description={
                      <div className="list-description">
                        <div>
                          Quantidade: <strong>{item.quantity}</strong>
                        </div>
                        <div>
                          Custo médio:{" "}
                          <strong>{formatCurrency(item.price ?? 0)}</strong>
                        </div>
                      </div>
                    }
                  />
                </Skeleton>
              </List.Item>
            )}
          />
        </Col>
      </Row>

      <FormModal
        add={addOldPosition}
        edit={editOldPosition}
        visibility={showAddPositionModal}
        rows={AddTransactionGOVFormItemRows(hasLendingPosition)}
        onCancel={() => {
          setShowAddPositionModal(false);
          // setHasLendingPosition(false);
        }}
        title={
          oldPositionEdit?._id || oldPositionEdit?.id
            ? "Editar ativo"
            : "Adicionar ativo"
        }
        itemToEdit={
          oldPositionEdit.id || oldPositionEdit._id
            ? oldPositionEdit
            : undefined
        }
      />
    </Container>
  );
};
