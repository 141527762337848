import clsx from "clsx";
import { useState } from "react";
import { VscClose } from "react-icons/vsc";
import { FaRegEdit } from "react-icons/fa";
import { List, Form, message } from "antd";
import NumberFormat from "react-number-format";
import { InfoCircleOutlined } from "@ant-design/icons";
import Button from "../../components/Button";
import { HasPlan } from "../../contexts/AuthContext";
import { currencyToNumber, zero } from "../../utils";
import backendRendimentos from "../../services/rendimentos";
import { cpfInputMask, formatCurrency, isMobile } from "../../utils";
import {
  validationCpf,
  validationDateIsOnReferenceMonth,
  validationFieldRequired,
} from "../../utils/formValidations";
import {
  CurrencyFormItem,
  MaskFormItem,
  DateFormItem,
  SelectFormItem,
  TextFormItem,
} from "../formItems";
import {
  IDarf,
  IDarfBolsa,
  JurosModalProps,
  MinPriceModalProps,
  MultaModalProps,
  minDarfPrice,
} from "../darf";


const optionsType = [
  { label: "Trabalho Autônomo", value: "Trabalho Autônomo", },
  { label: "Aluguéis", value: "Aluguéis" },
  // { label: "Pensão Alimentícia", value: "Pensão Alimentícia" },
  { label: "Outros rendimentos no exterior", value: "Exterior", oldModel: true },
  { label: "Dividendos no exterior", value: "Dividendos no exterior", oldModel: true },
  { label: "Outros", value: "Outros" },
]
export const YearReport = 2023;

const RendimentoFormItems = {
  id: TextFormItem({
    name: "id",
    label: "Id",
    hidden: true,
  }),
  tipo: (year: number) => SelectFormItem({
    name: "tipo",
    label: "Tipo",
    placeholder: "Selecione",
    mustUpdate: true,
    options: year < 2024 ? optionsType : optionsType.filter(el => !el.oldModel),
  }),
  valorRendimento: CurrencyFormItem({
    name: "valorRendimento",
    label: "Rendimento",
    hide: ({ data }: any) => data?.tipo === "Dividendos no exterior",
  }),
  valorRendimentoUsd: CurrencyFormItem({
    placeholder: "US$ 0,00",
    prefix: "US$",
    name: "valorRendimentoUsd",
    label: "Valor bruto (USD)",
    hide: ({ data }: any) => data?.tipo !== "Dividendos no exterior",
  }),
  cpfPagador: MaskFormItem({
    mask: cpfInputMask,
    rules: validationCpf,
    name: "cpfPagador",
    label: "CPF do pagador",
    hide: ({ data }: any) => data?.tipo !== "Trabalho Autônomo",
    onChange: (changed: any, values: any) =>
      changed.cpfPagador
        ? changed.cpfPagador.replace(/[_.-]/g, "")
        : values.cpfPagador,
  }),
  date: (month: number, year: number) =>
    DateFormItem({
      required: true,
      placeholder: `01/${zero(month + 1)}/${year}`,
      mask: `99/${zero(month + 1).replace(/[9]/g, `\\9`)}/${year
        .toString()
        .replace(/[9]/g, `\\9`)}`,
      rules: validationDateIsOnReferenceMonth(month, year),
      name: "date",
      label: "Data",
      hide: ({ data }: any) => data?.tipo !== "Dividendos no exterior",
    }),
  taxaCambio: MaskFormItem({
    name: "taxaCambio",
    label: "Taxa de câmbio",
    disabled: true,
    hide: ({ data }: any) => data?.tipo !== "Dividendos no exterior",
  }),
  ativo: MaskFormItem({
    rules: validationFieldRequired,
    name: "ativo",
    label: "Ativo",
    hide: ({ data }: any) => data?.tipo !== "Dividendos no exterior",
  }),
  impostoPagoExteriorUsd: CurrencyFormItem({
    prefix: "US$",
    name: "impostoPagoExteriorUsd",
    label: "Imposto pago no exterior (USD)",
    hide: ({ data }: any) => data?.tipo !== "Dividendos no exterior",
  }),
  cpfBeneficiario: MaskFormItem({
    mask: cpfInputMask,
    rules: validationCpf,
    name: "cpfBeneficiario",
    label: "CPF do beneficiário",
    hide: ({ data }: any) => data?.tipo !== "Trabalho Autônomo",
    onChange: (changed: any, values: any) =>
      changed.cpfBeneficiario
        ? changed.cpfBeneficiario.replace(/[_.-]/g, "")
        : values.cpfBeneficiario,
  }),
  impostoPagoExterior: CurrencyFormItem({
    name: "impostoPagoExterior",
    label: "Imposto Pago no Exterior",
    hide: ({ data }: any) => data?.tipo !== "Exterior",
  }),
};

interface IEdit {
  value: any;
  id: string;
  isEditting: boolean;
}

export const ExteriorValueProps = {
  title: <>Impostos Pagos no Exterior</>,
  content: (
    <div>
      <p>
        Valor total dos impostos retidos e/ou pagos no exterior no mês
        selecionado
      </p>
    </div>
  ),
};

export const RendimentoFormItemRows = (month: number, year: number) => [
  [RendimentoFormItems.id],
  [RendimentoFormItems.tipo(year)],
  [RendimentoFormItems.date(month, year)],
  [RendimentoFormItems.taxaCambio],
  [RendimentoFormItems.ativo],
  [RendimentoFormItems.valorRendimento],
  [RendimentoFormItems.valorRendimentoUsd],
  [RendimentoFormItems.cpfPagador],
  [RendimentoFormItems.cpfBeneficiario],
  [RendimentoFormItems.impostoPagoExterior],
  [RendimentoFormItems.impostoPagoExteriorUsd],
];

export const ImpostoTotalRendimentos = [
  {
    id: "impostoDevido",
    label: () => "(+) Valor do principal",
    Component: ({ data }: any) => {
      const impostoPrincipal = Math.max(
        0,
        (data?.impostoDevido ?? 0) +
        (data?.impostoAcumulado ?? 0) -
        (data?.gastosExterior ?? 0)
      );

      return formatCurrency(impostoPrincipal);
    },
  },
  {
    id: "impostoDevido",
    label: () => (
      <div
        className="desc-label"
        style={{ fontSize: "12px", paddingLeft: "24px" }}
      >
        (+) Imposto devido
      </div>
    ),
    Component: ({ data }: any) =>
      formatCurrency(Number(data?.impostoDevido || 0)),
  },
  {
    id: "impostoAcumulado",
    label: (onClick: any) => (
      <div
        className="desc-label"
        style={{ fontSize: "12px", paddingLeft: "24px" }}
      >
        (+) DARFs passados abaixo de R$ 10
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          onClick={() => onClick(MinPriceModalProps)}
        />
      </div>
    ),
    Component: ({
      data,
      year,
      month,
      loading,
      disabled,
      setDataBolsa,
      setDataCripto,
      id,
      monthStock,
      user,
    }: any) => {
      const [edit, setEdit] = useState<IEdit>();
      const [editForm] = Form.useForm();
      const handleEditValue = (id: string) => {
        const value = data?.[id as keyof (IDarf | IDarfBolsa)];
        setEdit({ id, isEditting: true, value });
        editForm.setFieldsValue({ [id]: value });
      };

      const handleEditValueCancel = () => {
        setEdit(undefined);
        editForm.resetFields();
      };
      const handleEditValueConfirm = () => {
        if (edit && edit.value >= 0 && edit.value < minDarfPrice) {
          if (id !== "") {
            backendRendimentos
              .put("/rendimentos/updateImpostoAcumulado", {
                _id: id,
                year,
                month: month + 1,
                [edit.id]: edit.value,
              })
              .then(() =>
                (setDataCripto ?? setDataBolsa)?.((data: any) => ({
                  ...data,
                  [edit.id]: edit.value,
                }))
              )
              .catch(() =>
                message.error(
                  "Algo inesperado aconteceu! Tente novamente mais tarde."
                )
              )
              .finally(() => handleEditValueCancel());
          } else {
            backendRendimentos
              .post("/rendimentos", {
                monthStock,
                year,
                month: month + 1,
                juros: data.juros,
                multa: data.multa,
                userCode: user.user.cpf,
                total: data.impostoTotal,
                impostoDevido: data.impostoDevido,
                totalRendimentos: data.totalRendimentos,
                baseTributaria: data.baseTributaria,
                aliquotaDevida: data.aliquotaDevida,
                totalDependentes: data.totalDependentes,
                totalDespesas: data.totalDespesas,
                totalPrevidencia: data.totalPrevidencia,
                totalPensao: data.totalPensao,
                totalDeducoes: data.totalDeducoes,
                [edit.id]: edit.value,
              })
              .then(() =>
                (setDataCripto ?? setDataBolsa)?.((data: any) => ({
                  ...data,
                  [edit.id]: edit.value,
                }))
              )
              .catch(() =>
                message.error(
                  "Algo inesperado aconteceu! Tente novamente mais tarde."
                )
              )
              .finally(() => handleEditValueCancel());
          }
        } else {
          handleEditValueCancel();
        }
      };
      return edit && edit.isEditting && edit.id === "impostoAcumulado" ? (
        <div className="desc-content">
          <Button icon={<VscClose />} onClick={handleEditValueCancel} />
          <Form
            form={editForm}
            onValuesChange={(changed) => {
              const value = currencyToNumber(changed.impostoAcumulado);
              editForm.setFieldsValue({ impostoAcumulado: value });
              setEdit((edit) => ({ ...edit!, value }));
            }}
          >
            <Form.Item
              name="impostoAcumulado"
              rules={[
                {
                  message: `Deve ser menor que ${minDarfPrice}`,
                  validator: (rule, value) =>
                    typeof value === "number" &&
                    value >= 0 &&
                    value < minDarfPrice
                      ? Promise.resolve()
                      : Promise.reject(),
                },
              ]}
            >
              <NumberFormat
                prefix="R$ "
                decimalScale={2}
                disabled={loading}
                decimalSeparator=","
                thousandSeparator="."
                className="ant-input"
                allowNegative={false}
              />
            </Form.Item>
          </Form>
          <Button
            type="primary"
            onClick={handleEditValueConfirm}
            disabled={edit.value < 0 || edit.value >= minDarfPrice}
          >
            Ok
          </Button>
        </div>
      ) : (
        <div
          className={clsx("desc-content", {
            "ml-40": disabled,
          })}
        >
          {formatCurrency(Number(data?.impostoAcumulado || 0))}
          {disabled && (
            <Button
              type="text"
              icon={<FaRegEdit />}
              onClick={() => handleEditValue("impostoAcumulado")}
            />
          )}
        </div>
      );
    },
  },
  {
    id: "gastosExterior",
    label: () => (
      <div
        className="desc-label"
        style={{ fontSize: "12px", paddingLeft: "24px" }}
      >
        (-) Imposto Retido no exterior
      </div>
    ),
    Component: ({ data }: any) =>
      formatCurrency(Number(data?.gastosExterior || 0)),
  },
  {
    id: "multa",
    label: (onClick: any) => (
      <div className="desc-label">
        (+) Multa de atraso{" "}
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          onClick={() => onClick(MultaModalProps)}
        />
      </div>
    ),
    Component: ({ data }: any) =>
      formatCurrency(
        Number(data?.["impostoDevido"] || 0) +
          Number(data?.["impostoAcumulado"] || 0) <
          minDarfPrice
          ? 0
          : Number(data?.["multa"] || 0)
      ),
  },
  {
    id: "juros",
    label: (onClick: any) => (
      <div className="desc-label">
        (+) Juros e/ou encargos de atraso{" "}
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          onClick={() => onClick(JurosModalProps)}
        />
      </div>
    ),
    Component: ({ data }: any) =>
      formatCurrency(
        Number(data?.["impostoDevido"] || 0) +
          Number(data?.["impostoAcumulado"] || 0) <
          minDarfPrice
          ? 0
          : Number(data?.["juros"] || 0)
      ),
  },
];

export const RendimentosImpostosDevidos = [
  {
    id: "totalRendimentos",
    label: () => "Total rendimentos",
    render: (data: any) => formatCurrency(data?.totalRendimentos ?? 0),
  },
  {
    id: "aliquotaDevida",
    label: (onClick: any, props?: {year: number, month: number}) => (
      <div className="desc-label">
        (x) Alíquota devida
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          onClick={() => onClick(AliquotaModalProps(props))}
        />
      </div>
    ),
    render: (data: any) => `${data?.aliquotaDevida.toFixed(2)}%`,
  },
  {
    id: "impostoDevido",
    label: () => "Imposto devido",
    render: (data: any) => formatCurrency(data?.impostoDevido ?? 0),
  },
];

const AliquotaModalProps = (props?: {year: number, month: number}) => {
  const year = props?.year ?? new Date().getFullYear();
  const month = props?.month ?? new Date().getMonth();
  const aliquotaDate = new Date(year, month)
  // console.log('ALIQUOTA', year, month, props);
    
  const aliquota1FinalDate = new Date(2023, 4);
  const aliquota2FinalDate = new Date(2024, 1);

  let firstBreakpoint = 1_903.98;
  const installments: [number, number, number, number] = [
    142.8,
    354.8,
    636.13,
    869.36,
  ]
  if (aliquotaDate < aliquota1FinalDate) {
    firstBreakpoint = 1_903.98;
  } else if (aliquotaDate < aliquota2FinalDate) {
    firstBreakpoint = 2112;
    installments[0] = 158.40
    installments[1] = 370.40
    installments[2] = 651.73
    installments[3] = 884.96
  } else {
    firstBreakpoint = 2_259.20;
    installments[0] = 169.44
    installments[1] = 381.44
    installments[2] = 662.77
    installments[3] = 896.00
  }

  function toCurrencyBRL(v: number) {
    return v.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
  }

  const stringValueBreakpoint = toCurrencyBRL(firstBreakpoint);
  return {
    title: "Alíquota sobre Ganho Carnê-Leão",
    content: (
      <div>
        <p>
          O imposto sobre rendimentos é calculado sobre o total recebido no mês,
          mediante a aplicação da seguinte tabela progressiva mensal:
        </p>
        <List>
          <List.Item>a) Até {stringValueBreakpoint} não há impostos a pagar;</List.Item>
          <List.Item>
            b) De {stringValueBreakpoint} até R$ 2.826,65, a alíquota é de 7,5% sobre os
            rendimentos, com parcela a deduzir de {toCurrencyBRL(installments[0])};
          </List.Item>
          <List.Item>
            c) De R$ 2.826,66 até R$ 3.751,05, a alíquota é de 15% sobre os
            rendimentos, com parcela a deduzir de {toCurrencyBRL(installments[1])};
          </List.Item>
          <List.Item>
            d) De R$ 3.751,06 até R$ 4.664,68, a alíquota é de 22,5% sobre os
            rendimentos, com parcela a deduzir de {toCurrencyBRL(installments[2])}; e
          </List.Item>
          <List.Item>
            e) Acima de R$ 4.664,68, a alíquota é de 27,5% sobre os rendimentos,
            com parcela a deduzir de {toCurrencyBRL(installments[3])}.
          </List.Item>
        </List>
      </div>
    ),
  }
};

export const ModalDependentes = {
  title: "Dependentes",
  content: (
    <div>
      <p>A Receita Federal considera como dependentes, mas não só:</p>
      <p>
        - Companheiro(a) com quem o contribuinte tenha filho ou viva há mais de
        5 anos, ou cônjuge;
      </p>
      <p>- Filho(a) ou enteado(a), até 21 anos de idade;</p>
      <p>
        - Filho(a) ou enteado(a), se ainda estiverem cursando estabelecimento de
        ensino superior ou escola técnica de segundo grau, até 24 anos de idade;
      </p>
      <p>
        - Irmão(ã), neto(a) ou bisneto(a), sem arrimo dos pais, de quem o
        contribuinte detenha a guarda judicial, até 21 anos, ou em qualquer
        idade, quando incapacitado física ou mentalmente para o trabalho;
      </p>
      <p>
        - Irmão(ã), neto(a) ou bisneto(a), sem arrimo dos pais, com idade de 21
        anos até 24 anos, se ainda estiver cursando estabelecimento de ensino
        superior ou escola técnica de segundo grau, desde que o contribuinte
        tenha detido sua guarda judicial até os 21 anos;
      </p>
      <p>
        - Irmão(ã), neto(a) ou bisneto(a) com deficiência, sem arrimo dos pais,
        do(a) qual o contribuinte detém a guarda judicial, em qualquer idade,
        quando a sua remuneração não exceder as deduções autorizadas por lei
        (tendo em vista a decisão do Supremo Tribunal Federal – STF, na Ação
        Direta de Inconstitucionalidade nº 5.583/DF);
      </p>
      <p>
        - Pais, avós e bisavós que, em 2021, tenham recebido rendimentos,
        tributáveis ou não, até R$ 22.847,76;
      </p>
      <p>
        – Pessoa absolutamente incapaz, da qual o contribuinte seja
        tutor ou curador.
      </p>
    </div>
  ),
};

export const ModalAutonomos = {
  title: "Despesas de Autônomos",
  content: (
    <div>
      <p>
        As seguintes despesas poderão ser deduzidas dos rendimentos recebidos de
        pessoas físicas por autônomos:
      </p>
      <p>- Aluguel do escritório/consultório</p>
      <p>- Água do escritório/consultório</p>
      <p>- Contribuições obrigatórias a entidades de classe</p>
      <p>- Condomínio do escritório/consultório</p>
      <p>- Emolumentos pagos a terceiros</p>
      <p>- Cópia e autenticação de documentos</p>
      <p>- Emolumentos pagos a terceiros</p>
      <p>- Energia do escritório/consultório</p>
      <p>- Gás do escritório/consultório </p>
      <p>- IPTU do escritório/consultório quando pago pelo contribuinte</p>
      <p>- ISS</p>
      <p>- Material de conservação e limpeza do escritório/consultório</p>
      <p>- Material de escritório</p>
      <p>
        - Remuneração paga a terceiros, com vínculo empregatício, INSS e FGTS
      </p>
      <p>- Telefone do escritório/consultório</p>
    </div>
  ),
};

export const ModalPrevidencia = {
  title: "Despesas com Previdência",
  content: (
    <div>
      <p>
        Despesas com contribuição para a Previdência Social da União, dos
        Estados, do Distrito Federal e dos Municípios são dedutíveis da base de
        cálculo do imposto sobre rendimentos.
      </p>
      <p>
        As contribuições para as entidades de previdência privada domiciliadas
        no País, bem como as contribuições destinadas ao Fundo de Aposentadoria
        Programada Individual (FAPI), não podem ser deduzidas na determinação da
        base de cálculo mensal. Esses pagamentos são dedutíveis apenas na
        Declaração de Ajuste Anual.
      </p>
    </div>
  ),
};

export const ModalPensao = {
  title: "Despesas com Pensão Alimentícia",
  content: (
    <div>
      <p>
        Despesas com alimentos ou pensões, quando em cumprimento de decisão
        judicial ou acordo homologado judicialmente, são dedutíveis da base de
        cálculo do imposto sobre rendimentos.
      </p>
      <p>
        As despesas médicas e as de instrução, pagas em decorrência de decisão
        judicial ou acordo homologado na Justiça, não podem ser deduzidas na
        determinação da base de cálculo mensal. Esses pagamentos são dedutíveis
        apenas na Declaração de Ajuste Anual, obedecido o limite anual para os
        gastos com instrução.
      </p>
    </div>
  ),
};
