import React from "react";
import { Upload } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { RcFile, UploadChangeParam } from "antd/lib/upload";
import { UploadRequestOption as RcCustomRequestOptions } from "rc-upload/lib/interface";
import {
  ShowUploadListInterface,
  UploadFile,
  UploadListType
} from "antd/lib/upload/interface";
import { Container } from "./styles";

export interface IUploadProps {
  accept: string;
  tabIndex?:number;
  beforeUpload?: (file: RcFile) => void;
  customRequest?: (file: RcCustomRequestOptions) => void;
  loading?: boolean;
  multiple?: boolean;
  descText?: string;
  maxCount?: number;
  customText?: string;
  listType?: UploadListType;
  fileList?: UploadFile<any>[];
  showUploadList?: boolean | ShowUploadListInterface;
  onRemove?:
    | ((file: UploadFile<any>) => boolean | void | Promise<boolean | void>)
    | undefined;
  onChange?: ((info: UploadChangeParam<UploadFile<any>>) => void) | undefined;
}

const UploadVelotax: React.FC<IUploadProps> = ({
  accept,
  tabIndex,
  fileList,
  onRemove,
  onChange,
  maxCount,
  beforeUpload,
  customRequest,
  loading = false,
  multiple = false,
  descText = "",
  showUploadList = false,
  listType = "picture-card",
  customText = "Arraste ou clique"
}) => {
  return (
    <Container>
      <Upload
        accept={accept}
        disabled={loading}
        multiple={multiple}
        fileList={fileList}
        listType={listType}
        onRemove={onRemove}
        onChange={onChange}
        maxCount={maxCount}
        beforeUpload={beforeUpload}
        customRequest={customRequest}
        showUploadList={showUploadList}
        className="income-report-uploader"
      >
        {loading ? (
          <div className="loading-container">
            <LoadingOutlined />
            <span>Isso pode demorar alguns minutos, aguarde</span>
          </div>
        ) : (
          <div className="upload-text-icon">
            <AiOutlineCloudUpload size={28} />
            {customText}
            {descText && <span className="upload-text-desc">{descText}</span>}
          </div>
        )}
      </Upload>
    </Container>
  );
};

export default UploadVelotax;
