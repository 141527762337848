import { Col, Row } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { HomeContainer } from "./styles";
import { useAuth } from "../../contexts/AuthContext";
import { useBroker } from "../../contexts/BrokerContext";
import { useB3Integration } from "../../contexts/B3IntegrationContext";

export const Home = () => {
  const navigate = useNavigate();
  const { user, fromCheckout } = useAuth();
  const { b3Authorized, b3AuthLoaded } = useB3Integration();
  const {
    allPaths,
    pathBackup,
    integration,
    integrations,
    currentBroker,
    hasCriptoIntegration,
    hasExteriorIntegration,
  } = useBroker();

  useEffect(() => {
    if (fromCheckout) {
      navigate("/sucesso2");
    } else if (
      b3AuthLoaded &&
      integration.lastUpdate !== "" &&
      Object.keys(integrations).length > 0
    ) {
      const pathBackupWithoutSearch = pathBackup?.split("?")?.[0];
      if (!b3Authorized && !user?.user?.skippedB3Integration) {
        navigate(`/${currentBroker.path}/bolsa-integration`);
      } else if (allPaths.includes(pathBackupWithoutSearch)) {
        if (pathBackupWithoutSearch?.includes("cripto")) {
          if (hasCriptoIntegration) {
            navigate(pathBackup ?? "/carteira");
          } else {
            navigate(`/${currentBroker.path}/cripto-integrations`);
          }
        } else if (pathBackupWithoutSearch?.includes("exterior")) {
          if (hasExteriorIntegration) {
            navigate(pathBackup ?? "/carteira");
          } else {
            navigate(`/${currentBroker.path}/exterior-integrations`);
          }
        } else {
          navigate(pathBackup ?? "/carteira");
        }
      } else {
        navigate("/carteira");
      }
      // navigate("/declaracao-irpf");
    }
  }, [
    allPaths,
    navigate,
    pathBackup,
    integrations,
    b3Authorized,
    fromCheckout,
    b3AuthLoaded,
    currentBroker?.path,
    hasCriptoIntegration,
    hasExteriorIntegration,
    integration?.lastUpdate,
    user?.user?.skippedB3Integration,
  ]);

  return (
    <HomeContainer>
      <Row justify="center" style={{ width: "100%" }}>
        <Col>
          <LoadingOutlined />
        </Col>
      </Row>
    </HomeContainer>
  );
};
