import { message } from "antd";
import { RcFile } from "antd/lib/upload";
import { UploadRequestOption as RcCustomRequestOptions } from "rc-upload/lib/interface";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";
import apiIrpf2023 from "../services/apiIrpf2023";
import { useOldTransactions } from "./OldTransactionsContext";

interface IUploadDecContext {
  loading: boolean;
  loadingSkip: boolean;
  confirmarModal: boolean;
  beforeUpload: (file: RcFile) => boolean;
  customRequest: (params: RcCustomRequestOptions) => void;
  setConfirmarModal: Dispatch<SetStateAction<boolean>>;
}

const UploadDecContext = createContext({} as IUploadDecContext);

export const UploadDecProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [loadingSkip] = useState(false);
  const [confirmarModal, setConfirmarModal] = useState(false);
  const { getOldTransactionsInit } = useOldTransactions();

  const beforeUpload = (file: RcFile) => {
    const nameSplitted = file.name.split(".");
    const isDec = nameSplitted[nameSplitted.length - 1].toLowerCase() === "dec";

    if (!isDec) {
      message.error("Você só pode fazer upload de arquivos .dec");
    }

    return isDec;
  };

  const customRequest = ({ file }: RcCustomRequestOptions) => {
    const formData = new FormData();
    formData.append("file", file);
    setLoading(true);

    apiIrpf2023
      .post("/receitaFederal/processarDec2019IRPF", formData)
      .then(async () => {
        message.success("Arquivo .dec importado com sucesso!");
        let interval: NodeJS.Timeout | null = null;
        if (loading) {
          interval = setInterval(() => {
            apiIrpf2023
              .get(`/receitaFederal/checkStatusRecuperarDecIRPF`)
              .then(({ data }) => {
                if (data?.processamantoDec2019) {
                  setLoading(false);
                  getOldTransactionsInit();

                  return clearInterval(interval as unknown as NodeJS.Timeout);
                }
              });
          }, 2000);
        } else {
          if (interval) {
            clearInterval(interval);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        const errorMessage =
          err.reponse?.message?.error ||
          "Não foi possivel importar o arquivo .dec!";
        message.error(errorMessage);
        setLoading(false);
      });
  };

  return (
    <UploadDecContext.Provider
      value={{
        loadingSkip,
        beforeUpload,
        customRequest,
        loading,
        confirmarModal,
        setConfirmarModal,
      }}
    >
      {children}
    </UploadDecContext.Provider>
  );
};

export const useUploadDec = () => useContext(UploadDecContext);
