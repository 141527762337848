import { useLocation, useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { RiDownloadCloudLine } from "react-icons/ri";
import { Button as MaterialButton } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Badge, Col,Row, Modal, List, message, Select, Skeleton, Typography } from "antd";
import { cards } from "./items";
import apis from "../../services/apis";
import { Card } from "../ReportBolsa/Cards";
import Button from "../../components/Button";
import { Page } from "../../constants/brokers";
import { useAuth } from "../../contexts/AuthContext";
import { useBroker } from "../../contexts/BrokerContext";
import { ReportContainer, Container, Content } from "../ReportBolsa/styles";
import {
  download,
  downloadPDF,
  errorMessage,
  getInitialYear,
  monthsExtended,
  zero,
} from "../../utils";
import { YearReport } from "../../constants/rendimentos";

interface ReportProps {
  item: Page;
}

export const ReportExterior: React.FC<ReportProps> = ({ item }) => {
  const { showUserPlanModal, userPlanModal, hasPlan, user } = useAuth();

  const navigate = useNavigate();
  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = (today.getFullYear() - 1);
  const [initialYear, setInitialYear] = useState(currentYear);
  window.history.replaceState({}, document.title);

  const { currentPage } = useBroker();
  const [action, setAction] = useState<string>();

  const [data, setData] = useState<any[]>();
  const [infoModal, toggleModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingLinks] = useState(false);
  const [year, setYear] = useState(initialYear);

  const historicFeature = item.features[0];
  const downloadFeature = item.features[1];
  // const sendEmailFeature = item.features[2];
  const transactionsFeature = item.features[3];
  const darfFeature = item.features[4];

  const yearOptions = Array.from(Array(initialYear - 2020 + 1))
    .map((y, i) => ({
      value: 2020 + i,
      label: `${2020 + i}`,
    }))
    .reverse();

  const onChangeYear = (value: string) => {
    setYear(parseInt(value));
  };

  const getHistoric = useCallback(() => {
    if (!action) return;
    if (action === "anual-report")
      return setData(
        Array.from(Array(new Date().getFullYear() - 2022))
          .map((y, i) => ({
            year: 2022 + i,
            label: `${2022 + i}`,
          }))
          .reverse()
      );

    setLoading(true);
    (currentPage?.api || apis)
    .get(`${historicFeature.apiUrl}/${action || "gain"}`, {
      params: { year },
    })
      .then((response) => setData((response.data ?? []).reverse()))
      .catch(() => message.error(errorMessage))
      .finally(() => setLoading(false));
  }, [historicFeature, year, currentPage, action]);

  // const getDowloadLinks = useCallback(
  //   (data: any[], action: string) => {
  //     setLoadingLinks(true);
  //     const dataLinks = data.map((item) =>
  //       !!item._id
  //         ? (currentPage?.api || apis).post(
  //             `${downloadFeature.apiUrl}/${action}`,
  //             {
  //               id: item._id,
  //               year: item.year,
  //               month: item.month,
  //             }
  //           )
  //         : Promise.resolve({ data: item })
  //     );
  //     Promise.allSettled(dataLinks).then((response) => {
  //       setData(
  //         response.map((item, index) =>
  //           item.status === "fulfilled" && item.value.data?.file
  //             ? {
  //                 ...data?.[index],
  //                 url: `data:application/pdf;base64,${item.value.data.file}`,
  //               }
  //             : data?.[index]
  //         )
  //       );
  //       setLoadingLinks(false);
  //     });
  //   },
  //   [currentPage, downloadFeature]
  // );

  // const sendDarfToEmail = (reportId: string) => {
  //   (currentPage?.api || apis)
  //     .get(`${sendEmailFeature.apiUrl}/${reportId}`)
  //     .then(() => {
  //       message.success("O DARF foi enviado para o seu e-mail");
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const downloadAction = (record: any) => {
    setLoading(true);
    (currentPage?.api || apis)
      .post(`${downloadFeature.apiUrl}/${action}`, {
        id: record._id,
        month: record.month,
        year: record.year,
      })
      .then((res) => {
        if (res.data.file) {
          downloadPDF(res.data.file, "exterior");
        } else if (res.data.fileUrl) {
          download(res.data.fileUrl);
        }
        message.success("O relatório foi enviado para o seu e-mail");
      })
      .catch(({ response }) =>
        message.error(response?.data?.message ?? errorMessage)
      )
      .finally(() => setLoading(false));
  };

  const downloadDarf = async (data: any) => {
    setLoading(true);

    (currentPage?.api || apis).post(`${darfFeature.apiUrl}`, data)
      .then((res) => {
        if (!res?.data?.darfUrl ) {
          return message.error("Houve um erro ao baixar seu relatório!")
        }
        download(res.data.darfUrl);
        message.success("Relatório emitido com sucesso");
      })
      .catch(({ response }) =>
        message.error(response?.data?.message)
      )
      .finally(() => {
        setLoading(false);
      });
  };

  
  const checkDarf = (record: any) => {
    setLoading(true);
    (currentPage?.api || apis).get(`${transactionsFeature.apiUrl}`, { params: { year: record.year} })
    .then((res) => {
      const darf = res.data.find((d: any)=> {
        if (record?.month == d?.month) return d;
      })
      if (!darf || !darf?._id) {
        message.info("Cliente não possui relatório para este mês.");
        setLoading(false);
        return;
      }
      delete darf?.transactions;
      const data = {
        ...darf,
        year: record.year,
        month: record.month,
        name: user?.user?.name,
        memoriaCalculo: [],
        justSave: false,
        payed: false,
        noSendMail: true,
        paymentInfo: {
          clientInfo: {
            name: user?.user?.name,
            email: user?.user?.email,
            document: user?.user?.cpf,
          },
        },
      };
      setLoading(false);
      downloadDarf(data);
    })
    .catch(({ response }) => {
      const { data, status } = response;
      if (status === 401) message.error(data?.message);
      setLoading(false);
    });
  }

  const checkProcessorAction = (record: any) => {
    setLoading(true);
    let showToast = false;
    const itvl = setInterval(() => {
      (currentPage?.api || apis)
        .get(`${downloadFeature.apiUrl}/${action}?year=${record.year}`)
        .then((res) => {
          const { data } = res;
          const { year, status } = data;
          const isProcessing = record.year === year && status === "processing";
          if (isProcessing && showToast) return;
          if (isProcessing && !showToast) {
            showToast = true;
            return message.success(
              `Estamos processando os dados para seu relatório anual de ${year}`
            );
          }
          setLoading(false);
          clearInterval(itvl);
        })
        .catch(({ response }) => {
          const { data, status } = response;
          if (status === 401)
            setTimeout(() => navigate("/velotax/bolsa-integration"), 2000);
          message.error(data?.message ?? errorMessage);
          setLoading(false);
          clearInterval(itvl);
        });
    }, 5000);
  };

  const handlePlanModal = () => {
    showUserPlanModal(!userPlanModal);
  };

  const handleActionButton = (id: string) => {
    if (!hasPlan) {
      handlePlanModal();
    } else {
      setAction(id);
      switch (id) {
        case "anual-report":
        case "income":
          setYear(2023);
          setInitialYear(2023);
          break;
        case "gain":
          setYear(2024);
          setInitialYear(2024);
          break;
      }
    }
  };

  useEffect(() => {
    getHistoric();
  }, [getHistoric, year, action]);

  return (
    <ReportContainer>
      <div className="velotax-container">
        <div className="velotax-content">
          <Typography.Title level={1} className="page-title">
            Relatórios auxiliares
          </Typography.Title>
          <Typography.Paragraph>
            Confira os relatórios auxiliares de operações no exterior para a
            declaração do seu IR.
          </Typography.Paragraph>
          {!action && (
            <div className="cards">
              {cards.map((item) => (
                // <Badge.Ribbon
                //   key={item.id}
                //   text="Em breve"
                //   color="primary"
                //   style={{ display: item.id === "reports" ? "" : "none" }}
                // >
                  <Card
                    key={item.id}
                    icon={item.icon}
                    handleClick={() => !loading && handleActionButton(item.id)}
                  >
                    {item.content}
                  </Card>
                // </Badge.Ribbon>
              ))}
            </div>
          )}
          {action && (
            <Container>
              <Content>
                <Typography.Paragraph>Selecione o ano:</Typography.Paragraph>
                {action !== "anual-report" && (
                  <Select
                    size="large"
                    options={yearOptions}
                    value={year.toString()}
                    onChange={onChangeYear}
                    placeholder="Selecione o ano"
                    style={{ minWidth: "150px" }}
                    getPopupContainer={(trigger) => trigger.parentElement}
                  />
                )}
                <List
                  loading={loading}
                  dataSource={
                    data?.filter(
                      (item) => {
                        return (
                          action === "anual-report" ||
                          item.year === year ||
                          item.year === currentYear)
                      }
                    ) ?? []
                  }
                  itemLayout="horizontal"
                  renderItem={(item, index) => (
                    <List.Item
                      actions={[
                        <Button
                          type="text"
                          size="large"
                          href={item.url}
                          disabled={loadingLinks}
                          onClick={
                            () =>
                            {
                              (action === "anual-report")
                                ? toggleModal(item)
                                : ((action === "gain") && year === 2024)
                                  ? checkDarf(item)
                                  : downloadAction(item)
                            }
                          }
                          download={`Relatório-${item.year}-${zero(
                            item.month
                          )}.pdf`}
                        >
                          Baixar
                          {loadingLinks ? (
                            <LoadingOutlined size={16} />
                          ) : (
                            <RiDownloadCloudLine size={16} />
                          )}
                        </Button>,
                      ]}
                    >
                      <Skeleton title={true} loading={item.loading} active>
                        <List.Item.Meta
                          title={
                            action !== "anual-report"
                              ? `${monthsExtended[item.month - 1]}`
                              : `${item.year}`
                          }
                        />
                      </Skeleton>
                    </List.Item>
                  )}
                />
                <Button
                  type="default"
                  size="large"
                  onClick={() => handleActionButton("")}
                  style={{ marginTop: "1rem" }}
                >
                  Voltar
                </Button>
              </Content>
            </Container>
          )}
        </div>
        <Modal
          title="Aviso"
          maskClosable={true}
          visible={!!infoModal}
          onCancel={() => {
            toggleModal(null);
          }}
          footer={
            <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
              <Col span={24}>
                <MaterialButton
                  fullWidth
                  size="large"
                  style={{ backgroundColor: "#1634ff" }}
                  variant="contained"
                  onClick={() => {
                    downloadAction(infoModal);
                    toggleModal(null);
                  }}
                >
                  Continuar
                </MaterialButton>
              </Col>
              <Col xs={24} sm={12}>
                <MaterialButton
                  fullWidth
                  size="small"
                  variant="text"
                  color="info"
                  onClick={() => {
                    navigate("/velotax/exterior-historic");
                  }}
                >
                  Ir para calculadora de DARF
                </MaterialButton>
              </Col>
            </Row>
          }
        >
          <Typography.Paragraph style={{ marginTop: "-24px" }}>
            <strong>
              Caso você tenha realizado operações de venda de ativos ou recebido
              cupom de bonds em {year},
            </strong>{" "}
            certifique-se de que todos os meses estejam com a situação "Regular"
            na Calculadora de DARF.
          </Typography.Paragraph>
        </Modal>
      </div>
    </ReportContainer>
  );
};
