import styled from "styled-components";

export const Container = styled.div`
  padding: 64px 32px 128px;
  @media only screen and (max-device-width: 812px) {
    padding: 0;
    min-height: 100%;
    background-color: var(--velotax-background-color);
  }
  &.view,
  &.view-edit {
    padding: 0;
  }
`;

export const Content = styled.div`
  padding: 32px;
  &:not(.view):not(.view-edit) {
    border: 1px solid var(--velotax-background-color-ghost);
  }
  &.second.view,
  &.second.view-edit {
    margin-top: -24px;
    padding: 0 32px 32px;
  }
  &.second {
    padding: 32px;
    border: none;
    background-color: transparent;
  }
  width: 700px;
  margin: 0 auto;
  border-radius: 16px;
  background-color: var(--velotax-background-color);

  .no-plan-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    z-index: 3;
    height: 100%;
    padding: 8px;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: transparent;
    border-radius: 0 8px 8px 0;
    backdrop-filter: blur(2px);
    border: 1px solid var(--velotax-background-color-ghost);
    border-left: none;
    .ant-btn {
      margin-top: 8px;
    }
  }

  h3.date-title {
    font-size: 24px;
    font-weight: 700;
  }

  h3 {
    display: flex;
    align-items: center;
    font-size: 20px;
    column-gap: 16px;
    color: var(--velotax-font-color);
    margin-bottom: 0;
  }
  .month-chevron {
    width: 32px;
    height: 32px;
    cursor: pointer;
    &.empty {
      cursor: default;
    }
  }
  .desc-label {
    display: flex;
    align-items: center;
    svg {
      fill: var(--ant-primary-color);
    }
  }
  .desc-content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    column-gap: 8px;
    svg {
      fill: var(--ant-primary-color);
    }
    span {
      align-self: center;
    }
    button {
      min-width: 32px;
    }
  }
  .ml-40 {
    margin-left: 40px;
  }
  .add {
    min-width: 128px;
    margin: 0 0 16px auto;
  }
  .ant-descriptions-item-label {
    width: 50%;
    background-color: var(--velotax-ghost) !important;
  }
  .ant-descriptions-item-content {
    text-align: center;
    background-color: var(--velotax-background-color) !important;
  }
  .ant-list.ant-list-split {
    padding: 0 16px;
    background-color: var(--velotax-ghost) !important;
    border: 1px solid var(--velotax-background-color-ghost);
    border-radius: 8px;
    .ant-list-item-action > li {
      padding: 0;
    }
  }
  .ant-list-item-action > li,
  .ant-list-item-meta-title,
  .ant-list-item-meta-description {
    color: var(--velotax-font-color);
  }
  .ant-list-item-meta-description {
    opacity: 0.75;
  }
  .list-description {
    display: flex;
    flex-direction: column;
  }
  .min-darf-price {
    margin-top: 24px;
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
  .text-center {
    display: block;
    text-align: center;
  }
  .ant-collapse-header {
    padding: 12px 0 !important;
  }
  .ant-collapse-content-box {
    padding: 16px 0 !important;
  }
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow
    svg {
    transform: rotate(-90deg);
  }
  .ant-collapse-header-text {
    width: 100%;
  }
  .ant-collapse-item-disabled {
    & > .ant-collapse-header {
      cursor: default;
      .ant-collapse-arrow {
        opacity: 0;
      }
    }
  }
  .total-tax-header {
    width: calc(100% - 48px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.original {
      h3:last-of-type {
        width: 40%;
        margin-bottom: 2px;
      }
    }
    h3 {
      margin: 0 0 6px;
    }
  }

  &.view .total-tax-header.impostos-devidos h3:first-of-type {
    max-width: 60%;
    font-size: 18px;
    margin-left: 4px;
    line-height: 22px;
  }

  h5 {
    margin: 0px;
  }

  @media only screen and (max-device-width: 812px) {
    width: 100%;
    border-radius: 0;
    padding: 24px 24px 48px;
    border: none !important;
    &.second {
      margin-top: -24px;
      padding: 0 24px 48px !important;
      background-color: var(--velotax-background-color);
    }
    h3 {
      font-size: 18px;
      column-gap: 8px;
    }
    .no-plan-container {
      left: 50%;
      width: 50%;
      font-size: 14px;
      line-height: 18px;
    }
    .total-tax-header {
      width: calc(100% - 20px);
      h3.text-center {
        span {
          font-size: 16px;
        }
      }
    }
    .anticon.anticon-right.ant-collapse-arrow {
      top: 24px;
      right: 0;
    }
    .desc-content {
      column-gap: 2px;
      flex-direction: row;
      &.is-editting {
        row-gap: 8px;
        flex-direction: column;
        button {
          width: 100%;
        }
      }
      &:not(.is-editting) {
        span {
          line-height: 32px;
        }
        button {
          margin-top: -2px;
        }
      }
      &.ml-40 {
        margin-left: 0;
      }
    }
  }
`;

export const BolsaOperations = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  border-radius: 8px;
  color: var(--velotax-font-color);
  background-color: var(--velotax-background-color);
  border: 1px solid var(--velotax-background-color-ghost);

  div.body-deducoes-tributarias {
    display: flex;
    flex-direction: column;
    padding: 0 8px;
    border-radius: 0 8px 8px 0;
    background-color: var(--velotax-background-color);
    border-left: 1px solid var(--velotax-background-color-ghost);
    span {
      height: 48px;
      display: flex;
      font-weight: 700;
      align-items: center;
      column-gap: 8px;
    }
  }

  div.header-deducoes-tributarias {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    border-radius: 8px 0 0 8px;
    background-color: var(--velotax-ghost);
    span {
      height: 48px;
      display: flex;
      font-weight: 700;
      align-items: center;
      column-gap: 8px;
      border-top: none;
      + span {
        border-top: 1px solid var(--velotax-background-color-ghost);
      }
    }
  }

  @media only screen and (max-device-width: 812px) {
    grid-template-columns: 1.6fr 1fr;
    span {
      font-size: 12px;
      button {
        max-width: 24px;
        min-width: 24px;
      }
    }
  }
`;
