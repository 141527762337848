import { useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";
import { AiOutlineArrowDown } from "react-icons/ai";
import { Col, Divider, Form, Row, message } from "antd";
import Button from "../Button";
import api from "../../services/api";
import { isMobile } from "../../utils";
import { DrawerModal } from "../DrawerModal";
import { useAuth } from "../../contexts/AuthContext";
import { useBroker } from "../../contexts/BrokerContext";
import { validationPhoneRequired } from "../../utils/formValidations";
import { ReactComponent as WhatsAppIcon } from "../../assets/whatsapp.svg";
import HandleTag from "../../services/handleTag";

export const PhoneNotification = () => {
  const { loadingIntegrationStatus } = useBroker();
  const {
    user,
    setUser,
    loadingSession,
    updateUserInfo,
    showPhoneModal,
    cameFromRegister,
    setShowPhoneModal,
  } = useAuth();

  const [showed, setShowed] = useState(false);
  const [loading, setLoading] = useState(false);

  const onCancel = () => {
    setLoading(true);
    api
      .post("/user/cellphone-filled", { document: user.user.cpf })
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        setUser((user: any) => ({
          ...user,
          user: { ...user.user, cellphoneFilled: true },
        }));
        updateUserInfo();
        setTimeout(() => {
          setShowPhoneModal(false);
          setLoading(false);
        }, 1000);
      });
  };

  const onFinish = ({ phone }: { phone: string }) => {
    setLoading(true);
    api
      .put("/user/feed-userinfo", {
        name: user.user.name,
        document: user.user.cpf,
        phone: phone?.replace(/[\s()-]/g, ""),
      })
      .then(() => {
        HandleTag("169");
        message.success("Obrigado por adicionar seu número!");
      })
      .catch(() => {})
      .finally(() => {
        onCancel();
      });
  };

  useEffect(() => {
    if (
      !showed &&
      user.user &&
      !loadingSession &&
      !loadingIntegrationStatus &&
      !user?.user?.cellphone &&
      !cameFromRegister.bolsa &&
      !cameFromRegister.phone
    ) {
      setShowed(true);
      // setShowPhoneModal(true);
    }
  }, [
    showed,
    user.user,
    loadingSession,
    setShowPhoneModal,
    cameFromRegister.phone,
    cameFromRegister.bolsa,
    loadingIntegrationStatus,
  ]);

  return (
    <DrawerModal
      width={600}
      height="auto"
      closable={false}
      onCancel={onCancel}
      maskClosable={false}
      className="no-closable"
      visible={showPhoneModal}
      title={
        <span style={{ fontWeight: "700" }}>Proteção contra a malha-fina</span>
      }
    >
      <Form size="large" layout="vertical" onFinish={onFinish}>
        <Row align="stretch" wrap={false}>
          <Col>
            <WhatsAppIcon style={{ width: "32px", marginRight: "8px" }} />
          </Col>
          <Col>
            <span
              style={{
                height: "32px",
                display: "inline-block",
                ...(!isMobile() ? { lineHeight: "32px" } : {}),
              }}
            >
              Receba seus DARFs sempre que possuir impostos em aberto
            </span>
          </Col>
        </Row>
        <br />
        <Form.Item name="phone" rules={validationPhoneRequired}>
          <ReactInputMask
            disabled={loading}
            mask="(99) 99999-9999"
            placeholder="(11) 90000-0000"
            className="ant-input ant-input-lg"
          />
        </Form.Item>
        <Divider />
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Button
              block
              size="large"
              type="primary"
              htmlType="submit"
              loading={loading}
              icon={<AiOutlineArrowDown />}
            >
              Prosseguir
            </Button>
          </Col>
          <Col span={24}>
            <Button
              type="link"
              size="small"
              disabled={loading}
              onClick={onCancel}
              style={{ margin: "0 0 16px auto", fontSize: "12px" }}
            >
              Não me avise quando estiver devendo impostos{" "}
            </Button>
          </Col>
        </Row>
      </Form>
    </DrawerModal>
  );
};
