import clsx from "clsx";
import { BsX } from "react-icons/bs";
import { BiRefresh } from "react-icons/bi";
import { FaPaperPlane } from "react-icons/fa";
import { MdErrorOutline } from "react-icons/md";
import { LoadingOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import { IoChatbubblesOutline } from "react-icons/io5";
import { chatApi } from "../../services/chatApi";
import { ChatButton, ChatWindow } from "./styles";
import { useAuth } from "../../contexts/AuthContext";
import { ReactComponent as VelotaxIcon } from "../../assets/velotax/veloicon.svg";

interface Msg {
  user: "velotax" | "user";
  text: string;
  error?: boolean;
}

interface APIChatMessage {
  userMessage: string;
  botResponse: string;
  createdAt: Date;
}

const defaultMsg: Msg = {
  user: "velotax",
  text: "Olá! Eu sou o assistente virtual da Velotax. Como posso te ajudar?",
};

export const ChatGPT = () => {
  const { signed, user } = useAuth();
  const chatBodyRef = useRef<HTMLDivElement>(null);

  const [msg, setMsg] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [msgs, setMsgs] = useState<Msg[]>([defaultMsg]);

  chatApi.defaults.headers.common["Authorization"] = `Bearer ${user?.token}`;

  const hide =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  const disabled = !msg.replace(/\s/g, "").length;

  useEffect(() => {
    if (signed) {
      loadMsgs();
    }
  }, [signed]);

  const resetChat = () => {
    setMsg("");
    setMsgs([defaultMsg]);
  };

  const loadMsgs = async () => {
    try {
      setLoading(true);
      const { data } = await chatApi.get<APIChatMessage[]>("/chat/user");
      if (data?.length > 0) {
        setMsgs([
          defaultMsg,
          ...data.reduce(
            (acc, cur) => [
              ...acc,
              { user: "user", text: cur.userMessage } as Msg,
              { user: "velotax", text: cur.botResponse } as Msg,
            ],
            [] as Msg[]
          ),
        ]);
      }
    } catch {
    } finally {
      setLoading(false);
    }
  };

  const sendMsg = async (text: string) => {
    setMsgs((msgs) => [...msgs, { user: "user", text }]);
    setLoading(true);
    setMsg("");
    try {
      const { data } = await chatApi.post("/chat/user", {
        userMessage: text,
      });
      setLoading(false);
      setMsgs((msgs) => [...msgs, { user: "velotax", text: data.botResponse }]);
    } catch {
      setLoading(false);
      setMsgs((msgs) => [
        ...msgs,
        {
          error: true,
          user: "velotax",
          text: "Não conseguimos responder sua mensagem. Tente novamente mais tarde!",
        },
      ]);
    }
  };

  return signed ? (
    <>
      <ChatButton
        className={clsx({ hide })}
        onClick={() => {
          setShow((show) => {
            if (!show && !scrolled) {
              setScrolled(true);
              setTimeout(() => {
                chatBodyRef.current?.scrollTo({
                  top: chatBodyRef.current?.scrollHeight,
                });
              }, 50);
            }
            return !show;
          });
        }}
      >
        <IoChatbubblesOutline />
      </ChatButton>
      <ChatWindow className={clsx({ hide, hidden: !show })}>
        <div className="chat-header">
          <div className="chat-icon">
            <VelotaxIcon />
          </div>
          <h1>Assistente Velotax</h1>
          <div className="reset-icon" onClick={resetChat}>
            <BiRefresh />
          </div>
          <div className="close-icon" onClick={() => setShow(false)}>
            <BsX />
          </div>
        </div>
        <div className="chat-body" ref={chatBodyRef}>
          <div className="chat-body-conversation">
            {msgs.map(({ user, text, error }, i) =>
              user === "velotax" ? (
                <div className="chat-msg" key={i}>
                  <span className={clsx("chat-msg-icon", { error })}>
                    {error ? <MdErrorOutline /> : <VelotaxIcon />}
                  </span>
                  <span className="chat-mgs-text">{text}</span>
                </div>
              ) : (
                <div className="chat-msg chat-msg-user" key={i}>
                  <span className="chat-mgs-text">{text}</span>
                </div>
              )
            )}
            {loading && (
              <div className="chat-msg">
                <div className="chat-msg">
                  <span className="chat-msg-icon">
                    <VelotaxIcon />
                  </span>
                  <span className="chat-mgs-text">
                    <LoadingOutlined />
                  </span>
                </div>
              </div>
            )}
            <div className="chat-body-white-space" />
          </div>
        </div>
        <div className="chat-footer">
          <div className="chat-input-container">
            <textarea
              value={msg}
              placeholder="Escreva sua dúvida aqui..."
              onChange={(e) => setMsg(e.target.value || "")}
              rows={
                2 +
                Math.floor(msg.length / 45) +
                msg.split("").filter((t) => t === "\n").length
              }
            />
            <button
              disabled={disabled}
              onClick={() => {
                if (!disabled) {
                  sendMsg(msg);
                }
              }}
            >
              <FaPaperPlane size={20} />
            </button>
          </div>
        </div>
      </ChatWindow>
    </>
  ) : (
    <></>
  );
};
