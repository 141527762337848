import styled from "styled-components";

export const Content = styled.div`
  .termos {
    padding: 0;
    font-size: 12px;
    margin: 32px auto 16px;
    text-align: justify;
  }
  label[for="cpf"],
  label[for="email"] {
    font-weight: 700;
    &::before {
      display: none !important;
    }
  }
  @media only screen and (max-device-width: 812px) {
    .corretoras {
      margin-top: -24px;
      & > div {
        row-gap: 12px;
        flex-wrap: wrap;
        column-gap: 24px !important;
        justify-content: start !important;
      }
    }
  }
`;
