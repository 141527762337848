import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { Form, FormInstance, message } from "antd";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  ReactNode,
  useMemo,
} from "react";
import api from "../services/api";
import apis from "../services/apis";
import meiApi from "../services/meiApi";
import msDarf from "../services/msDarf";
import apiBolsa from "../services/apiBolsa";
import apiCrypto from "../services/apiCrypto";
import apiVeloPro from "../services/apiVeloPro";
import sslSecure from "../assets/ssl-secure.svg";
import imoveisBackend from "../services/imoveis";
import apiIrpf2023 from "../services/apiIrpf2023";
import apiExterior from "../services/apiExterior";
import { apiPayment } from "../services/apiPayment";
import { BannerApp } from "../components/BannerAPP";
import { isMobile, planInfoToHasPlan } from "../utils";
import backnedRendimentos from "../services/rendimentos";
//import { handleInAppPurchaseIfIsIos } from "../utils/webview";

export const mobileAppIOSURL = [
  "appios-dev.velotax.com.br",
  "appios.velotax.com.br",
];
export const mobileAppAndroidURL = [
  "appandroid-dev.velotax.com.br",
  "appandroid.velotax.com.br",
];

interface ILoginData {
  cpf: string;
  pass: string;
  isAd?: boolean;
}

interface IModuleData {
  name: string;
  completed: boolean;
}

interface IDependent {
  name: string;
  cpf: string;
  profileId: string;
  type: string;
  _id: string;
}

interface PlanInfo {
  currentPlan: any;
  hasExpired: boolean;
  active: boolean;
  dueDate: number;
  type: string;
  paid: boolean;
  concierge?: boolean;
  permissions?: any;
}

interface IUser {
  name: string;
  email: string;
  cpf: string;
  birthdate?: string;
  cellphone?: string;
  cpfProtegido: boolean;
  profileId: string;
  dependents?: IDependent[];
  visibleCalcs: string[];
  visibleModules: IModuleData[];
  modulesStatus: IModuleData[];
  answeredStepDec: boolean;
  isAccountant?: boolean;
  userId?: string;
  showAccountantPremiumButton: {
    show: boolean;
    disabled: boolean;
  };
  showContratarContador: {
    show: boolean;
    disabled: boolean;
  };
  showContratarMalhaFina: {
    show: boolean;
    protected: boolean;
  };
  userPlanInfoVelotax: PlanInfo;
  meiPlanInfoVelotax?: PlanInfo;
  skippedB3Integration?: boolean;
  cellphoneFilled?: boolean;
  hasDismissed?: boolean;
  isAd?: boolean;
}

interface ISessionData extends IUser {
  token: string;
}

interface IAuthState {
  token: string;
  user: IUser;
}

interface HandleCalcsParamas {
  calcs: any[];
  navigateTo?: string;
}

interface CameFromRegister {
  bolsa: boolean;
  phone: boolean;
}

export interface HasPlan {
  basic: boolean;
  premium: boolean;
  concierge: boolean;
}

interface IAuthContext {
  handleSignIn: (loginData: ILoginData) => void;
  handleSignUp: (
    signUpData: any,
    onboardingData: any,
    hiringData?: any
  ) => void;
  setLocalStorageWithAuthData: (sesionData: ISessionData) => void;
  signOut: () => void;
  signed: boolean;
  user: IAuthState;
  loading: boolean;
  loadingSession: boolean;
  setLoadingSession: React.Dispatch<React.SetStateAction<boolean>>;
  getUserInfo: () => void;
  updateUserDec: () => void;
  authAccountantSSOCnpj: () => void;
  decUrl: string;
  setDecUrl: (decUrl: string) => void;
  showDbkModal: boolean;
  setShowDbkModal: (showDbkModal: boolean) => void;
  showIndicarModal: boolean;
  setShowIndicarModal: (showIndicarModal: boolean) => void;
  wasMessageSent: boolean;
  setWasMessageSent: (wasMessageSent: boolean) => void;
  showUserPlanModal: (show: boolean, title?: ReactNode) => void;
  userPlanModal: boolean;
  handleCalcs: (params: HandleCalcsParamas) => void;
  setUser: (data: any) => void;
  ssoToken: string;
  irpfBannerActive: boolean;
  forceUpdateTabNavigator: boolean;
  setForceUpdateTabNavigator: React.Dispatch<React.SetStateAction<boolean>>;
  disabledRecaptcha: boolean;
  hasPlan: false | HasPlan;
  cameFromRegister: CameFromRegister;
  setCameFromRegister: React.Dispatch<React.SetStateAction<CameFromRegister>>;
  chatOpen: boolean;
  setChatOpen: React.Dispatch<React.SetStateAction<boolean>>;
  showDrawer: boolean;
  setShowDrawer: Function;
  chatNotificationQtt: number;
  setChatNotificationQtt: React.Dispatch<React.SetStateAction<number>>;
  resgisterForm: FormInstance<any>;
  isIOSAPP: boolean;
  isAndroidAPP: boolean;
  b3Authorized: boolean;
  setB3Authorized: React.Dispatch<React.SetStateAction<boolean>>;
  updateUserInfo: () => void;
  showPhoneModal: boolean;
  setShowPhoneModal: React.Dispatch<React.SetStateAction<boolean>>;
  showNameModal: boolean;
  showIRPFAPPBanner: boolean;
  setShowNameModal: React.Dispatch<React.SetStateAction<boolean>>;
  hasExpired: boolean;
  hasDismissed: boolean;
  directPayment: boolean;
  setDirectPayment: React.Dispatch<React.SetStateAction<boolean>>;
  initIOSPlanCheck: () => void;
  fromCheckout: boolean;
  setFromCheckout: React.Dispatch<React.SetStateAction<boolean>>;
  getSsoToken: () => void;
  hasPermissionGeneral: boolean;
}

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider: React.FC = ({ children }) => {
  const isAndroidAPP = mobileAppAndroidURL.some((url) =>
    window?.location?.host?.includes?.(url)
  );
  const isIOSAPP = mobileAppIOSURL.some((url) =>
    window?.location?.host?.includes?.(url)
  );

  const showIRPFAPPBanner = !isAndroidAPP && !isIOSAPP && isMobile();

  const [resgisterForm] = Form.useForm();
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const [decUrl, setDecUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [directPayment, setDirectPayment] = useState(false);
  const [chatOpen, setChatOpen] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [showDbkModal, setShowDbkModal] = useState(false);
  const [b3Authorized, setB3Authorized] = useState(false);
  const [fromCheckout, setFromCheckout] = useState(false);
  const [showNameModal, setShowNameModal] = useState(false);
  const [loadingSession, setLoadingSession] = useState(true);
  const [wasMessageSent, setWasMessageSent] = useState(false);
  const [showPhoneModal, setShowPhoneModal] = useState(false);
  const [user, setUser] = useState<IAuthState>({} as IAuthState);
  const [showIndicarModal, setShowIndicarModal] = useState(false);
  const [chatNotificationQtt, setChatNotificationQtt] = useState(0);
  const [userPlanModal, showUserPlanModal] = useState<boolean>(false);
  const [forceUpdateTabNavigator, setForceUpdateTabNavigator] = useState(false);
  const [cameFromRegister, setCameFromRegister] = useState<CameFromRegister>({
    bolsa: false,
    phone: false,
  });

  const hasPlan = useMemo(
    () => planInfoToHasPlan(user?.user?.userPlanInfoVelotax),
    [user?.user?.userPlanInfoVelotax]
  );

  const hasPermissionGeneral =
    user?.user?.userPlanInfoVelotax?.permissions?.["xpinvestimentos"] &&
    new Date(user?.user?.userPlanInfoVelotax?.dueDate) > new Date();

  const disabledRecaptcha = true;

  const handleCalcs = async ({ calcs, navigateTo }: HandleCalcsParamas) => {
    try {
      setLoading(true);
      await api.put("/configuracoes/calcs", { calcs });
      setUser((user) => ({
        ...user,
        user: { ...user.user, visibleCalcs: calcs },
      }));
      setLoading(false);
      navigate(navigateTo ?? "/");
    } catch (err) {
      setLoading(false);
      throw Error;
    }
  };

  const getUserInformation = async () => {
    const token = localStorage.getItem("@VeloTax:token");
    const user = localStorage.getItem("@VeloTax:user");
    if (user && token && !pathname.includes("/p/sso")) {
      api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      apiIrpf2023.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      apis.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      meiApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      msDarf.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      apiBolsa.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      apiCrypto.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      apiPayment.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      apiVeloPro.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      apiExterior.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      imoveisBackend.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
      backnedRendimentos.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;

      const response = await api.get("/session/userInfo");
      if (response.data.isBlocked) {
        message.warning(
          "Sua conta foi temporariamente bloqueada, favor entrar em contato com o suporte.",
          5000
        );
        await signOut();
      }
      const retPlanInfo = await apiPayment.get("/user-plan/plan-info");

      const meiPlanInfo = await apiPayment.get(`/user-plan/plan-info`, {
        headers: {
          "x-api-provider": "mei",
        },
      });

      let userFromApi = null;
      if (response.data) {
        const {
          cpf,
          cpfProtegido,
          name,
          email,
          birthdate,
          cellphone,
          profileId,
          dependents,
          visibleCalcs,
          visibleModules,
          modulesStatus,
          answeredStepDec,
          showContratarMalhaFina,
          userId,
          showAccountantPremiumButton,
          showContratarContador,
          cellphoneFilled,
          skippedB3Integration,
          hasDismissed,
          isAd,
          isBlocked,
        } = response.data;
        userFromApi = {
          cpf,
          cpfProtegido,
          name,
          email,
          birthdate,
          cellphone,
          profileId,
          dependents,
          visibleCalcs,
          visibleModules,
          modulesStatus,
          answeredStepDec,
          showContratarContador,
          showContratarMalhaFina,
          isAccountant: cpf.length > 11,
          userId,
          showAccountantPremiumButton,
          userPlanInfoVelotax: retPlanInfo.data,
          meiPlanInfoVelotax: meiPlanInfo.data,
          skippedB3Integration,
          cellphoneFilled,
          hasDismissed,
          isAd,
          isBlocked,
        };
      }
      apiIrpf2023.defaults.headers.common["Authorization"] = `Bearer ${
        response.headers.token || token
      }`;
      api.defaults.headers.common["Authorization"] = `Bearer ${
        response.headers.token || token
      }`;
      apis.defaults.headers.common["Authorization"] = `Bearer ${
        response.headers.token || token
      }`;
      meiApi.defaults.headers.common["Authorization"] = `Bearer ${
        response.headers.token || token
      }`;
      msDarf.defaults.headers.common["Authorization"] = `Bearer ${
        response.headers.token || token
      }`;
      apiBolsa.defaults.headers.common["Authorization"] = `Bearer ${
        response.headers.token || token
      }`;
      apiCrypto.defaults.headers.common["Authorization"] = `Bearer ${
        response.headers.token || token
      }`;
      apiPayment.defaults.headers.common["Authorization"] = `Bearer ${
        response.headers.token || token
      }`;
      apiVeloPro.defaults.headers.common["Authorization"] = `Bearer ${
        response.headers.token || token
      }`;
      apiExterior.defaults.headers.common["Authorization"] = `Bearer ${
        response.headers.token || token
      }`;
      imoveisBackend.defaults.headers.common["Authorization"] = `Bearer ${
        response.headers.token || token
      }`;
      backnedRendimentos.defaults.headers.common["Authorization"] = `Bearer ${
        response.headers.token || token
      }`;

      return {
        token: response.headers.token || token,
        user: userFromApi || JSON.parse(user),
      };
    }
    return {} as IAuthState;
  };

  const getUserInfo = useCallback(
    (callback?: () => void) => {
      setLoadingSession(true);
      getUserInformation()
        .then((res) => {
          setUser(res);
          setLoadingSession(false);
          callback && callback();
        })
        .catch((err) => {
          console.log("err", err);
          setLoadingSession(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname]
  );

  const updateUserInfo = useCallback(() => {
    getUserInformation().then((res) => {
      setUser(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const updateUserDec = useCallback(() => {
    const updatedUser = { ...user.user, answeredStepDec: true };
    const token = localStorage.getItem("@VeloTax:token") || "";

    setUser({ user: updatedUser, token });
  }, [user]);

  const signOut = useCallback(() => {
    setLoadingSession(true);
    localStorage.removeItem("@VeloTax:token");
    localStorage.removeItem("@VeloTax:user");
    api.defaults.headers.common["Authorization"] = "";
    apiIrpf2023.defaults.headers.common["Authorization"] = "";
    apis.defaults.headers.common["Authorization"] = "";
    meiApi.defaults.headers.common["Authorization"] = "";
    msDarf.defaults.headers.common["Authorization"] = "";
    apiBolsa.defaults.headers.common["Authorization"] = "";
    apiCrypto.defaults.headers.common["Authorization"] = "";
    apiVeloPro.defaults.headers.common["Authorization"] = "";
    apiPayment.defaults.headers.common["Authorization"] = "";
    apiExterior.defaults.headers.common["Authorization"] = "";
    imoveisBackend.defaults.headers.common["Authorization"] = "";
    backnedRendimentos.defaults.headers.common["Authorization"] = "";
    setUser({} as IAuthState);
    navigate("/p/login");
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }, [navigate]);

  useEffect(() => {
    apiExterior.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 401) {
          setTimeout(() => {
            signOut();
          }, 3000);
        }
        return Promise.reject(error);
      }
    );
    apiVeloPro.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 401) {
          setTimeout(() => {
            signOut();
          }, 3000);
        }
        return Promise.reject(error);
      }
    );
    api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 401) {
          signOut();
        }

        return Promise.reject(error);
      }
    );

    getUserInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setLocalStorageWithAuthData = useCallback(
    ({
      token,
      cpf,
      cpfProtegido,
      email,
      name,
      birthdate,
      cellphone,
      profileId,
      dependents,
      visibleCalcs,
      visibleModules,
      modulesStatus,
      answeredStepDec,
      showContratarMalhaFina,
      userId,
      showAccountantPremiumButton,
      showContratarContador,
      userPlanInfoVelotax,
      cellphoneFilled,
      skippedB3Integration,
      isAd,
    }: ISessionData) => {
      localStorage.setItem("@VeloTax:token", token);
      localStorage.setItem(
        "@VeloTax:user",
        JSON.stringify({
          cpf,
          email,
          name,
          profileId,
          mostrouRecomendacao: false,
        })
      );
      apiIrpf2023.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      apis.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      meiApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      msDarf.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      apiBolsa.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      apiCrypto.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      apiPayment.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      apiVeloPro.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      apiExterior.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      imoveisBackend.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
      backnedRendimentos.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;

      setUser({
        token,
        user: {
          cpfProtegido,
          cpf,
          email,
          name,
          birthdate,
          cellphone,
          profileId,
          dependents,
          visibleCalcs,
          visibleModules,
          modulesStatus,
          answeredStepDec,
          showContratarMalhaFina,
          isAccountant: cpf.length > 11,
          userId,
          showAccountantPremiumButton,
          showContratarContador,
          userPlanInfoVelotax,
          skippedB3Integration,
          cellphoneFilled,
          isAd,
        },
      });
      if (search === "?redirect=true") {
        navigate("/conta");
      } else {
        navigate("/");
      }
    },
    // eslint-disable-next-line
    []
  );

  const authAccountantSSOCnpj = useCallback(async () => {
    if (!user.user.cpf.includes("_")) return;

    const { data } = await api.post("/session/accountant-sso-cnpj", {
      cnpj: user.user.cpf.includes("_")
        ? user.user.cpf.split("_")[1]
        : user.user.cpf,
    });

    navigate(data.url);
  }, [user, navigate]);

  const handleSignIn = useCallback(
    async (data: ILoginData) => {
      setLoading(true);
      try {
        const response: any = await api.post("/session", {
          cpf: data.cpf,
          pass: data.pass,
          from: "CALCULADORAV2",
          isAd: data.isAd || false,
        });
        if (response.data.isBlocked) {
          message.warning(
            "Sua conta foi temporariamente bloqueada, favor entrar em contato com o suporte.",
            5000
          );
          await signOut();
        }
        const {
          token,
          cpf,
          cpfProtegido,
          email,
          name,
          birthdate,
          cellphone,
          profileId,
          dependents,
          visibleCalcs,
          visibleModules,
          modulesStatus,
          answeredStepDec,
          showContratarMalhaFina,
          userId,
          showAccountantPremiumButton,
          showContratarContador,
          cellphoneFilled,
          skippedB3Integration,
        } = response.data;

        api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        apiIrpf2023.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${token}`;
        apis.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        meiApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        msDarf.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        apiBolsa.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        apiCrypto.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        apiPayment.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        apiVeloPro.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        apiExterior.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${token}`;
        imoveisBackend.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${token}`;
        backnedRendimentos.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${token}`;

        const retPlanInfo = await apiPayment.get(`/user-plan/plan-info`);

        const meiPlanInfo = await apiPayment.get(`user-plan/plan-info`, {
          headers: {
            "x-api-provider": "mei",
          },
        });

        setLoadingSession(true);
        setLocalStorageWithAuthData({
          token,
          cpf,
          cpfProtegido,
          email,
          name,
          birthdate,
          cellphone,
          profileId,
          dependents,
          visibleCalcs,
          visibleModules,
          modulesStatus,
          answeredStepDec,
          showContratarContador,
          showContratarMalhaFina,
          userId,
          showAccountantPremiumButton,
          userPlanInfoVelotax: retPlanInfo.data,
          meiPlanInfoVelotax: meiPlanInfo.data,
          cellphoneFilled,
          skippedB3Integration,
        });
        setLoading(false);
        setTimeout(() => {
          setLoadingSession(false);
        }, 500);
      } catch (err: any) {
        const errorMessage =
          err.response?.data?.message ||
          "CPF e/ou senha incorretos. Tente novamente";
        message.error(errorMessage);
        setLoading(false);
        throw Error;
      }
    },
    [setLocalStorageWithAuthData, signOut]
  );

  const handleSignUp = useCallback(
    (signUpData, onboardingData, hiringData = null) => {
      setLoading(true);
      const { name, cpf, email, pass, isAd, policiesAccepted } = signUpData;

      const modules = [
        ...(onboardingData.goods || []),
        ...(onboardingData.incomeTypes || []),
        ...(onboardingData.operationTypes || []),
      ];

      const user: { [key: string]: any } = {
        name,
        cpf,
        email,
        pass,
        cellphone: "",
        policiesAccepted,
        from: "CALCULADORAV2",
        dependents: onboardingData.dependents || [],
        modules: modules,
        isMobile: isMobile(),
        isAd,
      };

      const formData = hiringData ? (hiringData as FormData) : new FormData();

      for (const key in user) {
        formData.set(
          key,
          key === "modules" || key === "dependents"
            ? JSON.stringify(user[key])
            : user[key]
        );
      }

      api
        .post("/user", formData)
        .then((response) => {
          const { token, cpf, email, name, profileId } = response.data;

          localStorage.removeItem("publiconboarding");
          localStorage.setItem("@VeloTax:token", token);
          localStorage.setItem(
            "@VeloTax:user",
            JSON.stringify({
              cpf,
              email,
              name,
              profileId,
              mostrouRecomendacao: false,
            })
          );
          apiIrpf2023.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${token}`;
          api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          apis.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          meiApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          msDarf.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          apiBolsa.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          apiCrypto.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${token}`;
          apiVeloPro.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${token}`;
          apiPayment.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${token}`;
          apiExterior.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${token}`;
          imoveisBackend.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${token}`;
          backnedRendimentos.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${token}`;

          setCameFromRegister({ bolsa: true, phone: true });
          getUserInfo(() => {
            navigate("/");
            setLoading(false);
          });
        })
        .catch((err) => {
          message.error(
            err.response?.data?.message ||
              "Algo deu errado, tente novemente mais tarde"
          );
          if (
            err.response?.data?.message?.includes("Você já possui uma conta")
          ) {
            setTimeout(() => {
              navigate("/p/login");
              resgisterForm.resetFields();
            }, 1000);
          }
          setLoading(false);
        });
    },
    [navigate, getUserInfo, resgisterForm]
  );

  const irpfBannerActive = false;
  const [ssoToken, setSsoToken] = useState("");

  const getSsoToken = useCallback(async () => {
    const cpf = user.user.cpf;
    try {
      const { data } = await api.post("/user/sso-token", {
        cpf,
        from: "CALCULADORAV2",
      });
      setSsoToken(data?.token || "");
    } catch {}
  }, [user?.user?.cpf]);

  useEffect(() => {
    if (/*irpfBannerActive &&*/ user?.user?.cpf) {
      getSsoToken();
    }
  }, [getSsoToken, user?.user?.cpf]);

  const initIOSPlanCheck = useCallback(() => {
    setInterval(() => {
      updateUserInfo();
      if (hasPlan) {
        window.location.reload();
      }
    }, 3000);
  }, [updateUserInfo, hasPlan]);

  return (
    <AuthContext.Provider
      value={{
        isIOSAPP,
        isAndroidAPP,
        hasPlan,
        handleSignUp,
        handleSignIn,
        signOut,
        signed: !!Object.keys(user).length,
        user,
        loading,
        loadingSession,
        setLoadingSession,
        getUserInfo,
        setLocalStorageWithAuthData,
        updateUserDec,
        authAccountantSSOCnpj,
        decUrl,
        setDecUrl,
        showDbkModal,
        setShowDbkModal,
        showIndicarModal,
        setShowIndicarModal,
        wasMessageSent,
        setWasMessageSent,
        userPlanModal,
        b3Authorized,
        setB3Authorized,
        showUserPlanModal: (show, title) => {
          /*
          const isIos = handleInAppPurchaseIfIsIos();
          if (isIos) {
            return;
          }
          */
          showUserPlanModal(show);
          navigate("/planos");
        },
        handleCalcs,
        setUser,
        ssoToken,
        irpfBannerActive,
        forceUpdateTabNavigator,
        setForceUpdateTabNavigator,
        disabledRecaptcha,
        cameFromRegister,
        setCameFromRegister,
        chatOpen,
        setChatOpen,
        showDrawer,
        setShowDrawer,
        showNameModal,
        setShowNameModal,
        chatNotificationQtt,
        setChatNotificationQtt,
        resgisterForm,
        updateUserInfo,
        showPhoneModal,
        setShowPhoneModal,
        showIRPFAPPBanner,
        hasExpired: user?.user?.userPlanInfoVelotax?.hasExpired,
        hasDismissed: !!user?.user?.hasDismissed,
        directPayment,
        setDirectPayment,
        initIOSPlanCheck,
        getSsoToken,
        fromCheckout,
        setFromCheckout,
        hasPermissionGeneral,
      }}
    >
      {showIRPFAPPBanner && <BannerApp />}
      {children}
      {!isMobile() ? (
        <div className="footer-secure">
          <img src={sslSecure} alt="ssl secure" />
        </div>
      ) : null}
    </AuthContext.Provider>
  );
};

export const useAuth = (): IAuthContext => useContext(AuthContext);
