import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { List, Typography, Collapse } from "antd";
import { Container, Content } from "./styles";
import { useBroker } from "../../contexts/BrokerContext";
import { DrawerModal } from "../../components/DrawerModal";
import { CompaniesExemptList } from "../../constants/regulation";

interface RegulationProps {}

export const Regulation: React.FC<RegulationProps> = () => {
  const navigate = useNavigate();
  const { currentBroker } = useBroker();
  const [showListModal, setShowListModal] = useState(false);

  const handleListModal = () => {
    setShowListModal((show) => !show);
  };
  return (
    <Container>
      <Content>
        <Typography.Title level={1} className="page-title">
          Regras de IR utilizadas pelo Velotax
        </Typography.Title>
        <p>
          Entenda quais são as normativas da Receita Federal que utilizamos para
          calcular automaticamente o seu imposto de renda
        </p>
        <Collapse accordion>
          <Collapse.Panel header="Bolsa de valores" key="bolsa">
            <Typography.Title level={3}>
              Entendendo o que é renda variável
            </Typography.Title>
            <Typography.Paragraph>
              O mercado de renda variável compreende ativos cuja remuneração ou
              retorno de capital não pode ser dimensionado no momento da
              aplicação. São eles as ações, quotas ou quinhões de capital, o
              ouro, ativo financeiro, e os contratos negociados nas bolsas de
              valores, de mercadorias, de futuros e assemelhadas.
            </Typography.Paragraph>
            <Typography.Text strong italic>
              (Instrução Normativa RFB nº 1.585, de 31 de agosto de 2015, arts.
              56, 58 e 60 a 62)
            </Typography.Text>
            <Typography.Title level={3}>
              Qual imposto de renda incide sobre operações de renda variável
            </Typography.Title>
            <Typography.Paragraph>
              O ganhos líquidos são constituídos pela diferença positiva entre o
              valor de venda do ativo e o seu custo de aquisição. Os ganhos
              líquidos auferidos em operações realizadas em bolsas de valores,
              de mercadorias, de futuros, e assemelhadas, inclusive day trade,
              serão tributados às seguintes alíquotas:
            </Typography.Paragraph>
            <List>
              <List.Item>
                a) 15%, nas operações realizadas nos mercados à vista, a termo,
                de opções e de futuros;
              </List.Item>
              <List.Item>b) 20%, no caso de operação day trade;</List.Item>
              <List.Item>
                c) 20%, no caso de operação com fundos imobiliários (FIIs).
              </List.Item>
            </List>
            <Typography.Text strong italic>
              (Lei nº 11.033, de 21 de dezembro de 2004, art. 2º, caput, incisos
              I e II, e §§ 1º e 2º; e Instrução Normativa RFB nº 1.585, de 31 de
              agosto de 2015, arts. 57, 63 e 65)
            </Typography.Text>
            <Typography.Title level={3}>
              Quando o imposto deve ser pago
            </Typography.Title>
            <Typography.Paragraph>
              O imposto sobre a renda deve ser pago até o último dia útil do mês
              subsequente àquele em que os ganhos houverem sido apurados.
              <br />
              <br />O código a ser utilizado no Documento de Arrecadação das
              Receitas Federais (DARF) para pagamento desse tributo é 6015.
            </Typography.Paragraph>
            <Typography.Text strong italic>
              (Instrução Normativa RFB nº 1.585, de 31 de agosto de 2015, art.
              56, § 5º)
            </Typography.Text>
            <Typography.Title level={3}>
              Operações isentas de imposto de renda
            </Typography.Title>
            <Typography.Paragraph>
              A isenção de imposto de renda aplica-se para transações realizadas
              em mercados comuns e que se encaixem nas situações abaixo:
            </Typography.Paragraph>
            <List>
              <List.Item>
                a) com ações, no mercado à vista de bolsas de valores ou mercado
                de balcão, se o total das alienações desse ativo, realizadas no
                mês, não exceder a R$ 20.000,00 (vinte mil reais);
              </List.Item>
              <List.Item>
                b) com ações de pequenas e médias empresas a que se refere o
                art. 16 da Lei nº 13.043, de 13 de novembro de 2014;
                <br />
                <Typography.Link onClick={handleListModal}>
                  Clique aqui para ver a lista de empresas isentas
                </Typography.Link>
              </List.Item>
              <List.Item>
                c) com ouro, ativo financeiro, se o total das alienações desse
                ativo, realizadas no mês, não exceder a R$ 20.000,00 (vinte mil
                reais);
              </List.Item>
            </List>
            <Typography.Paragraph>
              A isenção acima não se aplica, entre outras, às operações de day
              trade, às negociações de cotas de fundos de investimentos
              imobiliários (FIIs) e fundos de investimento em índice de ações,
              aos resgates de cotas de fundos ou clubes de investimento em ações
              e à alienação de ações efetivada em operações de exercício de
              opções e no vencimento ou liquidação antecipada de contratos a
              termo.
            </Typography.Paragraph>
            <Typography.Text strong italic>
              (Lei nº 11.033, de 21 de dezembro de 2004, art.3º, inciso I; Lei
              nº 13.043, de 13 de novembro de 2014, art. 16; e Instrução
              Normativa RFB nº 1.585 , de 31 de agosto de 2015, art. 59, incisos
              I e II e § 2º)
            </Typography.Text>
            <Typography.Title level={3}>
              O que é operação day trade
            </Typography.Title>
            <Typography.Paragraph>
              Considera-se day trade a operação ou a conjugação de operações
              iniciadas e encerradas em um mesmo dia, com <u>o mesmo ativo,</u>{" "}
              <u>em uma mesma instituição intermediadora (isto é, corretora)</u>
              , em que a quantidade negociada tenha sido liquidada, total ou
              parcialmente. Na apuração do resultado da operação day trade são
              considerados, pela ordem, o primeiro negócio de compra com o
              primeiro de venda ou o primeiro negócio de venda com o primeiro de
              compra, sucessivamente.
            </Typography.Paragraph>
            <Typography.Text strong italic>
              (Lei nº 9.959, de 27 de janeiro de 2000, art. 8º; Lei nº 12.350,
              de 20 de dezembro de 2010, art. 45; e Instrução Normativa RFB nº
              1.585, de 31 de agosto de 2015, art. 65)
            </Typography.Text>
            <Typography.Title level={3}>
              Compensação de perdas passadas
            </Typography.Title>
            <Typography.Paragraph>
              As perdas incorridas nas operações de renda variável nos mercados
              à vista, de opções, futuros, a termo e assemelhados, poderão ser
              compensadas com os ganhos líquidos auferidos, no próprio mês ou
              nos meses subsequentes, em outras operações realizadas em qualquer
              das modalidades operacionais previstas nesses mercados.
            </Typography.Paragraph>
            <Typography.Paragraph>
              <strong>Atenção:</strong> as perdas incorridas em operações
              comuns, somente poderão ser compensadas com ganhos líquidos
              auferidos em operações da mesma espécie (comuns), realizadas no
              mês ou meses subsequentes. Do mesmo modo, as perdas incorridas em
              operações day trade somente são compensáveis com os ganhos
              líquidos auferidos nessas operações, e perdas incorridas em FIIs
              são compensáveis com os ganhos líquidos auferidos em FIIs.
            </Typography.Paragraph>
            <Typography.Text strong italic>
              (Regulamento do Imposto sobre a Renda – RIR/2018, art. 841, §§ 1º
              e 2º, aprovado pelo Decreto nº 9.580, de 22 de novembro de 2018; e
              Instrução Normativa RFB nº 1.585, de 31 de agosto de 2015, art.
              64)
            </Typography.Text>
            <Typography.Title level={3}>
              Dedução de despesas operacionais
            </Typography.Title>
            <Typography.Paragraph>
              As despesas efetivamente pagas destacadas na nota de corretagem ou
              no extrato da conta-corrente para a realização de operações de
              compra ou venda (corretagens, emolumentos etc.) podem ser
              consideradas na apuração do ganho líquido, sendo acrescidas ao
              preço de compra e deduzidas do preço de venda dos ativos ou
              contratos negociados.
            </Typography.Paragraph>
            <Typography.Text strong italic>
              (Lei nº 8.383, de 30 de dezembro de 1991, art. 27; e Regulamento
              do Imposto sobre a Renda - RIR/2018, art. 841, caput e § 2º,
              aprovado pelo Decreto nº 9.580, de 22 de novembro de 2018; e
              Instrução Normativa RFB nº 1.585, de 31 de agosto de 2015, art.
              56, § 3º)
            </Typography.Text>
            <Typography.Title level={3}>
              O que é o Portal da B3
            </Typography.Title>
            <Typography.Paragraph>
              O Portal da B3 armazena os saldos e os históricos de transação de
              forma consolidada para os investidores da bolsa de valores.
              <br />
              <br />
              Caso você nunca tenha se cadastrado no Portal da B3, faça o seu{" "}
              <Typography.Link
                target="_blank"
                href="https://www.investidor.b3.com.br/"
              >
                cadastro aqui
              </Typography.Link>
              . Só precisa ter o seu CPF e a data de nascimento em mãos.
            </Typography.Paragraph>
            {/*Remover os vídeos do passo a posso pois são baseados na calculadora antiga*/}
            {/* <Typography.Title level={3}>
          Ajustando manualmente o Velotax
        </Typography.Title>
        <Typography.Paragraph>
          Apesar do sistema do Velotax ser o mais automático possível, algumas
          informações não estão disponíveis na B3. Portanto os investidores
          devem ter atenção para ajustar manualmente na{" "}
          <Link to={`/${currentBroker.path}/darf`}>Calculadora</Link> os pontos
          abaixo.
        </Typography.Paragraph> */}
            {/* <Typography.Paragraph>
          <Collapse ghost>
            <Panel header="Onde encontrar suas notas corretagem" key="1">
              <iframe
                src="https://www.youtube.com/embed/hPbto5NvL6M"
                title="Onde encontrar suas notas corretagem"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Panel>
            <Panel header="Ajustes de custo médio" key="2">
              <iframe
                src="https://www.youtube.com/embed/ABLC__0cFys"
                title="Ajustes de custo médio"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Panel>
            <Panel header="Ajustes de prejuízos acumulados" key="3">
              <iframe
                src="https://www.youtube.com/embed/rVxHUk6S2y8"
                frameBorder="0"
                title="Ajustes de prejuízos acumulados"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Panel>
            <Panel header="Ajustes de desdobramentos e agrupamentos" key="4">
              <iframe
                src="https://www.youtube.com/embed/0yQaV0Wds2k"
                frameBorder="0"
                title="Ajustes de desdobramentos e agrupamentos"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Panel>
            <Panel header="Ajustes de operações vendidas e opções" key="5">
              <iframe
                src="https://www.youtube.com/embed/nMG4DDExw88"
                frameBorder="0"
                title="Ajustes de operações vendidas e opções"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Panel>
            <Panel header="Ajustes de operações de day trade" key="6">
              <iframe
                src="https://www.youtube.com/embed/d2Z7dTs4YTk"
                frameBorder="0"
                title="Ajustes de operações de day trade"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Panel>
            <Panel header="Ajustes de amortizações de FII" key="7">
              <iframe
                src="https://www.youtube.com/embed/s5ffyFXYZ9E"
                frameBorder="0"
                title="Ajustes de amortizações de FII"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Panel>
            <Panel header="Ajustes para IPOs, follow-ons e subscrição" key="8">
              <iframe
                src="https://www.youtube.com/embed/iXTH8XYlduQ"
                frameBorder="0"
                title="Ajustes para IPOs, follow-ons e subscrição"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Panel>
          </Collapse>
        </Typography.Paragraph> */}
            <Typography.Title level={3}>
              Ainda tem dúvidas? Acesse:
            </Typography.Title>
            <Typography.Link
              target="_blank"
              href="https://velotax.zendesk.com/hc/pt-br"
            >
              Central de Ajuda
            </Typography.Link>
            , onde nosso time de suporte responde suas dúvidas
          </Collapse.Panel>

          <Collapse.Panel header="Criptoativos" key="cripto">
            <Typography.Title level={3}>
              Entendendo o que são criptoativos
            </Typography.Title>
            <Typography.Paragraph>
              Os criptoativos são a representação digital de valor denominada em
              sua própria unidade de conta, cujo preço pode ser expresso em
              moeda soberana local ou estrangeira, transacionado eletronicamente
              com a utilização de criptografia e de tecnologias de registros
              distribuídos, que pode ser utilizado como forma de investimento,
              instrumento de transferência de valores ou acesso a serviços, e
              que não constitui moeda de curso legal.
            </Typography.Paragraph>
            <Typography.Paragraph>
              Alguns exemplos de criptoativos são Bitcoins, Altcoins (Ether,
              Ripple, Litecoin, entre outros) e Payment Tokens (Chiliz, Binance
              Coin, Chainlink, entre outros). Eles podem ser equiparados a
              ativos financeiros sujeitos a ganho de capital e devem ser
              declarados pelo seu valor de aquisição.
            </Typography.Paragraph>
            <Typography.Text strong italic>
              (Instrução Normativa RFB nº 1.888, de 3 de maio de 2019)
            </Typography.Text>
            <Typography.Title level={3}>
              Qual imposto de renda incide sobre ganhos com criptoativos
            </Typography.Title>
            <Typography.Paragraph>
              Os ganhos obtidos com a alienação de criptoativos cujo total
              alienado no mês seja superior a R$ 35.000,00 são tributados, a
              título de ganho de capital, segundo alíquotas progressivas
              estabelecidas em função do lucro:
            </Typography.Paragraph>
            <List>
              <List.Item>
                a) 15% sobre a parcela dos ganhos que não ultrapassar R$
                5.000.000,00;
              </List.Item>
              <List.Item>
                b) 17,5% sobre a parcela dos ganhos que exceder R$ 5.000.000,00
                e não ultrapassar R$ 10.000.000,00;
              </List.Item>
              <List.Item>
                c) 20% sobre a parcela dos ganhos que exceder R$ 10.000.000,00 e
                não ultrapassar R$ 30.000.000,00; e
              </List.Item>
              <List.Item>
                d) 22,5% sobre a parcela dos ganhos que ultrapassar R$
                30.000.000,00.
              </List.Item>
            </List>
            <Typography.Text strong italic>
              (Lei nº 5.172, de 25 de outubro de 1966 - Código Tributário
              Nacional - CTN, art. 118; Lei nº 8.981, de 20 de janeiro de 1995,
              art. 21; Instrução Normativa SRF nº 84, de 11 de outubro de 2001;
              Instrução Normativa SRF nº 599, de 28 de dezembro de 2005; e Ato
              Declaratório Interpretativo RFB nº 3, de 27 de abril de 2016)
            </Typography.Text>
            <Typography.Title level={3}>
              Quando o imposto deve ser pago
            </Typography.Title>
            <Typography.Paragraph>
              O recolhimento do imposto sobre a renda deve ser feito até o
              último dia útil do mês seguinte ao da transação de venda.
            </Typography.Paragraph>
            <Typography.Paragraph>
              O código a ser utilizado no Documento de Arrecadação das Receitas
              Federais (DARF) para pagamento desse tributo é 4600.
            </Typography.Paragraph>
            <Typography.Text strong italic>
              (Lei nº 5.172, de 25 de outubro de 1966 - Código Tributário
              Nacional - CTN, art. 118; Lei nº 8.981, de 20 de janeiro de 1995,
              art. 21; Instrução Normativa SRF nº 84, de 11 de outubro de 2001;
              Instrução Normativa SRF nº 599, de 28 de dezembro de 2005; e Ato
              Declaratório Interpretativo RFB nº 3, de 27 de abril de 2016)
            </Typography.Text>
            <Typography.Title level={3}>
              Operações isentas de imposto de renda
            </Typography.Title>
            <Typography.Paragraph>
              A isenção relativa às alienações de até R$ 35.000,00 mensais deve
              observar o conjunto de criptoativos ou moedas virtuais alienados
              no Brasil ou no exterior, independente de seu nome (bitcoin,
              ethereum, litecoin, tether ...). Caso o total alienado no mês
              ultrapasse esse valor, o ganho de capital relativo a todas as
              alienações estará sujeito à tributação.
            </Typography.Paragraph>
            <Typography.Text strong italic>
              (Lei nº 5.172, de 25 de outubro de 1966 - Código Tributário
              Nacional - CTN, art. 118; Lei nº 8.981, de 20 de janeiro de 1995,
              art. 21; Instrução Normativa SRF nº 84, de 11 de outubro de 2001;
              Instrução Normativa SRF nº 599, de 28 de dezembro de 2005; e Ato
              Declaratório Interpretativo RFB nº 3, de 27 de abril de 2016)
            </Typography.Text>
            <Typography.Title level={3}>
              Como converter de dólares (US$) para reais (R$)
            </Typography.Title>
            <Typography.Paragraph>
              Para a conversão de valores em reais, o valor expresso em moeda
              estrangeira deve ser convertido em dólar dos Estados Unidos da
              América e convertido em moeda nacional pela cotação do dólar dos
              Estados Unidos da América fixada, para venda, pelo Banco Central
              do Brasil (BCB) para a data da operação ou saldo, extraída do
              boletim de fechamento PTAX divulgado pelo BCB.
            </Typography.Paragraph>
            <Typography.Text strong italic>
              (Instrução Normativa RFB nº 1.888, de 3 de maio de 2019)
            </Typography.Text>
            <Typography.Title level={3}>
              Compensação de perdas passadas
            </Typography.Title>
            <Typography.Paragraph>
              Como os ganhos em criptoativos estão sujeitos as regulações de
              ganho de capital, as perdas passadas em criptoativos não podem ser
              compensadas dos ganhos futuros. As operações em criptoativos são
              tratadas de maneira singular, não sendo possível compensar os
              prejuízos de algumas operações com lucros de outras.
            </Typography.Paragraph>
            <Typography.Paragraph>
              Mesmo para operações no mesmo mês, de forma conservadora, valem as
              disposições acima: informar cada operação de venda, sem compensar
              lucros com perdas.
            </Typography.Paragraph>
            <Typography.Text strong italic>
              (Instrução Normativa SRF nº 84, de 11 de outubro de 2001, art. 2º,
              parágrafo único)
            </Typography.Text>
            <Typography.Title level={3}>
              Dedução de despesas operacionais
            </Typography.Title>
            <Typography.Paragraph>
              As despesas com corretagem na venda de criptoativos podem ser
              abatidas do ganho de capital a ser tributado.
            </Typography.Paragraph>
            <Typography.Title level={3}>
              Ainda tem dúvidas? Acesse:
            </Typography.Title>
            -{" "}
            <Typography.Link
              target="_blank"
              href="https://velotax.zendesk.com/hc/pt-br"
            >
              Central de Ajuda
            </Typography.Link>
            , onde nosso time de suporte responde suas dúvidas
          </Collapse.Panel>

          <Collapse.Panel header="Exterior" key="exterior">
            <Typography.Title level={3}>
              Entendendo quais ativos no exterior estão sujeitos a tributação
            </Typography.Title>
            <Typography.Paragraph>
              A alienação de bens ou direitos e liquidação ou resgate de
              aplicações financeiras, de propriedade de pessoa física,
              adquiridos em moeda estrangeira estarão sujeitos as regras de
              tributação sobre ganho de capital.
            </Typography.Paragraph>
            <Typography.Paragraph>
              Caso você tenha realizado operações que importem na alienação
              (venda) de bens ou direitos adquiridos em moeda estrangeira, como
              ações e outros ativos financeiros em bolsa de valores, de
              mercadorias, de futuros ou assemelhadas, você deverá efetuar o
              cálculo de ganho de capital tributável e o recolhimento do imposto
              devido segundo as alíquotas progressivas estabelecidas em função
              do lucro.
            </Typography.Paragraph>
            <Typography.Text strong italic>
              (Lei nº 13.254, de 13 de janeiro de 2016; Lei nº 13.428, de 30 de
              março de 2017; Instrução Normativa SRF nº 118, de 27 de dezembro
              de 2000; Instrução Normativa SRF nº 84, de 11 de outubro de 2001,
              art. 3º; Instrução Normativa RFB nº 1.627, de 11 de março de 2016;
              e Instrução Normativa RFB nº 1.704, de 31 de março de 2017)
            </Typography.Text>
            <Typography.Title level={3}>
              Qual imposto de renda incide sobre ganhos na venda de ativos no
              exterior
            </Typography.Title>
            <Typography.Paragraph>
              Os ganhos obtidos com a alienação de investimentos no exterior
              cujo total alienado no mês seja superior a R$ 35.000,00 são
              tributados, a título de ganho de capital, segundo alíquotas
              progressivas estabelecidas em função do lucro:
            </Typography.Paragraph>
            <List>
              <List.Item>
                a) 15% sobre a parcela dos ganhos que não ultrapassar R$
                5.000.000,00;
              </List.Item>
              <List.Item>
                b) 17,5% sobre a parcela dos ganhos que exceder R$ 5.000.000,00
                e não ultrapassar R$ 10.000.000,00;
              </List.Item>
              <List.Item>
                c) 20% sobre a parcela dos ganhos que exceder R$ 10.000.000,00 e
                não ultrapassar R$ 30.000.000,00;
              </List.Item>
              <List.Item>
                d) 22,5% sobre a parcela dos ganhos que ultrapassar R$
                30.000.000,00.
              </List.Item>
            </List>
            <Typography.Text strong italic>
              (Lei nº 8.981, de 20 de janeiro de 1995, art. 21; Lei nº 13.259,
              de 16 de março de 2016; e Ato Declaratório Interpretativo RFB nº
              3, de 27 de abril de 2016)
            </Typography.Text>
            <Typography.Title level={3}>
              Quando o imposto deve ser pago
            </Typography.Title>
            <Typography.Paragraph>
              O recolhimento do imposto sobre a renda deve ser feito até o
              último dia útil do mês seguinte ao da transação de venda.
            </Typography.Paragraph>
            <Typography.Paragraph>
              O código a ser utilizado no Documento de Arrecadação das Receitas
              Federais (DARF) para pagamento desse tributo é 8523.
            </Typography.Paragraph>
            <Typography.Text strong italic>
              (Lei nº 5.172, de 25 de outubro de 1966 - Código Tributário
              Nacional - CTN, art. 118; Lei nº 8.981, de 20 de janeiro de 1995,
              art. 21; Instrução Normativa SRF nº 84, de 11 de outubro de 2001;
              Instrução Normativa SRF nº 599, de 28 de dezembro de 2005; e Ato
              Declaratório Interpretativo RFB nº 3, de 27 de abril de 2016)
            </Typography.Text>
            <Typography.Title level={3}>
              Operações isentas de imposto de renda
            </Typography.Title>
            <Typography.Paragraph>
              A isenção relativa às alienações de até R$ 35.000,00 mensais deve
              observar o conjunto de instrumentos financeiros negociados em
              bolsa de valores no exterior, como ETFs (Exchange Traded Funds),
              REITs (Real Estate Investment Trust), ADRs (American Depositary
              Receipt) e Stocks (ações). Caso o total alienado no mês ultrapasse
              esse valor, o ganho de capital relativo a todas as alienações
              estará sujeito à tributação.
            </Typography.Paragraph>
            <Typography.Text strong italic>
              (Lei nº 9.250, de 26 de dezembro de 1995, art. 22, I e II; Lei nº
              10.406, de 10 de janeiro de 2002, art. 1.725; Instrução Normativa
              SRF nº 599, de 28 de dezembro de 2005, art. 1º; Solução de
              Consulta Cosit nº 320, de 20 de junho de 2017; e Solução de
              Consulta Cosit nº 264, de 24 de junho de 2019)
            </Typography.Text>
            <Typography.Title level={3}>
              Como funciona o cálculo do imposto de renda no exterior
            </Typography.Title>
            <Typography.Paragraph>
              Na hipótese de bens ou direitos adquiridos e aplicações
              financeiras realizadas em moeda estrangeira com rendimentos
              auferidos originariamente em reais, o ganho de capital corresponde
              à diferença positiva, em reais, entre o valor de venda e o custo
              de aquisição da aplicação financeira.
            </Typography.Paragraph>
            <Typography.Paragraph>
              Neste caso, o valor de venda deve ser convertido para reais pela
              cotação do dólar fixada para compra, pelo Banco Central do Brasil,
              para a data do recebimento. Já o custo de aquisição deve ser
              convertido em reais pela cotação do dólar fixada para venda para a
              data do pagamento.
            </Typography.Paragraph>
            <Typography.Paragraph>
              Caso os rendimentos auferidos utilizados na aquisição sejam
              originariamente em moeda estrangeira, o ganho de capital
              corresponde à diferença positiva, em dólares dos Estados Unidos da
              América, entre o valor de venda e o custo de aquisição da
              aplicação, convertida em moeda nacional mediante a utilização da
              cotação do dólar fixada, para compra, pelo Banco Central do
              Brasil, para a data do recebimento
            </Typography.Paragraph>
            <Typography.Text strong italic>
              (Medida Provisória nº 2.158-35, de 24 de agosto de 2001, art. 24;
              Lei nº 8.981, de 20 de janeiro de 1995, art. 21; Instrução
              Normativa SRF nº 208, de 27 de setembro de 2002; Instrução
              Normativa SRF nº 118, de 27 de dezembro de 2000; Solução de
              Consulta Interna Cosit nº 5, de 15 de fevereiro de 2013; e Solução
              de Consulta Cosit nº 33, de 26 de fevereiro de 2015)
            </Typography.Text>
            <Typography.Title level={3}>
              Como converter de dólares (US$) para reais (R$)
            </Typography.Title>
            <Typography.Paragraph>
              Para a conversão de valores em reais, o valor expresso em moeda
              estrangeira deve ser convertido em dólar dos Estados Unidos da
              América e convertido em moeda nacional pela cotação do dólar dos
              Estados Unidos da América fixada, para compra ou venda, pelo Banco
              Central do Brasil (BCB) para a data da operação ou saldo, extraída
              do boletim de fechamento PTAX divulgado pelo BCB.
            </Typography.Paragraph>
            <Typography.Text strong italic>
              (Instrução Normativa RFB nº 1.888, de 3 de maio de 2019)
            </Typography.Text>
            <Typography.Title level={3}>
              Compensação de perdas passadas
            </Typography.Title>
            <Typography.Paragraph>
              Como os ganhos na venda de ativos no exterior estão sujeitos as
              regulações de ganho de capital, as perdas passadas em ativos no
              exterior não podem ser compensadas dos ganhos futuros. As
              operações com ativos no exterior são tratadas de maneira singular,
              não sendo possível compensar os prejuízos de algumas operações com
              lucros de outras.
            </Typography.Paragraph>
            <Typography.Paragraph>
              Mesmo para operações no mesmo mês, de forma conservadora, valem as
              disposições acima: informar cada operação de venda, sem compensar
              lucros com perdas.
            </Typography.Paragraph>
            <Typography.Text strong italic>
              (Instrução Normativa SRF nº 84, de 11 de outubro de 2001, art. 2º,
              parágrafo único)
            </Typography.Text>
            <Typography.Title level={3}>
              Dedução de despesas operacionais
            </Typography.Title>
            <Typography.Paragraph>
              As despesas com corretagem na venda de ativos no exterior podem
              ser abatidas do ganho de capital a ser tributado.
            </Typography.Paragraph>
            <Typography.Title level={3}>
              Ainda tem dúvidas? Acesse:
            </Typography.Title>
            -{" "}
            <Typography.Link
              target="_blank"
              href="https://velotax.zendesk.com/hc/pt-br"
            >
              Central de Ajuda
            </Typography.Link>
            , onde nosso time de suporte responde suas dúvidas
          </Collapse.Panel>

          <Collapse.Panel header="Carnê-leão" key="autonomos">
            <Typography.Title level={3}>
              Imposto de Renda sobre Rendimentos
            </Typography.Title>
            <Typography.Paragraph>
              Os rendimentos recebidos de outras pessoas físicas ou de fontes no
              exterior que ultrapassem o valor isento de R$2.259,21 por mês
              estarão sujeitos ao recolhimento mensal do imposto de renda.
            </Typography.Paragraph>
            <Typography.Paragraph>
              Nessa categoria, os principais rendimentos tributados incluem,
              entre outros:
            </Typography.Paragraph>
            <List style={{ marginLeft: "40px" }}>
              <Typography.Paragraph>
                a) Autônomos, incluindo trabalhos sem vínculo empregatício;
              </Typography.Paragraph>
              <Typography.Paragraph>
                b) Aluguéis, incluindo a locação e sublocação de bens móveis e
                imóveis;
              </Typography.Paragraph>
              <Typography.Paragraph>
                c) Pensão, incluindo pensão alimentícia e alimentos
                provisionais;
              </Typography.Paragraph>
              <Typography.Paragraph>
                d) Exterior, incluindo quaisquer valores recebidos de fontes do
                exterior.
              </Typography.Paragraph>
            </List>
            <Typography.Text strong italic>
              (Regulamento do Imposto sobre a Renda - RIR/2018, arts. 118 a 121
              e art. 123, aprovado pelo Decreto nº 9.580, de 22 de novembro de
              2018; Instrução Normativa RFB nº 1.500, de 29 de outubro de 2014,
              arts. 24, § 4º, 53 e 54; Instrução Normativa RFB nº 1.531, de 19
              de dezembro de 2014)
            </Typography.Text>
            <Typography.Title level={3}>Autônomos</Typography.Title>
            <Typography.Paragraph>
              Inclui os profissionais não-assalariados que recebem de outras
              pessoas físicas, e que possuem rendimentos advindos da prestação
              de serviços de representante comercial autônomo, intermediação na
              realização de negócios por conta de terceiros, prestação de
              serviços de transporte de passageiros, prestação de serviços de
              transporte de cargas, entre outros trabalhos sem vínculo
              empregatício.
            </Typography.Paragraph>
            <Typography.Title level={3}>Aluguéis</Typography.Title>
            <Typography.Paragraph>
              Inclui proprietários de imóveis que possuem rendimentos de
              aluguéis, considerando que:
            </Typography.Paragraph>
            <List style={{ marginLeft: "40px" }}>
              <Typography.Paragraph>
                (a) Apenas o valor líquido do aluguel é tributado. As despesas
                de IPTU, condomínio, cobrança/recebimento e aluguel no caso de
                imóvel sublocado podem ser descontadas do rendimento;
              </Typography.Paragraph>
              <Typography.Paragraph>
                (b) Se você tiver mais de um imóvel e receber mais de um aluguel
                por mês, será necessário considerar a quantia total dos aluguéis
                recebidos.
              </Typography.Paragraph>
            </List>
            <Typography.Text>
              A data do pagamento, para efeito de incidência do imposto, será
              considerada aquela em que o locatário pagar o aluguel ao
              proprietário do bem ou à administradora.
            </Typography.Text>
            <Typography.Title level={3}>Pensão Alimentícia</Typography.Title>
            <Typography.Paragraph>
              Inclui os alimentandos que recebem pensão alimentícia. A pensão
              alimentícia pode ser recebida não apenas pelos filhos, mas por
              qualquer pessoa que tenha recebido esse benefício por decisão
              judicial, acordo homologado judicialmente ou por escritura
              pública. O contribuinte é o beneficiário da pensão, ainda que
              tenha sido paga a seu representante legal.
            </Typography.Paragraph>
            <Typography.Title level={3}>Exterior</Typography.Title>
            <Typography.Paragraph>
              Inclui pessoas físicas com rendimentos ou quaisquer outros valores
              recebidos de fontes do exterior. Neste caso, é importante observar
              a possível existência de{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.gov.br/receitafederal/pt-br/acesso-a-informacao/legislacao/acordos-internacionais/acordos-para-evitar-a-dupla-tributacao/acordos-para-evitar-a-dupla-tributacao"
              >
                acordos, convenções e tratados internacionais
              </a>{" "}
              firmados entre o Brasil e o país de origem dos rendimentos para
              evitar a bitributação.
            </Typography.Paragraph>
            <Typography.Title level={3}>
              Qual imposto de renda incide
            </Typography.Title>
            <Typography.Paragraph>
              O imposto sobre rendimentos é calculado sobre o total recebido no
              mês, mediante a aplicação da seguinte tabela progressiva mensal:
            </Typography.Paragraph>
            <List style={{ marginLeft: "40px" }}>
              <Typography.Paragraph>
                a) Até R$ 2.259,21 não há impostos a pagar;
              </Typography.Paragraph>
              <Typography.Paragraph>
                b) De R$ 2.259,21 até R$ 2.826,65, a alíquota é de 7,5% sobre os
                rendimentos, com parcela a deduzir de R$ 142,80;
              </Typography.Paragraph>
              <Typography.Paragraph>
                c) De R$ 2.826,66 até R$ 3.751,05, a alíquota é de 15% sobre os
                rendimentos, com parcela a deduzir de R$ 354,80;
              </Typography.Paragraph>
              <Typography.Paragraph>
                d) De R$ 3.751,06 até R$ 4.664,68, a alíquota é de 22,5% sobre
                os rendimentos, com parcela a deduzir de R$ 636,13;
              </Typography.Paragraph>
              <Typography.Paragraph>
                e) Acima de R$ 4.664,68, a alíquota é de 27,5% sobre os
                rendimentos, com parcela a deduzir de R$ 869,36.
              </Typography.Paragraph>
            </List>
            <Typography.Text strong italic>
              (Lei nº 11.482, de 31 de maio de 2007, arts. 1º e 3º; Lei nº
              12.024, de 27 de agosto de 2009, art. 3º; Regulamento do Imposto
              sobre a Renda - RIR/2018, art. 121, aprovado pelo Decreto nº
              9.580, de 22 de novembro de 2018; Instrução Normativa RFB nº
              1.500, de 29 de outubro de 2014, arts. 53 a 57 e 65; Solução de
              Consulta Interna Cosit nº 3, de 8 de fevereiro de 2012; e Solução
              de Consulta Interna Cosit nº 6, de 25 de maio de 2015)
            </Typography.Text>
            <Typography.Title level={3}>
              Quando o imposto deve ser pago
            </Typography.Title>
            <Typography.Paragraph>
              O recolhimento do imposto de renda deve ser feito até o último dia
              útil do mês seguinte ao recebimento dos rendimentos.
            </Typography.Paragraph>
            <Typography.Paragraph>
              O código a ser utilizado no Documento de Arrecadação das Receitas
              Federais (DARF) para pagamento desse tributo é 0190.
            </Typography.Paragraph>
            <Typography.Text strong italic>
              (Lei nº 8.383, de 30 de dezembro de 1991, art. 7º; e Instrução
              Normativa RFB nº 1.500, de 29 de outubro de 2014, arts. 53 a 57 e
              67)
            </Typography.Text>
            {/* <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/GNf-E5NittE"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>*/}
            <Typography.Title level={3}>
              Deduções no imposto de renda
            </Typography.Title>
            <Typography.Paragraph>
              São admitidas as seguintes deduções na base de cálculo do imposto
              sobre rendimentos:
            </Typography.Paragraph>
            <List style={{ marginLeft: "40px" }}>
              <Typography.Paragraph>
                a) Contribuição previdenciária oficial, o INSS;
              </Typography.Paragraph>
              <Typography.Paragraph>
                b) Dependentes, sendo R$ 189,59 por dependente a partir do ano
                2021;
              </Typography.Paragraph>
              <Typography.Paragraph>
                c) Pensão alimentícia, quando paga em cumprimento de decisão
                judicial, inclusive os alimentos provisionais, de acordo
                homologado judicialmente ou de escritura pública; e
              </Typography.Paragraph>
              <Typography.Paragraph>
                d) Despesas relacionadas à atividade do profissional autônomo
              </Typography.Paragraph>
            </List>
            <Typography.Paragraph>
              No caso de autônomos, somente as seguintes categorias de despesas
              podem ser deduzidas da base tributária:
            </Typography.Paragraph>
            <List style={{ marginLeft: "40px" }}>
              <Typography.Paragraph>
                i) Despesas trabalhistas;
              </Typography.Paragraph>
              <Typography.Paragraph>
                ii) Serviços de terceiros;
              </Typography.Paragraph>
              <Typography.Paragraph>
                iii) Despesas com custeio e funcionamento;
              </Typography.Paragraph>
              <Typography.Paragraph>
                iv) Propaganda, assinatura de jornais e revistas;
              </Typography.Paragraph>
              <Typography.Paragraph>v) Roupas especiais;</Typography.Paragraph>
              <Typography.Paragraph>
                vi) Contribuições a sindicatos, associações ou conselhos;
              </Typography.Paragraph>
              <Typography.Paragraph>
                vii) Despesas com congressos e seminários;
              </Typography.Paragraph>
              <Typography.Paragraph>
                viii) Honorários profissionais;
              </Typography.Paragraph>
              <Typography.Paragraph>
                ix) Imóvel residencial ou profissional;
              </Typography.Paragraph>
              <Typography.Paragraph>
                x) Manutenção e conservação;
              </Typography.Paragraph>
              <Typography.Paragraph>
                xi) Correios e transporte;
              </Typography.Paragraph>
              <Typography.Paragraph>
                xii) Instalações, máquinas e equipamentos;
              </Typography.Paragraph>
              <Typography.Paragraph>
                xii) Despesas Comuns como rateio sob forma de Condomínio;
              </Typography.Paragraph>
              <Typography.Paragraph>
                xiv) Outras despesas necessárias à atividade específica e
                individual de cada contribuinte não contempladas anteriormente.
              </Typography.Paragraph>
            </List>
            <Typography.Text strong italic>
              (Lei nº 7.290, de 19 de dezembro de 1984; Lei nº 7.713, de 22 de
              dezembro de 1988, art. 9º; Regulamento do Imposto sobre a Renda -
              RIR/2018, arts. 39 e 158, § 1º, aprovado pelo Decreto nº 9.580, de
              22 de novembro de 2018; e Parecer Normativo CST nº 122, de 8 de
              junho de 1974)
            </Typography.Text>
            <Typography.Title level={3}>
              Utilizando o Velotax para IR em Rendimentos
            </Typography.Title>
            <Typography.Paragraph>
              A{" "}
              <span
                className="link"
                onClick={() => navigate(`/${currentBroker.path}/autonomos`)}
              >
                Calculadora
              </span>{" "}
              do Velotax para cálculo e pagamento de IR sobre rendimentos de
              pessoas físicas ou fontes no exterior permite a inclusão manual de
              operações, e realiza os cálculos automaticamente seguindo as
              regras da Receita Federal.
            </Typography.Paragraph>
            <Typography.Paragraph>
              Após inserir os seus rendimentos do mês, o Velotax calculará os
              seus impostos devidos de acordo com as regras acima e emitirá o
              seu DARF para pagamento.
            </Typography.Paragraph>
            <Typography.Paragraph>
              Caso você esteja pagando em atraso, o Velotax também incluirá
              automaticamente os juros e multa devidos no seu DARF.
            </Typography.Paragraph>
            <Typography.Title level={3}>
              Ainda tem dúvidas? Acesse:
            </Typography.Title>
            <Typography.Paragraph>
              -{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.velotax.com.br/forum"
              >
                Fórum
              </a>{" "}
              , onde nossa comunidade de usuários responde suas dúvidas
            </Typography.Paragraph>
          </Collapse.Panel>

          <Collapse.Panel header="Venda de Imóveis" key="imoveis">
            <Typography.Title level={3}>
              IMPOSTO DE RENDA NA VENDA IMÓVEIS
            </Typography.Title>
            <Typography.Paragraph>
              As operações onde ocorram a venda de imóveis estão sujeitas a
              tributação pelo Imposto de Renda, aplicando-se as regras do ganho
              de capital. O ganho de capital é o resultado positivo obtido entre
              o valor da venda e o custo de aquisição do imóvel.
            </Typography.Paragraph>
            <Typography.Text strong italic>
              (Regulamento do Imposto sobre a renda – RIR/2018, Lei nº 9580 de
              22 de Novembro de 2018 Lei nº 11.196 de 21 de Novembro de 2005;
              Instrução Normativa 1500 de 29 de Outubro de 2014)
            </Typography.Text>
            <Typography.Title level={3}>
              QUANDO O IMPOSTO DEVE SER PAGO
            </Typography.Title>
            <Typography.Paragraph>
              O imposto deve ser apurado no mês em que foi realizada a venda,
              devendo ser pago por meio de guia DARF no código de receita 4600.
            </Typography.Paragraph>
            <Typography.Paragraph>
              O contribuinte terá o prazo até o último dia útil do mês seguinte
              para fazer o pagamento do imposto.
            </Typography.Paragraph>
            <Typography.Text strong italic>
              (Instrução Normativa SRF nº 84, de 11 de outubro de 2001, art. 17
              e § 4º do art. 19; Solução de Consulta Cosit nº 60, de 20 de
              fevereiro de 2014)
            </Typography.Text>
            <Typography.Title level={3}>DEDUÇÃO DE CORRETAGEM</Typography.Title>
            <Typography.Paragraph>
              As despesas de corretagem na venda do imóvel podem ser deduzidas
              do valor da venda no momento da apuração do imposto.
            </Typography.Paragraph>
            <Typography.Text strong italic>
              (Instrução Normativa SRF nº 84, de 11 de outubro de 2001, art. 17
              e § 4º do art. 19; Solução de Consulta Cosit nº 60, de 20 de
              fevereiro de 2014; e Solução de Consulta Cosit nº 50, de 22 de
              junho de 2020)
            </Typography.Text>
            <Typography.Title level={3}>
              CUSTO DE AQUISIÇÃO DO IMÓVEL{" "}
            </Typography.Title>
            <Typography.Paragraph>
              O custo de aquisição a ser considerado na apuração do imposto será
              o valor de compra do imóvel descrito na escritura ou contrato. Os
              valores de benfeitorias realizadas no imóvel podem somadas ao
              custo de aquisição com a condição que o contribuinte tenha
              documentação idônea (notas fiscais) comprovando os valores gastos.
              Essa documentação poderá ser solicitada pela Receita Federal.
            </Typography.Paragraph>
            <Typography.Text strong italic>
              (Instrução Normativa SRF nº 84, de 11 de outubro de 2001, art. 17
              e § 4º do art. 19; Solução de Consulta Cosit nº 60, de 20 de
              fevereiro de 2014; e Solução de Consulta Cosit nº 50, de 22 de
              junho de 2020)
            </Typography.Text>
            <Typography.Title level={3}>ALÌQUOTAS DO IMPOSTO</Typography.Title>
            <Typography.Paragraph>
              O ganho de capital obtido na venda de imóvel terá incidência de
              alíquota progressiva, conforme o valor do lucro na operação:
            </Typography.Paragraph>
            <List>
              <List.Item>
                a) 15% sobre a parcela dos ganhos que não ultrapassar R$
                5.000.000,00;
              </List.Item>
              <List.Item>
                b) 17,5% sobre a parcela dos ganhos que exceder R$ 5.000.000,00
                e não ultrapassar R$ 10.000.000,00;{" "}
              </List.Item>
              <List.Item>
                c) 20% sobre a parcela dos ganhos que exceder R$ 10.000.000,00 e
                não ultrapassar R$ 30.000.000,00;
              </List.Item>
              <List.Item>
                d) 22,5% sobre a parcela dos ganhos que ultrapassar R$
                30.000.000,00.
              </List.Item>
            </List>
            <Typography.Text strong italic>
              (Lei nº 5.172, de 25 de outubro de 1966 - Código Tributário
              Nacional - CTN, art. 118; Lei nº 8.981, de 20 de janeiro de 1995,
              art. 21; Instrução Normativa SRF nº 84, de 11 de outubro de 2001;
              Instrução Normativa SRF nº 599, de 28 de dezembro de 2005; e Ato
              Declaratório Interpretativo RFB nº 3, de 27 de abril de 2016)
            </Typography.Text>
            <Typography.Title level={3}>ISENÇÕES </Typography.Title>
            <Typography.Paragraph>
              A legislação prevê condições nas quais podem haver a isenção do
              Imposto de Renda. São elas:
            </Typography.Paragraph>
            <List>
              <List.Item>
                a) Venda de imóvel adquirido até o ano 1969 é totalmente isenta
                do Imposto de Renda.
              </List.Item>
              <List.Item>
                b) Venda de Imóvel cujo o valor negociado não ultrapasse
                R$35.000,00 no mês de apuração será totalmente isenta.
              </List.Item>
              <List.Item>
                c) Venda de imóvel cujo o valor negociado seja até R$440.000,00
                desde que seja o único imóvel do contribuinte e ele não tenha
                usufruído desse benefício nos últimos 5 anos.
              </List.Item>
              <List.Item>
                d) Será isenta do Imposto de Renda a parcela da venda cujo o
                valor seja revertido na compra de outro imóvel ou na quitação de
                financiamento imobiliário no prazo de até 180 dias. Válido
                apenas para transações com imóveis residenciais.
              </List.Item>
            </List>
            <Typography.Text strong italic>
              (Regulamento do Imposto sobre a Renda – RIR/2018, art. 35, Lei nº
              9580 de 22 de Novembro de 2018. Art. 38 e 39 da Lei 11.196 de 21
              de Novembro de 2005).
            </Typography.Text>
          </Collapse.Panel>
        </Collapse>
      </Content>

      <DrawerModal
        footer={null}
        visible={showListModal}
        onCancel={handleListModal}
        title={CompaniesExemptList?.title}
        width={1000}
      >
        {CompaniesExemptList?.content}
      </DrawerModal>
    </Container>
  );
};
