import { Menu } from "antd";
import { BsBriefcase, BsChat } from "react-icons/bs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BiBuildingHouse, BiUserCircle } from "react-icons/bi";
import { Container } from "./styles";
import { isMobile } from "../../utils";
import { useAuth } from "../../contexts/AuthContext";
import { useBroker } from "../../contexts/BrokerContext";
import { useB3Integration } from "../../contexts/B3IntegrationContext";
import { ReactComponent as CriptoIcon } from "../../assets/calculadoras/criptomoedas.svg";
import { ReactComponent as BolsaIcon } from "../../assets/calculadoras/bolsa-de-valores.svg";
import { ReactComponent as ExteriorIcon } from "../../assets/calculadoras/ativos-no-exterior.svg";
import { ReactComponent as AutonomosIcon } from "../../assets/icons/sidebar/autonomos.svg";

export const TabNavigator = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { b3Authorized } = useB3Integration();
  const {
    user,
    chatOpen,
    chatNotificationQtt,
    setChatNotificationQtt,
    forceUpdateTabNavigator,
  } = useAuth();
  const { currentBroker, hasCriptoIntegration, hasExteriorIntegration } =
    useBroker();

  const [activeKey, setActiveKey] = useState<string>("");

  const menuItems = useMemo(
    () => [
      ...(user.user
        ? [
            {
              fixed: true,
              id: "carteira",
              key: "/carteira",
              label: "Carteira",
              icon: <BiUserCircle />,
            },
          ]
        : []),
      {
        id: "bolsa",
        key: "/velotax/bolsa",
        label: "Bolsa",
        icon: <BolsaIcon />,
      },
      {
        id: "cripto",
        key: "/velotax/cripto",
        label: "Cripto",
        icon: <CriptoIcon />,
      },
      {
        id: "exterior",
        key: "/velotax/exterior",
        label: "Exterior",
        icon: <ExteriorIcon />,
      },
      {
        id: "autonomos",
        key: "/velotax/autonomos-historico",
        label: "Carnê-Leão",
        icon: <AutonomosIcon className="stroke" />,
      },
      {
        id: "imoveis",
        key: "/velotax/imoveis",
        label: "Imóveis",
        icon: <BiBuildingHouse />,
      },
      ...(user.user && !isMobile()
        ? [
            {
              fixed: true,
              id: "chat",
              key: "chat",
              label: (
                <>
                  Chat
                  {chatNotificationQtt > 0 && (
                    <span className="zendesk-chat-notification-qtt app-sidebar">
                      {chatNotificationQtt}
                    </span>
                  )}
                </>
              ),
              icon: <BsChat />,
            },
          ]
        : []),
    ],
    [user.user, chatNotificationQtt]
  );

  const visibleMenuItems = useMemo(() => {
    if (user.user) {
      const fixedItems = menuItems.filter((item) => item.fixed);
      const [home, chat] = [fixedItems[0], fixedItems.slice(-1)[0]];
      const menuActiveItems = menuItems.filter((item) => !item.fixed);
      const itemActiveIndex =
        menuActiveItems.length > 3
          ? menuActiveItems.findIndex((item) => pathname.includes(item.key))
          : -1;
      return [
        home,
        ...(itemActiveIndex > 2
          ? [...menuActiveItems.slice(0, 3), menuActiveItems[itemActiveIndex]]
          : [...menuActiveItems.slice(0, 4)]),
        ...(!isMobile() ? [chat] : []),
      ];
    } else {
      return menuItems;
    }
  }, [menuItems, pathname, user.user]);

  const setActiveKeyByPathname = useCallback(() => {
    const matchItem = menuItems
      .filter((item) => item.key !== "/")
      .find(
        (item) =>
          (item.key.includes("cripto") && pathname.includes("crypto")) ||
          pathname.includes(item.key)
      );
    setActiveKey(matchItem?.key || "");
  }, [menuItems, pathname]);

  useEffect(() => {
    if (pathname === "/") {
      setActiveKey(pathname);
    } else if (chatOpen) {
      setActiveKey("chat");
    } else {
      setActiveKeyByPathname();
    }
  }, [pathname, chatOpen, forceUpdateTabNavigator, setActiveKeyByPathname]);

  useEffect(() => {
    const observer = new MutationObserver(function (mutations) {
      const mutation = mutations.find(
        (m) => (m.target as HTMLElement).id === "chatbase-bubble-window"
      );
      if (mutation) {
        if ((mutation.target as HTMLElement).style.display === "none") {
          setActiveKeyByPathname();
        }
      }
    });
    const chatbaseWindow = document.getElementById("chatbase-bubble-window");
    if (chatbaseWindow) {
      observer.observe(chatbaseWindow, {
        attributes: true,
        attributeFilter: ["style"],
      });
    }
  }, [setActiveKeyByPathname]);

  return (
    <Container qtt={visibleMenuItems.length}>
      <Menu
        mode="horizontal"
        disabledOverflow
        activeKey={activeKey}
        selectedKeys={[activeKey]}
        onClick={(e) => {
          setActiveKey(e.key);
          const chatbaseWindow = document.getElementById(
            "chatbase-bubble-window"
          );
          if (!user.user) {
            const extension: Record<string, string> = {
              bolsa: "-integration",
              cripto: "-integrations",
              exterior: "-integrations",
              autonomos: "-cadastro",
              imoveis: "-cadastro",
            };
            navigate(`${e.key}${extension[e.key.split("/")[2]]}`);
          } else if (e.key === "chat") {
            document.getElementById("chatbase-bubble-button")?.click();
            setChatNotificationQtt(0);
            if (chatbaseWindow?.style.display === "none") {
              setActiveKeyByPathname();
            }
          } else {
            if (chatbaseWindow) {
              chatbaseWindow.style.display = "none";
            }
            navigate(
              e.key === `/${currentBroker.path}/bolsa` &&
                !b3Authorized &&
                !user.user.skippedB3Integration
                ? `/${currentBroker.path}/bolsa-integration`
                : e.key === `/${currentBroker.path}/cripto` &&
                  !hasCriptoIntegration
                ? `/${currentBroker.path}/cripto-integrations`
                : e.key === `/${currentBroker.path}/exterior` &&
                  !hasExteriorIntegration
                ? `/${currentBroker.path}/exterior-integrations`
                : e.key
            );
          }
        }}
      >
        {visibleMenuItems.map((item) => (
          <Menu.Item key={item.key} icon={item.icon}>
            {item.label}
          </Menu.Item>
        ))}
      </Menu>
      <span className="hidden">{forceUpdateTabNavigator}</span>
    </Container>
  );
};
