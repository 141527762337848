import clsx from "clsx";
import { BiUserCircle } from "react-icons/bi";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../Button";
import { Logo } from "../Logo";
import { isMobile } from "../../utils";
import HandleTag from "../../services/handleTag";
import { useAuth } from "../../contexts/AuthContext";
import { PoweredByVelotax } from "../PoweredByVelotax";
import { useBroker } from "../../contexts/BrokerContext";
import { useB3Integration } from "../../contexts/B3IntegrationContext";
import { ReactComponent as SairIcon } from "../../assets/icons/sidebar/sair.svg";
import { ReactComponent as IrpfIcon } from "../../assets/icons/sidebar/irpf.svg";
import { ReactComponent as ContaIcon } from "../../assets/icons/sidebar/conta.svg";
import {
  ContentButton,
  DrawerSider,
  FeatureButton,
  FeaturePromo,
  LayoutSider,
  Module,
  Modules,
  Settings,
} from "./styles";
import { UserPlanEnum } from "../../constants/plans";

interface ISideBarProps {
  pagePath: string;
}

interface ISideBarContainerProps {
  visible: boolean;
  setVisible: Function;
}

const SideBarContainer: React.FC<ISideBarContainerProps> = ({
  children,
  visible,
  setVisible,
}) =>
  isMobile() ? (
    <DrawerSider
      title=""
      width="208px"
      footer={null}
      closeIcon={null}
      placement="left"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      {children}
    </DrawerSider>
  ) : (
    <LayoutSider theme="light" width="208px">
      {children}
    </LayoutSider>
  );

const Sidebar: React.FC<ISideBarProps> = ({ pagePath }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { b3Authorized } = useB3Integration();
  const {
    user,
    signOut,
    hasPlan,
    showDrawer,
    setShowDrawer,
    hasPermissionGeneral,
  } = useAuth();
  const {
    sidebarPages,
    currentBroker,
    hasExteriorIntegration,
    hasCriptoIntegration,
  } = useBroker();

  const [openKeys, setOpenKeys] = useState([""]);

  const sidebarselectedcolor = currentBroker.colors.sidebarSelectedColor;

  const handleNavigate = (path: string) => {
    if (!user.user) {
      setShowDrawer(false);
      const extension: Record<string, string> = {
        bolsa: "-integration",
        cripto: "-integrations",
        exterior: "-integrations",
        autonomos: "-cadastro",
        imoveis: "-cadastro",
      };
      navigate(`${path}${extension[path.split("/")[2]]}`);
    } else if (path.includes("http")) {
      window.open(path, "_blank")?.focus();
    } else {
      setShowDrawer(false);
      if (path === `/${currentBroker.path}/bolsa`) {
        if (b3Authorized || user.user.skippedB3Integration) {
          navigate(path);
        } else {
          navigate(`/${currentBroker.path}/bolsa-integration`);
        }
      } else if (path === `/${currentBroker.path}/cripto`) {
        const userPlanValid =
          user.user.userPlanInfoVelotax.active &&
          user.user.userPlanInfoVelotax.type;
        if (
          userPlanValid ||
          (pathname === `/${currentBroker.path}/cripto-integrations` &&
            hasCriptoIntegration)
        ) {
          navigate(path);
        } else {
          navigate(`/${currentBroker.path}/cripto-integrations?redirect=true`);
        }
      } else if (path === `/${currentBroker.path}/exterior`) {
        const userPlanValid =
          user.user.userPlanInfoVelotax.active &&
          user.user.userPlanInfoVelotax.type;
        if (
          userPlanValid ||
          (pathname === `/${currentBroker.path}/exterior-integrations` &&
            hasExteriorIntegration)
        ) {
          navigate(path);
        } else {
          navigate(
            `/${currentBroker.path}/exterior-integrations?redirect=true`
          );
        }
      } else {
        navigate(path);
      }
    }
  };

  useEffect(() => {
    const currentPage = [
      ...sidebarPages,
      {
        path: "/irpf",
        subPages: [],
      },
    ].find(
      (page) =>
        page.path === pagePath ||
        page.subPages?.find((subPage) => subPage.path === pagePath)
    );
    setOpenKeys([currentPage?.path || ""]);
  }, [pagePath, sidebarPages]);

  const DECLARACAO_IRPF = false;

  return (
    <SideBarContainer visible={showDrawer} setVisible={setShowDrawer}>
      <Logo />
      {DECLARACAO_IRPF && (
        <FeaturePromo
          onClick={() => {
            handleNavigate("/declaracao-irpf");
          }}
        >
          <IrpfIcon className="stroke" />
          <span className="title-features">Declaração IRPF</span>
          <FeatureButton type="primary" className="btn-premium">
            DISPONÍVEL
          </FeatureButton>
        </FeaturePromo>
      )}
      <Modules
        mode="inline"
        openKeys={openKeys}
        selectedKeys={[pagePath, ...openKeys]}
        className={clsx("beauty-scrollbar zero-scrollbar", {
          user: !!user.user,
          poweredBy: !currentBroker.hidePoweredBy,
        })}
      >
        {user.user && currentBroker.useCarteira && (
          <Module
            key="/carteira"
            title="Carteira"
            icon={<BiUserCircle size={24} />}
            onClick={() => handleNavigate("/carteira")}
            sidebarselectedcolor={sidebarselectedcolor}
          >
            Meus investimentos
          </Module>
        )}
        {sidebarPages.map((page) => (
          <Module
            key={page.path}
            icon={page.icon}
            title={page.name}
            onClick={() => {
              if (!hasPlan && page.path.includes("autonomos"))
                navigate("/planos");
              else handleNavigate(page.path);
            }}
            sidebarselectedcolor={sidebarselectedcolor}
          >
            {page.name}
          </Module>
        ))}
        {user.user && false && (
          <Module
            key="/irpf"
            title="Declaração IRPF"
            icon={<IrpfIcon className="stroke" />}
            onClick={() => handleNavigate("/irpf")}
            sidebarselectedcolor={sidebarselectedcolor}
          >
            Declaração IRPF
          </Module>
        )}
      </Modules>
      {user.user ? (
        <ContentButton
          onClick={() => {
            if (!hasPlan || !hasPlan.concierge) navigate("/planos");
            HandleTag("54");
          }}
        >
          {!hasPlan && !hasPermissionGeneral ? (
            <div className="plan-sidebar pointer">
              Seu plano: <strong>Gratuito</strong>
            </div>
          ) : user?.user?.userPlanInfoVelotax?.type ===
              UserPlanEnum.VELOTAX_MAIN_PRO ||
            user?.user?.userPlanInfoVelotax?.type ===
              UserPlanEnum.VELOTAX_MAIN_PRO_MONTH ||
            user?.user?.userPlanInfoVelotax?.type ===
              UserPlanEnum.VELOTAX_MAIN_PRO ||
            user?.user?.userPlanInfoVelotax?.type.includes("_PRO") ||
            hasPermissionGeneral ? (
            <div
              className={`plan-sidebar ${
                hasPlan && !hasPlan.concierge ? "pointer" : ""
              }`}
            >
              Seu plano: <strong>Premium</strong>
            </div>
          ) : user?.user?.userPlanInfoVelotax?.type.includes("_CONCIERGE") ? (
            <div
              className={`plan-sidebar ${
                hasPlan && !hasPlan.concierge ? "pointer" : ""
              }`}
            >
              Seu plano: <strong>Concierge</strong>
            </div>
          ) : user?.user?.userPlanInfoVelotax?.type.includes("_BASIC") ? (
            <div
              className={`plan-sidebar ${
                hasPlan && !hasPlan.concierge ? "pointer" : ""
              }`}
            >
              Seu plano: <strong>Basic</strong>
            </div>
          ) : undefined}
        </ContentButton>
      ) : (
        <ContentButton style={{ justifyContent: "start" }}>
          <span style={{ paddingTop: "6px" }}>Já possui uma conta?</span>
          <Button
            type="link"
            style={{ marginTop: "-8px" }}
            onClick={() => {
              setShowDrawer(false);
              navigate("/p/login");
            }}
          >
            <strong>Faça seu login</strong>
          </Button>
        </ContentButton>
      )}
      {!currentBroker.hidePoweredBy && (
        <PoweredByVelotax className="poweredby-velotax sidebar" />
      )}
      {user.user && (
        <Settings selectedKeys={[pagePath]}>
          <Module
            key="/conta"
            className="conta"
            sidebarselectedcolor={sidebarselectedcolor}
            onClick={() => handleNavigate("/conta")}
            icon={<ContaIcon className="stroke" />}
          >
            Conta
          </Module>
          <Module
            key="signout"
            className="conta logout"
            sidebarselectedcolor="var(--ant-error-color)"
            icon={
              <SairIcon
                width="20px"
                height="20px"
                className="sair"
                fill="var(--medium-gray)"
              />
            }
            onClick={() => {
              signOut();
            }}
          >
            Sair
          </Module>
        </Settings>
      )}
    </SideBarContainer>
  );
};

export default Sidebar;
