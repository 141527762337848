import clsx from "clsx";
import { Tooltip } from "antd";
import { PayDarfButtonContainer } from "./styles";
import { mensagemDarfsRetroativos } from "../../utils";

interface PayDarfButtonProps {
  checked?: boolean;
  disabled?: boolean;
  onChange?: () => void;
  ativarDarfsRetroativos?: boolean;
  checkedChildren?: React.ReactNode;
  unCheckedChildren?: React.ReactNode;
}

export const PayDarfButton: React.FC<PayDarfButtonProps> = ({
  checked,
  onChange,
  disabled,
  checkedChildren,
  unCheckedChildren,
  ativarDarfsRetroativos,
}) => {
  return (
    <Tooltip
      title={
        !ativarDarfsRetroativos
          ? mensagemDarfsRetroativos
          : checked
          ? ""
          : "Clique para sinalizar se o imposto foi pago"
      }
    >
      <PayDarfButtonContainer
        className={clsx({ checked, disabled })}
        onClick={() => {
          if (!disabled) {
            onChange?.();
          }
        }}
      >
        {checked ? checkedChildren : unCheckedChildren}
      </PayDarfButtonContainer>
    </Tooltip>
  );
};
