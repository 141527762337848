import moment from "moment-timezone";

/* eslint-disable @typescript-eslint/no-var-requires */
const isBusinessDay = require("eh-dia-util");
// import ehDiaUtilSlim from 'eh-dia-util-slim';

export const getLastBusinessDay = (lastDayOfTheMonth: Date, holidays?: any) => {
  const date = lastDayOfTheMonth;
  while (!isBusinessDay(date) || holidays?.includes(moment(date).tz('Europe/London').format('MM-DD'))) {
    date.setDate(date.getDate() - 1);
  }
  return date;
};
/** @description Function to return if the day that passed is a business/week day */
export const isWeekday = (year: number, month: number, day: number) => {
  const dayReturn = new Date(year, month, day).getDay();
  if (dayReturn === 0 || dayReturn === 6) return false;
  return true;
};

/** @description Function to return the array of business/week days in that month */
export const weekDays = (dateParam: Date) => {
  const year = dateParam.getFullYear();
  const month = dateParam.getMonth();
  const days = new Date(year, month + 1, 0).getDate();
  let weekdays: any[] = [];
  for (let i = 1; i <= days; i++) {
    if (isWeekday(year, month, i)) weekdays.push(i);
  }
  return weekdays;
};

/** @description Function to get the second business/week day and check if it's true */
export const isSecondBusinessDay = (dateParam: Date) => {
  const currentDay = dateParam.getDate();
  const arrayOfWeekDays = weekDays(dateParam);
  return arrayOfWeekDays.indexOf(currentDay) > 2 ? true : false;
};
