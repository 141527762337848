import { Button } from "@mui/material";
import { FaRegEdit } from "react-icons/fa";
import { Col, List, Row, Skeleton, Tooltip, Typography } from "antd";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineInfoCircle } from "react-icons/ai";
import {
  LoadingOutlined,
  DownloadOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Container } from "./styles";
import { formatCurrency } from "../../../../utils";
import AntButton from "../../../../components/Button";
import { FormModal } from "../../../../components/FormModal";
import { DoacoesHerancasRows } from "../../../../constants/darfBolsa";
import moment from "moment-timezone";

export interface DoacoesHerancasProps {
  changed: boolean;
  loadingPut: boolean;
  loadingGet: boolean;
  setLoadingPut: Dispatch<SetStateAction<boolean>>;
  setLoadingGet: Dispatch<SetStateAction<boolean>>;
  doacaoHerancaEdit: any;
  doacoesHerancas: any[];
  showAddPositionModal: boolean;
  downloadDoacoesHerancasExcel: () => Promise<null | Blob>;
  setDoacaoHerancaEdit: Dispatch<SetStateAction<any>>;
  setShowAddPositionModal: Dispatch<SetStateAction<boolean>>;
  saveDoacoesHerancas: () => void;
  addDoacaoHeranca: (item: any) => void;
  editDoacaoHeranca: (item: any) => void;
  handleEditDoacaoHeranca: (item: any, index: number) => void;
  handleRemoveDoacaoHeranca: (item: any, index: number) => void;
  getOldTransactionsInit: () => void;
}

export const DoacoesHerancas: React.FC<DoacoesHerancasProps> = ({
  loadingGet,
  loadingPut,
  doacoesHerancas,
  downloadDoacoesHerancasExcel,
  setDoacaoHerancaEdit,
  setShowAddPositionModal,
  handleEditDoacaoHeranca,
  handleRemoveDoacaoHeranca,
  addDoacaoHeranca,
  editDoacaoHeranca,
  showAddPositionModal,
  doacaoHerancaEdit,
}) => {
  const [downloading, setDownloading] = useState(false);
  const [orders, setOrders] = useState<any[]>([]);

  useEffect(()=>{
    if (doacoesHerancas?.length > 0)
    {
      const ordersLocal: any[] = [];
      doacoesHerancas?.map((op) => {
        op?.orders.map((or: any)=> {
          or['dateView'] = moment(or?.date, ["DD/MM/YYYY","YYYY-MM-DD",]).format("DD/MM/YYYY");
          ordersLocal.push(or);
        })
      })
      setOrders(ordersLocal);
    }
  }, [doacoesHerancas])

  async function download() {
    setDownloading(true);
    const file = await downloadDoacoesHerancasExcel();
    if (file) {
      const blob = new Blob([file], { type: "xlsx" });
      const element = document.createElement("a");
      element.href = URL.createObjectURL(blob);
      element.download = "doacoes_herancas.xlsx";
      document.body.appendChild(element);
      element.click();
      element.remove();
    }
    setDownloading(false);
  }

  return (
    <Container>
      <Row justify="end" align="top" gutter={[16, 16]}>
        <Col flex={1} offset={0}>
          <Typography.Title style={{ justifySelf: "center" }} level={2}>
            Doações ou heranças
          </Typography.Title>
        </Col>
        <Col flex={1}>
          <Row justify="space-between" align="middle">
            {orders.length === 0 ? (
              <div />
            ) : (
              <Button
                size="small"
                style={{backgroundColor: "var(--ant-primary-color)", color: "white"}}
                variant="contained"
                disabled={orders.length === 0}
                startIcon={
                  loadingPut || loadingGet || downloading ? (
                    <LoadingOutlined style={{color: "white"}}/>
                  ) : (
                    <DownloadOutlined style={{color: "white"}}/>
                  )
                }
                onClick={download}>
                BAIXAR EXCEL
              </Button>
            )}
            <Button
              size="small"
              style={{backgroundColor: "var(--ant-primary-color)", color: "white"}}
              variant="contained"
              onClick={() => {
                setDoacaoHerancaEdit({});
                setShowAddPositionModal(true);
              }}
              startIcon={
                loadingPut || loadingGet ? (
                  <LoadingOutlined style={{color: "white"}}/>
                ) : (
                  <PlusCircleOutlined style={{color: "white"}}/>
                )
              }>
              Adicionar Doação/Herança
            </Button>
          </Row>
        </Col>
        <Col span={24}>
          <List
            loading={loadingGet}
            itemLayout="horizontal"
            dataSource={
              orders?.filter(
                (oldPosition) => !oldPosition.lendingSide
              ) ?? []
            }
            renderItem={(item, index) => (
              <List.Item
                className={item.price === 0 ? "error-border" : ""}
                actions={[
                  ...(item.price === 0
                    ? [
                        <Tooltip title="Preencha o custo médio!">
                          <AntButton
                            icon={
                              <AiOutlineInfoCircle
                                className="alert-icon"
                                size={20}
                              />
                            }
                            type="text"
                          />
                        </Tooltip>,
                      ]
                    : []),
                  <AntButton
                    type="text"
                    disabled={loadingPut}
                    icon={<FaRegEdit />}
                    onClick={() => handleEditDoacaoHeranca(item, index)}
                  />,
                  <AntButton
                    type="text"
                    disabled={loadingPut}
                    icon={<AiOutlineDelete />}
                    onClick={() => handleRemoveDoacaoHeranca(item, index)}
                  />,
                ]}>
                <Skeleton active title={false} loading={loadingGet}>
                  <List.Item.Meta
                    title={<div>{item.code}</div>}
                    description={
                      <>
                        <div className="list-description">
                          <div>
                            Data: <strong>{moment(item.date, [
                              "DD/MM/YYYY",
                              "YYYY-MM-DD",
                            ]).format("DD/MM/YYYY")}</strong>
                          </div>
                          <div>
                            Quantidade: <strong>{item.quantity}</strong>
                          </div>
                          <div>
                            Custo médio:{" "}
                            <strong>{formatCurrency(item.price ?? 0)}</strong>
                          </div>
                        </div>
                      </>
                    }
                  />
                </Skeleton>
              </List.Item>
            )}
          />
        </Col>
      </Row>

      <FormModal
        add={addDoacaoHeranca}
        edit={editDoacaoHeranca}
        visibility={showAddPositionModal}
        rows={DoacoesHerancasRows()}
        onCancel={() => {
          setShowAddPositionModal(false);
        }}
        title={
          doacaoHerancaEdit?._id || doacaoHerancaEdit?.id
            ? "Editar ativo"
            : "Adicionar ativo"
        }
        itemToEdit={
          doacaoHerancaEdit.id || doacaoHerancaEdit.code
            ? doacaoHerancaEdit
            : undefined
        }
      />
    </Container>
  );
};
