import VelotaxLogo from "../../assets/velotax/velotax-light.svg";

interface PoweredByVelotaxProps {
  className?: string;
}

export const PoweredByVelotax: React.FC<PoweredByVelotaxProps> = ({
  className,
}) => {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      className={className}
      href="https://www.velotax.com.br/"
    >
      <i>powered by</i>
      <img src={VelotaxLogo} alt="velotax" />
    </a>
  );
};
