import { Tooltip, Button } from "antd";
// import { Button } from "@mui/material";

export const PayButton: React.FC<{
  fullWidth?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  title?: React.ReactNode;
  isReport?: boolean
}> = ({ onClick, fullWidth, disabled, title, isReport }) => (
  <Tooltip title={title} getPopupContainer={(target) => target.parentElement!}>
    <Button
      onClick={onClick}
      type="primary"
      disabled={disabled}
      style={fullWidth ? { marginTop: "16px", height: "40px" } : {}}
    >
      {isReport ? 'Emitir relatório' : 'Pagar'}
    </Button>{" "}
  </Tooltip>
);
