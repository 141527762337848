import styled from "styled-components";

export const CardContainer = styled.div`
  min-width: 160px;
  height: 160px;
  border-radius: 8px;
  position: relative;
  transition: opacity 0.3s ease-in-out;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 88, 0.08);
  background-color: var(--velotax-background-color);

  &,
  a {
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  a {
    width: 100%;
    height: 100%;
  }

  figure {
    margin: 0 0 4px;

    svg {
      width: 32px;
      height: 32px;
      &:not(.no-fill) {
        fill: var(--ant-primary-color);
      }
      &.no-fill {
        stroke: var(--ant-primary-color);
      }
    }
  }

  .ant-btn.ant-btn-primary.btn-premium {
    top: 12px;
    right: -6px;
  }

  .content {
    width: 100%;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: var(--velotax-font-color);
    text-align: center;

    .ant-typography {
      color: inherit;
      margin: 0 auto 0 0;
      text-align: left;
      text-transform: uppercase;
    }

    .description {
      left: 16px;
      bottom: 16px;
      opacity: 0.7;
      font-size: 13px;
      font-weight: 300;
      position: absolute;
      width: calc(100% - 32px);
      .ant-typography {
        text-transform: none;
      }
    }
  }

  :hover {
    opacity: 0.8;
    cursor: pointer;
  }
  :active {
    opacity: 0.7;
    filter: contrast(0.95);
    cursor: pointer;
  }

  @media only screen and (max-device-width: 812px) {
    &,
    a {
      padding: 12px;
    }
    .description {
      left: 12px;
      bottom: 12px;
      width: calc(100% - 24px);
    }
    .ant-btn.ant-btn-primary.ant-btn-sm {
      top: 8px;
      right: 8px;
      font-size: 11px;
      column-gap: 4px;
      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
`;
