import React from "react";
import { Form, Input } from "antd";
import ReactInputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import { useAuth } from "../../contexts/AuthContext";
import { useBroker } from "../../contexts/BrokerContext";
import { AuthContainer } from "../../styles/LoginSignUp/authContainer";
import {
  validationCpf,
  validationFieldRequired,
} from "../../utils/formValidations";

interface IData {
  cpf: string;
  pass: string;
  isAd?: boolean;
}

export const Login: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm<IData>();
  const { handleSignIn, loading } = useAuth();
  const { getIntegrationStatus } = useBroker();
  const queryParams = new URLSearchParams(window.location.search);
  const isAd = Boolean(queryParams.get("ad"));

  const onFinish = async (data: IData) => {
    try {
      await handleSignIn({...data, isAd});
      getIntegrationStatus({});
    } catch (err: any) {
      return;
    }
  };

  const onValuesChange = (changed: any, values: any) => {
    const cpf = changed.cpf ? changed.cpf.replace(/[_.-/-]/g, "") : values.cpf;
    form.setFieldsValue({
      ...values,
      cpf,
    });
  };

  const handleNavigate = () => {
    isAd ? navigate("/p/cadastrar?ad=true") : navigate("/p/cadastrar")
  };

  return (
    <AuthContainer className="beauty-scrollbar">
      <div className="auth-content">
        <div className="form-container">
          <h1>Acesse sua conta</h1>
          <Form
            form={form}
            size="large"
            onFinish={onFinish}
            onValuesChange={onValuesChange}
          >
            <Form.Item name="cpf" rules={validationCpf}>
              <ReactInputMask
                placeholder="CPF"
                mask="999.999.999-99"
                className="ant-input ant-input-lg"
              />
            </Form.Item>

            <Form.Item name="pass" rules={validationFieldRequired}>
              <Input.Password placeholder="Senha" visibilityToggle={false} />
            </Form.Item>

            <div className="buttons">
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                className="full-width"
                loading={loading}
              >
                Entrar
              </Button>
            </div>
            <div className="bottom-acessar">
              <Button
                type="link"
                onClick={() => navigate("/p/forgot-password")}
                style={{
                  fontSize: "11px",
                  width: "150px",
                }}
              >
                Esqueci minha senha
              </Button>
            </div>
          </Form>

          <div className="footer">
            Ainda não possui uma conta?
            <Button
              type="link"
              onClick={handleNavigate}
              className="btn-underlined"
            >
              Começar
            </Button>
          </div>
          {/* <div className="velopro">
            Contador ou assessor de investimentos?
            <Button
              type="link"
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_VELOPRO_URL}/p/login`,
                  "_blank"
                )
              }
              className="btn-underlined"
            >
              Versão Profissional
            </Button>
          </div> */}
        </div>
      </div>
    </AuthContainer>
  );
};
