import { months } from "./date";
import { Posicao } from "../contexts/CarteiraContext";

const colors = [
  "#000058",
  "#1634FF",
  "#1634FFDD",
  "#1634FFBB",
  "#1634FF99",
  "#1634FF77",
  "#1634FF55",
  "#1634FF33",
  "#1634FF",
];

export const getProviderColor = (provider: string, index: number) => {
  while (index > 8) {
    const differnce = index - 8;
    index = differnce - 1;
  }
  return colors[index];
};

export const getClassesAtivosChart = (carteiraCategorizada: {
  [key: string]: Posicao[];
}) => {
  const getCategoriaAtivos = (categoria: string) => {
    switch (categoria) {
      case "FII - Fundo de Investimento Imobiliário":
        return "FIIs";
      case "Fundos de Investimentos":
        return "FIs";
      case "ETF - Exchange Traded Fund":
        return "ETFs";
      case "BDR - Brazilian Depositary Receipts":
        return "BDRs";
      case "Exterior":
        return "XP International";
      case "TesouroDireto":
        return "Tesouro Direto";
      case "Opcao":
        return "Opções";
      default:
        return categoria;
    }
  };

  let i = 0;
  let classes: any[] = [];
  for (let index in carteiraCategorizada) {
    const sum = carteiraCategorizada[index].reduce((acc, cur) => {
      return acc + cur.posicao;
    }, 0);
    const name = getCategoriaAtivos(index);
    classes.push({
      name,
      value: sum,
      color: colors[i % 6],
    });
    i += 1;
  }
  return classes;
};

export const getCorretoraAtivosChart = (carteiraCategorizada: {
  [key: string]: Posicao[];
}) => {
  const getCorretora = (corretora: string) => {
    switch (corretora) {
      case "XP INVESTIMENTOS CCTVM S/A":
        return "XP";
      default:
        return corretora.split(" ")[0] || corretora;
    }
  };
  let corretoras: any = [];
  for (let ativos of Object.values(carteiraCategorizada)) {
    for (let ativo of ativos) {
      corretoras[ativo.corretora] =
        (corretoras[ativo.corretora] || 0) + ativo.posicao;
    }
  }
  let i = 0;
  let result = [];
  for (let index in corretoras) {
    result.push({
      name: getCorretora(index),
      value: corretoras[index],
      color: colors[i % 6],
    });
    i += 1;
  }
  return result;
};

export const getLineChartData = (arr: any[]) => {
  if (!arr) return [];
  const previousDate = arr[0].date.split("/").map((num: any) => Number(num));
  const currentDate = arr[arr.length - 1].date
    .split("/")
    .map((num: any) => Number(num));

  let response = arr.map((i) => {
    return { name: "", value: i.value };
  });

  response[0].name = `${previousDate[0]} ${months[previousDate[1] - 1]} ${
    previousDate[2]
  }`;
  response[response.length - 1].name = `${currentDate[0]} ${
    months[currentDate[1] - 1]
  } ${currentDate[2]}`;

  return response;
};
