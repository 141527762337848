import { Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { AiOutlineArrowRight } from "react-icons/ai";
import Button from "../../../components/Button";
import { useB3Integration } from "../../../contexts/B3IntegrationContext";

interface B3IntegrationButtonsProps {
  checkOnlyExistsUserB3?: boolean;
}

export const B3IntegrationButtons: React.FC<B3IntegrationButtonsProps> = ({
  checkOnlyExistsUserB3,
}) => {
  const { handleIntegrate, checkingAuhtorization } = useB3Integration();

  return (
    <>
      <Button
        block
        size="large"
        type="primary"
        onClick={() => {
          // checkOnlyExistsUserB3
          //   ? handleAuthorizationB3()
          handleIntegrate();
        }}
      >
        Autorizar conexão&nbsp;
        <AiOutlineArrowRight size={18} />
      </Button>
      {checkingAuhtorization && (
        <Typography.Paragraph
          style={{
            display: "flex",
            margin: "24px 0",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingOutlined />
          &nbsp; Consultando a B3...
        </Typography.Paragraph>
      )}
    </>
  );
};
