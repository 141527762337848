import _ from "lodash";
import moment from "moment";
import * as XLSX from "xlsx";
import { HiOutlineDownload } from "react-icons/hi";
import React, { useEffect, useRef, useState } from "react";
import {
  Checkbox,
  Col,
  DatePicker,
  Modal,
  Row,
  Select,
  Table,
  Typography,
  message,
} from "antd";
import { ContainerModal } from "./styles";
import Button from "../../components/Button";
import { DrawerModal } from "../DrawerModal";
import apiBolsa from "../../services/apiBolsa";
import { ReportTypeDrawer } from "../ReportType/ReportType";
import { downloadExcelFromApi, isIosPlatform } from "../../utils/webview";
import { ReactComponent as FiltroIcon } from "../../assets/icons/filtro.svg";
import {
  currencyToNumber,
  download,
  downloadPDF,
  formatCurrency,
  monthsExtended,
} from "../../utils";

const months: any = {
  Jan: 1,
  Fev: 2,
  Mar: 3,
  Abr: 4,
  Mai: 5,
  Jun: 6,
  Jul: 7,
  Ago: 8,
  Set: 9,
  Out: 10,
  Nov: 11,
  Dez: 12,
};

export const ProventosModal: React.FC<any> = ({
  open,
  month,
  proventos,
  setOpenModal,
  yearProventos,
  proventosLoading,
  setYearProventos,
  setMonthProventos,
}) => {
  const date = new Date();
  const dateFormat = "DD/MM/YYYY";
  const selectYears = [1, 0, -1, -2, -3];
  const dateBeginRef = useRef(null);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<any>({});
  const [, setDataModalPDF] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [dateBeginFilter, setDateBeginFilter] = useState("");
  const [appliedFilters, setAppliedFilters] = useState<any>({});
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [reportDrawerProventos, setReportDrawerProventos] = useState(false);
  const [dateEndFilter, setDateEndFilter] = useState(
    moment().format(dateFormat)
  );

  /*const serializeDataExcel = (data: any) => {
    return data
      .filter(
        (el: any) => yearProventos === Number(moment(el?.date).format("Y"))
      )
      .map((el: any) => {
        return {
          Produto: el?.code,
          Pagamento: moment(el?.date).format("DD/MM/YYYY"),
          Tipo: _.capitalize(el?.corporateActionTypeDescription),
          Instituição: el?.corretora,
          "Qtde.": el?.quantity || "-",
          "Preço unit.":
            el?.unitPrice === null || el?.unitPrice === undefined
              ? "-"
              : "R$ " +
                el?.unitPrice?.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }),
          "Valor líquido":
            "R$ " +
            el?.grossAmount?.toLocaleString("pt-br", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
        };
      });
  };*/

  const downloadExcel = (data: any) => {
    setLoading(true);
    apiBolsa
      .post("/wallet/report-proventos", {
        data: data,
      })
      .then((result) => {
        if (isIosPlatform()) {
          downloadExcelFromApi(result);
          return;
        }

        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Proventos");
        XLSX.writeFile(workbook, "Proventos.xlsx");
      })
      .finally(() => {
        message.success("O relatório foi enviado para o seu e-mail");
        setLoading(false);
      });
  };

  const downloadRelatorio = async (type: string) => {
    try {
      setLoading(true);
      if (type === "pdf") {
        let fileUrl = "";
        let file = "";
        const newProventos = proventos.map((el: any) => {
          return {
            code: el.code || "",
            date: moment(el?.date).format("DD/MM/YYYY"),
            corporateActionTypeDescription: _.capitalize(
              el?.corporateActionTypeDescription
            ),
            corretora: el?.corretora,
            quantity: el?.quantity || "-",
            unitPrice: el?.unitPrice || "-",
            grossAmount: el?.grossAmount,
          };
        });
        const ret = await apiBolsa.post("/wallet/report-proventos-pdf", {
          proventos: newProventos,
        });
        if (ret) {
          fileUrl = ret?.data?.fileUrl;
          file = ret?.data?.file;
        }
        if (file) {
          downloadPDF(file, "Proventos");
        } else if (fileUrl) {
          download(fileUrl);
        }
      } else if (type === "xlsx") {
        downloadExcel(data);
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      message.error(
        "Não foi possível baixar o relatório de Proventos. Tente novamente mais tarde"
      );
    }
  };

  const reportPDFData = (data: any, type: string) => {
    const formatModalPDF = data.map((el: any) => {
      if (type === "prov") {
        return {
          code: el.code || "",
          date: moment(el?.date).format(dateFormat),
          corporateActionTypeDescription: _.capitalize(
            el?.corporateActionTypeDescription
          ),
          corretora: el?.corretora,
          quantity: el?.quantity || "-",
          unitPrice:
            typeof el?.unitPrice === "string"
              ? currencyToNumber(el?.unitPrice)
              : el?.unitPrice || "-",
          grossAmount:
            typeof el?.grossAmount === "string"
              ? currencyToNumber(el?.grossAmount)
              : el.grossAmount || "-",
        };
      } else if (type === "other") {
        return {
          code: el["Produto"] || "",
          date: el["Pagamento"],
          corporateActionTypeDescription: _.capitalize(el["Tipo"]),
          corretora: el["Instituição"],
          quantity: el["Qtde."] || "-",
          unitPrice:
            typeof el["Preço unit."] === "string"
              ? currencyToNumber(el["Preço unit."])
              : el["Preço unit."] || "-",
          grossAmount:
            typeof el["Valor líquido"] === "string"
              ? currencyToNumber(el["Valor líquido"])
              : el["Valor líquido"] || "-",
        };
      }
      return {};
    });
    return formatModalPDF;
  };

  useEffect(() => {
    let total = 0;
    const monthNumber = months[month];

    const dataFiltered = proventos.filter((el: any) => {
      const monthOp = Number(moment(el?.date).format("M"));
      const yearOp = Number(moment(el?.date).format("YYYY"));
      if (monthNumber === monthOp && yearProventos === yearOp) {
        return true;
      }
      return false;
    });
    setDataModalPDF(reportPDFData(dataFiltered, "prov"));
    const dataSerialized = dataFiltered.map((el: any) => {
      total += el?.grossAmount;

      return {
        Produto: el?.code,
        Pagamento: moment(el?.date).format(dateFormat),
        Tipo: _.capitalize(el?.corporateActionTypeDescription),
        Instituição: el?.corretora,
        "Qtde.": el?.quantity || "-",
        "Preço unit.": !el?.unitPrice
          ? "-"
          : "R$ " +
            el?.unitPrice?.toLocaleString("pt-br", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
        "Valor líquido":
          "R$ " +
          el?.grossAmount?.toLocaleString("pt-br", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
      };
    });
    setTotal(total);
    setData(dataSerialized);
    setOriginalData(dataSerialized);
  }, [month, yearProventos, proventos]);

  useEffect(() => {
    if (Object.keys(filters).length > 0 || (dateBeginFilter && dateEndFilter))
      setData(originalData);
  }, [filters, dateBeginFilter, dateEndFilter, originalData]);

  const handleOpenFilterModal = () => {
    setShowFilterModal(true);
  };

  const getCorretFilters = () => {
    const newCorret: any[] = [];
    if (originalData && originalData.length > 0)
      originalData.forEach((el: any) => {
        if (el["Instituição"] && !newCorret.includes(el["Instituição"])) {
          newCorret.push(el["Instituição"]);
        }
      });
    return newCorret;
  };

  return (
    <DrawerModal
      footer={null}
      visible={open}
      title="Proventos"
      maskClosable={true}
      destroyOnClose={true}
      className="modalProventos"
      onCancel={() => setOpenModal(false)}
    >
      <ContainerModal>
        <div className="container-1">
          <Row className="client-wallet-header-btns">
            <Select
              value={month}
              defaultValue={month}
              style={{ minWidth: "150px" }}
              onChange={(name) => setMonthProventos({ name })}
              getPopupContainer={(trigger) => trigger.parentElement!}
              options={Object.keys(months).map((m, i) => ({
                value: m,
                label: monthsExtended[i],
              }))}
            />
            <Select
              value={yearProventos}
              defaultValue={yearProventos}
              onChange={(e) => setYearProventos(e)}
              getPopupContainer={(trigger) => trigger.parentElement!}
              options={selectYears.map((y) => ({
                value: date.getFullYear() + y,
                label: date.getFullYear() + y,
              }))}
            />
          </Row>
          <Row className="client-wallet-header-btns">
            <Button onClick={handleOpenFilterModal} icon={<FiltroIcon />}>
              Filtrar
            </Button>
            <Button
              icon={<HiOutlineDownload />}
              onClick={() => {
                setReportDrawerProventos(true);
              }}
            >
              Baixar
            </Button>
          </Row>
        </div>
        <Row>
          <div className="year-resume">
            <Typography.Paragraph>Total recebido no mês:</Typography.Paragraph>
            <Typography.Paragraph>{formatCurrency(total)}</Typography.Paragraph>
          </div>
        </Row>
        <Row>
          <div className="container-table">
            <Table
              dataSource={data}
              style={{ width: "100%" }}
              loading={proventosLoading}
              pagination={{
                defaultPageSize: 10,
                defaultCurrent: 1,
                showSizeChanger: false,
              }}
              columns={[
                {
                  title: "Empresa",
                  align: "left",
                  width: "250px",
                  dataIndex: "Produto",
                },
                {
                  title: "Pagamento",
                  align: "left",
                  width: "50px",
                  dataIndex: "Pagamento",
                },
                {
                  title: "Tipo",
                  align: "left",
                  width: "100px",
                  dataIndex: "Tipo",
                },
                {
                  title: "Corretora",
                  align: "left",
                  width: "250px",
                  dataIndex: "Instituição",
                },
                {
                  title: "Qtde.",
                  align: "left",
                  width: "50px",
                  dataIndex: "Qtde.",
                },
                {
                  title: "Preço unit.",
                  align: "left",
                  width: "50px",
                  dataIndex: "Preço unit.",
                },
                {
                  title: "Valor líquido",
                  align: "left",
                  width: "50px",
                  dataIndex: "Valor líquido",
                },
              ]}
            />
          </div>
        </Row>
      </ContainerModal>
      <Modal
        footer={null}
        zIndex={1001}
        width="410px"
        visible={showFilterModal}
        title="Filtrar proventos"
        className="filter-proventos-modal"
        onCancel={() => {
          setFilters(appliedFilters);
          setShowFilterModal(false);
        }}
      >
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Row gutter={[8, 8]}>
              {getCorretFilters().length > 0 && (
                <>
                  <Col span={24}>
                    <Typography.Text strong>Instituição</Typography.Text>
                  </Col>
                  <Col span={24}>
                    <Checkbox.Group
                      value={filters.corretoras}
                      onChange={(corretoras) => {
                        setFilters((filters: any) => ({
                          ...filters,
                          corretoras,
                        }));
                      }}
                    >
                      <Row gutter={[4, 4]}>
                        {getCorretFilters().map((el: any) => {
                          const value = `${el?.split?.(" ")?.[0]}_${
                            el?.split?.(" ")?.[1]
                          }`;
                          return (
                            <Col span={24}>
                              <Checkbox value={`corret_${value}`}>
                                {el}
                              </Checkbox>
                            </Col>
                          );
                        })}
                      </Row>
                    </Checkbox.Group>
                  </Col>
                </>
              )}
              <Col span={24}>
                <Typography.Text strong>Período de pagamento</Typography.Text>
              </Col>
              <Col span={24}>
                <Typography.Text>Data inicial</Typography.Text>
                <DatePicker
                  ref={dateBeginRef}
                  format={dateFormat}
                  onClick={() => setDateBeginFilter("")}
                  onChange={(el) => {
                    if (el) setDateBeginFilter(moment(el).format(dateFormat));
                  }}
                  style={{
                    width: "50%",
                    marginTop: "5px",
                    marginBottom: "10px",
                  }}
                />
                <Typography.Text>Data final</Typography.Text>
                <DatePicker
                  format={dateFormat}
                  defaultValue={moment()}
                  onClick={() => setDateEndFilter("")}
                  onChange={(el) => {
                    if (el) setDateEndFilter(moment(el).format(dateFormat));
                  }}
                  style={{
                    width: "50%",
                    marginTop: "5px",
                    marginBottom: "10px",
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Button
              block
              size="large"
              type="primary"
              loading={loading}
              onClick={() => {
                setAppliedFilters(filters);
                const getProventosWithFilter = (filters: any) => {
                  const corretoras = filters?.corretoras || [];
                  const dataFiltered: any = [];
                  const dataFinal: any = [];
                  if (corretoras.length > 0) {
                    corretoras.forEach((corret: string) => {
                      data.forEach((el: any) => {
                        const corretEl = `corret_${
                          el["Instituição"]?.split(" ")[0]
                        }_${el["Instituição"]?.split(" ")[1]}`;
                        if (corretEl === corret) dataFiltered.push(el);
                      });
                    });
                    setData(dataFiltered);
                    if (dateBeginFilter && dateEndFilter) {
                      const dateBegin = moment(
                        dateBeginFilter,
                        dateFormat
                      ).toDate();
                      const dateEnd = moment(
                        dateEndFilter,
                        dateFormat
                      ).toDate();
                      dataFiltered.forEach((el: any) => {
                        const datePagamento = moment(
                          el["Pagamento"],
                          dateFormat
                        ).toDate();
                        if (
                          datePagamento >= dateBegin &&
                          datePagamento <= dateEnd
                        )
                          dataFinal.push(el);
                      });
                      setData(dataFinal);
                    }
                    setDataModalPDF(
                      reportPDFData(
                        dataFinal.length === 0 ? dataFiltered : dataFinal,
                        "other"
                      )
                    );
                    return;
                  } else if (dateBeginFilter && dateEndFilter) {
                    const dateBegin = moment(
                      dateBeginFilter,
                      dateFormat
                    ).toDate();
                    const dateEnd = moment(dateEndFilter, dateFormat).toDate();
                    data.forEach((el: any) => {
                      const datePagamento = moment(
                        el["Pagamento"],
                        dateFormat
                      ).toDate();
                      if (
                        datePagamento >= dateBegin &&
                        datePagamento <= dateEnd
                      )
                        dataFinal.push(el);
                    });
                    setData(dataFinal);
                    setDataModalPDF(reportPDFData(dataFinal, "other"));
                    return;
                  }
                  setData(originalData);
                };
                getProventosWithFilter(filters);
                setTimeout(() => {
                  setShowFilterModal(false);
                }, 500);
              }}
            >
              Aplicar
            </Button>
          </Col>
        </Row>
      </Modal>
      <ReportTypeDrawer
        loading={loading}
        open={reportDrawerProventos}
        onClose={() => setReportDrawerProventos(false)}
        setType={(type: string) => downloadRelatorio(type)}
      />
    </DrawerModal>
  );
};
